/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import StepWizard from '../../../components/stepWizard';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import {
    Grid,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import {
    MdLooksOne,
    MdLooksTwo,
    MdLooks3,
} from 'react-icons/md';
import BeenhereIcon from '@mui/icons-material/Beenhere';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////

const Recepcion = () => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const [currentStep, setCurrentStep] = useState(0);
    const matches = useMediaQuery('(min-width:700px)');
    const cruz = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Publico%2Fcruz.png?alt=media&token=ff2f4cee-73f5-4426-be08-abed0d8b97e5";
    const nextStep = (step) => {
        setCurrentStep(step);
    }
    const setProgress = (progress, pos) => {
        let stepsCopy = steps;
        stepsCopy.map((step, i) => {
            if (i == pos) {
                step.value = progress
            }
        });
        setSteps([...stepsCopy]);
    }
    const [steps, setSteps] = useState([
        {
            icon: <MdLooksOne />,
            iconActive: <BeenhereIcon />,
            active: false,
            container: <Step1 nextStep={nextStep} setProgress={setProgress} />,
            value: 0
        },
        {
            icon: <MdLooksTwo />,
            iconActive: <BeenhereIcon />,
            active: false,
            container: <Step2 nextStep={nextStep} setProgress={setProgress} />,
            value: 0
        },
        {
            icon: <MdLooks3 />,
            iconActive: <BeenhereIcon />,
            active: false,
            container: <Step3 nextStep={nextStep} setProgress={setProgress} />,
            value: 0
        }
    ]);
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (currentStep > 0) {
            let stepsCopy = steps;
            stepsCopy.map((step, i) => {
                if (i < currentStep) {
                    step.active = true;
                    step.value = 100
                } else {
                    step.active = false;
                    step.value = 0
                }
            });
            setSteps([...stepsCopy]);
        }
    }, [currentStep]);

    return (
        <>
            <div style={{ position: 'absolute', top: '0', right: '0', bottom: '0', left: '0' }}>
                <img src={cruz} alt="cruz"
                    style={{
                        position: 'absolute',
                        height: '100px',
                        width: '100px',
                        zIndex: '-9',
                        top: '75%',
                        left: '10%',
                        filter: 'opacity(0.2)'
                    }}
                />
                <img src={cruz} alt="cruz"
                    style={{
                        position: 'absolute',
                        height: '30px',
                        width: '30px',
                        zIndex: '-9',
                        top: '70%',
                        left: '82%',
                    }}
                />
                <img src={cruz} alt="cruz"
                    style={{
                        position: 'absolute',
                        height: '75px',
                        width: '75px',
                        zIndex: '-9',
                        top: '92%',
                        left: '59%',
                        filter: 'opacity(0.5)'
                    }}
                />
                {
                    matches && (
                        <img src={cruz} alt="cruz"
                            style={{
                                position: 'absolute',
                                height: '150px',
                                width: '150px',
                                zIndex: '-9',
                                top: '39%',
                                left: '75%',
                                filter: 'opacity(0.3)'
                            }}
                        />
                    )
                }
                <img src={cruz} alt="cruz"
                    style={{
                        position: 'absolute',
                        height: '100px',
                        width: '100px',
                        zIndex: '-9',
                        top: '15%',
                        left: '10%',
                        filter: 'opacity(0.2)'
                    }}
                />
                <img src={cruz} alt="cruz"
                    style={{
                        position: 'absolute',
                        height: '80px',
                        width: '80px',
                        zIndex: '-9',
                        top: '36%',
                        left: '19%',
                        filter: 'opacity(1)'
                    }}
                />
            </div>
            <div style={{ paddingTop: '20px' }}>
                <StepWizard steps={steps} currentStep={currentStep} />
            </div>
        </>
    );
}
export default Recepcion;