import { Grid, TextField } from "@mui/material"
import React from "react"

const antecedentesHF = ({ datos, setDatos, ...props }) => {
    return (
        <>
            <Grid item xs={12} sx={{ margin: "20px 0", color: "#FFFFFF", background: "black", padding: "10px" }}>
                ANTECEDENTES HEREDOFAMILIARES
            </Grid>
            <Grid item xs={12} sx={{ margin: "20px 0" }}>
                ENFERMEDADES CARDIOVASCULARES
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"infarto agudo miocardio".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.infartoAM}
                    placeholder={"infarto agudo miocardio".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, infartoAM: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"inquemia miocardica".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.inquemiaMiocardia}
                    placeholder={"inquemia miocardica".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, inquemiaMiocardia: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"hipertension arterial".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.hipertensionArterial}
                    placeholder={"hipertension arterial".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, hipertensionArterial: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"insuficiencia cardiaca".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.insuficienciaCardiaca}
                    placeholder={"insuficiencia cardiaca".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, insuficienciaCardiaca: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"otras".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.otrasEC}
                    placeholder={"otras".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, otrasEC: e.target.value } }) }}
                />
            </Grid>

            <Grid item xs={12} sx={{ margin: "20px 0" }}>
                ENFERMEDADES ENDOCRINOLOGICAS
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"diabetes Mellitus".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.diabetesMellitus}
                    placeholder={"diabetes Mellitus".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, diabetesMellitus: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"obesidad".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.obesidad}
                    placeholder={"obesidad".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, obesidad: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"hipertirodismo".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.hipertirodismo}
                    placeholder={"hipertirodismo".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, hipertirodismo: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"hipotiroidismo".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.hipotiroidismo}
                    placeholder={"hipotiroidismo".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, hipotiroidismo: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"otras".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.otrasEE}
                    placeholder={"otras".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, otrasEE: e.target.value } }) }}
                />
            </Grid>

            <Grid item xs={12} sx={{ margin: "20px 0" }}>
                ENFERMEDADES RESPIRATORIA
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"asma".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.asma}
                    placeholder={"asma".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, asma: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"rinitis Alergica".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.rinitisAlergica}
                    placeholder={"rinitis Alergica".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, rinitisAlergica: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"tuberculosis".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.tuberculosis}
                    placeholder={"tuberculosis".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, tuberculosis: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"enfisema Pulmonar".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.enfisemaPulmonar}
                    placeholder={"enfisema Pulmonar".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, enfisemaPulmonar: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"neumonias".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.neumonias}
                    placeholder={"neumonias".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, neumonias: e.target.value } }) }}
                />
            </Grid>

            <Grid item xs={12} sx={{ margin: "20px 0" }}>
                ENFERMEDADES NEUROLOGICAS
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"epilepsia".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.epilepsia}
                    placeholder={"epilepsia".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, epilepsia: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"migraña".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.migraña}
                    placeholder={"migraña".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, migraña: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"psiquiatricas".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.psiquiatricas}
                    placeholder={"psiquiatricas".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, psiquiatricas: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"degenerativas".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.degenerativas}
                    placeholder={"degenerativas".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, degenerativas: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"otras".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.otrasEN}
                    placeholder={"otras".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, otrasEN: e.target.value } }) }}
                />
            </Grid>

            <Grid item xs={12} sx={{ margin: "20px 0" }}>
                OTRAS
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"osteoartrosis / osteoartritis".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.osteoartrosis}
                    placeholder={"osteoartrosis / osteoartritis".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, osteoartrosis: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"enfermedades de la piel".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.piel}
                    placeholder={"enfermedades de la piel".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, piel: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"oncologicas".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.oncologicas}
                    placeholder={"oncologicas".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, oncologicas: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"sordera".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.sordera}
                    placeholder={"sordera".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, sordera: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"alergicos".toLocaleUpperCase()}
                    value={datos?.antecedentesHF?.alergicos}
                    placeholder={"alergicos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesHF: { ...datos?.antecedentesHF, alergicos: e.target.value } }) }}
                />
            </Grid>
        </>
    )
}

export default antecedentesHF