import React, { useState } from "react";
import { Divider, Grid, Paper, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import { useModel } from "../../../../hooks/useModel";
import Swal from "sweetalert2";
import { useAuth } from "../../../../hooks/useAuth";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { useEffect } from "react";
import { useAlert } from "../../../../hooks/useAlert";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
export const InterrogatorioPorAparatosSistemasComponent = ({
  idEvaluacion,
  idOrdenServicio,
  interrogatorio,
  setGuardado,
  setDisabledByName,
}) => {
  const classes = useStyles();
  const id = idEvaluacion;
  const { showAlert } = useAlert();
  const [admin] = useLocalStorage("admin", null);
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const { permiso } = useAuth();
  const [propiedades, setPropiedades] = useState(
    interrogatorio
      ? interrogatorio
      : {
        habitusExterno: "",
        auditivo: "",
        visual: "",
        respiratorio: "",
        cardiovascular: "",
        gastrointestinal: "",
        genitourinario: "",
        hematopoyetico: "",
        endocrinio: "",
        nervioso: "",
        musculoesqueletico: "",
        pielTegumentos: "",
        personalEnfermeria: "",
        medico: "",
        cp: "",
      }
  );
  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "evaluaciones",
      id,
      redirectOnPost: false,
    });

  useEffect(() => {
    if (Object.keys(modelError)?.length > 0 && !updateModelLoading) {
      console.log(modelError)
      Object.keys(modelError).forEach(
        error => {
          console.log(modelError[error])
          showAlert({ message: modelError[error], severity: "warning" })
        }
      )
      setGuardado(false)
    }
  }, [modelError])

  const onGuardar = async () => {
    const body = propiedades;

    let data = {
      idOrdenServicio,
      idEvaluacion,
      idSucursal: 2,
      InterrogatorioAparatos: body,
    };
    setDisabledByName("InterrogatorioPorAparatosSistemasComponent", true);
    await updateModel(data, true, "hospitalito/nuevaConsulta");
    setGuardado(true);
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <strong>{"INTERROGATORIO POR APARATOS Y SISTEMAS"}</strong>
          </div>
          <Divider />
        </Grid>
        <Grid
          item
          md={12}
          style={{ display: "flex", flexWrap: "wrap", marginTop: "-15px" }}
        >
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Habitus Externo"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.habitusExterno}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  habitusExterno: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Sistema Auditivo"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.auditivo}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  auditivo: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Sistema Visual"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.visual}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  visual: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Sistema Respiratorio"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.respiratorio}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  respiratorio: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Sistema Cardiovascular"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.cardiovascular}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  cardiovascular: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Sistema Gastrointestinal"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.gastrointestinal}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  gastrointestinal: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Sistema Genitourinario"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.genitourinario}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  genitourinario: e.target.value,
                }))
              }
            />
            <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
              <TextField
                label="Sistema Hematopoyético"
                variant="standard"
                maxRows={5}
                multiline
                fullWidth
                size="medium"
                value={propiedades.hematopoyetico}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    hematopoyetico: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
              <TextField
                label="Sistema Endocrino"
                variant="standard"
                maxRows={5}
                multiline
                fullWidth
                size="medium"
                value={propiedades.endocrinio}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    endocrinio: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
              <TextField
                label="Sistema Nervioso"
                variant="standard"
                maxRows={5}
                multiline
                fullWidth
                size="medium"
                value={propiedades.nervioso}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    nervioso: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
              <TextField
                label="Sistema Musculoesquelético"
                variant="standard"
                maxRows={5}
                multiline
                fullWidth
                size="medium"
                value={propiedades.musculoesqueletico}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    musculoesqueletico: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
              <TextField
                label="Piel y Tegumentos"
                variant="standard"
                maxRows={5}
                multiline
                fullWidth
                size="medium"
                value={propiedades.pielTegumentos}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    pielTegumentos: e.target.value,
                  }))
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading ||
                  updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["evaluaciones"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<Save />}
              style={{ color: "#fff" }}
            >
              GUARDAR
              {/* {(!editing ? "GUARDAR" : "MODIFICAR")} */}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
