import React, { useMemo } from 'react'
import { Divider, Grid, Paper, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { useModels } from '../../../../hooks/useModels';
import moment from 'moment-timezone';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
const FichaIdentificacion = ({ idOrdenServicio }) => {
  const classes = useStyles();

  const parametrosInicialesMemo = useMemo(() => ({
    name: "orden-servicio",
    ordenar: "idOrdenServicio.asc",
    extraParams: {
      idOrdenServicio: idOrdenServicio,
      expand: "detallePaciente, detalleConvenio, ordenServicioServicio"
    },
  }), []);
  const [models, modelsPage, refreshModels, deleteModel,] =
    useModels({ ...parametrosInicialesMemo });

  function getAge(dateString) {
    var today = new Date();
    var fechaNacimiento = new Date(dateString);
    var age = today.getFullYear() - fechaNacimiento.getFullYear();
    var m = today.getMonth() - fechaNacimiento.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < fechaNacimiento.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <>
      {models.map((fichaIdentificacion, index) => (
        <Grid container spacing={1} component={Paper} className={classes.paper} style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <strong>{"FICHA DE IDENTIFICACIÓN"}</strong>
            </div>
            <Divider />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Tipo Identificación"
              variant="standard"
              fullWidth
              size="medium"
              value={fichaIdentificacion.tipoIdentificacion ? fichaIdentificacion.tipoIdentificacion : "INE"}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Empresa"
              variant="standard"
              fullWidth
              size="medium"
              value={fichaIdentificacion.detalleConvenio.nombreConvenio ? fichaIdentificacion.detalleConvenio.nombreConvenio : "N/A"}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Identificación"
              variant="standard"
              fullWidth
              size="medium"
              value={fichaIdentificacion.detallePaciente.ine ? fichaIdentificacion.detallePaciente.ine : "N/A"}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Nombre Completo"
              variant="standard"
              fullWidth
              size="medium"
              value={fichaIdentificacion.detallePaciente.nombreCompleto ? fichaIdentificacion.detallePaciente.nombreCompleto : "N/A"}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Sexo"
              variant="standard"
              fullWidth
              size="medium"
              value={fichaIdentificacion?.detallePaciente?.genero === "M" ? "Masculino" : "Femenino"}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Folio"
              variant="standard"
              fullWidth
              size="medium"
              value={fichaIdentificacion.idOrdenServicio ? fichaIdentificacion.idOrdenServicio : "N/A"}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Fecha Cita"
              variant="standard"
              fullWidth
              size="medium"
              value={moment(fichaIdentificacion.fechaSolicitud).format('L')}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Hora"
              variant="standard"
              fullWidth
              size="medium"
              value={moment(fichaIdentificacion.fechaSolicitud).format('h:mm a')}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Fecha Nacimiento"
              variant="standard"
              fullWidth
              size="medium"
              value={fichaIdentificacion.detallePaciente.fechaNacimiento ? fichaIdentificacion.detallePaciente.fechaNacimiento : "N/A"}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Edad"
              variant="standard"
              fullWidth
              size="medium"
              value={getAge(fichaIdentificacion.detallePaciente.fechaNacimiento ? fichaIdentificacion.detallePaciente.fechaNacimiento : "N/A")}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Tipo Sangre"
              variant="standard"
              fullWidth
              size="medium"
              value={fichaIdentificacion.detallePaciente.tipoSangre ? fichaIdentificacion.detallePaciente.tipoSangre : "N/A"}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Tipo Examen"
              variant="standard"
              fullWidth
              size="medium"
              value={fichaIdentificacion.tipoExamen ? fichaIdentificacion.tipoExamen : "N/A"}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Estado Civil"
              variant="standard"
              fullWidth
              size="medium"
              value={fichaIdentificacion.detallePaciente.estadoCivil ? fichaIdentificacion.detallePaciente.estadoCivil : "N/A"}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Telefono"
              variant="standard"
              fullWidth
              size="medium"
              value={fichaIdentificacion.detallePaciente.telefono ? fichaIdentificacion.detallePaciente.telefono : "N/A"}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Escolaridad"
              variant="standard"
              fullWidth
              size="medium"
              value={fichaIdentificacion.detallePaciente.nivelEducativo ? fichaIdentificacion.detallePaciente.nivelEducativo : "N/A"}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Domicilio"
              variant="standard"
              fullWidth
              size="medium"
              value={fichaIdentificacion.detallePaciente.direccion ? fichaIdentificacion.detallePaciente.direccion : "N/A"}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Proyecto"
              variant="standard"
              fullWidth
              size="medium"
              value={fichaIdentificacion.detallePaciente.proyecto ? fichaIdentificacion.detallePaciente.proyecto : "N/A"}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Puesto"
              variant="standard"
              fullWidth
              size="medium"
              value={fichaIdentificacion.detallePaciente.puesto ? fichaIdentificacion.detallePaciente.puesto : "N/A"}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              disabled
              label="Contacto Emergencia"
              variant="standard"
              fullWidth
              size="medium"
              value={fichaIdentificacion.detallePaciente.contactoEmergencia ? fichaIdentificacion.detallePaciente.contactoEmergencia : "N/A"}
            />
          </Grid>
          <Grid item xs={12} sx={{
            borderBottom: "2px solid #dad083",
            backgroundColor: "#1d5e7b",
            textAlign: "center",
            padding: "5px",
            color: "white",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}>
            ESTUDIOS A REALIZAR
          </Grid>
          <Grid item xs={12} sx={{
            display: "flex",
            flexDirection: "column",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            padding: "0 !important",
          }}>
            {
              fichaIdentificacion?.ordenServicioServicio.map((servicio, i) => {
                return (
                  <div style={{ width: "100%", display: "flex", flexDirection: "row", borderBottom: "2px solid #dad083", borderBottomLeftRadius: fichaIdentificacion?.ordenServicioServicio.length - 1 === i ? "5px" : "0px", borderBottomRightRadius: fichaIdentificacion?.ordenServicioServicio.length - 1 === i ? "5px" : "0px" }}>
                    <div style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                      <div style={{ padding: "5px", width: "50%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "#1d5e7b", color: "white", borderBottomLeftRadius: fichaIdentificacion?.ordenServicioServicio.length - 1 === i ? "5px" : "0px" }}>GRUPO</div>
                      <div style={{ padding: "5px", width: "50%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>{servicio.grupo}</div>
                    </div>
                    <div style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                      <div style={{ padding: "5px", width: "50%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "#1d5e7b", color: "white" }}>SERVICIO</div>
                      <div style={{ padding: "5px", width: "50%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>{servicio.concepto}</div>
                    </div>
                  </div>
                )
              })
            }
          </Grid>
        </Grid>
      ))}
    </>
  )
}

export default FichaIdentificacion;