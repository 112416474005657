/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useModel } from "../../hooks/useModel";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { useAlert } from "../../hooks/useAlert";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import PageHeader from "../../components/PageHeader";
import {
    Grid,
    Divider,
    FormControlLabel,
    FormControl,
    Paper,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    Table,
    TableHead,
    TableBody,
    CircularProgress,
    IconButton,
    TableCell,
    TableRow
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import RangePicker from '../../components/RangePicker';
import InputRegex from '../../components/InputRegex';
import DatePicker from '../../components/DatePickerBeta';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AsyncAutocompleteSearchBeta from '../../components/AsyncAutocompleteSearchBeta';
import Loading from "../../components/FullScreenLoader";
import Swal from "sweetalert2";
import moment from 'moment';
import RemoveIcon from '@mui/icons-material/Remove';
import { makeStyles } from "@mui/styles";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { entradasStyle, tableCellStyles, tableRowStyles, } from '../../utils';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Edit } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));
const useEntradas = entradasStyle();

const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);

const AgendaDetail = ({ match, history, dialog }) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const entradas = useEntradas();
    const id = match.params.id;
    const classes = useStyles();
    const [clearInputDoctor, setClearInputDoctor] = useState(false);
    const [clearInputSucursal, setClearInputSucursal] = useState(false);
    const [doctor, setDoctor] = useState(null);
    const { showAlert } = useAlert();
    const [clearInputProducto, setClearInputProducto] = useState(false);
    const editing = !!id;
    const [reload, setReload] = useState(true);
    const { permiso } = useAuth();
    const [sucursal, setSucursal] = useState(null);
    const [diasInhabiles, setDiasInhabiles] = useState([]);
    const [admin] = useLocalStorage("admin", null);
    const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
    const [propiedades, setPropiedades] = useState({
        diasInhabiles: [],
        idDoctor: "",
        division: "30",
        semana: "",
        fechaInhabil: moment("2022/01/01 00:00").format('yyyy/MM/DD'),
        inicioHoraSemana1: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
        finHoraSemana1: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
        inicioHoraSemana2: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
        finHoraSemana2: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
        inicioHoraSabado1: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
        finHoraSabado1: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
        inicioHoraSabado2: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
        finHoraSabado2: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
        inicioHoraDomingo1: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
        finHoraDomingo1: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
        inicioHoraDomingo2: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
        finHoraDomingo2: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
        idSucursal,
    });

    const [semana, setSemana] = useState({
        lunes: false,
        martes: false,
        miercoles: false,
        jueves: false,
        viernes: false,
        sabado: false,
        domingo: false,
    });

    const { model, modelLoading, modelError, updateModel, updateModelLoading } =
        useModel({
            name: "agenda",
            expand: "doctor,agendaDiaInhabiles",
            id,
            redirectOnPost: true,
        });

    const doctorParamsMemo = useMemo(() => ({
        pagina: 1,
        limite: 20,
        idSucursal: idSucursal == "" ? sucursal?.idSucursal : idSucursal,
        ordenar: "nombreCompleto.desc"
    }), [idSucursal, sucursal]);

    useMemo(() => {
        setClearInputDoctor(true);
    }, [idSucursal, sucursal]);

    const sucursalParamsMemo = useMemo(() => ({
        pagina: 1,
        limite: 20,
        ordenar: "nombreSucursal.desc"
    }), []);
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const onGuardar = async () => {
        let title = "";
        editing
            ? (title = "¿Deseas Editar esta Agenda?")
            : (title = "¿Deseas Guardar esta Agenda?");
        const res = await Swal.fire({
            title: title,
            text: "Favor de corroborar sus datos antes de continuar.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, Continuar!",
            cancelButtonText: "Cancelar",
        });
        if (res.isConfirmed) {
            if (doctor != null) {
                if (Object.values(semana).some(s => s == true)) {
                    showAlert({
                        message: "Espera un momento.....",
                        severity: "warning",
                    });
                    let semanaFormated = [];
                    Object.keys(semana).forEach(key => {
                        if (semana[key]) {
                            if (key == "sabado") {
                                semanaFormated.push("sábado");
                            } else if (key == "miercoles") {
                                semanaFormated.push("miércoles");
                            } else {
                                semanaFormated.push(key);
                            }
                        }
                    });
                    let body = { ...propiedades, idDoctor: doctor.idUsuario, semana: semanaFormated.join(","), diasInhabiles };
                    if (editing) {
                        body.idAgenda = id;
                    }
                    updateModel(body, true, "agendas");
                } else {
                    showAlert({
                        message: "Seleccione por lo menos un dia de la semana",
                        severity: "error",
                    });
                }
            } else {
                showAlert({
                    message: "Seleccione un doctor para guardar la agenda",
                    severity: "error",
                });
            }
        }
    };
    const addDiasInhabiles = (fecha, comentario) => {
        if (diasInhabiles.some(dia => dia.fecha == fecha)) {
            showAlert({
                message: "Esa fecha ya fue agregada",
                severity: "error",
            });
        } else {
            let dia = { fecha, comentario };
            let copydias = [...diasInhabiles];
            copydias.push(dia);
            setDiasInhabiles(copydias);
            setPropiedades({ ...propiedades, comentario: "" });
        }
        setReload(true);
    }
    const onDropDiasInhabiles = async (fecha) => {
        const res = await Swal.fire({
            title: "Borrar",
            icon: "warning",
            text: `¿Estás seguro de que deseas borrar el dato seleccionado "${fecha}"?`,
            showCancelButton: true,
            confirmButtonColor: "#25607A",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, deseo Continuar!",
            cancelButtonText: "Cancelar",
        });
        if (res.isConfirmed) {
            showAlert({
                message: "Espera un momento.....",
                severity: "warning",
            });
            let posi;
            const copyDesglose = [...diasInhabiles];
            diasInhabiles.map((e, index) => {
                if (e.fecha === fecha) {
                    posi = index;
                }
            });
            copyDesglose.splice(posi, 1);
            setDiasInhabiles(copyDesglose);
            setReload(true);
            showAlert({
                message: "Producto borrado con éxito.",
                severity: "success",
            });
        } else {
            setReload(true);
        }
    };
    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////
    const setDoctorCallback = useCallback((e, v) => {
        if (v !== "" && v) {
            setDoctor(v);
        } else {
            setDoctor(null);
        }
    }, [setDoctor]);

    const setSucursalCallback = useCallback((e, v) => {
        if (v !== "" && v) {
            setSucursal(v);
            setPropiedades({ ...propiedades, idSucursal: v.idSucursal });
        } else {
            setSucursal(null);
        }
    }, [setSucursal, setPropiedades]);

    useEffect(() => {
        if (clearInputProducto) {
            setClearInputProducto(false);
        }
    }, [clearInputProducto]);

    useEffect(() => {
        if (clearInputDoctor) {
            setClearInputDoctor(false)
        }
    }, [clearInputDoctor]);

    useEffect(() => {
        let mounted = true;
        if (mounted && model) {
            setPropiedades(model);
            setDoctor(model.doctor);
            let preSemeanas = {};
            model.semana.split(",").forEach(s => {
                preSemeanas = { ...preSemeanas, [s]: true };
            });
            setDiasInhabiles(model.agendaDiaInhabiles);
            setSemana(preSemeanas);
        }
        return () => {
            mounted = false;
            setPropiedades({
                idDoctor: "",
                division: "",
                semana: "",
                diasInhabiles: [],
                fechaInhabil: moment("2022/01/01 00:00").format('yyyy/MM/DD'),
                inicioHoraSemana1: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
                finHoraSemana1: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
                inicioHoraSemana2: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
                finHoraSemana2: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
                inicioHoraSabado1: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
                finHoraSabado1: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
                inicioHoraSabado2: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
                finHoraSabado2: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
                inicioHoraDomingo1: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
                finHoraDomingo1: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
                inicioHoraDomingo2: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
                finHoraDomingo2: moment("2022/01/01 00:00").format('yyyy/MM/DD HH:mm'),
            });
        };
    }, [model]);

    if (modelLoading) return <Loading />;

    return (
        <>
            <PageHeader
                history={history}
                title={(!editing ? "NUEVA" : "EDITAR") + "  AGENDA"}
                links={[
                    {
                        to: "/agendas",
                        icon: <DateRangeIcon fontSize="small" />,
                        label: "Agenda",
                    },
                    {
                        to: !editing ? "/agenda/nuevo" : "/agenda/editar/" + id,
                        icon: !editing ? <Add /> : <Edit />,
                        label: !editing
                            ? "Nueva"
                            : "Editar",
                    },
                ]}
                editing={editing}
                dialog={dialog}
            />
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12}>
                    <div><strong>{"INFORMACIÓN DE LA AGENDA"}</strong></div>
                    <Divider />
                </Grid>
                {
                    admin && (
                        <Grid item xs={12}>
                            <AsyncAutocompleteSearchBeta
                                value={sucursal}
                                variant="outlined"
                                label="Sucursal"
                                size='small'
                                ruta="sucursal"
                                labelProp="nombreSucursal"
                                extraParams={sucursalParamsMemo}
                                clearInput={clearInputSucursal}
                                onChange={setSucursalCallback}
                                campoError="idSucursal"
                            />
                        </Grid>
                    )
                }
                <Grid item xs={12} md={6}>
                    <AsyncAutocompleteSearchBeta
                        value={doctor}
                        disabled={(sucursal != null || idSucursal != "") ? false : true}
                        variant="outlined"
                        label="Doctores"
                        size='small'
                        ruta="usuario/doctores"
                        labelProp="nombreCompleto"
                        extraParams={doctorParamsMemo}
                        clearInput={clearInputDoctor}
                        onChange={setDoctorCallback}
                        campoError="idDoctor"
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="divisionSelect">División</InputLabel>
                        <Select
                            labelId="divisionSelect"
                            id="divisionSelectId"
                            value={propiedades.division}
                            helperText={modelError?.division}
                            error={Boolean(modelError?.division)}
                            label="División"
                            onChange={e => {
                                setPropiedades({
                                    ...propiedades,
                                    division: e.target.value
                                })
                            }}
                        >
                            <MenuItem value={"15"}>15 Minutos</MenuItem>
                            <MenuItem value={"20"}>20 Minutos</MenuItem>
                            <MenuItem value={"25"}>25 Minutos</MenuItem>
                            <MenuItem value={"30"}>30 Minutos</MenuItem>
                            <MenuItem value={"35"}>35 Minutos</MenuItem>
                            <MenuItem value={"40"}>40 Minutos</MenuItem>
                            <MenuItem value={"45"}>45 Minutos</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {(admin === true) && (
                    <>

                    </>
                )}

                <Grid item xs={12} md={12} style={{ display: 'flex', alignItems: 'flex-end', flexWrap: "wrap", }}>
                    <FormControlLabel control={<Switch
                        checked={semana.lunes}
                        onChange={e => {
                            setSemana({
                                ...semana,
                                lunes: e.target.checked
                            });
                        }}
                    />} label="Lunes" />
                    <FormControlLabel control={<Switch
                        checked={semana.martes}
                        onChange={e => {
                            setSemana({
                                ...semana,
                                martes: e.target.checked
                            });
                        }}
                    />} label="Martes" />
                    <FormControlLabel control={<Switch
                        checked={semana.miercoles}
                        onChange={e => {
                            setSemana({
                                ...semana,
                                miercoles: e.target.checked
                            });
                        }}
                    />} label="Miércoles" />
                    <FormControlLabel control={<Switch
                        checked={semana.jueves}
                        onChange={e => {
                            setSemana({
                                ...semana,
                                jueves: e.target.checked
                            });
                        }}
                    />} label="Jueves" />
                    <FormControlLabel control={<Switch
                        checked={semana.viernes}
                        onChange={e => {
                            setSemana({
                                ...semana,
                                viernes: e.target.checked
                            });
                        }}
                    />} label="Viernes" />
                </Grid>
                <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <RangePicker
                        value={[new Date(propiedades.inicioHoraSemana1), new Date(propiedades.finHoraSemana1)]}
                        onChange={e => {
                            setPropiedades({
                                ...propiedades,
                                inicioHoraSemana1: moment(e[0]).format('yyyy/MM/DD HH:mm'),
                                finHoraSemana1: moment(e[1]).format('yyyy/MM/DD HH:mm')
                            });
                        }}
                        format="HH:mm"
                        placeholder='Seleccione el rango de fechas'
                        ranges={false}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <RangePicker
                        value={[new Date(propiedades.inicioHoraSemana2), new Date(propiedades.finHoraSemana2)]}
                        onChange={e => {
                            setPropiedades({
                                ...propiedades,
                                inicioHoraSemana2: moment(e[0]).format('yyyy/MM/DD HH:mm'),
                                finHoraSemana2: moment(e[1]).format('yyyy/MM/DD HH:mm')
                            });
                        }}
                        format="HH:mm"
                        placeholder='Seleccione el rango de fechas'
                        ranges={false}
                    />
                </Grid>
                <Grid item xs={12} md={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <FormControlLabel control={<Switch
                        checked={semana.sabado}
                        onChange={e => {
                            setSemana({
                                ...semana,
                                sabado: e.target.checked
                            });
                        }}
                    />} label="Sábado" />
                </Grid>
                <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <RangePicker
                        value={[new Date(propiedades.inicioHoraSabado1), new Date(propiedades.finHoraSabado1)]}
                        onChange={e => {
                            setPropiedades({
                                ...propiedades,
                                inicioHoraSabado1: moment(e[0]).format('yyyy/MM/DD HH:mm'),
                                finHoraSabado1: moment(e[1]).format('yyyy/MM/DD HH:mm')
                            });
                        }}
                        format="HH:mm"
                        placeholder='Seleccione el rango de fechas'
                        ranges={false}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <RangePicker
                        value={[new Date(propiedades.inicioHoraSabado2), new Date(propiedades.finHoraSabado2)]}
                        onChange={e => {
                            setPropiedades({
                                ...propiedades,
                                inicioHoraSabado2: moment(e[0]).format('yyyy/MM/DD HH:mm'),
                                finHoraSabado2: moment(e[1]).format('yyyy/MM/DD HH:mm')
                            });
                        }}
                        format="HH:mm"
                        placeholder='Seleccione el rango de fechas'
                        ranges={false}
                    />
                </Grid>
                <Grid item xs={12} md={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <FormControlLabel control={<Switch
                        checked={semana.domingo}
                        onChange={e => {
                            setSemana({
                                ...semana,
                                domingo: e.target.checked
                            });
                        }}
                    />} label="Domingo" />
                </Grid>
                <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <RangePicker
                        value={[new Date(propiedades.inicioHoraDomingo1), new Date(propiedades.finHoraDomingo1)]}
                        onChange={e => {
                            setPropiedades({
                                ...propiedades,
                                inicioHoraDomingo1: moment(e[0]).format('yyyy/MM/DD HH:mm'),
                                finHoraDomingo1: moment(e[1]).format('yyyy/MM/DD HH:mm')
                            });
                        }}
                        format="HH:mm"
                        placeholder='Seleccione el rango de fechas'
                        ranges={false}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <RangePicker
                        value={[new Date(propiedades.inicioHoraDomingo2), new Date(propiedades.finHoraDomingo2)]}
                        onChange={e => {
                            setPropiedades({
                                ...propiedades,
                                inicioHoraDomingo2: moment(e[0]).format('yyyy/MM/DD HH:mm'),
                                finHoraDomingo2: moment(e[1]).format('yyyy/MM/DD HH:mm')
                            });
                        }}
                        format="HH:mm"
                        placeholder='Seleccione el rango de fechas'
                        ranges={false}
                    />
                </Grid>

                <Grid item xs={12}>
                    Dias Inhabiles
                </Grid>

                <Grid item xs={12} md={10} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <DatePicker
                                labelText="Fecha Inicio"
                                variant="standard"
                                fullWidth
                                inputFormat="dd/MM/yyyy"
                                value={propiedades.fechaInhabil}
                                size="medium"
                                onChange={(e) => setPropiedades(prevState => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    fechaInhabil: moment(e).format('yyyy/MM/DD')
                                }))
                                }
                            />

                        </Grid>
                        <Grid item xs={6}>
                            <InputRegex
                                fullWidth
                                value={propiedades.comentario}
                                onChange={e => {
                                    setPropiedades({ ...propiedades, comentario: e.target.value });
                                }}
                                label={"Comentario"}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={2} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <LoadingButton
                        sx={{ width: "100%" }}
                        variant="contained"
                        onClick={e => { setReload(false); addDiasInhabiles(propiedades.fechaInhabil, propiedades.comentario); }}
                        size="medium"
                        style={{ color: "#fff" }}
                    >
                        Agregar Dia Inhabil
                    </LoadingButton>
                </Grid>

                {(diasInhabiles.length > 0) && (
                    <>
                        <Grid item xs={12}>
                            <Table className={entradas.tableEntradas}>
                                <TableHead>
                                    <TableRowModify>
                                        <TableCellModify> # </TableCellModify>
                                        <TableCellModify> FECHA </TableCellModify>
                                        <TableCellModify> COMENTARIO </TableCellModify>
                                        <TableCellModify> ELIMINAR </TableCellModify>
                                    </TableRowModify>
                                </TableHead>
                                <TableBody>
                                    {reload ? diasInhabiles.map((dia, index) => {
                                        return (
                                            <TableRowModify key={index}>
                                                <TableCellModify> {index + 1} </TableCellModify>
                                                <TableCellModify> {dia.fecha} </TableCellModify>
                                                <TableCellModify> {dia.comentario} </TableCellModify>
                                                <TableCellModify>
                                                    <IconButton
                                                        onClick={e => {
                                                            setReload(false)
                                                            onDropDiasInhabiles(dia.fecha);
                                                        }}
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </TableCellModify>
                                            </TableRowModify>
                                        )
                                    }) : (
                                        <TableRowModify>
                                            <TableCellModify></TableCellModify>
                                            <TableCellModify></TableCellModify>
                                            <TableCellModify> <CircularProgress /> </TableCellModify>
                                            <TableCellModify></TableCellModify>
                                        </TableRowModify>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                        <LoadingButton
                            disabled={
                                modelLoading || updateModelLoading ||
                                    admin ||
                                    (permiso && permiso[0]["agendas"] & 2) === 2
                                    ? false
                                    : true
                            }
                            loading={modelLoading || updateModelLoading}
                            variant="contained"
                            onClick={() => onGuardar()}
                            size="medium"
                            startIcon={<SaveIcon />}
                            style={{ color: "#fff" }}
                        >
                            {(!editing ? "GUARDAR" : "MODIFICAR")}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
export default AgendaDetail;