////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import React from 'react'
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import {
    Grid,
} from '@mui/material';
import GoogleMap from "../../../components/GoogleMap";
import { styled } from "@mui/material";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
const Telefono = styled("img")({
    "@keyframes pulsate": {
        "0%": {
            transform: "rotate(-15deg)",
        },
        "10%": {
            transform: "rotate(15deg)",
        },
        "20%": {
            transform: "rotate(-15deg)",
        },
        "30%": {
            transform: "rotate(0deg)",
        }
    },
    animationName: "pulsate",
    animationDuration: "2s",
    animationIterationCount: "infinite",
    width: "100%",
});
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////

const Component = () => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const fondo = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2FFondo.jpg?alt=media&token=8c6b4630-df68-469d-8eaa-7648cb6c1374";
    const logo = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2Flogo.png?alt=media&token=08d29a51-3658-451a-9975-4edd5c989540";
    const urgente = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2Fatencion%20medica%20urgente%20.png?alt=media&token=f8b69f5e-5bbb-4b43-8dfc-5a964310b8f9";
    const tel = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2Fllamar.png?alt=media&token=13de83d0-b416-476f-9653-b36a1a3d6120";
    const horario = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2FHorario.png?alt=media&token=efed5f3e-c5aa-4dfb-a19a-0b9e253d8524";
    const poximamente = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2Fproximamente%2024-7.png?alt=media&token=52c6e78f-9688-431b-8f3d-21aa5eba0ed0";
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////

    return (
        <>
            <div style={{ position: "absolute", left: "0", right: "0", bottom: "0", top: "0", zIndex: "1" }}>
                <img style={{ height: "100%", width: "100%", objectFit: "cover", objectPosition: "bottom" }} src={fondo} alt={"fondo"} />
            </div>
            <div style={{ position: "absolute", left: "0", right: "0", bottom: "0", top: "0", zIndex: "3" }}>
                <Grid container>
                    <Grid xs={12} sm={1} md={2} lg={3} xl={4}></Grid>
                    <Grid item xs={12} sm={10} md={8} lg={6} xl={4} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <img style={{ width: "60%", marginTop: "50px" }} src={logo} alt={"logo"} />
                        <div style={{ width: "70%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: "35px", justifyContent: "center", cursor: "pointer" }}>
                        <a style={{width:"25%"}} href="tel://662 399 8385"><Telefono src={tel} alt={"telefono"} /></a>
                            <img style={{ width: "60%" }} src={urgente} alt={"urgente"} />
                        </div>
                        <GoogleMap
                            position={{ lat: 29.0873424, lng: -110.9770697 }}
                            zoom={17}
                            customCss={{ height: "37vh", marginTop: "35px", width: "65%", boxShadow: "0px 0px 10px #0000003d", borderRadius: "5px", zIndex: "2", }}
                        />
                        <img style={{ width: "85%", marginTop: "-25px" }} src={horario} alt={"horario"} />
                        <img style={{ width: "35%", marginTop: "5px" }} src={poximamente} alt={"poximamente"} />
                    </Grid>
                    <Grid xs={12} sm={1} md={2} lg={3} xl={4}></Grid>
                </Grid>
            </div>
        </>
    );
}
export default Component;