////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useEffect, useState, useRef } from 'react'
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { IconButton } from 'rsuite';
import CircularProgress from '@mui/material/CircularProgress';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { imageCapture } from '../utils';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import ClearIcon from '@mui/icons-material/Clear';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useImageCapture = imageCapture();
const ReactFileManager = ({
  components = false, size = { width: '100%', height: '100%' }, animations = true, setBarcode, barcode, typeFile = "camara", onCloseBarcode, getVideo,
  style = {}, foto, setFoto, disabled = false, ...props
}) => {
  ////////////////////////////////////////////////////////////////////////
  //                              Vars                                  //
  ////////////////////////////////////////////////////////////////////////
  const imageCapture = useImageCapture();
  const [screenshot, setScreenshot] = useState(false);
  const constraints = {
    video: { facingMode: "environment" }
  };
  const beep = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2Fbeep-07.wav?alt=media&token=c7b22e2c-ccd5-46d3-8926-8a6005915ae2";
  const [loading, setLoading] = useState(true);
  const [stream, setStream] = useState({});
  const video = useRef();
  const animacion = useRef();
  const canvas = useRef();
  ////////////////////////////////////////////////////////////////////////
  //                           Funcions Def                             //
  ////////////////////////////////////////////////////////////////////////
  const clearScrenshot = () => {
    setScreenshot(false);
    setFoto(null);
    getDevices();
  }

  const getDevices = async () => {
    if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
      const newContraints = { ...constraints };
      startStream(newContraints);
    }
  }

  const startStream = async _constraints => {
    const _stream = await navigator.mediaDevices.getUserMedia(_constraints);
    window.localStream = _stream;
    setStream(_stream);
    handleStream(_stream);
  }

  const handleStream = _stream => {
    setLoading(false);
    video.current.srcObject = _stream;
    _stream == null ? video.current.pause() : video.current.play()
  };

  const getScreenshot = () => {
    var audio = new Audio("https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2FcameraSS.mp3?alt=media&token=31f14868-57f9-44f1-a8f8-6422968a181a");
    audio.play();
    animacion.current.style.boxShadow = '0px 0px 0px 15px gray inset';
    animacion.current.style.background = 'rgb(255 255 255 / 50%)';
    setScreenshot(true);
  }

  const saveFile = () => {
    canvas.current.toBlob(async blob => {
      var image = new Image();
      image.src = blob;
      setFoto(blob)
    });
  }
  ////////////////////////////////////////////////////////////////////////
  //                            Hooks Def                               //
  ////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (screenshot) {
      canvas.current.width = video.current.videoWidth;
      canvas.current.height = video.current.videoHeight;
      canvas.current.getContext('2d').drawImage(video.current, 0, 0, canvas.current.width, canvas.current.height);
      window.setTimeout(() => {
        animacion.current.style.boxShadow = 'none';
        animacion.current.style.background = 'none';
        video.current.srcObject.getTracks().forEach(track => track.stop());
      }, 500);
      saveFile();
    }
  }, [screenshot])

  useEffect(() => {
    if (typeFile == "camara") {
      getDevices();
    } else {

    }
    return () => {
        window.localStream.getVideoTracks().forEach(track => track.stop());
    }
  }, []);

  if (typeFile == "camara") {
    return (
      <>
        <div style={style} className={imageCapture.cam}>
          <div>
            <div ref={animacion} style={{ width: '100%', height: '100%', transition: 'all 0.5s ease', position: 'absolute', zIndex: '99' }}></div>
            {
              loading ?
                <CircularProgress style={{ margin: '150px' }} />
                :
                <video ref={video} style={{ width: '100%', transition: 'all 0.5s ease' }} />
            }
            {screenshot && (
              <canvas style={{ width: '100%', height: '100%', position: 'absolute', transition: 'all 0.5s ease' }} ref={canvas} />
            )}
            <IconButton disabled={ disabled?true: loading || screenshot === true} style={{ position: 'absolute', bottom: '10px', padding: '15px', borderRadius: '100%', zIndex: '100' }} onClick={e => { getScreenshot() }}><CameraAltIcon /></IconButton>
            <IconButton disabled={disabled?true: loading || screenshot === false} style={{ position: 'absolute', bottom: '10px', right: '10px', padding: '15px', borderRadius: '100%', zIndex: '100' }} onClick={e => { clearScrenshot() }}><ClearIcon /></IconButton>
          </div>
        </div>
      </>
    );
  } else if (typeFile == "barcode") {
    return (
      <div style={{ height: '20vh', textAlign: 'center', marginBottom: '50px' }}>
        <div style={{ height: '20vh', overflow: 'hidden' }}>
          <BarcodeScannerComponent
            width={400}
            height={400}
            onUpdate={(err, result) => {
              if (result) {
                if (result.text != "") {
                  let sound = new Audio(beep);
                  sound.play();
                  setBarcode(result.text);
                  onCloseBarcode(result.text);
                }
              }
            }}
          />
        </div>
      </div>
    );
  }
}
export default ReactFileManager;