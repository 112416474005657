import React, { useState, useCallback, useMemo } from "react";
import {
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Table,
  CircularProgress,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { useAlert } from "../../../../hooks/useAlert";
import Swal from "sweetalert2";
import AsyncAutocompleteSearchRework from "../../../../components/AsyncAutocompleteSearchRework";
import AutocompleteDeluxe from "../../../../components/AutocompleteDeluxe";
import { Save, Add } from "@mui/icons-material";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  tableCellStyles,
  tableRowStyles,
  entradasStyle,
  capitalizeFirst,
} from "../../../../utils";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { useAuth } from "../../../../hooks/useAuth";
import { useModel } from "../../../../hooks/useModel";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const useEntradasStyle = entradasStyle();
export const DiagnosticoComponent = ({
  idEvaluacion,
  idOrdenServicio,
  diagnostico,
  setGuardado,
  setDisabledByName,
}) => {
  const classes = useStyles();
  const id = idEvaluacion;
  const [admin] = useLocalStorage("admin", null);
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const { permiso } = useAuth();
  const [enfermedad, setEnfermedad] = useState(null);
  const [clearEnfermedad, setClearEnfermedad] = useState(false);
  const filtroDiagnostigoE = () => {
    let enf = [];
    diagnostico.diagnosticoEnfermedad.forEach((e) => {
      enf.push(e.enfermedad);
    });
    return enf;
  };
  const [enfermedades, setEnfermedades] = useState(
    diagnostico?.diagnosticoEnfermedad ? filtroDiagnostigoE() : []
  );
  const { showAlert } = useAlert();
  const [reload, setReload] = useState(true);
  const [propiedades, setPropiedades] = useState(
    diagnostico
      ? diagnostico
      : {
        diagnostico: "",
        personalEnfermeria: "",
        medico: "",
        cp: "",
      }
  );

  const paramsMemoEnfermedad = useMemo(
    () => ({
      ordenar: "NOMBRE.asc",
    }),
    []
  );

  const entradas = useEntradasStyle();

  const setEnfermedadCallback = useCallback((e, v) => {
    if (v !== "" && v) {
      setEnfermedad(v);
    } else {
      setEnfermedad("");
    }
  }, []);

  const addEnfermedad = () => {
    if (enfermedad?.idEnfermedad != null) {
      const repetido = enfermedades.some(
        (e) => e.idEnfermedad === enfermedad.idEnfermedad
      );
      if (repetido) {
        showAlert({
          message: "Esta Enfermedad ya fue agregada",
          severity: "error",
        });
        setEnfermedad(null);
      } else {
        let preEnfermedades = [...enfermedades];
        preEnfermedades.push(enfermedad);
        setEnfermedades(preEnfermedades);
        setEnfermedad(null);
      }
    }
  };

  const onDropEnfermedad = async (id, nombre) => {
    if (id) {
      const res = await Swal.fire({
        title: "Borrar",
        icon: "warning",
        text: `¿Estás seguro de que deseas borrar la enfermedad seleccionado "${capitalizeFirst(
          nombre.toLowerCase()
        )}"?`,
        showCancelButton: true,
        confirmButtonColor: "#25607A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, deseo Continuar!",
        cancelButtonText: "Cancelar",
      });
      if (res.isConfirmed) {
        showAlert({
          message: "Espera un momento.....",
          severity: "warning",
        });
        let posi;
        const copyEnfermedades = [...enfermedades];
        enfermedades.map((e, index) => {
          if (e.idEnfermedad === id) {
            posi = index;
          }
        });
        copyEnfermedades.splice(posi, 1);
        setEnfermedades(copyEnfermedades);
        setReload(true);
        showAlert({
          message: "Enfermedad borrado con éxito.",
          severity: "success",
        });
      } else {
        setReload(true);
      }
    }
  };

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({ name: "evaluaciones", id, redirectOnPost: false });

  useEffect(() => {
    if (Object.keys(modelError)?.length > 0 && !updateModelLoading) {
      console.log(modelError)
      Object.keys(modelError).forEach(
        error => {
          console.log(modelError[error])
          showAlert({ message: modelError[error], severity: "warning" })
        }
      )
      setGuardado(false)
    }
  }, [modelError])

  const onGuardar = async () => {
    let body = propiedades;

    let data = {
      idOrdenServicio,
      idEvaluacion,
      idSucursal: 2,
      Diagnostico: body,
      Enfermedades: enfermedades,
    };
    setDisabledByName("DiagnosticoComponent", true);
    await updateModel(data, true, "hospitalito/nuevaConsulta");
    setGuardado(true);
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <strong>{"DIAGNÓSTICO"}</strong>
          </div>
          <Divider />
        </Grid>

        <Grid
          item
          md={12}
          style={{ display: "flex", flexWrap: "wrap", marginTop: "-15px" }}
        >
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Descripción"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.diagnostico}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  diagnostico: e.target.value,
                }))
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <strong>{"CATÁLOGO CIE-10"}</strong>
          </div>
        </Grid>
        <Grid item xs={12} md={9}>
          <AutocompleteDeluxe
            labelToShow="ENFERMEDADES CATÁLOGO CIE-10"
            dato={enfermedad}
            setDato={setEnfermedad}
            labelProp="NOMBRE"
            nombre="enfermedades"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <LoadingButton
            sx={{ width: "100%" }}
            variant="contained"
            onClick={() => addEnfermedad()}
            size="medium"
            startIcon={<Add />}
            style={{ color: "#fff" }}
          >
            AGREGAR ENFERMEDAD
          </LoadingButton>
        </Grid>
        {enfermedades && enfermedades.length > 0 && (
          <>
            <Grid item xs={12}>
              <Table className={entradas.tableEntradas}>
                <TableHead>
                  <TableRowModify>
                    <TableCellModify> # </TableCellModify>
                    <TableCellModify> # Catálogo </TableCellModify>
                    <TableCellModify> Nombre Enfermedad </TableCellModify>
                    <TableCellModify> Capítulo </TableCellModify>
                    <TableCellModify> Letra </TableCellModify>
                    <TableCellModify> </TableCellModify>
                  </TableRowModify>
                </TableHead>
                <TableBody>
                  {reload ? (
                    enfermedades?.map((enf, index) => {
                      return (
                        <TableRowModify key={index}>
                          <TableCellModify> {index + 1} </TableCellModify>
                          <TableCellModify>
                            {" "}
                            {enf?.CATALOG_KEY}{" "}
                          </TableCellModify>
                          <TableCellModify> {enf?.NOMBRE} </TableCellModify>
                          <TableCellModify> {enf?.CAPITULO} </TableCellModify>
                          <TableCellModify> {enf?.LETRA} </TableCellModify>
                          <TableCellModify>
                            <IconButton
                              onClick={(e) => {
                                onDropEnfermedad(
                                  enf?.idEnfermedad,
                                  enf?.NOMBRE
                                );
                                setReload(false);
                              }}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </TableCellModify>
                        </TableRowModify>
                      );
                    })
                  ) : (
                    <TableRowModify>
                      <TableCellModify></TableCellModify>
                      <TableCellModify></TableCellModify>
                      <TableCellModify>
                        {" "}
                        <CircularProgress />{" "}
                      </TableCellModify>
                      <TableCellModify></TableCellModify>
                      <TableCellModify></TableCellModify>
                    </TableRowModify>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading ||
                  updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["evaluaciones"] & 2) === 2
                  ? false
                  : true
              }
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<Save />}
              style={{ color: "#fff" }}
            >
              GUARDAR
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
