/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import {
    Grid,
    Button,
    Hidden,
} from '@mui/material';
import moment from 'moment';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { capitalizeFirst, calendarStyle } from '../utils';
import { IconButton } from 'rsuite';
import useTheme from '@mui/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////

const useCalendarStyle = calendarStyle();


const Calendar = ({
    fechas,
    horas,
    setCita,
    actual,
    cita,
    division,
    diasSemana,
    agendaDiaInhabiles,
    ...props
}) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const theme = useTheme();
    const lastCol = useMediaQuery(theme.breakpoints.down('md'));
    const preLastCol = useMediaQuery(theme.breakpoints.down('sm'));
    let fechaFinal = moment(fechas[1]);
    const calendar = useCalendarStyle();
    let semana = diasSemana?.split(",");
    let semana1 = { ...horas.semana1 };
    let semana2 = { ...horas.semana2 };
    let sabado1 = { ...horas.sabado1 };
    let sabado2 = { ...horas.sabado2 };
    let domingo1 = { ...horas.domingo1 };
    let domingo2 = { ...horas.domingo2 };
    const dia = 1440;
    const [start, setStart] = useState(0);
    const [fechaActual, setFechaActual] = useState(moment(fechas[0]));
    const [fechaSemiFinal, setFechaSemiFinal] = useState(moment(fechas[0]));
    let fechaInicio = moment(fechas[0]);
    const [loading, setLoading] = useState(false);
    const [fechasFiltered, setFechasFiltered] = useState({});
    const [semanaButtons, setSemanaButtons] = useState([]);
    const [sabadoButtons, setSabadoButtons] = useState([]);
    const [domingoButtons, setDomingoButtons] = useState([]);

    const formatoMinutos = () => {
        let hora = new Date();
        let horaEnMinutos = hora.getHours() * 60;
        let total = horaEnMinutos + hora.getMinutes();
        return total;
    }

    const [horaEnMinutosActual, setHoraEnMinutosActual] = useState(formatoMinutos());

    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const getTimeFromMins = mins => {
        if (mins >= 24 * 60 || mins < 0) {
            throw new RangeError("Valid input should be greater than or equal to 0 and less than 1440.");
        }
        var h = mins / 60 | 0,
            m = mins % 60 | 0;
        return moment.utc().hours(h).minutes(m).format("hh:mm A");
    }

    const isDissable = (_fecha, _hora) => {
        if (!semana.includes(_fecha.split(" ")[0].toLowerCase())) {
            return true;
        } else {
            if (agendaDiaInhabiles.some(dia => moment(dia.fecha).format("dddd DD [de] MMMM YYYY") == _fecha)) {
                return true;
            } else {
                let citasFiltradas = cita.filter(c => c.dia == _fecha);
                if (citasFiltradas.length > 0) {
                    return citasFiltradas.some(c => c.hora == _hora);
                } else {
                    if (moment(Date()).format("dddd DD [de] MMMM YYYY") == _fecha && (horaEnMinutosActual + 60) > _hora) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        }
    }

    const classDisable = (_fecha, _minutos) => {
        if (!semana.includes(_fecha.split(" ")[0].toLowerCase())) {
            return calendar.disable;
        } else {
            if (agendaDiaInhabiles.some(dia => moment(dia.fecha).format("dddd DD [de] MMMM YYYY") == _fecha)) {
                return calendar.disable;
            } else {
                if (actual?.fecha == _fecha) {
                    if (actual?.minInicio == _minutos) {
                        return calendar.horaBtnActive;
                    } else {
                        if (isDissable(_fecha, _minutos)) {
                            return calendar.disable;
                        } else {
                            return calendar.horaBtn;
                        }
                    }
                } else {
                    if (isDissable(_fecha, _minutos)) {
                        return calendar.disable;
                    } else {
                        return calendar.horaBtn;
                    }
                }
            }
        }
    }

    const generateButton = (_fecha, _minutosInicio, _minutosFin) => {
        return <Button key={_fecha + _minutosInicio + Math.random(10000) + Math.random(10000)}
            className={classDisable(_fecha, _minutosInicio)}
            disabled={isDissable(_fecha, _minutosInicio)}
            onClick={
                e => {
                    setCita({ minInicio: _minutosInicio, minFin: _minutosFin, fecha: _fecha, fechaFormat: fechas[0] });
                }
            }
        >
            {getTimeFromMins(_minutosInicio)} - {getTimeFromMins(_minutosFin)}
        </Button>
    }

    const generateColumn = (_dia, _fecha) => {
        if (_dia == "sábado") {
            if (sabadoButtons.length > 0) {
                return (
                    <>
                        {
                            sabadoButtons.map((button, index) => {
                                return generateButton(_fecha, button.minutosInicio, button.minutosFin);
                            })
                        }
                    </>
                )

            }
        } else if (_dia == "domingo") {
            if (domingoButtons.length > 0) {
                return (
                    <>
                        {
                            domingoButtons.map((button, index) => {
                                return generateButton(_fecha, button.minutosInicio, button.minutosFin);
                            })
                        }
                    </>
                )
            }
        } else {
            if (semanaButtons.length > 0) {
                return (
                    <>
                        {
                            semanaButtons.map((button, index) => {
                                return generateButton(_fecha, button.minutosInicio, button.minutosFin);
                            })
                        }
                    </>
                )
            }
        }
    }
    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        let seButtons = [];
        let saButtons = [];
        let doButtons = [];
        let se1 = semana1.horaFinal?.split(" ")[1]?.split(":")[0] * 60 + parseInt(semana1.horaFinal?.split(" ")[1]?.split(":")[1]) - semana1.horaInicio?.split(" ")[1]?.split(":")[0] * 60 - parseInt(semana1.horaInicio?.split(" ")[1]?.split(":")[1]);
        let se2 = semana2.horaFinal?.split(" ")[1]?.split(":")[0] * 60 + parseInt(semana2.horaFinal?.split(" ")[1]?.split(":")[1]) - semana2.horaInicio?.split(" ")[1]?.split(":")[0] * 60 - parseInt(semana2.horaInicio?.split(" ")[1]?.split(":")[1]);
        let sa1 = sabado1.horaFinal?.split(" ")[1]?.split(":")[0] * 60 + parseInt(sabado1.horaFinal?.split(" ")[1]?.split(":")[1]) - sabado1.horaInicio?.split(" ")[1]?.split(":")[0] * 60 - parseInt(sabado1.horaInicio?.split(" ")[1]?.split(":")[1]);
        let sa2 = sabado2.horaFinal?.split(" ")[1]?.split(":")[0] * 60 + parseInt(sabado2.horaFinal?.split(" ")[1]?.split(":")[1]) - sabado2.horaInicio?.split(" ")[1]?.split(":")[0] * 60 - parseInt(sabado2.horaInicio?.split(" ")[1]?.split(":")[1]);
        let do1 = domingo1.horaFinal?.split(" ")[1]?.split(":")[0] * 60 + parseInt(domingo1.horaFinal?.split(" ")[1]?.split(":")[1]) - domingo1.horaInicio?.split(" ")[1]?.split(":")[0] * 60 - parseInt(domingo1.horaInicio?.split(" ")[1]?.split(":")[1]);
        let do2 = domingo2.horaFinal?.split(" ")[1]?.split(":")[0] * 60 + parseInt(domingo2.horaFinal?.split(" ")[1]?.split(":")[1]) - domingo2.horaInicio?.split(" ")[1]?.split(":")[0] * 60 - parseInt(domingo2.horaInicio?.split(" ")[1]?.split(":")[1]);
        let seInicio1 = semana1.horaInicio?.split(" ")[1]?.split(":")[0] * 60 + parseInt(semana1.horaInicio?.split(" ")[1]?.split(":")[1]);
        let seInicio2 = semana2.horaInicio?.split(" ")[1]?.split(":")[0] * 60 + parseInt(semana2.horaInicio?.split(" ")[1]?.split(":")[1]);
        let saInicio1 = sabado1.horaInicio?.split(" ")[1]?.split(":")[0] * 60 + parseInt(sabado1.horaInicio?.split(" ")[1]?.split(":")[1]);
        let saInicio2 = sabado2.horaInicio?.split(" ")[1]?.split(":")[0] * 60 + parseInt(sabado2.horaInicio?.split(" ")[1]?.split(":")[1]);
        let doInicio1 = domingo1.horaInicio?.split(" ")[1]?.split(":")[0] * 60 + parseInt(domingo1.horaInicio?.split(" ")[1]?.split(":")[1]);
        let doInicio2 = domingo2.horaInicio?.split(" ")[1]?.split(":")[0] * 60 + parseInt(domingo2.horaInicio?.split(" ")[1]?.split(":")[1]);

        for (let i = 0; i < (Math.floor(se1 / division)); i++) {
            let button = {
                minutosInicio: seInicio1 + (division * i),
                minutosFin: seInicio1 + (division * i) + parseInt(division),
            }
            seButtons.push(button);
        }

        for (let i = 0; i < (Math.floor(se2 / division)); i++) {
            let button = {
                minutosInicio: seInicio2 + (division * i),
                minutosFin: seInicio2 + (division * i) + parseInt(division),
            }
            seButtons.push(button);
        }

        if (seButtons.length > 0) {
            setSemanaButtons(seButtons);
        } else {
            setSemanaButtons([{
                minutosInicio: 0,
                minutosFin: 0,
            }]);
        }

        for (let i = 0; i < (Math.floor(sa1 / division)); i++) {
            let button = {
                minutosInicio: saInicio1 + (division * i),
                minutosFin: saInicio1 + (division * i) + parseInt(division),
            }
            saButtons.push(button);
        }

        for (let i = 0; i < (Math.floor(sa2 / division)); i++) {
            let button = {
                minutosInicio: saInicio2 + (division * i),
                minutosFin: saInicio2 + (division * i) + parseInt(division),
            }
            saButtons.push(button);
        }

        if (saButtons.length > 0) {
            setSabadoButtons(saButtons);
        } else {
            setSabadoButtons([{
                minutosInicio: 0,
                minutosFin: 0,
            }]);
        }

        for (let i = 0; i < (Math.floor(do1 / division)); i++) {
            let button = {
                minutosInicio: doInicio1 + (division * i),
                minutosFin: doInicio1 + (division * i) + parseInt(division),
            }
            doButtons.push(button);
        }

        for (let i = 0; i < (Math.floor(do2 / division)); i++) {
            let button = {
                minutosInicio: doInicio2 + (division * i),
                minutosFin: doInicio2 + (division * i) + parseInt(division),
            }
            doButtons.push(button);
        }
        if (doButtons.length > 0) {
            setDomingoButtons(doButtons);
        } else {
            setDomingoButtons([{
                minutosInicio: 0,
                minutosFin: 0,
            }]);
        }

        startFn(start);
    }, []);

    const startFn = _start => {
        setStart(_start);
        setFechaActual(moment(fechas[0]).add(_start, 'days'));
        setFechaSemiFinal(moment(moment(fechas[0]).add(_start, 'days').format("YYYY/MM/DD")));
        formatearFechas(moment(moment(fechas[0]).add(_start, 'days').format("YYYY/MM/DD")));
    }

    const formatearFechas = _fecha => {
        let fecha1 = _fecha.add(0, 'days').format("dddd DD [de] MMMM YYYY");
        let fecha2 = _fecha.add(1, 'days').format("dddd DD [de] MMMM YYYY");
        let fecha3 = _fecha.add(1, 'days').format("dddd DD [de] MMMM YYYY");
        let fecha4 = _fecha.add(1, 'days').format("dddd DD [de] MMMM YYYY");
        let fecha5 = _fecha.add(1, 'days').format("dddd DD [de] MMMM YYYY");

        setFechasFiltered({
            fecha1,
            fecha2,
            fecha3,
            fecha4,
            fecha5,
        });
        setLoading(false);
    }

    return (
        <>
            <Grid container style={{ color: 'white', padding: '20px 0' }}>
                <Grid item xs={12}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={2} md={1} lg={1} xl={1} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div>
                                    <IconButton
                                        style={{
                                            background: '#1c5e7b',
                                            margin: '10px 0px',
                                            color: '#FFF',
                                            width: '42px',
                                            height: '88px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            padding: '0 0 0 12px',
                                        }}
                                        disabled={loading || start <= 0}
                                        onClick={e => {
                                            setLoading(true);
                                            let preStart = (start - 1) < 0 ? 0 : (start - 1);
                                            setStart(preStart);
                                            startFn(preStart)
                                        }}
                                    >
                                        <ArrowBackIosIcon />
                                    </IconButton>
                                </div>
                                <div>
                                    <IconButton
                                        style={{
                                            background: '#1c5e7b',
                                            margin: '0 10px',
                                            color: '#FFF',
                                            width: '42px',
                                            height: '88px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                            padding: '0 0 0 0px',
                                        }}
                                        disabled={loading || start <= 0}
                                        onClick={e => {
                                            setLoading(true);
                                            let preStart = (start - 30) < 0 ? 0 : (start - 30);
                                            setStart(preStart);
                                            startFn(preStart)
                                        }}
                                    >
                                        <KeyboardDoubleArrowLeftIcon style={{ fontSize: "2.2rem" }} />
                                    </IconButton>
                                </div>
                            </Grid>
                            <Grid item xs={8} md={10} lg={10} xl={10} sx={{ display: "flex", flexDirection: "row", justifyItems: "center" }}>
                                <div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}>
                                        <div style={{ background: '#1c5e7b', color: '#FFF', width: '100%', padding: '20px 0', fontWeight: '600', textAlign: 'center', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}>
                                            <p>
                                                {
                                                    fechasFiltered.fecha1 && capitalizeFirst(fechasFiltered.fecha1?.split(" ")[0])
                                                }
                                            </p>
                                            <p>
                                                {
                                                    fechasFiltered.fecha1 && (fechasFiltered.fecha1?.split(" ")[1] + " " + fechasFiltered.fecha1?.split(" ")[2] + " " + fechasFiltered.fecha1?.split(" ")[3] + " " + fechasFiltered.fecha1?.split(" ")[4])
                                                }
                                            </p>
                                        </div>
                                        {
                                            generateColumn(fechasFiltered.fecha1?.split(" ")[0].toLowerCase(), fechasFiltered.fecha1)
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <div style={{ background: '#1c5e7b', color: '#FFF', width: '100%', padding: '20px 0', fontWeight: '600', textAlign: 'center' }}>
                                            <p>
                                                {
                                                    fechasFiltered.fecha2 && capitalizeFirst(fechasFiltered.fecha2?.split(" ")[0])
                                                }
                                            </p>
                                            <p>
                                                {
                                                    fechasFiltered.fecha2 && (fechasFiltered.fecha2?.split(" ")[1] + " " + fechasFiltered.fecha2?.split(" ")[2] + " " + fechasFiltered.fecha2?.split(" ")[3] + " " + fechasFiltered.fecha2?.split(" ")[4])
                                                }
                                            </p>
                                        </div>
                                        {
                                            generateColumn(fechasFiltered.fecha2?.split(" ")[0].toLowerCase(), fechasFiltered.fecha2)
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <div style={{ background: '#1c5e7b', color: '#FFF', width: '100%', padding: '20px 0', fontWeight: '600', textAlign: 'center', borderTopRightRadius: preLastCol ? '5px' : '0px', borderBottomRightRadius: preLastCol ? '5px' : '0px' }}>
                                            <p>
                                                {
                                                    fechasFiltered.fecha3 && capitalizeFirst(fechasFiltered.fecha3?.split(" ")[0])
                                                }
                                            </p>
                                            <p>
                                                {
                                                    fechasFiltered.fecha3 && (fechasFiltered.fecha3?.split(" ")[1] + " " + fechasFiltered.fecha3?.split(" ")[2] + " " + fechasFiltered.fecha3?.split(" ")[3] + " " + fechasFiltered.fecha3?.split(" ")[4])
                                                }
                                            </p>
                                        </div>
                                        {
                                            generateColumn(fechasFiltered.fecha3?.split(" ")[0].toLowerCase(), fechasFiltered.fecha3)
                                        }
                                    </div>
                                </div>
                                <Hidden smDown>
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                            <div style={{ background: '#1c5e7b', color: '#FFF', width: '100%', padding: '20px 0', fontWeight: '600', textAlign: 'center', borderTopRightRadius: lastCol ? '5px' : '0px', borderBottomRightRadius: lastCol ? '5px' : '0px' }}>
                                                <p>
                                                    {
                                                        fechasFiltered.fecha4 && capitalizeFirst(fechasFiltered.fecha4?.split(" ")[0])
                                                    }
                                                </p>
                                                <p>
                                                    {
                                                        fechasFiltered.fecha4 && (fechasFiltered.fecha4?.split(" ")[1] + " " + fechasFiltered.fecha4?.split(" ")[2] + " " + fechasFiltered.fecha4?.split(" ")[3] + " " + fechasFiltered.fecha4?.split(" ")[4])
                                                    }
                                                </p>
                                            </div>
                                            {
                                                generateColumn(fechasFiltered.fecha4?.split(" ")[0].toLowerCase(), fechasFiltered.fecha4)
                                            }
                                        </div>
                                    </div>
                                </Hidden>
                                <Hidden mdDown>
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }}>
                                            <div style={{ background: '#1c5e7b', color: '#FFF', width: '100%', padding: '20px 0', fontWeight: '600', textAlign: 'center', borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }}>
                                                <p>
                                                    {
                                                        fechasFiltered.fecha5 && capitalizeFirst(fechasFiltered.fecha5?.split(" ")[0])
                                                    }
                                                </p>
                                                <p>
                                                    {
                                                        fechasFiltered.fecha5 && (fechasFiltered.fecha5?.split(" ")[1] + " " + fechasFiltered.fecha5?.split(" ")[2] + " " + fechasFiltered.fecha5?.split(" ")[3] + " " + fechasFiltered.fecha5?.split(" ")[4])
                                                    }
                                                </p>
                                            </div>
                                            {
                                                generateColumn(fechasFiltered.fecha5?.split(" ")[0].toLowerCase(), fechasFiltered.fecha5)
                                            }
                                        </div>
                                    </div>
                                </Hidden>
                            </Grid>
                            <Grid item xs={2} md={1} lg={1} xl={1} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div>
                                    <IconButton
                                        style={{
                                            background: '#1c5e7b',
                                            margin: '10px 0px',
                                            color: '#FFF',
                                            width: '42px',
                                            height: '88px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            padding: '0',
                                        }}
                                        disabled={loading || start >= 85}
                                        onClick={e => {
                                            setLoading(true);
                                            let preStart = (start + 1) > 85 ? 85 : (start + 1);
                                            setStart(preStart);
                                            startFn(preStart)
                                        }}
                                    >
                                        <ArrowForwardIosIcon />
                                    </IconButton>
                                </div>
                                <div>
                                    <IconButton
                                        style={{
                                            background: '#1c5e7b',
                                            margin: '0 10px',
                                            color: '#FFF',
                                            width: '42px',
                                            height: '88px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                            padding: '0 0 0 0px',
                                        }}
                                        disabled={loading || start >= 85}
                                        onClick={e => {
                                            setLoading(true);
                                            let preStart = (start + 30) > 85 ? 85 : (start + 30);
                                            setStart(preStart);
                                            startFn(preStart)
                                        }}
                                    >
                                        <KeyboardDoubleArrowRightIcon style={{ fontSize: "2.2rem" }} />
                                    </IconButton>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid >
        </>
    );
}
export default Calendar;