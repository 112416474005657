import React, { useEffect, useState } from 'react'
import Graphs from '../../components/Graphs'
import Service from '../../services/api'
import moment from 'moment'
import DatePicker from "../../components/DatePickerBeta"
import { RiFileExcel2Fill } from "react-icons/ri"
import { Button, CircularProgress, IconButton } from '@mui/material'
import { baseUrl } from '../../utils/variables'
import ReplayIcon from '@mui/icons-material/Replay'

const Encuestas = () => {

    const [encuestas, setEncuestas] = useState([])
    const [cantidad, setCantidad] = useState(0)
    const [propiedades, setPropiedades] = useState({
        fechaInicio: "",
        fechaFinal: "",
    })
    const [loading, setLoading] = useState([])

    const getEncuestasFormat = _res => {
        let newRes = []
        _res?.forEach(encuesta => {
            newRes.push({ idEncuesta: encuesta?.idEncuesta, preguntas: JSON.parse(encuesta?.preguntas) })
        })
        return newRes
    }

    const getData = async () => {
        setLoading(true)
        let res = await Service.get("encuestas-zobele?fechaInicio=" + propiedades?.fechaInicio + "&fechaFinal=" + propiedades?.fechaFinal)
        let resFormat = getEncuestasFormat(res?.cuerpo?.resultado)
        setCantidad(resFormat?.length)
        let respuestas = getRespuestas(resFormat)
        setEncuestas([...respuestas])
        setLoading(false)
    }

    const getRespuestas = (_respuestas) => {
        let respuestas = []
        if (_respuestas?.length > 0) {
            let preguntas = _respuestas[0]?.preguntas
            preguntas.forEach((pregunta, ipregunta) => {
                if (pregunta?.pregunta != "Nos compartes algún comentario que te gustaría sea tomado en cuenta para mejorar nuestro servicio o bien para reforzar lo que estamos haciendo bien") {
                    let resTotales = []
                    pregunta?.respuestas?.forEach((respuesta, i) => {
                        let resTotal = _respuestas?.reduce((total, actual) => {
                            if (actual?.preguntas[ipregunta].respuestas[i]?.valor) {
                                return total + 1
                            } else {
                                return total
                            }
                        }, 0)
                        resTotales.push(resTotal)
                    })
                    respuestas.push({ pregunta: pregunta?.pregunta, respuestas: getRespuestasFormat(pregunta?.respuestas), resultados: resTotales })
                }
            })
            return respuestas
        } else {
            return respuestas
        }
    }

    const getRespuestasFormat = (_respuestas) => {
        let respuestasFormat = []
        _respuestas?.forEach(res => {
            respuestasFormat.push(res?.nombre)
        })
        return respuestasFormat
    }

    useEffect(() => {
        getData()
    }, [propiedades])

    useEffect(() => {
        console.log(encuestas)
    }, [encuestas])

    return (
        <>

            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                width: "100%",
            }}>
                <p style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "800",
                    fontSize: "29px",
                    lineHeight: "31px",
                    color: "#3A3A3A",
                }}>Resultados de <br />Encuesta de Servicio</p>
            </div>
            <div style={{
                width: "100%",
                background: "white",
                padding: "10px",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "15px 0px"
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <div style={{ paddingRight: "10px" }}>
                        <DatePicker
                            noMargin={true}
                            labelText="Fecha Inicio"
                            variant="standard"
                            fullWidth
                            typePicker="mobile"
                            inputFormat="dd/MM/yyyy"
                            size="medium"
                            value={propiedades.fechaInicio != "" ? propiedades.fechaInicio : null}
                            onChange={(e) => setPropiedades((prevState) => ({
                                ...prevState,
                                ...prevState.propiedades,
                                fechaInicio: moment(e).format("YYYY-MM-DD H:m:s"),
                            }))}
                        />
                    </div>
                    <div style={{ paddingLeft: "10px" }}>
                        <DatePicker
                            labelText="Fecha Final"
                            noMargin={true}
                            variant="standard"
                            fullWidth
                            typePicker="mobile"
                            inputFormat="dd/MM/yyyy"
                            size="medium"
                            value={propiedades.fechaFinal != "" ? propiedades.fechaFinal : null}
                            onChange={(e) => setPropiedades((prevState) => ({
                                ...prevState,
                                ...prevState.propiedades,
                                fechaFinal: moment(e).format("YYYY-MM-DD H:m:s"),
                            }))}
                        />
                    </div>
                </div>
                <div>
                    <Button
                        style={{
                            padding: "10px 50px",
                            borderRadius: "9px",
                            background: "#1d5e7b",
                            color: "white",
                        }}
                        onClick={e => {
                            window.open(`
                            ${baseUrl}excel/encuestas-zobele?fechaInicio=${propiedades?.fechaInicio}&fechaFinal=${propiedades?.fechaFinal}`, "_blank")
                        }}
                    >
                        <RiFileExcel2Fill />
                        Exportacion Excel
                    </Button>
                    <IconButton
                        style={{
                            background: "#1d5e7b",
                            margin: "0px 5px"
                        }}
                        onClick={e => { getData() }}>
                        <ReplayIcon style={{ color: "white" }} />
                    </IconButton>
                </div>
            </div>
            {

                !loading ?
                    <>
                        <div style={{
                            width: "100%",
                            background: "white",
                            padding: "20px 50px",
                            borderRadius: "12px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: "15px 0px"
                        }}>
                            <div>
                                <p
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "29px",
                                        lineHeight: "31px",
                                        color: "#1d5e7b",
                                    }}
                                >Total de Encuestas</p>
                            </div>
                            <div>
                                <div style={{
                                    width: "200px",
                                    height: "200px",
                                    borderRadius: "100%",
                                    border: "10px solid #1d5e7b",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    <p
                                        style={{
                                            fontFamily: "Montserrat",
                                            fontStyle: "normal",
                                            fontWeight: "700",
                                            fontSize: "29px",
                                            lineHeight: "31px",
                                            color: "#1d5e7b",
                                        }}
                                    >
                                        {cantidad}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {
                            encuestas?.length > 0 &&
                            encuestas?.map(pregunta => {
                                return (
                                    <div style={{
                                        width: "100%",
                                        background: "white",
                                        padding: "20px 50px",
                                        borderRadius: "12px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        margin: "15px 0px"
                                    }}>
                                        <div style={{
                                            width: "50%"
                                        }}>
                                            <p
                                                style={{
                                                    fontFamily: "Montserrat",
                                                    fontStyle: "normal",
                                                    fontWeight: "700",
                                                    fontSize: "29px",
                                                    lineHeight: "31px",
                                                    color: "#1d5e7b",
                                                }}
                                            >{pregunta?.pregunta}</p>
                                        </div>
                                        <div style={{
                                            width: "50%"
                                        }}>
                                            <Graphs
                                                datos={pregunta?.respuestas}
                                                cantidad={pregunta?.resultados}
                                                title={"Encuestas"}
                                                colors={['#1D5E7B']}
                                                subTitle={'Encuestas'}
                                                typeGraph="bar"
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
                    :
                    <>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}><CircularProgress style={{ color: "#1d5e7b" }} /></div>
                    </>
            }
        </>
    )
}

export default Encuestas