////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo, Fragment } from 'react'
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import CircleTransition from './circleTrasition';
import ProgressBar from './progressBar';
import {
    CircularProgress,
    LinearProgress,
    Grid,
} from '@mui/material';

////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////


const StepWizard = ({
    steps = [],
    currentStep = 0,
    ...props
}) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const [step, setStep] = useState(steps[currentStep]);
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////
    
    useEffect( () => {
        if(currentStep != steps.length){
            setStep(steps[currentStep])
        }else{
            
        }
    },[currentStep])


    return (
        <>
            <Grid container spacin={2}>
                <Grid item xs={12}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {
                            steps && steps.map((step, i) => {
                                if (i < steps.length - 1) {
                                    return (
                                        < Fragment key={i+"C"}>
                                            <CircleTransition
                                                icon={step.icon}
                                                iconActive={step.iconActive}
                                                active={step.active}
                                            />
                                            <ProgressBar value={step.value} />
                                        </Fragment >
                                    );
                                } else if (i == (steps.length - 1)) {
                                    return (
                                        <CircleTransition
                                            key={i+"D"}
                                            icon={step.icon}
                                            iconActive={step.iconActive}
                                            active={step.active}
                                        />
                                    );
                                }
                            })
                        }
                    </div>
                </Grid>
                <Grid item xs={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    {step.container}
                </Grid>
            </Grid>
        </>
    );
}
export default StepWizard;