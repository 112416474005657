/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
//React
import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/variables";
import { baseStyles, capitalizeFirst } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import { Grid, Menu, MenuItem, Switch, FormControlLabel, TextField, Avatar, FormControl, InputLabel, Select } from "@mui/material";
import { useModels } from "../../hooks/useModels";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import Modal from "react-modal";
//----------------------------------------------------------------------------------------------------------------------

//Librerias
import Swal from "sweetalert2";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import { Add, Delete, Edit, Search } from "@mui/icons-material";
import { FaUserInjured } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { NavLink } from "react-router-dom";
//----------------------------------------------------------------------------------------------------------------------

const useStyles = baseStyles();

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "9999",
  },
};
Modal.setAppElement(document.getElementById("#modal"));
const Index = () => {
  //Estados Iniciales
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [pagina, setPagina] = useState(1);
  const [openMenuRow, setOpenMenuRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [imgProductoModal, setImgProductoModal] = useState("");
  const [admin] = useLocalStorage("admin", null);
  const { user, permiso } = useAuth();
  const [modalIsOpen, setIsOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  //----------------------------------------------------------------------------------------------------------------------

  //Estados Iniciales Controller
  const header = [
    { prop: "ine", name: "I.N.E.", sortable: true },
    { prop: "nombreCompleto", name: "Nombre completo", sortable: true },
    { prop: "genero", name: "Género", sortable: true, },
    { prop: "telefono", name: "Teléfono", sortable: true },
    { prop: "correo", name: "Correo", sortable: true },
    { prop: "direccion", name: "Dirección", sortable: false },
  ];

  const [propiedades, setPropiedades] = useState({
    ine: "",
    genero: "",
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    nombreCompleto: "",
    estadoCivil: "",
    correo: "",
    direccion: "",
    telefono: "",
    urlFoto: "",
    fechaNacimiento: "",
    eliminado: 0,
  });

  const parametrosInicialesMemo = useMemo(() => ({ name: "paciente", ordenar: "idPaciente.desc" }), []);
  const [models, modelsLoading, modelsError, modelsPage, refreshModels, deleteModel,] = useModels({ ...parametrosInicialesMemo });
  //----------------------------------------------------------------------------------------------------------------------
  //Funciones

  const onSearchClicked = async (eliminado) => {
    let params = {};
    if (eliminado != null) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
      };
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage },
      };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async (page) => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async (per) => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  const onDeleteClicked = async (row, type) => {
    try {
      setOpenMenuRow(false);
      await Swal.fire({
        title: type,
        text: `¿Desea ${type} el dato seleccionado  "${capitalizeFirst(
          row.nombres.toLowerCase()
        )}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34AA44",
        cancelButtonColor: "#E72C33",
        confirmButtonText: "Si, deseo Continuar.",
      }).then((result) => {
        if (result.isConfirmed) {
          let params = {};
          params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: pagina, limite: perPage },
          };
          deleteModel(row.idPaciente, type, params);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  function openModal() {
    setIsOpen(true);
  };
  function closeModal() {
    setIsOpen(false);
  };
  //----------------------------------------------------------------------------------------------------------------------
  //Efectos
  //----------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div id="modal" style={{ zIndex: "99" }}>
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal" ariaHideApp={false}>
          <div style={{ backgroundColor: "#FFF" }}>
            <img style={{ maxHeight: '600px', minHeight: '400px', width: '100%' }} src={imgProductoModal} alt="imgProductoModal" />
          </div>
        </Modal>
      </div>
      <TablePageLayout title="Recepcion de Pacientes" model="paciente" history={history}
        actionButton={{ to: "/paciente/nuevo", icon: <Add />, label: "Agregar Paciente", permiso: "pacientes" }}
        links={[{ label: "Recepcion Pacientes", icon: <FaUserInjured fontSize="large" /> }]}
        SearchComponents={
          <>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                size="small"
                label="Nombre"
                variant="standard"
                onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  nombreCompleto: e.target.value
                }))}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputRegex
                label="I.N.E."
                fullWidth
                typeInput="text"
                maxLength={50}
                value={propiedades.ine}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    ine: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputRegex
                label="Teléfono"
                fullWidth
                typeInput="phone"
                maxLength={10}
                value={propiedades.telefono}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    telefono: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                size="small"
                label="Correo"
                variant="standard"
                onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  correo: e.target.value
                }))}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="tipoSelect">Estado Civil</InputLabel>
                <Select
                  labelId="tipoSelect"
                  fullWidth
                  label="Genero"
                  onChange={
                    (e) => {
                      setPropiedades(prevState => ({
                        ...prevState,
                        ...prevState.propiedades,
                        estadoCivil: e.target.value
                      }));
                    }
                  }
                  inputProps={{
                    value: propiedades.estadoCivil,
                  }}
                >
                  <MenuItem value={""}>TODOS</MenuItem>
                  <MenuItem value={"SOLTERO(A)"}>SOLTERO(A)</MenuItem>
                  <MenuItem value={"CASADO(A)"}>CASADO(A)</MenuItem>
                  <MenuItem value={"DIVORCIADO(A)"}>DIVORCIADO(A)</MenuItem>
                  <MenuItem value={"VIUDO(A)"}>VIUDO(A)</MenuItem>
                  <MenuItem value={"CONCUBINATO(A)"}>CONCUBINATO(A)</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputRegex
                label="Tipo de sangre"
                variant="standard"
                color="primary"
                fullWidth
                typeInput="text"
                maxLength={50}
                value={propiedades.tipoSangre}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    tipoSangre: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={8}></Grid>

            <Grid item xs={12} sm={4}>
              <LoadingButton
                variant="contained"
                onClick={() => onSearchClicked(null)}
                loading={modelsLoading && true}
                style={{ float: "right", color: "whitesmoke", justifyContent: "flex-end" }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>
          </>
        }
        DisableComponents={
          <>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={modelsLoading && true}
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        eliminado: e.target.checked ? 1 : 0,
                      }));
                      onSearchClicked(e.target.checked ? 1 : 0);
                    }}
                  />
                }
                label="Ver Deshabilitados"
              />
            </Grid>

            <Grid item xs={4} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }} >
              <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                <IconButton color="error" aria-label="upload picture" component="span" >
                  <a
                    className={classes.btnPdf}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}pdf/paciente?${"admin=" + (admin ? 1 : 0)}${"&uid=" + user?.idUsuario}${"&nombreCompleto=" + propiedades.nombreCompleto}${"&ine=" + propiedades.ine}${"&telefono=" + propiedades.telefono}${"&correo=" + propiedades.correo}${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`} >
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                <IconButton color="warning" aria-label="upload picture" component="span">
                  <a className={classes.btnExcel} target="_blank" rel="noreferrer"
                    href={`${baseUrl}excel/paciente?${"admin=" + (admin ? 1 : 0)}${"&uid=" + user?.idUsuario}${"&nombreCompleto=" + propiedades.nombreCompleto}${"&ine=" + propiedades.ine}${"&telefono=" + propiedades.telefono}${"&correo=" + propiedades.correo}${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`} >
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <TablePagination
            nombrePermiso="pacientes"
            permiso={permiso}
            header={header}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "urlFoto",
                name: "Foto",
                sortable: false,
                cell: (row, index) => (
                  <div>
                    <Avatar
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setImgProductoModal(row.urlFoto);
                        openModal();
                      }}
                      alt={row.nombre}
                      src={row.urlFoto}
                    />
                  </div>
                ),
              },
              {
                prop: "acciones",
                name: "Acciones",
                sortable: false,
                cell: (row, index) => (permiso && (permiso[0]["pacientes"] & 4) === 4 || (permiso[0]["pacientes"] & 8) === 8) && (
                  <div>
                    <IconButton onClick={(event) => { setOpenMenuRow(index); setAnchorEl(event.currentTarget) }}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu anchorEl={anchorEl} keepMounted open={index === openMenuRow} onClose={() => setOpenMenuRow(null)}>
                      {(admin === true || (permiso && permiso[0]["pacientes"] & 4) === 4) && (row.eliminado == null) && (
                        <NavLink exact to={(`paciente/editar/${row.idPaciente}`)} style={{ color: '#000', textDecoration: 'none' }}>
                          <MenuItem aria-label="editar"
                            onClick={() => {
                              history.push(`paciente/editar/${row.idPaciente}`, { match: row.idPaciente });
                            }}>
                            <Edit style={{ paddingRight: 5 }} />
                            Editar
                          </MenuItem>
                        </NavLink>
                      )}
                      {(admin === true || (permiso && permiso[0]["pacientes"] & 8) === 8) && (
                        row?.eliminado === null ? (
                          <MenuItem
                            onClick={() => onDeleteClicked(row, "Deshabilitar")}
                          >
                            <Delete style={{ paddingRight: 5 }} />
                            Deshabilitar
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={() => onDeleteClicked(row, "Habilitar")}
                          >
                            <RestoreFromTrashIcon style={{ paddingRight: 5 }} />
                            Habilitar
                          </MenuItem>
                        )
                      )}
                    </Menu>
                  </div>
                )
              },
            ]}
          />
        }
      />
    </>
  );
};
export default Index;
