const { NODE_ENV: env } = process.env;

const baseUrl =
    env === "development"
        ? "http://localhost:8080/v1/"
        : "https://backend.lhospitalito.com/v1/";

const baseUrlPublica =
    env === "development"
        ? "http://localhost:8080/"
        : "https://backend.lhospitalito.com/";

const frontUrl =
    env === "development"
        ? "http://localhost:3000/"
        : "https://react-clinica.web.app/";

export { baseUrl, frontUrl, baseUrlPublica };

