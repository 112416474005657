/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useRef } from "react";
import { useModel } from "../../hooks/useModel";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { useAlert } from "../../hooks/useAlert";
import FirebaseService from "../../services/firebase";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import PageHeader from "../../components/PageHeader";
import { Grid, Paper, TextField, Input } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import moment from "moment";
import Services from "../../services/api";
import { makeStyles } from "@mui/styles";
import { baseUrlPublica } from "../../utils/variables";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { capitalizeFirst } from "../../utils";
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Edit, MedicationLiquid } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

const RecetasDetail = ({ match, history, dialog }) => {
  ////////////////////////////////////////////////////////////////////////
  //                              Vars                                  //
  ////////////////////////////////////////////////////////////////////////
  const id = match.params.id;
  let isNull = false;
  const classes = useStyles();
  const receta =
    "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2FplantillaClinica.jpg?alt=media&token=9f783507-10b7-4222-bd87-b7b2414a5b95";
  const recetaHospitalito =
    "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2FplatillaLhospitalito.jpg?alt=media&token=d5717454-2656-439c-a65c-7d5ab57913d5";
  const { showAlert } = useAlert();
  const editing = !!id;
  const [admin] = useLocalStorage("admin", null);
  const { permiso, user } = useAuth();
  const rolRef = useRef();
  const [rol, setRol] = useState("");
  const hoy = moment(new Date()).format("DD/MM/yyyy");
  const docRef = useRef();
  const [cargando, setCargando] = useState(false);
  const [firma, setFirma] = useState(
    "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/DoctoresDetalles%2Ffirma_default.png?alt=media&token=c657d017-f580-487d-b518-f79db11a76db"
  );
  const [loaded, setLoaded] = useState(false);
  const [isFirma, setIsFirma] = useState(false);
  const [detalles, setDetalles] = useState({
    ssa: "",
    dgp: "",
    cp: "",
    cpe: "",
    rol: "",
    ced: "",
    ssp: "",
    urlFile: "",
    archivo: "",
  });
  const [detalles2, setDetalles2] = useState({
    ssa: "",
    dgp: "",
    cp: "",
    cpe: "",
    rol: "",
    ced: "",
    ssp: "",
    urlFile: "",
    archivo: "",
  });
  const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
  const [propiedades, setPropiedades] = useState({
    edad: "",
    paciente: "",
    contenido: "",
    idSucursal,
  });
  const [errores, setErrores] = useState(null);
  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "receta",
      expand: "detalleDoctor",
      id,
      redirectOnPost: true,
    });
  ////////////////////////////////////////////////////////////////////////
  //                           Funcions Def                             //
  ////////////////////////////////////////////////////////////////////////
  const onGuardar = async () => {
    if (detalles.archivo != "" || isFirma) {
      let url = "";
      let body = {
        ...propiedades,
        rol,
        cp: detalles.cp == "" ? "null" : detalles.cp,
        ssa: detalles.ssa == "" ? "null" : detalles.ssa,
        dgp: detalles.dgp == "" ? "null" : detalles.dgp,
        cpe: detalles.cpe == "" ? "null" : detalles.cpe,
        ssp: detalles.ssp == "" ? "null" : detalles.ssp,
        ced: detalles.ced == "" ? "null" : detalles.ced,
      };
      if (isFirma) {
        body = { ...body, urlFile: firma };
      } else {
        if (detalles.archivo) {
          const ranm = Math.floor(Math.random() * 1000);
          const paciente = body.paciente.toLowerCase();
          const pathFirebase =
            "DoctoresDetalles/" + "Imagen" + "_" + paciente + ranm;
          url = await FirebaseService.uploadFile(
            pathFirebase,
            detalles.archivo
          );
          body = { ...body, urlFile: url };
        }
      }
      if (editing) {
        body.idReceta = id;
      } else {
        setCargando(true);
        const res = await Services.post("receta/guardar", body);
        setErrores(res?.errores);
        if (res?.errores == null) {
          await Services.getPDF(
            `pdf/receta?${"id=" + res.detalle.id}${"&folio=" + res.detalle.folio
            }`
          );
          setCargando(false);
          showAlert({
            message: "Receta guardada correctamente",
            severity: "success",
          });
          history.push("/recetas");
        } else {
          setCargando(false);
          showAlert({ message: "Error al guardar receta", severity: "error" });
        }
      }
    } else {
      showAlert({ message: "Debe seleccionar una Firma", severity: "error" });
    }
  };
  const getDetalleDoctor = async (idUsuario) => {
    const res = await Services.get("detalle-doctor?idCreador=" + idUsuario);
    if (res.resultado.length > 0) {
      setFirma(res.resultado[0].urlFile);
      setIsFirma(true);
      setDetalles({ ...detalles, ...res.resultado[0] });
      setDetalles2({ ...detalles, ...res.resultado[0] });
    }
    setLoaded(true);
  };
  ////////////////////////////////////////////////////////////////////////
  //                            Hooks Def                               //
  ////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setPropiedades(model);
    }
    return () => {
      mounted = false;
      setPropiedades({
        edad: "",
        paciente: "",
        contenido: "",
        idSucursal,
      });
    };
  }, [model]);
  useEffect(() => {
    getDetalleDoctor(user.idUsuario);
    Object.keys(permiso[0]).map((key) => {
      if (key[key.length - 1] !== "s") {
        if (key != "idSucursal" && key != "idUsuario") {
          if ((permiso[0][key] & 1) === 1) {
            if (key == "medico") {
              setRol("MÉDICO GENERAL");
            } else if (key == "ocupacional") {
              setRol("MÉDICO OCUPACIONAL");
            } else if (key == "neurocirujano") {
              setRol("NEUROCIRUJANO");
            } else if (key == "radiologo") {
              setRol("MÉDICO RADIÓLOGO");
            }
          }
        }
      }
    });
  }, []);

  return (
    <>
      <PageHeader
        history={history}
        title={(!editing ? "NUEVA" : "EDITAR") + " RECETA"}
        links={[
          {
            to: "/recetas",
            icon: <MedicationLiquid fontSize="small" />,
            label: "Recetas",
          },
          {
            to: !editing ? "/receta/nuevo" : "/receta/editar/" + id,
            icon: !editing ? <Add /> : <Edit />,
            label: !editing
              ? "Nuevo"
              : "Editar - " +
              capitalizeFirst(propiedades?.paciente.toLowerCase()),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative", width: "700px" }}>
            <img
              style={{ width: "100%" }}
              src={detalles2?.receta}
              alt="Receta"
            />
            <p
              style={{
                position: "absolute",
                left: "558px",
                top: "314px",
                right: "56px",
              }}
            >
              {hoy}
            </p>
            <div style={{
              width: "100%",
              height: "100px",
              position: "absolute",
              background: "white",
              top: "248px",
            }}>

            </div>
            <TextField
              label="Paciente:"
              style={{
                position: "absolute",
                left: "56px",
                top: "331px",
                right: "210px",
              }}
              variant="standard"
              helperText={errores?.paciente}
              error={Boolean(errores?.paciente)}
              value={propiedades.paciente}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  paciente: e.target.value,
                }))
              }
            />
            <InputRegex
              label="Edad:"
              typeInput="number"
              style={{
                position: "absolute",
                left: "507px",
                top: "331px",
                right: "62px",
              }}
              variant="standard"
              value={propiedades.edad.toString()}
              helperText={errores?.edad}
              error={Boolean(errores?.edad)}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  edad: e.target.value,
                }))
              }
            />
            <TextField
              label="Receta:"
              multiline
              helperText={errores?.contenido}
              error={Boolean(errores?.contenido)}
              rows={13}
              style={{
                position: "absolute",
                left: "56px",
                top: "390px",
                right: "56px",
              }}
              value={propiedades.contenido}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  contenido: e.target.value,
                }))
              }
            />
            <label
              style={{
                position: "absolute",
                top: "721px",
                left: "405px",
                right: "0",
                botton: "58px",
                cursor: "pointer",
              }}
              htmlFor="icon-button-file"
            >
              <Input
                onChange={(e) => {
                  setFirma(URL.createObjectURL(e.target.files[0]));
                  setIsFirma(false);
                  setDetalles({ ...detalles, archivo: e.target.files[0] });
                  setDetalles2({ ...detalles, archivo: e.target.files[0] });
                }}
                style={{ display: "none" }}
                accept="image/*"
                id="icon-button-file"
                type="file"
                disabled={editing}
              />
              <img
                style={{
                  width: "300px",
                  height: "125px",
                  objectFit: "contain",
                }}
                src={firma}
                alt={"firma"}
              />
            </label>
          </div>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <LoadingButton
                disabled={
                  modelLoading ||
                    updateModelLoading ||
                    admin ||
                    (permiso && permiso[0]["recetas"] & 2) === 2
                    ? false
                    : true
                }
                loading={modelLoading || updateModelLoading || cargando}
                variant="contained"
                onClick={onGuardar}
                size="medium"
                startIcon={<SaveIcon />}
                style={{ color: "#fff" }}
              >
                {!editing ? "GUARDAR" : "MODIFICAR"}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default RecetasDetail;
