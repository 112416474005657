/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
//React
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/variables";
import { baseStyles, capitalizeFirst } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { useNotifications } from "../../hooks/useNotifications";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import {
  Grid,
  Menu,
  MenuItem,
  Switch,
  Avatar,
  Modal,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useModels } from "../../hooks/useModels";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import { firestore } from "../../firebase/firebaseConfig";

//----------------------------------------------------------------------------------------------------------------------

//Librerias
import Swal from "sweetalert2";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import {
  Delete,
  Edit,
  Search,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
//----------------------------------------------------------------------------------------------------------------------

const useStyles = baseStyles();

const Index = () => {
  //Estados Iniciales
  const classes = useStyles();
  
  const { setNotifications, conteo} = useNotifications();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [perPage, setPerPage] = React.useState(10);
  const [pagina, setPagina] = React.useState(1);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const [admin] = useLocalStorage("admin", null);
  const { user, permiso } = useAuth();
  //----------------------------------------------------------------------------------------------------------------------

  //Estados Iniciales Controller
  const header = [
    {
      prop: "correo",
      name: "Correo",
    },
    {
      prop: "uid",
      name: "Folio",
    },
  ];

  const [propiedades, setPropiedades] = useState({
    correo: "",
    uid:"",
    vigente: "Todo",
    eliminado: 0,
  });

  const parametrosInicialesMemo = useMemo(
    () => ({
      name: "correo-promocion",
      ordenar: "idCorreoPromocion.desc",
    }),
    []
  );

  const reset = useCallback(async ()=>{
      await firestore
      .collection("alertas")
      .doc("JBySo0vNelJRFIgYgy3r")
      .set({ correoPromocion: 0, show: false}, { merge: true });
      setNotifications(0);
   },[setNotifications])

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({ ...parametrosInicialesMemo });

  //----------------------------------------------------------------------------------------------------------------------

  //Funciones

  const onSearchClicked = async (eliminado) => {
    let params = {};
    if (eliminado != null) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
      };
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage },
      };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async (page) => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async (per) => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  const onDeleteClicked = async (row, type) => {
    try {
      setOpenMenuRow(false);
      await Swal.fire({
        title: type,
        text: `¿Desea ${type} el dato seleccionado  "${capitalizeFirst(
          row.correo.toLowerCase()
        )}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34AA44",
        cancelButtonColor: "#E72C33",
        confirmButtonText: "Si, deseo Continuar.",
      }).then((result) => {
        if (result.isConfirmed) {
          let params = {};
          params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: pagina, limite: perPage },
          };
          deleteModel(row.idCorreoPromocion, type, params);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onVigenteClicked = async (row, type) => {
    try {
      setOpenMenuRow(false);
      await Swal.fire({
        title: "Vigencia",
        text: `¿Desea dar de baja la vigencia de la promoción seleccionada  "${capitalizeFirst(
          row.correo.toLowerCase()
        )}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34AA44",
        cancelButtonColor: "#E72C33",
        confirmButtonText: "Si, deseo Continuar.",
      }).then((result) => {
        if (result.isConfirmed) {
          let params = {};
          params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: pagina, limite: perPage },
          };
          deleteModel(row.idCorreoPromocion, type, params);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //----------------------------------------------------------------------------------------------------------------------

  //Efectos
  useEffect(() => {
    reset();
  },[]);
  //----------------------------------------------------------------------------------------------------------------------

  return (
      <TablePageLayout
        title="Correo Promoción"
        model="correo-promocion"
        history={history}
        links={[
          {
            label: "Correo Promociones",
            icon: <MarkunreadMailboxIcon fontSize="small"/>,
          },
        ]}
        SearchComponents={
          <>
            <Grid item xs={12} sm={3}>
              <InputRegex
                label="Correo"
                fullWidth
                typeInput="text"
                maxLength={50}
                value={propiedades.correo}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    correo: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputRegex
                label="Folio"
                fullWidth
                typeInput="text"
                maxLength={150}
                value={propiedades.uid}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    uid: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="tipoSelect">Vigencia</InputLabel>
                <Select
                  style={{textAlign: "left"}}
                  labelId="vigencia"
                  fullWidth
                  label="Todo"
                  value={propiedades.vigente}
                  onChange={(e) => {
                    setPropiedades(prevState => ({
                      ...prevState,
                      ...prevState.propiedades,
                      vigente: e.target.value
                    }));
                   
                  }
                  }
                >
                  <MenuItem value={"Todo"}>Todos</MenuItem>
                  <MenuItem value={0}>Vigente</MenuItem>
                  <MenuItem value={1}>No vigente</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <LoadingButton
                variant="contained"
                onClick={() => onSearchClicked(null)}
                loading={modelsLoading && true}
                style={{
                  float: "right",
                  color: "whitesmoke",
                }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>
          </>
        }
        DisableComponents={
          <>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={modelsLoading && true}
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        eliminado: e.target.checked ? 1 : 0,
                      }));
                      onSearchClicked(e.target.checked ? 1 : 0);
                    }}
                  />
                }
                label="Ver Deshabilitados"
              />
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="error"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnPdf}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}pdf/correo-promocion?${
                      "admin=" + (admin ? 1 : 0)
                    }${"&uid=" + user?.idUsuario}${
                      "&correo=" + propiedades.correo
                    }${"&uid=" + propiedades.uid}${
                      "&vigente=" + propiedades.vigente
                    }${
                      "&eliminado=" + (propiedades.eliminado ? 1 : 0)
                    }`}
                  >
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="warning"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnExcel}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}excel/correo-promocion?${
                      "admin=" + (admin ? 1 : 0)
                    }${"&uid=" + user?.idUsuario}${
                      "&correo=" + propiedades.correo
                    }${"&uid=" + propiedades.uid}${
                      "&vigente=" + propiedades.vigente
                    }${
                      "&eliminado=" + (propiedades.eliminado ? 1 : 0)
                    }`}
                  >
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <TablePagination
            nombrePermiso="correosPromociones"
            permiso={permiso}
            header={header}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "vigente",
                name: "Vigente",
                sortable: false,
                cell: (row, index) =>
                    <Switch
                      disabled={row && (row?.eliminado !== null || row?.vigente !== null)  ? true : false}
                      checked={row && row?.vigente === null ? true : false}
                      onChange={(e) => {
                        onVigenteClicked(row, "Vigente");
                      }}
                    />,
              },
              {
                prop: "acciones",
                name: "Acciones",
                sortable: false,
                cell: (row, index) => (permiso && (permiso[0]["correosPromociones"] & 4) === 4 || (permiso[0]["correosPromociones"] & 8) === 8) && (
                  <div>
                    <IconButton onClick={(event) => { setOpenMenuRow(index); setAnchorEl(event.currentTarget) }}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu anchorEl={anchorEl} keepMounted open={index === openMenuRow} onClose={() => setOpenMenuRow(null)}>
                      {(admin === true || (permiso && permiso[0]["correosPromociones"] & 4) === 4) && (row.eliminado == null) && (
                        <NavLink exact to={(`paquete/editar/${row.idPaquete}`)} style={{ color: '#000', textDecoration: 'none' }}>
                          <MenuItem aria-label="editar"
                            onClick={() => {
                              history.push(`paquete/editar/${row.idPaquete}`, { match: row.idPaquete });
                            }}>
                            <Edit style={{ paddingRight: 5 }} />
                            Editar
                          </MenuItem>
                        </NavLink>
                      )}
                      {(admin === true || (permiso && permiso[0]["correosPromociones"] & 8) === 8) && (
                        row?.eliminado === null ? (
                          <MenuItem
                            onClick={() => onDeleteClicked(row, "Deshabilitar")}
                          >
                            <Delete style={{ paddingRight: 5 }} />
                            Deshabilitar
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={() => onDeleteClicked(row, "Habilitar")}
                          >
                            <RestoreFromTrashIcon style={{ paddingRight: 5 }} />
                            Habilitar
                          </MenuItem>
                        )
                      )}
                    </Menu>
                  </div>
                ),
              },
            ]}
          />
        }
      />
  );
};
export default Index;
