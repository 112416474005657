////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useMemo, useState } from "react";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import PageHeader from "../../../components/PageHeader";
import { Grid, Paper, FormControlLabel, Switch, CircularProgress } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";
import Service from "../../../services/api";
import { useAuth } from '../../../hooks/useAuth';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { capitalizeFirst } from "../../../utils";
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Edit } from "@mui/icons-material";
import { FaClinicMedical } from "react-icons/fa";
import FichaIdentificacion from "./pestana-botones/fichaIdentificacion";
import { useModels } from "../../../hooks/useModels";
import { useAlert } from "../../../hooks/useAlert";
import { Save } from "@mui/icons-material";

import {
  SignosVitalesSomatometriaComponent,
  NotasEnfermeriaComponent,
  AntecedentesComponent,
  InterrogatorioPorAparatosSistemasComponent,
  NotasEvolucionComponent,
  PadecimientoActualComponent,
  ExploracionFisicaComponent,
  PlanComponent,
  DiagnosticoComponent,
  ParaclinicosComponent,
} from "./pestana-botones";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { RecetasComponent } from "./pestana-botones/recetasComponent";
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

const Detail = ({ match, history, dialog }) => {
  ////////////////////////////////////////////////////////////////////////
  //                              Vars                                  //
  ////////////////////////////////////////////////////////////////////////
  const id = match.params.id;
  const classes = useStyles();
  const editing = !!id;
  const [admin] = useLocalStorage('admin', null);
  const { user, permiso } = useAuth();
  const { showAlert } = useAlert();
  const [disabled, setDisabled] = useState(true);
  const [accion, setAccion] = useState(null);
  const [idEvaluacion, setIdEvaluacion] = useState(null);
  const [idOrdenServicio, setIdOrdenServicio] = useState(id);
  const [evaluacion, setEvaluacion] = useState(null);
  const [guardado, setGuardado] = useState(true);
  const [detallePaciente, setDetallePaciente] = useState(false);
  const [loading, setLoading] = useState(false);

  const finalizarEvaluacion = async () => {
    let res = await Service.post("evaluaciones/guardar", {
      idOrdenServicio,
      funcion: "finalizar",
    });
    if (res.detalle.estatus == 200) {
      showAlert({
        message: res.mensaje,
        severity: "success",
      });
      history.push("/hospitalito");
    } else {
      showAlert({
        message: res.mensaje,
        severity: "error",
      });
    }
  };

  let botonesDefault = [];

  if (permiso && permiso[0]["doctor"] > 0) {
    botonesDefault = [
      {
        icon: null,
        value: "SignosVitalesSomatometriaComponent",
        title: "Signos Vitales y Somatometría",
        on: true,
      },
      {
        icon: null,
        value: "RecetasComponent",
        title: "Receta",
        on: true,
      },
      {
        icon: null,
        value: "NotasEnfermeriaComponent",
        title: "Notas Enfermería",
        on: true,
      },
      {
        icon: null,
        value: "AntecedentesComponent",
        title: "Antecedentes",
        on: true,
      },
      {
        icon: null,
        value: "InterrogatorioPorAparatosSistemasComponent",
        title: "Interrogatorio Por Aparatos y Sistemas",
        on: true,
      },
      {
        icon: null,
        value: "NotasEvolucionComponent",
        title: "Notas Evolución",
        on: true,
      },
      {
        icon: null,
        value: "PadecimientoActualComponent",
        title: "Padecimiento Actual",
        on: true,
      },
      {
        icon: null,
        value: "ExploracionFisicaComponent",
        title: "Exploración Física",
        on: true,
      },
      { icon: null, value: "PlanComponent", title: "Plan", on: true },
      {
        icon: null,
        value: "DiagnosticoComponent",
        title: "Diagnóstico",
        on: true,
      },
      {
        icon: null,
        value: "ParaclinicosComponent",
        title: "Paraclinicos",
        on: true,
      },
    ];
  }

  const getOrdenServicio = async () => {
    setLoading(true);
    if (idOrdenServicio) {
      let res = await Service.get(
        "orden-servicio?idOrdenServicio=" +
        idOrdenServicio +
        "&expand=evaluacion.signos" +
        ",evaluacion.notasEnfermeria" +
        ",evaluacion.notasEvolucion" +
        ",evaluacion.antecedente" +
        ",evaluacion.interrogatorio" +
        ",evaluacion.padecimiento" +
        ",evaluacion.exploracion" +
        ",evaluacion.plan" +
        ",evaluacion.receta" +
        ",evaluacion.diagnostico.diagnosticoEnfermedad.enfermedad" +
        ",detallePaciente" +
        ",evaluacion.paraclinicos.estudios"
      );
      setEvaluacion(res?.resultado[0]?.evaluacion);
      setDetallePaciente(res?.resultado[0]?.detallePaciente);
    }
    setLoading(false);
  };

  const [botones, setBotones] = useState([...botonesDefault]);

  useEffect(() => {
    getOrdenServicio();
  }, [accion]);

  useEffect(() => {
    console.log(guardado)
  }, [guardado]);

  useEffect(() => {
    if (botones) {
      let falla = false;
      let botonesCount = 0;
      botones.forEach((boton) => {
        if (!falla) {
          if (boton.on) {
            botonesCount++;
            if (!boton.validado) {
              falla = true;
            }
          }
        }
      });
      if (botonesCount > 0) {
        setDisabled(falla);
      }
    }
  }, [botones]);

  const setDisabledByName = (name, validacion) => {
    if (botones) {
      let preBotones = [...botones];
      preBotones.map((boton) => {
        if (boton.value == name) {
          boton.validado = validacion;
        }
      });
      setBotones(preBotones);
    }
  };



  ////////////////////////////////////////////////////////////////////////
  //                           Funcions Def                             //
  ////////////////////////////////////////////////////////////////////////
  const switchCaseOptions = (boton) => {
    switch (boton?.value) {
      case "SignosVitalesSomatometriaComponent":
        return (
          <SignosVitalesSomatometriaComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            signos={evaluacion ? evaluacion.signos : null}
            setDisabledByName={setDisabledByName}
          />
        );

      case "RecetasComponent":
        return (
          <RecetasComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            guardado={guardado}
            receta={evaluacion ? evaluacion?.receta : null}
            setDisabledByName={setDisabledByName}
          />
        );

      case "NotasEnfermeriaComponent":
        return (
          <NotasEnfermeriaComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            notasEnfermeria={evaluacion ? evaluacion.notasEnfermeria : null}
            setDisabledByName={setDisabledByName}
          />
        );

      case "AntecedentesComponent":
        return (
          <AntecedentesComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            antecedente={evaluacion ? evaluacion.antecedente : null}
            setDisabledByName={setDisabledByName}
          />
        );

      case "InterrogatorioPorAparatosSistemasComponent":
        return (
          <InterrogatorioPorAparatosSistemasComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            interrogatorio={evaluacion ? evaluacion.interrogatorio : null}
            setDisabledByName={setDisabledByName}
          />
        );

      case "NotasEvolucionComponent":
        return (
          <NotasEvolucionComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            notasEvolucion={evaluacion ? evaluacion.notasEvolucion : null}
            setDisabledByName={setDisabledByName}
          />
        );

      case "PadecimientoActualComponent":
        return (
          <PadecimientoActualComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            padecimiento={evaluacion ? evaluacion.padecimiento : null}
            setDisabledByName={setDisabledByName}
          />
        );

      case "ExploracionFisicaComponent":
        return (
          <ExploracionFisicaComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            exploracion={evaluacion ? evaluacion.exploracion : null}
            setDisabledByName={setDisabledByName}
          />
        );

      case "PlanComponent":
        return (
          <PlanComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            plan={evaluacion ? evaluacion.plan : null}
            setDisabledByName={setDisabledByName}
          />
        );

      case "DiagnosticoComponent":
        return (
          <DiagnosticoComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            diagnostico={evaluacion ? evaluacion.diagnostico : null}
            setDisabledByName={setDisabledByName}
          />
        );

      case "ParaclinicosComponent":
        return (
          <ParaclinicosComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            paraclinicos={evaluacion ? evaluacion.paraclinicos : null}
            setDisabledByName={setDisabledByName}
          />
        );

      default:
        return <></>;
    }
  };
  ////////////////////////////////////////////////////////////////////////
  //                            Hooks Def                               //
  ////////////////////////////////////////////////////////////////////////

  return (
    <>
      <PageHeader
        history={history}
        title={(!editing ? "NUEVA" : "EDITAR") + " EVALUACIÓN MEDICA"}
        links={[
          {
            to: "/hospitalito",
            icon: <FaClinicMedical fontSize="medium" />,
            label: "L HOSPITALITO",
          },
          {
            to: !editing
              ? "/hospitalito/nuevaConsulta"
              : "/hospitalito/editar/" + id,
            icon: !editing ? <Add /> : <Edit />,
            label: !editing
              ? "Nueva"
              : "Editar - " + capitalizeFirst("EVALUACION"),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid container spacing={1} sx={{ marginBottom: "500px" }}>
        <FichaIdentificacion idOrdenServicio={idOrdenServicio} />
        <Grid
          container
          spacing={1}
          component={Paper}
          className={classes.paper}
          style={{ marginTop: 10 }}
        >
          {/* {botones &&
            botones.map((boton, index) => (
              <FormControlLabel
                control={
                  <Switch
                    value={boton.on}
                    onChange={(e) => {
                      let preBotones = [...botones];
                      preBotones[index].on = e.target.checked;
                      setBotones(preBotones);
                      setAccion(null);
                    }}
                  />
                }
                label={boton.title}
              />
            ))} */}
        </Grid>
        <Grid
          container
          spacing={1}
          component={Paper}
          className={classes.paper}
          style={{ marginTop: 10 }}
        >
          {botones &&
            botones.map(
              (boton, index) =>
                boton.on && (
                  <Grid
                    item
                    xs={6}
                    md={4}
                    lg={3}
                    xl={2}
                    key={index}
                    style={{ display: "flex", padding: "5px" }}
                  >
                    <LoadingButton
                      variant="contained"
                      onClick={async () => {
                        if (!guardado) {
                          const text =
                            "NO HAS GUARDADO ¿QUIERES CONTINUAR SIN GUARDAR?".toUpperCase();
                          const res = await Swal.fire({
                            title: text,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#25607A",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Si",
                            cancelButtonText: "Cancelar",
                          });
                          if (res.isConfirmed) {
                            setGuardado(false)
                            setAccion(boton)
                          }
                        } else {
                          setGuardado(false)
                          setAccion(boton)
                        }
                      }
                      }
                      size="large"
                      style={{
                        color: "#fff",
                        width: "100%",
                        backgroundColor: "#1C5E7B",
                      }}
                      startIcon={boton.icon}
                    >
                      <p style={{ padding: "5px" }}>{boton.title}</p>
                    </LoadingButton>
                  </Grid>
                )
            )}
        </Grid>
        {
          !loading ?
            <>
              {switchCaseOptions(accion)}
            </>
            :
            <Grid
              container
              spacing={1}
              component={Paper}
              className={classes.paper}
              style={{ marginTop: 10, display: "flex", flexDirection: "row", justifyContent: "center" }}
            >
              <CircularProgress />
            </Grid>

        }
        {(admin === true || (permiso && permiso[0]['doctor'] > 0)) && (
          <Grid
            container
            spacing={1}
            component={Paper}
            className={classes.paper}
            style={{ marginTop: 10 }}
          >
            <LoadingButton
              variant="contained"
              onClick={(e) => {
                finalizarEvaluacion();
              }}
              size="large"
              style={{
                color: "#fff",
                width: "100%",
              }}
              startIcon={<Save />}
            >
              FINALIZAR EVALUACION
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default Detail;
