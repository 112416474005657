/* eslint-disable no-useless-concat */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect } from 'react';
import { useModel } from "../../hooks/useModel";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import FirebaseService from "../../services/firebase";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import PageHeader from "../../components/PageHeader";
import {
  Grid,
  Divider,
  Checkbox,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";

////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { capitalizeFirst } from '../../utils';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Edit } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import InputRegex from '../../components/InputRegex';
import FactCheckIcon from '@mui/icons-material/FactCheck';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

const ValoracionesDetail = ({ match, history, dialog }) => {
  ////////////////////////////////////////////////////////////////////////
  //                              Vars                                  //
  ////////////////////////////////////////////////////////////////////////
  const id = match.params.id;
  const classes = useStyles();
  const editing = !!id;
  const [admin] = useLocalStorage("admin", null);
  const { permiso } = useAuth();
  const [idSucursal] = useLocalStorage("idSucursal", "");
  // const [comentario, setComentario] = useState('NO APTO PROBLEMAS VISUALES');
  const [propiedades, setPropiedades] = useState({
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    idConvenio: 1,
    urlFile: 'null',
    status: 'PROCESO',
    comentario: 'null',
    l: false,
    rx: false,
    a: false,
    m: false,
    idSucursal,
    estado: "PROCESO",
    archivo: null,
  });
  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "valoracion",
      id,
      redirectOnPost: true,
    });
  ////////////////////////////////////////////////////////////////////////
  //                           Funcions Def                             //
  ////////////////////////////////////////////////////////////////////////
  const onGuardar = async () => {
    let body = { ...propiedades }
    if (editing) {
      body.idValoracion = id;
      if (propiedades.archivo !== null && propiedades.archivo !== undefined) {
        const ranm = Math.floor(Math.random() * 1000);
        const pathFirebase = "Valoracion/" + "File" + "_" + propiedades.nombreCompleto + ranm + "_" + propiedades.archivo.name.split('.').pop();
        let urlFile = await FirebaseService.uploadFile(pathFirebase, propiedades.archivo);

        body = { ...body, urlFile };
      }
    }
    updateModel(body, true, "valoraciones");
  };
  ////////////////////////////////////////////////////////////////////////
  //                            Hooks Def                               //
  ////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setPropiedades(model);
    }
    return () => {
      mounted = false;
      setPropiedades({
        nombres: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        idConvenio: 1,
        urlFile: 'null',
        comentario: 'null',
        status: 'PROCESO',
        idSucursal,
        archivo: null,
      });
    };
  }, [model]);

  return (
    <>
      <PageHeader
        history={history}
        title={(!editing ? "NUEVA" : "EDITAR") + " VALORACIÓN"}
        links={[
          { to: "/valoraciones", icon: <FactCheckIcon fontSize="small" />, label: "Valoraciones" },
          {
            to: !editing ? "/valoracion/nuevo" : "/valoracion/editar/" + id,
            icon: !editing ? <Add /> : <Edit />,
            label: !editing ? "Nuevo" : "Editar - " + capitalizeFirst(propiedades.nombres.toLocaleLowerCase()),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid container spacing={1} >
        <Grid item xs={12} md={2} lg={3}></Grid>
        <Grid item xs={12} md={8} lg={6}>
          <Grid container spacing={1} component={Paper} className={classes.paper} style={{ marginTop: 10 }} >
            <Grid item xs={12}>
              <div><strong>{"INFORMACIÓN DE LA VALORACIÓN"}</strong></div>
              <Divider />
            </Grid>
            <Grid item xs={12} >
              <InputRegex
                label="Nombre/s"
                variant="standard"
                fullWidth
                helperText={modelError?.nombres}
                error={Boolean(modelError?.nombres)}
                size="medium"
                typeInput='text'
                value={propiedades.nombres.toUpperCase()}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    nombres: e.target.value.toUpperCase(),
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} >
              <InputRegex
                label="Apellido Paterno"
                variant="standard"
                fullWidth
                helperText={modelError?.apellidoPaterno}
                error={Boolean(modelError?.apellidoPaterno)}
                size="medium"
                typeInput='text'
                value={propiedades.apellidoPaterno.toUpperCase()}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    apellidoPaterno: e.target.value.toUpperCase(),
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} >
              <InputRegex
                label="Apellido Materno"
                variant="standard"
                fullWidth
                helperText={modelError?.apellidoMaterno}
                error={Boolean(modelError?.apellidoMaterno)}
                size="medium"
                typeInput='text'
                value={propiedades.apellidoMaterno.toUpperCase()}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    apellidoMaterno: e.target.value.toUpperCase(),
                  }))
                }
              />
            </Grid>
            {(permiso && permiso[0]["valoraciones"] & 15) === 15 && (
              <>
                {(propiedades.status === 'FINALIZADO') && (
                  <Grid item xs={12} >
                    <FormControl variant="standard" fullWidth size="large">
                      <InputLabel variant="standard" id="demo-select-small">Comentario</InputLabel>
                      <Select
                        variant="standard"
                        size="large"
                        id="demo-select-small"
                        label="Comentarios"
                        value={propiedades.comentario}
                        onChange={(e) => {
                          setPropiedades(prevState => ({
                            ...prevState,
                            ...prevState.propiedades,
                            comentario: e.target.value
                          }))
                        }}
                      >
                        <MenuItem value="ACEPTADO">ACEPTADO</MenuItem>
                        <MenuItem value="NO APTO RADIOGRAFIA PATOLOGICA">NO APTO RADIOGRAFIA PATOLOGICA</MenuItem>
                        <MenuItem value="NO APTO AUDIOMETRÍA">NO APTO AUDIOMETRÍA</MenuItem>
                        <MenuItem value="NO APTO ABANDONO DE PROCESO">NO APTO ABANDONO DE PROCESO</MenuItem>
                        <MenuItem value="NO APTO ANTIDOPING POSITIVO">NO APTO ANTIDOPING POSITIVO</MenuItem>
                        <MenuItem value="NO APTO CIRUGÍAS MAYORES NO MENORES A 1 AÑO">NO APTO CIRUGÍAS MAYORES NO MENORES A 1 AÑO</MenuItem>
                        <MenuItem value="NO APTO ESTATURA FUERA DEL RANGO PERMISIBLE">NO APTO ESTATURA FUERA DEL RANGO PERMISIBLE</MenuItem>
                        <MenuItem value="NO APTO IMC MAYOR AL ACEPTADO">NO APTO IMC MAYOR AL ACEPTADO</MenuItem>
                        <MenuItem value="NO APTO IMC MENOR AL ACEPTADO">NO APTO IMC MENOR AL ACEPTADO</MenuItem>
                        <MenuItem value="NO APTO CREPITACIÓN DE HOMBROS">NO APTO CREPITACION DE HOMBROS</MenuItem>
                        <MenuItem value="NO APTO DIABETES DESCONTROLADA">NO APTO DIABETES DESCONTROLADA</MenuItem>
                        <MenuItem value="NO APTO PROBLEMAS VISUALES">NO APTO PROBLEMAS VISUALES</MenuItem>
                        <MenuItem value="NO APTO HIPERTENSIÓN NO CONTROLADA">NO APTO HIPERTENSIÓN NO CONTROLADA</MenuItem>
                        <MenuItem value="NO APTO OTROS ANTECEDENTES DE RIESGO MULTICAUSA">NO APTO OTROS ANTECEDENTES DE RIESGO MULTICAUSA</MenuItem>
                        <MenuItem value="NO APTO POR ENTREVISTA ZOBELE">NO APTO POR ENTREVISTA ZOBELE</MenuItem>
                        <MenuItem value="NO APTO SENSIBILIDAD A LOS PROCESOS ZOBELE">NO APTO SENSIBILIDAD A LOS PROCESOS ZOBELE</MenuItem>
                        <MenuItem value="NO APTO TRANSTORNOS PSIQUIÁTRICOS">NO APTO TRANSTORNOS PSIQUIÁTRICOS</MenuItem>
                        <MenuItem value="NO APTO RAYOS X COLUMNA">NO APTO RAYOS X COLUMNA</MenuItem>
                        <MenuItem value="NO APTO RAYOS X HOMBROS">NO APTO RAYOS X HOMBROS</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {editing && (
                  <>
                    <Grid item xs={6} >
                      <div>
                        <strong>Laboratorio</strong>
                      </div>
                      <div>
                        <Checkbox
                          checked={propiedades.l}
                          onChange={e => {
                            setPropiedades(prevState => ({
                              ...prevState,
                              ...prevState.propiedades,
                              l: e.target.checked
                            }))
                          }}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#000 !important' } }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={6} >
                      <div>
                        <strong style={{ whiteSpace: 'nowrap' }}>Medicina general</strong>
                      </div>
                      <div>
                        <Checkbox
                          checked={propiedades.m}
                          onChange={e => {
                            setPropiedades(prevState => ({
                              ...prevState,
                              ...prevState.propiedades,
                              m: e.target.checked
                            }))
                          }}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#000 !important' } }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={6} >
                      <div>
                        <strong>Audiometria</strong>
                      </div>
                      <div>
                        <Checkbox
                          checked={propiedades.a}
                          onChange={e => {
                            setPropiedades(prevState => ({
                              ...prevState,
                              ...prevState.propiedades,
                              a: e.target.checked
                            }))
                          }}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#000 !important' } }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={6} >
                      <div>
                        <strong>Rayos X</strong>
                      </div>
                      <div>
                        <Checkbox
                          checked={propiedades.rx}
                          onChange={e => {
                            setPropiedades(prevState => ({
                              ...prevState,
                              ...prevState.propiedades,
                              rx: e.target.checked
                            }))
                          }}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#000 !important' } }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} >
                      <FormControl variant="standard" fullWidth size="large">
                        <InputLabel variant="standard" id="estado-demo-select-small">ESTATUS DEL PACIENTE</InputLabel>
                        <Select
                          variant="standard"
                          size="large"
                          id="estado-demo-select-small"
                          label="Estado"
                          value={propiedades.i === null ? "PROCESO" : propiedades.i === true ? "APROBADO" : "NO APROBADO"}
                          onChange={(e) => {
                            setPropiedades(prevState => ({
                              ...prevState,
                              ...prevState.propiedades,
                              i: e.target.value === "PROCESO" ? null : e.target.value === "APROBADO" ? true : false,
                              estado: e.target.value,
                            }))
                          }}
                        >
                          <MenuItem value="APROBADO">APROBADO</MenuItem>
                          <MenuItem value="NO APROBADO">NO APROBADO</MenuItem>
                          <MenuItem value="PROCESO">PROCESO</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} >
                      <FormControl variant="standard" fullWidth size="large">
                        <InputLabel variant="standard" id="status-demo-select-small">ESTATUS DE LA EVALUACIÓN</InputLabel>
                        <Select
                          variant="standard"
                          size="large"
                          id="status-demo-select-small"
                          label="Estatus"
                          value={propiedades.status}
                          onChange={(e) => {
                            setPropiedades(prevState => ({
                              ...prevState,
                              ...prevState.propiedades,
                              status: e.target.value
                            }))
                          }}
                        >
                          <MenuItem value="FINALIZADO">FINALIZADO</MenuItem>
                          <MenuItem value="PROCESO">PROCESO</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} >
                      <Button variant="contained" component="label">
                        SUBIR PDF
                        <input hidden accept="application/pdf" multiple type="file" onChange={e => { setPropiedades({ ...propiedades, archivo: e.target.files[0] }) }} />
                      </Button>
                    </Grid>
                    <Grid item xs={12} >
                      PDF ACTUAL:{propiedades.urlFile === "null" ? "SIN PDF" : <a href={propiedades.urlFile} target="_blank" rel="noreferrer">ver archivo</a>}
                    </Grid>
                  </>
                )}
              </>
            )}
            <Grid item xs={12} style={{ margin: '20px 0' }}>
              <Grid container justifyContent="flex-end">
                <LoadingButton
                  disabled={modelLoading || updateModelLoading || admin || (permiso && permiso[0]["valoraciones"] & 2) === 2 ? false : true}
                  loading={modelLoading || updateModelLoading}
                  variant="contained"
                  onClick={() => onGuardar()}
                  size="medium"
                  startIcon={<SaveIcon />}
                  style={{ color: "#fff" }}
                >
                  {(!editing ? "GUARDAR" : "MODIFICAR")}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2} lg={3}></Grid>
      </Grid>
    </>
  );
}
export default ValoracionesDetail;