/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
// <======================= COMPONENTES ==========================>
import {
  Grid, Breadcrumbs, Paper, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress,
  Divider, IconButton, Typography, Chip, Switch, Button, CardMedia, Table, TableHead, TableBody, TableCell, TableRow
} from "@mui/material";
import FirebaseService from "../../services/firebase";
import InputRegex from "../../components/InputRegex";
import DatePickerBeta from '../../components/DatePickerBeta';
import { useLocalStorage } from "./../../hooks/useLocalStorage";
import { usePost } from "../../hooks/usePost";
import { useAlert } from "./../../hooks/useAlert";
import moment from "moment-timezone";
import FullScreenLoader from "../../components/FullScreenLoader";
import { baseStyles, breadCrumbsStyles, tablaPrimaryStyle, tableCellStyles, tableRowStyles } from "../../utils";
import Swal from "sweetalert2";
import { useAuth } from "../../hooks/useAuth";
import { useGet } from "../../hooks/useGet";
import LoadingButton from '@mui/lab/LoadingButton';
import Modal from "react-modal";
// <========================= STYLES =============================>
import { styled } from "@mui/material/styles";
// <========================= ICONOS =============================>
import scanerGif from "../../assets/img/barcode-scan.gif";
import { Home } from "@mui/icons-material";
import { PhotoCamera } from "@mui/icons-material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { FiUpload } from "react-icons/fi";
import { BsCameraFill } from 'react-icons/bs';
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import ReactFileManager from "../../components/ReactFileManager";
import Tabs from "../../components/TabsRework";
import { set } from "date-fns";

const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const useStyles = baseStyles();
const useTablas = tablaPrimaryStyle();
const StylesBreadCrumb = breadCrumbsStyles()(Chip);

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  color: "black",
  padding: theme.spacing(1),
  BorderStyle: "outset",
}));
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
let Barcode = require("react-barcode");
Modal.setAppElement(document.getElementById("#modal"));
const Index = ({ match }) => {
  // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
  // <=======================================STATES DEF ETC====================================================> //
  // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
  const classes = useStyles();
  const tablas = useTablas();
  const history = useHistory();
  const formulario = useRef();
  const editing = match.params.idProductoInventario !== undefined;
  const [admin] = useLocalStorage("admin", null);
  const [errores, cargandoBoton, peticion, setCargando, detalles] = usePost();
  const { permiso } = useAuth();
  const [servSuc, setServSuc] = useState([]);
  const textError = useRef();
  const [qr, setQr] = useState("");
  const [reload, setReload] = useState(false);
  const { showAlert } = useAlert();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [archivo, setArchivo] = useState();
  const [foto, setFoto] = useState(null);
  const [urlImg, setUrlImg] = useState("");
  const [tipoImagen, setTipoImagen] = useState("file");
  const [video, setVideo] = useState(null);
  const [baseSucursal, setBaseSucursal] = useState([]);
  const [pos, setPos] = useState(0);
  const [propiedades, setPropiedades] = useState({
    nombreProducto: "",
    serie: "",
    tipo: "Medicamento",
    fechaCaducidad: moment(new Date()).add(1, "d").format("YYYY-MM-DDTHH:mm:ss"),
    tipoM: "Tabletas",
    medida: "",
    talla: "null",
    tipoDosis: "Mg",
    dosis: "",
    marca: "",
    modelo: "",
    almacen: "",
    descripcion: "",
    comentario: "",
    precio: "",
    precioPublico: "",
    unidad: "",
    precioUnidad: '',
    porcentaje: 30,
    orden: "",
    via: "Vía oral",
    urlFoto:
      "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Productos%2Fdefault.png.png?alt=media&token=bea632da-49a3-44b0-ae46-d8fcf9070fd7",
    productoSucursal: [],
  });
  const [producto, cargando] = useGet({
    initUrl: "producto-inventario",
    params: {
      idProductoInventario: match.params.idProductoInventario,
      expand: "productoSucursal.sucursal",
    },
    action: editing ? true : false,
  });
  let getSucursales = { pagina: 1, ordenar: "nombreSucursal.asc" };
  const [sucursales, cargandoSucursales] = useGet({
    initUrl: "sucursal/index",
    params: getSucursales,
    action: true,
  });
  // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
  // <=======================================FUNCIONES MEMO====================================================> //
  // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //

  const handleFileRead = async (event) => {
    let file = event.target.files[0];
    const typePhoto = file.name.split(".").pop();
    switch (typePhoto) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        break;
      default:
        showAlert({
          message: "El archivo no tiene la extensión adecuada",
          severity: "warning",
        });
        file = "";
    }
    if (file !== undefined) {
      const urlFoto = URL.createObjectURL(event.target.files[0]);
      setArchivo(file);
      setPropiedades((prevState) => ({
        ...prevState,
        ...prevState.propiedades,
        urlFoto: urlFoto,
      }));
    }
  };

  const guardarProducto = async () => {
    setCargando(true);
    let cI;
    let filterObj = servSuc.filter((e) => e.active == true);
    let urlFileProducto = "";
    let obj = { ...propiedades };
    if (obj.tipo == "Equipo") {
      obj.medida = 0;
      obj.tipoM = "null";
      obj.almacen = "Almacen Equipo";
      obj.via = 'null';
      obj.dosis = 'null';
    } else if (obj.tipo == "Medicamento") {
      obj.almacen = "Almacen Medicamento";
      obj.modelo = "null";
    }
    let data = producto && producto.resultado && producto.resultado[0].productoSucursal;
    if (data) {
      baseSucursal.map((e) => {
        data = data.filter((e2) => e2.idSucursal != e.idSucursal);
        cI = data.concat(filterObj);
      });
    } else {
      cI = filterObj;
    }
    obj = { ...obj, productoSucursal: cI };

    if (obj.productoSucursal.length > 0) {
      const producto = propiedades.nombreProducto.split(" ").join("").toLowerCase();
      const ranm = Math.floor(Math.random() * 1000);
      const pathFirebase = "Producto/" + "Imagen" + "_" + producto + "_" + ranm;
      if (tipoImagen == "file") {
        if (archivo) {
          urlFileProducto = await FirebaseService.uploadFile(pathFirebase, archivo);
          obj = { ...obj, urlFoto: urlFileProducto };
        }
      } else {
        if (foto) {
          urlFileProducto = await FirebaseService.uploadFile(pathFirebase, foto);
          obj = { ...obj, urlFoto: urlFileProducto };
        }
      }
      let title = "";
      editing
        ? (title = "¿Deseas Editar este Producto?")
        : (title = "¿Deseas Guardar este Producto?");
      const res = await Swal.fire({
        title: title,
        text: "Favor de corroborar sus datos antes de continuar.",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Continuar!",
        cancelButtonText: "Cancelar",
      });
      if (obj.urlFoto === "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Productos%2Fdefault.png.png?alt=media&token=bea632da-49a3-44b0-ae46-d8fcf9070fd7" && res.isConfirmed === true) {
        const resp = await Swal.fire({
          title: "¡No ha seleccionado una imagen.!",
          text: "Se agregarar un imagen por defecto, ¿Desea continuar?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Continuar!",
          cancelButtonText: "Cancelar",
        });
        if (resp.isConfirmed) {
          showAlert({
            message: "Guardando imagen...",
            severity: "warning",
          });
          peticion("producto-inventario/guardar", obj, "/productos");
        } else {
          setCargando(false);
        }
      }
      if (res.isConfirmed && obj.urlFoto !== "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Productos%2Fdefault.png.png?alt=media&token=bea632da-49a3-44b0-ae46-d8fcf9070fd7") {
        showAlert({
          message: "Guardando imagen...",
          severity: "warning",
        });
        showAlert({
          message: "Guardando producto...",
          severity: "warning",
        });
        peticion("producto-inventario/guardar", obj, "/productos");
      } else {
        setCargando(false);
      }
      setCargando(false);

    } else {
      obj = { ...obj, urlFoto: "" };
      setCargando(false);
      showAlert({
        message:
          "Favor de agregar las sucursales al que el producto pertenece.",
        severity: "warning",
      });
    }
  };
  const deleteImg = async (url) => {
    await FirebaseService.deleteFile("Productos/", url);
    setUrlImg("");
  };
  function openModal() {
    setIsOpen(true);
  };
  function closeModal() {
    setIsOpen(false);
  };
  const getFilterArraySucursales = (array) => {
    return array.map((e) => {
      return {
        idSucursal: e.idSucursal,
        nombreSucursal: e.nombreSucursal,
        active: false,
      };
    });
  };
  const changePos = async _pos => {
    if (_pos == 0) {
      setTipoImagen("file");
    } else {
      setTipoImagen("foto");
    }
    setPos(_pos);
  };
  const onCloseBarcode = () => {
    closeModal();
  }
  const getVideo = _video => {
    setVideo(_video);
  }
  // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
  // <=======================================EFFECTS CALLBACKS=================================================> //
  // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
  useEffect(() => {
    if (editing) {
      let data = producto && producto.resultado && producto.resultado[0];
      setPropiedades(data);
    }
  }, [producto, editing]);

  useEffect(() => {
    if (errores) {
      formulario.current.scrollIntoView({ behavior: "smooth" });
      if (errores?.serie) {
        textError.current.innerHTML = errores?.serie;
      }
      if (urlImg != "" && urlImg != undefined) {
        deleteImg(urlImg);
      }
    }
  }, [errores]);

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    if (sucursales.resultado) {
      const newObj = getFilterArraySucursales(sucursales.resultado);
      setServSuc(newObj);
    }
  }, [sucursales.resultado]);

  useEffect(() => {
    if (editing) {
      let data = producto && producto?.resultado && producto?.resultado[0];
      setPropiedades(data);
      setQr(data?.serie);
      if (data?.productoSucursal && sucursales.resultado) {
        const newObj = data?.productoSucursal.map((e) => {
          return {
            idSucursal: e.idSucursal,
            nombreSucursal: e?.sucursal?.nombreSucursal,
            active: true,
          };
        });
        const newSucursalesObj = getFilterArraySucursales(sucursales.resultado);
        newObj.map((e) => {
          newSucursalesObj.map((e2) => {
            if (e2.idSucursal == e.idSucursal) {
              e2.active = e.active;
            }
          });
        });
        setBaseSucursal(newSucursalesObj.filter((e) => e.active == true));
        setServSuc(newSucursalesObj);
      }
    }
  }, [producto, editing, sucursales.resultado]);

  useEffect(() => {
    const newPropiedades = propiedades;
    newPropiedades.serie = qr;
    setPropiedades({ ...propiedades, ...newPropiedades });
  }, [qr]);

  if (editing && cargando) return <FullScreenLoader />;

  return (
    <div className={classes.root}>
      <div id="modal" style={{ zIndex: "99" }}>
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal" ariaHideApp={false}>
          {(modalType == "barcode") && (
            <ReactFileManager typeFile={"barcode"} setBarcode={setQr} barcode={qr} onCloseBarcode={onCloseBarcode} />
          )}
          {modalType == "qr" && (
            <>
              <input
                style={{ opacity: "0", position: "absolute", cursor: "default", }}
                autoFocus={true}
                onBlur={({ target }) => { target.focus() }}
                onChange={(e) => {
                  if (e.target.value.match(/ñ/g)) {
                    showAlert({
                      message: "El codigo de barras no acepta ñ",
                      severity: "warning",
                    });
                    closeModal();
                  } else {
                    setQr(e.target.value.replaceAll("'", "-"));
                  }
                }}
                onKeyPress={(event) => {
                  if (event.which == 13 || event.keyCode == 13) {
                    event.target.value = "";
                    closeModal();
                    return false;
                  }
                  return true;
                }}
              />
              <img src={scanerGif} alt="Escanea codigo de barras" style={{ width: '300px', height: '300px' }} />
              <p style={{ zIndex: 999, textAlign: 'center', color: 'rgba(100,100,100,1)', fontSize: '20px' }}>{propiedades.serie.replaceAll("'", "-")}</p>
            </>
          )}
        </Modal>
      </div>
      <Paper className={classes.paper}>
        <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
          {editing ? `EDITANDO PRODUCTO` : `NUEVO PRODUCTO`}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <StylesBreadCrumb component="a" label="Inicio" icon={<Home fontSize="small" />} onClick={() => history.push("/")} />
              <StylesBreadCrumb
                component="a"
                label="Productos"
                onClick={() => history.push("/productos")}
              />
              <StylesBreadCrumb
                component="a"
                label={editing ? `Editar Producto: ${propiedades?.nombreProducto}` : `Crear Producto`}
              />
            </Breadcrumbs>
            <br></br>
          </Grid>

          <Grid item xs={12}>
            <Div ref={formulario}> <strong>{"INFORMACIÓN DEL PRODUCTO"}</strong> </Div>
            <Divider />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              label="Nombre Producto"
              variant="standard"
              fullWidth
              helperText={errores?.nombreProducto}
              error={Boolean(errores?.nombreProducto)}
              size="medium"
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  nombreProducto: e.target.value.toUpperCase(),
                }))
              }
              inputProps={{
                value: propiedades?.nombreProducto.toUpperCase(),
              }}
            />
          </Grid>

          {qr != "" ? (
            <Grid item xs={12} md={4} style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
              <IconButton
                style={{ height: "fit-content", margin: "5px" }}
                onClick={() => {
                  setModalType("qr");
                  openModal();
                }}
              >
                <DocumentScannerIcon />
              </IconButton>
              <IconButton style={{ height: 'fit-content', margin: '5px' }} onClick={() => {
                setModalType("barcode")
                openModal();
              }}>
                <CameraAltIcon />
              </IconButton>
              <Barcode height={30} width={1.2} fontSize={13} margin={0} marginTop={20} value={qr}
                onClick={() => {
                  setModalType("qr");
                  openModal();
                }}
              />
              <IconButton
                style={{ height: "fit-content", margin: "5px" }}
                onClick={() => {
                  setQr("");
                  const newPropiedades = propiedades;
                  newPropiedades.serie = "";
                  setPropiedades({ ...propiedades, ...newPropiedades });
                }}
              >
                <CloseIcon />
              </IconButton>
              {errores?.serie && (
                <IconButton
                  onClick={() => {
                    setModalType("qr");
                    openModal();
                  }}
                >
                  <ErrorOutlineIcon style={{ color: "#AA0A00", margin: "0 10px" }} />
                  <Typography style={{ color: "#AA0A00" }} ref={textError} />
                </IconButton>
              )}
            </Grid>
          ) : (
            <Grid item xs={12} md={4} style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
              <IconButton
                style={{ height: "fit-content", margin: "5px" }}
                onClick={() => {
                  setModalType("qr");
                  openModal();
                }}
              >
                <DocumentScannerIcon />
              </IconButton>
              <IconButton style={{ height: 'fit-content', margin: '5px' }} onClick={() => {
                setModalType("barcode")
                openModal();
              }}>
                <CameraAltIcon />
              </IconButton>
              {Boolean(errores?.serie) && (
                <IconButton
                  onClick={() => {
                    setModalType("qr");
                    openModal();
                  }}
                >
                  <ErrorOutlineIcon style={{ color: "#AA0A00", margin: "0 10px" }} />
                  <Typography style={{ color: "#AA0A00" }} ref={textError} />
                </IconButton>
              )}
            </Grid>
          )}

          <Grid item xs={12} md={4}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="tipoSelect">Seleccionar Tipo</InputLabel>
              <Select
                disabled={editing}
                labelId="tipoSelect"
                fullWidth
                error={Boolean(errores?.tipo)}
                label="Tipo"
                onChange={(e) => {
                  if (propiedades.tipo === "Medicamento") {
                    if (propiedades.modelo === "null") {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        modelo: "",
                      }));
                    }
                  } else {
                    if (propiedades.tipoM === "null") {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        tipoM: "MG",
                      }));
                    }
                  }
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    tipo: e.target.value,
                  }));
                }}
                inputProps={{
                  value: propiedades.tipo,
                }}
              >
                <MenuItem value={"Medicamento"}>Medicamento</MenuItem>
                <MenuItem value={"Equipo"}>Equipo</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={propiedades.tipo === "Equipo" ? 6 : 4}>
            <TextField
              label="Marca"
              variant="standard"
              fullWidth
              helperText={errores?.marca}
              error={Boolean(errores?.marca)}
              size="medium"
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  marca: e.target.value.toUpperCase(),
                }))
              }
              inputProps={{
                value: propiedades.marca,
              }}
            />
          </Grid>

          {!reload && propiedades.tipo == "Medicamento" && (
            <>
              {/* <Grid item xs={12} md={4}>
                <DatePickerBeta
                  labelText="Fecha de Caducidad"
                  value={propiedades?.fechaCaducidad}
                  variant="standard"
                  inputFormat="MM/dd/yyyy"
                  onChange={(e) => {
                    if (e != null && e != "Invalid Date") {
                      let isBefore = moment(new Date()).isBefore(e);
                      if (isBefore) {
                        setPropiedades((prevState) => ({
                          ...prevState,
                          ...prevState.propiedades,
                          fechaCaducidad: moment(e).format("YYYY-MM-DD H:m:s"),
                        }));
                      } else {
                        setPropiedades((prevState) => ({
                          ...prevState,
                          ...prevState.propiedades,
                          fechaCaducidad: moment(new Date())
                            .add(1, "d")
                            .format("YYYY-MM-DD H:m:s"),
                        }));
                        showAlert({
                          message:
                            "La fecha de caducidad no puede ser antes del dia actual",
                          severity: "error",
                        });
                        setReload(true);
                      }
                    } else {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        fechaCaducidad: moment(new Date())
                          .add(1, "d")
                          .format("YYYY-MM-DD H:m:s"),
                      }));
                      showAlert({
                        message: "La fecha de caducidad es incorrecta",
                        severity: "error",
                      });
                      setReload(true);
                    }
                  }}
                />
              </Grid> */}

              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="tipoSelect">Presentación del Fármaco</InputLabel>
                  <Select
                    labelId="tipoSelect"
                    fullWidth
                    error={Boolean(errores?.tipoM)}
                    label="Tipo"
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        tipoM: e.target.value,
                      }));
                    }}
                    inputProps={{
                      value: propiedades.tipoM,
                    }}
                  >
                    <MenuItem value={"Tabletas"}>Tabletas</MenuItem>
                    <MenuItem value={"Crema"}>Crema</MenuItem>
                    <MenuItem value={"Gotas"}>Gotas</MenuItem>
                    <MenuItem value={"Supositorios"}>Supositorios</MenuItem>
                    <MenuItem value={"Grajeas"}>Grajeas</MenuItem>
                    <MenuItem value={"Perlas"}>Perlas</MenuItem>
                    <MenuItem value={"Cápsulas"}>Cápsulas</MenuItem>
                    <MenuItem value={"Ámpulas"}>Ámpulas</MenuItem>
                    <MenuItem value={"Frascos"}>Frascos</MenuItem>
                    <MenuItem value={"Inhaladores"}>Inhaladores</MenuItem>
                    <MenuItem value={"Parches"}>Parches</MenuItem>
                    <MenuItem value={"Sobres"}>Sobres</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputRegex
                  style={{ height: "50px" }}
                  label="Cantidad Presentación"
                  variant="standard"
                  fullWidth
                  typeInput="number"
                  value={propiedades?.medida}
                  error={Boolean(errores?.medida)}
                  onChange={(e) =>
                    setPropiedades((prevState) => ({
                      ...prevState,
                      ...prevState.propiedades,
                      medida: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="tipoSelect">Seleccionar Dosis</InputLabel>
                  <Select
                    labelId="tipoSelect"
                    fullWidth
                    error={Boolean(errores?.tipoDosis)}
                    label="Tipo"
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        tipoDosis: e.target.value,
                      }));
                    }}
                    inputProps={{
                      value: propiedades.tipoDosis,
                    }}
                  >
                    <MenuItem value={"Mg"}>Mg</MenuItem>
                    <MenuItem value={"Ml"}>Ml</MenuItem>
                    <MenuItem value={"Gr"}>Gr</MenuItem>
                    <MenuItem value={"Lt"}>Lt</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputRegex
                  style={{ height: "50px" }}
                  label="Dosis"
                  variant="standard"
                  fullWidth
                  typeInput="text"
                  value={propiedades?.dosis}
                  error={Boolean(errores?.dosis)}
                  onChange={(e) =>
                    setPropiedades((prevState) => ({
                      ...prevState,
                      ...prevState.propiedades,
                      dosis: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={9} md={4} xl={4}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="demo-simple-select-autowidth-label">Via de administración</InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    label="Sucursales"
                    value={propiedades.via}
                    onChange={(e) => {
                      setPropiedades(prevState => ({
                        ...prevState,
                        ...prevState.propiedades,
                        via: e.target.value
                      }));
                    }}
                  >
                    <MenuItem value="Intravenoso">INTRAVENOSO</MenuItem>
                    <MenuItem value="Intramuscular">INTRAMUSCULAR</MenuItem>
                    <MenuItem value="Nasal">NASAL</MenuItem>
                    <MenuItem value="Oftálmico">OFTÁLMICO</MenuItem>
                    <MenuItem value="Optica">OPTICA</MenuItem>
                    <MenuItem value="Rectal">RECTAL</MenuItem>
                    <MenuItem value="Subcutáneo">SUBCUTÁNEO</MenuItem>
                    <MenuItem value="Tópica">TÓPICA</MenuItem>
                    <MenuItem value="Vía oral">VÍA ORAL</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}

          {propiedades.tipo == "Equipo" && (
            <>
              <Grid item xs={12} md={propiedades.tipo === "Equipo" ? 6 : 4}>
                <TextField
                  label="Modelo"
                  variant="standard"
                  fullWidth
                  helperText={errores?.modelo}
                  error={Boolean(errores?.modelo)}
                  size="medium"
                  onChange={(e) =>
                    setPropiedades((prevState) => ({
                      ...prevState,
                      ...prevState.propiedades,
                      modelo: e.target.value.toUpperCase(),
                    }))
                  }
                  inputProps={{
                    value: propiedades.modelo,
                  }}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <TextField
              label="Descripción"
              variant="standard"
              fullWidth
              helperText={errores?.descripcion}
              error={Boolean(errores?.descripcion)}
              size="medium"
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  descripcion: e.target.value,
                }))
              }
              inputProps={{
                value: propiedades.descripcion,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <InputRegex
              style={{ height: "50px" }}
              label="Ordenar Pedido"
              variant="standard"
              fullWidth
              typeInput="number"
              value={propiedades?.orden}
              error={Boolean(errores?.orden)}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  orden: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} sm={9} md={4} xl={4}>
            <InputRegex
              style={{ height: '50px' }}
              label="Porcentaje"
              variant="standard"
              fullWidth
              typeInput="number"
              value={propiedades?.porcentaje}
              onChange={(e) => {
                setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  porcentaje: e.target.value,
                  precioPublico: Math.round((propiedades.precio * (1 + (e.target.value / 100))) * Math.pow(10, 2)) / Math.pow(10, 2),
                  precioUnidad: Math.round(((Math.round((propiedades.precio * (1 + (e.target.value / 100))) * Math.pow(10, 2)) / Math.pow(10, 2)) / propiedades.unidad) * Math.pow(10, 2)) / Math.pow(10, 2),
                }))
              }}
              helperText={errores?.porcentaje}
              error={Boolean(errores?.porcentaje)}
            />
          </Grid>
          <Grid item xs={12} md={4} >
            <InputRegex
              style={{ height: '50px' }}
              label="Precio Proveedor"
              variant="standard"
              fullWidth
              typeInput="currency"
              value={propiedades?.precio?.toString() === "0.00" ? "" : propiedades?.precio?.toString()}
              onChange={(e) => {
                setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  precio: e.target.value,
                  precioPublico: Math.round((e.target.value * (1 + (propiedades.porcentaje / 100))) * Math.pow(10, 2)) / Math.pow(10, 2),
                  precioUnidad: Math.round(((Math.round((e.target.value * (1 + (propiedades.porcentaje / 100))) * Math.pow(10, 2)) / Math.pow(10, 2)) / propiedades.unidad) * Math.pow(10, 2)) / Math.pow(10, 2),
                }))
              }}
              helperText={errores?.precio}
              error={Boolean(errores?.precio)}
            />
          </Grid>
          {propiedades.precio !== "" && (
            <>
              <Grid item xs={12} md={4} >
                <InputRegex
                  style={{ height: '50px' }}
                  label="Precio Publico"
                  variant="standard"
                  disabled={true}
                  fullWidth
                  typeInput="currency"
                  value={propiedades?.precioPublico?.toString()}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputRegex
                  style={{ height: "50px" }}
                  label="Cantidad Por Unidad"
                  variant="standard"
                  fullWidth
                  typeInput="number"
                  value={propiedades?.unidad || ""}
                  error={Boolean(errores?.unidad)}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        precioUnidad: ""
                      }))
                    }
                    setPropiedades((prevState) => ({
                      ...prevState,
                      ...prevState.propiedades,
                      unidad: e.target.value,
                      precioUnidad: Math.round((propiedades.precioPublico / e.target.value) * Math.pow(10, 2)) / Math.pow(10, 2),
                    }))
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4} >
                <InputRegex
                  style={{ height: '50px' }}
                  label="Precio Unidad"
                  variant="standard"
                  disabled={true}
                  fullWidth
                  typeInput="currency"
                  value={propiedades?.precioUnidad?.toString()}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <TextField
              multiline
              rows={3}
              label="Comentario"
              variant="standard"
              fullWidth
              helperText={errores?.comentario}
              error={Boolean(errores?.comentario)}
              size="medium"
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  comentario: e.target.value,
                }))
              }
              inputProps={{
                value: propiedades.comentario,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Tabs changePos={changePos} pos={pos} tabs={[
              {
                title: "Subir Imagen",
                icon: <FiUpload style={{ fontSize: 'x-large', marginLeft: '5px', marginBottom: '5px' }} />,
                content: <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={7} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '30px' }}>
                      <CardMedia
                        component="img"
                        image={propiedades.urlFoto}
                        title=""
                        style={{ width: "305px", height: "305px", objectFit: 'cover', border: '7px solid whiteSmoke', borderRadius: '5px' }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justifyContent="center" alignItems="center" >
                    <Grid item xs={12} sm={12} md={2}>
                      <div>
                        <input
                          hidden
                          accept="image/gif,image/jpeg,image/jpg,image/png"
                          className={classes.input}
                          id="contained-button-file"
                          type="file"
                          onChange={handleFileRead}
                        />
                        <label htmlFor="contained-button-file">
                          <Button style={{ color: 'white' }} variant="contained" color="primary" component="span" >
                            <PhotoCamera />
                          </Button>
                        </label>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              },
              {
                title: "Tomar Foto",
                icon: <BsCameraFill style={{ fontSize: 'x-large', marginLeft: '5px', marginBottom: '4px' }} />,
                content: <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
                    <ReactFileManager foto={foto} setFoto={setFoto} getVideo={getVideo} />
                  </Grid>
                </div>
              }
            ]} />
          </Grid>

        </Grid>
      </Paper>

      <Paper className={classes.paper}>
        <Grid item md={12}>
          <Div>
            <strong>{"SUCURSALES"}</strong>
          </Div>
          <Divider />
        </Grid>
        {cargandoSucursales ? (
          <div className={classes.loadingTable}>
            <CircularProgress />
          </div>
        ) : (
          <Grid
            container
            spacing={2}
            style={{ alignItems: "center", marginTop: "1rem" }}
          >
            <Table className={tablas.TablePrimary}>
              <TableHead>
                <TableRowModify>
                  <TableCellModify>#</TableCellModify>
                  <TableCellModify>Sucursal</TableCellModify>
                  <TableCellModify>Acción</TableCellModify>
                </TableRowModify>
              </TableHead>
              <TableBody>
                {servSuc &&
                  servSuc.map((row, index) => {
                    return (
                      <TableRowModify key={row.idSucursal}>
                        <TableCellModify>{index + 1}</TableCellModify>
                        <TableCellModify>{row.nombreSucursal}</TableCellModify>
                        <TableCellModify>
                          <Switch
                            checked={row.active}
                            onChange={(e) => {
                              servSuc[index].active = e.target.checked;
                              setServSuc([...servSuc]);
                            }}
                          />
                        </TableCellModify>
                      </TableRowModify>
                    );
                  })}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Paper>

      <Paper className={classes.paper}>
        <Grid item xs={12} xl={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                admin === true ||
                  (permiso && permiso[0]["inventarios"] & 2) === 2
                  ? false
                  : true
              }
              loading={cargandoBoton}
              variant="contained"
              onClick={guardarProducto}
              size="large"
              className={classes.successButton}
              style={{ color: "#fff" }}
            >
              {editing ? "EDITAR PRODUCTO" : "GUARDAR PRODUCTO"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Index;
