import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu'
import ListItemIcon from '@mui/material/ListItemIcon';
import moment from "moment-timezone";
import PersonIcon from '@mui/icons-material/Person';
import 'moment/locale/es'
import Avatar from '@mui/material/Avatar';
import SyncIcon from '@mui/icons-material/Sync';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Logout from '@mui/icons-material/Logout';
import { useAuth } from "../hooks/useAuth";
import { useCacheBuster } from "../hooks/CacheBuster";
import { useLocalStorage } from "../hooks/useLocalStorage";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import logo from "../assets/img/logo.png";
import { NavLink } from "react-router-dom";
function Header(props) {
  const { refreshCacheAndReload } = useCacheBuster();
  const { onDrawerToggle } = props;
  const { user, signOut } = useAuth();
  const [nombreCompleto, setNombreCompleto] = useLocalStorage("nombreCompleto", null);
  const [correo, setCorreo] = useLocalStorage("correo", null);
  
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (user && nombreCompleto == null && correo == null) {
        setNombreCompleto(user?.nombreCompleto);
        setCorreo(user?.correo);
      }
    }
    return () => (mounted = false);
  }, [user, nombreCompleto, correo, setCorreo, setNombreCompleto]);

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <React.Fragment>
      <AppBar color="secondary" position="static" style={{ padding: '3px', borderBottom: '2px solid #DAD083' }}>
        <Container maxWidth="xl" style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <Box sx={{ display: { md: 'none', xs: 'block' } }}>
            <IconButton
              color="inherit"
              aria-label="opern drawer"
              edge="start"
              onClick={onDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box>
              <NavLink to="/inicio" style={{ textDecoration: 'none' }}>
                <img width={'100px'} src={logo} alt="logo" />
              </NavLink>
          </Box>
          <Box style={{color:'#1d5e7b', fontSize:'1rem', fontWeight:'600'}} sx={{ display: { md: 'block', xs: 'none' } }}>
            {moment().format('LLLL').slice(0, -5).toUpperCase()}
          </Box>
          <Toolbar disableGutters style={{ justifyContent: 'flex-end' }}>
            <Box >
              <Tooltip title="Perfil">
                <IconButton onClick={handleOpenUserMenu}>
                  <Avatar alt={user?.nombreCompleto} src={user?.urlFoto} />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem>
                  <ListItemIcon>
                    <PersonIcon fontSize="small" />
                  </ListItemIcon>
                   {nombreCompleto}
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <AlternateEmailIcon fontSize="small" />
                  </ListItemIcon>
                  {correo}
                </MenuItem>
                <Divider />
                <MenuItem onClick={refreshCacheAndReload}>
                  <ListItemIcon>
                    <SyncIcon fontSize="small" />
                  </ListItemIcon>
                  Actualizar
                </MenuItem>
                <MenuItem onClick={signOut}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Cerrar Sesión
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;