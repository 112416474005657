/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */

////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useModel } from "../../hooks/useModel";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { useAlert } from "../../hooks/useAlert";
import { useGet } from "../../hooks/useGet";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import PageHeader from "../../components/PageHeader";
import {
  Grid,
  Divider,
  Paper,
  Switch,
  Table,
  CircularProgress,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import TablePagination from "../../components/TablePagination";
import { useModels } from "../../hooks/useModels";
import InputRegex from "../../components/InputRegex";
import Loading from "../../components/FullScreenLoader";
import AsyncAutocompleteSearchBeta from '../../components/AsyncAutocompleteSearchBeta';
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { tableCellStyles, tableRowStyles, entradasStyle, capitalizeFirst, internationalCurrency } from '../../utils';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Edit, MedicationLiquid } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const useEntradasStyle = entradasStyle();

const PaquetesDetail = ({ match, history, dialog }) => {
  ////////////////////////////////////////////////////////////////////////
  //                              Vars                                  //
  ////////////////////////////////////////////////////////////////////////
  const header = [
    {
      name: "Nombre Sucursal",
      prop: "nombreSucursal",
    }
  ];
  const id = match.params.id;
  const classes = useStyles();
  const { showAlert } = useAlert();
  const [first, setFirst] = useState(false);
  const [firstLaboratorio, setFirstLaboratorio] = useState(false);
  const entradas = useEntradasStyle();
  const [newObjSucursales, setNewObjSucursales] = useState([])
  const [reload, setReload] = useState(true);
  const [productos, setProductos] = useState([]);
  const [laboratorios, setLaboratorios] = useState([]);
  const [clearInputProducto, setClearInputProducto] = useState(false);
  const [clearInputLaboratorio, setClearInputLaboratorio] = useState(false);
  const [clearInputSucursal, setClearInputSucursal] = useState(false);
  const editing = !!id;
  const [sucursal, setSucursal] = useState(null);
  const [admin] = useLocalStorage("admin", null);
  const { permiso } = useAuth();
  const [producto, setProducto] = useState(null);
  const [laboratorio, setLaboratorio] = useState(null);
  const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");

  const [propiedades, setPropiedades] = useState({
    nombrePaquete: "",
    descripcion: "",
    paqueteProductoInventario: [],
    paqueteInventarioLaboratorio: [],
    idSucursal,
  });

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "paquete",
      expand: "paqueteSucursal, paqueteProductoInventario.productoInventario, paqueteInventarioLaboratorio.inventarioLaboratorio",
      id,
      redirectOnPost: true,
    });

  const productoParamsMemo = useMemo(() => ({
    pagina: 1,
    limite: 20,
    idSucursal: sucursal ? sucursal.idSucursal : null,
    ordenar: "nombreProducto.desc"
  }), [sucursal]);

  const sucursalParamsMemo = useMemo(() => ({
    pagina: 1,
    limite: 20,
    ordenar: "nombreSucursal.desc"
  }), []);

  const laboratorioParamsMemo = useMemo(() => ({
    pagina: 1,
    limite: 20,
    idSucursal: sucursal ? sucursal.idSucursal : null,
    ordenar: "nombreProducto.desc"
  }), [sucursal]);

  const parametrosInicialesMemo = useMemo(
    () => ({
      name: "sucursal",
      ordenar: "idSucursal.asc",
    }),
    []
  );
  const [models, modelsPage, refreshModels, deleteModel,] =
    useModels({ ...parametrosInicialesMemo });
  ////////////////////////////////////////////////////////////////////////
  //                           Funcions Def                             //
  ////////////////////////////////////////////////////////////////////////
  const onDropServicio = async (id, nombre, tipo = "producto") => {
    if (tipo == "producto") {
      if (id) {
        const res = await Swal.fire({
          title: "Borrar",
          icon: "warning",
          text: `¿Estás seguro de que deseas borrar el producto seleccionado "${capitalizeFirst(nombre.toLowerCase())}"?`,
          showCancelButton: true,
          confirmButtonColor: "#25607A",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, deseo Continuar!",
          cancelButtonText: "Cancelar",
        });
        if (res.isConfirmed) {
          showAlert({
            message: "Espera un momento.....",
            severity: "warning",
          });
          let posi;
          const copyProductos = [...productos];
          productos.map((e, index) => {
            if (e.idProductoInventario === id) {
              posi = index;
            }
          });
          copyProductos.splice(posi, 1);
          setProductos(copyProductos);
          setReload(true);
          showAlert({
            message: "Producto borrado con éxito.",
            severity: "success",
          });
        } else {
          setReload(true);
        }
      }
    } else if (tipo == "laboratorio") {
      if (id) {
        const res = await Swal.fire({
          title: "Borrar",
          icon: "warning",
          text: `¿Estás seguro de que deseas borrar el producto seleccionado "${capitalizeFirst(nombre.toLowerCase())}"?`,
          showCancelButton: true,
          confirmButtonColor: "#25607A",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, deseo Continuar!",
          cancelButtonText: "Cancelar",
        });
        if (res.isConfirmed) {
          showAlert({
            message: "Espera un momento.....",
            severity: "warning",
          });
          let posi;
          const copyLaboratorio = [...laboratorios];
          laboratorios.map((e, index) => {
            if (e.idInventarioLaboratorio === id) {
              posi = index;
            }
          });
          copyLaboratorio.splice(posi, 1);
          setLaboratorios(copyLaboratorio);
          setReload(true);
          showAlert({
            message: "Producto borrado con éxito.",
            severity: "success",
          });
        } else {
          setReload(true);
        }
      }
    }
  };

  const onGuardar = async () => {
    let respaldo = [];
    let sucursalesFiltered = [];
    if (editing) {
      model?.paqueteSucursal.map(sucursal => {
        if (!models.some(s => s.idSucursal == sucursal.idSucursal)) {
          respaldo.push({ ...sucursal.sucursal, active: true })
        }
      });
      let sucursalesToFilter = newObjSucursales.concat(respaldo);
      sucursalesToFilter.map(sucursal => {
        if (sucursal.active) {
          sucursalesFiltered.push(sucursal);
        }
      });
    } else {
      let sucursalesToFilter = newObjSucursales;
      sucursalesToFilter.map(sucursal => {
        if (sucursal.active) {
          sucursalesFiltered.push(sucursal);
        }
      });
    }
    if (sucursalesFiltered.length === 0) {
      showAlert({ message: 'Seleccione al menos una sucursal', severity: "warning", });
      return;
    }
    if (productos.length === 0 && productos.length === 0) {
      showAlert({ message: 'Seleccione al menos un Producto', severity: "warning", });
      return;
    }
    let body = { ...propiedades, paqueteSucursal: sucursalesFiltered, paqueteProductoInventario: productos, paqueteInventarioLaboratorio: laboratorios }
    if (editing) {
      body.idPaquete = id;
    }
    updateModel(body, true, "paquetes");
  };

  const addProducto = (tipo = "producto") => {
    setClearInputProducto(true);

    if (tipo == "producto") {
      if (producto?.idProductoInventario != null) {
        const repetido = productos.some(e => e.idProductoInventario === producto.idProductoInventario);
        if (repetido) {
          showAlert({
            message: "Este producto ya existe en este paquete",
            severity: "error",
          });
          setProducto(null);
        } else {
          const obj = { idProductoInventario: producto.idProductoInventario }
          productoActualizar({
            urlUpdate: 'producto-inventario',
            properties: obj,
            loading: true,
          });
          setProducto(null);
        }
      }
    } else if (tipo == "laboratorio") {
      if (laboratorio?.idInventarioLaboratorio != null) {
        const repetido = laboratorios.some(e => e.idInventarioLaboratorio === laboratorio.idInventarioLaboratorio);
        if (repetido) {
          showAlert({
            message: "Este producto ya existe en este paquete",
            severity: "error",
          });
          setLaboratorio(null);
        } else {
          const obj = { idInventarioLaboratorio: laboratorio.idInventarioLaboratorio }
          laboratorioActualizar({
            urlUpdate: 'inventario-laboratorio',
            properties: obj,
            loading: true,
          });
          setLaboratorio(null);
        }
      }
    }


  }

  const getFilterArraySucursales = (array) => {
    return array.map((e) => {
      if (editing) {
        if (model) {
          if (model.paqueteSucursal.some(sucursal => sucursal.idSucursal == e.idSucursal)) {
            return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: true }
          } else {
            return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
          }
        } else {
          return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
        }
      } else {
        return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
      }
    })
  }

  ////////////////////////////////////////////////////////////////////////
  //                            Hooks Def                               //
  ////////////////////////////////////////////////////////////////////////
  const [_producto, productoCargando, productoActualizar] = useGet({
    initUrl: 'producto-inventario',
    params: {
      idSucursal: idSucursal
    },
    action: true,
  });

  const [_laboratorio, laboratorioCargando, laboratorioActualizar] = useGet({
    initUrl: 'inventario-laboratorio',
    params: {
      idSucursal: idSucursal
    },
    action: true,
  });

  useMemo(() => {
    if (_producto?.resultado?.length > 0 && _producto?.resultado?.length < 2 && first) {
      let productoDefault = {
        ..._producto.resultado[0],
        idSucursal: sucursal.idSucursal,
      };
      setProductos([...productos, productoDefault]);
    } else {
      if (_producto?.resultado?.length > 0) {
        setFirst(true);
      }
    }
  }, [_producto]);

  useMemo(() => {
    if (_laboratorio?.resultado?.length > 0 && _laboratorio?.resultado?.length < 2 && firstLaboratorio) {
      let laboratorioDefault = {
        ..._laboratorio.resultado[0],
        idSucursal: sucursal.idSucursal,
      };
      setLaboratorios([...laboratorios, laboratorioDefault]);
    } else {
      if (_laboratorio?.resultado?.length > 0) {
        setFirstLaboratorio(true);
      }
    }
  }, [_laboratorio]);

  useEffect(() => {
    if (clearInputProducto) {
      setClearInputProducto(false);
    }
  }, [clearInputProducto]);

  useEffect(() => {
    if (clearInputSucursal) {
      setClearInputSucursal(false);
    }
  }, [clearInputSucursal]);

  useEffect(() => {
    if (clearInputLaboratorio) {
      setClearInputLaboratorio(false);
    }
  }, [clearInputLaboratorio]);

  const setProductoCallback = useCallback((e, v) => {
    if (v !== "" && v) {
      setProducto(v);
    } else {
      setProducto(null);
    }
  }, [setProducto]);

  const setSucursalCallback = useCallback((e, v) => {
    if (v !== "" && v) {
      setSucursal(v);
    } else {
      setSucursal(null);
    }
  }, [setSucursal]);

  const setLaboratorioCallback = useCallback((e, v) => {
    if (v !== "" && v) {
      setLaboratorio(v);
    } else {
      setLaboratorio(null);
    }
  }, [setLaboratorio]);

  useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      let newArr = [];
      model.paqueteProductoInventario.map(producto => {
        newArr.push(producto.productoInventario);
      })
      let newArr2 = [];
      model.paqueteInventarioLaboratorio.map(laboratorio => {
        newArr2.push(laboratorio.inventarioLaboratorio);
      })
      setPropiedades(model);
      const newObj = getFilterArraySucursales(models);
      setNewObjSucursales(newObj);
      setProductos(newArr);
      setLaboratorios(newArr2);
    }
    return () => {
      mounted = false;
      setPropiedades({
        nombrePaquete: "",
        descripcion: "",
        paqueteProductoInventario: [],
        paqueteInventarioLaboratorio: [],
      });
    };
  }, [model]);

  useEffect(() => {
    const newObj = getFilterArraySucursales(models);
    setNewObjSucursales(newObj);
  }, [models]);

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title={(!editing ? "NUEVO" : "EDITAR") + " PAQUETE"}
        links={[
          {
            to: "/paquetes",
            icon: <MedicationLiquid fontSize="small" />,
            label: "Paquetes",
          },
          {
            to: !editing ? "/pauqete/nuevo" : "/pauqete/editar/" + id,
            icon: !editing ? <Add /> : <Edit />,
            label: !editing
              ? "Nuevo"
              : "Editar - " +
              capitalizeFirst(
                propiedades?.nombrePaquete.toLocaleLowerCase()
              ),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div><strong>{"INFORMACIÓN DEL PAQUETE"}</strong></div>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputRegex
            label="Nombre Paquete"
            variant="standard"
            fullWidth
            size="medium"
            helperText={modelError?.nombrePaquete}
            error={Boolean(modelError?.nombrePaquete)}
            typeInput='text'
            value={propiedades?.nombrePaquete}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                nombrePaquete: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputRegex
            label="Descripción"
            variant="standard"
            fullWidth
            helperText={modelError?.descripcion}
            error={Boolean(modelError?.descripcion)}
            size="medium"
            typeInput='text'
            value={propiedades?.descripcion}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                descripcion: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <AsyncAutocompleteSearchBeta
            variant="standard"
            label="Sucursal"
            ruta="sucursal"
            labelProp="nombreSucursal"
            extraParams={sucursalParamsMemo}
            clearInput={clearInputSucursal}
            onChange={setSucursalCallback}
            campoError="idSucursal"
          />
        </Grid>
      </Grid>

      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} md={10}>
          <AsyncAutocompleteSearchBeta
            variant="standard"
            label="Productos"
            disabled={sucursal == null}
            ruta="producto-inventario"
            labelProp="nombreProducto"
            extraParams={productoParamsMemo}
            clearInput={clearInputProducto}
            onChange={setProductoCallback}
            campoError="idProductoInventario"
          />
        </Grid>
        <Grid item xs={12} md={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <LoadingButton
            loading={productoCargando}
            onClick={(e) => { addProducto() }}
            style={{ color: '#FFF' }} variant="contained"><AddIcon /> Agregar Producto</LoadingButton>
        </Grid>
        {(productos.length > 0) && (
          <>
            <Grid item xs={12}>
              <Table className={entradas.tableEntradas}>
                <TableHead>
                  <TableRowModify>
                    <TableCellModify> # </TableCellModify>
                    <TableCellModify> Nombre Producto </TableCellModify>
                    <TableCellModify> Precio Proveedor </TableCellModify>
                    <TableCellModify> </TableCellModify>
                  </TableRowModify>
                </TableHead>
                <TableBody>
                  {reload ? productos?.map((prod, index) => {
                    return (
                      <TableRowModify key={index}>
                        <TableCellModify> {index + 1} </TableCellModify>
                        <TableCellModify> {prod?.nombreProducto} </TableCellModify>
                        <TableCellModify> {internationalCurrency(prod?.precio)} </TableCellModify>
                        <TableCellModify>
                          <IconButton
                            onClick={e => {
                              onDropServicio(prod?.idProductoInventario, prod?.nombreProducto);
                              setReload(false);
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </TableCellModify>
                      </TableRowModify>
                    )
                  }) : (
                    <TableRowModify>
                      <TableCellModify></TableCellModify>
                      <TableCellModify></TableCellModify>
                      <TableCellModify> <CircularProgress /> </TableCellModify>
                      <TableCellModify></TableCellModify>
                    </TableRowModify>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </>
        )}
      </Grid>

      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} md={10}>
          <AsyncAutocompleteSearchBeta
            variant="standard"
            label="Producto Laboratorio"
            ruta="inventario-laboratorio"
            disabled={sucursal == null}
            labelProp="nombreProducto"
            extraParams={laboratorioParamsMemo}
            clearInput={clearInputLaboratorio}
            onChange={setLaboratorioCallback}
            campoError="idInventarioLaboratorio"
          />
        </Grid>
        <Grid item xs={12} md={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <LoadingButton
            loading={laboratorioCargando}
            onClick={(e) => { addProducto("laboratorio") }}
            style={{ color: '#FFF' }} variant="contained"><AddIcon /> Agregar Producto Laboratorio</LoadingButton>
        </Grid>
        {(laboratorios.length > 0) && (
          <>
            <Grid item xs={12}>
              <Table className={entradas.tableEntradas}>
                <TableHead>
                  <TableRowModify>
                    <TableCellModify> # </TableCellModify>
                    <TableCellModify> Nombre Producto </TableCellModify>
                    <TableCellModify> Precio Proveedor </TableCellModify>
                    <TableCellModify> </TableCellModify>
                  </TableRowModify>
                </TableHead>
                <TableBody>
                  {reload ? laboratorios?.map((lab, index) => {
                    return (
                      <TableRowModify key={index}>
                        <TableCellModify> {index + 1} </TableCellModify>
                        <TableCellModify> {lab?.nombreProducto} </TableCellModify>
                        <TableCellModify> {internationalCurrency(lab?.precioProveedor)} </TableCellModify>
                        <TableCellModify>
                          <IconButton
                            onClick={e => {
                              onDropServicio(lab?.idInventarioLaboratorio, lab?.nombreProducto, "laboratorio");
                              setReload(false);
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </TableCellModify>
                      </TableRowModify>
                    )
                  }) : (
                    <TableRowModify>
                      <TableCellModify></TableCellModify>
                      <TableCellModify></TableCellModify>
                      <TableCellModify> <CircularProgress /> </TableCellModify>
                      <TableCellModify></TableCellModify>
                    </TableRowModify>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </>
        )}
      </Grid>

      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <strong>{"SUCURSALES"}</strong>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <TablePagination
            header={header}
            data={newObjSucursales}
            extraRows={[
              {
                prop: "accion",
                name: "Acción",
                sortable: false,
                cell: (row, index) =>
                  <Switch
                    disabled={row && (row?.eliminado === null && row?.active === null) ? true : false}
                    checked={row?.active}
                    onChange={({ target }) => {
                      newObjSucursales[index].active = target.checked;
                      setNewObjSucursales([...newObjSucursales]);
                    }}
                  />,
              },
            ]}
          />
        </Grid>

      </Grid>
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading || updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["paquetes"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<SaveIcon />}
              style={{ color: "#fff" }}
            >
              {(!editing ? "GUARDAR" : "MODIFICAR")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default PaquetesDetail;