/* eslint-disable no-unused-vars */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import React, { Component } from 'react'
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import Tabs from '../../components/TabsRework';
import Listado from './listado';
import Entradas from './entradas';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import ViewListIcon from '@mui/icons-material/ViewList';
import AddIcon from '@mui/icons-material/Add';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
export default class Index extends Component {
  constructor(props) {
    super(props);
    this.pos = 0;
  }
  ////////////////////////////////////////////////////////////////////////
  //                              Vars                                  //
  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////
  //                           Funcions Def                             //
  ////////////////////////////////////////////////////////////////////////
  changePos = _pos => {
    this.pos = _pos;
    this.forceUpdate();
  }
  ////////////////////////////////////////////////////////////////////////
  //                            Hooks Def                               //
  ////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <Tabs tabs={
        [
          {
            title: "Listado",
            icon: <ViewListIcon />,
            content: <Listado changePos={this.changePos} />
          }, {
            title: "Entradas",
            icon: <AddIcon />,
            content: <Entradas changePos={this.changePos} />
          }
        ]}
        pos={this.pos}
        changePos={this.changePos}
      />
    )
  }
}