import { Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import React from "react"

const exploracion = ({ datos, setDatos, ...props }) => {
    return (
        <>
            <Grid item xs={12} sx={{ margin: "20px 0", color: "#FFFFFF", background: "black", padding: "10px" }}>
                EXPLORACIÓN FISICA
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"peso kg:".toLocaleUpperCase()}
                    value={datos?.exploracion?.peso}
                    placeholder={"peso kg:".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, peso: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"talla cm:".toLocaleUpperCase()}
                    value={datos?.exploracion?.talla}
                    placeholder={"talla cm:".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, talla: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"fc x'".toLocaleUpperCase()}
                    value={datos?.exploracion?.fc}
                    placeholder={"fc x'".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, fc: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"fr x'".toLocaleUpperCase()}
                    value={datos?.exploracion?.fr}
                    placeholder={"fr x'".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, fr: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"ta mmhg".toLocaleUpperCase()}
                    value={datos?.exploracion?.ta}
                    placeholder={"ta mmhg".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, ta: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"imc".toLocaleUpperCase()}
                    value={datos?.exploracion?.imc}
                    placeholder={"imc".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, imc: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"indice Robust".toLocaleUpperCase()}
                    value={datos?.exploracion?.indiceRobust}
                    placeholder={"indice Robust".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, indiceRobust: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} sx={{ padding: "10px" }}>
                AGUDEZA VISUAL
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"od".toLocaleUpperCase()}
                    value={datos?.exploracion?.od}
                    placeholder={"od".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, od: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"oi".toLocaleUpperCase()}
                    value={datos?.exploracion?.oi}
                    placeholder={"oi".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, oi: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"ambos".toLocaleUpperCase()}
                    value={datos?.exploracion?.ambos}
                    placeholder={"ambos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, ambos: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} sx={{ padding: "10px" }}>
                C/L
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"od".toLocaleUpperCase()}
                    value={datos?.exploracion?.odcl}
                    placeholder={"od".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, odcl: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"oi".toLocaleUpperCase()}
                    value={datos?.exploracion?.oicl}
                    placeholder={"oi".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, oicl: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"ambos".toLocaleUpperCase()}
                    value={datos?.exploracion?.amboscl}
                    placeholder={"ambos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, amboscl: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} >
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"ojos".toLocaleUpperCase()}
                    value={datos?.exploracion?.ojos}
                    placeholder={"ojos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, ojos: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"pupilas".toLocaleUpperCase()}
                    value={datos?.exploracion?.pupilas}
                    placeholder={"pupilas".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, pupilas: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"campimetria".toLocaleUpperCase()}
                    value={datos?.exploracion?.campimetria}
                    placeholder={"campimetria".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, campimetria: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"sentido Cromatico".toLocaleUpperCase()}
                    value={datos?.exploracion?.sentidoCromatico}
                    placeholder={"sentido Cromatico".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, sentidoCromatico: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"nariz".toLocaleUpperCase()}
                    value={datos?.exploracion?.nariz}
                    placeholder={"nariz".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, nariz: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"tabique Nasal".toLocaleUpperCase()}
                    value={datos?.exploracion?.tabiqueNasal}
                    placeholder={"tabique Nasal".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, tabiqueNasal: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"cornetes".toLocaleUpperCase()}
                    value={datos?.exploracion?.cornetes}
                    placeholder={"cornetes".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, cornetes: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "0px 10px 10px 10px" }}>
                <InputLabel id="polipos-label">{"polipos".toLocaleUpperCase()}</InputLabel>
                <Select
                    fullWidth
                    labelId="polipos-label"
                    label={"polipos".toLocaleUpperCase()}
                    value={datos?.exploracion?.polipos}
                    placeholder={"polipos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, polipos: e.target.value } }) }}
                >
                    <MenuItem value={"SI"}>SI</MenuItem>
                    <MenuItem value={"NO"}>NO</MenuItem>
                </Select>
            </Grid>
            <Grid xs={12} md={6} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"orofaringe".toLocaleUpperCase()}
                    value={datos?.exploracion?.orofaringe}
                    placeholder={"orofaringe".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, orofaringe: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={6} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"dentadura".toLocaleUpperCase()}
                    value={datos?.exploracion?.dentadura}
                    placeholder={"dentadura".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, dentadura: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"oidos".toLocaleUpperCase()}
                    value={datos?.exploracion?.oidos}
                    placeholder={"oidos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, oidos: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"conducto Auditivo Externo".toLocaleUpperCase()}
                    value={datos?.exploracion?.conductoAuditivoExterno}
                    placeholder={"conducto Auditivo Externo".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, conductoAuditivoExterno: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"membrana Timpano".toLocaleUpperCase()}
                    value={datos?.exploracion?.membranaTimpano}
                    placeholder={"membrana Timpano".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, membranaTimpano: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} sx={{ padding: "10px" }}>
                TORAX
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"amplexion".toLocaleUpperCase()}
                    value={datos?.exploracion?.amplexion}
                    placeholder={"amplexion".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, amplexion: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"amplexacion".toLocaleUpperCase()}
                    value={datos?.exploracion?.amplexacion}
                    placeholder={"amplexacion".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, amplexacion: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"campos Pulmonares".toLocaleUpperCase()}
                    value={datos?.exploracion?.camposPulmonares}
                    placeholder={"campos Pulmonares".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, camposPulmonares: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"pt".toLocaleUpperCase()}
                    value={datos?.exploracion?.pt}
                    placeholder={"pt".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, pt: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "0px 10px 10px 10px" }}>
                <InputLabel id="respiracionNormal-label">{"respiracion Normal".toLocaleUpperCase()}</InputLabel>
                <Select
                    fullWidth
                    labelId="respiracionNormal-label"
                    label={"respiracionNormal".toLocaleUpperCase()}
                    value={datos?.exploracion?.respiracionNormal}
                    placeholder={"respiracionNormal".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, respiracionNormal: e.target.value } }) }}
                >
                    <MenuItem value={"SI"}>SI</MenuItem>
                    <MenuItem value={"NO"}>NO</MenuItem>
                </Select>
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "0px 10px 10px 10px" }}>
                <InputLabel id="estertores-label">{"estertores".toLocaleUpperCase()}</InputLabel>
                <Select
                    fullWidth
                    labelId="estertores-label"
                    label={"estertores".toLocaleUpperCase()}
                    value={datos?.exploracion?.estertores}
                    placeholder={"estertores".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, estertores: e.target.value } }) }}
                >
                    <MenuItem value={"SI"}>SI</MenuItem>
                    <MenuItem value={"NO"}>NO</MenuItem>
                </Select>
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"tipo".toLocaleUpperCase()}
                    value={datos?.exploracion?.tipo}
                    placeholder={"tipo".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, tipo: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"localizacion".toLocaleUpperCase()}
                    value={datos?.exploracion?.localizacion}
                    placeholder={"localizacion".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, localizacion: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={12} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"cardiovascular".toLocaleUpperCase()}
                    value={datos?.exploracion?.cardiovascular}
                    placeholder={"cardiovascular".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, cardiovascular: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} sx={{ padding: "10px" }}>
                ABDOMEN
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"perimetro cm".toLocaleUpperCase()}
                    value={datos?.exploracion?.perimetro}
                    placeholder={"perimetro cm".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, perimetro: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"peristaltismo".toLocaleUpperCase()}
                    value={datos?.exploracion?.peristaltismo}
                    placeholder={"peristaltismo".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, peristaltismo: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "0px 10px 10px 10px" }}>
                <InputLabel id="visceromeglias-label">{"visceromeglias".toLocaleUpperCase()}</InputLabel>
                <Select
                    fullWidth
                    labelId="visceromeglias-label"
                    label={"visceromeglias".toLocaleUpperCase()}
                    value={datos?.exploracion?.visceromeglias}
                    placeholder={"visceromeglias".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, visceromeglias: e.target.value } }) }}
                >
                    <MenuItem value={"SI"}>SI</MenuItem>
                    <MenuItem value={"NO"}>NO</MenuItem>
                </Select>
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "0px 10px 10px 10px" }}>
                <InputLabel id="hernias-label">{"hernias".toLocaleUpperCase()}</InputLabel>
                <Select
                    fullWidth
                    labelId="hernias-label"
                    label={"hernias".toLocaleUpperCase()}
                    value={datos?.exploracion?.hernias}
                    placeholder={"hernias".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, hernias: e.target.value } }) }}
                >
                    <MenuItem value={"SI"}>SI</MenuItem>
                    <MenuItem value={"NO"}>NO</MenuItem>
                </Select>
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"comentario hernias".toLocaleUpperCase()}
                    value={datos?.exploracion?.comentarioHernias}
                    placeholder={"comentario hernias".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, comentarioHernias: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"organos Genitales".toLocaleUpperCase()}
                    value={datos?.exploracion?.organosGenitales}
                    placeholder={"organos Genitales".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, organosGenitales: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"hemorroides".toLocaleUpperCase()}
                    value={datos?.exploracion?.hemorroides}
                    placeholder={"hemorroides".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, hemorroides: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} sx={{ padding: "10px" }}>
                OSTEOMUSCULAR
            </Grid>
            <Grid xs={12} sx={{ padding: "10px" }}>
                COLUMNA CERVICAL
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"movilidad".toLocaleUpperCase()}
                    value={datos?.exploracion?.movilidad}
                    placeholder={"movilidad".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, movilidad: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"flexion y extension".toLocaleUpperCase()}
                    value={datos?.exploracion?.flexionExtension}
                    placeholder={"flexion y extension".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, flexionExtension: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"rotacionLateralDerecha".toLocaleUpperCase()}
                    value={datos?.exploracion?.rotacionLateralDerecha}
                    placeholder={"rotacionLateralDerecha".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, rotacionLateralDerecha: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"rotacion Lateral Izquierda".toLocaleUpperCase()}
                    value={datos?.exploracion?.rotacionLateralIzquierda}
                    placeholder={"rotacion Lateral Izquierda".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, rotacionLateralIzquierda: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"inclinacion Lateral Izquierda".toLocaleUpperCase()}
                    value={datos?.exploracion?.inclinacionLateralIzquierda}
                    placeholder={"inclinacion Lateral Izquierda".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, inclinacionLateralIzquierda: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"inclinacion Lateral Derecha".toLocaleUpperCase()}
                    value={datos?.exploracion?.inclinacionLateralDerecha}
                    placeholder={"inclinacion Lateral Derecha".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, inclinacionLateralDerecha: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"fuerza Muscular".toLocaleUpperCase()}
                    value={datos?.exploracion?.fuerzaMuscular}
                    placeholder={"fuerza Muscular".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, fuerzaMuscular: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} sx={{ padding: "10px" }}>
                COLUMNA LUMBOSCARA
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"movilidad".toLocaleUpperCase()}
                    value={datos?.exploracion?.movilidad2}
                    placeholder={"movilidad".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, movilidad2: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"flexion y extension".toLocaleUpperCase()}
                    value={datos?.exploracion?.flexionExtension2}
                    placeholder={"flexion y extension".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, flexionExtension2: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"rotacion Lateral Derecha".toLocaleUpperCase()}
                    value={datos?.exploracion?.rotacionLateralDerecha2}
                    placeholder={"rotacion Lateral Derecha".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, rotacionLateralDerecha2: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"rotacion Lateral Izquierda".toLocaleUpperCase()}
                    value={datos?.exploracion?.rotacionLateralIzquierda2}
                    placeholder={"rotacion Lateral Izquierda".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, rotacionLateralIzquierda2: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"inclinacion Lateral Izquierda".toLocaleUpperCase()}
                    value={datos?.exploracion?.inclinacionLateralIzquierda2}
                    placeholder={"inclinacion Lateral Izquierda".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, inclinacionLateralIzquierda2: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"inclinacion Lateral Derecha".toLocaleUpperCase()}
                    value={datos?.exploracion?.inclinacionLateralDerecha2}
                    placeholder={"inclinacion Lateral Derecha".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, inclinacionLateralDerecha2: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"fuerza Muscular".toLocaleUpperCase()}
                    value={datos?.exploracion?.fuerzaMuscular2}
                    placeholder={"fuerza Muscular".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, fuerzaMuscular2: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} sx={{ padding: "10px" }}>
                EXTREMIDADES
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"movilidad".toLocaleUpperCase()}
                    value={datos?.exploracion?.movilidad3}
                    placeholder={"movilidad".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, movilidad3: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"integridad".toLocaleUpperCase()}
                    value={datos?.exploracion?.integridad}
                    placeholder={"integridad".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, integridad: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"acortamiento".toLocaleUpperCase()}
                    value={datos?.exploracion?.acortamiento}
                    placeholder={"acortamiento".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, acortamiento: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"sensibilidad".toLocaleUpperCase()}
                    value={datos?.exploracion?.sensibilidad}
                    placeholder={"sensibilidad".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, sensibilidad: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"varices".toLocaleUpperCase()}
                    value={datos?.exploracion?.varices}
                    placeholder={"varices".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, varices: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"reflejos".toLocaleUpperCase()}
                    value={datos?.exploracion?.reflejos}
                    placeholder={"reflejos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, reflejos: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"fuerza Muscular".toLocaleUpperCase()}
                    value={datos?.exploracion?.fuerzaMuscular3}
                    placeholder={"fuerza Muscular".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, fuerzaMuscular3: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} >
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"lasague".toLocaleUpperCase()}
                    value={datos?.exploracion?.lasague}
                    placeholder={"lasague".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, lasague: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"patrick".toLocaleUpperCase()}
                    value={datos?.exploracion?.patrick}
                    placeholder={"patrick".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, patrick: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"observaciones exploracion fisica".toLocaleUpperCase()}
                    value={datos?.exploracion?.observaciones}
                    placeholder={"observaciones exploracion fisica".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, observaciones: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"resultado biometría hemática".toLocaleUpperCase()}
                    value={datos?.exploracion?.biometriaHematica}
                    placeholder={"resultado biometría hemática".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, biometriaHematica: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"resultado glucosa, urea, creatinina, colesterol, trigliceridos:".toLocaleUpperCase()}
                    value={datos?.exploracion?.resultadosGen}
                    placeholder={"resultado glucosa, urea, creatinina, colesterol, trigliceridos:".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, resultadosGen: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"resultado glicohemoglobina:".toLocaleUpperCase()}
                    value={datos?.exploracion?.glicohemoglobina}
                    placeholder={"resultado glicohemoglobina:".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, glicohemoglobina: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"resultado examen general de orina".toLocaleUpperCase()}
                    value={datos?.exploracion?.examenOrina}
                    placeholder={"resultado examen general de orina".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, exploracion: { ...datos?.exploracion, examenOrina: e.target.value } }) }}
                />
            </Grid>
        </>
    )
}

export default exploracion