
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useModel } from "../../hooks/useModel";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { useAlert } from "../../hooks/useAlert";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import PageHeader from "../../components/PageHeader";
import {
  Grid,
  Divider,
  Paper,
  Table,
  CircularProgress,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import Loading from "../../components/FullScreenLoader";
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { tableCellStyles, tableRowStyles, entradasStyle, capitalizeFirst, internationalCurrency } from '../../utils';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Edit, MedicationLiquid } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import AllInboxIcon from '@mui/icons-material/AllInbox';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const useEntradasStyle = entradasStyle();

const GruposDetails = ({ match, history, dialog }) => {
  ////////////////////////////////////////////////////////////////////////
  //                              Vars                                  //
  ////////////////////////////////////////////////////////////////////////
  const id = match.params.idGrupoCampana;
  const classes = useStyles();
  const { showAlert } = useAlert();
  const entradas = useEntradasStyle();
  const [reload, setReload] = useState(true);
  const [contactos, setContactos] = useState([]);
  const [clearInputContacto, setClearInputContacto] = useState(false);
  const editing = !!id;
  const [admin] = useLocalStorage("admin", null);
  const { permiso } = useAuth();
  const [propiedades, setPropiedades] = useState({
    nombreGrupo: "",
    contactos: [],
    contacto: "",
    tipo: "",
  });
  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "grupo-contacto",
      expand: "datosCampana",
      id,
      redirectOnPost: true,
    });
  ////////////////////////////////////////////////////////////////////////
  //                           Funcions Def                             //
  ////////////////////////////////////////////////////////////////////////
  const onDropContacto = async (id, nombre) => {
    if (id) {
      const res = await Swal.fire({
        title: "Borrar",
        icon: "warning",
        text: `¿Estás seguro de que deseas borrar el producto seleccionado "${capitalizeFirst(nombre.toLowerCase())}"?`,
        showCancelButton: true,
        confirmButtonColor: "#25607A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, deseo Continuar!",
        cancelButtonText: "Cancelar",
      });
      if (res.isConfirmed) {
        showAlert({
          message: "Espera un momento.....",
          severity: "warning",
        });
        let posi;
        const copyContactos = [...contactos];
        contactos.map((e, index) => {
          if (e.idDatosCampana === id) {
            posi = index;
          }
        });
        copyContactos.splice(posi, 1);
        setContactos(copyContactos);
        setReload(true);
        showAlert({
          message: "Contacto borrado con éxito.",
          severity: "success",
        });
      } else {
        setReload(true);
      }
    }
  };

  const onGuardar = async () => {
    if (contactos.length === 0) {
      showAlert({ message: 'Agrege al menos un Contacto', severity: "warning", });
      return;
    }
    let body = { ...propiedades, contactos: contactos }
    if (editing) {
      body.idGrupoContacto = id;
    }
    updateModel(body, true, "grupos");
  };

  const addContacto = () => {
    setClearInputContacto(true);
    if (propiedades?.contacto != null) {
      const repetido = contactos.some(e => e.contacto === propiedades.contacto);
      if (repetido) {
        showAlert({
          message: "Este contacto ya existe en este paquete",
          severity: "error",
        });
        setPropiedades({ ...propiedades, tipo: "", contacto: "" });
      } else {
        const obj = { tipo: propiedades.tipo, contacto: propiedades.contacto }
        let newContactos = contactos;
        newContactos.push(obj);
        setContactos(newContactos);
        setPropiedades({ ...propiedades, tipo: "", contacto: "" });
      }
    }
  }

  ////////////////////////////////////////////////////////////////////////
  //                            Hooks Def                               //
  ////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (clearInputContacto) {
      setClearInputContacto(false);
    }
  }, [clearInputContacto]);

  useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setPropiedades(model);
      setContactos(model.datosCampana);
    }
    return () => {
      mounted = false;
      setPropiedades({
        nombreGrupo: "",
        contactos: [],
        contacto: "",
        tipo: "",
      });
    };
  }, [model]);

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title={(!editing ? "NUEVO" : "EDITAR") + "  GRUPO"}
        links={[
          {
            to: "/grupos",
            icon: <AllInboxIcon fontSize="small" />,
            label: "Grupo Contacto",
          },
          {
            to: !editing ? "/pauqete/nuevo" : "/pauqete/editar/" + id,
            icon: !editing ? <Add /> : <Edit />,
            label: !editing
              ? "Nuevo"
              : "Editar - " +
              capitalizeFirst(
                propiedades?.nombreGrupo.toLocaleLowerCase()
              ),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div><strong>{"INFORMACIÓN DE GRUPO"}</strong></div>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <InputRegex
            label="Nombre del Grupo"
            variant="standard"
            fullWidth
            size="medium"
            helperText={modelError?.nombreGrupo}
            error={Boolean(modelError?.nombreGrupo)}
            typeInput='text'
            value={propiedades?.nombreGrupo}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                nombreGrupo: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} md={5}>
          <InputRegex
            label="Nombre del Contacto"
            variant="standard"
            fullWidth
            size="medium"
            helperText={modelError?.tipo}
            error={Boolean(modelError?.tipo)}
            typeInput='text'
            value={propiedades?.tipo}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                tipo: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <InputRegex
            label="Contacto"
            variant="standard"
            fullWidth
            size="medium"
            helperText={modelError?.contacto}
            error={Boolean(modelError?.contacto)}
            typeInput='text'
            value={propiedades?.contacto}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                contacto: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={12} md={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <LoadingButton
            onClick={(e) => { addContacto() }}
            style={{ color: '#FFF' }} variant="contained"><AddIcon /> Agregar Contacto</LoadingButton>
        </Grid>
        {(contactos.length > 0) && (
          <>
            <Grid item xs={12}>
              <Table className={entradas.tableEntradas}>
                <TableHead>
                  <TableRowModify>
                    <TableCellModify> # </TableCellModify>
                    <TableCellModify> Nombre Contacto </TableCellModify>
                    <TableCellModify> Contacto </TableCellModify>
                    <TableCellModify> </TableCellModify>
                  </TableRowModify>
                </TableHead>
                <TableBody>
                  {reload ? contactos?.map((prod, index) => {
                    return (
                      <TableRowModify key={index}>
                        <TableCellModify> {index + 1} </TableCellModify>
                        <TableCellModify> {prod?.tipo} </TableCellModify>
                        <TableCellModify> {prod?.contacto} </TableCellModify>
                        <TableCellModify>
                          <IconButton
                            onClick={e => {
                              onDropContacto(prod?.tipo, prod?.tipo);
                              setReload(false);
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </TableCellModify>
                      </TableRowModify>
                    )
                  }) : (
                    <TableRowModify>
                      <TableCellModify></TableCellModify>
                      <TableCellModify></TableCellModify>
                      <TableCellModify> <CircularProgress /> </TableCellModify>
                      <TableCellModify></TableCellModify>
                    </TableRowModify>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading || updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["campanas"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<SaveIcon />}
              style={{ color: "#fff" }}
            >
              {(!editing ? "GUARDAR" : "MODIFICAR")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default GruposDetails;