/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import { Divider, Grid, Paper, TextField } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { LoadingButton } from "@mui/lab"
import { Save } from "@mui/icons-material"
import { useModel } from "../../../../hooks/useModel"
import { useAuth } from "../../../../hooks/useAuth"
import { useAlert } from "../../../../hooks/useAlert"
import { useLocalStorage } from "../../../../hooks/useLocalStorage"
import InputRegex from "../../../../components/InputRegex"
import { useEffect } from "react"

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}))
export const SignosVitalesSomatometriaComponent = ({
  idEvaluacion,
  idOrdenServicio,
  setDisabledByName,
  setGuardado,
  signos,
}) => {
  const classes = useStyles()
  const id = idEvaluacion
  const [admin] = useLocalStorage("admin", null)
  const { showAlert } = useAlert()
  const [idSucursal] = useLocalStorage("idSucursal", null)
  const { permiso } = useAuth()
  const [propiedades, setPropiedades] = useState(
    signos
      ? signos
      : {
        sistolica: "",
        diastolica: "",
        frecuenciaCardiaca: "",
        frecuenciaRespiratoria: "",
        temperatura: "",
        saturacionOxigeno: "",
        peso: "",
        glasgow: "",
        estatura: "",
        imc: "",
        perimetroCintura: "",
        perimetroCadera: "",
        indiceCinturaCadera: "",
        personalEnfermeria: "",
        medico: "",
        cp: "",
        dextrosis: "0",
      }
  )

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "evaluaciones",
      id,
      redirectOnPost: false,
    })

  useEffect(() => {
    if (Object.keys(modelError)?.length > 0 && !updateModelLoading) {
      console.log(modelError)
      Object.keys(modelError).forEach(
        error => {
          console.log(modelError[error])
          showAlert({ message: modelError[error], severity: "warning" })
        }
      )
      setGuardado(false)
    }
  }, [modelError])

  const onGuardar = async () => {
    const body = propiedades

    let data = {
      idOrdenServicio,
      idEvaluacion,
      idSucursal: 2,
      SignosVitalesSomatometria: { ...body, imc: Number.isNaN(body?.imc) ? 0 : body?.imc, indiceCinturaCadera: Number.isNaN(body?.indiceCinturaCadera) ? 0 : body?.indiceCinturaCadera },
    }
    setDisabledByName("SignosVitalesSomatometriaComponent", true)
    await updateModel(data, true, "hospitalito/nuevaConsulta")
    setGuardado(true);
  }
  return (
    <>
      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <strong>{"SIGNOS VITALES Y SOMATOMETRÍA"}</strong>
          </div>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <p style={{ textAlign: "center" }}>PRESIÓN ARTERIAL</p>
        </Grid>

        <Grid
          item
          md={12}
          style={{ display: "flex", flexWrap: "wrap", marginTop: "-15px" }}
        >
          <Grid item xs={12} md={4} style={{ padding: "0px 5px" }}>
            <TextField
              label="Sistólica"
              variant="standard"
              fullWidth
              size="medium"
              value={propiedades.sistolica}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  sistolica: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "0px 5px" }}>
            <TextField
              label="Diastólica"
              variant="standard"
              fullWidth
              size="medium"
              value={propiedades.diastolica}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  diastolica: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "0px 5px" }}>
            <TextField
              label="Frecuencia Cardiaca"
              variant="standard"
              fullWidth
              size="medium"
              value={propiedades.frecuenciaCardiaca}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  frecuenciaCardiaca: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={3} style={{ padding: "0px 5px" }}>
            <TextField
              label="Frecuencia Respiratoria"
              variant="standard"
              fullWidth
              size="medium"
              value={propiedades.frecuenciaRespiratoria}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  frecuenciaRespiratoria: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={3} style={{ padding: "0px 5px" }}>
            <TextField
              label="Temperatura"
              variant="standard"
              fullWidth
              size="medium"
              value={propiedades.temperatura}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  temperatura: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={3} style={{ padding: "0px 5px" }}>
            <TextField
              typeInput="decimal"
              label="Saturación de Oxígeno"
              variant="standard"
              fullWidth
              size="medium"
              value={propiedades.saturacionOxigeno}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  saturacionOxigeno: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={3} style={{ padding: "0px 5px" }}>
            <TextField
              label="Dextrosis"
              variant="standard"
              fullWidth
              size="medium"
              value={propiedades.dextrosis}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  dextrosis: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={3} style={{ padding: "0px 5px" }}>
            <TextField
              typeInput="decimal"
              label="Escala de Glasgow"
              variant="standard"
              fullWidth
              size="medium"
              value={propiedades.glasgow}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  glasgow: e.target.value,
                }))
              }
            />
          </Grid>
        </Grid>

        <Grid item xs={12} marginTop={"15px"}>
          <p style={{ textAlign: "center" }}>CALCULO DEL I.M.C.</p>
        </Grid>

        <Grid
          item
          md={12}
          style={{ display: "flex", flexWrap: "wrap", marginTop: "-15px" }}
        >
          <Grid item xs={12} md={4} style={{ padding: "0px 5px" }}>
            <InputRegex
              label="Peso"
              typeInput="decimal"
              variant="standard"
              fullWidth
              size="medium"
              value={propiedades.peso}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  peso: e.target.value,
                  imc: e.target.value / Math.pow(propiedades.estatura, 2),
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "0px 5px" }}>
            <InputRegex
              typeInput="decimal"
              label="Estatura"
              variant="standard"
              fullWidth
              size="medium"
              value={propiedades.estatura}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  estatura: e.target.value,
                  imc: propiedades.peso / Math.pow(e.target.value, 2),
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "0px 5px" }}>
            <InputRegex
              disabled
              label="I.M.C."
              variant="standard"
              fullWidth
              size="medium"
              value={propiedades.imc}
            />
          </Grid>
        </Grid>

        <Grid item md={12} style={{ display: "flex", flexWrap: "wrap" }}>
          <Grid item xs={12} md={4} style={{ padding: "0px 5px" }}>
            <InputRegex
              typeInput="number"
              label="Perimetro de Cintura"
              variant="standard"
              fullWidth
              size="medium"
              value={propiedades.perimetroCintura}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  perimetroCintura: e.target.value,
                  indiceCinturaCadera: e.target.value / propiedades.perimetroCadera
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "0px 5px" }}>
            <InputRegex
              typeInput="number"
              label="Perimetro de Cadera"
              variant="standard"
              fullWidth
              size="medium"
              value={propiedades.perimetroCadera}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  perimetroCadera: e.target.value,
                  indiceCinturaCadera: propiedades.perimetroCintura / e.target.value
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "0px 5px" }}>
            <InputRegex
              disabled
              label="Indice Cintura-Cadera"
              variant="standard"
              fullWidth
              size="medium"
              value={propiedades.indiceCinturaCadera}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading ||
                  updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["evaluaciones"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<Save />}
              style={{ color: "#fff" }}
            >
              GUARDAR
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
