
//React
import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/variables";
import { baseStyles, capitalizeFirst } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import { Grid, Menu, MenuItem, Switch, FormControlLabel, TextField, Avatar, Modal, FormControl, InputLabel, Select } from "@mui/material";
import { useModels } from "../../hooks/useModels";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
//----------------------------------------------------------------------------------------------------------------------

//Librerias
import Swal from "sweetalert2";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import { AccessTimeFilled, Add, Delete, Edit, Search, StoreMallDirectory } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { NavLink } from "react-router-dom";
import { RiServiceFill } from "react-icons/ri";
//----------------------------------------------------------------------------------------------------------------------
const useStyles = baseStyles();

const modalStyle = {
  position: 'absolute',
  top: '50%',
  right: 'auto',
  left: '50%',
  bottom: 'auto',
  transform: 'translate(-50%, -50%)',
  marginRight: '-50%',
  background: '#FFF',
  outline: 'none',
  padding: '15px 0 0 0',
  borderRadius: '5px',
  boxShadow: '0 0 15px black'
};

const Index = () => {
  //Estados Iniciales
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [pagina, setPagina] = useState(1);
  const [openMenuRow, setOpenMenuRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [imgProductoModal, setImgProductoModal] = useState("");
  const [admin] = useLocalStorage("admin", null);
  const { user, permiso } = useAuth();
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  //----------------------------------------------------------------------------------------------------------------------

  //Estados Iniciales Controller
  const header = [
    { prop: "grupo", name: "Grupo", sortable: true},
    { prop: "claveP", name: "Clave", sortable: true },
    { prop: "unidadM", name: "Unidad Medica", sortable: true, },
    { prop: "concepto", name: "Concepto", sortable: true },
    { prop: "proceso", name: "Proceso", sortable: false },
    { prop: "valorUIva", name: "Precio", sortable: false },
  ];

  const [propiedades, setPropiedades] = useState({
    grupo: "",
    claveP: "",
    unidadM: "",
    concepto: "",
    proceso: "",
    valorUIva: "",
    urlFoto: "",
    eliminado: 0,
  });

  const parametrosInicialesMemo = useMemo(() => ({ name: "servicio", ordenar: "idServicio.desc" }),[]);
  const [ models, modelsLoading, modelsError, modelsPage, refreshModels, deleteModel, ] = useModels({ ...parametrosInicialesMemo });
  //----------------------------------------------------------------------------------------------------------------------

   const onSearchClicked = async (eliminado) => {
     let params = {};
     if (eliminado != null) {
       params = {
         ...parametrosInicialesMemo,
         ...propiedades,
         ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
       };
     } else {
       params = {
         ...parametrosInicialesMemo,
         ...propiedades,
         ...{ pagina: pagina, limite: perPage },
       };
     }
     await refreshModels({ isCargando: true, params });
   };
 
   const changePage = async (page) => {
     setPagina(page + 1);
     let params = {
       ...parametrosInicialesMemo,
       ...propiedades,
       ...{ pagina: page + 1, limite: perPage },
     };
     await refreshModels({ isCargando: true, params });
   };
 
   const changePageRow = async (per) => {
     setPerPage(per);
     const params = {
       ...parametrosInicialesMemo,
       ...propiedades,
       ...{ limite: per, pagina: pagina },
     };
     await refreshModels({ isCargando: true, params });
   };
 
   const onDeleteClicked = async (row, type) => {
     try {
       setOpenMenuRow(false);
       await Swal.fire({
         title: type,
         text: `¿Desea ${type} el dato seleccionado  "${capitalizeFirst(
           row.concepto.toLowerCase()
         )}"?`,
         icon: "warning",
         showCancelButton: true,
         confirmButtonColor: "#34AA44",
         cancelButtonColor: "#E72C33",
         confirmButtonText: "Si, deseo Continuar.",
       }).then((result) => {
         if (result.isConfirmed) {
           let params = {};
           params = {
             ...parametrosInicialesMemo,
             ...propiedades,
             ...{ pagina: pagina, limite: perPage },
           };
           deleteModel(row.idServicio, type, params);
         }
       });
     } catch (error) {
       console.log(error);
     }
   };
   //----------------------------------------------------------------------------------------------------------------------
   //Efectos
   //----------------------------------------------------------------------------------------------------------------------

 
  return (
    <>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" style={modalStyle} >
        <div style={{ backgroundColor: "#FFF" }}>
          <CloseIcon
            onClick={handleClose}
            style={{ marginBottom: "15px", marginRight: "15px", display: "flex", marginLeft: "auto", cursor: "pointer", }}
          />
          <img style={{ maxHeight: '600px', minHeight: '400px', width: '100%' }} src={imgProductoModal} alt="imgProductoModal" />
        </div>
      </Modal>
      <TablePageLayout title="Servicios" model="servicio" history={history}
        actionButton={{ to: "/servicio/nuevo", icon: <Add />, label: "Agregar Servicio", permiso: "servicios" }}
        links={[{ label: "Servicios", icon: <RiServiceFill fontSize="small" /> }]}
        SearchComponents={
         <>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              size="small"
              label="Grupo"
              variant="standard"
              onChange={(e) => setPropiedades(prevState => ({
                ...prevState,
                ...prevState.propiedades,
                grupo: e.target.value
              }))}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              size="small"
              label="concepto"
              variant="standard"
              onChange={(e) => setPropiedades(prevState => ({
                ...prevState,
                ...prevState.propiedades,
                concepto: e.target.value
              }))}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              size="small"
              label="clave"
              variant="standard"
              onChange={(e) => setPropiedades(prevState => ({
                ...prevState,
                ...prevState.propiedades,
                claveP: e.target.value
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                size="small"
                label="unidad Medica"
                variant="standard"
                onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  unidadM: e.target.value
                }))}
              />
          </Grid>
          <Grid xs={12} sm={4}></Grid>
          {(admin === true) &&(
            <Grid item xs={12} sm={4}>
              <FormControl size='small' fullWidth >
                <InputLabel id="demo-simple-select-autowidth-label">Sucursal</InputLabel>
                <Select
                  fullWidth
                  variant="standard"
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  label="Sucursales"
                  value={propiedades.idSucursal}
                  onChange={(e) => setPropiedades(prevState=>({
                    ...prevState,
                    ...prevState.propiedades,
                    idSucursal:e.target.value
                  }))}
                >
                  <MenuItem value="">Todas</MenuItem>
                  {models && models.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.idSucursal} >{item.nombreSucursal}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          )
          }
          <Grid item xs={12} sm={4}>
            <LoadingButton
              variant="contained"
              onClick={() => onSearchClicked(null)}
              loading={modelsLoading && true}
              style={{
                float: "right",
                color: "whitesmoke",
                justifyContent: "flex-end",
              }}
              startIcon={<Search />}
            />
          </Grid>
         </>
        }
        DisableComponents={
          <>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={modelsLoading && true}
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        eliminado: e.target.checked ? 1 : 0,
                      }));
                      onSearchClicked(e.target.checked ? 1 : 0);
                    }}
                  />
                }
                label="Ver Deshabilitados"
              />
            </Grid>

            <Grid item xs={4} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }} >
              <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                <IconButton color="error" aria-label="upload picture" component="span" >
                  <a
                    className={classes.btnPdf}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}pdf/servicios?${"admin=" + (admin ? 1 : 0)}${"&uid=" + user?.idUsuario}${"&grupo=" + propiedades.grupo}${"&concepto=" + propiedades.concepto}${"&claveP=" + propiedades.claveP}${"&unidadM=" + propiedades.unidadM}${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`} >
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                <IconButton color="warning" aria-label="upload picture" component="span">
                  <a className={classes.btnExcel} target="_blank" rel="noreferrer"
                    href={`${baseUrl}excel/servicios?${"admin=" + (admin ? 1 : 0)}${"&uid=" + user?.idUsuario}${"&grupo=" + propiedades.grupo}${"&concepto=" + propiedades.concepto}${"&claveP=" + propiedades.claveP}${"&unidadM=" + propiedades.unidadM}${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`} >
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <TablePagination
            nombrePermiso="servicios"
            permiso={permiso}
            header={header}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "acciones",
                name: "Acciones",
                sortable: false,
                cell: (row, index) => (
                  <div>
                    <IconButton onClick={(event) => { setOpenMenuRow(index); setAnchorEl(event.currentTarget) }}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu anchorEl={anchorEl} keepMounted open={index === openMenuRow} onClose={() => setOpenMenuRow(null)}>
                      {(admin === true || (permiso && permiso[0]["servicios"] & 4) === 4) && (row.eliminado == null) && (
                        <NavLink exact to={(`servicio/editar/${row.idServicio}`)} style={{ color: '#000', textDecoration: 'none' }}>
                          <MenuItem aria-label="editar"
                            onClick={() => {
                             history.push(`servicio/editar/${row.idServicio}`, { match: row.idServicio });
                            }}>
                            <Edit style={{ paddingRight: 5 }} />
                            Editar
                          </MenuItem>
                        </NavLink>
                      )}
                      {(admin === true || (permiso && permiso[0]["servicios"] & 8) === 8) && (
                        row?.eliminado === null ? (
                          <MenuItem
                            onClick={() => onDeleteClicked(row, "Deshabilitar")}
                          >
                            <Delete style={{ paddingRight: 5 }} />
                            Deshabilitar
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={() => onDeleteClicked(row, "Habilitar")}
                          >
                            <RestoreFromTrashIcon style={{ paddingRight: 5 }} />
                            Habilitar
                          </MenuItem>
                        )
                      )}
                    </Menu>
                  </div>
                ),
              },
            ]}
          />
        }
      />
    </>
  );
};
export default Index;
