/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useModel } from "../../hooks/useModel";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import Service from "../../services/api";
import { DigitalSignature } from "../../components/DigitalSignature";

////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import PageHeader from "../../components/PageHeader";
import {
  Grid,
  Paper,
  TextField,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAlert } from "../../hooks/useAlert";
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";
import PacienteTarget from "./PacienteTarget";
import FirebaseService from "../../services/firebase";
import moment from "moment-timezone";
import { formatCurrency } from '../../utils/index';
import Evaluacion from "./Evaluacion";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { tableCellStyles, tableRowStyles, entradasStyle, capitalizeFirst } from '../../utils';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Edit } from "@mui/icons-material";
import { BsFileEarmarkRichtextFill } from "react-icons/bs";
import SaveIcon from "@mui/icons-material/Save";
import AutocompleteDeluxe from "../../components/AutocompleteDeluxe";
import { Divider } from "rsuite";
import InputRegex from "../../components/InputRegex";
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    flexWrap: "wrap",
  },
}));
const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const useEntradasStyle = entradasStyle();
const OrdenServicioDetail = ({ match, history, dialog }) => {
  ////////////////////////////////////////////////////////////////////////
  //                              Vars                                  //
  ////////////////////////////////////////////////////////////////////////
  const id = match.params.id;
  const idOrdenServicio = match.params.idOrdenServicio;
  const classes = useStyles();
  const { showAlert } = useAlert();
  const [firma, setFirma] = useState(null);
  const entradas = useEntradasStyle();
  const editing = !!idOrdenServicio;
  const [admin] = useLocalStorage("admin", null);
  const { permiso } = useAuth();
  const [firmar, setFirmar] = useState(false);
  const [idSucursalStorage] = useLocalStorage("idSucursal", "");
  const [convenio, setConvenio] = useState(null);
  const [observacion, setObservacion] = useState("N/A");
  const [cama, setCama] = useState("0");
  const [solicitudRH, setSolicitudRH] = useState("N/A");
  const formulario = useRef();
  const [urlFirma, setUrlFirma] = useState("");
  const [vendedor, setVendedor] = useState(null);
  const [formaPago, setFormaPago] = useState("CREDITO");
  const [clearFormaPago, setClearFormaPago] = useState(null);
  const [evaluaciones, setEvaluaciones] = useState([]);
  const [guardado, setGuardado] = useState(true);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [tempImg, setTempImg] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [finalizado, setFinalizado] = useState(false);
  const [cita, setCita] = useState(null);
  const [evaluacionesEditado, setEvaluacionesEditado] = useState([]);
  const [totalEditado, setTotalEditado] = useState({
    producto: 0,
    laboratorio: 0
  });

  const canvasFirma = useRef(null)

  const defaultPaciente = {
    ine: "",
    genero: "M",
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    nombreCompleto: "",
    correo: "N/A",
    direccion: "N/A",
    telefono: "9999999999",
    contactoEmergencia: "9999999999",
    urlFoto:
      "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Paciente%2Fpaciente_default.jpg?alt=media&token=4dfe89d7-0766-462e-a924-a4a67156277b",
    fechaNacimiento: moment(new Date()).format("yyyy/MM/DD"),
    estadoCivil: "SOLTERO(A)",
    tipoSangre: "O+",
    pacienteSucursal: [],
    nivelEducativo: "PREPARATORIA",
    nacionalidad: "México",
    fechaSolicitud: moment(new Date()).format("yyyy/MM/DD"),
    alergias: "NINGUNA",
    puesto: "N/A",
    proyecto: "N/A",
    edad: 0,
    nombreEmergencia: "",
  };

  const [pacienteDetalles, setPacienteDetalles] = useState(defaultPaciente);

  const buscador = {
    categoria: "",
    marca: "",
  }

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "orden-servicio",
      idOrdenServicio,
      redirectOnPost: true,
    });

  const getTotalEditado = _evas => {
    let _p = _evas?.reduce((a, s) => {
      if (s?.tipoEvaluacion == "Producto") {
        return a + (Math.round(((parseFloat(s?.precioPublico) - parseFloat(s?.descuento)) * s?.cantidad) * Math.pow(10, 2)) / Math.pow(10, 2));
      } else {
        return a
      }
    }, 0);
    let _l = _evas?.reduce((a, s) => {
      if (s?.tipoEvaluacion == "Producto Laboratorio") {
        return a + (Math.round(((parseFloat(s?.precioProveedor) - parseFloat(s?.descuento)) * s?.cantidad) * Math.pow(10, 2)) / Math.pow(10, 2));
      } else {
        return a
      }
    }, 0);
    return {
      producto: _p,
      laboratorio: _l,
    }
  }

  useEffect(() => {
    if (convenio) {
      if (convenio?.nombreConvenio != "PARTICULARES") {
        setVendedor(convenio?.vendedor)
      }
    }
  }, [convenio])

  useEffect(() => {
    if (firma) {
      setTempImg(firma.toDataURL())
    }
  }, [firma])

  ////////////////////////////////////////////////////////////////////////
  //                           Funcions Def                             //
  ////////////////////////////////////////////////////////////////////////
  const onGuardar = async () => {
    if (idSucursalStorage == 1 && pacienteDetalles?.urlFoto == "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Paciente%2Fpaciente_default.jpg?alt=media&token=4dfe89d7-0766-462e-a924-a4a67156277b") {
      showAlert({
        message:
          "La foto del paciente es requerida",
        severity: "error",
      });
      return;
    }
    const text =
      "¿Estás seguro de que deseas guardar la orden de servicio?".toUpperCase();
    const res = await Swal.fire({
      title: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#25607A",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Guardar!",
      cancelButtonText: "Cancelar",
    });
    if (res.isConfirmed) {
      if (!editing) {

        if (pacienteDetalles?.nombres == "" || pacienteDetalles?.nombres == null || pacienteDetalles?.nombres == undefined) {
          showAlert({
            message: "Es necesario agregarle el nombre al paciente",
            severity: "error",
          });
          return;
        }

        if (pacienteDetalles?.apellidoPaterno == "" || pacienteDetalles?.apellidoPaterno == null || pacienteDetalles?.apellidoPaterno == undefined) {
          showAlert({
            message: "Es necesario agregarle el apellido paterno al paciente",
            severity: "error",
          });
          return;
        }

        if (pacienteDetalles?.apellidoMaterno == "" || pacienteDetalles?.apellidoMaterno == null || pacienteDetalles?.apellidoMaterno == undefined) {
          showAlert({
            message: "Es necesario agregarle el apellido materno al paciente",
            severity: "error",
          });
          return;
        }

        if (pacienteDetalles?.ine == "" || pacienteDetalles?.ine == null || pacienteDetalles?.ine == undefined) {
          showAlert({
            message: "Es necesario agregarle la INE al paciente",
            severity: "error",
          });
          return;
        }


        firma.toBlob(async blob => {
          let url = "";
          const ranm = Math.floor(Math.random() * 1000);
          const pathFirebase = "OrdenServicioFirmas/FIRMA_PACIENTE_" + pacienteDetalles.ine + "_" + pacienteDetalles.nombres + "_" + pacienteDetalles?.apellidoPaterno + "_" + pacienteDetalles?.apellidoMaterno + "_" + moment(new Date()).format("DD_MM_YYYY_HH_mm_ss") + "_" + ranm;
          var image = new Image();
          image.src = blob;
          url = await FirebaseService.uploadFile(pathFirebase, blob);

          if (evaluaciones.length < 1) {
            showAlert({
              message:
                "Seleccione al menos un producto o servicio para agregar la orden de servicio",
              severity: "error",
            });
            return;
          }
          if (!convenio) {
            showAlert({
              message: "Es necesario seleccionar un convenio",
              severity: "error",
            });
            return;
          }
          if (!vendedor) {
            showAlert({
              message: "Es necesario seleccionar un vendedor",
              severity: "error",
            });
            return;
          }
          if (!solicitudRH) {
            showAlert({
              message: "Es requerido que seleccione una solicitud de RH",
              severity: "error",
            });
          }
          if (!cama) {
            showAlert({
              message: "Se requiere que ingrese el numero de una cama",
              severity: "error",
            });
          }

          let descuento = parseFloat(convenio.descuento);
          let totalPaquetes = 0;
          let totalServicio = 0;

          evaluaciones.forEach((eva) => {
            switch (eva.tipoEvaluacion) {
              case "Servicio":
                totalServicio =
                  Math.round(
                    (totalServicio + parseFloat(eva.valorUIva) * eva.cantidad) *
                    Math.pow(10, 2)
                  ) / Math.pow(10, 2);
                break;
              case "Producto":
                totalPaquetes =
                  Math.round(
                    (totalPaquetes + parseFloat(eva.precioPublico) * eva.cantidad) *
                    Math.pow(10, 2)
                  ) / Math.pow(10, 2);
                break;
              case "Producto Laboratorio":
                totalPaquetes =
                  Math.round(
                    (totalPaquetes + parseFloat(eva.precioPublico) * eva.cantidad) *
                    Math.pow(10, 2)
                  ) / Math.pow(10, 2);
                break;
            }
          });

          let totalSD =
            Math.round((totalPaquetes + totalServicio) * Math.pow(10, 2)) /
            Math.pow(10, 2);
          let totalD =
            Math.round((totalSD / 100) * descuento * Math.pow(10, 2)) /
            Math.pow(10, 2);
          let total =
            Math.round((totalSD - totalD) * Math.pow(10, 2)) / Math.pow(10, 2);
          let body = {
            idOrdenServicio,
            evaluaciones,
            pacienteDetalles,
            convenio,
            vendedor,
            idCita: id ? id : 0,
            idSucursal: idSucursalStorage,
            status: "PROCESO",
            observaciones: observacion,
            descuento,
            totalPaquetes,
            totalServicio,
            totalSD,
            totalD,
            total,
            formaPago,
            cama,
            solicitudRH,
            firma: url
          };
          updateModel(body, true, "ordenServicios");
        });
      } else {
        if (evaluaciones.length < 1) {
          showAlert({
            message:
              "Seleccione al menos un producto o servicio para agregar la orden de servicio",
            severity: "error",
          });
          return;
        }
        if (!convenio) {
          showAlert({
            message: "Es necesario seleccionar un convenio",
            severity: "error",
          });
          return;
        }
        if (!vendedor) {
          showAlert({
            message: "Es necesario seleccionar un vendedor",
            severity: "error",
          });
          return;
        }
        if (!solicitudRH) {
          showAlert({
            message: "Es requerido que seleccione una solicitud de RH",
            severity: "error",
          });
        }
        if (!cama) {
          showAlert({
            message: "Se requiere que ingrese el numero de una cama",
            severity: "error",
          });
        }
        let descuento = parseFloat(convenio.descuento);
        let totalPaquetes = 0;
        let totalServicio = 0;

        evaluaciones.forEach((eva) => {
          switch (eva.tipoEvaluacion) {
            case "Servicio":
              totalServicio =
                Math.round(
                  (totalServicio + parseFloat(eva.valorUIva) * eva.cantidad) *
                  Math.pow(10, 2)
                ) / Math.pow(10, 2);
              break;
            case "Producto":
              totalPaquetes =
                Math.round(
                  (totalPaquetes + parseFloat(eva.precioPublico) * eva.cantidad) *
                  Math.pow(10, 2)
                ) / Math.pow(10, 2);
              break;
            case "Producto Laboratorio":
              totalPaquetes =
                Math.round(
                  (totalPaquetes + parseFloat(eva.precioPublico) * eva.cantidad) *
                  Math.pow(10, 2)
                ) / Math.pow(10, 2);
              break;
          }
        });

        let totalSD =
          Math.round((totalPaquetes + totalServicio) * Math.pow(10, 2)) /
          Math.pow(10, 2);
        let totalD =
          Math.round((totalSD / 100) * descuento * Math.pow(10, 2)) /
          Math.pow(10, 2);
        let total =
          Math.round((totalSD - totalD) * Math.pow(10, 2)) / Math.pow(10, 2);

        let body = {
          idOrdenServicio,
          evaluaciones,
          pacienteDetalles,
          convenio,
          vendedor,
          idSucursal: idSucursalStorage,
          status: "PROCESO",
          observaciones: observacion,
          descuento,
          totalPaquetes,
          totalServicio,
          totalSD,
          totalD,
          total,
          formaPago,
          cama,
          solicitudRH,
          firma: urlFirma
        };
        updateModel(body, true, "ordenServicios");
      }
    };
  }
  ////////////////////////////////////////////////////////////////////////
  //                            Hooks Def                               //
  ////////////////////////////////////////////////////////////////////////

  const filterOrdenServicioServicio = ordenServicioServicio => {
    let servicios = [];
    ordenServicioServicio.forEach(servicio => {
      servicios.push(
        {
          ...servicio,
          tipoEvaluacion: "Servicio",
          servicioDoctores: servicio.servicio.servicioDoctores,
          por: (Math.round(((servicio.descuentoPublico / servicio.valorUIva) * 100) * 100) / 100),
          descuento: servicio.descuentoPublico,
          precioDescuento: Math.round((servicio.valorUIva - servicio.descuentoPublico) * 100) / 100,
        }
      )
    });
    return servicios;
  }

  const filtredOrdenServicioProducto = ordenServicioProducto => {
    let productos = [];
    ordenServicioProducto.forEach(entradas => {
      entradas?.entrada?.movimientos?.forEach(movimiento => {
        productos.push({
          ...movimiento?.producto,
          tipoEvaluacion: "Producto",
          descuento: movimiento?.descuentoPublico,
          cantidad: movimiento?.cantidad,
        });
      });
    });
    return productos;
  }

  const filtredOrdenServicioInventarioLaboratorio = inventarioLaboratorio => {
    let laboratorios = [];
    inventarioLaboratorio.forEach(entradas => {
      entradas?.entrada?.movimientos?.forEach(movimiento => {
        laboratorios.push({
          ...movimiento?.producto,
          tipoEvaluacion: "Producto Laboratorio",
          descuento: movimiento?.descuentoPublico,
          cantidad: movimiento?.cantidad,
        });
      });
    });
    return laboratorios;
  }

  const getOrdenServicio = async () => {
    setLoadingEdit(true);
    let res = await Service.get("orden-servicio?expand=detallePaciente,cita,detalleConvenio.convenio.vendedor,detalleConvenio.vendedor,ordenServicioServicio.servicio.servicioDoctores.doctor,entradas.entrada.movimientos.producto,entradasLaboratorio.entrada.movimientos.producto&idOrdenServicio=" + idOrdenServicio);
    if (res?.resultado.length > 0) {
      let _paciente = { ...res?.resultado[0]?.detallePaciente };
      setPacienteDetalles(_paciente);
      setFormaPago(res?.resultado[0]?.formaPago);
      setCama(res?.resultado[0]?.cama);
      setSolicitudRH(res?.resultado[0]?.solicitudRH);
      setObservacion(res?.resultado[0]?.observaciones);
      setFinalizado(res?.resultado[0]?.status == "FINALIZADO");
      setConvenio({ ...res?.resultado[0]?.detalleConvenio.convenio });
      setUrlFirma(res?.resultado[0]?.firma);
      let evas = filterOrdenServicioServicio(res?.resultado[0]?.ordenServicioServicio);
      let productos = filtredOrdenServicioProducto(res?.resultado[0]?.entradas);
      let inventarios = filtredOrdenServicioInventarioLaboratorio(res?.resultado[0]?.entradasLaboratorio);
      setEvaluacionesEditado([...productos, ...inventarios])
      let _totalEditado = getTotalEditado([...productos, ...inventarios]);
      setTotalEditado(_totalEditado);
      setEvaluaciones([...evas]);
      setLoadingEdit(false);
    } else {
      showAlert({
        message: "No se pudo cargar la orden de servicio seleccionada",
        severity: "error"
      });
      setLoadingEdit(false);
    }
  }

  const getCita = async () => {
    setLoadingEdit(true);
    let res = await Service.get("cita?idCita=" + id + "&expand=paciente,citaDatos.convenio.vendedor,citaDatos.tipoEvaluacion.paquete.paqueteProductoInventario.productoInventario.inventario,citaDatos.tipoEvaluacion.paquete.paqueteInventarioLaboratorio.inventarioLaboratorio.inventario,citaDatos.tipoEvaluacion.tipoEvaluacionServicio.servicio.servicioDoctores.doctor,citaServicios.servicio.servicioDoctores.doctor")
    if (res?.resultado?.length > 0) {
      setCita(res?.resultado[0])
      setConvenio(res?.resultado[0]?.citaDatos?.convenio)
      setSolicitudRH(res?.resultado[0]?.citaDatos?.solicitudRH)
    } else {
      showAlert({
        message: "No se pudo cargar la cita seleccionada",
        severity: "error"
      });
      setLoadingEdit(false);
    }
  }

  useEffect(async () => {
    if (editing) {
      await getOrdenServicio();
    } else {
      if (id) {
        await getCita()
      }
    }
    setLoaded(true)
  }, []);

  useEffect(() => {
    if (clearFormaPago) {
      setFormaPago(null);
      setClearFormaPago(false);
    }
  }, [clearFormaPago]);

  return (
    <>
      <PageHeader
        history={history}
        title={(!editing ? "NUEVA" : "EDITAR") + " ORDEN DE SERVICIO"}
        links={[
          {
            to: "/ordenServicios",
            icon: <BsFileEarmarkRichtextFill fontSize="large" />,
            label: "Orden Servicio",
          },
          {
            to: !editing ? "/ordenServicio/nuevo" : "/ordenServicio/ /" + id,
            icon: !editing ? <Add /> : <Edit />,
            label: !editing
              ? "Nuevo"
              : "Editar - " +
              "Orden de Servicio con el id: " + idOrdenServicio,
          },
        ]}
        editing={editing}
        dialog={dialog}
      />

      {
        loaded
        &&
        <PacienteTarget
          setGuardado={setGuardado}
          cita={cita}
          setCita={setCita}
          setPacienteDetalles={setPacienteDetalles}
          pacienteDetalles={pacienteDetalles}
          defaultPaciente={defaultPaciente}
          osEditing={editing}
          loadingEdit={loadingEdit}
          setLoadingEdit={setLoadingEdit}
        />
      }

      {/**Convenio */}
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: "10", marginBottom: "20" }}
      >
        <Grid item xs={12}>
          <div ref={formulario} style={{ textAlign: "center" }}>
            <strong>{"INFORMACION DEL CONVENIO"}</strong>
          </div>
          <Divider />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <AutocompleteDeluxe
            labelToShow="Convenio"
            labelProp="nombreConvenio"
            nombre="convenio"
            disabled={editing}
            filtro={{ expand: "vendedor" }}
            value={convenio}
            setDato={setConvenio}
          />
        </Grid>

        <Grid item lg={6} xs={6} md={6} sm={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="tipoSelect">Forma de pago</InputLabel>
            <Select
              labelId="tipoSelect"
              fullWidth
              disabled={editing}
              label="FormaPago"
              onChange={(e) => {
                setFormaPago(e.target.value);
              }}
              inputProps={{
                value: formaPago,
              }}
            >
              <MenuItem value={"CONTADO"}>CONTADO</MenuItem>
              <MenuItem value={"CREDITO"}>CREDITO</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <AutocompleteDeluxe
            labelToShow="Vendedor"
            disabled={editing ? true : convenio?.nombreConvenio == "PARTICULARES" ? false : true}
            labelProp="nombreCompleto"
            nombre="usuario/vendedores"
            value={vendedor}
            setDato={setVendedor}
          />
        </Grid>
      </Grid>

      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: "10", marginBottom: "20" }}
      >
        <Grid item lg={6} xs={6} md={6} sm={6}>
          <InputRegex
            label="Solicitante de RH"
            fullWidth
            disabled={editing}
            typeInput="text"
            onChange={(e) => {
              setSolicitudRH(e.target.value);
            }}
            inputProps={{
              value: solicitudRH,
            }}
          />
        </Grid>
        <Grid item lg={6} xs={6} md={6} sm={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="tipoSelect">Numero de cama</InputLabel>
            <Select
              labelId="tipoSelect"
              fullWidth
              disabled={editing}
              label="Numero de cama"
              onChange={(e) => {
                setCama(e.target.value);
              }}
              inputProps={{
                value: cama,
              }}
            >
              <MenuItem value={"0"}>0</MenuItem>
              <MenuItem value={"1"}>1</MenuItem>
              <MenuItem value={"2"}>2</MenuItem>
              <MenuItem value={"3"}>3</MenuItem>
              <MenuItem value={"4"}>4</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Motivo de consulta"
            multiline
            rows={3}
            disabled={editing}
            fullWidth
            size="small"
            onChange={(e) => {
              setObservacion(e.target.value);
            }}
            inputProps={{ value: observacion }}
          />
        </Grid>
      </Grid>

      {
        editing && (
          <Grid
            component={Paper}
            container
            className={classes.paper}
            spacing={1}
            style={{ marginTop: "10", marginBottom: "20" }}
          >
            <Grid item xs={12}>
              <div ref={formulario} style={{ textAlign: "center" }}>
                <strong>{"PRODUCTOS UTILIZADOS"}</strong>
              </div>
              <Divider />
            </Grid>
            <Table className={entradas.tableEntradas}>
              <TableHead>
                <TableRowModify>
                  <TableCellModify> Cantidad </TableCellModify>
                  <TableCellModify> Nombre Producto </TableCellModify>
                  <TableCellModify> Precio Publico </TableCellModify>
                  <TableCellModify> Precio con Descuento </TableCellModify>
                  <TableCellModify> Total </TableCellModify>
                </TableRowModify>
              </TableHead>
              <TableBody>
                {evaluacionesEditado?.map((eva, index) => {
                  console.log(eva, "ebbvassss")
                  return (
                    <TableRowModify key={index}>
                      <TableCellModify sx={{ display: "flex", flexDirection: "row" }}>
                        <p style={{
                          background: "#1d5e7b",
                          width: "fit-content",
                          padding: "10px",
                          color: "#FFF",
                          borderRadius: "5px",
                          fontSize: "1rem",
                          fontWeight: "600"
                        }}>{eva.cantidad}</p>
                      </TableCellModify>
                      <TableCellModify>
                        {
                          eva.tipoEvaluacion == "Servicio"
                            ?
                            eva.concepto
                            :
                            eva.nombreProducto
                        }
                      </TableCellModify>
                      <TableCellModify>
                        {
                          eva.tipoEvaluacion == "Servicio"
                            ?
                            "$ " + formatCurrency(eva.valorUIva, 2)
                            :
                            "$ " + formatCurrency(eva.precioPublico, 2)
                        }
                      </TableCellModify>
                      <TableCellModify>
                        {
                          eva.tipoEvaluacion == "Producto"
                            ?
                            "$ " + formatCurrency(Math.round((parseFloat(eva.precioPublico) - parseFloat(eva.descuento)) * Math.pow(10, 2)) / Math.pow(10, 2), 2)
                            :
                            "$ " + formatCurrency(Math.round((parseFloat(eva.precioProveedor) - parseFloat(eva.descuento)) * Math.pow(10, 2)) / Math.pow(10, 2), 2)
                        }
                      </TableCellModify>
                      <TableCellModify>
                        {
                          eva.tipoEvaluacion == "Producto"
                            ?
                            "$ " + formatCurrency(Math.round(((parseFloat(eva.precioPublico) - parseFloat(eva.descuento)) * eva.cantidad) * Math.pow(10, 2)) / Math.pow(10, 2), 2)
                            :
                            "$ " + formatCurrency(Math.round(((parseFloat(eva.precioProveedor) - parseFloat(eva.descuento)) * eva.cantidad) * Math.pow(10, 2)) / Math.pow(10, 2), 2)
                        }
                      </TableCellModify>
                    </TableRowModify>
                  )
                })
                }
              </TableBody>
            </Table>
          </Grid>
        )
      }

      {
        loaded
        &&
        <Evaluacion
          evaluaciones={evaluaciones}
          cita={cita}
          setEvaluaciones={setEvaluaciones}
          convenio={convenio}
          editing={editing}
          loadingEdit={loadingEdit}
          finalizado={finalizado}
          totalEditado={totalEditado}
          setLoadingEdit={setLoadingEdit}
          idOrdenServicio={idOrdenServicio}
        />
      }

      <Grid component={Paper} container className={classes.paper} spacing={1} sx={{ marginBottom: "30px" }}>
        <Grid item xs={12} md={12}
          style={{ padding: "0px 5px", marginTop: "15px", flexDirection: "row", display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          {!editing
            ?
            <>
              {
                firmar ?
                  <DigitalSignature
                    ref={canvasFirma}
                    firma={firma}
                    setFirma={setFirma}
                    setFirmar={setFirmar}
                  />
                  :
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <p>FIRMA DEL PACIENTE</p>
                    <img
                      onClick={e => {
                        setFirmar(true)
                      }}
                      src={tempImg ? tempImg : "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/DoctoresDetalles%2Ffirma_default.png?alt=media&token=c657d017-f580-487d-b518-f79db11a76db"}
                      alt={"firma del paciente"}
                    />
                  </div>
              }
            </>
            :
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <img
                src={urlFirma}
                alt={"firma del paciente"}
              />
              <p>FIRMA DEL PACIENTE</p>
            </div>
          }
        </Grid>
      </Grid>

      <Grid component={Paper} container className={classes.paper} spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                finalizado
                  ?
                  true
                  :
                  !guardado
                    ? true
                    : modelLoading ||
                      updateModelLoading ||
                      admin ||
                      (permiso && permiso[0]["ordenServicios"] & 2) === 2
                      ? false
                      : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<SaveIcon />}
              style={{ color: "#fff" }}
            >
              {!editing ? "GUARDAR" : "MODIFICAR"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default OrdenServicioDetail;
