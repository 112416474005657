/* eslint-disable array-callback-return */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useModel } from "../../hooks/useModel";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { useAlert } from "../../hooks/useAlert";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import PageHeader from "../../components/PageHeader";
import {
    Grid,
    Divider,
    Paper,
    Switch,
    Table,
    CircularProgress,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import TablePagination from "../../components/TablePagination";
import { useModels } from "../../hooks/useModels";
import InputRegex from "../../components/InputRegex";
import Loading from "../../components/FullScreenLoader";
import AsyncAutocompleteSearchBeta from '../../components/AsyncAutocompleteSearchBeta';
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { tableCellStyles, tableRowStyles, entradasStyle, capitalizeFirst } from '../../utils';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Edit } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import RemoveIcon from '@mui/icons-material/Remove';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddIcon from '@mui/icons-material/Add';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));
const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const useEntradasStyle = entradasStyle();

const TipoEvaluacionDetail = ({ match, history, dialog }) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const header = [
        {
            name: "Nombre Sucursal",
            prop: "nombreSucursal",
        }
    ];
    const id = match.params.id;
    const classes = useStyles();
    const { showAlert } = useAlert();
    const entradas = useEntradasStyle();
    const [newObjSucursales, setNewObjSucursales] = useState([])
    const [reload, setReload] = useState(true);
    const [servicios, setServicios] = useState([]);
    const [clearInputServicio, setClearInputServicio] = useState(false);
    const editing = !!id;
    const [admin] = useLocalStorage("admin", null);
    const { permiso } = useAuth();
    const [servicio, setServicio] = useState(null);
    const [paquete, setPaquete] = useState(null);
    const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
    const [propiedades, setPropiedades] = useState({
        evaluacion: "",
        tipoConcepto: "",
        idPaquete: "",
        tipoEvaluacionServicio: [],
    });

    const { model, modelLoading, modelError, updateModel, updateModelLoading } =
        useModel({
            name: "tipo-evaluacion",
            expand: "tipoEvaluacionSucursal, tipoEvaluacionServicio.servicio, paquete",
            id,
            redirectOnPost: true,
        });
    const servicioParamsMemo = useMemo(() => ({
        pagina: 1,
        limite: 20,
        idSucursal: idSucursal,
        ordenar: "concepto.desc"
    }), [idSucursal]);

    const paqueteParamsMemo = useMemo(() => ({
        pagina: 1,
        limite: 20,
        idSucursal: idSucursal,
        ordenar: "nombrePaquete.desc"
    }), [idSucursal]);

    const parametrosInicialesMemo = useMemo(
        () => ({
            name: "sucursal",
            ordenar: "idSucursal.asc",
        }),
        []
    );
    const [models] =
        useModels({ ...parametrosInicialesMemo });
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const onDropServicio = async (id, nombre) => {
        if (id) {
            const res = await Swal.fire({
                title: "Borrar",
                icon: "warning",
                text: `¿Estás seguro de que deseas borrar el servicio seleccionado "${capitalizeFirst(nombre.toLowerCase())}"?`,
                showCancelButton: true,
                confirmButtonColor: "#25607A",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, deseo Continuar!",
                cancelButtonText: "Cancelar",
            });
            if (res.isConfirmed) {
                showAlert({
                    message: "Espera un momento.....",
                    severity: "warning",
                });
                let posi;
                const copyServicios = [...servicios];
                servicios.map((e, index) => {
                    if (e.idServicio === id) {
                        posi = index;
                    }
                });
                copyServicios.splice(posi, 1);
                setServicios(copyServicios);
                setReload(true);
                showAlert({
                    message: "Servicio borrado con éxito.",
                    severity: "success",
                });
            } else {
                setReload(true);
            }
        }
    };
    const onGuardar = async () => {
        let respaldo = [];
        let sucursalesFiltered = [];
        if (editing) {
            model.tipoEvaluacionSucursal.map(sucursal => {
                if (!models.some(s => s.idSucursal == sucursal.idSucursal)) {
                    respaldo.push({ ...sucursal.sucursal, active: true })
                }
            });
            let sucursalesToFilter = newObjSucursales.concat(respaldo);
            sucursalesToFilter.map(sucursal => {
                if (sucursal.active) {
                    sucursalesFiltered.push(sucursal);
                }
            });
        } else {
            let sucursalesToFilter = newObjSucursales;
            sucursalesToFilter.map(sucursal => {
                if (sucursal.active) {
                    sucursalesFiltered.push(sucursal);
                }
            });
        }
        if (sucursalesFiltered.length === 0) {
            showAlert({ message: 'Seleccione al menos una sucursal', severity: "warning", });
            return;
        }
        if (servicios.length === 0) {
            showAlert({ message: 'Seleccione al menos un Servicio', severity: "warning", });
            return;
        }
        let body = { ...propiedades, tipoEvaluacionSucursal: sucursalesFiltered, tipoEvaluacionServicio: servicios, idPaquete: paquete !== null ? paquete.idPaquete : paquete }
        if (editing) {
            body.idServicio = id;
        }
        updateModel(body, true, "evaluaciones");
    };
    const addServicio = () => {
        setClearInputServicio(true);
        if (servicio.idServicio != null) {
            const repetido = servicios.some(e => e.idServicio === servicio.idServicio);
            if (repetido) {
                showAlert({
                    message: "Este Servicio ya existe en este Tipo de Evaluación",
                    severity: "error",
                });
                setServicio(null);
            } else {
                let copyServicios = [...servicios];
                copyServicios.push(servicio);
                setServicios([...copyServicios]);
                setServicio(null);
            }
        }
    }
    const getFilterArraySucursales = (array) => {
        return array.map((e) => {
            if (editing) {
                if (model) {
                    if (model.tipoEvaluacionSucursal.some(sucursal => sucursal.idSucursal == e.idSucursal)) {
                        return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: true }
                    } else {
                        return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
                    }
                } else {
                    return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
                }
            } else {
                return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
            }
        })
    }

    const filtrarServicios = (array) => {
        let serviciosCopy = servicios;
        array.map(sev => {
            let _servicio = {
                ...sev.servicio,
                descuento: sev.descuento,
                precioDescuento: sev.precioDescuento,
                por: 100
                    -
                    (
                        Math.round(
                            (
                                (sev.precioDescuento / sev.servicio.valorUIva) * 100
                            ) * 100
                        ) / 100
                    )
            };
            serviciosCopy.push(_servicio);
        });
        return serviciosCopy;
    }
    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (clearInputServicio) {
            setClearInputServicio(false);
        }
    }, [clearInputServicio]);

    const setServicioCallback = useCallback((e, v) => {
        if (v !== "" && v) {
            setServicio({ ...v, descuento: 0, precioDescuento: v.valorUIva, por: 0 });
        } else {
            setServicio(null);
        }
    }, [setServicio]);

    const setPaqueteCallback = useCallback((e, v) => {
        if (v !== "" && v) {
            setPaquete(v);
        } else {
            setPaquete(null);
        }
    }, [setPaquete]);

    useEffect(() => {
        let mounted = true;
        if (mounted && model) {
            setPropiedades(model);
            setPaquete(model.paquete);
            const newObj = getFilterArraySucursales(models);
            setNewObjSucursales(newObj);
            setServicios(filtrarServicios(model.tipoEvaluacionServicio));
        }
        return () => {
            mounted = false;
            setPropiedades({
                evaluacion: "",
                tipoConcepto: "",
                idPaquete: "",
                tipoEvaluacionServicio: [],
            });
        };
    }, [model]);

    useEffect(() => {
        const newObj = getFilterArraySucursales(models);
        setNewObjSucursales(newObj);
    }, [models]);


    if (modelLoading) return <Loading />;

    return (
        <>
            <PageHeader
                history={history}
                title={(!editing ? "NUEVA" : "EDITAR") + " EVALUACIÓN"}
                links={[
                    {
                        to: "/evaluaciones",
                        icon: <AssignmentIcon fontSize="small" />,
                        label: "Tipo Evaluaciones",
                    },
                    {
                        to: !editing ? "/evaluacion/nuevo" : "/evaluacion/editar/" + id,
                        icon: !editing ? <Add /> : <Edit />,
                        label: !editing
                            ? "Nuevo"
                            : "Editar - " +
                            capitalizeFirst(
                                propiedades?.evaluacion.toLocaleLowerCase()
                            ),
                    },
                ]}
                editing={editing}
                dialog={dialog}
            />
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12}>
                    <div><strong>{"INFORMACIÓN DE LA EVALUACIÓN"}</strong></div>
                    <Divider />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputRegex
                        label="Evaluación"
                        variant="standard"
                        fullWidth
                        size="medium"
                        helperText={modelError?.evaluacion}
                        error={Boolean(modelError?.evaluacion)}
                        typeInput='text'
                        value={propiedades.evaluacion}
                        onChange={(e) =>
                            setPropiedades((prevState) => ({
                                ...prevState,
                                ...prevState.propiedades,
                                evaluacion: e.target.value,
                            }))
                        }
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputRegex
                        label="Tipo Concepto"
                        variant="standard"
                        fullWidth
                        helperText={modelError?.tipoConcepto}
                        error={Boolean(modelError?.tipoConcepto)}
                        size="medium"
                        typeInput='text'
                        value={propiedades.tipoConcepto}
                        onChange={(e) =>
                            setPropiedades((prevState) => ({
                                ...prevState,
                                ...prevState.propiedades,
                                tipoConcepto: e.target.value,
                            }))
                        }
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <AsyncAutocompleteSearchBeta
                        variant="standard"
                        label="Paquetes"
                        ruta="paquete"
                        defaultValue={paquete}
                        labelProp="nombrePaquete"
                        extraParams={paqueteParamsMemo}
                        onChange={setPaqueteCallback}
                        campoError="idProductoInventario"
                    />
                </Grid>


            </Grid>
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12} sm={10}>
                    <AsyncAutocompleteSearchBeta
                        variant="standard"
                        label="Servicios"
                        ruta="servicio"
                        labelProp="concepto"
                        extraParams={servicioParamsMemo}
                        clearInput={clearInputServicio}
                        onChange={setServicioCallback}
                        campoError="idProductoInventario"
                    />
                </Grid>
                <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <LoadingButton
                        onClick={(e) => { addServicio() }}
                        style={{ color: '#FFF' }} variant="contained"><AddIcon /> Agregar Servicio</LoadingButton>
                </Grid>
                {(servicios.length > 0) && (
                    <>
                        <Grid item xs={12}>
                            <Table className={entradas.tableEntradas}>
                                <TableHead>
                                    <TableRowModify>
                                        <TableCellModify> # </TableCellModify>
                                        <TableCellModify> Concepto </TableCellModify>
                                        <TableCellModify> Grupo </TableCellModify>
                                        <TableCellModify> descuento % </TableCellModify>
                                        <TableCellModify> descuento </TableCellModify>
                                        <TableCellModify> Valor Unitario </TableCellModify>
                                        <TableCellModify> Valor con descuento </TableCellModify>
                                        <TableCellModify> </TableCellModify>
                                    </TableRowModify>
                                </TableHead>
                                <TableBody>
                                    {reload ? servicios.map((serv, index) => {
                                        return (
                                            <TableRowModify key={index}>
                                                <TableCellModify> {index + 1} </TableCellModify>
                                                <TableCellModify> {serv?.concepto} </TableCellModify>
                                                <TableCellModify> {serv?.grupo} </TableCellModify>
                                                <TableCellModify>
                                                    <InputRegex
                                                        value={serv?.por}
                                                        onChange={e => {
                                                            let copyServicios = [...servicios];
                                                            copyServicios[index].por = e.target.value;
                                                            copyServicios[index].precioDescuento = Math.round((copyServicios[index].valorUIva - ((copyServicios[index].valorUIva / 100) * (e.target.value >= 0 && e.target.value ? e.target.value : 0))) * 100) / 100;
                                                            copyServicios[index].descuento = Math.round((copyServicios[index].valorUIva - (Math.round((copyServicios[index].valorUIva - ((copyServicios[index].valorUIva / 100) * (e.target.value >= 0 && e.target.value ? e.target.value : 0))) * 100) / 100)) * 100) / 100;
                                                            setServicios([...copyServicios]);

                                                        }}
                                                        typeInput={"%"}
                                                    />
                                                </TableCellModify>
                                                <TableCellModify>
                                                    $ {serv.descuento}
                                                </TableCellModify>
                                                <TableCellModify> {serv.valorUIva} </TableCellModify>
                                                <TableCellModify>
                                                    <InputRegex
                                                        value={serv?.precioDescuento}
                                                        onChange={e => {
                                                            let copyServicios = [...servicios];
                                                            copyServicios[index].precioDescuento = e.target.value;
                                                            copyServicios[index].por = 100 - (Math.round(((e.target.value / copyServicios[index].valorUIva) * 100) * 100) / 100);
                                                            copyServicios[index].descuento =
                                                                Math.round(
                                                                    (
                                                                        (
                                                                            copyServicios[index].valorUIva
                                                                            -
                                                                            Math.round(e.target.value * 100) / 100
                                                                        )
                                                                    ) * 100
                                                                ) / 100;
                                                            setServicios([...copyServicios]);
                                                        }}
                                                        typeInput={"currency"}
                                                    />
                                                </TableCellModify>
                                                <TableCellModify>
                                                    <IconButton
                                                        onClick={e => {
                                                            onDropServicio(serv.idServicio, serv.concepto);
                                                            setReload(false);
                                                        }}
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </TableCellModify>
                                            </TableRowModify>
                                        )
                                    }) : (
                                        <TableRowModify>
                                            <TableCellModify></TableCellModify>
                                            <TableCellModify></TableCellModify>
                                            <TableCellModify> <CircularProgress /> </TableCellModify>
                                            <TableCellModify></TableCellModify>
                                        </TableRowModify>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </>
                )}
            </Grid>

            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12}>
                    <strong>{"SUCURSALES"}</strong>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <TablePagination
                        header={header}
                        data={newObjSucursales}
                        extraRows={[
                            {
                                prop: "accion",
                                name: "Acción",
                                sortable: false,
                                cell: (row, index) =>
                                    <Switch
                                        disabled={row && (row?.eliminado === null && row?.active === null) ? true : false}
                                        checked={row?.active}
                                        onChange={({ target }) => {
                                            newObjSucursales[index].active = target.checked;
                                            setNewObjSucursales([...newObjSucursales]);
                                        }}
                                    />,
                            },
                        ]}
                    />
                </Grid>

            </Grid>
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                        <LoadingButton
                            disabled={
                                modelLoading || updateModelLoading ||
                                    admin ||
                                    (permiso && permiso[0]["tipoEvaluaciones"] & 2) === 2
                                    ? false
                                    : true
                            }
                            loading={modelLoading || updateModelLoading}
                            variant="contained"
                            onClick={() => onGuardar()}
                            size="medium"
                            startIcon={<SaveIcon />}
                            style={{ color: "#fff" }}
                        >
                            {(!editing ? "GUARDAR" : "MODIFICAR")}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
export default TipoEvaluacionDetail;