/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useModels } from "../../hooks/useModels";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import {
  Grid,
  FormControlLabel,
  Switch,
  Menu,
  MenuItem,
  Popover,
  getTableSortLabelUtilityClass,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAlert } from "./../../hooks/useAlert";
import moment from "moment";
import { DateRangePicker, Row } from "rsuite";
import Services from "../../services/api";
import "rsuite/dist/rsuite.css";
import Swal from "sweetalert2";
import FirebaseService from "../../services/firebase";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { baseStyles, capitalizeFirst } from "../../utils";
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import IconButton from "@mui/material/IconButton";
import { BsFileEarmarkRichtextFill } from "react-icons/bs";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import { Add, Delete, Edit, Search } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReceiptIcon from '@mui/icons-material/Receipt';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
import { baseUrl } from "../../utils/variables";
import AsyncAutocompleteSearchRework from "../../components/AsyncAutocompleteSearchRework";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import InputRegex from "../../components/InputRegex";
import AutocompleteDeluxe from "../../components/AutocompleteDeluxe";

const useStyles = baseStyles();

const OrdenServicio = () => {
  ////////////////////////////////////////////////////////////////////////
  //                              Vars                                  //
  ////////////////////////////////////////////////////////////////////////
  const classes = useStyles();
  const history = useHistory();
  const { showAlert } = useAlert();
  const [clear, setClear] = useState(false);
  const [openMenuRow, setOpenMenuRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [pagina, setPagina] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [admin] = useLocalStorage("admin", null);
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const { user, permiso } = useAuth();
  const [doctor, setDoctor] = useState({});
  const [clearDoctor, setClearDoctor] = useState(false);
  const [idConvenio, setIdConvenio] = useState("");

  const parametrosInicialesMemo = useMemo(
    () => ({
      pagina: 1,
      limite: 10,
      name: "orden-servicio",
      ordenar: "idOrdenServicio.desc",
      expand: "sucursal,detallePaciente,detalleConvenio,entradas.movimientos.lote,entradasLaboratorio.movimientos.lote,ordenServicioServicio",
      extraParams: { idSucursal },
    }),
    []
  );
  const [convenio, setConvenio] = useState(null)
  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({ ...parametrosInicialesMemo });

  const [propiedades, setPropiedades] = useState({
    fechaInicio: moment().startOf("month").format("yyyy/MM/DD"),
    fechaFinal: moment(new Date()).format("yyyy/MM/DD"),
    status: "",
  });

  ////////////////////////////////////////////////////////////////////////
  //                           Funcions Def                             //
  ////////////////////////////////////////////////////////////////////////
  const onSearchClicked = async (valor) => {
    let params = {};
    if (valor != null && valor <= 1) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        convenio: convenio?.idConvenio,
        ...{ pagina: pagina, limite: perPage, eliminado: valor },
      };
    } else if (valor === "no-apto") {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        convenio: convenio?.idConvenio,
        ...{ pagina: pagina, limite: perPage, estado: valor },
      };
    } else if (valor === "aprobado") {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        convenio: convenio?.idConvenio,
        ...{ pagina: pagina, limite: perPage, estado: valor },
      };
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        convenio: convenio?.idConvenio,
        ...{ pagina: pagina, limite: perPage },
      };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async (page) => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      convenio: convenio?.idConvenio,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async (per) => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      convenio: convenio?.idConvenio,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  const onDeleteClicked = async (row, type) => {
    try {
      setOpenMenuRow(false);
      await Swal.fire({
        title: type,
        text: `¿Desea ${type} el dato seleccionado "${capitalizeFirst(
          row.nombreCompleto.toLowerCase()
        )}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34AA44",
        cancelButtonColor: "#E72C33",
        confirmButtonText: "Si, deseo Continuar.",
      }).then((result) => {
        if (result.isConfirmed) {
          let params = {};
          params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: pagina, limite: perPage },
          };
          deleteModel(row.idOrdenServicio, type, params);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const setDoctorCallback = useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setDoctor(v);
        setPropiedades({ ...propiedades, idAgenda: v?.agendas[0]?.idAgenda });
      } else {
        setDoctor(null);
      }
    },
    [setDoctor]
  );

  const paramsMemoDoctor = useMemo(
    () => ({
      ordenar: "nombreCompleto.asc",
      idSucursal: idSucursal ? idSucursal : null,
      expand: "agendas",
    }),
    [idSucursal]
  );

  const setIdConvenioCallback = useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdConvenio(v.idConvenio);
        setPropiedades({ ...propiedades, idConvenio: v.idConvenio });
      } else {
        setIdConvenio("");
        setPropiedades({ ...propiedades, idConvenio: "" });
      }
    },
    [setIdConvenio]
  );

  const paramsMemo = useMemo(
    () => ({
      ordenar: "nombreConvenio.asc",
      expand: "convenio",
    }),
    []
  );

  const getTotales = (row) => {
    let productos = 0;
    if (row?.entradas?.movimientos?.length > 0) {
      productos = row?.entradas?.movimientos?.reduce((a, s) => {
        return a + Math.round(parseFloat(s?.totalDescuentoPublico) * Math.pow(10, 2)) / Math.pow(10, 2);
      }, 0);
    }
    let laboratorios = 0;
    if (row?.entradasLaboratorio?.movimientos?.length > 0) {
      laboratorios = row?.entradasLaboratorio?.movimientos?.reduce((a, s) => {
        return a + Math.round(parseFloat(s?.totalDescuentoPublico) * Math.pow(10, 2)) / Math.pow(10, 2);
      }, 0);
    }
    let servicios = 0;
    if (row?.ordenServicioServicio?.length > 0) {
      servicios = row?.ordenServicioServicio?.reduce((a, s) => {
        return a + Math.round(parseFloat(s?.totalDescuentoPublico) * Math.pow(10, 2)) / Math.pow(10, 2);
      }, 0);
    }
    return { productos, laboratorios, servicios, totales: (productos + laboratorios + servicios) };
  }

  ////////////////////////////////////////////////////////////////////////
  //                            Hooks Def                               //
  ////////////////////////////////////////////////////////////////////////
  return (
    <>
      <TablePageLayout
        title="Orden de Servicio"
        model="orden-servicio"
        history={history}
        actionButton={{
          to: "/ordenServicio/nuevo",
          icon: <Add />,
          label: "Agregar",
          permiso: "ordenServicios",
        }}
        links={[
          {
            label: "ordenServicios",
            to: "/ordenServicios",
            icon: <BsFileEarmarkRichtextFill fontSize="large" />,
          },
        ]}
        SearchComponents={
          <>
            <Grid
              item
              xs={12}
              md={4}
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <DateRangePicker
                value={[
                  new Date(propiedades.fechaInicio),
                  new Date(propiedades.fechaFinal),
                ]}
                style={{ width: "100%" }}
                format="dd/MM/yyyy"
                orientation="vertical"
                placeholder="Seleccione el rango de fechas"
                onChange={(e) => {
                  setPropiedades({
                    ...propiedades,
                    fechaInicio: moment(e[0]).format("yyyy/MM/DD"),
                    fechaFinal: moment(e[1]).format("yyyy/MM/DD"),
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center" }}>
              <InputRegex
                fullWidth
                size='samall'
                label='Nombre del paciente'
                value={propiedades.nombreCompleto}
                onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  nombreCompleto: e.target.value,
                }))}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <AsyncAutocompleteSearchRework
                style={{ margin: "10px 0" }}
                label="Nombre del profesional"
                labelProp="nombreCompleto"
                name="publico/doctores"
                onChange={setDoctorCallback}
                clearInput={clearDoctor}
                extraParams={paramsMemoDoctor}
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center" }}>
              <AutocompleteDeluxe
                nombre={"convenio"}
                dato={convenio}
                setDato={setConvenio}
                labelToShow={"Convenio"}
                labelProp={"nombreConvenio"}
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center" }}>
              <FormControl fullWidth variant='standard'>
                <InputLabel id="label-Status">Estatus</InputLabel>
                <Select
                  labelId="label-Status"
                  id="label-Status-Id"
                  value={propiedades?.status}
                  label="Estatus"
                  onChange={e => { setPropiedades({ ...propiedades, status: e.target.value }) }}
                >
                  <MenuItem value={""}>TODOS</MenuItem>
                  <MenuItem value={"PROCESO"}>PROCESO</MenuItem>
                  <MenuItem value={"FINALIZADO"}>FINALIZADO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <LoadingButton
                variant="contained"
                onClick={() =>
                  onSearchClicked(
                    propiedades.estado ? propiedades.estado : null
                  )
                }
                loading={modelsLoading && true}
                style={{ float: "right", color: "whitesmoke" }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>
          </>
        }
        DisableComponents={
          <>
            <Grid item xs={8}>
              <FormControlLabel
                className={classes.switchColor}
                control={
                  <Switch
                    disabled={modelsLoading && true}
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        eliminado: e.target.checked ? 1 : 0,
                      }));
                      onSearchClicked(e.target.checked ? 1 : 0);
                    }}
                  />
                }
                label="Ver Finalizadoos"
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  color="error"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnPdf}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}pdf/orden-servicio?
                    ${"admin=" + (admin ? 1 : 0)}
                    ${"&uid=" + user?.idUsuario}
                    ${"&fechaInicio=" + propiedades.fechaInicio}${"&fechaFinal=" + propiedades.fechaFinal}
                    ${"&idSucursal=" + idSucursal}
                    ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`}
                  >
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="warning"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnExcel}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}excel/orden-servicio?
                    ${"admin=" + (admin ? 1 : 0)}
                    ${"&uid=" + user?.idUsuario}
                    ${"&fechaInicio=" + propiedades.fechaInicio}
                    ${"&idSucursal=" + idSucursal}
                    ${"&idConvenio=" + convenio?.idConvenio}
                    ${"&fechaFinal=" + propiedades.fechaFinal}
                    ${"&status=" + propiedades.status}`}
                  >
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <TablePagination
            nombrePermiso="ordenServicios"
            permiso={permiso}
            header={[]}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "convenio",
                name: "Convenio",
                sortable: true,
                cell: (row, index) => (
                  <p key={index}>{row?.detalleConvenio?.nombreConvenio}</p>
                ),
              },
              {
                prop: "nombrePaciente",
                name: "Paciente",
                sortable: true,
                cell: (row, index) => (
                  <p key={index}>{row?.detallePaciente?.nombreCompleto}</p>
                ),
              },
              {
                prop: "fechaSolicitud",
                name: "Fecha Solicitud",
                sortable: false,
                cell: (row, index) => (
                  <p key={index}>{row.fechaSolicitud.slice(0, -8)}</p>
                ),
              },
              {
                prop: "observaciones",
                name: "Observaciones",
                sortable: false,
                cell: (row, index) => <p key={index}>{row.observaciones}</p>,
              },
              {
                prop: "status",
                name: "Estatus",
                sortable: true,
                cell: (row, index) => <p key={index}>{row.status}</p>,
              },
              // {
              //   prop: "totales",
              //   name: "Totales",
              //   sortable: true,
              //   cell: (row, index) => (
              //     <PopupState variant="popover" popupId="demo-popup-popover">
              //       {(popupState) => (
              //         <>
              //           <IconButton
              //             variant="contained"
              //             {...bindTrigger(popupState)}
              //           >
              //             <VisibilityIcon />
              //           </IconButton>
              //           <Popover
              //             {...bindPopover(popupState)}
              //             anchorOrigin={{
              //               vertical: "bottom",
              //               horizontal: "center",
              //             }}
              //             transformOrigin={{
              //               vertical: "top",
              //               horizontal: "center",
              //             }}
              //           >
              //             <div
              //               style={{ display: "flex", flexDirection: "column" }}
              //               key={index}
              //             >
              //               <div style={{ width: "100%" }}>
              //                 <div
              //                   style={{
              //                     whiteSpace: "nowrap",
              //                     background: "#1D5E7B ",
              //                     color: "#FFF",
              //                     textAlign: "center",
              //                   }}
              //                 >
              //                   TOTAL DE PRODUCTOS
              //                 </div>
              //                 <div
              //                   style={{
              //                     whiteSpace: "nowrap",
              //                     textAlign: "center",
              //                     width: "100%",
              //                   }}
              //                 >
              //                   {getTotales(row).productos}
              //                 </div>
              //               </div>
              //               <div style={{ width: "100%" }}>
              //                 <div
              //                   style={{
              //                     whiteSpace: "nowrap",
              //                     background: "#1D5E7B ",
              //                     color: "#FFF",
              //                     textAlign: "center",
              //                   }}
              //                 >
              //                   TOTAL LABORATORIOS
              //                 </div>
              //                 <div
              //                   style={{
              //                     whiteSpace: "nowrap",
              //                     textAlign: "center",
              //                   }}
              //                 >
              //                   {getTotales(row).laboratorios}
              //                 </div>
              //               </div>
              //               <div style={{ width: "100%" }}>
              //                 <div
              //                   style={{
              //                     whiteSpace: "nowrap",
              //                     background: "#1D5E7B ",
              //                     color: "#FFF",
              //                     textAlign: "center",
              //                   }}
              //                 >
              //                   TOTAL SERVICIOS
              //                 </div>
              //                 <div
              //                   style={{
              //                     whiteSpace: "nowrap",
              //                     textAlign: "center",
              //                   }}
              //                 >
              //                   {getTotales(row).servicios}
              //                 </div>
              //               </div>
              //               <div style={{ width: "100%" }}>
              //                 <div
              //                   style={{
              //                     whiteSpace: "nowrap",
              //                     background: "#1D5E7B ",
              //                     color: "#FFF",
              //                     textAlign: "center",
              //                   }}
              //                 >
              //                   TOTAL
              //                 </div>
              //                 <div
              //                   style={{
              //                     whiteSpace: "nowrap",
              //                     textAlign: "center",
              //                   }}
              //                 >
              //                   {getTotales(row).totales}
              //                 </div>
              //               </div>
              //             </div>
              //           </Popover>
              //         </>
              //       )}
              //     </PopupState>
              //   ),
              // },
              {
                prop: "recibo",
                name: "Recibo",
                sortable: true,
                cell: (row, index) =>
                  <NavLink
                    exact
                    target="_blank"
                    to={`recibo/${row?.idOrdenServicio}`}
                    style={{ color: "#000", textDecoration: "none" }}
                  >
                    <MenuItem
                      aria-label="Generar Recibo"
                      onClick={() => {
                        history.push(
                          (`recibo/${row?.idOrdenServicio}`,
                            { match: row?.idOrdenServicio })
                        );
                      }}
                    >
                      <ReceiptIcon style={{ paddingRight: 5 }} />
                    </MenuItem>
                  </NavLink>,
              },
              {
                prop: "acciones",
                name: "Acciones",
                sortable: false,
                cell: (row, index) =>
                  ((permiso && (permiso[0]["ordenServicios"] & 4) === 4)) && (
                    <div>
                      <IconButton
                        onClick={(event) => {
                          setOpenMenuRow(index);
                          setAnchorEl(event.currentTarget);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={index === openMenuRow}
                        onClose={() => setOpenMenuRow(null)}
                      >
                        {(admin === true ||
                          (permiso && permiso[0]["ordenServicios"] & 4) === 4) &&
                          <NavLink
                            exact
                            to={`ordenServicio/editar/${row?.idOrdenServicio}`}
                            style={{ color: "#000", textDecoration: "none" }}
                          >
                            <MenuItem
                              aria-label="ordenServicio"
                              onClick={() => {
                                history.push(
                                  (`ordenServicio/editar/${row?.idOrdenServicio}`,
                                    { match: row?.idOrdenServicio })
                                );
                              }}
                            >
                              <Edit style={{ paddingRight: 5 }} />
                              Orden Servicio
                            </MenuItem>
                          </NavLink>
                        }
                        {(admin === true ||
                          (permiso && permiso[0]["evaluaciones"] & 2) === 2) &&
                          <NavLink
                            exact
                            to={row?.idSucursal == 1 ? `/clinicaNuevaVida/nuevaConsulta/${row?.idOrdenServicio}` : `/hospitalito/nuevaConsulta/${row?.idOrdenServicio}`}
                            style={{ color: "#000", textDecoration: "none" }}
                          >
                            <MenuItem
                              aria-label="ordenServicio"
                              onClick={() => {
                                history.push(
                                  (row?.idSucursal == 1 ? `/clinicaNuevaVida/nuevaConsulta/${row?.idOrdenServicio}` : `/hospitalito/nuevaConsulta/${row?.idOrdenServicio}`,
                                    { match: row?.idOrdenServicio })
                                );
                              }}
                            >
                              <Edit style={{ paddingRight: 5 }} />
                              Evaluar
                            </MenuItem>
                          </NavLink>
                        }
                      </Menu>
                    </div >
                  ),
              },
            ]}
          />
        }
      />
    </>
  );
};
export default OrdenServicio;
