import React, {createContext,useMemo,useContext} from "react";
import { createTheme } from '@mui/material/styles';

const AppContext = createContext();

export function FormProvider(props) {
    let theme = createTheme({
        palette: {
            primary: {
                light: '#eee7b6',
                main: '#1c5e7b',
                dark: '#DAD083'
            },
            secondary: {
                light: '#eee7b6',
                main: '#DAD083',
                dark: '#b3ac79'
            }
        }
    });

    const memData = useMemo(() => {
        return {
            MuiTheme: theme
        };
    }, [theme]);

    return <AppContext.Provider value={memData} {...props} />;
}

export function useFormulario() {
    const context = useContext(AppContext);
    if (!context) {
        // eslint-disable-next-line no-throw-literal
        throw "error: app context not defined.";
    }
    return context;
}