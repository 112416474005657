//React
import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/variables";
import { baseStyles, capitalizeFirst } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import {
  Grid,
  Menu,
  MenuItem,
  Avatar,
  Modal,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useModels } from "../../hooks/useModels";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";

//----------------------------------------------------------------------------------------------------------------------

//Librerias
import Swal from "sweetalert2";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import { Search, ContactsRounded } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import VisibilityIcon from '@mui/icons-material/Visibility';

//----------------------------------------------------------------------------------------------------------------------

//Imagenes
//----------------------------------------------------------------------------------------------------------------------

const useStyles = baseStyles();

const Index = () => {
  //Estados Iniciales
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [perPage, setPerPage] = React.useState(10);
  const [pagina, setPagina] = React.useState(1);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const [imgProductoModal, setImgProductoModal] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const [admin] = useLocalStorage("admin", null);
  const { user, permiso } = useAuth();
  const [modalType, setModalType] = useState(null);
  const [qr, setQr] = useState("");
  //----------------------------------------------------------------------------------------------------------------------

  //Estados Iniciales Controller
  const header = [
    {
      prop: "nombreCompleto",
      name: "Nombre Completo",
    },
    {
      prop: "ine",
      name: "Ine",
    },
    {
      prop: "correo",
      name: "Correo",
    },
    {
      prop: "telefono",
      name: "Telefono",
    }
  ];

  const [propiedades, setPropiedades] = useState({
    idRecepcionPaciente: "",
    ine: "",
    nombreCompleto: "",
    genero: "",
    correo: "",
    telefono: "",
    eliminado: 0,
  });

  const parametrosInicialesMemo = useMemo(
    () => ({
      name: "recepcion-paciente",
      ordenar: "idRecepcionPaciente.desc",
    }),
    []
  );

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({ ...parametrosInicialesMemo });

  //----------------------------------------------------------------------------------------------------------------------

  //Funciones

  const onSearchClicked = async (eliminado) => {
    let params = {};
    if (eliminado != null) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
      };
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage },
      };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async (page) => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async (per) => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  // const onDeleteClicked = async (row, type) => {
  //   try {
  //     setOpenMenuRow(false);
  //     await Swal.fire({
  //       title: type,
  //       text: `¿Desea ${type} el dato seleccionado  "${capitalizeFirst(
  //         row.nombreCompleto.toLowerCase()
  //       )}"?`,
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#34AA44",
  //       cancelButtonColor: "#E72C33",
  //       confirmButtonText: "Si, deseo Continuar.",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         let params = {};
  //         params = {
  //           ...parametrosInicialesMemo,
  //           ...propiedades,
  //           ...{ pagina: pagina, limite: perPage },
  //         };
  //         deleteModel(row.idRecepcionPaciente, type, params);
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  //----------------------------------------------------------------------------------------------------------------------

  //Efectos

  //----------------------------------------------------------------------------------------------------------------------
  const modalStyle = {
    position: "absolute",
    top: "50%",
    right: "auto",
    left: "50%",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    marginRight: "-50%",
    background: "#FFF",
    outline: "none",
    padding: "15px 0 0 0",
    borderRadius: "5px",
    boxShadow: "0 0 15px black",
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" style={modalStyle} >
        <div style={{ backgroundColor: "#FFF" }}>
          <CloseIcon onClick={handleClose} style={{ marginBottom: "15px", marginRight: "15px", display: "flex", marginLeft: "auto", cursor: "pointer", }} />
          {modalType === "img" && (
            <img style={{ maxHeight: "600px", minHeight: "400px", width: "100%" }} src={imgProductoModal} alt="imgProductoModal" />
          )}
        </div>
      </Modal>
      <TablePageLayout
        title="Historial de Pacientes"
        model="recepcion-paciente"
        history={history}
        links={[{ label: "Historial Pacientes", icon: <ContactsRounded /> }]}
        SearchComponents={
          <>
            <Grid item xs={12} sm={4}>
              <InputRegex
                color="primary"
                label="Ine"
                fullWidth
                typeInput="number"
                maxLength={13}
                value={propiedades.ine}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    ine: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputRegex
                color="primary"
                label="Nombre Completo"
                fullWidth
                typeInput="text"
                maxLength={200}
                value={propiedades.nombreCompleto}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    nombreCompleto: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputRegex
                style={{ marginBottom: "20px", background: "#FFF" }}
                label="Correo"
                typeInput="text"
                maxLength={150}
                fullWidth
                value={propiedades.correo}
                onChange={(e) => {
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    correo: e.target.value.toLowerCase(),
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="tipoSelect">Estado Civil</InputLabel>
                <Select
                  labelId="tipoSelect"
                  fullWidth
                  label="Genero"
                  onChange={
                    (e) => {
                      setPropiedades(prevState => ({
                        ...prevState,
                        ...prevState.propiedades,
                        estadoCivil: e.target.value
                      }));
                    }
                  }
                  inputProps={{
                    value: propiedades.estadoCivil,
                  }}
                >
                  <MenuItem value={""}>TODOS</MenuItem>
                  <MenuItem value={"SOLTERO(A)"}>SOLTERO(A)</MenuItem>
                  <MenuItem value={"CASADO(A)"}>CASADO(A)</MenuItem>
                  <MenuItem value={"DIVORCIADO(A)"}>DIVORCIADO(A)</MenuItem>
                  <MenuItem value={"VIUDO(A)"}>VIUDO(A)</MenuItem>
                  <MenuItem value={"CONCUBINATO(A)"}>CONCUBINATO(A)</MenuItem>
                  {/* <MenuItem value={"SEPARACIONJUDICIAL"}>SEPARACIÓN EN PROCESO JUDICIAL</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputRegex
                label="Tipo de sangre"
                variant="standard"
                color="primary"
                fullWidth
                typeInput="text"
                maxLength={50}
                value={propiedades.tipoSangre}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    tipoSangre: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputRegex
                style={{ marginBottom: "20px", background: "#FFF" }}
                label="Telefono"
                typeInput="phone"
                maxLength={10}
                fullWidth
                value={propiedades.telefono}
                onChange={(e) => {
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    telefono: e.target.value.replaceAll(/[-() ]/g, ""),
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={8}></Grid>
            <Grid item xs={12} sm={4}>
              <LoadingButton
                variant="contained"
                onClick={() => onSearchClicked(null)}
                loading={modelsLoading && true}
                style={{
                  float: "right",
                  color: "whitesmoke",
                }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>
          </>
        }
        DisableComponents={
          <>
            <Grid item xs={8}> </Grid>
            <Grid item xs={4} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", }} >
              <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                <IconButton color="error" aria-label="upload picture" component="span">
                  <a className={classes.btnPdf} target="_blank" rel="noreferrer"
                    href={`${baseUrl}pdf/recepcion-pacientes?${"admin=" + (admin ? 1 : 0)}${"&uid=" + user?.idUsuario}${"&ine=" + propiedades.ine}${"&nombreCompleto=" + propiedades.nombreCompleto}${"&correo=" + propiedades.correo}${"&telefono=" + propiedades.telefono}${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`}>
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                <IconButton color="warning" aria-label="upload picture" component="span">
                  <a className={classes.btnExcel} target="_blank" rel="noreferrer"
                    href={`${baseUrl}excel/recepcion-pacientes?${"admin=" + (admin ? 1 : 0)}${"&uid=" + user?.idUsuario}${"&ine=" + propiedades.ine}${"&nombreCompleto=" + propiedades.nombreCompleto}${"&correo=" + propiedades.correo}${"&telefono=" + propiedades.telefono}${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`}>
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <TablePagination
            nombrePermiso="recepcionPacientes"
            permiso={permiso}
            header={header}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "genero",
                name: "Género",
                cell: (row, index) => <p key={index}>{(row.genero === "M" ? "Masculino" : (row.genero === "F" ? "Femenino" : "Otro"))}</p>,
              },
              {
                prop: "direccion",
                name: "Direcciòn",
                cell: (row, index) => <p key={index}>{row.direccion}</p>,
              },
              {
                prop: "urlFoto",
                name: "FOTO",
                sortable: false,
                cell: (row, index) => (
                  <div>
                    <Avatar
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setImgProductoModal(row.urlFoto);
                        setModalType("img");
                        handleOpen();
                      }}
                      alt={row.nombreCompleto}
                      src={row.urlFoto}
                    />
                  </div>
                ),
              },
              {
                prop: "acciones",
                name: "Acciones",
                sortable: false,
                cell: (row, index) => (
                  <div>
                    <IconButton onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }} >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu anchorEl={anchorEl} keepMounted open={index === openMenuRow} onClose={() => setOpenMenuRow(null)}>
                      <MenuItem onClick={() => history.push(`/historialPaciente/ver/${row.idRecepcionPaciente}`)}>
                        <VisibilityIcon style={{ paddingRight: 5 }} /> Ver
                      </MenuItem>
                    </Menu>
                  </div>
                ),
              },
            ]}
          />
        }
      />
    </>
  );
};
export default Index;
