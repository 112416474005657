////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { usePost } from "../../../hooks/usePost";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Button
} from '@mui/material';
import InputRegex from '../../../components/InputRegex';
import moment from "moment-timezone";
import DatePickerBeta from '../../../components/DatePickerBeta'
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BlockIcon from '@mui/icons-material/Block';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////


const Step1 = ({
    nextStep,
    setProgress,
    ...props
}) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const defaultPropiedades = {
        nombres: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        genero: "M",
        fechaNacimiento: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        idSucursal: '1',
        estadoCivil: "SOLTERO(A)",
        tipoSangre: "O-",
    };
    const [propiedades, setPropiedades] = useState({ ...defaultPropiedades });
    const [errores, cargando, peticion, setCargando, detalles] = usePost();
    const [sig, setSig] = useState(false);
    const [recepcion, setRecepcion] = useLocalStorage("recepcion", {});
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        let progress = 0;
        if (propiedades?.genero != "") {
            progress += 20;
        }
        if (propiedades?.nombres != "") {
            progress += 20;
        }
        if (propiedades?.apellidoPaterno != "") {
            progress += 20;
        }
        if (propiedades?.apellidoMaterno != "") {
            progress += 20;
        } if (propiedades?.fechaNacimiento != "") {
            progress += 20;
        }
        if (progress == 100) {
            setSig(true);
        } else {
            setSig(false);
        }
        setProgress(progress, 0);
    }, [propiedades]);

    useEffect(() => {
        if (Object.keys(recepcion).length > 0) {
            setPropiedades({ ...propiedades, ...recepcion });
        }
    }, [recepcion]);

    return (
        <>
            <Grid item xs={12} sm={8} md={6} lg={4} style={{ marginTop: '20px', margin: '20px' }}>
                <p style={{ fontSize: '1.5rem', color: '#666', margin: '0', marginBottom: '20px' }}>Recepción - Datos Personales</p>
                <InputRegex
                    style={{ marginBottom: '20px', background: '#FFF' }}
                    label="Nombres"
                    variant='outlined'
                    typeInput="nombre"
                    helperText={errores?.nombres}
                    error={Boolean(errores?.nombres)}
                    fullWidth
                    value={propiedades.nombres}
                    onChange={
                        e => {
                            setPropiedades(prevState => ({
                                ...prevState,
                                ...prevState.propiedades,
                                nombres: e.target.value
                            }))
                        }
                    }
                />
                <InputRegex
                    style={{ marginBottom: '20px', background: '#FFF' }}
                    label="Apellido Paterno"
                    typeInput="nombre"
                    variant='outlined'
                    helperText={errores?.apellidoPaterno}
                    error={Boolean(errores?.apellidoPaterno)}
                    fullWidth
                    value={propiedades.apellidoPaterno}
                    onChange={
                        e => {
                            setPropiedades(prevState => ({
                                ...prevState,
                                ...prevState.propiedades,
                                apellidoPaterno: e.target.value
                            }))
                        }
                    }
                />
                <InputRegex
                    style={{ marginBottom: '20px', background: '#FFF' }}
                    label="Apellido Materno"
                    typeInput="nombre"
                    helperText={errores?.apellidoMaterno}
                    error={Boolean(errores?.apellidoMaterno)}
                    fullWidth
                    variant='outlined'
                    value={propiedades.apellidoMaterno}
                    onChange={
                        e => {
                            setPropiedades(prevState => ({
                                ...prevState,
                                ...prevState.propiedades,
                                apellidoMaterno: e.target.value
                            }))
                        }
                    }
                />
                <FormControl fullWidth style={{ marginBottom: '20px', background: '#FFF' }}>
                    <InputLabel id="genero">Genero</InputLabel>
                    <Select
                        labelId="genero"
                        label="genero"
                        value={propiedades.genero}
                        onChange={
                            e => {
                                setPropiedades(prevState => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    genero: e.target.value
                                }))
                            }
                        }
                    >
                        <MenuItem value={"M"}>Masculino</MenuItem>
                        <MenuItem value={"F"}>Femenino</MenuItem>
                        <MenuItem value={"O"}>Otros</MenuItem>
                    </Select>
                </FormControl>
                <DatePickerBeta
                    labelText="Fecha de Nacimiento"
                    value={propiedades.fechaNacimiento}
                    typePicker='mobile'
                    inputFormat="MM/dd/yyyy"
                    onChange={
                        e => {
                            setPropiedades(prevState => ({
                                ...prevState,
                                ...prevState.propiedades,
                                fechaNacimiento: moment(e).format("YYYY-MM-DDTHH:mm:ss")
                            }))
                        }
                    }
                />
                <FormControl fullWidth style={{ marginBottom: '20px', background: '#FFF' }}>
                    <InputLabel id="estadoCivil">Estado Civil</InputLabel>
                    <Select
                        labelId="estadoCivil"
                        label="estadoCivil"
                        value={propiedades.estadoCivil}
                        onChange={
                            e => {
                                setPropiedades(prevState => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    estadoCivil: e.target.value
                                }))
                            }
                        }
                    >
                        <MenuItem value={"SOLTERO(A)"}>SOLTERO(A)</MenuItem>
                        <MenuItem value={"CASADO(A)"}>CASADO(A)</MenuItem>
                        <MenuItem value={"DIVORCIADO(A)"}>DIVORCIADO(A)</MenuItem>
                        <MenuItem value={"VIUDO(A)"}>VIUDO(A)</MenuItem>
                        <MenuItem value={"CONCUBINATO(A)"}>CONCUBINATO(A)</MenuItem>
                    </Select>
                </FormControl>

                <InputRegex
                    style={{ marginBottom: '20px', background: '#FFF' }}
                    label="Tipo de sangre"
                    color="primary"
                    fullWidth
                    typeInput="text"
                    variant='outlined'
                    maxLength={50}
                    value={propiedades.tipoSangre.toUpperCase()}
                    onChange={(e) =>
                        setPropiedades((prevState) => ({
                            ...prevState,
                            ...prevState.propiedades,
                            tipoSangre: e.target.value.toUpperCase(),
                        }))
                    }
                />

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                    <Button
                        onClick={e => {
                            nextStep(1);
                            setRecepcion({ ...recepcion, ...propiedades });
                        }}
                        disabled={!sig} style={{ background: !sig ? '#FFF' : '#155480', color: sig ? '#FFF' : '#155480', width: '48%', transition: 'background 1s', border: sig ? 'none' : '2px solid #155480' }}>
                        {sig ? <>Siguiente<NavigateNextIcon style={{ margin: '0 0 1px 10px' }} /></> : <>Siguiente<BlockIcon style={{ margin: '0 0 1px 10px' }} /></>}
                    </Button>
                </div>
            </Grid>
        </>
    );
}
export default Step1;