////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { usePost } from "../../../hooks/usePost";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import {
    Grid,
    Button
} from '@mui/material';
import InputRegex from '../../../components/InputRegex';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import BlockIcon from '@mui/icons-material/Block';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////

const Step2 = ({
    nextStep,
    setProgress,
    ...props
}) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const defaultPropiedades = {
        ine: "",
        correo: "",
        direccion: "",
        telefono: "",
    };
    const [propiedades, setPropiedades] = useState({ ...defaultPropiedades });
    const [errores, cargando, peticion, setCargando, detalles] = usePost();
    const [sig, setSig] = useState(false);
    const [recepcion, setRecepcion] = useLocalStorage("recepcion", {});
    const [invalidCorreo, setInvalidCorreo] = useState(false);
    const [invalidTelefono, setInvalidTelefono] = useState(false);
    const [invalidIne, setInvalidIne] = useState(false);
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        let progress = 0;
        if (propiedades?.ine != "") {
            if(propiedades?.ine.length>12){
                progress += 25;
                setInvalidIne(false);
            }else {
                setInvalidIne(true);
            }
        }
        if (propiedades?.correo != "" && propiedades?.correo != undefined) {
            if(propiedades?.correo.match(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/g)){
                progress += 25;
                setInvalidCorreo(false);
            }else {
                setInvalidCorreo(true);
            }
        }else{
            setInvalidCorreo(false);
        }
        if (propiedades?.direccion != "") {
            progress += 25;
        }
        if (propiedades?.telefono != "") {
            if(propiedades?.telefono.length>9){
                progress += 25;
                setInvalidTelefono(false);
            }else {
                setInvalidTelefono(true);
            }
        }
        if (progress == 100) {
            setSig(true);
        } else {
            setSig(false);
        }
        setProgress(progress, 1);
    }, [propiedades]);

    useEffect(() => {
        if(Object.keys(recepcion).length > 0){
            setPropiedades({...propiedades, ...recepcion });
        }
    }, [recepcion]);

    return (
        <>
            <Grid item xs={12} sm={8} md={6} lg={4} style={{ marginTop: '20px', margin: '20px' }}>
                <p style={{ fontSize: '1.5rem', color: '#666', margin: '0', marginBottom: '20px' }}>Recepción - Datos de Contacto</p>
                <InputRegex
                    style={{ marginBottom: '20px', background: '#FFF' }}
                    label="INE"
                    variant='outlined'
                    helperText={invalidIne?"INE no valido":""}
                    maxLength={13}
                    typeInput='number'
                    error={invalidIne}
                    fullWidth
                    value={propiedades.ine}
                    onChange={
                        e => {
                            setPropiedades(prevState => ({
                                ...prevState,
                                ...prevState.propiedades,
                                ine: e.target.value.toUpperCase()
                            }))
                        }
                    }
                />
                    <InputRegex
                        style={{ marginBottom: '20px', background: '#FFF' }}
                        label="Correo"
                        typeInput='text'
                        maxLength={200}
                        helperText={invalidCorreo?"correo no valido":""}
                        error={invalidCorreo}
                        variant='outlined'
                        fullWidth
                        value={propiedades.correo}
                        onChange={
                            e => {
                                setPropiedades(prevState => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    correo: e.target.value.toLowerCase()
                                }))
                            }
                        }
                    />
                <InputRegex
                    style={{ marginBottom: '20px', background: '#FFF' }}
                    label="Dirección"
                    helperText={errores?.direccion}
                    error={Boolean(errores?.direccion)}
                    fullWidth
                    value={propiedades.direccion}
                    variant='outlined'
                    onChange={
                        e => {
                            setPropiedades(prevState => ({
                                ...prevState,
                                ...prevState.propiedades,
                                direccion: e.target.value
                            }))
                        }
                    }
                />
                <InputRegex
                    style={{ marginBottom: '20px', background: '#FFF' }}
                    label="Telefono"
                    typeInput='phone'
                    maxLength={10}
                    helperText={invalidTelefono?"telefono no valido":""}
                    error={invalidTelefono}
                    variant='outlined'
                    fullWidth
                    value={propiedades.telefono}
                    onChange={
                        e => {
                            setPropiedades(prevState => ({
                                ...prevState,
                                ...prevState.propiedades,
                                telefono: e.target.value.replaceAll(/[-() ]/g, "")
                            }))
                        }
                    }
                />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Button
                        onClick={e => {
                            nextStep(0);
                            setRecepcion({ ...recepcion, ...propiedades });
                        }}
                        style={{ background: '#155480', color: '#FFF', width: '48%' }}>
                        <NavigateBeforeIcon style={{ margin: '0 10px 1px 0' }} /> Atras
                    </Button>
                    <Button
                        onClick={e => {
                            nextStep(2);
                            setRecepcion({ ...recepcion, ...propiedades });
                        }}
                        disabled={!sig} style={{ background: !sig ? '#FFF' : '#155480', color: sig ? '#FFF' : '#155480', width: '48%', transition: 'background 1s', border: sig ? 'none' : '2px solid #155480' }}>
                        {sig ? <>Siguiente<NavigateNextIcon style={{ margin: '0 0 1px 10px' }} /></> : <>Siguiente<BlockIcon style={{ margin: '0 0 1px 10px' }} /></>}
                    </Button>
                </div>
            </Grid>
        </>
    );
}
export default Step2;