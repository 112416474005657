import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material"
import React from "react"

const antecedentesLaborales = ({ datos, setDatos, ...props }) => {
    return (
        <>
            <Grid item xs={12} sx={{ margin: "20px 0", color: "#FFFFFF", background: "black", padding: "10px" }}>
                ANTECEDENTES LABORALES
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"edad en la que empezo a laborar".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.edadInicioLaboral}
                    placeholder={"edad en la que empezo a laborar".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, edadInicioLaboral: e.target.value } }) }}
                />
            </Grid>

            <Grid item xs={12} sx={{ margin: "20px 0" }}>
            </Grid>

            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"puesto de trabajo".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.puestoTrabajo1}
                    placeholder={"puesto de trabajo".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, puestoTrabajo1: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"empresa".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.empresa1}
                    placeholder={"empresa".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, empresa1: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"duracion".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.duracion1}
                    placeholder={"duracion".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, duracion1: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"horario de Trabajo".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.horarioTrabajo1}
                    placeholder={"horario de Trabajo".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, horarioTrabajo1: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    multiline
                    label={"descripcion de Actividades".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.descripcionActividades1}
                    placeholder={"descripcion de Actividades".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, descripcionActividades1: e.target.value } }) }}
                />
            </Grid>

            <Grid item xs={12} sx={{ margin: "20px 0" }}>
                FACTORES DE RIESGO OCUPACIONAL
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <FormControlLabel required control={
                    <Checkbox
                        checked={datos?.antecedentesLaborales?.fisicos1}
                        onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, fisicos1: e.target.checked } }) }}
                    />
                } label={"fisicos".toLocaleUpperCase()} />
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <FormControlLabel required control={
                    <Checkbox
                        checked={datos?.antecedentesLaborales?.quimicos1}
                        onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, quimicos1: e.target.checked } }) }}
                    />
                } label={"quimicos".toLocaleUpperCase()} />
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <FormControlLabel required control={
                    <Checkbox
                        checked={datos?.antecedentesLaborales?.psicosociales1}
                        onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, psicosociales1: e.target.checked } }) }}
                    />
                } label={"psicosociales".toLocaleUpperCase()} />
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <FormControlLabel required control={
                    <Checkbox
                        checked={datos?.antecedentesLaborales?.ergonomicos1}
                        onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, ergonomicos1: e.target.checked } }) }}
                    />
                } label={"ergonomicos".toLocaleUpperCase()} />
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <FormControlLabel required control={
                    <Checkbox
                        checked={datos?.antecedentesLaborales?.biologicos1}
                        onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, biologicos1: e.target.checked } }) }}
                    />
                } label={"biologicos".toLocaleUpperCase()} />
            </Grid>

            <Grid xs={12} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    multiline
                    label={"equipo de protección personal utilizado".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.equipoProteccion1}
                    placeholder={"equipo de protección personal utilizado".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, equipoProteccion1: e.target.value } }) }}
                />
            </Grid>

            <Grid item xs={12} sx={{ margin: "20px 0" }}>
            </Grid>

            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"puesto de trabajo".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.puestoTrabajo2}
                    placeholder={"puesto de trabajo".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, puestoTrabajo2: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"empresa".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.empresa2}
                    placeholder={"empresa".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, empresa2: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"duracion".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.duracion2}
                    placeholder={"duracion".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, duracion2: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"horario de Trabajo".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.horarioTrabajo2}
                    placeholder={"horario de Trabajo".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, horarioTrabajo2: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    multiline
                    label={"descripcion de Actividades".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.descripcionActividades2}
                    placeholder={"descripcion de Actividades".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, descripcionActividades2: e.target.value } }) }}
                />
            </Grid>

            <Grid item xs={12} sx={{ margin: "20px 0" }}>
                FACTORES DE RIESGO OCUPACIONAL
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <FormControlLabel required control={
                    <Checkbox
                        checked={datos?.antecedentesLaborales?.fisicos2}
                        onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, fisicos2: e.target.checked } }) }}
                    />
                } label={"fisicos".toLocaleUpperCase()} />
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <FormControlLabel required control={
                    <Checkbox
                        checked={datos?.antecedentesLaborales?.quimicos2}
                        onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, quimicos2: e.target.checked } }) }}
                    />
                } label={"quimicos".toLocaleUpperCase()} />
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <FormControlLabel required control={
                    <Checkbox
                        checked={datos?.antecedentesLaborales?.psicosociales2}
                        onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, psicosociales2: e.target.checked } }) }}
                    />
                } label={"psicosociales".toLocaleUpperCase()} />
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <FormControlLabel required control={
                    <Checkbox
                        checked={datos?.antecedentesLaborales?.ergonomicos2}
                        onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, ergonomicos2: e.target.checked } }) }}
                    />
                } label={"ergonomicos".toLocaleUpperCase()} />
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <FormControlLabel required control={
                    <Checkbox
                        checked={datos?.antecedentesLaborales?.biologicos2}
                        onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, biologicos2: e.target.checked } }) }}
                    />
                } label={"biologicos".toLocaleUpperCase()} />
            </Grid>
            <Grid xs={12} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    multiline
                    label={"equipo de protección personal utilizado".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.equipoProteccion2}
                    placeholder={"equipo de protección personal utilizado".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, equipoProteccion2: e.target.value } }) }}
                />
            </Grid>

            <Grid item xs={12} sx={{ margin: "20px 0" }}>
            </Grid>

            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"puesto de trabajo".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.puestoTrabajo3}
                    placeholder={"puesto de trabajo".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, puestoTrabajo3: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"empresa".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.empresa3}
                    placeholder={"empresa".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, empresa3: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"duracion".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.duracion3}
                    placeholder={"duracion".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, duracion3: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={3} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"horario de Trabajo".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.horarioTrabajo3}
                    placeholder={"horario de Trabajo".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, horarioTrabajo3: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    multiline
                    label={"descripcion de Actividades".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.descripcionActividades3}
                    placeholder={"descripcion de Actividades".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, descripcionActividades3: e.target.value } }) }}
                />
            </Grid>

            <Grid item xs={12} sx={{ margin: "20px 0" }}>
                FACTORES DE RIESGO OCUPACIONAL
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <FormControlLabel required control={
                    <Checkbox
                        checked={datos?.antecedentesLaborales?.fisicos3}
                        onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, fisicos3: e.target.checked } }) }}
                    />
                } label={"fisicos".toLocaleUpperCase()} />
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <FormControlLabel required control={
                    <Checkbox
                        checked={datos?.antecedentesLaborales?.quimicos3}
                        onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, quimicos3: e.target.checked } }) }}
                    />
                } label={"quimicos".toLocaleUpperCase()} />
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <FormControlLabel required control={
                    <Checkbox
                        checked={datos?.antecedentesLaborales?.psicosociales3}
                        onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, psicosociales3: e.target.checked } }) }}
                    />
                } label={"psicosociales".toLocaleUpperCase()} />
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <FormControlLabel required control={
                    <Checkbox
                        checked={datos?.antecedentesLaborales?.ergonomicos3}
                        onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, ergonomicos3: e.target.checked } }) }}
                    />
                } label={"ergonomicos".toLocaleUpperCase()} />
            </Grid>

            <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                <FormControlLabel required control={
                    <Checkbox
                        checked={datos?.antecedentesLaborales?.biologicos3}
                        onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, biologicos3: e.target.checked } }) }}
                    />
                } label={"biologicos".toLocaleUpperCase()} />
            </Grid>

            <Grid xs={12} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    multiline
                    label={"equipo de protección personal utilizado".toLocaleUpperCase()}
                    value={datos?.antecedentesLaborales?.equipoProteccion3}
                    placeholder={"equipo de protección personal utilizado".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesLaborales: { ...datos?.antecedentesLaborales, equipoProteccion3: e.target.value } }) }}
                />
            </Grid>

        </>
    )
}

export default antecedentesLaborales