
import { CircularProgress, Grid, Paper, Typography, InputLabel, FormControl, Select, MenuItem  } from '@mui/material';
import { useMemo, useState } from 'react';
import Graphs from '../../components/Graphs';
import { baseStyles } from '../../utils';
import moment from 'moment';
import { DateRangePicker } from 'rsuite';
import { Search } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useModels } from "../../hooks/useModels";
import AutocompleteDeluxe from '../../components/AutocompleteDeluxe';
import Service from '../../services/api'
import { useEffect } from 'react';
import { useAuth } from "../../hooks/useAuth";

const useStyles = baseStyles();

const Reportes = () => {
    const classes = useStyles();
    const [convenio, setConvenio] = useState(null);
    const [perPage, setPerPage] = useState(9999999);
    const [pagina, setPagina] = useState(1);
    const { user, permiso } = useAuth();
    const [conveniosPU, setConveniosPU] = useState(null);
    const [propiedades, setPropiedades] = useState({
        fechaInicio: moment().startOf("month").format("yyyy/MM/DD"),
        fechaFinal: moment(new Date()).format("yyyy/MM/DD"),
        idSucursal: 1
    });
    const parametrosInicialesMemo = useMemo(() => ({
        pagina: 1,
        limite: 9999999,
        ordenar: 'creado.desc',
        name: "orden-servicio",
        expand: 'detalleConvenio,' +
            'detallePaciente,' +
            'evaluacion.antecedentesLaborales,' +
            'evaluacion.antecedentesPersonales,' +
            'evaluacion.interrogatorio,' +
            'evaluacion.exploracion,' +
            'evaluacion.accidentes,' +
            'evaluacion.paraclinicos,' +
            'evaluacion.laboratorios,' +
            'evaluacion.estudiosGabinete,' +
            'evaluacion.comentarios,' +
            'evaluacion.audioValores,' +
            'evaluacion.entradaInventario,' +
            'evaluacion.padecimiento,' +
            'evaluacion.estudiosGabinete.archivosRadios,' +
            'evaluacion.ordenServicio.detallePaciente,' +
            'evaluacion.ordenServicio.detalleConvenio,' +
            'evaluacion.ordenServicio.ordenServicioDoctores.doctor,' +
            'evaluacion.ordenServicio.ordenServicioDoctores,',
        extraParams: {
            porUsuario: "true",
            ...propiedades
        }
    }), []);
    const [models, modelsLoading, modelsError, modelsPage, refreshModels, deleteModel] = useModels({ ...parametrosInicialesMemo });

    const getCantidades = () => {
        let cantidades = []
        if (models?.length > 0) {
            let l = models.filter(m => {
                return m?.evaluacion?.laboratorios !== null && m?.evaluacion?.laboratorios !== undefined
            })
            cantidades.push(l?.length)
            let a = models.filter(m => {
                return m?.evaluacion?.audioValores !== null && m?.evaluacion?.audioValores !== undefined
            })
            cantidades.push(a?.length)
            let m = models.filter(m => {
                return m?.evaluacion?.exploracion !== null && m?.evaluacion?.exploracion !== undefined
            })
            cantidades.push(m?.length)
            let rx = models.filter(m => {
                return m?.evaluacion?.estudiosGabinete?.radio === true
            })
            cantidades.push(rx?.length)
        }
        return cantidades
    }

    useEffect(async () => {
        let res = await Service.get("convenio-usuario?idUsuario=" + user?.idUsuario + "&expand=convenio");
        setConveniosPU(res?.resultado)
    }, [])

    const onSearchClicked = async (eliminado = null) => {
        let params = {};
        if (eliminado != null) {
            params = {
                ...parametrosInicialesMemo,
                ...propiedades,
                convenio: convenio?.idConvenio,
                ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
            };
        } else {
            params = {
                ...parametrosInicialesMemo,
                ...propiedades,
                convenio: convenio?.idConvenio,
                ...{ pagina: pagina, limite: perPage },
            };
        }
        await refreshModels({ isCargando: true, params });
    };

    return (
        <>
            <Paper className={classes.paper} elevation={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Reportes
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={5} style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <DateRangePicker value={[new Date(propiedades.fechaInicio), new Date(propiedades.fechaFinal)]} style={{ width: '100%' }} format="dd/MM/yyyy" placeholder="Seleccione el rango de fechas"
                            onChange={e => {
                                setPropiedades({
                                    ...propiedades,
                                    fechaInicio: moment(e[0]).format("yyyy/MM/DD"),
                                    fechaFinal: moment(e[1]).format("yyyy/MM/DD")
                                });
                            }}
                        />
                    </Grid>
                    {
                        conveniosPU?.length > 1 && (
                            <Grid item xs={12} >
                                {/* <AutocompleteDeluxe
                                    labelToShow="Convenio"
                                    labelProp="nombreConvenio"
                                    nombre="convenio"
                                    value={convenio}
                                    setDato={setConvenio}
                                /> */}
                                <FormControl fullWidth variant='standard'>
                                    <InputLabel id="didconvenio">Convenio</InputLabel>
                                    <Select
                                        labelId="didconvenio"
                                        id="dconvenio"
                                        value={convenio}
                                        label="Convenio"
                                        onChange={(e) => {
                                            setConvenio(e.target.value)
                                        }}
                                    >
                                        <MenuItem value={null}>TODOS</MenuItem>
                                        {
                                            conveniosPU?.map((c, i) => (
                                                <MenuItem key={i} value={c}>{c?.convenio.nombreConvenio}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        )
                    }
                    <Grid item xs={12} md={2} style={{ display: 'flex', justifyContent: 'end' }}>
                        <LoadingButton
                            variant="contained"
                            onClick={() => onSearchClicked()}
                            size="large"
                            style={{ color: "#fff" }}
                            startIcon={<Search />}
                        >
                            BUSCAR
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Paper>

            <Paper className={classes.paper} elevation={3}>
                {!modelsLoading ? (
                    <Grid container spacing={2} style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center         "
                    }}>
                        <Grid item xs={12} style={{ width: "50%" }}>
                            <Graphs
                                datos={['Total de Pacientes ' + models?.length]}
                                cantidad={[100]}
                                colors={['#1D5E7B']}
                                typeGraph="radialBar"
                            />
                        </Grid>
                        {/* <Grid item xs={12} md={6} style={{ marginTop: '30px' }}>
                            <Graphs
                                datos={['Aptos', 'Prospectos', 'No Aptos']}
                                cantidad={[res?.aprobados, res?.prospecto, res?.noAptos]}
                                colors={['#4CAF50', '#FEB019', '#FF4560']}
                                typeGraph="pie"
                            />
                        </Grid> */}
                        <Grid item xs={12} style={{ width: "70%" }}>
                            <Graphs
                                datos={['Laboratorio', 'Audiometría', 'Medicina General', 'Rayos X']}
                                cantidad={getCantidades()}
                                title={"Estudios realizados"}
                                colors={['#1D5E7B']}
                                subTitle={'Estudios realizados por cantidad de personas'}
                                typeGraph="bar"
                            />
                        </Grid>
                    </Grid>
                )
                    :
                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        padding: "50px 0"
                    }}>
                        <CircularProgress />
                    </div>
                }
            </Paper>
        </>
    );
}
export default Reportes;