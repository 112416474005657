import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
// <========================= COMPONENTES =============================>
import {
    Grid,
    Typography,
    TextField,
    MenuItem,
    FormControl,
    Select,
    Button,
    InputLabel,
    FormGroup,
    ListItemText,
    ListItem,
    List,
    Switch,
    IconButton,
    FormControlLabel,
} from "@mui/material";
import { useFileManager } from '../../hooks/useFileManager';
import FileManager from '../../components/FileManager';
import { usePost } from "../../hooks/usePost";
import { useLocalStorage } from "./../../hooks/useLocalStorage";
import { useAlert } from "./../../hooks/useAlert";
import Swal from "sweetalert2";
import { useGet } from "../../hooks/useGet";
import AddIcon from '@mui/icons-material/Add';
import FirebaseService from "../../services/firebase";
import AsyncAutocompleteSearchBeta from '../../components/AsyncAutocompleteSearchBeta';
import AsyncAutocompleteSearchRework from '../../components/AsyncAutocompleteSearchRework';
// <========================= STYLES =============================>
import {
    campanaStyle,
    tablaPrimaryStyle,
    baseStyles,
} from "../../utils";
// <========================= ICONOS =============================>
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LoadingButton from '@mui/lab/LoadingButton';


const useCampanaStyle = campanaStyle();
const useStyles = baseStyles();
const useTablaPrimaryStyle = tablaPrimaryStyle();
const Agregar = ({ idCampana = 0 }) => {
    const editing = idCampana !== 0;
    const { showAlert } = useAlert();
    const [grupo, setGrupo] = useState(null);
    const formulario = useRef();
    const filesRef = useRef();
    const classes = useStyles();
    const [clearGrupo, setClearGrupo] = useState(false);
    const [reload, setReload] = useState(true);
    const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
    const [idSucursalaAsync, setIdSucursalAsync] = useState(idSucursal);
    const { files, agregarFiles, clearFiles, activeTab, agregarActiveTab, cargandoFiles } = useFileManager();
    const [admin] = useLocalStorage("admin", null);
    const [empresaSelect, setEmpresaSelect] = useState(true);
    const [tempFile, setTempFile] = useState("");
    const [correos, setCorreos] = useState([]);
    const [errores, cargando, peticion, setCargando, detalles] = usePost();
    const defaultPropiedades = {
        idCampana: "",
        titulo: "",
        sujeto: "Clinica Nueva Vida",
        cuerpo: "",
        etiqueta: "",
        empresa: "Clinica Nueva Vida",
        periodo: "Semana",
        idSucursal: idSucursal,
        footer: true,
        de: "contacto@clinicanuevavida.org",
        filesCampana: [],
        datosCampana: [],
    };
    const [propiedades, setPropiedades] = useState({ ...defaultPropiedades });
    const campanaStyle = useCampanaStyle();
    const tablaPrimaryStyle = useTablaPrimaryStyle();

    const [clearInputSucursal, setClearInputSucursal] = useState(false);

    const setSucursalCallback = useCallback(
        (e, v) => {
            if (v !== "" && v) {
                setPropiedades({ ...propiedades, idSucursal: v.idSucursal });
                setIdSucursalAsync(v.idSucursal)
            }
        },
        [propiedades]
    );
    const paramsMemoGrupo = useMemo(
        () => ({
            ordenar: "nombreGrupo.asc",
        }),
        [grupo]
    );

    const paramsGet = {
        pagina: 1,
        idSucursal: idSucursal,
        limite: 1,
        idCampana: idCampana,
        expand: "grupoContacto,filesCampana"
    };
    const [campana, cargandoCampana, actualizar] = useGet({
        initUrl: "campana",
        params: paramsGet,
        action: true,
    });

    useEffect(() => {
        if (editing) {
            if (campana?.resultado?.length > 0) {
                setPropiedades({ ...propiedades, ...campana?.resultado[0] });
                setGrupo(campana?.resultado[0].grupoContacto);
                setReload(false);
                let fileCopy = campana?.resultado[0].filesCampana;
                fileCopy.sort((a, b) => {
                    if (a.orden > b.orden) {
                        return 1;
                    }
                    if (a.orden < b.orden) {
                        return -1;
                    }
                    return 0;
                });
                clearFiles(fileCopy);
                setCorreos(campana?.resultado[0].datosCampana);
            }
        }
    }, [campana]);

    useEffect(() => {
        if (detalles && detalles?.estatus == 400) {
            formulario.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [detalles]);

    const setidGrupoCallback = useCallback(
        (e, v) => {
            if (v !== "" && v) {
                setGrupo(v);
            } else {
                setGrupo("");
            }
        },
        [setGrupo]
    );

    const agregar = async () => {
        const res = await Swal.fire({
            title: "Estas seguro que deseas guardar esta Campaña?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#25607A",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, Guardar!",
            cancelButtonText: "Cancelar",
        });

        if (res.isConfirmed) {

            showAlert({
                message: "Espera un momento.....",
                severity: "warning",
            });

            let obj = { ...propiedades };

            if (obj.footer) {
                obj.footer = obj.empresa;
            } else {
                obj.footer = "Sin Footer";
            }
            if (obj.empresa != "otra") {
                if (obj.empresa == "Clinica Nueva Vida") {
                    obj.de = "contacto@clinicanuevavida.org";
                } else if (obj.empresa == "Bylsa Drilling") {
                    obj.de = "contacto@bylsadrilling.com";
                }
            }

            obj.datosCampana = correos;
            obj.filesCampana = files;
            obj.idGrupoContacto = grupo.idGrupoContacto;
            if (obj.filesCampana < 1) {
                showAlert({
                    message: "por lo menos es necesario 1 archivo",
                    severity: "error",
                });
            } else {
                peticion("campana/guardar", obj, "/campanas", true, false);
            }
        }
    }

    const sucursalParamsMemo = useMemo(
        () => ({
            pagina: 1,
            limite: 20,
            ordenar: "nombreSucursal.desc"
        }),
        []
    );

    const ordenar = (num, dir) => {
        setReload(false);
        let fileCopy = files;
        fileCopy.map(file => {
            if (dir == "up") {
                if (file.orden == num) {
                    file.orden--;
                } else {
                    if (file.orden == num - 1) {
                        file.orden++;
                    }
                }
            } else if (dir == "down") {
                if (file.orden == num) {
                    file.orden++
                } else {
                    if (file.orden == num + 1) {
                        file.orden--;
                    }
                }
            }
        });
        fileCopy.sort((a, b) => {
            if (a.orden > b.orden) {
                return 1;
            }
            if (a.orden < b.orden) {
                return -1;
            }
            return 0;
        });
        clearFiles(fileCopy);
    }

    const reOrdenar = (filesArray, num) => {
        return filesArray.map(
            file => {
                if (file.orden > num) {
                    file.orden--;
                    return file;
                } else {
                    return file;
                }
            }
        );
    }

    const deleteImg = url => {
        return new Promise((res, rej) => {
            FirebaseService.deleteFile("Spamer/", url);
            res(true);
        });
    }

    const eliminar = async num => {
        const res = await Swal.fire({
            title: "Estas seguro que deseas eliminar este archivo?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#25607A",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, borrarlo!",
            cancelButtonText: "Cancelar",
        });


        if (res.isConfirmed) {
            showAlert({
                message: "Espera un momento.....",
                severity: "warning",
            });


            let fileCopy = files;
            deleteImg(fileCopy[num - 1].nombre).then(res => {
                setReload(false);
                fileCopy.splice((num - 1), 1);
                fileCopy = reOrdenar(fileCopy, num);
                clearFiles(fileCopy);
                showAlert({
                    message: "Archivo borrado con éxito.",
                    severity: "success",
                });
            });

        }

    }
    const deleteCorreo = async i => {
        const res = await Swal.fire({
            title: "Estas seguro que deseas eliminar este correo?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#25607A",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, borrarlo!",
            cancelButtonText: "Cancelar",
        });


        if (res.isConfirmed) {
            showAlert({
                message: "Espera un momento.....",
                severity: "warning",
            });

            let copyCorreos = correos;
            copyCorreos.splice(i, 1);
            setCorreos([...copyCorreos]);

            showAlert({
                message: "Correo borrado con éxito.",
                severity: "success",
            });
        }
    }

    const agregarCorreo = () => {
        if (propiedades.correo != "") {
            if (propiedades.correo.match(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/g)) {
                setCorreos([...correos, { tipo: "Correo", contacto: propiedades.correo }]);
                setPropiedades({ ...propiedades, correo: "" });
            } else {
                showAlert({
                    message: "correo no valido",
                    severity: "error",
                });
            }
        }
    }

    useEffect(() => {
        setReload(true);
        if (files.length > 0) {
            //setTimeout(()=>filesRef.current.scrollIntoView({ behavior: "smooth" }),1000)
        }
    }, [files]);


    useEffect(() => {
    }, [propiedades]);
    return (
        <>
            <Grid container spacing={2}>
                <Grid ref={formulario} item xs={12}><Typography className={campanaStyle.titulo} variant="p">{editing ? "EDITAR" : "AGREGAR"} CAMPAÑA</Typography></Grid>
                {
                    admin && (
                        <>
                            <Grid item xs={12} sm={4}>
                                <AsyncAutocompleteSearchBeta
                                    size="small"
                                    label="Sucursales"
                                    variant="standard"
                                    ruta="sucursal"
                                    labelProp="nombreSucursal"
                                    extraParams={sucursalParamsMemo}
                                    clearInput={clearInputSucursal}
                                    publica={false}
                                    onChange={setSucursalCallback}
                                    campoError="idSucursal"
                                />
                            </Grid>
                            <Grid xs={12} md={4}>

                            </Grid>
                            <Grid xs={12} md={4}>

                            </Grid>
                        </>
                    )
                }
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Etiqueta"
                        variant="standard"
                        fullWidth
                        value={propiedades.etiqueta}
                        helperText={errores?.etiqueta}
                        error={Boolean(errores?.etiqueta)}
                        onChange={
                            e => {
                                setPropiedades(prevState => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    etiqueta: e.target.value
                                }))
                            }
                        }

                    />
                </Grid>
                <Grid item xs={12} md={4} >
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="empresa">Empresa</InputLabel>
                        <Select
                            labelId="empresa"
                            label="Empresa"
                            value={propiedades.empresa}
                            onChange={
                                e => {
                                    if (e.target.value != "otra") {
                                        let email = "";
                                        if (e.target.value == "Bylsa Drilling") {
                                            email = "contacto@bylsadrilling.com";
                                        } else if (e.target.value == "Clinica Nueva Vida") {
                                            email = "contacto@clinicanuevavida.org";
                                        } else if (e.target.value == "BL Law Corp") {
                                            email = "contacto@lawcorp.com.mx";
                                        } else if (e.target.value == "Minerales Tarachi") {
                                            email = "contacto@mtarachi.com";
                                        }
                                        setPropiedades(prevState => ({
                                            ...prevState,
                                            ...prevState.propiedades,
                                            empresa: e.target.value,
                                            footer: true,
                                            sujeto: e.target.value,
                                            de: email,
                                        }));
                                        setEmpresaSelect(true);
                                    } else {
                                        setPropiedades(prevState => ({
                                            ...prevState,
                                            ...prevState.propiedades,
                                            empresa: e.target.value,
                                            footer: false,
                                            sujeto: "",
                                            de: "",
                                        }));
                                        setEmpresaSelect(false);
                                    }
                                }
                            }
                        >
                            <MenuItem value={"Bylsa Drilling"}>Bylsa Drilling</MenuItem>
                            <MenuItem value={"Clinica Nueva Vida"}>Clinica Nueva Vida</MenuItem>
                            <MenuItem value={"BL Law Corp"}>BL Law Corp</MenuItem>
                            <MenuItem value={"Minerales Tarachi"}>Minerales Tarachi</MenuItem>
                            <MenuItem value={"otra"}>Otra</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} md={4}>

                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Titulo"
                        helperText={errores?.titulo}
                        error={Boolean(errores?.titulo)}
                        variant="standard"
                        fullWidth
                        value={propiedades.titulo}
                        onChange={
                            e => {
                                setPropiedades(prevState => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    titulo: e.target.value
                                }))
                            }
                        }
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Sujeto"
                        variant="standard"
                        helperText={errores?.sujeto}
                        error={Boolean(errores?.sujeto)}
                        disabled={empresaSelect}
                        fullWidth
                        value={propiedades.sujeto}
                        onChange={
                            e => {
                                setPropiedades(prevState => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    sujeto: e.target.value
                                }))
                            }
                        }
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Sujeto Correo"
                        variant="standard"
                        disabled={empresaSelect}
                        helperText={errores?.de}
                        error={Boolean(errores?.de)}
                        fullWidth
                        value={propiedades.de}
                        onBlur={
                            e => {
                                if (!e.target.value.match(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/g)) {
                                    setPropiedades(prevState => ({
                                        ...prevState,
                                        ...prevState.propiedades,
                                        de: ""
                                    }))
                                    showAlert({
                                        message: "correo no valido",
                                        severity: "error",
                                    });
                                }
                            }
                        }
                        onChange={
                            e => {
                                setPropiedades(prevState => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    de: e.target.value.toLowerCase()
                                }))
                            }
                        }
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Encabezado"
                        variant="standard"
                        fullWidth
                        helperText={errores?.cuerpo}
                        error={Boolean(errores?.cuerpo)}
                        value={propiedades.cuerpo}
                        onChange={
                            e => {
                                setPropiedades(prevState => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    cuerpo: e.target.value
                                }))
                            }
                        }
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="periodo">Periodo</InputLabel>
                        <Select
                            labelId="periodo"
                            value={propiedades.periodo}
                            label="Periodo"
                            onChange={
                                e => {
                                    setPropiedades(prevState => ({
                                        ...prevState,
                                        ...prevState.propiedades,
                                        periodo: e.target.value
                                    }))

                                }
                            }
                        >
                            {/* <MenuItem value={"Minuto"}>Minuto</MenuItem>
                            <MenuItem value={"5 Minutos"}>5 Minutos</MenuItem> */}
                            <MenuItem value={"Dia"}>Dia</MenuItem>
                            <MenuItem value={"Semana"}>Semana</MenuItem>
                            <MenuItem value={"Mes"}>Mes</MenuItem>
                            <MenuItem value={"10 Mayo"}>10 de Mayo</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    {
                        <FormGroup>
                            <FormControlLabel control={<Switch
                                onClick={
                                    e => {
                                        if (e.target.checked && propiedades.empresa == "otra") {
                                            showAlert({
                                                message: "No se puede poner pie de pagina con esta empresa",
                                                severity: "error",
                                            });
                                            setPropiedades(prevState => ({
                                                ...prevState,
                                                ...prevState.propiedades,
                                                footer: false
                                            }))
                                        } else {
                                            setPropiedades(prevState => ({
                                                ...prevState,
                                                ...prevState.propiedades,
                                                footer: e.target.checked
                                            }))
                                        }
                                    }
                                }
                                checked={propiedades.footer} />} label="Pie de pagina" />
                        </FormGroup>
                    }

                </Grid>
                <Grid item xs={12} md={4}>
                    <AsyncAutocompleteSearchRework
                        style={{ margin: '10px 0' }}
                        defaultValue={grupo ?? null}
                        label="Grupos de Contacto"
                        labelProp="nombreGrupo"
                        name="grupo-contacto"
                        onChange={setidGrupoCallback}
                        clearInput={clearGrupo}
                        extraParams={paramsMemoGrupo}
                    />
                </Grid>
                <Grid item xs={12} className={campanaStyle.tabsFile}>
                    <FileManager />
                </Grid>
                <Grid item xs={12}>
                    {
                        (reload && files && files?.length > 0) &&
                        (
                            <>
                                <Typography ref={filesRef} className={campanaStyle.titulo} variant="p">Previsualizacion del correo</Typography>
                                {
                                    files.map((file, i) => {
                                        return (
                                            <>
                                                {
                                                    (file.tipo == "Imagen") &&
                                                    (
                                                        <div key={i} className={campanaStyle.previewRow}>
                                                            <div className={campanaStyle.previewColLeft}>
                                                                <Typography className={campanaStyle.titulo} variant="h1">{file.orden}</Typography>
                                                            </div>
                                                            <div className={campanaStyle.previewColCenter}>
                                                                {
                                                                    (file.redirect !== "") ?
                                                                        <a href={file.redirect} target="_blank" rel="noreferrer">
                                                                            <img src={file.file} alt="imagen no valida" />
                                                                        </a>
                                                                        :
                                                                        <img src={file.file} alt="imagen no valida" />

                                                                }
                                                            </div>
                                                            <div className={campanaStyle.previewColRight}>
                                                                <IconButton
                                                                    onClick={
                                                                        e => {
                                                                            eliminar(file.orden);
                                                                        }
                                                                    }
                                                                >
                                                                    <CancelIcon

                                                                    />
                                                                </IconButton>
                                                                {
                                                                    file.orden > 1 && (
                                                                        <IconButton
                                                                            onClick={
                                                                                e => {
                                                                                    ordenar(file.orden, "up");
                                                                                }
                                                                            }

                                                                        >
                                                                            <ArrowDropUpIcon
                                                                            />
                                                                        </IconButton>
                                                                    )
                                                                }
                                                                {
                                                                    file.orden < files.length && (
                                                                        <IconButton
                                                                            onClick={
                                                                                e => {
                                                                                    ordenar(file.orden, "down");
                                                                                }
                                                                            }

                                                                        >
                                                                            <ArrowDropDownIcon
                                                                            />
                                                                        </IconButton>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    (file.tipo == "Video") &&
                                                    (
                                                        <div key={i} className={campanaStyle.previewRow}>
                                                            <div className={campanaStyle.previewColLeft}>
                                                                <Typography className={campanaStyle.titulo} variant="h1">{file.orden}</Typography>
                                                            </div>
                                                            <div className={campanaStyle.previewColCenter}>
                                                                <a href={file.redirect} target="_blank" rel="noreferrer">
                                                                    <img src="https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Campanas%2Fvideoimg.png?alt=media&token=73695bd4-a359-49c6-8acf-a9bbd6dd7287" alt="imagen no valida" />
                                                                </a>
                                                            </div>
                                                            <div className={campanaStyle.previewColRight}>
                                                                <IconButton
                                                                    onClick={
                                                                        e => {
                                                                            eliminar(file.orden);
                                                                        }
                                                                    }
                                                                >
                                                                    <CancelIcon

                                                                    />
                                                                </IconButton>
                                                                {
                                                                    file.orden > 1 && (
                                                                        <IconButton
                                                                            onClick={
                                                                                e => {
                                                                                    ordenar(file.orden, "up");
                                                                                }
                                                                            }

                                                                        >
                                                                            <ArrowDropUpIcon
                                                                            />
                                                                        </IconButton>
                                                                    )
                                                                }
                                                                {
                                                                    file.orden < files.length && (
                                                                        <IconButton
                                                                            onClick={
                                                                                e => {
                                                                                    ordenar(file.orden, "down");
                                                                                }
                                                                            }

                                                                        >
                                                                            <ArrowDropDownIcon
                                                                            />
                                                                        </IconButton>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    (file.tipo == "Pdf") &&
                                                    (
                                                        <div key={i} className={campanaStyle.previewRow}>
                                                            <div className={campanaStyle.previewColLeft}>
                                                                <Typography className={campanaStyle.titulo} variant="h1">{file.orden}</Typography>
                                                            </div>
                                                            <div className={campanaStyle.previewColCenter}>
                                                                <a href={file.redirect} target="_blank" rel="noreferrer">
                                                                    <img src="https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Campanas%2Fdefaultpdf.png?alt=media&token=7e79fc7c-aec2-41ce-aa0c-e1f9c6e11745" alt="imagen no valida" />
                                                                </a>
                                                            </div>
                                                            <div className={campanaStyle.previewColRight}>
                                                                <IconButton
                                                                    onClick={
                                                                        e => {
                                                                            eliminar(file.orden);
                                                                        }
                                                                    }
                                                                >
                                                                    <CancelIcon

                                                                    />
                                                                </IconButton>
                                                                {
                                                                    file.orden > 1 && (
                                                                        <IconButton
                                                                            onClick={
                                                                                e => {
                                                                                    ordenar(file.orden, "up");
                                                                                }
                                                                            }

                                                                        >
                                                                            <ArrowDropUpIcon
                                                                            />
                                                                        </IconButton>
                                                                    )
                                                                }
                                                                {
                                                                    file.orden < files.length && (
                                                                        <IconButton
                                                                            onClick={
                                                                                e => {
                                                                                    ordenar(file.orden, "down");
                                                                                }
                                                                            }

                                                                        >
                                                                            <ArrowDropDownIcon
                                                                            />
                                                                        </IconButton>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        );
                                    })
                                }
                            </>
                        )
                    }
                </Grid>
                <Grid item xs={12} style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                }}>
                    <LoadingButton
                        loading={cargandoFiles}
                        variant="contained"
                        size="large"
                        style={{ color: "#fff" }}
                        className={classes.successButton}
                        onClick={
                            e => {
                                agregar();
                            }
                        }
                    >
                        {editing ? "Editar" : "Agregar"} Campaña
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}

export default Agregar;