/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
//React
import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/variables";
import { baseStyles, capitalizeFirst, formatCurrency } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import { Grid, Menu, MenuItem, Switch, Avatar, Popover, FormControlLabel, FormControl, InputLabel, Select } from "@mui/material";
import { useModels } from "../../hooks/useModels";
import Modal from 'react-modal';
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
//----------------------------------------------------------------------------------------------------------------------

//Librerias
import Swal from "sweetalert2";
import Barcode from "react-barcode";
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import { Add, Edit, Delete, Search } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import InventoryIcon from '@mui/icons-material/Inventory';
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';

//----------------------------------------------------------------------------------------------------------------------

//Imagenes
import scanerGif from "../../assets/img/barcode-scan.gif";
import { NavLink } from "react-router-dom";
import ReactFileManager from "../../components/ReactFileManager";
//----------------------------------------------------------------------------------------------------------------------

const useStyles = baseStyles();
Modal.setAppElement(document.getElementById("#modal"));
const Index = () => {
  //Estados Iniciales
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [perPage, setPerPage] = React.useState(10);
  const [pagina, setPagina] = React.useState(1);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const [imgProductoModal, setImgProductoModal] = useState("");
  const [admin] = useLocalStorage("admin", null);
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const { permiso } = useAuth();
  const [modalType, setModalType] = useState(null);
  const [qr, setQr] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  //----------------------------------------------------------------------------------------------------------------------

  //Estados Iniciales Controller

  const [propiedades, setPropiedades] = useState({
    idInventarioLaboratorio: "",
    serie: "",
    nombreProducto: "",
    tipo: "",
    marca: "",
    via: "",
    talla: "",
    laboratorio: 1,
    eliminado: 0,
  });

  const parametrosInicialesMemo = useMemo(() => ({
    name: "inventario-laboratorio",
    ordenar: "idInventarioLaboratorio.desc",
    expand: "inventario",
    extraParams: {
      limite:10,
      idSucursal: idSucursal
    }
  }), [idSucursal]);

  const sucursalesParametros = useMemo(() => ({
    name: "sucursal",
    ordenar: "nombreSucursal.asc",
  }), []);

  const [models, modelsLoading, modelsError, modelsPage, refreshModels, deleteModel,] = useModels({ ...parametrosInicialesMemo });
  const [sucursales] = useModels({ ...sucursalesParametros });
  //----------------------------------------------------------------------------------------------------------------------

  const onSearchClicked = async eliminado => {
    let params = {};
    if (eliminado != null) {
      params = { ...parametrosInicialesMemo, ...propiedades, ...{ pagina: pagina, limite: perPage, eliminado: eliminado }, };
    } else {
      params = { ...parametrosInicialesMemo, ...propiedades, ...{ pagina: pagina, limite: perPage } };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async page => {
    setPagina(page + 1);
    let params = { ...parametrosInicialesMemo, ...propiedades, ...{ pagina: page + 1, limite: perPage } };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async per => {
    setPerPage(per);
    const params = { ...parametrosInicialesMemo, ...propiedades, ...{ limite: per, pagina: pagina } };
    await refreshModels({ isCargando: true, params });
  };

  const onDeleteClicked = async (row, type) => {
    try {
      setOpenMenuRow(false);
      await Swal.fire({
        title: type,
        text: `¿Desea ${type} el dato seleccionado  "${capitalizeFirst(
          row.nombreProducto.toLowerCase()
        )}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34AA44",
        cancelButtonColor: "#E72C33",
        confirmButtonText: "Si, deseo Continuar.",
      }).then((result) => {
        if (result.isConfirmed) {
          let params = {};
          params = { ...parametrosInicialesMemo, ...propiedades, ...{ pagina: pagina, limite: perPage } };
          deleteModel(row.idInventarioLaboratorio, type, params);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }
  const onCloseBarcode = () => {
    closeModal();
    onSearchClicked();
  }
  //----------------------------------------------------------------------------------------------------------------------

  //----------------------------------------------------------------------------------------------------------------------
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <>
      <div className={classes.root}>
        <div id="modal" style={{ zIndex: '99' }}>
          <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
            <div style={{ backgroundColor: "#FFF" }}>
              {(modalType === "img") && (
                <img style={{ maxHeight: '600px', minHeight: '400px', width: '100%' }} src={imgProductoModal} alt="imgProductoModal" />
              )}
              {(modalType == "barcode") && (
                <ReactFileManager typeFile={"barcode"} setBarcode={setQr} barcode={qr} onCloseBarcode={onCloseBarcode} />
              )}
              {(modalType === "qr") && (
                <>
                  <input style={{ opacity: "0", position: "absolute", cursor: "default" }} autoFocus={true} onBlur={({ target }) => { target.focus() }}
                    onChange={e => {
                      setQr(e.target.value.replaceAll("'", "-"));
                      const newPropiedades = propiedades;
                      newPropiedades.serie = e.target.value.replaceAll("'", "-");
                      setPropiedades({ ...propiedades, ...newPropiedades });
                    }}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        event.target.value = "";
                        closeModal();
                        onSearchClicked();
                        return false;
                      }
                      return true;
                    }}
                  />
                  <img src={scanerGif} alt="Escanea codigo de barras" />
                  <p style={{ zIndex: 999, textAlign: 'center', color: 'rgba(100,100,100,1)', fontSize: '20px' }}>{propiedades.serie.replaceAll("'", "-")}</p>
                </>
              )}
            </div>
          </Modal>
        </div>

        <TablePageLayout
          title="Productos Laboratorio"
          model="inventario-laboratorio"
          history={history}
          actionButton={{ to: "/productoLaboratorio/nuevo", icon: <Add />, label: "Agregar producto", permiso: "inventarioLaboratorios" }}
          links={[{ label: "Productos Inventario", to: "/productoInventario", icon: <InventoryIcon fontSize="small" /> },
          ]}
          SearchComponents={
            <>
              <Grid item sm={12} md={4}>
                <InputRegex
                  color="primary"
                  label="Nombre del producto"
                  fullWidth
                  typeInput="text"
                  maxLength={50}
                  value={propiedades.nombreProducto.toUpperCase()}
                  onChange={(e) =>
                    setPropiedades((prevState) => ({
                      ...prevState,
                      ...prevState.propiedades,
                      nombreProducto: e.target.value,
                    }))
                  }
                />
              </Grid>

              {qr != "" ?
                <Grid item sm={12} md={4} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <IconButton style={{ height: 'fit-content', margin: '5px' }} onClick={e => {
                    setModalType("qr")
                    openModal();
                  }}>
                    <DocumentScannerIcon />
                  </IconButton>
                  <IconButton style={{ height: 'fit-content', margin: '5px' }} onClick={e => {
                    setModalType("barcode")
                    openModal();
                  }}>
                    <CameraAltIcon />
                  </IconButton>
                  <Barcode height={30} width={1.2} fontSize={13} margin={0} marginTop={20} value={qr}
                    onClick={e => {
                      setModalType("qr")
                      openModal();
                    }}
                  />
                  <IconButton style={{ height: 'fit-content', margin: '5px' }}
                    onClick={
                      e => {
                        setQr("");
                        const newPropiedades = propiedades;
                        newPropiedades.serie = "";
                        setPropiedades({ ...propiedades, ...newPropiedades });
                        onSearchClicked();
                      }
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                :
                <Grid item xs={12} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
                  <IconButton style={{ height: 'fit-content', margin: '5px' }} onClick={e => {
                    setModalType("qr")
                    openModal();
                  }}>
                    <DocumentScannerIcon />
                  </IconButton>
                  <IconButton style={{ height: 'fit-content', margin: '5px' }} onClick={e => {
                    setModalType("barcode")
                    openModal();
                  }}>
                    <CameraAltIcon />
                  </IconButton>
                </Grid>
              }
              <Grid item sm={12} md={4}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="demo-simple-select-autowidth-label">Via de administración</InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    label="Via Administracion"
                    value={propiedades.via}
                    onChange={(e) => {
                      setPropiedades(prevState => ({
                        ...prevState,
                        ...prevState.propiedades,
                        via: e.target.value
                      }));
                    }}
                  >
                    <MenuItem value="">TODAS</MenuItem>
                    <MenuItem value="Intravenoso">INTRAVENOSO</MenuItem>
                    <MenuItem value="Intramuscular">INTRAMUSCULAR</MenuItem>
                    <MenuItem value="Nasal">NASAL</MenuItem>
                    <MenuItem value="Oftálmico">OFTÁLMICO</MenuItem>
                    <MenuItem value="Optica">OPTICA</MenuItem>
                    <MenuItem value="Rectal">RECTAL</MenuItem>
                    <MenuItem value="Subcutáneo">SUBCUTÁNEO</MenuItem>
                    <MenuItem value="Tópica">TÓPICA</MenuItem>
                    <MenuItem value="Vía oral">VÍA ORAL</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {(admin === true) && (
                <>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="demo-simple-select-autowidth-label">Sucursal</InputLabel>
                      <Select
                        fullWidth
                        variant="standard"
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        label="Sucursales"
                        value={propiedades.idSucursal}
                        onChange={(e) => setPropiedades(prevState => ({
                          ...prevState,
                          ...prevState.propiedades,
                          idSucursal: e.target.value
                        }))}
                      >
                        <MenuItem value="">TODAS</MenuItem>
                        {sucursales && sucursales.map((item, index) => (
                          <MenuItem key={index} value={item.idSucursal} >{item.nombreSucursal.toUpperCase()}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}></Grid>
                </>
              )}
              <Grid item xs={12} md={4} style={{ justifyContent: 'flex-end' }}>
                <LoadingButton
                  variant="contained"
                  onClick={() => onSearchClicked(null)}
                  loading={modelsLoading && true}
                  style={{
                    float: "right",
                    color: "whitesmoke",
                  }}
                  startIcon={<Search />}
                >
                  Buscar
                </LoadingButton>
              </Grid>
            </>
          }
          DisableComponents={
            <>
              <Grid item xs={8}>
                <FormControlLabel
                  className={classes.switchColor}
                  control={
                    <Switch
                      disabled={modelsLoading && true}
                      onChange={(e) => {
                        setPropiedades((prevState) => ({
                          ...prevState,
                          ...prevState.propiedades,
                          eliminado: e.target.checked ? 1 : 0,
                        }));
                        onSearchClicked(e.target.checked ? 1 : 0);
                      }}
                    />
                  }
                  label="Ver Desabilitados"
                />
              </Grid>

              <Grid item xs={4} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center", }} >
                  <IconButton color="error" aria-label="upload picture" component="span" >
                    <a
                      className={classes.btnPdf}
                      target="_blank"
                      rel="noreferrer"
                      href={`${baseUrl}pdf/inventario-laboratorio?${"admin=" + (admin ? 1 : 0)}
                        ${"&idSucursal=" + idSucursal}
                        ${"&nombreProducto=" + propiedades.nombreProducto}
                        ${"&serie=" + propiedades.serie}
                        ${"&via=" + propiedades.via}
                        ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`
                      }>
                      <MdPictureAsPdf />
                    </a>
                  </IconButton>
                </Grid>
                <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center", }} >
                  <IconButton color="warning" aria-label="upload picture" component="span">
                    <a
                      className={classes.btnExcel}
                      target="_blank"
                      rel="noreferrer"
                      href={`${baseUrl}excel/inventario-laboratorio?${"admin=" + (admin ? 1 : 0)}
                        ${"&idSucursal=" + idSucursal}
                        ${"&nombreProducto=" + propiedades.nombreProducto}
                        ${"&serie=" + propiedades.serie}
                        ${"&via=" + propiedades.via}
                        ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`
                      }>
                      <RiFileExcel2Fill />
                    </a>
                  </IconButton>
                </Grid>
              </Grid>
            </>
          }
          tableLoading={modelsLoading}
          tableErrors={modelsError}
          TableComponent={
            <TablePagination
              nombrePermiso="inventarioLaboratorios"
              permiso={permiso}
              header={[]}
              data={models}
              paginatedApi
              pagina={pagina}
              paginationPageSize={perPage}
              onRowsPerPageChangeApi={changePageRow}
              changePageApi={changePage}
              count={modelsPage !== null ? modelsPage.total : 0}
              labelRowsPerPage={"Renglones por página"}
              extraRows={[
                // {
                //   prop: "vermas",
                //   name: "VER MÁS",
                //   sortable: true,
                //   cell: (row, index) => getVerMas(row, index, sucursales)
                // },
                {
                  prop: "serie",
                  name: "SERIE",
                  sortable: true,
                  cell: (row, index) => <Barcode height={30} width={1.2} fontSize={13} margin={0} marginTop={20} value={row.serie} />
                },
                {
                  prop: "nombreProducto",
                  name: "NOMBRE PRODUCTO",
                  cell: (row, index) => <p key={index}>{row.nombreProducto.toUpperCase()}</p>
                },
                {
                  prop: "inventario",
                  name: "EXISTENCIA",
                  cell: (row, index) => <p key={index}>{row.inventario}</p>
                },
                {
                  prop: "precioProveedor",
                  name: "PRECIO",
                  cell: (row, index) => <p key={index}>$ {row.precioProveedor}</p>
                },
                {
                  prop: "urlFoto",
                  name: "IMAGEN",
                  sortable: false,
                  cell: (row, index) => (
                    <div>
                      <Avatar
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setImgProductoModal(row.urlFoto);
                          setModalType("img")
                          openModal();
                        }}
                        alt={row.nombre?.toUpperCase()}
                        src={row.urlFoto}
                      />
                    </div>
                  ),
                },
                {
                  prop: "acciones",
                  name: "ACCIONES",
                  sortable: false,
                  cell: (row, index) => ((permiso && (permiso[0]["inventarioLaboratorios"] & 4) === 4) || (permiso[0]["inventarioLaboratorios"] & 8) === 8) && (
                    <div>
                      <IconButton onClick={(event) => { setOpenMenuRow(index); setAnchorEl(event.currentTarget); }} >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu anchorEl={anchorEl} keepMounted open={index === openMenuRow} onClose={() => setOpenMenuRow(null)} >
                        {row?.eliminado === null ? (
                          <MenuItem onClick={() => onDeleteClicked(row, "Deshabilitar")} >
                            <Delete style={{ paddingRight: 5 }} /> Deshabilitar
                          </MenuItem>
                        ) : (
                          <MenuItem onClick={() => onDeleteClicked(row, "Habilitar")} >
                            <RestoreFromTrashIcon style={{ paddingRight: 5 }} /> Habilitar
                          </MenuItem>
                        )}
                        {(admin === true || (permiso && permiso[0]["inventarioLaboratorios"] & 4) === 4) && (row.eliminado == null) && (
                          <NavLink exact to={(`productoLaboratorio/editar/${row.idInventarioLaboratorio}`)} style={{ color: '#000', textDecoration: 'none' }}>
                            <MenuItem aria-label="editar"
                              onClick={() => {
                                history.push(`productoLaboratorio/editar/${row.idInventarioLaboratorio}`, { match: row.idInventarioLaboratorio })
                              }}>
                              <Edit style={{ paddingRight: 5 }} /> Editar
                            </MenuItem>
                          </NavLink>
                        )}
                      </Menu>
                    </div>
                  ),
                },
              ]}
            />
          }
        />
      </div>
    </>
  );
};

export default Index;
