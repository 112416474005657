import { Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import React from "react"

const antecedentesPNP = ({ datos, setDatos, ...props }) => {
    return (
        <>
            <Grid item xs={12} sx={{ margin: "20px 0", color: "#FFFFFF", background: "black", padding: "10px" }}>
                ANTECEDENTES PERSONALES NO PATOLOGICOS
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"originario".toLocaleUpperCase()}
                    value={datos?.antecedentesPNP?.originario}
                    placeholder={"originario".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPNP: { ...datos?.antecedentesPNP, originario: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "0px 10px 10px 10px" }}>
                <InputLabel id="vivienda-label">{"vivienda".toLocaleUpperCase()}</InputLabel>
                <Select
                    fullWidth
                    labelId="vivienda-label"
                    label={"vivienda".toLocaleUpperCase()}
                    value={datos?.antecedentesPNP?.vivienda}
                    placeholder={"vivienda".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPNP: { ...datos?.antecedentesPNP, vivienda: e.target.value } }) }}
                >
                    <MenuItem value={"PROPIA"}>PROPIA</MenuItem>
                    <MenuItem value={"RENTA"}>RENTA</MenuItem>
                    <MenuItem value={"PRESTADA"}>PRESTADA</MenuItem>
                    <MenuItem value={"CON FAMILIARES"}>CON FAMILIARES</MenuItem>
                </Select>
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "0px 10px 10px 10px" }}>
                <InputLabel id="tipoFamilia-label">{"tipo de familia".toLocaleUpperCase()}</InputLabel>
                <Select
                    fullWidth
                    labelId="tipoFamilia-label"
                    label={"tipoFamilia".toLocaleUpperCase()}
                    value={datos?.antecedentesPNP?.tipoFamilia}
                    placeholder={"tipoFamilia".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPNP: { ...datos?.antecedentesPNP, tipoFamilia: e.target.value } }) }}
                >
                    <MenuItem value={"INICIACION"}>INICIACION</MenuItem>
                    <MenuItem value={"EXPANSION"}>EXPANSION</MenuItem>
                    <MenuItem value={"CONSOLIDACION"}>CONSOLIDACION</MenuItem>
                    <MenuItem value={"SEPARACION"}>SEPARACION</MenuItem>
                </Select>
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"ESCOLARIDAD".toLocaleUpperCase()}
                    value={datos?.antecedentesPNP?.escolaridad}
                    placeholder={"ESCOLARIDAD".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPNP: { ...datos?.antecedentesPNP, escolaridad: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"numero de hijos".toLocaleUpperCase()}
                    value={datos?.antecedentesPNP?.numeroHijos}
                    placeholder={"numero de hijos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPNP: { ...datos?.antecedentesPNP, numeroHijos: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"grupo sanguineo y rh".toLocaleUpperCase()}
                    value={datos?.antecedentesPNP?.grupoSanguineo}
                    placeholder={"grupo sanguineo y rh".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPNP: { ...datos?.antecedentesPNP, grupoSanguineo: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"inmunizaciones".toLocaleUpperCase()}
                    value={datos?.antecedentesPNP?.inmunizaciones}
                    placeholder={"inmunizaciones".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPNP: { ...datos?.antecedentesPNP, inmunizaciones: e.target.value } }) }}
                />
            </Grid>

            <Grid item xs={12} sx={{ margin: "20px 0" }}>
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "0px 10px 10px 10px" }}>
                <InputLabel id="tabaquismo-label">{"tabaquismo".toLocaleUpperCase()}</InputLabel>
                <Select
                    fullWidth
                    labelId="tabaquismo-label"
                    label={"tabaquismo".toLocaleUpperCase()}
                    value={datos?.antecedentesPNP?.tabaquismo}
                    placeholder={"tabaquismo".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPNP: { ...datos?.antecedentesPNP, tabaquismo: e.target.value } }) }}
                >
                    <MenuItem value={"SI"}>SI</MenuItem>
                    <MenuItem value={"NO"}>NO</MenuItem>
                </Select>
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"edad de Inicio".toLocaleUpperCase()}
                    value={datos?.antecedentesPNP?.edadInicioT}
                    placeholder={"edad de Inicio".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPNP: { ...datos?.antecedentesPNP, edadInicioT: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"cuanto".toLocaleUpperCase()}
                    value={datos?.antecedentesPNP?.cuantoT}
                    placeholder={"cuanto".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPNP: { ...datos?.antecedentesPNP, cuantoT: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "0px 10px 10px 10px" }}>
                <InputLabel id="exFumador-label">{"ex Fumador".toLocaleUpperCase()}</InputLabel>
                <Select
                    fullWidth
                    labelId="exFumador-label"
                    label={"ex Fumador".toLocaleUpperCase()}
                    value={datos?.antecedentesPNP?.exFumador}
                    placeholder={"ex Fumador".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPNP: { ...datos?.antecedentesPNP, exFumador: e.target.value } }) }}
                >
                    <MenuItem value={"SI"}>SI</MenuItem>
                    <MenuItem value={"NO"}>NO</MenuItem>
                </Select>
            </Grid>

            <Grid item xs={12} sx={{ margin: "20px 0" }}>
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "0px 10px 10px 10px" }}>
                <InputLabel id="etilismo-label">{"etilismo".toLocaleUpperCase()}</InputLabel>
                <Select
                    fullWidth
                    labelId="etilismo-label"
                    label={"etilismo".toLocaleUpperCase()}
                    value={datos?.antecedentesPNP?.etilismo}
                    placeholder={"etilismo".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPNP: { ...datos?.antecedentesPNP, etilismo: e.target.value } }) }}
                >
                    <MenuItem value={"SI"}>SI</MenuItem>
                    <MenuItem value={"NO"}>NO</MenuItem>
                </Select>
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"edad de Inicio".toLocaleUpperCase()}
                    value={datos?.antecedentesPNP?.edadInicioE}
                    placeholder={"edad de Inicio".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPNP: { ...datos?.antecedentesPNP, edadInicioE: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"cuanto".toLocaleUpperCase()}
                    value={datos?.antecedentesPNP?.cuantoE}
                    placeholder={"cuanto".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPNP: { ...datos?.antecedentesPNP, cuantoE: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "0px 10px 10px 10px" }}>
                <InputLabel id="actividadesDeportivas-label">{"actividades deportivas".toLocaleUpperCase()}</InputLabel>
                <Select
                    fullWidth
                    labelId="actividadesDeportivas-label"
                    label={"actividades deportivas".toLocaleUpperCase()}
                    value={datos?.antecedentesPNP?.actividadesDeportivas}
                    placeholder={"actividades deportivas".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPNP: { ...datos?.antecedentesPNP, actividadesDeportivas: e.target.value } }) }}
                >
                    <MenuItem value={"SI"}>SI</MenuItem>
                    <MenuItem value={"NO"}>NO</MenuItem>
                </Select>
            </Grid>
        </>
    )
}

export default antecedentesPNP