import React, { useEffect } from "react"
import { Grid, Typography } from "@mui/material"
import { useAuth } from "../../hooks/useAuth"
import FullScreenLoader from "../../components/FullScreenLoader"
import moment from "moment-timezone"

const Home = () => {
  const { loginLoading } = useAuth()

  if (loginLoading) return <FullScreenLoader />

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{ padding: 20 }}
        spacing={3}
      >
        <Grid item xs={12}>
          <Typography
            component="h6"
            variant="h5"
            color="textPrimary"
            style={{ textAlign: "center" }}
          >
            BIENVENIDO
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
export default Home
