import { Grid, TextField } from "@mui/material"
import React from "react"

const antecedentesPP = ({ datos, setDatos, ...props }) => {
    return (
        <>
            <Grid item xs={12} sx={{ margin: "20px 0", color: "#FFFFFF", background: "black", padding: "10px" }}>
                ANTECEDENTES PERSONALES PATOLOGICOS
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"diabetes mellitus".toLocaleUpperCase()}
                    value={datos?.antecedentesPP?.diabetesMelitus}
                    placeholder={"diabetes mellitus".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPP: { ...datos?.antecedentesPP, diabetesMelitus: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"hipertension".toLocaleUpperCase()}
                    value={datos?.antecedentesPP?.hipertension}
                    placeholder={"hipertension".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPP: { ...datos?.antecedentesPP, hipertension: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"cardiopatia".toLocaleUpperCase()}
                    value={datos?.antecedentesPP?.cardiopatia}
                    placeholder={"cardiopatia".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPP: { ...datos?.antecedentesPP, cardiopatia: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"genitourinario".toLocaleUpperCase()}
                    value={datos?.antecedentesPP?.genitourinario}
                    placeholder={"insuficiencia cardiaca".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPP: { ...datos?.antecedentesPP, genitourinario: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"quirurgicos".toLocaleUpperCase()}
                    value={datos?.antecedentesPP?.quirurgicos}
                    placeholder={"quirurgicos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPP: { ...datos?.antecedentesPP, quirurgicos: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"alergicos".toLocaleUpperCase()}
                    value={datos?.antecedentesPP?.alergicos}
                    placeholder={"alergicos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPP: { ...datos?.antecedentesPP, alergicos: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"traumaticos".toLocaleUpperCase()}
                    value={datos?.antecedentesPP?.traumaticos}
                    placeholder={"traumaticos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPP: { ...datos?.antecedentesPP, traumaticos: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"neurologicos".toLocaleUpperCase()}
                    value={datos?.antecedentesPP?.neurologicos}
                    placeholder={"neurologicos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPP: { ...datos?.antecedentesPP, neurologicos: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"hematologicos".toLocaleUpperCase()}
                    value={datos?.antecedentesPP?.hematologicos}
                    placeholder={"hematologicos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPP: { ...datos?.antecedentesPP, hematologicos: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"congenitos".toLocaleUpperCase()}
                    value={datos?.antecedentesPP?.congenitos}
                    placeholder={"congenitos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPP: { ...datos?.antecedentesPP, congenitos: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"dermatologicos".toLocaleUpperCase()}
                    value={datos?.antecedentesPP?.dermatologicos}
                    placeholder={"dermatologicos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPP: { ...datos?.antecedentesPP, dermatologicos: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"digestivos".toLocaleUpperCase()}
                    value={datos?.antecedentesPP?.digestivos}
                    placeholder={"digestivos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPP: { ...datos?.antecedentesPP, digestivos: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"mentales".toLocaleUpperCase()}
                    value={datos?.antecedentesPP?.mentales}
                    placeholder={"mentales".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPP: { ...datos?.antecedentesPP, mentales: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"endocrinos".toLocaleUpperCase()}
                    value={datos?.antecedentesPP?.endocrinos}
                    placeholder={"endocrinos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPP: { ...datos?.antecedentesPP, endocrinos: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"infecciosos".toLocaleUpperCase()}
                    value={datos?.antecedentesPP?.infecciosos}
                    placeholder={"infecciosos".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPP: { ...datos?.antecedentesPP, infecciosos: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"toxicomanias".toLocaleUpperCase()}
                    value={datos?.antecedentesPP?.toxicomanias}
                    placeholder={"toxicomanias".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesPP: { ...datos?.antecedentesPP, toxicomanias: e.target.value } }) }}
                />
            </Grid>
        </>
    )
}

export default antecedentesPP