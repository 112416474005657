/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from "@mui/material";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Image,
} from "@react-pdf/renderer";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import Service from "../services/api";
import { formatCurrency } from '../utils/index';
import { RiFacebookBoxFill } from 'react-icons/ri';
import { RiInstagramFill } from 'react-icons/ri';
import { TbWorld } from 'react-icons/tb';
import { BsFillTelephoneFill } from 'react-icons/bs';
// Create styles

const styles = StyleSheet.create({
    page: {
        backgroundColor: "white",
        color: "black",
    },
    section: {
        margin: 10,
        padding: 10,
    },
    viewer: {
        width: "100%", //the pdf viewer will take up all of the width and height
        height: window.innerHeight,
    },
    header: {
        margin: 10,
        padding: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    row: {
        margin: 10,
        padding: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    col: {
        margin: 10,
        padding: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    ocho: {
        width: "66.66%",
        padding: "5px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    cuatro: {
        width: "33.33%",
    },
    logo: {
        width: "50%",
    },
    check: {
        width: "100%",
    },
    headerTitle: {
        fontWeight: "200 !important",
        fontSize: "12px"
    },
    headerSubTitle: {
        fontWeight: "200 !important",
        fontSize: "10px"
    },
    headerText: {
        fontSize: "10px",
    },
    verticalFlex: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    folioContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "5px",
        width: "70%",
    },
    folioHead: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "white",
        border: "2px solid #4B0000",
        backgroundColor: "#710000",
        width: "100%",
        marginBottom: "1px",
        borderRadius: "2px",
    },
    folio: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "black",
        border: "2px solid #666666",
        width: "100%",
        borderRadius: "2px",
    },
    fechaContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "70%",
    },
    fechaHead: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "white",
        border: "2px solid #4B0000",
        backgroundColor: "#710000",
        width: "100%",
        marginBottom: "1px",
        borderRadius: "2px",
    },
    fecha: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "black",
        border: "2px solid #666666",
        width: "100%",
        borderRadius: "2px",
    },
    nombre: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    telefono: {
        width: "45%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    pago: {
        width: "55%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    factura: {
        width: "55%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    nombreTitle: {
        width: "15%",
        textAlign: "center",
    },
    nombreContent: {
        height: "30px",
        width: "85%",
        backgroundColor: "whitesmoke",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    telefonoTitle: {
        width: "33.5%",
        textAlign: "center",
    },
    telefonoContent: {
        height: "30px",
        width: "66.5%",
        backgroundColor: "whitesmoke",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    pagoTitle: {
        width: "50%",
        textAlign: "center",
    },
    pagoContent: {
        height: "30px",
        width: "50%",
        backgroundColor: "whitesmoke",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    facturaTitle: {
        width: "50%",
        textAlign: "center",
    },
    facturaContent: {
        width: "50%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center"
    },
    si: {
        width: "30px",
        height: "30px",
        backgroundColor: "whitesmoke",
        padding: "5px",
    },
    no: {
        width: "30px",
        height: "30px",
        backgroundColor: "whitesmoke",
        padding: "5px",
    },
    container: {
        width: "100%",
        margin: "10px 0",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    dos: {
        padding: "5px",
        width: "16.66%",
        borderLeft: "1px solid #666666",
        borderRight: "1px solid #666666",
    },
    rowBody: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        fontSize: "12px",
        textAlign: "center",
    },
    rowHeader: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: "#1D5E7B",
        color: "white",
        fontSize: "12px",
        textAlign: "center",
    }
});

// Create Document Component
function BasicDocument({ match, ...props }) {
    const [logo, setLogo] = useState(process.env.PUBLIC_URL + '/img/logo.png');
    const [logo2, setLogo2] = useState(process.env.PUBLIC_URL + '/img/logoHospitalito2.png')
    const [check, setCheck] = useState(process.env.PUBLIC_URL + '/img/check.png');
    const [net, setNet] = useState(process.env.PUBLIC_URL + '/img/net.png');
    const [facebook, setFacebook] = useState(process.env.PUBLIC_URL + '/img/facebook.png');
    const [instagram, setInstagram] = useState(process.env.PUBLIC_URL + '/img/instagram.png');
    const [phone, setPhone] = useState(process.env.PUBLIC_URL + '/img/phone.png');
    const [loading, setLoading] = useState(true);
    const contenedor = useRef();
    const [ordenServicio, setOrdenServicio] = useState(null);
    let id = match.params.id;

    const getOrdenServicio = async () => {
        if (!!id) {
            let res = await Service.get(
                "orden-servicio?idOrdenServicio=" +
                id +
                "&expand=detallePaciente," +
                "entradas.entrada.movimientos.producto," +
                "entradasLaboratorio.entrada.movimientos.producto," +
                "ordenServicioServicio," +
                "sucursal"
            );
            let medicamentosFiltrado = [];
            let equiposFiltrado = [];
            res?.resultado[0]?.entradas.forEach(entrada => {
                entrada?.entrada?.movimientos.forEach(mov => {
                    if (mov.producto.tipo === "Medicamento") {
                        medicamentosFiltrado.push(mov);
                    } else if (mov.producto.tipo === "Equipo") {
                        equiposFiltrado.push(mov);
                    }
                });
            });

            let totalEquipos = 0;
            if (equiposFiltrado.length > 0) {
                totalEquipos = equiposFiltrado?.reduce((a, s) => {
                    return a + Math.round(((s?.producto?.precioPublico * s?.cantidad)) * Math.pow(10, 2)) / Math.pow(10, 2);
                }, 0);
            }

            let totalMed = medicamentosFiltrado?.reduce((a, s) => {
                return a + Math.round(((s?.producto?.precioPublico * s?.cantidad)) * Math.pow(10, 2)) / Math.pow(10, 2);
            }, 0);

            let totalServ = res?.resultado[0]?.ordenServicioServicio?.reduce((a, s) => {
                return a + Math.round((s?.totalDescuentoPublico) * Math.pow(10, 2)) / Math.pow(10, 2);
            }, 0);

            setOrdenServicio({
                folio: res?.resultado[0]?.idOrdenServicio,
                fecha: res?.resultado[0]?.fechaSolicitud,
                facturacion: true,
                formaPago: res?.resultado[0]?.formaPago,
                servicios: [...res?.resultado[0]?.ordenServicioServicio],
                paciente: { ...res?.resultado[0]?.detallePaciente },
                sucursal: { ...res?.resultado[0]?.sucursal },
                medicamentos: [...medicamentosFiltrado],
                equipo: totalEquipos,
                total: (Math.round((totalEquipos + totalMed + totalServ) * Math.pow(10, 2)) / Math.pow(10, 2))
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        getOrdenServicio();
    }, []);

    const getFolio = _folio => {
        if (_folio.toString().length >= 7) {
            return "N° " + _folio;
        } else {
            let preFolio = "N° ";
            let x = 7 - _folio.toString().length;
            while (x > 0) {
                preFolio += "0";
                x--;
            }
            preFolio += _folio;
            return preFolio;
        }
    }

    return (
        <>
            {
                !loading
                    ?
                    <PDFViewer style={styles.viewer}>
                        {/* Start of the document*/}
                        <Document>
                            {
                            }
                            {/*render a single page*/}
                            <Page size="A4" style={styles.page}>
                                <View style={styles.header}>
                                    <div style={styles.ocho}>
                                        <Image style={styles.logo} src={ordenServicio?.sucursal?.idSucursal == "1" ? logo : logo2} />
                                        <Text style={styles.headerTitle}>{ordenServicio?.sucursal?.idSucursal ? "MEDICINA OCUPACIONAL NUEVA VIDA S.A. DE C.V." : "LHOSPITALITO S.A. DE C.V."}</Text>
                                        <Text style={styles.headerSubTitle}>{ordenServicio?.sucursal?.idSucursal ? "RFC: PCO191126GM2, Blvd. Juan Navarrete 192-Local -L" : "RFC: PCO191126GM2, Blvd. Juan Navarrete 192-Local -L"}</Text>
                                        <Text style={styles.headerText}>Col. Villa Satelite. Hermosillo, Son. Cp. 83200</Text>
                                    </div>
                                    <div style={styles.cuatro}>
                                        <div style={styles.verticalFlex}>
                                            <div style={styles.folioContainer}>
                                                <div style={styles.folioHead}><Text>FOLIO</Text></div>
                                                <div style={styles.folio}><Text>{getFolio(ordenServicio?.folio)}</Text></div>
                                            </div>
                                            <div style={styles.fechaContainer}>
                                                <div style={styles.fechaHead}><Text>FECHA</Text></div>
                                                <div style={styles.fecha}><Text>{moment(ordenServicio?.fecha).format("DD/MM/YYYY")}</Text></div>
                                            </div>
                                        </div>
                                    </div>
                                </View>
                                <View style={styles.col}>
                                    <div style={styles.nombre}>
                                        <Text style={styles.nombreTitle}>Nombre</Text><div style={styles.nombreContent}><Text>{ordenServicio?.paciente?.nombreCompleto}</Text></div>
                                    </div>
                                    <div style={styles.container}>
                                        <div style={styles.telefono}>
                                            <Text style={styles.telefonoTitle}>Telefono</Text><div style={styles.telefonoContent}><Text>{ordenServicio?.paciente?.telefono}</Text></div>
                                        </div>
                                        <div style={styles.pago}>
                                            <Text style={styles.pagoTitle}>Metodo de pago</Text><div style={styles.pagoContent}><Text>{ordenServicio?.formaPago}</Text></div>
                                        </div>
                                    </div>
                                    <div style={styles.container}>
                                        <div style={styles.telefono}>
                                        </div>
                                        <div style={styles.factura}>
                                            <Text style={styles.facturaTitle}>Facturación</Text>
                                            <div style={styles.facturaContent}>
                                                <Text>SI</Text><div style={styles.si}>{ordenServicio?.facturacion ? <Image style={styles.check} src={check} /> : <></>}</div>
                                                <Text>NO</Text><div style={styles.no}>{!ordenServicio?.facturacion ? <Image style={styles.check} src={check} /> : <></>}</div>
                                            </div>
                                        </div>
                                    </div>
                                </View>
                                <View style={styles.col} ref={contenedor}>
                                    <div style={styles.rowHeader}>
                                        <div style={styles.dos}><Text style={{ textAlign: "center" }}>CANTIDAD</Text></div>
                                        <div style={styles.ocho}><Text style={{ textAlign: "center" }}>DESCRIPCION</Text></div>
                                        <div style={styles.dos}><Text style={{ textAlign: "center" }}>TOTAL</Text></div>
                                    </div>
                                    {
                                        ordenServicio?.servicios.map((servicio, i) => {
                                            return (
                                                <div key={servicio?.idServicio} style={styles.rowBody}>
                                                    <div style={{ ...styles.dos, backgroundColor: (i + 1) % 2 === 0 ? "whitesmoke" : "white" }}><Text style={{ textAlign: "center" }}>{servicio?.cantidad}</Text></div>
                                                    <div style={{ ...styles.ocho, backgroundColor: (i + 1) % 2 === 0 ? "whitesmoke" : "white" }}><Text style={{ fontSize: "10px", color: "#353535", textAlign: "center" }}>{servicio?.concepto}</Text></div>
                                                    <div style={{ ...styles.dos, backgroundColor: (i + 1) % 2 === 0 ? "whitesmoke" : "white" }}><Text style={{ textAlign: "center" }}>{"$ " + formatCurrency(Math.round((servicio?.totalDescuentoPublico) * Math.pow(10, 2)) / Math.pow(10, 2), 2)}</Text></div>
                                                </div>
                                            );
                                        })
                                    }
                                    {
                                        ordenServicio?.medicamentos.map((medicamento, i) => {
                                            return (
                                                <div key={medicamento?.idProductoInventario + "-" + i} style={styles.rowBody}>
                                                    <div style={{ ...styles.dos, backgroundColor: (ordenServicio?.servicios.length + (i + 1)) % 2 === 0 ? "whitesmoke" : "white" }}><Text style={{ textAlign: "center" }}>{medicamento?.cantidad}</Text></div>
                                                    <div style={{ ...styles.ocho, backgroundColor: (ordenServicio?.servicios.length + (i + 1)) % 2 === 0 ? "whitesmoke" : "white" }}><Text style={{ fontSize: "10px", color: "#353535", textAlign: "left" }}>{medicamento?.producto?.nombreProducto}</Text></div>
                                                    <div style={{ ...styles.dos, backgroundColor: (ordenServicio?.servicios.length + (i + 1)) % 2 === 0 ? "whitesmoke" : "white" }}><Text style={{ textAlign: "center" }}>{"$ " + formatCurrency(Math.round((medicamento?.producto?.precioPublico) * Math.pow(10, 2)) / Math.pow(10, 2), 2)}</Text></div>
                                                </div>
                                            );
                                        })
                                    }
                                    {ordenServicio?.equipo !== 0 && (
                                        <div style={styles.rowBody}>
                                            <div style={{ ...styles.dos, backgroundColor: (ordenServicio?.servicios.length + ordenServicio?.medicamentos.length + 1) % 2 === 0 ? "whitesmoke" : "white" }}><Text style={{ textAlign: "center" }}></Text></div>
                                            <div style={{ ...styles.ocho, backgroundColor: (ordenServicio?.servicios.length + ordenServicio?.medicamentos.length + 1) % 2 === 0 ? "whitesmoke" : "white" }}><Text style={{ fontSize: "10px", color: "#353535", textAlign: "center" }}>MATERIAL DE CURACIÓN</Text></div>
                                            <div style={{ ...styles.dos, backgroundColor: (ordenServicio?.servicios.length + ordenServicio?.medicamentos.length + 1) % 2 === 0 ? "whitesmoke" : "white" }}><Text style={{ textAlign: "center" }}>{ordenServicio?.equipo !== 0 ? formatCurrency(ordenServicio?.equipo, 2) : "$0.00"}</Text></div>
                                        </div>
                                    )
                                    }
                                    <div style={{ ...styles.rowBody, borderBottom: "1px solid #666666", borderTop: "1px solid #666666" }}>
                                        <div style={{ ...styles.dos, backgroundColor: (ordenServicio?.servicios.length + ordenServicio?.medicamentos.length + 2) % 2 === 0 ? "whitesmoke" : "white" }}><Text style={{ textAlign: "center" }}></Text></div>
                                        <div style={{ ...styles.ocho, backgroundColor: (ordenServicio?.servicios.length + ordenServicio?.medicamentos.length + 2) % 2 === 0 ? "whitesmoke" : "white", alignItems: "right" }}><Text style={{ textAlign: "right" }}>TOTAL</Text></div>
                                        <div style={{ ...styles.dos, backgroundColor: (ordenServicio?.servicios.length + ordenServicio?.medicamentos.length + 2) % 2 === 0 ? "whitesmoke" : "white" }}><Text style={{ textAlign: "center" }}>{formatCurrency(ordenServicio?.total, 2)}</Text></div>
                                    </div>
                                </View>
                                <View style={{ position: "absolute", bottom: "35px", left: "0", right: "0" }}>
                                    <div style={{ width: "100%" }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly", margin: "10px" }}>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                                <Image style={{ height: "20px", width: "20px", marginRight: "3px" }} src={phone} />
                                                <Text style={{ fontSize: "10px", color: "#353535" }}>(662) 399 83 85</Text>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                                <Image style={{ height: "20px", width: "20px", marginRight: "3px" }} src={facebook} />
                                                <Image style={{ height: "20px", width: "20px", marginRight: "3px" }} src={instagram} />
                                                <Text style={{ fontSize: "10px", color: "#353535" }}>
                                                    /Nuevavidamx
                                                </Text>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                                <Image style={{ height: "20px", width: "20px", marginRight: "3px" }} src={net} />
                                                <Text style={{ fontSize: "10px", color: "#353535" }}>www.clinicanuevavida.org</Text>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                            <Text style={{ fontSize: "10px", color: "#353535" }}>
                                                Solicita tu factura al correo:
                                            </Text>
                                            <Text style={{ fontSize: "10px", color: "#1D5E7B", marginLeft: "3px" }}>
                                                facturacion@clinicanuevavida.org.mx.
                                            </Text>
                                        </div>
                                        <div>
                                            <Text style={{ fontSize: "10px", color: "#353535", textAlign: "center" }}>
                                                Envía tus datos fiscales y comprobante de pago.
                                            </Text>
                                        </div>
                                    </div>
                                </View>
                                <View style={{ position: "absolute", bottom: "0", left: "0", right: "0" }}>
                                    <div style={{ backgroundColor: "#1D5E7B", height: "30px", width: "100%" }}></div>
                                </View>
                            </Page>
                        </Document>
                    </PDFViewer>
                    :
                    <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress />
                    </div>
            }
        </>
    );
}
export default BasicDocument;