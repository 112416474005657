/* eslint-disable array-callback-return */
    import React, { useEffect, useState } from "react";
    import { styled } from '@mui/material/styles';
    import LoadingButton from '@mui/lab/LoadingButton';
    // <========================= COMPONENTES =============================>
    import {
        Paper,
        Box,
        BottomNavigation,
        BottomNavigationAction,
        Chip,
        TableCell,
        TableRow,
        Divider,
        TableContainer,
        Grid,
        Table,
        TableHead,
        TableBody,
        Typography,
        CircularProgress,
        TextField,
        Switch,
        Breadcrumbs
    } from "@mui/material";
    import { useHistory } from "react-router-dom";
    import { useGet } from "../../hooks/useGet";
    import { usePost } from "../../hooks/usePost";
    import { Home} from "@mui/icons-material";
    // <========================= STYLES =============================>
    import { horarioStyle, tableCellStyles, tableRowStyles, baseStyles,breadCrumbsStyles } from '../../utils';
    // <========================= ICONOS =============================>
    import ViewListIcon from '@mui/icons-material/ViewList';
    import {useAlert} from "./../../hooks/useAlert";
////////////////////////////////////////////////////////////////////////////////////////////////////// //
    // <=======================================STATES DEF ETC====================================================> //

    // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
    // <=======================================FUNCIONES MEMO====================================================> //
    const Div = styled('div')(({ theme }) => ({
        ...theme.typography.button,
        color: "black",
        padding: theme.spacing(1),
        BorderStyle: "outset"
      }));
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
    const pStyles = horarioStyle();
    const useStyles = baseStyles();
    const StylesBreadCrumb = breadCrumbsStyles()(Chip);
    const TableCellModify = tableCellStyles()(TableCell);
    const TableRowModify = tableRowStyles()(TableRow);

    const Index = ({ match }) => {
    const { showAlert } = useAlert();
    const history = useHistory();
    const classes = useStyles();
    const ps = pStyles();
    const id = match.params.idSucursal;
    const editing = match.params.idSucursal !== undefined;
    const [errores, cargandoBoton, peticion, detalles] = usePost();
    const [objHorario, setObjHorario] = useState([]);
    const [horarios, cargandoHorario] = useGet({
        initUrl: "horario-sucursal",
        params: {
          idSucursal: id,
        },
        action: editing ? true : false,
    });
    
    const cargarHorarios =  async (e,i) => {
      const newObj = horarios.resultado; 
      if(e.target.name !== "show"){
        newObj[i][e.target.name] = e.target.value;
      }else{
        newObj[i][e.target.name] = e.target.checked; 
       if(e.target.checked){
           if(newObj[i]["fechaAbierto"] >= newObj[i]["fechaDescansoInicio"] 
              || newObj[i]["fechaAbierto"] >= newObj[i]["fechaDescansoFin"] 
              || newObj[i]["fechaCerrado"] <= newObj[i]["fechaDescansoInicio"] 
              || newObj[i]["fechaCerrado"] <= newObj[i]["fechaDescansoFin"]
              ){
            newObj[i]["fechaAbierto"] = "07:00"; 
            newObj[i]["fechaCerrado"] = "20:00"; 
           }
       }
      }
      setObjHorario((newObj) => [
        ...newObj,
      ]);
    } 

    const guardarHorario =  async () => {
       let cont = 0;
       objHorario.map((e,i) => {
            const keys = Object.keys(e);
                keys.map((name) => {
                    if(name !== "show" && e[name] === ""){
                        showAlert({
                            message: "Favor de checar los datos, fecha inválida.",
                            severity: "error",
                          });
                        cont++;
                    }
                })
         });
        if(cont === 0) peticion("horario-sucursal/guardar", {idSucursal: id,horarios: objHorario}, "")
    } 

    useEffect(() => {
        if (horarios.resultado) {
            setObjHorario(horarios.resultado);
        }
    },[horarios.resultado])

    return (
        <>
        <Box sx={{ pb: 7 }}>
            <Paper className={ps.permisosPaperNav} elevation={3}>
                <BottomNavigation
                    className={ps.btnGroup}
                    showLabels
                    onChange={e => {

                    }}
                >
                    <BottomNavigationAction  onClick={() => history.push("/sucursales")} icon={<ViewListIcon />} className={ps.btnNavActivo} label="Horario Semanal"/>
                </BottomNavigation>
            </Paper>
            <Paper className={ps.permisosPaper}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Breadcrumbs aria-label="breadcrumb">
                        <StylesBreadCrumb
                            component="a"
                            label="Inicio"
                            icon={<Home fontSize="small" />}
                            onClick={() => history.push("/")}
                        />
                        <StylesBreadCrumb
                            component="a"
                            label="Sucursales"
                            onClick={() => history.push("/sucursales")}
                        />
                         <StylesBreadCrumb
                            component="a"
                            label={"Horario - "+id}
                        />
                        </Breadcrumbs>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Div><strong>{"HORARIO SEMANAL"}</strong></Div>
                    <Divider />
                </Grid>

                <TableContainer className={classes.container}>
               {
                       cargandoHorario || cargandoBoton ? (
                        <div className={classes.loadingTable}>
                          <CircularProgress />
                        </div>
                      ):(
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRowModify>
                                    <TableCellModify><strong>Dia</strong> </TableCellModify>
                                    <TableCellModify><strong>Abierto</strong></TableCellModify>
                                    <TableCellModify><strong>Cerrado</strong></TableCellModify>
                                    <TableCellModify><strong>Horario Comida</strong></TableCellModify>
                                    <TableCellModify><strong>Inicio</strong></TableCellModify>
                                    <TableCellModify><strong>Fin</strong></TableCellModify>
                          
                                </TableRowModify>
                                </TableHead>
                                <TableBody>
                                {
                                objHorario && objHorario.map((item, index) => {
                                    return( 
                                        <TableRowModify key={item + index + Math.random(100)} >
                                            <TableCellModify>
                                            <Typography>{item.dia}</Typography>
                                            </TableCellModify>

                                            <TableCellModify>
                                            <TextField
                                                label='Fecha Abierto'
                                                name="fechaAbierto"
                                                type="time"
                                                defaultValue={item.fechaAbierto}
                                                InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                inputProps={{
                                                    step: 300, // 5 min
                                                  }}
                                                onChange={(e) => {if(e.target.value === "") {
                                                    objHorario[index][e.target.name] = "";
                                                    setObjHorario((objHorario) => [
                                                        ...objHorario,
                                                      ]);
                                                }}}
                                                onBlur={(e) => {
                                                    if(e.target.value >= item.fechaCerrado 
                                                         || item.show && e.target.value >= item.fechaDescansoInicio
                                                         || item.show && e.target.value >= item.fechaDescansoFin
                                                         ){
                                                        showAlert({
                                                            message: "La fecha Inicial no puede ser superior a la fecha FinalNi a las fechas Inicio y fin de descanso.",
                                                            severity: "error",
                                                          });
                                                        objHorario[index][e.target.name] = item.fechaAbierto;
                                                        setObjHorario((objHorario) => [
                                                            ...objHorario,
                                                          ]);
                                                    }else{
                                                        cargarHorarios(e,index)                                                        
                                                    }
                                                  }}
                                            />
                                            </TableCellModify>

                                            <TableCellModify>
                                                <TextField
                                                    label='Fecha Cerrado'
                                                    name="fechaCerrado"
                                                    type="time"
                                                    defaultValue={item.fechaCerrado}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                      }}
                                                    inputProps={{
                                                        step: 300, // 5 min
                                                      }}
                                                    onChange={(e) => {if(e.target.value === "") {
                                                        objHorario[index][e.target.name] = "";
                                                        setObjHorario((objHorario) => [
                                                            ...objHorario,
                                                          ]);
                                                    }}}
                                                    onBlur={(e) => {
                                                        if(item.fechaAbierto >= e.target.value 
                                                            || item.show && e.target.value <= item.fechaDescansoInicio
                                                            || item.show && e.target.value <= item.fechaDescansoFin
                                                            ){
                                                            showAlert({
                                                                message: "La fecha final no puede ser superior a la fecha inicial,Ni a las fechas Inicio y fin de descanso.",
                                                                severity: "error",
                                                              });
                                                            objHorario[index][e.target.name] = item.fechaCerrado;
                                                            setObjHorario((objHorario) => [
                                                                ...objHorario,
                                                              ]);
                                                        }else{
                                                            cargarHorarios(e,index)
                                                        }
                                                        
                                                    }}
                                                />
                                            </TableCellModify>

                                            <TableCellModify>
                                                <Switch
                                                    id={index.toString() + item.dia}
                                                    name="show"
                                                    checked={item.show}
                                                    onChange={(e) => {cargarHorarios(e,index)}}
                                                />
                                            </TableCellModify>

                                            <TableCellModify>
                                            <TextField
                                                id={index.toString() + item.dia}
                                                label='Inicio Desacanso'
                                                name="fechaDescansoInicio"
                                                type="time"
                                                defaultValue={item.fechaDescansoInicio}
                                                disabled={!item.show}
                                                InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                inputProps={{
                                                    step: 300, // 5 min
                                                  }}
                                                onChange={(e) => {if(e.target.value === "") {
                                                    objHorario[index][e.target.name] = "";
                                                    setObjHorario((objHorario) => [
                                                        ...objHorario,
                                                      ]);
                                                }}}
                                                onBlur={(e) => {
                                                    if(e.target.value < item.fechaAbierto 
                                                        || e.target.value > item.fechaCerrado
                                                        || e.target.value > item.fechaDescansoInicio){
                                                        showAlert({
                                                            message: "La fecha Inferior a la fecha Abierto ni superior a la fecha Cerrado o Fecha Fin.",
                                                            severity: "error",
                                                          });
                                                        objHorario[index][e.target.name] = item.fechaDescansoInicio;
                                                        setObjHorario((objHorario) => [
                                                            ...objHorario,
                                                          ]);
                                                    }else{
                                                        cargarHorarios(e,index)
                                                    }
                                                }}
                                              />
                                            </TableCellModify>
                                            <TableCellModify>
                                                <TextField
                                                    id={index.toString() + item.dia}
                                                    label='Fin Desacanso'
                                                    name="fechaDescansoFin"
                                                    type="time"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 300, // 5 min
                                                    }}
                                                    defaultValue={item.fechaDescansoFin}
                                                    disabled={!item.show}
                                                    onChange={(e) => {if(e.target.value === "") {
                                                        objHorario[index][e.target.name] = "";
                                                        setObjHorario((objHorario) => [
                                                            ...objHorario,
                                                          ]);
                                                    }}}  
                                                    onBlur={(e) => {
                                                        if(e.target.value <= item.fechaDescansoInicio 
                                                            || e.target.value >= item.fechaCerrado
                                                            || e.target.value < item.fechaAbierto){
                                                            showAlert({
                                                                message: "La fecha Inferior a la fecha Abierto ni superior a la fecha Cerrado o Fecha Fin.",
                                                                severity: "error",
                                                              });
                                                            objHorario[index][e.target.name] = item.fechaDescansoInicio;
                                                            setObjHorario((objHorario) => [
                                                                ...objHorario,
                                                            ]);
                                                        }else{
                                                            cargarHorarios(e,index)
                                                        }
                                                    }}
                                                    />
                                            </TableCellModify>

                                        </TableRowModify>
                                        )
                                
                                    })
                                        
                                }
                              </TableBody>
                       </Table>
                       )
                }
                    
                </TableContainer>

                <Paper className={ps.permisosPaperBtn} style={{}}>
                            <Grid item xs={12} xl={12}>
                                <Grid container justifyContent="flex-end">
                                    <LoadingButton
                                        loading={cargandoBoton}
                                        variant="contained"
                                        onClick={e=>{
                                            guardarHorario();
                                        }}
                                        size="large"
                                        style={{ color: "#fff" }}
                                    >
                                        GUARDAR HORARIO
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                 </Paper>
            </Paper>
        </Box>
    </>
    );
}

export default Index;