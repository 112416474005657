import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Grid, Typography } from '@mui/material'
import { useLocalStorage } from '../../../hooks/useLocalStorage';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BlockIcon from '@mui/icons-material/Block';
import { KeyboardReturnOutlined } from '@mui/icons-material';

const Step1 = ({ nextStep, setSolicitud, ...props }) => {
    const checkeds = [
        {
            id: 1,
            name: 'Atención urgente a domicilio',
            check: false
        },
        {
            id: 2,
            name: 'Video-consulta',
            check: false
        },
        {
            id: 3,
            name: 'Visita medica',
            check: false
        },
        {
            id: 4,
            name: 'Visita enfermeria',
            check: false
        },
        {
            id: 5,
            name: 'Traslado en ambulancia',
            check: false
        },
        {
            id: 6,
            name: "Acompañamiento en L'Hospitalito u hospital de referencia",
            check: false
        },
    ];
    const [checked, setChecked] = useState(checkeds);
    const [sig, setSig] = useState(false);
    const [solicitudServicio, setSolicitudServicio] = useLocalStorage("solicitudServicio", {});

    const guardar = () => {
        let filterChecked = checked.filter((e) => e.check === true);
        setSolicitudServicio({ ...solicitudServicio, ...filterChecked });
    }

    useEffect(() => {
        let progress = 0;
        checked.map(({ check }) => {
            if (check !== false) {
                progress = 100;
            }
            if (progress == 100) {
                setSig(true);
            } else {
                setSig(false);
            }
        })
        setSolicitud(progress, 0);
    }, [checked]);

    useEffect(() => {
    }, [solicitudServicio]);

    return (
        <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <Grid item xs={12} sm={10} md={8} lg={6} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ margin: '25px' }}>
                    <img
                        alt="L-Hospitalito-Logo.png"
                        src="https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2Flogo.png?alt=media&token=08d29a51-3658-451a-9975-4edd5c989540"
                        title="L-Hospitalito"
                        height="90"
                        width='100%'
                        style={{ objectFit: 'contain' }}
                    />
                </div>
            </Grid>

            <Grid item md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant='h1' style={{ fontSize: '30px', textAlign: 'center' }}> SELECCIONA EL SERVICIO A SOLICITAR</Typography>
            </Grid>
            <Grid item xs={12} sm={10} md={8} lg={6} ></Grid>
            <Grid item xs={12} sm={10} md={8} lg={6} style={{ display: 'flex', flexDirection: 'column', margin: '0px 20px 0 20px' }}>
                {checked.map(({ id, name, check }) => (
                    <>
                        <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', padding: 0, margin: 0, textAlign: 'justify' }} key={id}>
                            <Checkbox
                                checked={check}
                                onChange={(e) => setChecked((prevState) => {
                                    const newState = [...prevState]
                                    newState[id - 1].check = e.target.checked
                                    return newState
                                })}
                                inputProps={{ 'aria-label': 'controlled' }}
                                size="medium"
                            />
                            <Typography variant='p' style={{ fontSize: '23px' }} >{name}</Typography>
                        </Grid>
                    </>
                ))}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', margin: '20px 0 20px 0' }}>
                    <Button
                        onClick={e => {
                            nextStep(1);
                            guardar();
                        }}
                        disabled={!sig} style={{ background: '#155480', color: '#FFF', width: '60%', transition: 'background 1s', border: 'none' }}>
                        {sig ? <>Siguiente<NavigateNextIcon style={{ margin: '0 0 1px 10px' }} /></> : <>Siguiente<BlockIcon style={{ margin: '0 0 1px 10px' }} /></>}
                    </Button>
                </div>
            </Grid>
        </Grid>
    )
}

export default Step1