import React from 'react'
import { Page, Document, Text, StyleSheet, Image, pdf, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
})
const ImagePDF = ({ img, ...props }) => {
    return (
        <Document>
            {/*render a single page*/}
            <Page size="A4" style={styles.page}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
                    <View>
                        <Image src={img} />
                    </View>
                </div>
            </Page>
        </Document>
    )
}

const blobToExport = async (img) => {
    return await pdf(<ImagePDF img={img} />).toBlob()
}

export default blobToExport