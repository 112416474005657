/* eslint-disable eqeqeq */
import { useEffect, useState, useRef } from 'react';
// <========================= COMPONENTES =============================>
import {
    Grid,
    Button,
    InputAdornment,
    FormControlLabel,
    CircularProgress,
    Input,
    FormControl,
    Switch
} from '@mui/material';
import { useAlert } from "./../hooks/useAlert";
import FirebaseService from "../services/firebase";
import { useFileManager } from '../hooks/useFileManager';
// <========================= STYLES =============================>
import { fileManagerStyle } from '../utils/index';
// <========================= ICONOS =============================>
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';


const useFileManagerStyle = fileManagerStyle();
const FileManager = () => {
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
    // <=======================================STATES DEF ETC====================================================> //
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
    const fileManagerStyle = useFileManagerStyle();
    const imageFile = useRef();
    const videoFile = useRef();
    const defaultPDF = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Campanas%2Fdefaultpdf.png?alt=media&token=7e79fc7c-aec2-41ce-aa0c-e1f9c6e11745";
    const { showAlert } = useAlert();
    const { files, agregarFiles, clearFiles, activeTab, agregarActiveTab, cargandoFiles, setCarga } = useFileManager();

    const [vinculoActivo, setVinculoActivo] = useState(false);
    const defaultImagenPropiedades = {
        tipo: "",
        file: "",
        tempUrl: "",
        redirect: "",
        agregar: false,
        nombre: "",
    };
    const getFileUpload = (path, file) => {
        return new Promise((res, rej) => {
            let url = FirebaseService.uploadFile(path, file);
            res(url);
        });
    }
    const [propiedades, setPropiedades] = useState({ ...defaultImagenPropiedades });
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
    // <=======================================FUNCIONES MEMO====================================================> //
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
    // <=======================================EFFECTS CALLBACKS=================================================> //
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
    useEffect(() => {
        setPropiedades({ ...propiedades, orden: files.length + 1 });
    }, [files]);

    useEffect(() => {
        if(propiedades.tempUrl != ""){
            if(propiedades.tipo == "Video"){
                videoFile.current.src = propiedades.tempUrl;
            }
            if(propiedades.tipo == "Imagen"){
                imageFile.current.src = propiedades.tempUrl;
            }
        }
        if (propiedades.agregar) {
            setPropiedades({ ...propiedades, agregar: false });
            agregarFiles(propiedades);
            setPropiedades({ ...defaultImagenPropiedades });
            setVinculoActivo(false);
            setCarga(false);
            showAlert({
                message: "Archivo agregado",
                severity: "success",
            });
        }
    }, [propiedades]);

    return (
        <Grid container spacing={2} style={{ padding: '16px 0 0 16px !important' }}>
            <Grid item xs={12} md={6} className={fileManagerStyle.typeImagenLeftSide}>
                {
                    (propiedades.tipo === "Imagen")
                    &&
                    (
                        <img
                            ref={imageFile}
                            className={fileManagerStyle.typeImagenImage}
                            src={propiedades.tempUrl}
                            alt={"Imagen no Valida"}
                        />
                    )
                }
                {
                    (propiedades.tipo === "Video")
                    &&
                    (
                        <video
                            controls
                            className={fileManagerStyle.typeImagenImage}
                            ref={videoFile}
                            src={propiedades.tempUrl}
                        >
                        </video>
                    )
                }
                {
                    (propiedades.tipo === "Pdf")
                    &&
                    (
                        <img
                            ref={videoFile}
                            className={fileManagerStyle.typeImagenImage}
                            src={defaultPDF}
                            alt={"PDF no Valido"}
                        />
                    )
                }
            </Grid>
            <Grid item xs={12} md={6} className={fileManagerStyle.typeImagenRightSide}>
                {
                    !cargandoFiles ?
                        <>
                            <label htmlFor="typeImage">
                                <input
                                    id="typeImage"
                                    type="file"
                                    onChange={
                                        e => {

                                            const file = e.target.files[0];
                                            const tipoFile = file.name.split('.').pop();
                                            switch (tipoFile) {
                                                case 'jpg':
                                                case 'jpeg':
                                                case 'png':
                                                case 'gif':
                                                    setPropiedades({ ...propiedades, file: e.target.files[0], tipo: "Imagen", tempUrl: URL.createObjectURL(e.target.files[0]) });
                                                    break;
                                                case 'pdf':
                                                    setPropiedades({ ...propiedades, file: e.target.files[0], tipo: "Pdf" , tempUrl:URL.createObjectURL(e.target.files[0])});
                                                    break;
                                                case 'mp4':
                                                case 'avi':
                                                case 'mkv':
                                                    setPropiedades({ ...propiedades, file: e.target.files[0], tipo: "Video", tempUrl: URL.createObjectURL(e.target.files[0]) });
                                                    break;
                                                default:
                                                    showAlert({ message: 'El archivo no tiene la extensión adecuada', severity: "error", });
                                                    break;
                                            }
                                        }
                                    }
                                    style={{ display: 'none' }}
                                />
                                <Button
                                    component="span"
                                    style={{borderTop:'2px solid #DAD083'}}
                                    className={fileManagerStyle.typeImagenButton}
                                >
                                    <FileUploadIcon />
                                    Subir Archivo
                                </Button>
                            </label>
                            {
                                (propiedades.tipo == "Imagen")
                                &&
                                (
                                    <>
                                        <FormControlLabel className={fileManagerStyle.typeImagenButton} style={{ margin: '0', height: '56px', justifyContent: 'center' }} control={<Switch
                                            checked={vinculoActivo}
                                            onChange={e => {
                                                setVinculoActivo(e.target.checked);
                                            }}
                                        />} label="Insertar un Vinculo" />
                                        <FormControl variant="standard" className={vinculoActivo ? fileManagerStyle.vinculo : ""} fullWidth style={{ height: '56px' }} disabled={vinculoActivo ? false : true}>
                                            <Input
                                                style={{ height: '56px', color: '#666', fontSize: '1.5rem' }}
                                                id="inputVinculo"
                                                value={propiedades.redirect}
                                                inputProps={{
                                                    placeholder: 'Vinculo'
                                                }}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <LinkIcon style={{ margin: '0 10px' }} />
                                                    </InputAdornment>
                                                }
                                                onChange={
                                                    e => {
                                                        setPropiedades({ ...propiedades, redirect: e.target.value })
                                                    }
                                                }
                                                onBlur={
                                                    e => {
                                                        if (e.target.value.match(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi)) {
                                                            setPropiedades({ ...propiedades, redirect: e.target.value })
                                                        } else {
                                                            showAlert({
                                                                message: "Vinculo proporcionado no valido.",
                                                                severity: "error",
                                                            });
                                                            setPropiedades({ ...propiedades, redirect: "" })
                                                            e.target.value = "";
                                                        }
                                                    }
                                                }
                                            />
                                        </FormControl>
                                    </>
                                )
                            }
                            {
                                propiedades.tipo != "" && (
                                    <Button
                                        component="span"
                                        className={fileManagerStyle.typeImagenButton}
                                        style={{ borderTopRightRadius: '5px !important' }}
                                        onClick={
                                            e => {
                                                setCarga(true);
                                                if (propiedades.tipo == "Imagen") {
                                                    showAlert({
                                                        message: "Espera un momento.....",
                                                        severity: "warning",
                                                    });
                                                    if (propiedades.file != "") {
                                                        const ranm = Math.floor(Math.random() * 10000);
                                                        const ranm2 = Math.floor(Math.random() * 10000);
                                                        const pathFirebase = "Spamer/" + propiedades.tipo + "_" + ranm + ranm2;
                                                        getFileUpload(pathFirebase, propiedades.file).then(url => {
                                                            setPropiedades({
                                                                ...propiedades,
                                                                file: url,
                                                                agregar: true,
                                                                nombre: propiedades.tipo + "_" + ranm + ranm2,
                                                            });
                                                        });
                                                    }
                                                } else if (propiedades.tipo == "Video") {
                                                    showAlert({
                                                        message: "Espera un momento el video puede tardar en subirse.....",
                                                        severity: "warning",
                                                    });
                                                    if (propiedades.file != "") {
                                                        const ranm = Math.floor(Math.random() * 10000);
                                                        const ranm2 = Math.floor(Math.random() * 10000);
                                                        const pathFirebase = "Spamer/" + propiedades.tipo + "_" + ranm + ranm2;
                                                        getFileUpload(pathFirebase, propiedades.file).then(url => {
                                                            setPropiedades({
                                                                ...propiedades,
                                                                redirect: url,
                                                                file: "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Campanas%2Fvideoimg.png?alt=media&token=73695bd4-a359-49c6-8acf-a9bbd6dd7287",
                                                                agregar: true,
                                                                nombre: propiedades.tipo + "_" + ranm + ranm2,
                                                            });
                                                        });
                                                    }
                                                } else if (propiedades.tipo == "Pdf") {
                                                    showAlert({
                                                        message: "Espera un momento.....",
                                                        severity: "warning",
                                                    });
                                                    if (propiedades.file != "") {
                                                        const ranm = Math.floor(Math.random() * 10000);
                                                        const ranm2 = Math.floor(Math.random() * 10000);
                                                        const pathFirebase = "Spamer/" + propiedades.tipo + "_" + ranm + ranm2;
                                                        getFileUpload(pathFirebase, propiedades.file).then(url => {
                                                            setPropiedades({
                                                                ...propiedades,
                                                                redirect: url,
                                                                file: "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Campanas%2Fdefaultpdf.png?alt=media&token=7e79fc7c-aec2-41ce-aa0c-e1f9c6e11745",
                                                                agregar: true,
                                                                nombre: propiedades.tipo + "_" + ranm + ranm2,
                                                            });
                                                        });
                                                    }
                                                }
                                            }
                                        }
                                    >
                                        <AddIcon />
                                        Agregar {(propiedades.tipo == "Imagen" && "Imagen")}{(propiedades.tipo == "Video" && "Video")}{(propiedades.tipo == "Pdf" && "PDF")}
                                    </Button>
                                )
                            }
                        </>
                        :
                        <Grid container spacing={2} style={{ marginTop: '20px' }}>
                            <br />
                            <CircularProgress style={{ margin: 'auto' }} />
                        </Grid>
                }
            </Grid>
        </Grid>
    );
}

export default FileManager;