const obtenerErrorFirebase = (error) => {
    let message = '';
    switch (error) {
        case 'There is no user record corresponding to this identifier. The user may have been deleted.':
            message = 'No hay ningún registro de usuario que corresponda a estas credenciales.';
            break;

        case 'The email address is badly formatted.':
            message = 'La dirección de correo electrónico está mal formateada.';
            break;

        case 'The password is invalid or the user does not have a password.':
            message = 'La contraseña no es válida o el usuario no tiene contraseña.';
            break;
        
        case 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.':
            message = 'El acceso a esta cuenta se ha deshabilitado temporalmente. Puede intentarlo de nuevo más tarde.';
            break;
        
        default:
            break;
    }
    return message;
};

export { obtenerErrorFirebase };