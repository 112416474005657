import React from "react";
import {
  Typography,
} from '@mui/material';

const Index = () => {
  
  return (
    <div>
      <Typography
        variant="h4"
        gutterBottom
      >
        FACTURAS
      </Typography>
    </div>
  )
};
export default Index;