import React, { Fragment, useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import ReplayIcon from '@mui/icons-material/Replay';
import { IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";

export const DigitalSignature = ({ firma, setFirma, setFirmar, setFullScreen, ...props }) => {
  const signatureRef = useRef({});
  const canvasRef = useRef(null);
  const [error, setError] = useState(false);
  const saveSignature = (signature) => {
    setFirma(signature);
  };

  const setFullScreenMode = refe => {
    refe.requestFullscreen()
      .then(function () {
        // element has entered fullscreen mode successfully
      })
      .catch(function (error) {
        // element could not enter fullscreen mode
        // error message
        console.log(error.message);
      });
  }

  useEffect(() => {
    setFullScreenMode(canvasRef.current)
  }, [canvasRef])

  return (
    <div ref={canvasRef} style={{backgroundColor: "white"}}>
      <p style={{ color: "#666", padding: "10px", position: "fixed", top: "0", left: "0", right: "0", zIndex: "9999" }}>
      Consentimiento informado del Aspirante o Trabajador: autorizo al doctor(a) abajo mencionado a realizar mi examen médico ocupacional registrado en este documento. Ademas, autorizo la realización de toma de muestra sanguinea, recolección de muestra de orina y la realización de los analisis clínicos que se me soliciten (Antidoping, prueba presuntiva de VIH, etc).  El doctor(a) abajo mencionado me ha explicado la naturaleza y propósito del examen y analisis; He comprendido y he tenido la oportunidad de analizar el propósito, los beneficios, la interpretación, las limitaciones, y riesgos de los exámenes a realizar a partir de la asesoría brindada. Entiendo que la realización de estos exámenes es voluntaria y que tuve la oportunidad de retirar mi consentimiento en cualquier momento. Fui informado de las medidas para proteger la confidencialidad de mis resultados. 
Las respuestas dadas por mí en este examen son completas y verídicas. Autorizo al doctor(a) para que suministre a las personas o entidades contempladas en la legislación vigente, la información en este documento, para el buen cumplimiento del sistema de seguridad y salud en el trabajo y para las situaciones contempladas en la misma legislación, igualmente para que remitan la Historia Clínica a la Empresa la cual me encuentro actualmente afiliado. Finalmente, manifiesto que he leído y comprendido perfectamente lo anterior y que todos los espacios en blancos han sido completados entes de mi firma y que me encuentro en capacidad de expresar mi consentimiento.
      </p>
      <SignatureCanvas
        canvasProps={{
          style: { background: "transparent", position: "fixed", top: "0", left: "0", right: "0", bottom: "0", height: "-webkit-fill-available", width: "-webkit-fill-available", zIndex: "10000" },
        }}
        penColor="blue"
        ref={signatureRef}
        onEnd={() => saveSignature(signatureRef.current.getTrimmedCanvas())}
        onBegin={() => {
          setError(false);
        }}
      />
      <LoadingButton
        variant="contained"
        onClick={() => setFirmar(false)}
        size="medium"
        startIcon={<SaveIcon />}
        style={{ color: "#fff", position: "fixed", bottom: "0", left: "0", right: "0", zIndex: "10001" }}
      >
        Completado
      </LoadingButton>

      <IconButton
        sx={{ height: "40px" }}
        onClick={() => {
          signatureRef.current.clear();
          saveSignature(null);
        }}
      >
        <ReplayIcon />
      </IconButton>
      <pre>{error ? <div>La firma es obligatoria</div> : false}</pre>
    </div >
  );
};
