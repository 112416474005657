import React from 'react'
import { Page, Document, Text, StyleSheet, Image, pdf, View } from '@react-pdf/renderer'
import { useEffect, useState, useRef } from "react";
import { borderLeft } from '@mui/system'
import EvaluacionesClinica from '../views/evaluacionMedica/clinicaNuevaVida'

const styles = StyleSheet.create({
    page: {
        display: "flex",
        flexDirection: 'column',
        backgroundColor: '#FCFEFF',
        padding: "20px",
    },
    view: {
        width: "100%",
    },
    header: {
        backgroundColor: "#006666",
        color: "#FFF",
        width: "100%",
        padding: "5px",
        textAlign: "center",
    },
    rows: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
    },
    check: {
        width: "100%",
    },
    facturaTitle: {
        width: "50%",
        textAlign: "center",
    },
    facturaContent: {
        width: "80%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    si: {
        width: "20px",
        height: "20px",
        backgroundColor: "whitesmoke",
        padding: "5px",
    },
    no: {
        width: "20px",
        height: "20px",
        backgroundColor: "whitesmoke",
        padding: "5px",
    },
    factura: {
        width: "50%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    letter10: {
        fontSize: "10px"
    },
})

const Clinica = ({ data, ...props }) => {
    const [check, setCheck] = useState(process.env.PUBLIC_URL + '/img/check.png');

    return (
        <Document>
            {/*render a single page*/}
            <Page size="A4" style={styles.page}>
                <View style={styles.view}>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <div style={styles.header}>
                            <Text style={{ fontSize: "16px" }}>HISTORIAL</Text>
                        </div>
                        <div>
                            <Text style={{ fontSize: "14px", backgroundColor: "#F2F2F2", display: "flex", textAlign: "center" }}>FICHA DE IDENTIFICACION</Text>
                        </div>
                        <div style={styles.rows}>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "11px" }}>Identificacion oficial</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>INE</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "11.5px" }}>Número de identificacion</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.ine}</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Fecha examen</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.fechaSolicitud}</Text>
                            </div>
                        </div>
                        <div style={styles.rows}>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Empresa</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detalleConvenio?.nombreConvenio}</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Solicitante</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.solicitudRH}</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Folio</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.idOrdenServicio}</Text>
                            </div>
                        </div>
                        <div style={styles.rows}>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Nombre paciente</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.nombreCompleto}</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Fecha de nacimiento</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.fechaNacimiento}</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Edad</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.edad}</Text>
                            </div>
                        </div>
                        <div style={styles.rows}>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Sexo</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.genero}</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Estado civil</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.estadoCivil}</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Tipo de sangre</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.tipoSangre}</Text>
                            </div>
                        </div>
                        <div style={styles.rows}>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Puesto de trabajo</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.puesto}</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Proyecto</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.proyecto}</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Escolaridad</Text>
                            </div>
                            <div style={{
                                width: "16.66%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.nivelEducativo}</Text>
                            </div>
                        </div>
                        <div style={styles.rows}>
                            <div style={{
                                width: "25%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Domicilio</Text>
                            </div>
                            <div style={{
                                width: "25%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.direccion}</Text>
                            </div>
                            <div style={{
                                width: "25%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Telefono</Text>
                            </div>
                            <div style={{
                                width: "25%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.telefono}</Text>
                            </div>
                        </div>
                    </div>
                </View>
                {console.log(data?.laboratorios?.comentario)}
                {data?.laboratorios &&
                    data?.laboratorios?.comentario &&
                    <View style={styles.view}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={styles.header}>
                                <Text style={{ fontSize: "16px" }}>COMENTARIO LABORATORIO</Text>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "100%",
                                    padding: "5px",
                                    backgroundColor: "#F2F2F2",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "justify",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.laboratorios?.comentario}</Text>
                                </div>
                            </div>
                        </div>
                    </View>
                }
                {data?.signos &&
                    <View style={styles.view}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={styles.header}>
                                <Text style={{ fontSize: "16px" }}>SIGNOS VITALES</Text>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "25%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Presion Arterial</Text>
                                </div>
                                <div style={{
                                    width: "25%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Sistolica: {data?.signos?.sistolica} Diastolica: {data?.signos?.diastolica}</Text>
                                </div>
                                <div style={{
                                    width: "25%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>frecuencia cardiaca</Text>
                                </div>
                                <div style={{
                                    width: "25%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.signos?.frecuenciaCardiaca}</Text>
                                </div>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Frecuencia respiratoria</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.signos?.frecuenciaRespiratoria}</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Temperatura</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.signos?.temperatura}</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Saturacion de oxigeno</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.signos?.saturacionOxigeno}</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Dextrosis</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.signos?.dextrosis}</Text>
                                </div>
                            </div>
                        </div>
                    </View>
                }
                {data?.signos &&
                    <View style={styles.view}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={styles.header}>
                                <Text style={{ fontSize: "16px" }}>SOMATOMETRIA</Text>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Peso</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.signos?.peso}</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Estatura</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.signos?.estatura}</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>IMC</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.signos?.imc}</Text>
                                </div>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Perimetro de cintura</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.signos?.perimetroCintura}</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Perimetro Cadera</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.signos?.perimetroCadera}</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Indice cintura cadera</Text>
                                </div>
                                <div style={{
                                    width: "16.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.signos?.indiceCinturaCadera}</Text>
                                </div>
                            </div>
                        </div>
                    </View>
                }

                {data?.antecedentesLaborales?.length > 0 &&
                    <View style={styles.view}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={styles.header}>
                                <Text style={{ fontSize: "16px" }}>ANTECEDENTES LABORALES</Text>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "25%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Lugar de trabajo</Text>
                                </div>
                                <div style={{
                                    width: "25%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Tipo de trabajo</Text>
                                </div>
                                <div style={{
                                    width: "25%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Tiempo de trabajo</Text>
                                </div>
                                <div style={{
                                    width: "25%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Condiciones especiales</Text>
                                </div>
                            </div>
                            {
                                data?.antecedentesLaborales?.map(laboral => {
                                    return (
                                        <div style={styles.rows}>
                                            <div style={{
                                                width: "25%",
                                                padding: "5px",
                                                borderLeft: "1px solid #E3E3E3",
                                                borderBottom: "1px solid #E3E3E3",
                                                textAlign: "center",
                                            }}>
                                                <Text style={{ fontSize: "10px" }}>{laboral?.lugarTrabajo}</Text>
                                            </div>
                                            <div style={{
                                                width: "25%",
                                                padding: "5px",
                                                borderLeft: "1px solid #E3E3E3",
                                                borderBottom: "1px solid #E3E3E3",
                                                textAlign: "center",
                                            }}>
                                                <Text style={{ fontSize: "10px" }}>{laboral?.tipoTrabajo}</Text>
                                            </div>
                                            <div style={{
                                                width: "25%",
                                                padding: "5px",
                                                borderLeft: "1px solid #E3E3E3",
                                                borderBottom: "1px solid #E3E3E3",
                                                textAlign: "center",
                                            }}>
                                                <Text style={{ fontSize: "10px" }}>{laboral?.tiempoTrabajo}</Text>
                                            </div>
                                            <div style={{
                                                width: "25%",
                                                padding: "5px",
                                                borderLeft: "1px solid #E3E3E3",
                                                borderBottom: "1px solid #E3E3E3",
                                                textAlign: "center",
                                            }}>
                                                <Text style={{ fontSize: "10px" }}>{laboral?.condicionesEspeciales}</Text>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </View>
                }

                {data?.accidentes?.length > 0 &&
                    <View style={styles.view}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={styles.header}>
                                <Text style={{ fontSize: "16px" }}>ACCIDENTES LABORALES</Text>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "25%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Empresa</Text>
                                </div>
                                <div style={{
                                    width: "25%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Tipo de Lesión</Text>
                                </div>
                                <div style={{
                                    width: "25%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Año</Text>
                                </div>
                                <div style={{
                                    width: "25%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Descripción</Text>
                                </div>
                            </div>
                            {
                                data?.accidentes?.map(accidente => {
                                    return (
                                        <div style={styles.rows}>
                                            <div style={{
                                                width: "25%",
                                                padding: "5px",
                                                borderLeft: "1px solid #E3E3E3",
                                                borderBottom: "1px solid #E3E3E3",
                                                textAlign: "center",
                                            }}>
                                                <Text style={{ fontSize: "10px" }}>{accidente?.empresa}</Text>
                                            </div>
                                            <div style={{
                                                width: "25%",
                                                padding: "5px",
                                                borderLeft: "1px solid #E3E3E3",
                                                borderBottom: "1px solid #E3E3E3",
                                                textAlign: "center",
                                            }}>
                                                <Text style={{ fontSize: "10px" }}>{accidente?.tipoLesion}</Text>
                                            </div>
                                            <div style={{
                                                width: "25%",
                                                padding: "5px",
                                                borderLeft: "1px solid #E3E3E3",
                                                borderBottom: "1px solid #E3E3E3",
                                                textAlign: "center",
                                            }}>
                                                <Text style={{ fontSize: "10px" }}>{accidente?.anio}</Text>
                                            </div>
                                            <div style={{
                                                width: "25%",
                                                padding: "5px",
                                                borderLeft: "1px solid #E3E3E3",
                                                borderBottom: "1px solid #E3E3E3",
                                                textAlign: "center",
                                            }}>
                                                <Text style={{ fontSize: "10px" }}>{accidente?.descripcion}</Text>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </View>
                }

                {data?.antecedentesPersonales &&
                    <View style={styles.view}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={styles.header}>
                                <Text style={{ fontSize: "16px" }}>ANTECEDENTES HEREDOFAMILIARES</Text>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "100%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.antecedentesPersonales?.familiares}</Text>
                                </div>
                            </div>
                        </div>
                    </View>
                }

                {data?.antecedentesPersonales &&
                    <View style={styles.view}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={styles.header}>
                                <Text style={{ fontSize: "16px" }}>ANTECEDENTES PERSONALES NO PATOLÓGICOS</Text>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "100%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.antecedentesPersonales?.noPatologicos}</Text>
                                </div>
                            </div>
                        </div>
                    </View>
                }

                {data?.antecedentesPersonales &&
                    <View style={styles.view}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={styles.header}>
                                <Text style={{ fontSize: "16px" }}>ANTECEDENTES PERSONALES PATOLÓGICOS</Text>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "100%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.antecedentesPersonales?.patologicos}</Text>
                                </div>
                            </div>
                        </div>
                    </View>
                }

                {data?.antecedentesPersonales && data?.ordenServicio?.detallePaciente?.genero === "F" &&
                    <View style={styles.view}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={styles.header}>
                                <Text style={{ fontSize: "16px" }}>ANTECEDENTES GINECO OBESTÉTRICOS</Text>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "100%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.antecedentesPersonales?.ginecoObestetricos}</Text>
                                </div>
                            </div>
                        </div>
                    </View>
                }

                {data?.padecimiento &&
                    <View style={styles.view}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={styles.header}>
                                <Text style={{ fontSize: "16px" }}>PADECIMIENTO ACTUAL</Text>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "100%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.padecimiento?.padecimiento}</Text>
                                </div>
                            </div>
                        </div>
                    </View>
                }

                {data?.padecimiento &&
                    <View style={styles.view}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={styles.header}>
                                <Text style={{ fontSize: "16px" }}>PADECIMIENTO ACTUAL</Text>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "100%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.padecimiento?.padecimiento}</Text>
                                </div>
                            </div>
                        </div>
                    </View>
                }

                {(data?.interrogatorio || data?.exploracion) &&
                    <>
                        {data?.interrogatorio &&
                            <View style={styles.view}>
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}>
                                    <div style={styles.header}>
                                        <Text style={{ fontSize: "16px" }}>INTERROGATORIO POR APARATOS Y SISTEMAS</Text>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Habitus Externo</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.interrogatorio?.habitusExterno}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Sistema Autitivo</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.interrogatorio?.auditivo}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Sistema Respiratorio</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.interrogatorio?.respiratorio}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Sistema Cardiovascular</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.interrogatorio?.cardiovascular}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Sistema Digestivo</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.interrogatorio?.gastrointestinal}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Sistema Genitourinario</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.interrogatorio?.genitourinario}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Sistema Hematopoyético</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.interrogatorio?.hematopoyetico}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Sistema Endocrinológico</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.interrogatorio?.endocrinio}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Sistema Nervioso</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.interrogatorio?.nervioso}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Sistema Musculoesquelético</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.interrogatorio?.musculoesqueletico}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Piel y Tegumentos</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.interrogatorio?.pielTegumentos}</Text>
                                        </div>
                                    </div>
                                </div>
                            </View>
                        }
                        {data?.exploracion &&
                            <View style={styles.view}>
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}>
                                    <div style={styles.header}>
                                        <Text style={{ fontSize: "16px" }}>EXPLORACIÓN FÍSICA</Text>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Cabeza y cuello</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.exploracion?.cabezaCuello}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Cardiorespiratorio</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.exploracion?.cardiorespiratorio}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Abdomen</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.exploracion?.abdomen}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Extremidades Superiores</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.exploracion?.extremidadesSuperiores}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Extremidades Inferiores</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.exploracion?.extremidadesInferiores}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "20%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Columna</Text>
                                        </div>
                                        <div style={{
                                            width: "80%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>{data?.exploracion?.columna}</Text>
                                        </div>
                                    </div>
                                </div>
                            </View>
                        }
                    </>
                }

                {(data?.paraclinicos || data?.audioValores) &&
                    <>
                        {data?.paraclinicos &&
                            <View style={styles.view}>
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}>
                                    <div style={styles.header}>
                                        <Text style={{ fontSize: "12px" }}>AGUDEZA VISUAL</Text>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "25%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>Vista Corregida</Text>
                                        </div>
                                        <div style={{
                                            width: "25%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.paraclinicos?.usaLentes}</Text>
                                        </div>
                                        <div style={{
                                            width: "25%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>Daltonismo</Text>
                                        </div>
                                        <div style={{
                                            width: "25%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.paraclinicos?.daltonismo}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "25%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>Capimetria Derecha</Text>
                                        </div>
                                        <div style={{
                                            width: "25%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.paraclinicos?.campimetriaDerecha}</Text>
                                        </div>
                                        <div style={{
                                            width: "25%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>Capimetria Izquierda</Text>
                                        </div>
                                        <div style={{
                                            width: "25%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.paraclinicos?.campimetriaIzquierda}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "100%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Vista Lejana</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "16.66%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>Ambos ojos</Text>
                                        </div>
                                        <div style={{
                                            width: "16.66%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.paraclinicos?.vlao}</Text>
                                        </div>
                                        <div style={{
                                            width: "16.66%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>Ojo Derecho</Text>
                                        </div>
                                        <div style={{
                                            width: "16.66%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.paraclinicos?.vlod}</Text>
                                        </div>
                                        <div style={{
                                            width: "16.66%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>Ojo Izquierdo</Text>
                                        </div>
                                        <div style={{
                                            width: "16.66%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.paraclinicos?.vloi}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "100%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "10px" }}>Vista Cercana</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "16.66%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>Ambos ojos</Text>
                                        </div>
                                        <div style={{
                                            width: "16.66%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.paraclinicos?.vcao}</Text>
                                        </div>
                                        <div style={{
                                            width: "16.66%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>Ojos Derecho</Text>
                                        </div>
                                        <div style={{
                                            width: "16.66%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.paraclinicos?.vcod}</Text>
                                        </div>
                                        <div style={{
                                            width: "16.66%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>Ojos Izquierdo</Text>
                                        </div>
                                        <div style={{
                                            width: "16.66%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.paraclinicos?.vcoi}</Text>
                                        </div>
                                    </div>
                                </div>
                            </View>
                        }
                        {data?.audioValores &&
                            <View style={styles.view}>
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}>
                                    <div style={styles.header}>
                                        <Text style={{ fontSize: "12px" }}>AUDIOMETRIA</Text>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>Frecuencia</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>Izquierdo</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>Derecho</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>500</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.i500}</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.d500}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>1000</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.i1000}</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.d1000}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>2000</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.i2000}</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.d2000}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>3000</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.i3000}</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.d3000}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>3000</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.i3000}</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.d3000}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>4000</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.i4000}</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.d4000}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>6000</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.i6000}</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.d6000}</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>8000</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.i8000}</Text>
                                        </div>
                                        <div style={{
                                            width: "33.33%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.d8000}</Text>
                                        </div>
                                    </div>
                                    {data?.audioValores &&
                                        <div style={styles.rows}>
                                            <Image style={{ width: "100%", }} src={data?.audioValores?.urlFoto} />
                                        </div>
                                    }
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "25%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>Monoaural Izquierdo</Text>
                                        </div>
                                        <div style={{
                                            width: "25%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>Monoaural Derecho</Text>
                                        </div>
                                        <div style={{
                                            width: "25%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>HBC</Text>
                                        </div>
                                        <div style={{
                                            width: "25%",
                                            padding: "5px",
                                            backgroundColor: "#F2F2F2",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>Diagnostico audiológico</Text>
                                        </div>
                                    </div>
                                    <div style={styles.rows}>
                                        <div style={{
                                            width: "25%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.monoauralIzquierdo}</Text>
                                        </div>
                                        <div style={{
                                            width: "25%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.monoauralDerecho}</Text>
                                        </div>
                                        <div style={{
                                            width: "25%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.hbc}</Text>
                                        </div>
                                        <div style={{
                                            width: "25%",
                                            padding: "5px",
                                            borderLeft: "1px solid #E3E3E3",
                                            borderBottom: "1px solid #E3E3E3",
                                            textAlign: "center",
                                        }}>
                                            <Text style={{ fontSize: "9px" }}>{data?.audioValores?.interpretacion}</Text>
                                        </div>
                                    </div>
                                </div>
                            </View>
                        }
                    </>
                }

                {data?.estudiosGabinete &&
                    <View style={styles.view}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={styles.header}>
                                <Text style={{ fontSize: "16px" }}>EXAMENES DE GABINETE</Text>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "30%",
                                    padding: "5px",
                                    backgroundColor: "#F2F2F2",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Radiografia</Text>
                                </div>
                                <div style={{
                                    width: "70%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.estudiosGabinete?.interpretacionRadiologia}</Text>
                                </div>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "30%",
                                    padding: "5px",
                                    backgroundColor: "#F2F2F2",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Espirometria</Text>
                                </div>
                                <div style={{
                                    width: "70%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.estudiosGabinete?.espirometria}</Text>
                                </div>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "30%",
                                    padding: "5px",
                                    backgroundColor: "#F2F2F2",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Electrocardiograma</Text>
                                </div>
                                <div style={{
                                    width: "70%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.estudiosGabinete?.electrocardiograma}</Text>
                                </div>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "30%",
                                    padding: "5px",
                                    backgroundColor: "#F2F2F2",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>Laboratorio</Text>
                                </div>
                                <div style={{
                                    width: "70%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "10px" }}>{data?.laboratorios?.comentario}</Text>
                                </div>
                            </div>
                        </div>
                    </View>
                }
                <View style={styles.view}>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <div style={styles.header}>
                            <Text style={{ fontSize: "16px" }}>RESULTADO DE EVALUACIÓN OCUPACIONAL</Text>
                        </div>
                        <div style={styles.rows}>
                            <div style={{
                                width: "30%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Evaluacion Ocupacional</Text>
                            </div>
                            <div style={{
                                width: "70%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.comentarios?.evaluacion}</Text>
                            </div>
                        </div>
                        <div style={styles.rows}>
                            <div style={{
                                width: "30%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Restriccion laboral</Text>
                            </div>
                            <div style={{
                                width: "70%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.comentarios?.restricciones}</Text>
                            </div>
                        </div>
                        <div style={styles.rows}>
                            <div style={{
                                width: "30%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Recomendaciones</Text>
                            </div>
                            <div style={{
                                width: "70%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.comentarios?.recomendaciones}</Text>
                            </div>
                        </div>
                        <div style={styles.rows}>
                            <div style={{
                                width: "50%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>MÉDICO OCUPACIONAL QUE REALIZO</Text>
                            </div>
                            <div style={{
                                width: "50%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>FIRMA</Text>
                            </div>
                        </div>
                        <div style={{
                            width: "100%",
                            borderLeft: "1px solid #E3E3E3",
                            borderBottom: "1px solid #E3E3E3",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "row",
                        }}>
                            <div style={{
                                width: "50%",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                            }}>
                                <div style={styles.rows}>
                                    <div style={{
                                        width: "100%",
                                        borderLeft: "1px solid #E3E3E3",
                                        borderBottom: "1px solid #E3E3E3",
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",

                                    }}>
                                        <Text style={{ fontSize: "10px" }}>Dr(a).</Text>
                                    </div>
                                    <div style={{
                                        width: "100%",
                                        borderLeft: "1px solid #E3E3E3",
                                        borderBottom: "1px solid #E3E3E3",
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",

                                    }}>
                                        <Text style={{ fontSize: "10px" }}>{
                                            "MARCOS GONZALEZ AVILA"
                                            // data?.ordenServicio?.usuario
                                            //   ?
                                            //   data?.ordenServicio?.usuario?.nombreCompleto
                                            //   :
                                            //   data?.ordenServicio?.ordenServicioDoctores[0]?.detalleDoctores?.creador?.nombreCompleto
                                        }</Text>
                                    </div>
                                </div>
                                <div style={styles.rows}>
                                    <div style={{
                                        width: "100%",
                                        borderLeft: "1px solid #E3E3E3",
                                        borderBottom: "1px solid #E3E3E3",
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",

                                    }}>
                                        <Text style={{ fontSize: "10px" }}>Cédula Profesional</Text>
                                    </div>
                                    <div style={{
                                        width: "100%",
                                        borderLeft: "1px solid #E3E3E3",
                                        borderBottom: "1px solid #E3E3E3",
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",

                                    }}>
                                        <Text style={{ fontSize: "10px" }}>{
                                            "6639961"
                                            // data?.ordenServicio?.usuario
                                            //   ?
                                            //   data?.ordenServicio?.usuario?.detalleDoctor?.cp
                                            //   :
                                            //   data?.ordenServicio?.ordenServicioDoctores[0]?.detalleDoctores?.cp
                                        }</Text>
                                    </div>
                                </div>
                                <div style={styles.rows}>
                                    <div style={{
                                        width: "100%",
                                        borderLeft: "1px solid #E3E3E3",
                                        borderBottom: "1px solid #E3E3E3",
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",

                                    }}>
                                        <Text style={{ fontSize: "10px" }}>SSA:</Text>
                                    </div>
                                    <div style={{
                                        width: "100%",
                                        borderLeft: "1px solid #E3E3E3",
                                        borderBottom: "1px solid #E3E3E3",
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",

                                    }}>
                                        <Text style={{ fontSize: "10px" }}>{
                                            "8110/10"
                                            // data?.ordenServicio?.usuario
                                            //   ?
                                            //   data?.ordenServicio?.usuario?.detalleDoctor?.cp
                                            //   :
                                            //   data?.ordenServicio?.ordenServicioDoctores[0]?.detalleDoctores?.cp
                                        }</Text>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                width: "50%",
                                height: "60px",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Image style={{ fontSize: "10px" }} src={
                                    "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/DoctoresDetalles%2FImagen_roberto%20adrian%20galindo%20luzanilla217?alt=media&token=fea3f332-2b5f-4be9-b95f-53f6fd596ef9"
                                    // data?.ordenServicio?.usuario
                                    //   ?
                                    //   data?.ordenServicio?.usuario?.detalleDoctor?.urlFile
                                    //   :
                                    //   data?.ordenServicio?.ordenServicioDoctores[0]?.detalleDoctores?.urlFile
                                } />
                            </div>
                        </div>
                    </div>
                </View>
            </Page>
        </Document >
    )
}

const blobToExport = async (data) => {
    return await pdf(<Clinica data={data} />).toBlob()
}

export default blobToExport