import { useEffect, useState, useRef } from 'react';
import { useHistory, NavLink } from "react-router-dom";
// <========================= COMPONENTES =============================>
import {
    Grid,
    Typography,
    TextField,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    Table,
    CircularProgress,
    TableBody,
    TableRow,
    TableCell,
    Switch,
    IconButton,
    Tooltip,
    TableHead,
} from "@mui/material";
import DatePicker from "../../components/DatePickerBeta";
import { useFileManager } from '../../hooks/useFileManager';
import LoadingButton from '@mui/lab/LoadingButton';
import { usePost } from "../../hooks/usePost";
import { useGet } from "../../hooks/useGet";
import { useLocalStorage } from "./../../hooks/useLocalStorage";
import moment from "moment-timezone";
import { useAlert } from "./../../hooks/useAlert";
import Agregar from "./agregar";
import Swal from "sweetalert2";
import { useAuth } from "../../hooks/useAuth";
import { baseUrl } from "../../utils/variables";
// <========================= STYLES =============================>
import {
    campanaStyle,
    tableCellStyles,
    baseStyles,
    tableRowStyles,
    tablaPrimaryStyle,
} from "../../utils";
// <========================= ICONOS =============================>
import { MdPictureAsPdf } from 'react-icons/md';
import { RiFileExcel2Fill } from 'react-icons/ri';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { Delete, Edit } from "@mui/icons-material";
import {
    Search,
  } from "@mui/icons-material";


const useCampanaStyle = campanaStyle();
const useStyles = baseStyles();
const useTablaPrimaryStyle = tablaPrimaryStyle();
const Listado = ({ match }) => {
    const campanaStyle = useCampanaStyle();
    const tablaPrimaryStyle = useTablaPrimaryStyle();

    const classes = useStyles();
    const { showAlert } = useAlert();
    const [errores, cargandoPost, peticion, setCargando, detalles] = usePost();
    const { permiso } = useAuth();
    const { files, agregarFiles, clearFiles, activeTab, agregarActiveTab } = useFileManager();
    const switchEliminado = useRef();
    const [tipoCargando, setTipoCargando] = useState(1);
    const history = useHistory();
    const [servSuc, setServSuc] = useState([]);
    const TableCellModify = tableCellStyles()(TableCell);
    const TableRowModify = tableRowStyles()(TableRow);
    const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
    const [admin] = useLocalStorage("admin", null);
    const [campos, setCampos] = useState({
        fechaInicio: moment().startOf("month").format("YYYY-MM-DDTHH:mm:ss"),
        fechaFinal: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        empresa: '',
        periodo: '',
        tag: '',
        eliminado: false,
    });
    const paramsGet = {
        pagina: 1,
        idSucursal: idSucursal
    };
    const buscar = (eliminado = null) => {
        setTipoCargando(2);
        let obj = { ...paramsGet }
        const dateVal = moment(campos.fechaInicio).isAfter(campos.fechaFinal);
        if (dateVal) {
            return showAlert({ message: 'La fecha final no puede ser menor a la de inicio', severity: 'warning' });
        }
        if (eliminado != null) {
            if (eliminado) obj = { ...obj, eliminado: eliminado }
        } else {
            if (campos.eliminado) obj = { ...obj, eliminado: campos.eliminado }
        }
        if (campos.fechaInicio) obj = { ...obj, fechaInicio: campos.fechaInicio }
        if (campos.fechaFinal) obj = { ...obj, fechaFinal: campos.fechaFinal }
        if (campos.tag) obj = { ...obj, tag: campos.tag }
        if (campos.empresa == "Todas") {
            if (campos.empresa) obj = { ...obj, empresa: "" }
        } else {
            if (campos.empresa) obj = { ...obj, empresa: campos.empresa }
        }
        if (campos.periodo == "Todos") {
            if (campos.periodo) obj = { ...obj, periodo: "" }
        } else {
            if (campos.periodo) obj = { ...obj, periodo: campos.periodo }
        }
        actualizar({
            urlUpdate: "campana",
            properties: obj,
            loading: true,
        });
    }

    useEffect(() => {
        if (detalles?.estatus === 200) {
            setTipoCargando(2);
            buscar();
        }
    }, [detalles])
    const deshabilitar = async idCampana => {
        if (idCampana) {
            const res = await Swal.fire({
                title: '¿Deseas deshabilitar esta Campaña?',
                text: "Favor de corroborar sus datos antes de continuar.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Deshabilitar!',
                cancelButtonText: 'Cancelar'
            });

            if (res.isConfirmed) {
                showAlert({
                    message: "Espera un momento.....",
                    severity: "warning",
                });
                peticion("campana/eliminar", { idCampana: idCampana }, "campanas", true, false, "DELETE");
            }
        }
    }

    const habilitar = async idCampana => {
        if (idCampana) {
            const res = await Swal.fire({
                title: '¿Deseas habilitar esta Campaña?',
                text: "Favor de corroborar sus datos antes de continuar.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Habilitar!',
                cancelButtonText: 'Cancelar'
            });

            if (res.isConfirmed) {
                showAlert({
                    message: "Espera un momento.....",
                    severity: "warning",
                });
                peticion("campana/habilitar", { idCampana: idCampana }, "campanas", true, false, "DELETE");
            }
        }
    }
    const [sucursales] = useGet({
        initUrl: 'sucursal',
        params: { pagina: 1, ordenar: "nombreSucursal.asc" },
        action: true,
    });
    const getFilterArraySucursales = array => {
        return array.map((e) => {
            return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, active: false }
        })
    }
    const [campanas, cargando, actualizar] = useGet({
        initUrl: "campana",
        params: paramsGet,
        action: true,
    });
    useEffect(() => {
        if (sucursales.resultado) {
            const newObj = getFilterArraySucursales(sucursales.resultado);
            setServSuc(newObj);
        }
    }, [sucursales.resultado])
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}><Typography className={campanaStyle.titulo} variant="p">CAMPAÑAS</Typography></Grid>
            {
                admin &&
                (
                    <>
                        <Grid item xs={12} sm={9} md={3} xl={3}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="demo-simple-select-autowidth-label">Sucursal</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    label="Sucursales"
                                    value={idSucursal}
                                    onChange={(e) => {
                                        setIdSucursal(e.target.value)
                                    }
                                    }
                                >
                                    <MenuItem value="">
                                        Todas
                                    </MenuItem>
                                    {servSuc && servSuc.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.idSucursal} >{item.nombreSucursal}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={4}></Grid>
                        <Grid xs={12} md={4}></Grid>
                    </>
                )
            }
            <Grid item xs={12} md={4}>
                <DatePicker
                    labelText="Fecha Inicio"
                    variant="standard"
                    fullWidth
                    inputFormat="dd/MM/yyyy"
                    value={campos.fechaInicio}
                    size="medium"
                    onChange={(e) => setCampos(prevState => ({
                        ...prevState,
                        ...prevState.campos,
                        fechaInicio: moment(e).format("YYYY-MM-DD H:m:s")
                    }))
                    }
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <DatePicker
                    labelText="Fecha Final"
                    variant="standard"
                    fullWidth
                    inputFormat="dd/MM/yyyy"
                    value={campos.fechaFinal}
                    size="medium"
                    onChange={(e) => setCampos(prevState => ({
                        ...prevState,
                        ...prevState.campos,
                        fechaFinal: moment(e).format("YYYY-MM-DD H:m:s")
                    }))
                    }
                />
            </Grid>
            <Grid xs={12} md={4}></Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    label="Etiqueta"
                    variant="standard"
                    fullWidth
                    size="medium"
                    onChange={(e) => setCampos(prevState => ({
                        ...prevState,
                        ...prevState.campos,
                        tag: e.target.value
                    }))
                    }
                    inputProps={{
                        value: campos.tag,
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="standard">
                    <InputLabel id="empresa">Empresa</InputLabel>
                    <Select
                        labelId="empresa"
                        label="Empresa"
                        value={campos.empresa}
                        onChange={
                            e => {
                                setCampos(prevState => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    empresa: e.target.value
                                }));
                            }
                        }
                    >
                        <MenuItem value={"Todas"}>Todas</MenuItem>
                        <MenuItem value={"Bylsa Drilling"}>Bylsa Drilling</MenuItem>
                        <MenuItem value={"Clinica Nueva Vida"}>Clinica Nueva Vida</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="standard">
                    <InputLabel id="periodo">Periodo</InputLabel>
                    <Select
                        labelId="periodo"
                        value={campos.periodo}
                        label="Periodo"
                        onChange={
                            e => {
                                setCampos(prevState => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    periodo: e.target.value
                                }))

                            }
                        }
                    >
                        <MenuItem value={"Todos"}>Todos</MenuItem>
                        <MenuItem value={"Minuto"}>Minuto</MenuItem>
                        <MenuItem value={"5 Minutos"}>5 Minutos</MenuItem>
                        <MenuItem value={"dia"}>Dia</MenuItem>
                        <MenuItem value={"semana"}>Semana</MenuItem>
                        <MenuItem value={"mes"}>Mes</MenuItem>
                        <MenuItem value={"10mayo"}>10 de Mayo</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
                <LoadingButton
                    loading={(cargando && tipoCargando === 2) && true}
                    variant="contained"
                    onClick={() => buscar()}
                    size="large"
                    style={{
                        color: "#fff"
                    }}
                    startIcon={<Search />}
                >
                    BUSCAR
                </LoadingButton>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid item xs={6}>
                        <Switch
                            ref={switchEliminado}
                            disabled={(cargando && tipoCargando === 2) && true}
                            onChange={(e) => {
                                setCampos(prevState => ({
                                    ...prevState,
                                    ...prevState.campos,
                                    eliminado: e.target.checked
                                }));
                                buscar(e.target.checked);
                            }
                            }
                        />
                        Ver Deshabilitados
                    </Grid>
                    <Grid item xs={4} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Grid item xs={2}>
                            <IconButton color="error" aria-label="" component="span">
                                <a className={classes.btnPdf} target="_blank" rel="noreferrer" href={
                                     `${baseUrl}pdf/campanas?${"admin=" + (admin?1:0)}${"&fechaInicio=" + campos.fechaInicio}${"&fechaFinal=" + campos.fechaFinal}${"&etiqueta=" + (campos.etiqueta !== undefined ? campos.etiqueta : "")}${"&empresa=" + campos.empresa}${"&periodo=" + campos.periodo} ${"&eliminado=" + (campos.eliminado ? 1 : 0)}`
                                }>
                                    <MdPictureAsPdf />
                                </a>
                            </IconButton>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton color="warning" aria-label="" component="span">
                                <a className={classes.btnExcel} target="_blank" rel="noreferrer" href={
                                    `${baseUrl}excel/campanas?${"admin=" + (admin?1:0)}${"&fechaInicio=" + campos.fechaInicio}${"&fechaFinal=" + campos.fechaFinal}${"&etiqueta=" + (campos.etiqueta !== undefined ? campos.etiqueta : "")}${"&empresa=" + campos.empresa}${"&periodo=" + campos.periodo}${"&eliminado=" + (campos.eliminado ? 1 : 0)}`
                                }>
                                    <RiFileExcel2Fill />
                                </a>
                            </IconButton>
                        </Grid>
                        </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '20px' }}>
                <Grid item xs={12}>
                    {
                        cargando
                            ?
                            <>
                                <Grid container spacing={2} style={{ marginTop: '20px' }}>
                                    <br />
                                    <CircularProgress style={{ margin: 'auto' }} />
                                </Grid>
                            </>
                            :
                            <Table className={tablaPrimaryStyle.TablePrimary}>
                                <TableHead>
                                    <TableRowModify>
                                        <TableCellModify>
                                            #
                                        </TableCellModify>
                                        <TableCellModify>
                                            Fecha
                                        </TableCellModify>
                                        <TableCellModify>
                                            Etiqueta
                                        </TableCellModify>
                                        <TableCellModify>
                                            Empresa
                                        </TableCellModify>
                                        <TableCellModify>
                                            Periodo
                                        </TableCellModify>
                                        <TableCellModify>
                                            Titulo
                                        </TableCellModify>
                                        <TableCellModify>
                                            Sujeto
                                        </TableCellModify>
                                        <TableCellModify>
                                            Acciones
                                        </TableCellModify>
                                    </TableRowModify>
                                </TableHead>
                                <TableBody>
                                    {
                                        campanas?.resultado && campanas?.resultado.map((campana, i) => {
                                            return (
                                                <TableRowModify>
                                                    <TableCellModify>
                                                        {i + 1}
                                                    </TableCellModify>
                                                    <TableCellModify>
                                                        {moment(campana.creado).format("YYYY/MM/DDTHH")}
                                                    </TableCellModify>
                                                    <TableCellModify>
                                                        {campana.etiqueta}
                                                    </TableCellModify>
                                                    <TableCellModify>
                                                        {campana.empresa}
                                                    </TableCellModify>
                                                    <TableCellModify style={{fontWeight:'600'}}>
                                                        {campana.periodo.toUpperCase()}
                                                    </TableCellModify>
                                                    <TableCellModify>
                                                        {campana.titulo}
                                                    </TableCellModify>
                                                    <TableCellModify>
                                                        {campana.sujeto}/{campana.de}
                                                    </TableCellModify>
                                                    <TableCellModify>
                                                        {
                                                            (admin === true || (permiso && permiso[0]["campanas"] & 4) === 4) && (
                                                                (campana.eliminado == null) && (
                                                                    <NavLink to={(`campanas/editar/${campana.idCampana}`)} exact>
                                                                        <Tooltip title="Editar">
                                                                            <IconButton
                                                                                aria-label="editar"
                                                                                onClick={() => {
                                                                                    agregarActiveTab({
                                                                                        title: "Editar",
                                                                                        content: <Agregar idCampana={campana.idCampana} />,
                                                                                        icon: <Edit />
                                                                                    });
                                                                                    history.push(`campanas/editar/${campana.idCampana}`);
                                                                                }
                                                                                }
                                                                            >
                                                                                <Edit />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </NavLink>
                                                                )
                                                            )
                                                        }
                                                        {
                                                            (admin === true || (permiso && permiso[0]["campanas"] & 8) === 8) && (
                                                                <>
                                                                    {(campana.eliminado == null) ?
                                                                        <Tooltip title="Deshabilitar">
                                                                            <IconButton
                                                                                aria-label="eliminar"
                                                                                onClick={() => deshabilitar(campana.idCampana)}
                                                                            >
                                                                                <Delete />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        :
                                                                        <Tooltip title="Habilitar">
                                                                            <IconButton
                                                                                aria-label="eliminar"
                                                                                onClick={() => habilitar(campana.idCampana)}
                                                                            >
                                                                                <RestoreFromTrashIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </TableCellModify>
                                                </TableRowModify>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Listado;