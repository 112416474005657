////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useModels } from "../../hooks/useModels";
import { NavLink } from "react-router-dom";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import {
  Grid,
  MenuItem,
  Popover,
  Switch,
  FormControl,
  InputLabel,
  Select,
  Menu,
  FormControlLabel,
} from "@mui/material";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import Swal from "sweetalert2";
import { internationalCurrency } from '../../utils/index';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { baseStyles, capitalizeFirst } from "../../utils";
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import IconButton from "@mui/material/IconButton";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import { Add, Delete, Edit, Search } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import AssignmentIcon from '@mui/icons-material/Assignment';
import VisibilityIcon from '@mui/icons-material/Visibility';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
import { baseUrl } from "../../utils/variables";
const useStyles = baseStyles();

const TipoEvaluacion = () => {
  ////////////////////////////////////////////////////////////////////////
  //                              Vars                                  //
  ////////////////////////////////////////////////////////////////////////
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const [openMenuRow, setOpenMenuRow] = useState(null);
  const history = useHistory();
  const [perPage, setPerPage] = useState(10);
  const [pagina, setPagina] = useState(1);
  const [admin] = useLocalStorage("admin", null);
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const { user, permiso } = useAuth();
  const [propiedades, setPropiedades] = useState({
    evaluacion: "",
    tipoConcepto: "",
  });
  const parametrosInicialesMemo = useMemo(
    () => ({
      name: "tipo-evaluacion",
      expand: "tipoEvaluacionServicio.servicio, paquete.paqueteProductoInventario.productoInventario",
      extraParams: {
        idSucursal: idSucursal
      }
    }),
    []
  );
  const parametrosSucursales = useMemo(
    () => ({
      name: "sucursal",
    }),
    []
  );

  const [models, modelsLoading, modelsError, modelsPage, refreshModels, deleteModel] = useModels({ ...parametrosInicialesMemo });

  const [ modelsSucursal, modelsLoadingSucursal, modelsErrorSucursal, modelsPageSucursal, refreshModelsSucursal, deleteModelSucursal, ] = useModels({ ...parametrosSucursales });
  ////////////////////////////////////////////////////////////////////////
  //                           Funcions Def                             //
  ////////////////////////////////////////////////////////////////////////
  const onSearchClicked = async eliminado => {
    let params = {};
    if (eliminado != null) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
      };
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage },
      };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async page => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async per => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  const onDeleteClicked = async (row, type) => {
    try {
      setOpenMenuRow(false);
      await Swal.fire({
        title: type,
        text: `¿Desea ${type} el dato seleccionado  "${capitalizeFirst(
          row.evaluacion.toLowerCase()
        )}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34AA44",
        cancelButtonColor: "#E72C33",
        confirmButtonText: "Si, deseo Continuar.",
      }).then((result) => {
        if (result.isConfirmed) {
          let params = {};
          params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: pagina, limite: perPage },
          };
          deleteModel(row.idTipoEvaluacion, type, params);
        }

      });
    } catch (error) {
      console.log(error);
    }
  };

  const getVerMas = row => {
    const paq = row.paquete;
    return (
      <PopupState variant="popover" popupId="demo-popup-popover" >
        {(popupState) => (
          <>
            <IconButton variant="contained" {...bindTrigger(popupState)}>
              <VisibilityIcon />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div style={{ whiteSpace: 'nowrap', background: '#1D5E7B ', padding: '5px 20px', color: '#FFF', textAlign: 'center', width: '100%' }}>Servicios</div>
                  {row.tipoEvaluacionServicio.map((serv, i) => {
                    return (
                      <div key={serv.idServicio} style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div style={{ width: '100%' }}>
                          <div style={{ whiteSpace: 'nowrap', background: '#1D5E7B ', padding: '5px 20px', color: '#FFF', textAlign: 'center' }}>Nombre</div>
                          <div style={{whiteSpace: 'nowrap', color: "#666", textAlign: 'center' }}>{serv?.servicio?.concepto}</div>
                        </div>
                        <div style={{ width: '100%' }}>
                          <div style={{ whiteSpace: 'nowrap', background: '#1D5E7B ', padding: '5px 20px', color: '#FFF', textAlign: 'center' }}>Precio</div>
                          <div style={{ whiteSpace: 'nowrap', color: "#666", textAlign: 'center' }}>{internationalCurrency(serv?.servicio?.valorUIva)}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div style={{ whiteSpace: 'nowrap', background: '#1D5E7B ', padding: '5px 20px', color: '#FFF', textAlign: 'center', width: '100%' }}>Productos</div>
                  {paq && paq.paqueteProductoInventario.map((pa, i) => {
                    return (
                      <>
                        <div key={pa.productoInventario.idProductoInventario} style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                          <div style={{ width: '100%' }}>
                            <div style={{ whiteSpace: 'nowrap', background: '#1D5E7B ', padding: '5px 20px', color: '#FFF', textAlign: 'center' }}>Nombre</div>
                            <div style={{ whiteSpace: 'nowrap', color: "#666", whiteSpace: 'nowrap', textAlign: 'center' }}>{pa.productoInventario.nombreProducto}</div>
                          </div>
                          <div style={{ width: '100%' }}>
                            <div style={{ whiteSpace: 'nowrap', background: '#1D5E7B ', padding: '5px 20px', color: '#FFF', textAlign: 'center' }}>Precio</div>
                            <div style={{ whiteSpace: 'nowrap' ,color: "#666", whiteSpace: 'nowrap', textAlign: 'center' }}>{internationalCurrency(pa.productoInventario.precioPublico)}</div>
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
            </Popover>
          </>
        )}
      </PopupState>
    );
  }
  ////////////////////////////////////////////////////////////////////////
  //                            Hooks Def                               //
  ////////////////////////////////////////////////////////////////////////
  return (
    <>
      <TablePageLayout
        title="Tipo Evaluación"
        model="tipo-evaluacion"
        history={history}
        actionButton={{
          to: "/evaluacion/nuevo",
          icon: <Add />,
          label: "Agregar",
          permiso: "tipoEvaluaciones"
        }}
        links={[
          {
            label: "Tipo Evaluaciones",
            to: "/evaluaciones",
            icon: <AssignmentIcon fontSize="small" />
          },
        ]}
        SearchComponents={
          <>
            {(admin === true) && (
              <>
                <Grid item xs={12} sm={4}>
                  <FormControl size='small' fullWidth >
                    <InputLabel id="demo-simple-select-autowidth-label">Sucursal</InputLabel>
                    <Select
                      fullWidth
                      variant="standard"
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      label="Sucursales"
                      value={propiedades.idSucursal}
                      onChange={(e) => setPropiedades(prevState => ({
                        ...prevState,
                        ...prevState.propiedades,
                        idSucursal: e.target.value
                      }))}
                    >
                      <MenuItem value="">Todas</MenuItem>
                      {modelsSucursal && modelsSucursal.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.idSucursal} >{item.nombreSucursal}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}></Grid>
              </>
            )
            }
            <Grid item xs={12} sm={9} md={5}>
              <InputRegex
                label="Evaluación"
                variant="standard"
                fullWidth
                maxLength={100}
                size="medium"
                typeInput='text'
                value={propiedades.evaluacion}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    evaluacion: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={9} md={5}>
              <InputRegex
                label="Tipo Concepto"
                variant="standard"
                fullWidth
                size="medium"
                maxLength={100}
                typeInput='text'
                value={propiedades.tipoConcepto}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    tipoConcepto: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={9} md={2}>
              <LoadingButton
                variant="contained"
                fullWidth
                onClick={() => onSearchClicked(null)}
                loading={modelsLoading && true}
                style={{
                  float: "right",
                  color: "whitesmoke",
                }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>
          </>
        }
        DisableComponents={
          <>
            <Grid item xs={8}>
              <FormControlLabel
                className={classes.switchColor}
                control={
                  <Switch
                    disabled={modelsLoading && true}
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        eliminado: e.target.checked ? 1 : 0,
                      }));
                      onSearchClicked(e.target.checked ? 1 : 0);
                    }}
                  />
                }
                label="Ver Desabilitados"
              />
            </Grid>
            <Grid item xs={4} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", }}>
              <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                <IconButton color="error" aria-label="upload picture" component="span">
                  <a className={classes.btnPdf} target="_blank" rel="noreferrer" href={`${baseUrl}pdf/evaluacion?${"admin=" + (admin ? 1 : 0)
                    }${"&uid=" + user?.idUsuario}${"&nombreSucursal=" + propiedades.nombreSucursal
                    }${"&evaluacion=" + propiedades.evaluacion}${"&tipoConcepto=" + propiedades.tipoConcepto
                    }${"&eliminado=" + (propiedades.eliminado ? 1 : 0)
                    }`}>
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid item xs={2} style={{ isplay: "flex", flexDirection: "row", alignItems: "center", }} >
                <IconButton color="warning" aria-label="upload picture" component="span">
                  <a className={classes.btnExcel} target="_blank" rel="noreferrer" href={`${baseUrl}excel/evaluacion?${"admin=" + (admin ? 1 : 0)
                    }${"&uid=" + user?.idUsuario}${"&nombreSucursal=" + propiedades.nombreSucursal
                    }${"&evaluacion=" + propiedades.evaluacion}${"&tipoConcepto=" + propiedades.tipoConcepto
                    }${"&eliminado=" + (propiedades.eliminado ? 1 : 0)
                    }`}>
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <TablePagination
            nombrePermiso="tipoEvaluaciones"
            permiso={permiso}
            header={[]}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "evaluacion",
                name: "Evaluación",
                cell: (row, index) => (
                  <p key={index}>{row.evaluacion}</p>
                )
              },
              {
                prop: "tipoConcepto",
                name: "Tipo Concepto",
                cell: (row, index) => (
                  <p key={index}>{row.tipoConcepto}</p>
                )
              },
              {
                prop: "vermas",
                name: "Ver Más",
                cell: (row, index) => (
                  <p key={index}>{getVerMas(row)}</p>
                )
              },
              {
                prop: "exportar",
                name: "Exportar",
                cell: (row, index) => (
                  <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Grid item xs={1} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <IconButton color="error" aria-label="upload picture" component="span">
                        <a className={classes.btnPdf} target="_blank" rel="noreferrer" href={
                          `${baseUrl}pdf/evaluacion-row?${"idTipoEvaluacion=" + row.idTipoEvaluacion}`}>
                          <MdPictureAsPdf />
                        </a>
                      </IconButton>
                      <IconButton color="warning" aria-label="upload picture" component="span">
                        <a className={classes.btnExcel} target="_blank" rel="noreferrer" href={
                          `${baseUrl}excel/evaluacion-row?${"idTipoEvaluacion=" + row.idTipoEvaluacion}`}>
                          <RiFileExcel2Fill />
                        </a>
                      </IconButton>
                    </Grid>
                  </Grid>
                )
              },
              {
                prop: "acciones",
                name: "Acciones",
                sortable: false,
                cell: (row, index) => (permiso && (permiso[0]["tipoEvaluaciones"] & 4) === 4 || (permiso[0]["tipoEvaluaciones"] & 8) === 8) && (
                  <div>
                    <IconButton onClick={(event) => { setOpenMenuRow(index); setAnchorEl(event.currentTarget) }}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu anchorEl={anchorEl} keepMounted open={index === openMenuRow} onClose={() => setOpenMenuRow(null)}>
                      {(admin === true || (permiso && permiso[0]["tipoEvaluaciones"] & 4) === 4) && (row.eliminado == null) && (
                        <NavLink exact to={(`evaluacion/editar/${row.idTipoEvaluacion}`)} style={{ color: '#000', textDecoration: 'none' }}>
                          <MenuItem aria-label="editar"
                            onClick={() => {
                              history.push(`evaluacion/editar/${row.idTipoEvaluacion}`, { match: row.idTipoEvaluacion });
                            }}>
                            <Edit style={{ paddingRight: 5 }} />
                            Editar
                          </MenuItem>
                        </NavLink>
                      )}
                      {(admin === true || (permiso && permiso[0]["tipoEvaluaciones"] & 8) === 8) && (
                        row?.eliminado === null ? (
                          <MenuItem
                            onClick={() => onDeleteClicked(row, "Deshabilitar")}
                          >
                            <Delete style={{ paddingRight: 5 }} />
                            Deshabilitar
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={() => onDeleteClicked(row, "Habilitar")}
                          >
                            <RestoreFromTrashIcon style={{ paddingRight: 5 }} />
                            Habilitar
                          </MenuItem>
                        )
                      )}
                    </Menu>
                  </div>
                ),
              },
            ]}
          />
        }
      />
    </>
  );
}
export default TipoEvaluacion;