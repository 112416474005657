/* eslint-disable no-unused-vars */
import * as React from 'react';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ButtonMenu from './ButtonMenu';
import logo from '../assets/img/logo.png';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { navPublicoStyle } from "../utils";
import PreNavbar from "./preNavbar";
import { useLocation } from 'react-router-dom'

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const navPublico = navPublicoStyle();

export const Navigation = (props) => {
  const navPublicoStyle = navPublico();

  const buttonMenu = {
    titleText: "ENCUESTAS",
    idBoton: "button_menu",
    menus: [
      {
        target: "_blanck",
        text: "",
        href: ""
      },
    ],
  }

  const location = useLocation().pathname;
  var text = "";
  if (location === "/factura") {
    text = "Bienvenido a tu factura electronica";
  } else {
    text = "Bienvenido a clinica nueva vida";
  }

  return (
    <>
      {
        location !== "/ubicacionlhospitalito" && (
          <>
            <PreNavbar />
            <AppBar position="static" className={navPublicoStyle.navPublico}>
              <Container maxWidth="xl" style={{ display: "flex", justifyContent: "center" }}>
                <Toolbar className={navPublicoStyle.toolBar} disableGutters>

                  <Box>
                    <img className={navPublicoStyle.logo} src={logo} alt="Logo" />
                  </Box>

                  {/* <Box sx={{ display: { xs: 'none', md: 'block' } }} className={navPublicoStyle.bBox}>
                    <p>{text}</p>
                  </Box> */}

                  {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <ButtonMenu className={navPublicoStyle.buttonMenu} obj={buttonMenu} />
                  </Box> */}

                </Toolbar>
              </Container>
            </AppBar>
          </>
        )
      }

    </>

  )
}
