/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Service from "../../services/api";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import DatePickerBeta from "../../components/DatePickerBeta";
import { useAlert } from "../../hooks/useAlert";
import moment from "moment-timezone";
import FirebaseService from "../../services/firebase";

import {
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Divider,
  CardMedia,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Edit, PhotoCamera } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import { FiUpload } from "react-icons/fi";
import { BsCameraFill } from "react-icons/bs";
import Tabs from "../../components/TabsRework";
import ReactFileManager from "../../components/ReactFileManager";
import AsyncAutocompleteSearchRework from "../../components/AsyncAutocompleteSearchRework";
import AutocompleteDeluxe from "../../components/AutocompleteDeluxe";
import Swal from "sweetalert2";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////

const PacienteTarget = ({
  history,
  setGuardado,
  dialog,
  pacienteDetalles,
  setPacienteDetalles,
  defaultPaciente,
  loadingEdit,
  setLoadingEdit,
  osEditing,
  cita,
  ...props
}) => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const [archivo, setArchivo] = useState(null);
  const [foto, setFoto] = useState(null);
  const [tipoImagen, setTipoImagen] = useState("file");
  const formulario = useRef();
  const [editing, setEditing] = useState(false);
  const [pos, setPos] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paciente, setPaciente] = useState(null);
  const [nuevo, setNuevo] = useState(false);
  const [editar, setEditar] = useState(false);

  const sizes = {
    lg: 4,
    xs: 12,
    sm: 12,
  };
  ////////////////////////////////////////////////////////////////////////
  //                              Vars                                  //
  ////////////////////////////////////////////////////////////////////////

  useEffect(async () => { 
    if (osEditing) {
      setPaciente(pacienteDetalles)
    }
    if (cita) {
      setLoadingEdit(true);
      setPaciente(cita?.paciente);
      setPacienteDetalles(cita?.paciente);
      setLoadingEdit(false);
    }
  }, []);
  ////////////////////////////////////////////////////////////////////////
  //                           Funcions Def                             //
  ////////////////////////////////////////////////////////////////////////

  const handleFileRead = async ({ target }) => {
    let file = target.files[0];
    const typePhoto = file.name.split(".").pop();
    switch (typePhoto) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        break;
      default:
        showAlert({
          message: "El archivo no tiene la extensión adecuada",
          severity: "warning",
        });
        file = "";
    }
    if (file !== undefined) {
      setArchivo(file);
      setPacienteDetalles((prevState) => ({
        ...prevState,
        ...prevState.pacienteDetalles,
        urlFoto: URL.createObjectURL(target.files[0]),
      }));
    }
  };

  const onGuardar = async () => {
    const res = await Swal.fire({
      title: "Guardar",
      icon: "warning",
      text: `¿Estás seguro de que deseas guardar el Paciente?`,
      showCancelButton: true,
      confirmButtonColor: "#25607A",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, deseo Continuar!",
      cancelButtonText: "Cancelar",
    });

    if (res.isConfirmed) {

      if (pacienteDetalles?.ine == "" || pacienteDetalles?.ine == null || pacienteDetalles?.ine == undefined) {
        showAlert({
          message: "la INE es requerida",
          severity: "warning",
        })
        return
      }

      setLoading(true);
      let urlFilePaciente = "";
      let body = pacienteDetalles;
      const nombreCompleto = pacienteDetalles?.nombres + "_" + pacienteDetalles?.apellidoPaterno + "_" + pacienteDetalles?.apellidoMaterno
      const paciente = pacienteDetalles?.ine
        .split(" ")
        .join("")
        .toLowerCase();

      const ranm = Math.floor(Math.random() * 1000);
      const pathFirebase = "Paciente/" + "Imagen" + "_" + paciente + "_" + nombreCompleto + moment(new Date()).format("DD_MM_YYYY_HH_mm_ss") + "_" + ranm;
      console.log(pathFirebase)

      if (tipoImagen == "file") {
        if (archivo) {
          urlFilePaciente = await FirebaseService.uploadFile(
            pathFirebase,
            archivo
          );
          setPacienteDetalles({ ...pacienteDetalles, urlFoto: urlFilePaciente });
          body = { ...body, urlFoto: urlFilePaciente };
        }
      } else {
        if (foto) {
          urlFilePaciente = await FirebaseService.uploadFile(
            pathFirebase,
            foto
          );
          setPacienteDetalles({ ...pacienteDetalles, urlFoto: urlFilePaciente });
          body = { ...body, urlFoto: urlFilePaciente };
        }
      }

      if (editing) {
        body.idPaciente = paciente?.idPaciente;
      }

      body = { ...body, os: 1 };
      let res = await Service.post("paciente/guardar", body);
      setLoading(false);
      if (res.detalle.estatus == 200) {
        setPacienteDetalles({ ...pacienteDetalles, ...res?.mensaje })
        setEditing(true)
        setEditar(true);
        showAlert({
          message: "paciente guardado correctamente",
          severity: "success",
        });
      } else if (res.detalle.estatus == 400) {
        showAlert({
          message: res.mensaje,
          severity: "error",
        });
      }
    }
  };

  const changePos = async (_pos) => {
    if (_pos == 0) {
      setTipoImagen("file");
    } else {
      setTipoImagen("foto");
    }
    setPos(_pos);
  };

  ////////////////////////////////////////////////////////////////////////
  //                            Hooks Def                               //
  ////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (paciente) {
      setPacienteDetalles({
        ...pacienteDetalles,
        ...paciente,
        fechaNacimiento: moment(paciente.fechaNacimiento).format("yyyy/MM/DD"),
        edad: getAge(moment(paciente.fechaNacimiento).format("yyyy/MM/DD")),
      });
    } else {
      setPacienteDetalles({ ...defaultPaciente });
    }
  }, [paciente])

  function getAge(dateString) {
    var today = new Date();
    var fechaNacimiento = new Date(dateString);
    var age = today.getFullYear() - fechaNacimiento.getFullYear();
    var m = today.getMonth() - fechaNacimiento.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < fechaNacimiento.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <>
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div ref={formulario} style={{ textAlign: "center" }}>
            <strong>{"INFORMACIÓN DEL PACIENTE"}</strong>
          </div>
          <Divider />
        </Grid>
        {!loadingEdit ? (
          <Grid container spacing={2}>
            {
              osEditing
                ?
                <Grid item lg={4} xs={12} sm={12} md={12} sx={{ display: "flex", alignItems: "center" }}>
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <img style={{ width: "100%" }} src={pacienteDetalles.urlFoto} alt={"pppaciente"} />
                  </Grid>
                </Grid>
                :
                <Grid item lg={4} xs={12} sm={12} md={12} sx={{ display: "flex", alignItems: "center" }}>
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <Tabs
                        changePos={changePos}
                        pos={pos}
                        tabs={[
                          {
                            title: "Subir Imagen",
                            icon: (
                              <FiUpload
                                style={{
                                  fontSize: "x-large",
                                  marginLeft: "5px",
                                  marginBottom: "5px",
                                }}
                              />
                            ),
                            content: (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                style={{ textAlign: "center", alignItems: "center" }}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={7}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      marginBottom: "30px",
                                    }}
                                  >
                                    <CardMedia
                                      component="img"
                                      image={pacienteDetalles?.urlFoto}
                                      title=""
                                      style={{
                                        width: "305px",
                                        height: "234px",
                                        objectFit: "cover",
                                        border: "7px solid whiteSmoke",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                  style={{
                                    height: "20px",
                                  }}
                                >
                                  <Grid item xs={12} sm={12} md={2}>
                                    <div>
                                      <input
                                        hidden
                                        accept="image/gif,image/jpeg,image/jpg,image/png"
                                        className={classes.input}
                                        id="contained-button-file"
                                        type="file"
                                        onChange={handleFileRead}
                                        disabled={!nuevo && !editar}
                                      />
                                      <label htmlFor="contained-button-file">
                                        <Button
                                          style={{ color: "white" }}
                                          variant="contained"
                                          color="primary"
                                          component="span"
                                          disabled={!nuevo && !editar}
                                        >
                                          <PhotoCamera />
                                        </Button>
                                      </label>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ),
                          },
                          {
                            title: "Tomar Foto",
                            icon: (
                              <BsCameraFill
                                style={{
                                  fontSize: "x-large",
                                  marginLeft: "5px",
                                  marginBottom: "4px",
                                }}
                              />
                            ),
                            content: (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  sm={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <ReactFileManager foto={foto} setFoto={setFoto} />
                                </Grid>
                              </div>
                            ),
                          },
                        ]}
                      />
                    </div>
                  </Grid>
                </Grid>
            }
            <Grid item lg={8} xs={12} sm={12}>
              <Grid
                container
                style={{
                  padding: "10px 0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <DatePickerBeta
                    labelText="Fecha Solicitud Orden Servicio"
                    value={pacienteDetalles?.fechaSolicitud}
                    variant="standard"
                    size={"medium"}
                    disabled={osEditing}
                    inputFormat="dd/MM/yyyy"
                    onChange={(e) => {
                      setPacienteDetalles((prevState) => ({
                        ...prevState,
                        ...prevState.pacienteDetalles,
                        fechaSolicitud: e,
                      }));
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  padding: "10px 0",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <AutocompleteDeluxe
                  labelToShow={"Pacientes"}
                  labelProp="nombreCompleto"
                  nombre="paciente"
                  dato={paciente}
                  setDato={setPaciente}
                  disabled={osEditing ? true : editar ? true : false}
                />

                <div style={{ display: "flex", flexDirection: "row" }}>
                  {paciente ? (
                    <LoadingButton
                      disabled={osEditing}
                      variant="contained"
                      style={{ margin: "15px", color: "#FFF" }}
                      onClick={() => setEditar(true)}
                    >
                      {" "}
                      <Edit />{" "}
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      disabled={osEditing}
                      variant="contained"
                      style={{ margin: "15px", color: "#FFF" }}
                      onClick={() => {
                        setPacienteDetalles({
                          ...pacienteDetalles,
                          pacienteSucursal: [{ idSucursal }],
                        });
                        setNuevo(true);
                      }}
                    >
                      {" "}
                      <Add />{" "}
                    </LoadingButton>
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  padding: "10px 0",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
              </Grid>

              <Grid container spacing={2}>
                <Grid item {...sizes}>
                  <InputRegex
                    variant="standard"
                    color="primary"
                    label="INE"
                    fullWidth
                    disabled={!editar && !nuevo}
                    typeInput="text"
                    maxLength={13}
                    value={pacienteDetalles?.ine}
                    onChange={(e) => {
                      setPacienteDetalles((prevState) => ({
                        ...prevState,
                        ...prevState.pacienteDetalles,
                        ine: e.target.value,
                      }));
                    }}
                  />
                </Grid>

                <Grid item {...sizes}>
                  <InputRegex
                    label="Nombre(s)"
                    variant="standard"
                    color="primary"
                    disabled={!editar && !nuevo}
                    fullWidth
                    typeInput="text"
                    maxLength={50}
                    value={pacienteDetalles?.nombres.toUpperCase()}
                    onChange={(e) =>
                      setPacienteDetalles((prevState) => ({
                        ...prevState,
                        ...prevState.pacienteDetalles,
                        nombres: e.target.value.toUpperCase(),
                      }))
                    }
                  />
                </Grid>

                <Grid item {...sizes}>
                  <InputRegex
                    label="Apellido Paterno"
                    variant="standard"
                    color="primary"
                    disabled={!editar && !nuevo}
                    fullWidth
                    typeInput="text"
                    maxLength={50}
                    value={pacienteDetalles?.apellidoPaterno.toUpperCase()}
                    onChange={(e) =>
                      setPacienteDetalles((prevState) => ({
                        ...prevState,
                        ...prevState.pacienteDetalles,
                        apellidoPaterno: e.target.value.toUpperCase(),
                      }))
                    }
                  />
                </Grid>

                <Grid item {...sizes}>
                  <InputRegex
                    label="Apellido Materno"
                    variant="standard"
                    color="primary"
                    disabled={!editar && !nuevo}
                    fullWidth
                    typeInput="text"
                    maxLength={50}
                    value={pacienteDetalles?.apellidoMaterno.toUpperCase()}
                    onChange={(e) =>
                      setPacienteDetalles((prevState) => ({
                        ...prevState,
                        ...prevState.pacienteDetalles,
                        apellidoMaterno: e.target.value.toUpperCase(),
                      }))
                    }
                  />
                </Grid>

                <Grid item {...sizes}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="tipoSelect">Seleccionar género</InputLabel>
                    <Select
                      labelId="tipoSelect"
                      fullWidth
                      label="Genero"
                      disabled={!editar && !nuevo}
                      onChange={(e) => {
                        setPacienteDetalles((prevState) => ({
                          ...prevState,
                          ...prevState.pacienteDetalles,
                          genero: e.target.value,
                        }));
                      }}
                      inputProps={{
                        value: pacienteDetalles?.genero,
                      }}
                    >
                      <MenuItem value={"M"}>Masculino</MenuItem>
                      <MenuItem value={"F"}>Femenino</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item {...sizes}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="tipoSelect">Estado Civil</InputLabel>
                    <Select
                      labelId="tipoSelect"
                      disabled={!editar && !nuevo}
                      fullWidth
                      label="Estado Civil"
                      onChange={(e) => {
                        setPacienteDetalles((prevState) => ({
                          ...prevState,
                          ...prevState.pacienteDetalles,
                          estadoCivil: e.target.value,
                        }));
                      }}
                      inputProps={{
                        value: pacienteDetalles?.estadoCivil,
                      }}
                    >
                      <MenuItem value={"SOLTERO(A)"}>SOLTERO(A)</MenuItem>
                      <MenuItem value={"CASADO(A)"}>CASADO(A)</MenuItem>
                      <MenuItem value={"DIVORCIADO(A)"}>DIVORCIADO(A)</MenuItem>
                      <MenuItem value={"VIUDO(A)"}>VIUDO(A)</MenuItem>
                      <MenuItem value={"CONCUBINATO(A)"}>CONCUBINATO(A)</MenuItem>
                      {/* <MenuItem value={"SEPARACIONJUDICIAL"}>SEPARACIÓN EN PROCESO JUDICIAL</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item {...sizes}>
                  <DatePickerBeta
                    labelText="Fecha de Nacimiento"
                    value={pacienteDetalles?.fechaNacimiento}
                    variant="standard"
                    size={"medium"}
                    disabled={!editar && !nuevo}
                    inputFormat="dd/MM/yyyy"
                    onChange={(e) => {
                      setPacienteDetalles({ ...pacienteDetalles, fechaNacimiento: e });
                      setPacienteDetalles({ ...pacienteDetalles, fechaNacimiento: e, edad: getAge(moment(e).format("yyyy/MM/DD")) });
                    }}
                  />
                </Grid>

                <Grid item lg={2} xs={12} sm={12}>
                  <InputRegex
                    label="Edad"
                    typeInput="text"
                    color="primary"
                    disabled={!editar && !nuevo}
                    value={getAge(pacienteDetalles?.fechaNacimiento)}
                  />
                </Grid>

                <Grid item lg={2} xs={12} sm={12}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="tipoSelect">Tipo de Sangre</InputLabel>
                    <Select
                      labelId="tipoSelect"
                      fullWidth
                      label="TipoSangre"
                      disabled={!editar && !nuevo}
                      onChange={(e) => {
                        setPacienteDetalles((prevState) => ({
                          ...prevState,
                          ...prevState.pacienteDetalles,
                          tipoSangre: e.target.value,
                        }));
                      }}
                      inputProps={{
                        value: pacienteDetalles?.tipoSangre,
                      }}
                    >
                      <MenuItem value={"A+"}>A+</MenuItem>
                      <MenuItem value={"A-"}>A-</MenuItem>
                      <MenuItem value={"B+"}>B+</MenuItem>
                      <MenuItem value={"B-"}>B-</MenuItem>
                      <MenuItem value={"AB+"}>AB+</MenuItem>
                      <MenuItem value={"AB-"}>AB-</MenuItem>
                      <MenuItem value={"O+"}>O+</MenuItem>
                      <MenuItem value={"O-"}>O-</MenuItem>
                      <MenuItem value={"Desconocido"}>Desconocido</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item {...sizes}>
                  <TextField
                    label="Alergias"
                    variant="standard"
                    disabled={!editar && !nuevo}
                    fullWidth
                    type="text"
                    value={pacienteDetalles?.alergias}
                    onChange={(e) =>
                      setPacienteDetalles((prevState) => ({
                        ...prevState,
                        ...prevState.pacienteDetalles,
                        alergias: e.target.value.toUpperCase(),
                      }))
                    }
                  />
                </Grid>

                <Grid item {...sizes}>
                  <TextField
                    label="Correo"
                    variant="standard"
                    disabled={!editar && !nuevo}
                    fullWidth
                    type="email"
                    value={pacienteDetalles?.correo.toUpperCase()}
                    onChange={(e) =>
                      setPacienteDetalles((prevState) => ({
                        ...prevState,
                        ...prevState.pacienteDetalles,
                        correo: e.target.value.toUpperCase(),
                      }))
                    }
                  />
                </Grid>

                <Grid item {...sizes}>
                  <InputRegex
                    label="Teléfono"
                    variant="standard"
                    disabled={!editar && !nuevo}
                    fullWidth
                    maxLength={10}
                    typeInput="phone"
                    value={pacienteDetalles?.telefono}
                    onChange={(e) =>
                      setPacienteDetalles((prevState) => ({
                        ...prevState,
                        ...prevState.pacienteDetalles,
                        telefono: e.target.value.replaceAll(/[-() ]/g, ""),
                      }))
                    }
                  />
                </Grid>

                <Grid item {...sizes}>
                  <InputRegex
                    label="Contacto Emergencia"
                    variant="standard"
                    disabled={!editar && !nuevo}
                    fullWidth
                    maxLength={10}
                    typeInput="phone"
                    value={pacienteDetalles?.contactoEmergencia == null ? "9999999999" : pacienteDetalles?.contactoEmergencia}
                    onChange={(e) =>
                      setPacienteDetalles((prevState) => ({
                        ...prevState,
                        ...prevState.pacienteDetalles,
                        contactoEmergencia: e.target.value.replaceAll(/[-() ]/g, ""),
                      }))
                    }
                  />
                </Grid>

                <Grid item {...sizes}>
                  <InputRegex
                    label="Nombre del contacto de emergencia"
                    variant="standard"
                    color="primary"
                    disabled={!editar && !nuevo}
                    fullWidth
                    typeInput="text"
                    maxLength={50}
                    value={pacienteDetalles?.nombreEmergencia}
                    onChange={(e) =>
                      setPacienteDetalles((prevState) => ({
                        ...prevState,
                        ...prevState.pacienteDetalles,
                        nombreEmergencia: e.target.value.toUpperCase(),
                      }))
                    }
                  />
                </Grid>

                <Grid item {...sizes}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="tipoSelect">Nivel de estudio</InputLabel>
                    <Select
                      labelId="tipoSelect"
                      fullWidth
                      label="NivelEducativo"
                      disabled={!editar && !nuevo}
                      onChange={(e) => {
                        setPacienteDetalles((prevState) => ({
                          ...prevState,
                          ...prevState.pacienteDetalles,
                          nivelEducativo: e.target.value,
                        }));
                      }}
                      inputProps={{
                        value: pacienteDetalles?.nivelEducativo,
                      }}
                    >
                      <MenuItem value={"PRIMARIA"}>PRIMARIA</MenuItem>
                      <MenuItem value={"SECUNDARIA"}>SECUNDARIA</MenuItem>
                      <MenuItem value={"PREPARATORIA"}>PREPARATORIA</MenuItem>
                      <MenuItem value={"TECNICA"}>TECNICA</MenuItem>
                      <MenuItem value={"LICENCIATURA"}>LICENCIATURA</MenuItem>
                      <MenuItem value={"INGENIERIA"}>INGENIERIA</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item {...sizes}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="tipoSelect">Nacionalidad</InputLabel>
                    <Select
                      labelId="tipoSelect"
                      fullWidth
                      label="Nacionalidad"
                      disabled={!editar && !nuevo}
                      onChange={(e) => {
                        setPacienteDetalles((prevState) => ({
                          ...prevState,
                          ...prevState.pacienteDetalles,
                          nacionalidad: e.target.value,
                        }));
                      }}
                      inputProps={{
                        value: pacienteDetalles?.nacionalidad,
                      }}
                    >
                      <MenuItem value={"Afganistán"}>Afganistán</MenuItem>
                      <MenuItem value={"Albania"}>Albania</MenuItem>
                      <MenuItem value={"Alemania"}>Alemania</MenuItem>
                      <MenuItem value={"Andorra"}>Andorra</MenuItem>
                      <MenuItem value={"Angola"}>Angola</MenuItem>
                      <MenuItem value={"Anguila"}>Anguila</MenuItem>
                      <MenuItem value={"Antártida"}>Antártida</MenuItem>
                      <MenuItem value={"Antigua y Barbuda"}>
                        Antigua y Barbuda
                      </MenuItem>
                      <MenuItem value={"Antillas holandesas"}>
                        Antillas holandesas
                      </MenuItem>
                      <MenuItem value={"Arabia Saudí"}>Arabia Saudí</MenuItem>
                      <MenuItem value={"Argelia"}>Argelia</MenuItem>
                      <MenuItem value={"Argentina"}>Argentina</MenuItem>
                      <MenuItem value={"Armenia"}>Armenia</MenuItem>
                      <MenuItem value={"Aruba"}>Aruba</MenuItem>
                      <MenuItem value={"Australia"}>Australia</MenuItem>
                      <MenuItem value={"Austria"}>Austria</MenuItem>
                      <MenuItem value={"Azerbaiyán"}>Azerbaiyán</MenuItem>
                      <MenuItem value={"Bahamas"}>Bahamas</MenuItem>
                      <MenuItem value={"Bahrein"}>Bahrein</MenuItem>
                      <MenuItem value={"Bangladesh"}>Bangladesh</MenuItem>
                      <MenuItem value={"Barbados"}>Barbados</MenuItem>
                      <MenuItem value={"Bélgica"}>Bélgica</MenuItem>
                      <MenuItem value={"Belice"}>Belice</MenuItem>
                      <MenuItem value={"Benín"}>Benín</MenuItem>
                      <MenuItem value={"Bermudas"}>Bermudas</MenuItem>
                      <MenuItem value={"Bhután"}>Bhután</MenuItem>
                      <MenuItem value={"Bielorrusia"}>Bielorrusia</MenuItem>
                      <MenuItem value={"Birmania"}>Birmania</MenuItem>
                      <MenuItem value={"Bolivia"}>Bolivia</MenuItem>
                      <MenuItem value={"Bosnia y Herzegovina"}>
                        Bosnia y Herzegovina
                      </MenuItem>
                      <MenuItem value={"Botsuana"}>Botsuana</MenuItem>
                      <MenuItem value={"Brasil"}>Brasil</MenuItem>
                      <MenuItem value={"Brunei"}>Brunei</MenuItem>
                      <MenuItem value={"Bulgaria"}>Bulgaria</MenuItem>
                      <MenuItem value={"Burkina Faso"}>Burkina Faso</MenuItem>
                      <MenuItem value={"Burundi"}>Burundi</MenuItem>
                      <MenuItem value={"Cabo Verde"}>Cabo Verde</MenuItem>
                      <MenuItem value={"Camboya"}>Camboya</MenuItem>
                      <MenuItem value={"Camerún"}>Camerún</MenuItem>
                      <MenuItem value={"Canadá"}>Canadá</MenuItem>
                      <MenuItem value={"Chad"}>Chad</MenuItem>
                      <MenuItem value={"Chile"}>Chile</MenuItem>
                      <MenuItem value={"China"}>China</MenuItem>
                      <MenuItem value={"Chipre"}>Chipre</MenuItem>
                      <MenuItem value={"Ciudad estado del Vaticano"}>
                        Ciudad estado del Vaticano
                      </MenuItem>
                      <MenuItem value={"Colombia"}>Colombia</MenuItem>
                      <MenuItem value={"Comores"}>Comores</MenuItem>
                      <MenuItem value={"Congo"}>Congo</MenuItem>
                      <MenuItem value={"Corea"}>Corea</MenuItem>
                      <MenuItem value={"Corea del Norte"}>
                        Corea del Norte
                      </MenuItem>
                      <MenuItem value={"Costa del Marfíl"}>
                        Costa del Marfíl
                      </MenuItem>
                      <MenuItem value={"Costa Rica"}>Costa Rica</MenuItem>
                      <MenuItem value={"Croacia"}>Croacia</MenuItem>
                      <MenuItem value={"Cuba"}>Cuba</MenuItem>
                      <MenuItem value={"Dinamarca"}>Dinamarca</MenuItem>
                      <MenuItem value={"Djibouri"}>Djibouri</MenuItem>
                      <MenuItem value={"Dominica"}>Dominica</MenuItem>
                      <MenuItem value={"Ecuador"}>Ecuador</MenuItem>
                      <MenuItem value={"Egipto"}>Egipto</MenuItem>
                      <MenuItem value={"El Salvador"}>El Salvador</MenuItem>
                      <MenuItem value={"Emiratos Arabes Unidos"}>
                        Emiratos Arabes Unidos
                      </MenuItem>
                      <MenuItem value={"Eritrea"}>Eritrea</MenuItem>
                      <MenuItem value={"Eslovaquia"}>Eslovaquia</MenuItem>
                      <MenuItem value={"Eslovenia"}>Eslovenia</MenuItem>
                      <MenuItem value={"España"}>España</MenuItem>
                      <MenuItem value={"Estados Unidos"}>
                        Estados Unidos
                      </MenuItem>
                      <MenuItem value={"Estonia"}>Estonia</MenuItem>
                      <MenuItem value={"c"}>Etiopía</MenuItem>
                      <MenuItem value={"Ex-República Yugoslava de Macedonia"}>
                        Ex-República Yugoslava de Macedonia
                      </MenuItem>
                      <MenuItem value={"Filipinas"}>Filipinas</MenuItem>
                      <MenuItem value={"Finlandia"}>Finlandia</MenuItem>
                      <MenuItem value={"Francia"}>Francia</MenuItem>
                      <MenuItem value={"Gabón"}>Gabón</MenuItem>
                      <MenuItem value={"Gambia"}>Gambia</MenuItem>
                      <MenuItem value={"Georgia"}>Georgia</MenuItem>
                      <MenuItem
                        value={"Georgia del Sur y las islas Sandwich del Sur"}
                      >
                        Georgia del Sur y las islas Sandwich del Sur
                      </MenuItem>
                      <MenuItem value={"Ghana"}>Ghana</MenuItem>
                      <MenuItem value={"Gibraltar"}>Gibraltar</MenuItem>
                      <MenuItem value={"Granada"}>Granada</MenuItem>
                      <MenuItem value={"Grecia"}>Grecia</MenuItem>
                      <MenuItem value={"Groenlandia"}>Groenlandia</MenuItem>
                      <MenuItem value={"Guadalupe"}>Guadalupe</MenuItem>
                      <MenuItem value={"Guam"}>Guam</MenuItem>
                      <MenuItem value={"Guatemala"}>Guatemala</MenuItem>
                      <MenuItem value={"Guayana"}>Guayana</MenuItem>
                      <MenuItem value={"Guayana francesa"}>
                        Guayana francesa
                      </MenuItem>
                      <MenuItem value={"Guinea"}>Guinea</MenuItem>
                      <MenuItem value={"Guinea Ecuatorial"}>
                        Guinea Ecuatorial
                      </MenuItem>
                      <MenuItem value={"Guinea-Bissau"}>Guinea-Bissau</MenuItem>
                      <MenuItem value={"Haití"}>Haití</MenuItem>
                      <MenuItem value={"Holanda"}>Holanda</MenuItem>
                      <MenuItem value={"Honduras"}>Honduras</MenuItem>
                      <MenuItem value={"Hong Kong R. A. E"}>
                        Hong Kong R. A. E
                      </MenuItem>
                      <MenuItem value={"Hungría"}>Hungría</MenuItem>
                      <MenuItem value={"India"}>India</MenuItem>
                      <MenuItem value={"Indonesia"}>Indonesia</MenuItem>
                      <MenuItem value={"Irak"}>Irak</MenuItem>
                      <MenuItem value={"Irán"}>Irán</MenuItem>
                      <MenuItem value={"Irlanda"}>Irlanda</MenuItem>
                      <MenuItem value={"Isla Bouvet"}>Isla Bouvet</MenuItem>
                      <MenuItem value={"Isla Christmas"}>
                        Isla Christmas
                      </MenuItem>
                      <MenuItem value={"Isla Heard e Islas McDonald"}>
                        Isla Heard e Islas McDonald
                      </MenuItem>
                      <MenuItem value={"Islandia"}>Islandia</MenuItem>
                      <MenuItem value={"Islas Caimán"}>Islas Caimán</MenuItem>
                      <MenuItem value={"Islas Cook"}>Islas Cook</MenuItem>
                      <MenuItem value={"Islas de Cocos o Keeling"}>
                        Islas de Cocos o Keeling
                      </MenuItem>
                      <MenuItem value={"Islas Faroe"}>Islas Faroe</MenuItem>
                      <MenuItem value={"Islas Fiyi"}>Islas Fiyi</MenuItem>
                      <MenuItem value={"Islas Malvinas Islas Falkland"}>
                        Islas Malvinas Islas Falkland
                      </MenuItem>
                      <MenuItem value={"Islas Marianas del norte"}>
                        Islas Marianas del norte
                      </MenuItem>
                      <MenuItem value={"Islas Marshall"}>
                        Islas Marshall
                      </MenuItem>
                      <MenuItem value={"Islas menores de Estados Unidos"}>
                        Islas menores de Estados Unidos
                      </MenuItem>
                      <MenuItem value={"Islas Palau"}>Islas Palau</MenuItem>
                      <MenuItem value={"Islas Salomón"}>Islas Salomón</MenuItem>
                      <MenuItem value={"Islas Tokelau"}>Islas Tokelau</MenuItem>
                      <MenuItem value={"Islas Turks y Caicos"}>
                        Islas Turks y Caicos
                      </MenuItem>
                      <MenuItem value={"Islas Vírgenes EE.UU."}>
                        Islas Vírgenes EE.UU.
                      </MenuItem>
                      <MenuItem value={"Islas Vírgenes Reino Unido"}>
                        Islas Vírgenes Reino Unido
                      </MenuItem>
                      <MenuItem value={"Israel"}>Israel</MenuItem>
                      <MenuItem value={"Italia"}>Italia</MenuItem>
                      <MenuItem value={"Jamaica"}>Jamaica</MenuItem>
                      <MenuItem value={"Japón"}>Japón</MenuItem>
                      <MenuItem value={"Jordania"}>Jordania</MenuItem>
                      <MenuItem value={"Kazajistán"}>Kazajistán</MenuItem>
                      <MenuItem value={"Kenia"}>Kenia</MenuItem>
                      <MenuItem value={"Kirguizistán"}>Kirguizistán</MenuItem>
                      <MenuItem value={"Kiribati"}>Kiribati</MenuItem>
                      <MenuItem value={"Kuwait"}>Kuwait</MenuItem>
                      <MenuItem value={"Laos"}>Laos</MenuItem>
                      <MenuItem value={"Lesoto"}>Lesoto</MenuItem>
                      <MenuItem value={"Letonia"}>Letonia</MenuItem>
                      <MenuItem value={"Líbano"}>Líbano</MenuItem>
                      <MenuItem value={"Liberia"}>Liberia</MenuItem>
                      <MenuItem value={"Libia"}>Libia</MenuItem>
                      <MenuItem value={"Liechtenstein"}>Liechtenstein</MenuItem>
                      <MenuItem value={"Lituania"}>Lituania</MenuItem>
                      <MenuItem value={"Luxemburgo"}>Luxemburgo</MenuItem>
                      <MenuItem value={"Macao R. A. E"}>Macao R. A. E</MenuItem>
                      <MenuItem value={"Madagascar"}>Madagascar</MenuItem>
                      <MenuItem value={"Malasia"}>Malasia</MenuItem>
                      <MenuItem value={"Malawi"}>Malawi</MenuItem>
                      <MenuItem value={"Maldivas"}>Maldivas</MenuItem>
                      <MenuItem value={"Malí"}>Malí</MenuItem>
                      <MenuItem value={"Malta"}>Malta</MenuItem>
                      <MenuItem value={"Marruecos"}>Marruecos</MenuItem>
                      <MenuItem value={"Martinica"}>Martinica</MenuItem>
                      <MenuItem value={"Mauricio"}>Mauricio</MenuItem>
                      <MenuItem value={"Mauritania"}>Mauritania</MenuItem>
                      <MenuItem value={"Mayotte"}>Mayotte</MenuItem>
                      <MenuItem value={"México"}>México</MenuItem>
                      <MenuItem value={"Micronesia"}>Micronesia</MenuItem>
                      <MenuItem value={"Moldavia"}>Moldavia</MenuItem>
                      <MenuItem value={"Mónaco"}>Mónaco</MenuItem>
                      <MenuItem value={"Mongolia"}>Mongolia</MenuItem>
                      <MenuItem value={"Montserrat"}>Montserrat</MenuItem>
                      <MenuItem value={"Mozambique"}>Mozambique</MenuItem>
                      <MenuItem value={"Namibia"}>Namibia</MenuItem>
                      <MenuItem value={"Nauru"}>Nauru</MenuItem>
                      <MenuItem value={"Nepal"}>Nepal</MenuItem>
                      <MenuItem value={"Nicaragua"}>Nicaragua</MenuItem>
                      <MenuItem value={"Níger"}>Níger</MenuItem>
                      <MenuItem value={"Nigeria"}>Nigeria</MenuItem>
                      <MenuItem value={"Niue"}>Niue</MenuItem>
                      <MenuItem value={"Norfolk"}>Norfolk</MenuItem>
                      <MenuItem value={"Noruega"}>Noruega</MenuItem>
                      <MenuItem value={"Nueva Caledonia"}>
                        Nueva Caledonia
                      </MenuItem>
                      <MenuItem value={"Nueva Zelanda"}>Nueva Zelanda</MenuItem>
                      <MenuItem value={"Omán"}>Omán</MenuItem>
                      <MenuItem value={"Panamá"}>Panamá</MenuItem>
                      <MenuItem value={"Papua Nueva Guinea"}>
                        Papua Nueva Guinea
                      </MenuItem>
                      <MenuItem value={"Paquistán"}>Paquistán</MenuItem>
                      <MenuItem value={"Paraguay"}>Paraguay</MenuItem>
                      <MenuItem value={"Perú"}>Perú</MenuItem>
                      <MenuItem value={"Pitcairn"}>Pitcairn</MenuItem>
                      <MenuItem value={"Polinesia francesa"}>
                        Polinesia francesa
                      </MenuItem>
                      <MenuItem value={"Polonia"}>Polonia</MenuItem>
                      <MenuItem value={"Portugal"}>Portugal</MenuItem>
                      <MenuItem value={"Puerto Rico"}>Puerto Rico</MenuItem>
                      <MenuItem value={"Qatar"}>Qatar</MenuItem>
                      <MenuItem value={"Reino Unido"}>Reino Unido</MenuItem>
                      <MenuItem value={"República Centroafricana"}>
                        República Centroafricana
                      </MenuItem>
                      <MenuItem value={"República Checa"}>
                        República Checa
                      </MenuItem>
                      <MenuItem value={"República de Sudáfrica"}>
                        República de Sudáfrica
                      </MenuItem>
                      <MenuItem value={"República Democrática del Congo Zaire"}>
                        República Democrática del Congo Zaire
                      </MenuItem>
                      <MenuItem value={"República Dominicana"}>
                        República Dominicana
                      </MenuItem>
                      <MenuItem value={"Reunión"}>Reunión</MenuItem>
                      <MenuItem value={"Ruanda"}>Ruanda</MenuItem>
                      <MenuItem value={"Rumania"}>Rumania</MenuItem>
                      <MenuItem value={"Rusia"}>Rusia</MenuItem>
                      <MenuItem value={"Samoa"}>Samoa</MenuItem>
                      <MenuItem value={"Samoa occidental"}>
                        Samoa occidental
                      </MenuItem>
                      <MenuItem value={"San Kitts y Nevis"}>
                        San Kitts y Nevis
                      </MenuItem>
                      <MenuItem value={"San Marino"}>San Marino</MenuItem>
                      <MenuItem value={"San Pierre y Miquelon"}>
                        San Pierre y Miquelon
                      </MenuItem>
                      <MenuItem value={"San Vicente e Islas Granadinas"}>
                        San Vicente e Islas Granadinas
                      </MenuItem>
                      <MenuItem value={"Santa Helena"}>Santa Helena</MenuItem>
                      <MenuItem value={"Santa Lucía"}>Santa Lucía</MenuItem>
                      <MenuItem value={"Santo Tomé y Príncipe"}>
                        Santo Tomé y Príncipe
                      </MenuItem>
                      <MenuItem value={"Senegal"}>Senegal</MenuItem>
                      <MenuItem value={"Serbia y Montenegro"}>
                        Serbia y Montenegro
                      </MenuItem>
                      <MenuItem value={"Sychelles"}>Seychelles</MenuItem>
                      <MenuItem value={"Sierra Leona"}>Sierra Leona</MenuItem>
                      <MenuItem value={"Singapur"}>Singapur</MenuItem>
                      <MenuItem value={"Siria"}>Siria</MenuItem>
                      <MenuItem value={"Somalia"}>Somalia</MenuItem>
                      <MenuItem value={"Sri Lanka"}>Sri Lanka</MenuItem>
                      <MenuItem value={"Suazilandia"}>Suazilandia</MenuItem>
                      <MenuItem value={"Sudán"}>Sudán</MenuItem>
                      <MenuItem value={"Suecia"}>Suecia</MenuItem>
                      <MenuItem value={"Suiza"}>Suiza</MenuItem>
                      <MenuItem value={"Surinam"}>Surinam</MenuItem>
                      <MenuItem value={"Svalbard"}>Svalbard</MenuItem>
                      <MenuItem value={"Tailandia"}>Tailandia</MenuItem>
                      <MenuItem value={"Taiwán"}>Taiwán</MenuItem>
                      <MenuItem value={"Tanzania"}>Tanzania</MenuItem>
                      <MenuItem value={"Tayikistán"}>Tayikistán</MenuItem>
                      <MenuItem
                        value={"Territorios británicos del océano Indico"}
                      >
                        Territorios británicos del océano Indico
                      </MenuItem>
                      <MenuItem value={"Territorios franceses del sur"}>
                        Territorios franceses del sur
                      </MenuItem>
                      <MenuItem value={"}Timor Oriental"}>
                        Timor Oriental
                      </MenuItem>
                      <MenuItem value={"Togo"}>Togo</MenuItem>
                      <MenuItem value={"Tonga"}>Tonga</MenuItem>
                      <MenuItem value={"Trinidad y Tobago"}>
                        Trinidad y Tobago
                      </MenuItem>
                      <MenuItem value={"Túnez"}>Túnez</MenuItem>
                      <MenuItem value={"Turkmenistán"}>Turkmenistán</MenuItem>
                      <MenuItem value={"Turquía"}>Turquía</MenuItem>
                      <MenuItem value={"Tuvalu"}>Tuvalu</MenuItem>
                      <MenuItem value={"Ucrania"}>Ucrania</MenuItem>
                      <MenuItem value={"Uganda"}>Uganda</MenuItem>
                      <MenuItem value={"Uruguay"}>Uruguay</MenuItem>
                      <MenuItem value={"Uzbekistán"}>Uzbekistán</MenuItem>
                      <MenuItem value={"Vanuatu"}>Vanuatu</MenuItem>
                      <MenuItem value={"Venezuela"}>Venezuela</MenuItem>
                      <MenuItem value={"Vietnam"}>Vietnam</MenuItem>
                      <MenuItem value={"Wallis y Futuna"}>
                        Wallis y Futuna
                      </MenuItem>
                      <MenuItem value={"Yemen"}>Yemen</MenuItem>
                      <MenuItem value={"Zambia"}>Zambia</MenuItem>
                      <MenuItem value={"Zimbabue"}>Zimbabue</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item {...sizes}>
                  <TextField
                    label="Puesto"
                    variant="standard"
                    disabled={!editar && !nuevo}
                    fullWidth
                    value={pacienteDetalles?.puesto}
                    onChange={(e) =>
                      setPacienteDetalles((prevState) => ({
                        ...prevState,
                        ...prevState.pacienteDetalles,
                        puesto: e.target.value.toUpperCase(),
                      }))
                    }
                  />
                </Grid>

                <Grid item {...sizes}>
                  <TextField
                    label="Proyecto"
                    variant="standard"
                    disabled={!editar && !nuevo}
                    fullWidth
                    value={pacienteDetalles?.proyecto}
                    onChange={(e) =>
                      setPacienteDetalles((prevState) => ({
                        ...prevState,
                        ...prevState.pacienteDetalles,
                        proyecto: e.target.value.toUpperCase(),
                      }))
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Dirección"
                    variant="outlined"
                    disabled={!editar && !nuevo}
                    multiline
                    size="medium"
                    rows={1}
                    fullWidth
                    value={pacienteDetalles?.direccion}
                    onChange={(e) =>
                      setPacienteDetalles((prevState) => ({
                        ...prevState,
                        ...prevState.pacienteDetalles,
                        direccion: e.target.value,
                      }))
                    }
                  />
                </Grid>

                <br />
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {(editar || nuevo) && (
                    <Grid container justifyContent="flex-end" margin={"10px"}>
                      <LoadingButton
                        loading={loading}
                        variant="contained"
                        onClick={() => {
                          setNuevo(false);
                          setEditar(false);
                        }}
                        size="medium"
                        startIcon={<SaveIcon />}
                        style={{ color: "#fff" }}
                      >
                        Cancelar
                      </LoadingButton>
                    </Grid>
                  )}
                  <Grid container justifyContent="flex-end">
                    <LoadingButton
                      disabled={!editar && !nuevo}
                      variant="contained"
                      loading={loading}
                      onClick={() => onGuardar()}
                      size="medium"
                      startIcon={<SaveIcon />}
                      style={{ color: "#fff" }}
                    >
                      {!editar ? "GUARDAR" : "MODIFICAR"}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{ marginTop: "10px" }} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PacienteTarget;
