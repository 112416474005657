import React from 'react'
import { Page, Document, Text, StyleSheet, Image, pdf, View } from '@react-pdf/renderer'
import { useEffect, useState, useRef } from "react";
import { borderLeft } from '@mui/system'
import EvaluacionesClinica from '../views/evaluacionMedica/clinicaNuevaVida'

const styles = StyleSheet.create({
    page: {
        display: "flex",
        flexDirection: 'column',
        backgroundColor: '#FCFEFF',
        padding: "20px",
    },
    view: {
        width: "100%",
    },
    header: {
        backgroundColor: "#006666",
        color: "#FFF",
        width: "100%",
        padding: "5px",
        textAlign: "center",
    },
    rows: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
    },
    check: {
        width: "100%",
    },
    facturaTitle: {
        width: "50%",
        textAlign: "center",
    },
    facturaContent: {
        width: "80%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    si: {
        width: "20px",
        height: "20px",
        backgroundColor: "whitesmoke",
        padding: "5px",
    },
    no: {
        width: "20px",
        height: "20px",
        backgroundColor: "whitesmoke",
        padding: "5px",
    },
    factura: {
        width: "50%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    letter10: {
        fontSize: "10px"
    },
})

const Clinica = ({ data, ...props }) => {
    const [check, setCheck] = useState(process.env.PUBLIC_URL + '/img/check.png');

    return (
        <Document>
            {/*render a single page*/}
            <Page size="A4" style={styles.page}>
                <View style={styles.view}>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                    }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContentnt: "center" }}>
                            <Image style={{ width: "200px" }} src={"https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2Flogo.png?alt=media&token=16a0ddad-f731-4fb3-9f4c-05a2b1fd40de"} />
                        </div>
                    </div>

                </View>
                <View style={styles.view}>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <div style={styles.header}>
                            <Text style={{ fontSize: "14px" }}>EXAMEN MÉDICO OCUPACIONAL</Text>
                        </div>
                        <div style={styles.rows}>
                            <div style={{
                                width: "33.33%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Fecha</Text>
                            </div>
                            <div style={{
                                width: "33.33%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Folio</Text>
                            </div>
                            <div style={{
                                width: "33.33%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderRight: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Empresa</Text>
                            </div>
                        </div>
                        <div style={styles.rows}>

                            <div style={{
                                width: "33.33%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.fechaSolicitud}</Text>
                            </div>
                            <div style={{
                                width: "33.33%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.idOrdenServicio}</Text>
                            </div>
                            <div style={{
                                width: "33.33%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderRight: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detalleConvenio?.nombreConvenio}</Text>
                            </div>
                        </div>
                    </div>
                </View>

                <View style={{ ...styles.view, height: "auto" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={styles.header}>
                                <Text style={{ fontSize: "10px" }}>Datos del Aspirante o Trabajor.</Text>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "33.33%",
                                    padding: "5px",
                                    backgroundColor: "#F2F2F2",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>Nombre</Text>
                                </div>
                                <div style={{
                                    width: "66.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>{data?.ordenServicio?.detallePaciente?.nombreCompleto}</Text>
                                </div>
                            </div>

                            <div style={styles.rows}>
                                <div style={{
                                    width: "33.33%",
                                    padding: "5px",
                                    backgroundColor: "#F2F2F2",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>Identificacion oficial</Text>
                                </div>
                                <div style={{
                                    width: "66.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>INE</Text>
                                </div>
                            </div>

                            <div style={styles.rows}>
                                <div style={{
                                    width: "33.33%",
                                    padding: "5px",
                                    backgroundColor: "#F2F2F2",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>Número de identificación</Text>
                                </div>
                                <div style={{
                                    width: "66.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>{data?.ordenServicio?.detallePaciente?.ine}</Text>
                                </div>
                            </div>

                            <div style={styles.rows}>
                                <div style={{
                                    width: "33.33%",
                                    padding: "5px",
                                    backgroundColor: "#F2F2F2",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>Género</Text>
                                </div>
                                {data?.ordenServicio?.detallePaciente?.genero === "M" &&
                                    <div style={{
                                        width: "66.66%",
                                        padding: "5px",
                                        borderLeft: "1px solid #E3E3E3",
                                        borderBottom: "1px solid #E3E3E3",
                                        textAlign: "center",
                                    }}>
                                        <Text style={{ fontSize: "7px" }}>MASCULINO</Text>
                                    </div>
                                }
                                {data?.ordenServicio?.detallePaciente?.genero === "F" &&
                                    <div style={{
                                        width: "66.66%",
                                        padding: "5px",
                                        borderLeft: "1px solid #E3E3E3",
                                        borderBottom: "1px solid #E3E3E3",
                                        textAlign: "center",
                                    }}>
                                        <Text style={{ fontSize: "7px" }}>FEMENINO</Text>
                                    </div>
                                }
                            </div>

                            <div style={styles.rows}>
                                <div style={{
                                    width: "33.33%",
                                    padding: "5px",
                                    backgroundColor: "#F2F2F2",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>Edad</Text>
                                </div>
                                <div style={{
                                    width: "66.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>{data?.ordenServicio?.detallePaciente?.edad}</Text>
                                </div>
                            </div>

                            <div style={styles.rows}>
                                <div style={{
                                    width: "33.33%",
                                    padding: "5px",
                                    backgroundColor: "#F2F2F2",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>Puesto a desempeñar</Text>
                                </div>
                                <div style={{
                                    width: "66.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>{data?.ordenServicio?.detallePaciente?.puesto}</Text>
                                </div>
                            </div>

                            <div style={styles.rows}>
                                <div style={{
                                    width: "33.33%",
                                    padding: "5px",
                                    backgroundColor: "#F2F2F2",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>Teléfono</Text>
                                </div>
                                <div style={{
                                    width: "66.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>{data?.ordenServicio?.detallePaciente?.telefono}</Text>
                                </div>
                            </div>

                            <div style={styles.rows}>
                                <div style={{
                                    width: "33.33%",
                                    padding: "5px",
                                    backgroundColor: "#F2F2F2",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>Contacto emergencia</Text>
                                </div>
                                <div style={{
                                    width: "66.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>{data?.ordenServicio?.detallePaciente?.contactoEmergencia}</Text>
                                </div>
                            </div>
                            <div style={styles.rows}>
                                <div style={{
                                    width: "33.33%",
                                    padding: "5px",
                                    backgroundColor: "#F2F2F2",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>Nombre del Contacto emergencia</Text>
                                </div>
                                <div style={{
                                    width: "66.66%",
                                    padding: "5px",
                                    borderLeft: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    textAlign: "center",
                                }}>
                                    <Text style={{ fontSize: "7px" }}>{data?.ordenServicio?.detallePaciente?.nombreEmergencia}</Text>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "30%" }}>
                            <Image style={{ width: "100%" }} src={data?.ordenServicio?.detallePaciente?.urlFoto} />
                        </div>
                    </div>
                </View>

                <View style={styles.view}>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <div style={styles.header}>
                            <Text style={{ fontSize: "12px" }}>Estudios Realizados.</Text>
                        </div>
                        <div style={styles.rows}>
                            <div style={{
                                width: "25%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <div>
                                    <Text style={{ fontSize: "7px" }}>Examen médico</Text>
                                    <div style={styles.facturaContent}>
                                        <Text style={{ fontSize: "7px" }}>SI</Text><div style={styles.si}>{data?.exploracion ? <Image style={styles.check} src={check} /> : <></>}</div>
                                        <Text style={{ fontSize: "7px" }}>NO</Text><div style={styles.no}>{!data?.exploracion ? <Image style={styles.check} src={check} /> : <></>}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                width: "25%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <div>
                                    <Text style={{ fontSize: "7px" }}>Audiometría</Text>
                                    <div style={styles.facturaContent}>
                                        <Text style={{ fontSize: "7px" }}>SI</Text><div style={styles.si}>{data?.audioValores ? <Image style={styles.check} src={check} /> : <></>}</div>
                                        <Text style={{ fontSize: "7px" }}>NO</Text><div style={styles.no}>{!data?.audioValores ? <Image style={styles.check} src={check} /> : <></>}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                width: "25%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <div>
                                    <Text style={{ fontSize: "7px" }}>Examen visual</Text>
                                    <div style={styles.facturaContent}>
                                        <Text style={{ fontSize: "7px" }}>SI</Text><div style={styles.si}>{data?.paraclinicos ? <Image style={styles.check} src={check} /> : <></>}</div>
                                        <Text style={{ fontSize: "7px" }}>NO</Text><div style={styles.no}>{!data?.paraclinicos ? <Image style={styles.check} src={check} /> : <></>}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                width: "25%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <div>
                                    <Text style={{ fontSize: "7px" }}>Antidoping</Text>
                                    <div style={styles.facturaContent}>
                                        <Text style={{ fontSize: "7px" }}>SI</Text><div style={styles.si}>{data?.laboratorios ? <Image style={styles.check} src={check} /> : <></>}</div>
                                        <Text style={{ fontSize: "7px" }}>NO</Text><div style={styles.no}>{!data?.laboratorios ? <Image style={styles.check} src={check} /> : <></>}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={styles.rows}>
                            <div style={{
                                width: "25%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <div>
                                    <Text style={{ fontSize: "7px" }}>Radiografia</Text>
                                    <div style={styles.facturaContent}>
                                        <Text style={{ fontSize: "7px" }}>SI</Text><div style={styles.si}>{data?.estudiosGabinete?.radio ? <Image style={styles.check} src={check} /> : <></>}</div>
                                        <Text style={{ fontSize: "7px" }}>NO</Text><div style={styles.no}>{!data?.estudiosGabinete?.radio ? <Image style={styles.check} src={check} /> : <></>}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                width: "25%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <div>
                                    <Text style={{ fontSize: "7px" }}>Espirometría</Text>
                                    <div style={styles.facturaContent}>
                                        <Text style={{ fontSize: "7px" }}>SI</Text><div style={styles.si}>{data?.estudiosGabinete?.espiro ? <Image style={styles.check} src={check} /> : <></>}</div>
                                        <Text style={{ fontSize: "7px" }}>NO</Text><div style={styles.no}>{!data?.estudiosGabinete?.espiro ? <Image style={styles.check} src={check} /> : <></>}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                width: "25%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <div>
                                    <Text style={{ fontSize: "7px" }}>Laboratorio</Text>
                                    <div style={styles.facturaContent}>
                                        <Text style={{ fontSize: "7px" }}>SI</Text><div style={styles.si}>{data?.laboratorios ? <Image style={styles.check} src={check} /> : <></>}</div>
                                        <Text style={{ fontSize: "7px" }}>NO</Text><div style={styles.no}>{!data?.laboratorios ? <Image style={styles.check} src={check} /> : <></>}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                width: "25%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <div>
                                    <Text style={{ fontSize: "7px" }}>Electrocardiograma</Text>
                                    <div style={styles.facturaContent}>
                                        <Text style={{ fontSize: "7px" }}>SI</Text><div style={styles.si}>{data?.estudiosGabinete?.electro ? <Image style={styles.check} src={check} /> : <></>}</div>
                                        <Text style={{ fontSize: "7px" }}>NO</Text><div style={styles.no}>{!data?.estudiosGabinete?.electro ? <Image style={styles.check} src={check} /> : <></>}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </View>

                <View style={styles.view}>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <div style={styles.rows}>
                            <div style={{
                                width: "30%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Concepto de aptitud ocupacional</Text>
                            </div>
                            <div style={{
                                width: "70%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "7px" }}>{data?.comentarios?.evaluacion}</Text>
                            </div>
                        </div>
                        <div style={styles.rows}>
                            <div style={{
                                width: "30%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Restricciones laborales</Text>
                            </div>
                            <div style={{
                                width: "70%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "7px" }}>{data?.comentarios?.restricciones}</Text>
                            </div>
                        </div>
                        <div style={styles.rows}>
                            <div style={{
                                width: "30%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "10px" }}>Comentarios</Text>
                            </div>
                            <div style={{
                                width: "70%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "7px" }}>{data?.comentarios?.recomendaciones}</Text>
                            </div>
                        </div>

                        <div style={styles.rows}>
                            <div style={{
                                width: "100%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "7px" }}>Consentimiento informado del Aspirante o Trabajador: autorizo al doctor(a) abajo mencionado a realizar mi examen médico ocupacional registrado en este documento. El doctor(a) abajo mencionado me ha explicado la naturaleza y propósito del examen; He comprendido y he tenido la oportunidad de analizar el propósito, los beneficios, la interpretación, las limitaciones, y riesgos del examen médico a partir de la asesoría brindada. Entiendo que la realización de este examen es voluntaria y que tuve la oportunidad de retirar mi consentimiento en cualquier momento. Fui informado de las medidas para proteger la confidencialidad de mis resultados. Las respuestas dadas por mí en este examen son completas y verídicas. Autorizo al doctor(a) para que suministre a las personas o entidades contempladas en la legislación vigente, la información en este documento, para el buen cumplimiento del sistema de seguridad y salud en el trabajo y para las situaciones contempladas en la misma legislación, igualmente para que remitan la Historia Clínica a la Empresa la cual me encuentro actualmente afiliado. Finalmente manifiesto que he leído y comprendido perfectamente lo anterior y que todos los espacios en blancos han sido completados entes de mi firma y que me encuentro en capacidad de expresar mi consentimiento.</Text>
                            </div>
                        </div>

                        <div style={styles.rows}>
                            <div style={{
                                width: "50%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "7px" }}>Médico Ocupacional</Text>
                            </div>
                            <div style={{
                                width: "50%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "7px" }}>Aspirante o trabajador</Text>
                            </div>
                        </div>

                        <div style={styles.rows}>
                            <div style={{
                                width: "50%",
                                height: "50px",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Image style={{ fontSize: "10px" }} src={
                                    "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/DoctoresDetalles%2FImagen_roberto%20adrian%20galindo%20luzanilla217?alt=media&token=fea3f332-2b5f-4be9-b95f-53f6fd596ef9"
                                    // data?.ordenServicio?.usuario
                                    //   ?
                                    //   data?.ordenServicio?.usuario?.detalleDoctor?.urlFile
                                    //   :
                                    //   data?.ordenServicio?.ordenServicioDoctores[0]?.detalleDoctores?.urlFile
                                } />
                            </div>
                            <div style={{
                                width: "50%",
                                height: "50px",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Image style={{ fontSize: "7px" }} src={data?.ordenServicio?.firma} />
                            </div>
                        </div>

                        <div style={styles.rows}>
                            <div style={{
                                width: "50%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "7px" }}>DR(a): {
                                    "MARCOS GONZALEZ AVILA"
                                    // data?.ordenServicio?.usuario
                                    //   ?
                                    //   data?.ordenServicio?.usuario?.nombreCompleto
                                    //   :
                                    //   data?.ordenServicio?.ordenServicioDoctores[0]?.detalleDoctores?.creador?.nombreCompleto
                                }</Text>
                            </div>
                            <div style={{
                                width: "50%",
                                padding: "5px",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "7px" }}>Nombre: {data?.ordenServicio?.detallePaciente?.nombreCompleto}</Text>
                            </div>
                        </div>

                        <div style={styles.rows}>
                            <div style={{
                                width: "25%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "7px" }}>Cédula profesional: {
                                    "6639961"
                                    // data?.ordenServicio?.usuario
                                    //   ?
                                    //   data?.ordenServicio?.usuario?.detalleDoctor?.cp
                                    //   :
                                    //   data?.ordenServicio?.ordenServicioDoctores[0]?.detalleDoctores?.cp
                                }</Text>
                            </div>
                            <div style={{
                                width: "25%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "7px" }}>SSA: {
                                    "8110/10"
                                    // data?.ordenServicio?.usuario
                                    //   ?
                                    //   data?.ordenServicio?.usuario?.detalleDoctor?.cp
                                    //   :
                                    //   data?.ordenServicio?.ordenServicioDoctores[0]?.detalleDoctores?.cp
                                }</Text>
                            </div>
                            <div style={{
                                width: "50%",
                                padding: "5px",
                                backgroundColor: "#F2F2F2",
                                borderLeft: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                textAlign: "center",
                            }}>
                                <Text style={{ fontSize: "7px" }}>Identificacion: {data?.ordenServicio?.detallePaciente?.ine} </Text>
                            </div>
                        </div>
                    </div>
                </View>

                <View style={styles.view}>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                    }}>
                        <Image style={{ width: "600px" }} src={"https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2FfooterEvaluacionClinica.png?alt=media&token=2ed5f4b6-5ff8-458d-bd79-ab86dc3135c7"} />
                    </div>
                </View>
            </Page>
        </Document >
    )
}

const blobToExport = async (data) => {
    return await pdf(<Clinica data={data} />).toBlob()
}

export default blobToExport