/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState } from "react";
import Service from "../../../services/api";
import { useAlert } from "../../../hooks/useAlert";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import PageHeader from "../../../components/PageHeader";
import { Grid, Paper, Switch, FormControlLabel, CircularProgress } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";
import Swal from "sweetalert2";
import { useAuth } from '../../../hooks/useAuth';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

import {
  SignosVitalesSomatometriaComponent,
  AntecedentesLaborablesComponent,
  AntecedentesPersonalesComponent,
  InterrogatorioPorAparatosSistemasComponent,
  ExploracionFisicaComponent,
  ParaclinicosComponent,
  LaboratoriosComponent,
  EstudiosGabineteComponent,
  ComentariosConclusionesComponent,
  DatosPdfComponent,
} from "./pestana-botones";

////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { capitalizeFirst } from "../../../utils";
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Edit } from "@mui/icons-material";
import { FaClinicMedical } from "react-icons/fa";
import { Save } from "@mui/icons-material";
import FichaIdentificacion from "./pestana-botones/fichaIdentificacion";
import { useEffect } from "react";
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

const Detail = ({ match, history, dialog }) => {
  ////////////////////////////////////////////////////////////////////////
  //                              Vars                                  //
  ////////////////////////////////////////////////////////////////////////
  const id = match.params.id;
  const classes = useStyles();
  const editing = !!id;
  const { showAlert } = useAlert();
  const [admin] = useLocalStorage('admin', null);
  const { user, permiso } = useAuth();
  const [accion, setAccion] = useState(null);
  const [idEvaluacion, setIdEvaluacion] = useState(null);
  const [idOrdenServicio, setIdOrdenServicio] = useState(id);
  const [disabled, setDisabled] = useState(true);
  const [evaluacion, setEvaluacion] = useState(null);
  const [guardado, setGuardado] = useState(true);
  const [loading, setLoading] = useState(false);
  const [detallesPaciente, setDetallesPaciente] = useState(null);

  let botonesDefault = [];
  if (permiso && permiso[0]["doctor"] > 0) {
    botonesDefault = [
      {
        icon: null,
        value: "signosVitalesSomatometria",
        title: "Signos Vitales y Somatometría",
        on: true,
        validado: true,
      },
      {
        icon: null,
        value: "antecedentesLaborables",
        title: "Antecedentes Laborables",
        on: true,
        validado: true,
      },
      {
        icon: null,
        value: "antecedentesPersonales",
        title: "Antecedentes Personales",
        on: true,
        validado: true,
      },
      {
        icon: null,
        value: "interrogatorioPorAparatosSistemas",
        title: "Interrogatorio Por Aparatos y Sistemas",
        on: true,
        validado: true,
      },
      {
        icon: null,
        value: "exploracionFisica",
        title: "Exploración Física",
        on: true,
        validado: true,
      },
      {
        icon: null,
        value: "paraclinicos",
        title: "Paraclinicos",
        on: true,
        validado: true,
      },
      {
        icon: null,
        value: "laboratorios",
        title: "Laboratorios",
        on: true,
        validado: true,
      },
      {
        icon: null,
        value: "estudiosGabinete",
        title: "Estudios de Gabinete",
        on: true,
        validado: true,
      },
      {
        icon: null,
        value: "comentariosConclusiones",
        title: "Comentarios y Conclusiones",
        on: true,
        validado: true,
      },
      {
        icon: null,
        value: "datosPdf",
        title: "Datos PDF",
        on: true,
        validado: true,
      },
    ];
  } else if (permiso && permiso[0]["laboratorio"] > 0) {
    botonesDefault = [
      {
        icon: null,
        value: "laboratorios",
        title: "Laboratorios",
        on: true,
        validado: true,
      },
      {
        icon: null,
        value: "datosPdf",
        title: "Datos PDF",
        on: true,
        validado: true,
      },
    ];
  } else if (permiso && permiso[0]["radiologo"] > 0) {
    botonesDefault = [
      {
        icon: null,
        value: "estudiosGabinete",
        title: "Estudios de Gabinete",
        on: true,
        validado: true,
      },
      {
        icon: null,
        value: "datosPdf",
        title: "Datos PDF",
        on: true,
        validado: true,
      },
    ];
  } else if (permiso && permiso[0]["enfermero"] > 0) {
    botonesDefault = [
      {
        icon: null,
        value: "paraclinicos",
        title: "Paraclinicos",
        on: true,
        validado: true,
      },
      {
        icon: null,
        value: "datosPdf",
        title: "Datos PDF",
        on: true,
        validado: true,
      },
    ];
  }


  const getOrdenServicio = async () => {
    setLoading(true);
    if (idOrdenServicio) {
      let res = await Service.get(
        "orden-servicio?idOrdenServicio=" +
        idOrdenServicio +
        "&expand=evaluacion.signos" +
        ",evaluacion.notasEnfermeria" +
        ",evaluacion.notasEvolucion" +
        ",evaluacion.antecedente" +
        ",evaluacion.interrogatorio" +
        ",evaluacion.padecimiento" +
        ",evaluacion.exploracion" +
        ",evaluacion.plan" +
        ",evaluacion.diagnostico.diagnosticoEnfermedad.enfermedad" +
        ",evaluacion.paraclinicos" +
        ",evaluacion.laboratorios" +
        ",evaluacion.datospdf" +
        ",evaluacion.estudiosGabinete.archivosRadios" +
        ",evaluacion.antecedentesLaborales" +
        ",evaluacion.antecedentesPersonales" +
        ",evaluacion.accidentes" +
        ",evaluacion.comentarios.archivosAdjuntos" +
        ",detallePaciente" +
        ",evaluacion.audioValores"
      );
      setEvaluacion(res?.resultado[0]?.evaluacion);
      setDetallesPaciente(res?.resultado[0]?.detallePaciente);
    }
    setLoading(false);
  };

  const [botones, setBotones] = useState([...botonesDefault]);

  useEffect(() => {
    getOrdenServicio();
  }, [accion]);

  useEffect(() => {
    console.log(guardado)
  }, [guardado]);

  useEffect(() => {
    if (botones) {
      let falla = false;
      let botonesCount = 0;
      botones.forEach((boton) => {
        if (!falla) {
          if (boton.on) {
            botonesCount++;
            if (!boton.validado) {
              falla = true;
            }
          }
        }
      });
      if (botonesCount > 0) {
        setDisabled(falla);
      }
    }
  }, [botones]);

  const setDisabledByName = (name, validacion) => {
    if (botones) {
      let preBotones = [...botones];
      preBotones.map((boton) => {
        if (boton.value == name) {
          boton.validado = validacion;
        }
      });
      setBotones(preBotones);
    }
  };

  const finalizarEvaluacion = async () => {
    let res = await Service.post("evaluaciones/guardar", {
      idOrdenServicio,
      funcion: "finalizar",
    });
    if (res.detalle.estatus == 200) {
      showAlert({
        message: res.mensaje,
        severity: "success",
      });
      history.push("/clinicaNuevaVida");
    } else {
      showAlert({
        message: res.mensaje,
        severity: "error",
      });
    }
  };
  ////////////////////////////////////////////////////////////////////////
  //                           Funcions Def                             //
  ////////////////////////////////////////////////////////////////////////
  const switchCaseOptions = (boton) => {
    switch (boton?.value) {
      case "signosVitalesSomatometria":
        return (
          <SignosVitalesSomatometriaComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            setDisabledByName={setDisabledByName}
            signos={evaluacion ? evaluacion.signos : null}
          />
        );

      case "antecedentesLaborables":
        return (
          <AntecedentesLaborablesComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            setDisabledByName={setDisabledByName}
            antecedentesLaboral={evaluacion ? evaluacion.antecedentesLaborales : null}
            accidenteLaboral={evaluacion ? evaluacion.accidentes : null}
          />
        );

      case "antecedentesPersonales":
        return (
          <AntecedentesPersonalesComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            setDisabledByName={setDisabledByName}
            antecedentesPersonales={evaluacion ? evaluacion.antecedentesPersonales : null}
          />
        );

      case "interrogatorioPorAparatosSistemas":
        return (
          <InterrogatorioPorAparatosSistemasComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            setDisabledByName={setDisabledByName}
            interrogatorio={evaluacion ? evaluacion.interrogatorio : null}
          />
        );

      case "exploracionFisica":
        return (
          <ExploracionFisicaComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            setDisabledByName={setDisabledByName}
            exploracion={evaluacion ? evaluacion.exploracion : null}
          />
        );

      case "paraclinicos":
        return (
          <ParaclinicosComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            setDisabledByName={setDisabledByName}
            paraclinicos={evaluacion ? evaluacion.paraclinicos : null}
            audioValores={evaluacion ? evaluacion.audioValores : null}
            detallesPaciente={detallesPaciente ? detallesPaciente : null}
          />
        );

      case "laboratorios":
        return (
          <LaboratoriosComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            setDisabledByName={setDisabledByName}
            laboratorios={evaluacion ? evaluacion.laboratorios : null}
          />
        );

      case "estudiosGabinete":
        return (
          <EstudiosGabineteComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            setDisabledByName={setDisabledByName}
            estudiosGabinete={evaluacion ? evaluacion.estudiosGabinete : null}
          />
        );

      case "comentariosConclusiones":
        return (
          <ComentariosConclusionesComponent
            idEvaluacion={idEvaluacion}
            eva={evaluacion ? evaluacion?.idEvaluacion : null}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            audio={evaluacion ? evaluacion.audioValores ? evaluacion.audioValores : null : null}
            setDisabledByName={setDisabledByName}
            comentarios={evaluacion ? evaluacion.comentarios : null}
            estudiosGabinete={evaluacion ? evaluacion.estudiosGabinete : null}
            laboratorios={evaluacion ? evaluacion.laboratorios : null}
          />
        );

      case "datosPdf":
        return (
          <DatosPdfComponent
            idEvaluacion={idEvaluacion}
            idOrdenServicio={idOrdenServicio}
            setGuardado={setGuardado}
            fichaIdentificacion={detallesPaciente && detallesPaciente}
            datospdf={evaluacion ? evaluacion.datospdf : null}
          />
        );

      default:
        return <></>;
    }
  };
  ////////////////////////////////////////////////////////////////////////
  //                            Hooks Def                               //
  ////////////////////////////////////////////////////////////////////////

  return (
    <>
      <PageHeader
        history={history}
        title={(!editing ? "NUEVA" : "EDITAR") + " EVALUACIÓN MEDICA"}
        links={[
          {
            to: "/clinicaNuevaVida",
            icon: <FaClinicMedical fontSize="medium" />,
            label: "Clinica Nueva Vida",
          },
          {
            to: !editing
              ? "/clinicaNuevaVida/nuevaConsulta"
              : "/clinicaNuevaVida/editar/" + id,
            icon: !editing ? <Add /> : <Edit />,
            label: !editing
              ? "Nueva"
              : "Editar - " + capitalizeFirst("EVALUACION"),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid container spacing={1}>
        <FichaIdentificacion idOrdenServicio={idOrdenServicio} />
        <Grid
          container
          spacing={1}
          component={Paper}
          className={classes.paper}
          style={{ marginTop: 10 }}
        >
          {/* {botones &&
            botones.map((boton, index) => (
              <FormControlLabel
                control={
                  <Switch
                    value={boton.on}
                    onChange={(e) => {
                      let preBotones = [...botones];
                      preBotones[index].on = e.target.checked;
                      setBotones(preBotones);
                      setAccion(null);
                    }}
                  />
                }
                label={boton.title}
              />
            ))} */}
        </Grid>
        <Grid
          container
          spacing={1}
          component={Paper}
          className={classes.paper}
          style={{ marginTop: 10 }}
        >
          {botones &&
            botones.map(
              (boton, index) =>
                boton.on && (
                  <Grid
                    item
                    xs={6}
                    md={4}
                    lg={3}
                    xl={2}
                    key={index}
                    style={{ display: "flex", padding: "5px" }}
                  >
                    <LoadingButton
                      variant="contained"
                      onClick={async () => {
                        if (!guardado) {
                          const text =
                            "NO HAS GUARDADO ¿QUIERES CONTINUAR SIN GUARDAR?".toUpperCase();
                          const res = await Swal.fire({
                            title: text,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#25607A",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Si",
                            cancelButtonText: "Cancelar",
                          });
                          if (res.isConfirmed) {
                            setGuardado(false)
                            setAccion(boton)
                          }
                        } else {
                          setGuardado(false)
                          setAccion(boton)
                        }
                      }
                      }
                      size="large"
                      style={{
                        color: "#fff",
                        width: "100%",
                        backgroundColor: "#1C5E7B",
                      }}
                      startIcon={boton.icon}
                    >
                      <p style={{ padding: "5px" }}>{boton.title}</p>
                    </LoadingButton>
                  </Grid>
                )
            )}
        </Grid>
        {
          !loading ?
            <>
              {switchCaseOptions(accion)}
            </>
            :
            <Grid
              container
              spacing={1}
              component={Paper}
              className={classes.paper}
              style={{ marginTop: 10, display: "flex", flexDirection: "row", justifyContent: "center" }}
            >
              <CircularProgress />
            </Grid>

        }
        {(admin === true || (permiso && permiso[0]['doctor'] > 0)) && (
          <Grid
            container
            spacing={1}
            component={Paper}
            className={classes.paper}
            style={{ marginTop: 10 }}
          >
            <LoadingButton
              variant="contained"
              onClick={(e) => {
                finalizarEvaluacion();
              }}
              size="large"
              style={{
                color: "#fff",
                width: "100%",
              }}
              startIcon={<Save />}
            >
              FINALIZAR EVALUACION
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Detail;
