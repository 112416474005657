/* eslint-disable no-unused-vars */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////

const CircleTransition = ({
    icon,
    iconActive,
    color="#1e5e7b",
    active = false,
    ...props
}) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const defaultStyles = {
        background: "#FFF",
        height: '50px',
        width: '50px',
        borderRadius: '100%',
        border:active?'25px solid #1e5e7b':'4px solid #1e5e7b',
        boxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        WebkitBoxSizing: 'border-box',
        transition: 'border .5s',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        fontSize:'1.5rem',
        color:active?'#FFF':color
    };
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////

    return (
        <>
            <div style={defaultStyles}>
                {active?iconActive:icon}
            </div>
        </>
    );
}
export default CircleTransition;