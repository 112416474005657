import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { 
  Grid, 
  Breadcrumbs, 
  Paper, 
  TextField, 
  Typography,
  Chip,
} from "@mui/material";
import { Home } from "@mui/icons-material";
import { baseStyles,breadCrumbsStyles } from "../../utils";
import { useGet } from "../../hooks/useGet";
import { usePost } from "../../hooks/usePost";
import FullScreenLoader from "../../components/FullScreenLoader";
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAlert } from "./../../hooks/useAlert";

const useStyles = baseStyles();
const StylesBreadCrumb = breadCrumbsStyles()(Chip);


const Detail = ({ match }) => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const history = useHistory();
  const editing = match.params.idFactura !== undefined;
  const [errores, cargandoBoton, peticion] = usePost();
  
  const [propiedades, setPropiedades] = useState({ 
    nombre: "",
    descripcion: "",
    precio: "",
    urlFoto: "",
    idCategoria: ""
  });

  // const [categorias] = useGet({
  //   initUrl: 'categoria/index',asdasdasd
  //   params: {},
  //   action: true,
  // });
  
  const [factura, cargando] = useGet({
    initUrl: "factura/index",
    params: { 
      idFactura : match.params.idFactura
    },
    action: editing ? true : false,
  });


  useEffect(() => {
    if(editing){
      let data = factura && factura.resultado && factura.resultado[0];
      setPropiedades(data);
    
    }
  }, [factura,editing]);

  const guardar = async() => {
    peticion("factura/guardar",propiedades,"/facturas");
  }

  if(editing && cargando) return <FullScreenLoader />

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.typographyModified} gutterBottom style={{textAlign:"center"}}>
        {editing ? `EDITANDO FACTURA` : `NUEVO FACTURA`} 
      </Typography>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <StylesBreadCrumb
                component="a"
                label="Inicio"
                icon={<Home fontSize="small" />}
                onClick={() => history.push("/")} 
              />
              <StylesBreadCrumb 
                component="a" 
                label="facturas" 
                onClick={() => history.push("/facturas")} 
              />
              <StylesBreadCrumb 
                component="a" 
                label={editing ? `Editar Factura: ${propiedades.descripcion}` : `Crear Factura`}
              />
            </Breadcrumbs>
            <br></br>
          </Grid>
          <Grid item xs={12}>
           <div><strong>{"INFORMACIÓN DE LA FACTURA"}</strong></div>
           <Divider />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={6}>
            <TextField
              label="Descripción De La Factura"
              variant="outlined"
              fullWidth
              helperText={errores?.descripcion}
              error={Boolean(errores?.descripcion)}
              size="small"
              onChange={(e) => setPropiedades(prevState => ({
                 ...prevState,
                 ...prevState.descripcion, 
                 descripcion: e.target.value
                })) 
              }
              inputProps={{
                value: propiedades.descripcion,
              }}
            />
          </Grid>
       
          <Grid item xs={12} sm={12} md={12} xl={6}>
            <TextField
              label="Unidad"
              variant="outlined"
              fullWidth
              helperText={errores?.unidad}
              error={Boolean(errores?.unidad)}
              size="small"
              onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades, 
                  unidad: e.target.value
                })) 
              }
              inputProps={{
                value: propiedades.unidad,
              }}
            />
          </Grid>

          <Grid item xs={12} xl={12}>
            <Grid container justifyContent="flex-end">
              <LoadingButton 
                loading={cargandoBoton}
                variant="contained" 
                onClick={guardar}
                size="large"
                className={classes.successButton}
              >
                Generar Factura
              </LoadingButton>
          </Grid>
        </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Detail;