import { Grid, TextField } from "@mui/material"
import React from "react"

const fichaIdentificacion = ({ datos, setDatos, ...props }) => {
    return (
        <>
            <Grid item xs={12} sx={{ margin: "20px 0", color: "#FFFFFF", background: "black", padding: "10px" }}>
                FICHA DE IDENTIFICACION
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"nombre".toLocaleUpperCase()}
                    value={datos?.fichaIdentificacion?.nombre}
                    placeholder={"nombre".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, fichaIdentificacion: { ...datos?.fichaIdentificacion, nombre: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"fecha del examen".toLocaleUpperCase()}
                    value={datos?.fichaIdentificacion?.fechaExamen}
                    placeholder={"fecha del examen".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, fichaIdentificacion: { ...datos?.fichaIdentificacion, fechaExamen: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"fecha de nacimiento".toLocaleUpperCase()}
                    value={datos?.fichaIdentificacion?.fechaNacimiento}
                    placeholder={"fecha de nacimiento".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, fichaIdentificacion: { ...datos?.fichaIdentificacion, fechaNacimiento: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"edad".toLocaleUpperCase()}
                    value={datos?.fichaIdentificacion?.edad}
                    placeholder={"edad".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, fichaIdentificacion: { ...datos?.fichaIdentificacion, edad: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"estado civil".toLocaleUpperCase()}
                    value={datos?.fichaIdentificacion?.estadoCivil}
                    placeholder={"estado civil".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, fichaIdentificacion: { ...datos?.fichaIdentificacion, estadoCivil: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"sexo".toLocaleUpperCase()}
                    value={datos?.fichaIdentificacion?.sexo}
                    placeholder={"sexo".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, fichaIdentificacion: { ...datos?.fichaIdentificacion, sexo: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"télefono".toLocaleUpperCase()}
                    value={datos?.fichaIdentificacion?.telefono}
                    placeholder={"télefono".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, fichaIdentificacion: { ...datos?.fichaIdentificacion, telefono: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"lugar de nacimiento".toLocaleUpperCase()}
                    value={datos?.fichaIdentificacion?.lugarNacimiento}
                    placeholder={"lugar de nacimiento".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, fichaIdentificacion: { ...datos?.fichaIdentificacion, lugarNacimiento: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"lugar de Residencia".toLocaleUpperCase()}
                    value={datos?.fichaIdentificacion?.lugarResidencia}
                    placeholder={"lugar de Residencia".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, fichaIdentificacion: { ...datos?.fichaIdentificacion, lugarResidencia: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"dirección".toLocaleUpperCase()}
                    value={datos?.fichaIdentificacion?.direccion}
                    placeholder={"dirección".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, fichaIdentificacion: { ...datos?.fichaIdentificacion, direccion: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"ine".toLocaleUpperCase()}
                    value={datos?.fichaIdentificacion?.ine}
                    placeholder={"ine".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, fichaIdentificacion: { ...datos?.fichaIdentificacion, ine: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"aspirante al puesto de".toLocaleUpperCase()}
                    value={datos?.fichaIdentificacion?.aspirante}
                    placeholder={"aspirante al puesto de".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, fichaIdentificacion: { ...datos?.fichaIdentificacion, aspirante: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"numero de seguro social".toLocaleUpperCase()}
                    value={datos?.fichaIdentificacion?.nss}
                    placeholder={"numero de seguro social".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, fichaIdentificacion: { ...datos?.fichaIdentificacion, nss: e.target.value } }) }}
                />
            </Grid>
        </>
    )
}

export default fichaIdentificacion