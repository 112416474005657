////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useModels } from '../../../hooks/useModels';
import { NavLink } from 'react-router-dom';
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import {
  Grid,
  MenuItem,
  IconButton,
  FormControlLabel,
  Switch,
  FormControl,
  Select,
  InputLabel,
  Menu,
} from '@mui/material';
import Hospitalito from "../../../pdf/Hospitalito";
import TablePageLayout from '../../../components/TablePageLayout';
import RangePicker from '../../../components/RangePicker';
import TablePagination from '../../../components/TablePagination';
import LoadingButton from '@mui/lab/LoadingButton';
import InputRegex from '../../../components/InputRegex';
import Swal from 'sweetalert2';
import moment from 'moment';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { baseStyles } from '../../../utils';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import HandshakeIcon from '@mui/icons-material/Handshake';
import { Add, Delete, Edit, Search } from '@mui/icons-material';
import { MdPictureAsPdf } from 'react-icons/md';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { RiFileExcel2Fill } from 'react-icons/ri';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
import { baseUrl, baseUrlPublica } from '../../../utils/variables';
const useStyles = baseStyles();


const EvaluacionesHospitalito = () => {
  ////////////////////////////////////////////////////////////////////////
  //                              Vars                                  //
  ////////////////////////////////////////////////////////////////////////
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const [perPage, setPerPage] = useState(10);
  const [openMenuRow, setOpenMenuRow] = useState(null);
  const [pagina, setPagina] = useState(1);
  const [admin] = useLocalStorage('admin', null);
  const { user, permiso } = useAuth();
  const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");

  const parametrosInicialesMemo = useMemo(
    () => ({
      name: 'evaluaciones',
      extraParams: { idSucursal: 2 },
      expand: 'ordenServicio.detallePaciente,ordenServicio.detalleConvenio,ordenServicio.ordenServicioDoctores.doctor,ordenServicio.ordenServicioDoctores.detalleDoctores,entradaInventario,estudiosGabinete.archivosRadios,laboratorios,audioValores,comentarios,signos,antecedentesLaborales,antecedentesPersonales,padecimiento,interrogatorio,exploracion,paraclinicos,diagnostico,notasEnfermeria,antecedente,plan,diagnostico.diagnosticoEnfermedad.enfermedad,ordenServicio.usuario.detalleDoctor,notasEvolucion',
    }),
    []
  );
  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({ ...parametrosInicialesMemo });

  const [propiedades, setPropiedades] = useState({
    nombreConvenio: '',
    fechaInicio: moment(new Date()).startOf('month').format('YYYY/MM/DD'),
    fechaFinal: moment(new Date()).format('YYYY/MM/DD'),
  });

  ////////////////////////////////////////////////////////////////////////
  //                           Funcions Def                             //
  ////////////////////////////////////////////////////////////////////////
  const onDeleteClicked = async (mensaje, type, id) => {
    try {
      setOpenMenuRow(false);
      await Swal.fire({
        title: type,
        text: mensaje,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34AA44',
        cancelButtonColor: '#E72C33',
        confirmButtonText: 'Si, deseo Continuar.',
      }).then((result) => {
        if (result.isConfirmed) {
          let params = {};
          params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: pagina, limite: perPage },
          };
          deleteModel(id, type, params);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSearchClicked = async eliminado => {
    let params = {};
    if (eliminado != null) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
      };
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage },
      };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async page => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async per => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };
  ////////////////////////////////////////////////////////////////////////
  //                            Hooks Def                               //
  ////////////////////////////////////////////////////////////////////////

  return (
    <>
      <TablePageLayout
        title='Evaluaciones Hospitalito'
        model='evaluaciones'
        history={history}
        links={[
          {
            label: 'Evaluaciones Hospitalito',
            to: '/evaluaciones',
            icon: <HandshakeIcon fontSize='small' />
          },
        ]}
        SearchComponents={
          <>
            <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
              <RangePicker
                style={{ width: '100%' }}
                value={[new Date(propiedades.fechaInicio), new Date(propiedades.fechaFinal)]}
                format='dd/MM/yyyy'
                placeholder='Seleccione el rango de fechas'
                onChange={e => {
                  setPropiedades({
                    ...propiedades,
                    fechaInicio: moment(e[0]).format('yyyy/MM/DD'),
                    fechaFinal: moment(e[1]).format('yyyy/MM/DD')
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center" }}>
              <InputRegex
                fullWidth
                size='samall'
                label='Nombre del paciente'
                value={propiedades.nombreCompleto}
                onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  nombreCompleto: e.target.value,
                }))}
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ display: 'flex' }}>
              <LoadingButton
                variant='contained'
                fullWidth
                onClick={() => onSearchClicked(null)}
                loading={modelsLoading && true}
                style={{
                  float: 'right',
                  color: 'whitesmoke',
                }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>
          </>
        }
        DisableComponents={
          <>
            <Grid item xs={8}>
              <FormControlLabel
                className={classes.switchColor}
                control={
                  <Switch
                    disabled={modelsLoading && true}
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        eliminado: e.target.checked ? 1 : 0,
                      }));
                      onSearchClicked(e.target.checked ? 1 : 0);
                    }}
                  />
                }
                label='Ver Deshabilitados'
              />
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', }}>
              <Grid item xs={2} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                <IconButton color='error' aria-label='upload picture' component='span'>
                  <a className={classes.btnPdf} target='_blank' rel='noreferrer'
                    href={`baseUrlpdf/paquetes?${'admin=' + (admin ? 1 : 0)}
                        ${'&uid=' + user?.idUsuario}
                        ${'&nombrePaquete=' + propiedades.nombrePaquete}`}>
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid item xs={2} style={{ isplay: 'flex', flexDirection: 'row', alignItems: 'center', }} >
                <IconButton color='warning' aria-label='upload picture' component='span'>
                  <a className={classes.btnExcel} target='_blank' rel='noreferrer' href={`baseUrlexcel/paquetes?
                        ${'admin=' + (admin ? 1 : 0)}
                        ${'&uid=' + user?.idUsuario}
                        ${'&nombrePaquete=' + propiedades.nombrePaquete}`}>
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <TablePagination
            nombrePermiso='evaluaciones'
            permiso={permiso}
            header={[]}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={'Renglones por página'}
            extraRows={[
              {
                prop: 'creado',
                name: 'Fecha de Evaluación',
                sortable: false,
                cell: (row, index) => (
                  <p key={index}>{moment(row.creado).format('DD/MM/yyy')}</p>
                )
              },
              {
                prop: 'nombreCompleto',
                name: 'Nombre Completo del Paciente',
                cell: (row, index) => (
                  <p key={index}>{row.ordenServicio.detallePaciente.nombreCompleto}</p>
                )
              },
              {
                prop: 'nombreConvenio',
                name: 'Empresa',
                cell: (row, index) => (
                  <p key={index}>{row.ordenServicio?.detalleConvenio?.nombreConvenio}</p>
                )
              },
              {
                prop: 'doctor',
                name: 'Doctor',
                cell: (row, index) => (
                  <p key={index}>{row.ordenServicio?.cita?.agenda?.doctor.nombreCompleto}</p>
                )
              },
              {
                prop: 'pdf',
                name: 'Exportacion PDF',
                cell: (row, index) => (
                  <IconButton color='error' aria-label='upload picture' component='span'
                    disabled={row?.ordenServicio?.status != "FINALIZADO"}
                    onClick={async e => {
                      let pdf = await Hospitalito(row);
                      pdf = await pdf;
                      window.open(window.URL.createObjectURL(pdf), "_blank");
                    }}>
                    <MdPictureAsPdf />
                  </IconButton>
                )
              },
              {
                prop: 'acciones',
                name: 'Acciones',
                sortable: false,
                cell: (row, index) => (permiso && (permiso[0]['evaluaciones'] & 4) === 4 || (permiso[0]['evaluaciones'] & 8) === 8) && (
                  <div>
                    <IconButton onClick={(event) => { setOpenMenuRow(index); setAnchorEl(event.currentTarget) }}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu anchorEl={anchorEl} keepMounted open={index === openMenuRow} onClose={() => setOpenMenuRow(null)}>
                      {(admin === true || (permiso && permiso[0]['evaluaciones'] & 4) === 4) && (row.eliminado == null) && (
                        <NavLink exact to={(`hospitalito/${row.idOrdenServicio}`)} style={{ color: '#000', textDecoration: 'none' }}>
                          <MenuItem aria-label='editar'
                            onClick={() => {
                              history.push(`hospitalito/${row.idOrdenServicio}`, { match: row.idOrdenServicio });
                            }}>
                            <Edit style={{ paddingRight: 5 }} />
                            Editar
                          </MenuItem>
                        </NavLink>
                      )}
                      {(admin === true || (permiso && permiso[0]['evaluaciones'] & 8) === 8) && (
                        row?.eliminado === null ? (
                          <MenuItem
                            onClick={() => onDeleteClicked(`Desea Deshabilitar el Evaluaciones Hospitalito ${row.nombreConvenio}`, 'Deshabilitar', row.idOrdenServicio)}
                          >
                            <Delete style={{ paddingRight: 5 }} />
                            Deshabilitar
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={() => onDeleteClicked(`Desea Habilitar el Evaluaciones Hospitalito ${row.nombreConvenio}`, 'Habilitar', row.idOrdenServicio)}
                          >
                            <RestoreFromTrashIcon style={{ paddingRight: 5 }} />
                            Habilitar
                          </MenuItem>
                        )
                      )}
                    </Menu>
                  </div>
                ),
              },
            ]}
          />
        }
      />
    </>
  );
}
export default EvaluacionesHospitalito;