/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useModel } from "../../hooks/useModel";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { useAlert } from "../../hooks/useAlert";
import { useModels } from "../../hooks/useModels";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import PageHeader from "../../components/PageHeader";
import {
    Grid,
    Paper,
    FormControl,
    Divider,
    TableCell,
    CircularProgress,
    TableRow,
    TableHead,
    TableBody,
    IconButton,
    Table,
    InputLabel,
    Switch,
    Select,
    MenuItem,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import TablePagination from "../../components/TablePagination";
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";
import Loading from "../../components/FullScreenLoader";
import AsyncAutocompleteSearchRework from "../../components/AsyncAutocompleteSearchRework";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { tableCellStyles, tableRowStyles, entradasStyle, capitalizeFirst } from '../../utils';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Edit } from "@mui/icons-material";
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from "@mui/icons-material/Save";
import PercentIcon from '@mui/icons-material/Percent';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));
const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const useEntradasStyle = entradasStyle();

const ComisionDetail = ({ match, history, dialog }) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const id = match.params.id;
    const header = [
        {
            name: "Nombre Sucursal",
            prop: "nombreSucursal",
        }
    ];
    const classes = useStyles();
    const { showAlert } = useAlert();
    const entradas = useEntradasStyle();
    const [usuario, setUsuario] = useState({});
    const [clear, setClear] = useState(false);
    const [clearConvenio, setClearConvenio] = useState(false);
    const [errorUsuario, setErrorUsuario] = useState(false);
    const [reload, setReload] = useState(true);
    const [loadingUsuario, setLoadingUsuario] = useState(false);
    const [newObjSucursales, setNewObjSucursales] = useState([])
    const editing = !!id;
    const [admin] = useLocalStorage("admin", null);
    const { permiso } = useAuth();
    const [usuarios, setUsuarios] = useState([]);
    const [convenio, setConvenio] = useState({});
    const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
    const [propiedades, setPropiedades] = useState({
        comision: "5",
        comentario: "",
        idSucursal,
    });

    const paramsMemo = useMemo(
        () => ({
            ordenar: "nombreCompleto.asc",
        }),
        []
    );

    const paramsMemoU = useMemo(() => ({ 
        ordenar: "nombreConvenio.asc",
        expand: "convenio.nombreConvenio",
       }), []
       );
 
    const parametrosInicialesMemo = useMemo(
        () => ({
            name: "sucursal",
            ordenar: "idSucursal.asc",
        }),
        []
    );
    const [models] =
        useModels({ ...parametrosInicialesMemo });

    const { model, modelLoading, modelError, updateModel, updateModelLoading } =
        useModel({
            name: "comision",
            expand: "comisionSucursal.sucursal,comisionUsuario.usuario",
            id,
            redirectOnPost: true,
        });

        
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const onGuardar = async () => {
        let respaldo = [];
        let sucursalesFiltered = [];
        if (editing) {
            model.comisionSucursal.map(sucursal => {
                if (!models.some(s => s.idSucursal == sucursal.idSucursal)) {
                    respaldo.push({ ...sucursal.sucursal, active: true })
                }
            });
            let sucursalesToFilter = newObjSucursales.concat(respaldo);
            sucursalesToFilter.map(sucursal => {
                if (sucursal.active) {
                    sucursalesFiltered.push(sucursal);
                }
            });
        } else {
            let sucursalesToFilter = newObjSucursales;
            sucursalesToFilter.map(sucursal => {
                if (sucursal.active) {
                    sucursalesFiltered.push(sucursal);
                }
            });
        }
        if (sucursalesFiltered.length === 0) {
            showAlert({ message: 'Seleccione al menos una sucursal', severity: "warning", });
            return;
        }
        if (usuarios.length === 0) {
            setErrorUsuario(true);
            showAlert({ message: 'Seleccione al menos un usuario', severity: "warning", });
            return;
        } else {
            setErrorUsuario(false);
        }

        let body = { ...propiedades, comisionSucursal: sucursalesFiltered, usuario: usuarios};
        updateModel(body, true, "comisiones");
    };

    const agregarUsuario = () => {
        setClear(true);
        window.setInterval(() => { setClear(false) }, 10);
        if (usuario.idUsuario != null) {
            const repetido = usuarios.some(e => e.idUsuario === usuario.idUsuario);
            if (repetido) {
                showAlert({
                    message: "Este Usuario ya fue agregado",
                    severity: "error",
                });
                setUsuario(null);
            } else {
                let copyUsuarios = [...usuarios];
                copyUsuarios.push(usuario);
                setUsuarios(copyUsuarios);
                setUsuario(null);
            }
        }
    }
    const onDropUsuario = async (id, nombre) => {
        if (id) {
            const res = await Swal.fire({
                title: "Borrar",
                icon: "warning",
                text: `¿Estás seguro de que deseas borrar el Usuario seleccionado "${capitalizeFirst(nombre.toLowerCase())}"?`,
                showCancelButton: true,
                confirmButtonColor: "#25607A",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, deseo Continuar!",
                cancelButtonText: "Cancelar",
            });
            if (res.isConfirmed) {
                showAlert({
                    message: "Espera un momento.....",
                    severity: "warning",
                });
                let posi;
                const copyUsuarios = [...usuarios];
                usuarios.map((e, index) => {
                    if (e.idUsuario === id) {
                        posi = index;
                    }
                });
                copyUsuarios.splice(posi, 1);
                setUsuarios(copyUsuarios);
                setReload(true);
                showAlert({
                    message: "Usuario borrado con éxito.",
                    severity: "success",
                });
            } else {
                setReload(true);
            }
        }
    };
    const getFilterArraySucursales = (array) => {
        return array.map((e) => {
            if (editing) {
                if (model) {
                    if (model.comisionSucursal.some(s => s.idSucursal == e.idSucursal)) {
                        return { idSucursal: e.idSucursal, nombreSucursal: e?.nombreSucursal, eliminado: e?.eliminado, active: true }
                    } else {
                        return { idSucursal: e.idSucursal, nombreSucursal: e?.nombreSucursal, eliminado: e?.eliminado, active: false }
                    }
                } else {
                    return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
                }
            } else {
                return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
            }
        })
    }

    
    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        let mounted = true;
        if (mounted && model) {
            let PreLoadUsuarios = [];
            model.comisionUsuario.map(u => PreLoadUsuarios.push(u.usuario));
            setPropiedades(model);
            setUsuarios(PreLoadUsuarios);
            const newObj = getFilterArraySucursales(models);
            setNewObjSucursales(newObj);
        }
        return () => {
            mounted = false;
            setPropiedades({
                comision: "",
                comentario: "",
                idSucursal,
            });
        };
    }, [model]);

    useEffect(() => {
        const newObj = getFilterArraySucursales(models);
        setNewObjSucursales(newObj);
    }, [models]);

    const setidUsuarioCallback = useCallback(
        (e, v) => {
            if (v !== "" && v) {
                setUsuario(v);
            } else {
                setUsuario("");
            }
        },
        []
    );

    const setConvenioCallback = useCallback((e, v) => {
        if (v !== "" && v) {
            setConvenio(v);
        } else {
            setConvenio(null);
        }
      }, [setConvenio]);
    

    if (modelLoading) return <Loading />;

    return (
        <>
            <PageHeader
                history={history}
                title={(!editing ? "NUEVA" : "EDITAR") + " COMISION"}
                links={[
                    {
                        to: "/comisiones",
                        icon: <PercentIcon fontSize="small" />,
                        label: "Comision",
                    },
                    {
                        to: !editing ? "/comision/nuevo" : "/comision/editar/" + id,
                        icon: !editing ? <Add /> : <Edit />,
                        label: !editing
                            ? "Nueva"
                            : "Editar - " +
                            capitalizeFirst(
                                propiedades?.comision.toLowerCase()
                            ),
                    },
                ]}
                editing={editing}
                dialog={dialog}
            />
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <div><strong>{"INFORMACIÓN DE LA COMISION"}</strong></div>
                    <Divider />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <InputRegex
                        label="Comentario"
                        variant="standard"
                        fullWidth
                        size="medium"
                        typeInput='text'
                        value={propiedades.comentario}
                        onChange={(e) =>
                            setPropiedades((prevState) => ({
                                ...prevState,
                                ...prevState.propiedades,
                                comentario: e.target.value,
                            }))
                        }
                    />
                </Grid>
                
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="comisionSelect">Comision</InputLabel>
                        <Select
                            labelId="comisionSelect"
                            id="comisionSelectId"
                            value={propiedades.comision}
                            label="Comision"
                            onChange={(e) =>
                                setPropiedades((prevState) => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    comision: e.target.value,
                                }))
                            }
                        >
                            <MenuItem value={"5"}>5%</MenuItem>
                            <MenuItem value={"10"}>10%</MenuItem>
                            <MenuItem value={"15"}>15%</MenuItem>
                            <MenuItem value={"20"}>20%</MenuItem>
                            <MenuItem value={"25"}>25%</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                
            </Grid>
            
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <div><strong>{"USUARIOS DE LA COMISION"}</strong></div>
                    <Divider />
                </Grid>
                <Grid item xs={10}>
                    <AsyncAutocompleteSearchRework
                        label="Usuarios"
                        labelProp="nombreCompleto"
                        name="usuario"
                        onChange={setidUsuarioCallback}
                        clearInput={clear}
                        extraParams={paramsMemo}
                        error={errorUsuario}
                    />
                </Grid>
                <Grid item xs={2} style={{ display: 'flex' }}>
                    <LoadingButton
                        disabled={usuario?.idUsuario == null}
                        variant="contained"
                        color="primary"
                        onClick={e => { agregarUsuario() }}
                        loading={loadingUsuario}
                        style={{ color: "#fff", width: '100%', height: '70%' }}
                    >
                        <Add />Agregar Usuario
                    </LoadingButton>
                </Grid>
                {(usuarios.length > 0) && (
                    <>
                        <Grid item xs={12}>
                            <Table className={entradas.tableEntradas}>
                                <TableHead>
                                    <TableRowModify>
                                        <TableCellModify> # </TableCellModify>
                                        <TableCellModify> Nombre Completo </TableCellModify>
                                        <TableCellModify> Correo </TableCellModify>
                                        <TableCellModify> </TableCellModify>
                                    </TableRowModify>
                                </TableHead>
                                <TableBody>
                                    {reload ? usuarios.map((u, index) => {
                                        return (
                                            <TableRowModify key={index}>
                                                <TableCellModify> {index + 1} </TableCellModify>
                                                <TableCellModify> {u?.nombreCompleto} </TableCellModify>
                                                <TableCellModify> {u?.correo} </TableCellModify>
                                                <TableCellModify>
                                                    <IconButton
                                                        onClick={e => {
                                                            onDropUsuario(u.idUsuario, u.nombreCompleto);
                                                            setReload(false);
                                                        }}
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </TableCellModify>
                                            </TableRowModify>
                                        )
                                    }) : (
                                        <TableRowModify>
                                            <TableCellModify></TableCellModify>
                                            <TableCellModify></TableCellModify>
                                            <TableCellModify> <CircularProgress /> </TableCellModify>
                                            <TableCellModify></TableCellModify>
                                        </TableRowModify>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12}>
                    <strong>{"SUCURSALES"}</strong>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <TablePagination
                        header={header}
                        data={newObjSucursales}
                        extraRows={[
                            {
                                prop: "accion",
                                name: "Acción",
                                sortable: false,
                                cell: (row, index) =>
                                    <Switch
                                        disabled={row && (row?.eliminado === null && row?.active === null) ? true : false}
                                        checked={row?.active}
                                        onChange={({ target }) => {
                                            newObjSucursales[index].active = target.checked;
                                            setNewObjSucursales([...newObjSucursales]);
                                        }}
                                    />,
                            },
                        ]}
                    />
                </Grid>


            </Grid>
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                        <LoadingButton
                            disabled={
                                modelLoading || updateModelLoading ||
                                    admin ||
                                    (permiso && permiso[0]["comisiones"] & 2) === 2
                                    ? false
                                    : true
                            }
                            loading={modelLoading || updateModelLoading}
                            variant="contained"
                            size="medium"
                            onClick={e => { onGuardar() }}
                            startIcon={<SaveIcon />}
                            style={{ color: "#fff" }}
                        >
                            {(!editing ? "GUARDAR" : "MODIFICAR")}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
export default ComisionDetail;