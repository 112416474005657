import React from 'react';
import { Detector } from 'react-detect-offline';
import {useAlert} from "./../hooks/useAlert";

export default function InternetDetector() {
    const { showAlert } = useAlert();

	return (
		<Detector
			onChange={hasConnection => !hasConnection && showAlert({
                message: "Hay problemas de conexíon. Por favor, verifique su conexión a internet.",
                severity: "error",
				time: 6000
              })}
			render={() => null}
		/>
	);
}