import React from 'react'
import { Page, Document, Text, StyleSheet, Image, pdf, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    page: {
        display: "flex",
        flexDirection: 'column',
        backgroundColor: '#FCFEFF',
        padding: "20px",
    },
    view: {
        width: "100%",
    },
    rows: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
    },
})

const getCampos = (_datapdf, _data) => {
    let arrayInterrogatorio = [
        [
            "aparatoDigestivo",
            "aparatoRespiratorio",
        ],
        [
            "aparatoUrogenital",
            "esferaNeuropsiquiatrica",
        ],
        [
            "organosDeLosSentidos",
            "pielYAnexos",
        ],
        [
            "sintomasGenerales",
            "sistemaEndrocrino",
        ],
        [
            "sistemaMusculoesqueletico",
        ],
    ];
    switch (_data) {
        case "interrogatorio":
            return (
                <>
                    <div style={{ width: "100%", margin: "15px 0", fontSize: "15px", backgroundColor: "black", color: "white", padding: "5px" }}>
                        <Text>INTERROGATORIO POR APARATOS Y SISTEMAS</Text>
                    </div>
                    {
                        arrayInterrogatorio?.map(duo => {
                            return (
                                <>
                                    <View wrap={false} style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        height: "auto",
                                    }}>
                                        {
                                            duo?.map(apartadoInterrogatorio => {
                                                return (
                                                    <div style={{ width: "50%", padding: "10px" }}>
                                                        <div style={{ border: "1px solid black" }}>
                                                            <div style={{
                                                                width: "100%",
                                                                backgroundColor: "#666666",
                                                                color: "white"
                                                            }}>
                                                                <Text style={{
                                                                    color: "white",
                                                                    fontSize: "10px"
                                                                }}>{(apartadoInterrogatorio.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + apartadoInterrogatorio.replace(/([A-Z])/g, " $1").slice(1)).toLocaleUpperCase()}</Text>
                                                            </div>
                                                            {
                                                                Object.keys(_datapdf[_data][apartadoInterrogatorio])?.map(datoInterrogatorio => {
                                                                    return (
                                                                        <div style={{
                                                                            borderTop: "1px solid black",
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                        }}>
                                                                            <div style={{
                                                                                width: "50%",
                                                                            }}><Text style={{ fontSize: "7px" }}>{(datoInterrogatorio.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + datoInterrogatorio.replace(/([A-Z])/g, " $1").slice(1)).toLocaleUpperCase()}</Text></div>
                                                                            <div style={{
                                                                                width: "50%",
                                                                                borderLeft: "1px solid black"
                                                                            }}><Text style={{ fontSize: "7px" }}>{_datapdf[_data][apartadoInterrogatorio][datoInterrogatorio]}</Text></div>
                                                                        </div >
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </View>

                                </>
                            )
                        })
                    }
                </>
            )
        case "fichaIdentificacion":
            let ficha = _datapdf[_data];
            return (
                <>
                    <View style={{ width: "100%" }}>
                        <div style={{ width: "100%", margin: "15px 0", fontSize: "15px", backgroundColor: "black", color: "white", padding: "5px" }}><Text>FICHA DE IDENTIFICACIÓN</Text></div>
                        <View wrap={false} style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            height: "auto",
                        }}>
                            <div style={{ width: "50%", padding: "10px" }}>
                                <div style={{ border: "1px solid black" }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>NOMBRE</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{ficha?.nombre}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>EDAD</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{ficha?.edad}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>SEXO</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{ficha?.sexo}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>LUGAR DE NACIMIENTO</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{ficha?.lugarNacimiento}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>LUGAR DE RESIDENCIA</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{ficha?.lugarResidencia}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>DIRECCIÓN</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{ficha?.direccion}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>NUM IDENTIFICACION OFICIAL</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{ficha?.ine}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>ASPIRANTE AL PUESTO SOLICITADO</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{ficha?.aspirante}</Text></div>
                                    </div >
                                </div>
                            </div>
                            <div style={{ width: "50%", padding: "10px" }}>
                                <div style={{ border: "1px solid black" }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>FECHA DEL EXAMEN</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{ficha?.fechaExamen}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>FECHA DE NACIMIENTO</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{ficha?.fechaNacimiento}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>ESTADO CIVIL</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{ficha?.estadoCivil}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>TELÉFONO</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{ficha?.telefono}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>NÚMERO DE SEGURO SOCIAL</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{ficha?.nss}</Text></div>
                                    </div >
                                </div>
                            </div>
                        </View>
                    </View>
                </>
            )
        case "antecedentesHF":
            let antecedentesHF = _datapdf[_data];
            return (
                <>
                    <div style={{ width: "100%", margin: "15px 0", fontSize: "15px", backgroundColor: "black", color: "white", padding: "5px" }}>
                        <Text>ANTECEDENTES HEREDOFAMILIARES</Text>
                    </div>
                    <>
                        <View wrap={false} style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            height: "auto",
                        }}>
                            <div style={{ width: "50%", padding: "10px" }}>
                                <div style={{ border: "1px solid black" }}>
                                    <div style={{
                                        width: "100%",
                                        backgroundColor: "#666666",
                                        color: "white",
                                        padding: "5px"
                                    }}>
                                        <Text style={{
                                            color: "white",
                                            fontSize: "10px"
                                        }}>ENFERMEDADES CARDIOVASCULARES</Text>
                                    </div>
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>INFARTO AGUDO MIOCARDIO</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.infartoAM}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>ISQUEMIA MIOCÁRDIACA</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.inquemiaMiocardia}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>HIPERTENSIÓN ARTERIAL</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.hipertensionArterial}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>INSUFICIENCIA CARDIACA</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.insuficienciaCardiaca}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>OTRAS</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.otrasEC}</Text></div>
                                    </div >
                                </div>
                            </div>

                            <div style={{ width: "50%", padding: "10px" }}>
                                <div style={{ border: "1px solid black" }}>
                                    <div style={{
                                        width: "100%",
                                        backgroundColor: "#666666",
                                        color: "white",
                                        padding: "5px"
                                    }}>
                                        <Text style={{
                                            color: "white",
                                            fontSize: "10px"
                                        }}>ENFERMEDADES ENDROCRINOLOGICAS</Text>
                                    </div>
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>DIABETES MELLITUS</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.diabetesMellitus}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>OBESIDAD</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.obesidad}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>HIPERTIROIDISMO</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.hipertirodismo}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>HIPOTIROIDISMO</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.hipotiroidismo}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>OTRAS</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.otrasEE}</Text></div>
                                    </div >
                                </div>
                            </div>
                        </View>
                        <View wrap={false} style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            height: "auto",
                        }}>
                            <div style={{ width: "50%", padding: "10px" }}>
                                <div style={{ border: "1px solid black" }}>
                                    <div style={{
                                        width: "100%",
                                        backgroundColor: "#666666",
                                        color: "white",
                                        padding: "5px"
                                    }}>
                                        <Text style={{
                                            color: "white",
                                            fontSize: "10px"
                                        }}>ENFERMEDADES RESPIRATORIAS</Text>
                                    </div>
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>ASMA</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.asma}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>RINITIS ALERGICA</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.rinitisAlergica}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>TUBERCULOSIS</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.tuberculosis}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>ENFISEMA PULMONAR</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.enfisemaPulmonar}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>NEUMONÍAS</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.neumonias}</Text></div>
                                    </div >
                                </div>
                            </div>
                            <div style={{ width: "50%", padding: "10px" }}>
                                <div style={{ border: "1px solid black" }}>
                                    <div style={{
                                        width: "100%",
                                        backgroundColor: "#666666",
                                        color: "white",
                                        padding: "5px"
                                    }}>
                                        <Text style={{
                                            color: "white",
                                            fontSize: "10px"
                                        }}>ENFERMEDADES NEUROLOGICAS</Text>
                                    </div>
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>EPILEPSIA</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.epilepsia}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>MIGRAÑA</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.migraña}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>PSIQUIATRICAS</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.psiquiatricas}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>DEGENERATIVAS DEL SNC</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.degenerativas}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>OTRAS</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.otrasEN}</Text></div>
                                    </div >
                                </div>
                            </div>
                        </View>
                        <View wrap={false} style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            height: "auto",
                        }}>
                            <div style={{ width: "50%", padding: "10px" }}>
                                <div style={{ border: "1px solid black" }}>
                                    <div style={{
                                        width: "100%",
                                        backgroundColor: "#666666",
                                        color: "white",
                                        padding: "5px"
                                    }}>
                                        <Text style={{
                                            color: "white",
                                            fontSize: "10px"
                                        }}>OTRAS</Text>
                                    </div>
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>OSTEOARTROSIS/OSTEOARTRITIS</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.osteoartrosis}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>ENFERMEDADES DE LA PIEL</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.piel}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>ONCOLOGICAS</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.oncologicas}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>SORDERA</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.sordera}</Text></div>
                                    </div >
                                    <div style={{
                                        borderTop: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            width: "50%",
                                        }}><Text style={{ fontSize: "7px" }}>ALERGICOS</Text></div>
                                        <div style={{
                                            width: "50%",
                                            borderLeft: "1px solid black"
                                        }}><Text style={{ fontSize: "7px" }}>{antecedentesHF?.alergicos}</Text></div>
                                    </div >
                                </div>
                            </div>
                        </View>
                    </>
                </>
            )
        case "antecedentesPNP":
            let antecedentesPNP = _datapdf[_data];
            return (

                <View style={{ width: "100%", marginBottom: "60px" }}>
                    <div style={{ width: "100%", margin: "15px 0", fontSize: "15px", backgroundColor: "black", color: "white", padding: "5px" }}><Text>ANTECEDENTES PERSONALES NO PATOLOGICOS</Text></div>
                    <View wrap={false} style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        height: "auto",
                    }}>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>ORIGINARIO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPNP?.originario}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>NÚMERO DE HIJOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPNP?.numeroHijos}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>ESCOLARIDAD</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPNP?.escolaridad}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>INMUNIZACIONES</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPNP?.inmunizaciones}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>VIVIENDA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPNP?.vivienda}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>TIPO DE FAMILIA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPNP?.tipoFamilia}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>GRUPO SANGUINEO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPNP?.grupoSanguineo}</Text></div>
                                </div >
                            </div>
                        </div>

                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>TABAQUISMO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPNP?.tabaquismo}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>EDAD DE INICIO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPNP?.edadInicioT}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>CUANTO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPNP?.cuantoT}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>EX FUMADOR</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPNP?.exFumador}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>ETILISMO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPNP?.etilismo}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>EDAD INICIO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPNP?.edadInicioE}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>CUANTO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPNP?.cuantoE}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>ACTIVIDADES DEPORTIVAS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPNP?.actividadesDeportivas}</Text></div>
                                </div >
                            </div>
                        </div>
                    </View>
                </View>
            )
        case "antecedentesGineco":
            let antecedentesGenico = _datapdf[_data];
            return (
                <View style={{ width: "100%", marginBottom: "40px" }}>
                    <div style={{ width: "100%", margin: "15px 0", fontSize: "15px", backgroundColor: "black", color: "white", padding: "5px" }}><Text>ANTECEDENTES GINECOOBSTETRICOS</Text></div>
                    <View wrap={false} style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        height: "auto",
                    }}>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>MENARCA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesGenico?.menarca}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>G</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesGenico?.g}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>C</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesGenico?.c}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>FUM</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesGenico?.fum}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>MPF</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesGenico?.mpf}</Text></div>
                                </div >
                            </div>
                        </div>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>IVSA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesGenico?.ivsa}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>P</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesGenico?.p}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>A</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesGenico?.a}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>DOC</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesGenico?.doc}</Text></div>
                                </div >
                            </div>
                        </div>
                    </View>
                </View>
            )
        case "antecedentesLaborales":
            let antecedentesLaborales = _datapdf[_data];
            return (
                <View style={{ width: "100%" }}>
                    <div style={{ width: "100%", margin: "15px 0", fontSize: "15px", backgroundColor: "black", color: "white", padding: "5px" }}><Text>ANTECEDENTES LABORALES</Text></div>
                    <View wrap={false} style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        height: "auto",
                    }}>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>EDAD EN QUE INICIO A LABORAR</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.edadInicioLaboral}</Text></div>
                                </div >
                            </div>
                        </div>
                    </View>
                    <View wrap={false} style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        height: "auto",
                    }}>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    width: "100%",
                                    backgroundColor: "#666666",
                                    color: "white",
                                    padding: "5px"
                                }}>
                                    <Text style={{
                                        color: "white",
                                        fontSize: "10px"
                                    }}>PUESTO No. 1</Text>
                                </div>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>EMPRESA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.empresa1}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>PUESTO DE TRABAJO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.puestoTrabajo1}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>DURACION</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.duracion1}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>HORARIO DE TRABAJO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.horarioTrabajo1}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>DESCRIPOCIÓN DE ACTIVIDADES</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.descripcionActividades1}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>FACTORES DE RIESGO OCUPACIONAL</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>
                                            {antecedentesLaborales?.fisicos1 ? "FISICOS" : ""}
                                            {antecedentesLaborales?.quimicos1 ? ", QUIMICOS" : ""}
                                            {antecedentesLaborales?.ergonomicos1 ? ", ERGONOMICOS" : ""}
                                            {antecedentesLaborales?.psicosociales1 ? ", PSICOSOCIALES" : ""}
                                            {antecedentesLaborales?.biologicos1 ? ", BIOLOGICOS" : ""}
                                        </Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>EQUIPO DE PROTECCIÓN PERSONAL UTILIZADO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.equipoProteccion1}</Text></div>
                                </div >
                            </div>
                        </div>

                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    width: "100%",
                                    backgroundColor: "#666666",
                                    color: "white",
                                    padding: "5px"
                                }}>
                                    <Text style={{
                                        color: "white",
                                        fontSize: "10px"
                                    }}>PUESTO No. 2</Text>
                                </div>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>EMPRESA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.empresa2}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>PUESTO DE TRABAJO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.puestoTrabajo2}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>DURACION</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.duracion2}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>HORARIO DE TRABAJO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.horarioTrabajo2}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>DESCRIPOCIÓN DE ACTIVIDADES</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.descripcionActividades2}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>FACTORES DE RIESGO OCUPACIONAL</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>
                                            {antecedentesLaborales?.fisicos2 ? "FISICOS" : ""}
                                            {antecedentesLaborales?.quimicos2 ? ", QUIMICOS" : ""}
                                            {antecedentesLaborales?.ergonomicos2 ? ", ERGONOMICOS" : ""}
                                            {antecedentesLaborales?.psicosociales2 ? ", PSICOSOCIALES" : ""}
                                            {antecedentesLaborales?.biologicos2 ? ", BIOLOGICOS" : ""}
                                        </Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>EQUIPO DE PROTECCIÓN PERSONAL UTILIZADO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.equipoProteccion2}</Text></div>
                                </div >
                            </div>
                        </div>
                    </View>
                    <View wrap={false} style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        height: "auto",
                    }}>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    width: "100%",
                                    backgroundColor: "#666666",
                                    color: "white",
                                    padding: "5px"
                                }}>
                                    <Text style={{
                                        color: "white",
                                        fontSize: "10px"
                                    }}>PUESTO No. 3</Text>
                                </div>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>EMPRESA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.empresa3}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>PUESTO DE TRABAJO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.puestoTrabajo3}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>DURACION</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.duracion3}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>HORARIO DE TRABAJO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.horarioTrabajo3}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>DESCRIPOCIÓN DE ACTIVIDADES</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.descripcionActividades3}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>FACTORES DE RIESGO OCUPACIONAL</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>
                                            {antecedentesLaborales?.fisicos3 ? "FISICOS" : ""}
                                            {antecedentesLaborales?.quimicos3 ? ", QUIMICOS" : ""}
                                            {antecedentesLaborales?.ergonomicos3 ? ", ERGONOMICOS" : ""}
                                            {antecedentesLaborales?.psicosociales3 ? ", PSICOSOCIALES" : ""}
                                            {antecedentesLaborales?.biologicos3 ? ", BIOLOGICOS" : ""}
                                        </Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>EQUIPO DE PROTECCIÓN PERSONAL UTILIZADO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesLaborales?.equipoProteccion3}</Text></div>
                                </div >
                            </div>
                        </div>
                    </View>
                </View>
            )
        case "antecedentesPP":
            let antecedentesPP = _datapdf[_data];
            return (
                <View style={{ width: "100%", marginBottom: "40px" }}>
                    <div style={{ width: "100%", margin: "15px 0", fontSize: "15px", backgroundColor: "black", color: "white", padding: "5px" }}><Text>ANTECEDENTES PERSONALES PATOLOGICOS</Text></div>
                    <View wrap={false} style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        height: "auto",
                    }}>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>DIABETES MELLITUS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPP?.diabetesMelitus}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>HIPERTENSIÓN</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPP?.hipertension}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>CARDIOPATÍA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPP?.cardiopatia}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>GENITOURINARIO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPP?.genitourinario}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>QUIRURGICOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPP?.quirurgicos}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>ALERGICOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPP?.alergicos}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>TRAUMATICOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPP?.traumaticos}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>NEUROLÓGICOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPP?.neurologicos}</Text></div>
                                </div >
                            </div>

                        </div>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>HEMATOLÓGICOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPP?.hematologicos}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>CONGÉNITOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPP?.congenitos}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>DERMATOLOGICOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPP?.dermatologicos}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>DIGESTIVOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPP?.digestivos}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>MENTALES</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPP?.mentales}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>ENDOCRINOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPP?.endocrinos}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>INFECCIOSOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPP?.infecciosos}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>TOXICOMANIAS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{antecedentesPP?.toxicomanias}</Text></div>
                                </div >
                            </div>
                        </div>
                    </View>
                </View>
            )
        case "exploracion":
            let exploracion = _datapdf[_data];
            return (
                <>
                    <View style={{ width: "100%", marginBottom: "40px" }}>
                        <div style={{ width: "100%", margin: "15px 0", fontSize: "15px", backgroundColor: "black", color: "white", padding: "5px" }}><Text>EXPLORACIÓN FISICA</Text></div>
                    </View>
                    <View wrap={false} style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        height: "auto",
                    }}>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    width: "100%",
                                    backgroundColor: "#666666",
                                    color: "white"
                                }}>
                                    <Text style={{
                                        color: "white",
                                        fontSize: "10px"
                                    }}>EXPLORACIÓN FISICA</Text>
                                </div>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>PESO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.peso} Kg</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>TALLA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.talla} cm</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>FC</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.fc} x'</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>FR</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.fr} x'</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>T.A.</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.ta} mmHg</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>IMC</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.imc}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>INDICE ROBUST.</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.indiceRobust}</Text></div>
                                </div >
                            </div>

                        </div>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    width: "100%",
                                    backgroundColor: "#666666",
                                    color: "white"
                                }}>
                                    <Text style={{
                                        color: "white",
                                        fontSize: "10px"
                                    }}>AGUDEZA VISUAL</Text>
                                </div>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>O.D.</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.od}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>O.I.</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.oi}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>AMBOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.ambos}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>C/L O.D.</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.odcl}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>C/L O.I.</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.oicl}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>C/L AMBOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.amboscl}</Text></div>
                                </div >
                            </div>
                        </div>
                    </View>
                    <View wrap={false} style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        height: "auto",
                    }}>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    width: "100%",
                                    backgroundColor: "#666666",
                                    color: "white"
                                }}>
                                    <Text style={{
                                        color: "white",
                                        fontSize: "10px"
                                    }}>EXPLORACIÓN FISICA OJOS</Text>
                                </div>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>OJOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.ojos}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>PUPILAS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.pupilas}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>CAMPIMETRIA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.campimetria}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>SENTIDO CROMATICO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.sentidoCromatico}</Text></div>
                                </div >

                            </div>
                        </div>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    width: "100%",
                                    backgroundColor: "#666666",
                                    color: "white"
                                }}>
                                    <Text style={{
                                        color: "white",
                                        fontSize: "10px"
                                    }}>EXPLORACIÓN FISICA NARIZ</Text>
                                </div>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>NARIZ</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.nariz}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>TABIQUE NASAL</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.tabiqueNasal}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>CORNETES</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.cornetes}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>POLIPOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.polipos}</Text></div>
                                </div >
                            </div>

                        </div>
                    </View>
                    <View wrap={false} style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        height: "auto",
                    }}>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    width: "100%",
                                    backgroundColor: "#666666",
                                    color: "white"
                                }}>
                                    <Text style={{
                                        color: "white",
                                        fontSize: "10px"
                                    }}>EXPLORACIÓN FISICA OROFARINGE</Text>
                                </div>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>OROFARINGE</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.orofaringe}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>DENTADURA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.dentadura}</Text></div>
                                </div >
                                <div style={{
                                    width: "100%",
                                    backgroundColor: "#666666",
                                    color: "white"
                                }}>
                                    <Text style={{
                                        color: "white",
                                        fontSize: "10px"
                                    }}>EXPLORACIÓN FISICA OIDOS</Text>
                                </div>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>OIDOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.oidos}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>CONDUCTO AUDITIVO EXTERNO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.conductoAuditivoExterno}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>MEMBRANA TIMPANO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.membranaTimpano}</Text></div>
                                </div >

                            </div>
                        </div>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    width: "100%",
                                    backgroundColor: "#666666",
                                    color: "white"
                                }}>
                                    <Text style={{
                                        color: "white",
                                        fontSize: "10px"
                                    }}>EXPLORACIÓN FISICA TORAX</Text>
                                </div>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>AMPLEXION</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.amplexion}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>AMPLEXACION</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.amplexacion}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>CAMPOS PULMONARES</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.camposPulmonares}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>PT</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.pt}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>RESPIRACION NORMAL</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.respiracionNormal}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>ESTERTORES</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.estertores}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>TIPO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.tipo}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>LOCALIZACION</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.localizacion}</Text></div>
                                </div >
                            </div>
                        </div>
                    </View>
                    <View wrap={false} style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        height: "auto",
                    }}>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    width: "100%",
                                    backgroundColor: "#666666",
                                    color: "white"
                                }}>
                                    <Text style={{
                                        color: "white",
                                        fontSize: "10px"
                                    }}>EXPLORACIÓN FISICA CARDIOVASCULAR</Text>
                                </div>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>CARDIOVASCULAR</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.cardiovascular}</Text></div>
                                </div >
                            </div>
                        </div>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    width: "100%",
                                    backgroundColor: "#666666",
                                    color: "white"
                                }}>
                                    <Text style={{
                                        color: "white",
                                        fontSize: "10px"
                                    }}>EXPLORACIÓN FISICA ABDOMEN</Text>
                                </div>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>PERIMETRO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.perimetro} cm</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>PERISTALTISMO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.peristaltismo}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>VISCEROMEGLIAS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.visceromeglias}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>HERNIAS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.hernias}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>COMENTARIO DE HERNIAS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.comentarioHernias}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>ORGANOS GENITALES</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.organosGenitales}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>HEMORROIDES</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.hemorroides}</Text></div>
                                </div >
                            </div>
                        </div>
                    </View>
                    <View style={{ width: "100%", marginBottom: "40px" }}>
                        <div style={{ width: "100%", margin: "15px 0", fontSize: "15px", backgroundColor: "black", color: "white", padding: "5px" }}><Text>OSTEOMUSCULAR</Text></div>
                    </View>
                    <View wrap={false} style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        height: "auto",
                    }}>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    width: "100%",
                                    backgroundColor: "#666666",
                                    color: "white"
                                }}>
                                    <Text style={{
                                        color: "white",
                                        fontSize: "10px"
                                    }}>COLUMNA SERVICAL</Text>
                                </div>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>MOVILIDAD</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.movilidad}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>FLEXION Y EXTENSION</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.flexionExtension}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>ROTACION LATERAL DERECHA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.rotacionLateralDerecha}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>ROTACION LATERAL IZQUIERDA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.rotacionLateralIzquierda}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>INCLINACION LATERAL IZQUIERDA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.inclinacionLateralIzquierda}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>INCLINACION LATERAL DERECHA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.inclinacionLateralDerecha}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>FUERZA MUSCULAR</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.fuerzaMuscular}</Text></div>
                                </div >
                            </div>
                        </div>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    width: "100%",
                                    backgroundColor: "#666666",
                                    color: "white"
                                }}>
                                    <Text style={{
                                        color: "white",
                                        fontSize: "10px"
                                    }}>COLUMNA LUMBOSCARA</Text>
                                </div>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>MOVILIDAD</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.movilidad2}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>FLEXION Y EXTENSION</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.flexionExtension2}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>ROTACION LATERAL DERECHA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.rotacionLateralDerecha2}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>ROTACION LATERAL IZQUIERDA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.rotacionLateralIzquierda2}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>INCLINACION LATERAL IZQUIERDA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.inclinacionLateralIzquierda2}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>INCLINACION LATERAL DERECHA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.inclinacionLateralDerecha2}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>FUERZA MUSCULAR</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.fuerzaMuscular2}</Text></div>
                                </div >
                            </div>
                        </div>
                    </View>
                    <View wrap={false} style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        height: "auto",
                    }}>
                        <div style={{ width: "50%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    width: "100%",
                                    backgroundColor: "#666666",
                                    color: "white"
                                }}>
                                    <Text style={{
                                        color: "white",
                                        fontSize: "10px"
                                    }}>EXTREMIDADES</Text>
                                </div>
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>MOVILIDAD</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.movilidad3}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>INTEGRIDAD</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.integridad}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>ACORTAMIENTO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.acortamiento}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>SENSIBILIDAD</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.sensibilidad}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>VARICES</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.varices}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>REFLEJOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.reflejos}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>FUERZA MUSCULAR</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.fuerzaMuscular3}</Text></div>
                                </div >
                            </div>
                        </div>
                    </View>
                    <View wrap={false} style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        height: "auto",
                    }}>
                        <div style={{ width: "100%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>LASAGUE</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.lasague}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>PATRICK</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.patrick}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>OBSERVACIONES EXPLORACIÓN FISICA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.observaciones}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>RESULTADO BIOMETRÍA HEMÁTICA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.biometriaHematica}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>RESULTADO GLUCOSA, UREA, CREATININA, COLESTEROL, TRIGLICERIDOS</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.resultadosGen}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>RESULTADO GLICOHEMOGLOBINA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.glicohemoglobina}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>RESULTADO EXAMEN GENERAL DE ORINA</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{exploracion?.examenOrina}</Text></div>
                                </div >
                            </div>
                        </div>
                    </View>
                </>
            )
        case "conclusiones":
            let conclusiones = _datapdf[_data];
            return (
                <>
                    <View wrap={false} style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        height: "auto",
                    }}>
                        <View style={{ width: "100%", margin: "15px 0", fontSize: "15px", backgroundColor: "black", color: "white", padding: "5px" }}>
                            <div><Text>COMENTARIOS Y CONCLUCIONES</Text></div>
                        </View>
                        <div style={{ width: "100%", padding: "10px" }}>
                            <div style={{ border: "1px solid black" }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>DIAGNOSTICO MEDICO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{conclusiones?.diagnosticoMedico}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>PLAN TERAPEUTICO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{conclusiones?.terapeutico}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>RECOMENDACIONES</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{conclusiones?.recomendaciones}</Text></div>
                                </div >
                                <div style={{
                                    borderTop: "1px solid black",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "50%",
                                    }}><Text style={{ fontSize: "7px" }}>APTO MEDICAMENTE PARA EL PERFIL DE PUESTO SOLICITADO</Text></div>
                                    <div style={{
                                        width: "50%",
                                        borderLeft: "1px solid black"
                                    }}><Text style={{ fontSize: "7px" }}>{conclusiones?.apto}</Text></div>
                                </div >
                            </div>
                        </div>
                    </View>
                </>
            )

        default:
            break;
    }
}

const DatosPdf = ({ data }) => {
    const datapdf = JSON.parse(data?.datospdf?.data)
    console.log("datapdf", datapdf)

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.view}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <Text>EXAMEN MÉDICO OCUPACIONAL</Text>
                    </div>
                </View>
                {

                    Object.keys(datapdf)?.map(data => {
                        return (
                            getCampos(datapdf, data)
                        )
                    })
                }
                <View style={styles.view}>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <div>
                            <Image style={{ width: "200px" }} src={"https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/DoctoresDetalles%2FImagen_0918?alt=media&token=1ead184a-04eb-48ad-8558-e156c29ef080&_gl=1*1qmbva7*_ga*MTU4OTAxOTUxMC4xNjc3NjEyNjk3*_ga_CW55HF8NVT*MTY4NjYxMDQ3NS4yNC4xLjE2ODY2MTA1NDIuMC4wLjA."} />
                        </div>
                        <div>
                            <Text style={{ fontSize: "15px" }}>NOMBRE DEL MEDICO: DR. MARCOS GONZALEZ AVILA</Text>
                        </div>
                        <div>
                            <Text style={{ fontSize: "15px" }}>CEDULA PROFECIONAL: 6639961</Text>
                        </div>
                        <div>
                            <Text style={{ fontSize: "15px" }}>REGISTRO SSA: 8110/10</Text>
                        </div>
                        <div>
                            <Text style={{ fontSize: "15px" }}>FIRMA:</Text>
                        </div>
                    </div>
                </View>
            </Page>
        </Document >
    )
}

const blobToExport = async (data) => {
    return await pdf(<DatosPdf data={data} />).toBlob()
}

export default blobToExport