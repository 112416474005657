import React, { useState } from 'react';
import { useHistory, NavLink } from "react-router-dom";
import {
    Paper,
    BottomNavigation,
    BottomNavigationAction,
} from "@mui/material";
import ViewListIcon from '@mui/icons-material/ViewList';
import { tabStyle } from '../utils';
import Listado from "../views/campanas/listado";
import Swal from "sweetalert2";
import { useFileManager } from '../hooks/useFileManager';


const useTabStyle = tabStyle();
const Tabs = ({
    tabs = [{
        title: "Tab 1",
        onClick: () => { },
        icon: <ViewListIcon />,
        content: <></>,
        selected: false,
    }],
}) => {
    const { files, agregarFiles, clearFiles, activeTab, agregarActiveTab} = useFileManager();
    const tabStyle = useTabStyle();
    const history = useHistory();
    let posi = 0;
    tabs.map((e, index) => {
        if (e.selected) {
            posi = index;
        }
    });
    
    return (
        <>
            <Paper elevation={3} className={tabStyle.tabsPaperNav}>
                <BottomNavigation
                    className={tabStyle.btnGroup}
                    showLabels
                    onChange={e => { }}
                >
                    {
                        tabs && tabs.map((tab, i) => {
                            return (
                                <BottomNavigationAction
                                    className={activeTab.title == tab.title ? tabStyle.btnNavActivo : tabStyle.btnNav}
                                    key={i}
                                    label={tab.title}
                                    onClick={async e => {
                                        if(activeTab.title == "Editar"){
                                            const res = await Swal.fire({
                                                title: '¿los datos no guardados se perderan deseas continuar?',
                                                text: "Favor de corroborar sus datos",
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: '#3085d6',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'Si, Continuar!',
                                                cancelButtonText: 'Cancelar'
                                            });
                                            if (res.isConfirmed) {
                                                history.push("/campanas");
                                                window.location.reload();
                                            }
                                        }else{
                                            agregarActiveTab(tab);
                                        }
                                    }}
                                    icon={tab.icon}
                                />
                            )
                        })
                    }
                </BottomNavigation>
            </Paper>
            <Paper className={tabStyle.tabsPaper}>
                {activeTab.content}
            </Paper>
        </>
    );

}

export default Tabs;