//React
import React, { useEffect, useState, useRef, useMemo } from "react";
import { capitalizeFirst } from "../../utils";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import { useModel } from "../../hooks/useModel";
import LoadingButton from "@mui/lab/LoadingButton";
import Loading from "../../components/FullScreenLoader";
import DatePickerBeta from '../../components/DatePickerBeta';
//----------------------------------------------------------------------------------------------------------------------

//Librerias
import {
  Grid, MenuItem, Modal, FormControl,
  InputLabel, Select, TextField, Typography, Divider, CardMedia, Paper
} from "@mui/material";
import { makeStyles } from "@mui/styles";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import { ArrowBack, ContactsRounded, RemoveRedEye } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
//----------------------------------------------------------------------------------------------------------------------

//Imagenes
import PageHeader from "../../components/PageHeader";
import InputRegex from "../../components/InputRegex";
//----------------------------------------------------------------------------------------------------------------------

//Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
const modalStyle = {
  position: "absolute",
  top: "50%",
  right: "auto",
  left: "50%",
  bottom: "auto",
  transform: "translate(-50%, -50%)",
  marginRight: "-50%",
  background: "#FFF",
  outline: "none",
  padding: "15px 0 0 0",
  borderRadius: "5px",
  boxShadow: "0 0 15px black",
};
//----------------------------------------------------------------------------------------------------------------------
const Index = ({ match, history, dialog }) => {
  //Estados Iniciales
  const classes = useStyles();
  const id = match.params.id;
  const formulario = useRef();
  const [imgProductoModal, setImgProductoModal] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  //----------------------------------------------------------------------------------------------------------------------
  //Estados Iniciales Controller
  const [propiedades, setPropiedades] = useState({
    idSucursal: "",
    ine: "",
    genero: "",
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    nombreCompleto: "",
    fechaNacimiento: "",
    correo: "",
    direccion: "",
    telefono: "",
    urlFoto: "",
    estadoCivil: "",
    tipoSangre: "",
  });

  const { model, modelLoading } =
    useModel({
      name: "recepcion-paciente",
      id,
      redirectOnPost: true,
    });

  useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setPropiedades(model);
    }
  }, [model]);

  if (modelLoading) return <Loading />;

  return (
    <>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" style={modalStyle} >
        <div style={{ backgroundColor: "#FFF" }}>
          <CloseIcon
            onClick={handleClose}
            style={{ marginBottom: "15px", marginRight: "15px", display: "flex", marginLeft: "auto", cursor: "pointer" }}
          />
          <img style={{ maxHeight: '600px', minHeight: '400px', width: '100%' }} src={imgProductoModal} alt="imgProductoModal" />
        </div>
      </Modal>

      <PageHeader
        history={history}
        title={"VER HISTORIAL DE PACIENTES"}
        links={[
          { to: "/historialPacientes", icon: <ContactsRounded />, label: "Recepcion de Pacientes" },
          { to: "/historialPaciente/ver/" + id, icon: <RemoveRedEye />, label: capitalizeFirst(propiedades?.nombreCompleto.toLocaleLowerCase()) },
        ]}
        dialog={dialog}
      />
      <Grid component={Paper} container className={classes.paper} spacing={1} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <div ref={formulario}><strong>{"INFORMACIÓN DEL PACIENTE"}</strong></div>
          <Divider />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled
            label="INE"
            variant="standard"
            fullWidth
            size="medium"
            inputProps={{
              style: { WebkitTextFillColor: "black" },
              value: propiedades.ine,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            disabled
            label="Nombre del Paciente"
            variant="standard"
            fullWidth
            type="text"
            value={propiedades.nombres}
            inputProps={{
              style: { WebkitTextFillColor: "black" },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            disabled
            variant="standard"
            label="Apellido Paterno"
            fullWidth
            type="text"
            value={propiedades.apellidoPaterno}
            inputProps={{
              style: { WebkitTextFillColor: "black" },
            }}
          />
        </Grid>

        <Grid item xs={12} md={4} >
          <TextField
            disabled
            label="Apellido Materno"
            variant="standard"
            fullWidth
            size="medium"
            inputProps={{
              style: { WebkitTextFillColor: "black" },
              value: propiedades.apellidoMaterno,
            }}
          />
        </Grid>

        <Grid item xs={12} md={4} >
          <TextField
            disabled
            label="Nombre Completo"
            variant="standard"
            fullWidth
            type="text"
            value={propiedades.nombreCompleto.toString()}
            inputProps={{
              style: { WebkitTextFillColor: "black" },
            }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="tipoSelect">Genero</InputLabel>
            <Select
              labelId="tipoSelect"
              fullWidth
              label="Tipo"
              disabled
              inputProps={{
                style: { WebkitTextFillColor: "black" },
                value: propiedades.genero,
              }}
            >
              <MenuItem value={"M"} style={{ WebkitTextFillColor: "black" }}>Masculino</MenuItem>
              <MenuItem value={"F"} style={{ WebkitTextFillColor: "black" }}>Femenino</MenuItem>
              <MenuItem value={"O"} style={{ WebkitTextFillColor: "black" }}>Otros</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="tipoSelect">Estado Civil</InputLabel>
            <Select
              labelId="tipoSelect"
              fullWidth
              label="Estado Civil"
              disabled
              inputProps={{
                style: { WebkitTextFillColor: "black" },
                value: propiedades.estadoCivil,
              }}
            >
              <MenuItem value={"SOLTERO(A)"}>SOLTERO(A)</MenuItem>
              <MenuItem value={"CASADO(A)"}>CASADO(A)</MenuItem>
              <MenuItem value={"DIVORCIADO(A)"}>DIVORCIADO(A)</MenuItem>
              <MenuItem value={"VIUDO(A)"}>VIUDO(A)</MenuItem>
              <MenuItem value={"CONCUBINATO(A)"}>CONCUBINATO(A)</MenuItem>
              {/* <MenuItem value={"SEPARACIONJUDICIAL"}>SEPARACIÓN EN PROCESO JUDICIAL</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            disabled
            label="Tipo de sangre"
            variant="standard"
            color="primary"
            fullWidth
            typeInput="text"
            value={propiedades.tipoSangre.toString()}
            inputProps={{
              style: { WebkitTextFillColor: "black" },
            }}
          />
        </Grid>

        <Grid item xs={12} md={4} >
          <TextField
            disabled
            label="Correo"
            variant="standard"
            fullWidth
            type="email"
            value={propiedades.correo.toString()}
            inputProps={{
              style: { WebkitTextFillColor: "black" },
            }}
          />
        </Grid>

        <Grid item xs={12} md={4} >
          <DatePickerBeta
            style={{ WebkitTextFillColor: "black", height: '50px' }}
            labelText="Fecha de Nacimiento"
            value={propiedades.fechaNacimiento}
            variant="standard"
            inputFormat="MM/dd/yyyy"
            disabled
            inputProps={{
              style: { WebkitTextFillColor: "black" },
            }}
          />
        </Grid>

        <Grid item xs={12} md={4} >
          <TextField
            disabled
            style={{ height: '50px' }}
            label="Direccion"
            variant="standard"
            fullWidth
            type="text"
            value={propiedades.direccion.toString()}
            inputProps={{
              style: { WebkitTextFillColor: "black" },
            }}
          />
        </Grid>

        <Grid item xs={12} md={4} >
          <InputRegex
            style={{ height: '50px' }}
            label="Teléfono"
            variant="standard"
            fullWidth
            disabled
            typeInput="phone"
            value={propiedades.telefono.toString()}
            inputProps={{
              style: { WebkitTextFillColor: "black" },
              value: propiedades.telefono,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={12} md={3}>
              <Typography variant="h6" gutterBottom> <strong>IMAGEN</strong> </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={12} md={7} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '30px' }}>
              <Divider style={{ marginBottom: "2%" }} />
              <div style={{ cursor: 'pointer' }} onClick={() => { handleOpen(); setImgProductoModal(propiedades.urlFoto) }}>
                <CardMedia
                  component="img"
                  image={propiedades.urlFoto}
                  title=""
                  style={{ width: "150px", height: "150px", objectFit: 'cover', borderRadius: '100%' }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-start">
            <LoadingButton variant="contained" onClick={() => history.goBack()} size="medium" startIcon={<ArrowBack />} style={{ color: "#fff" }}>
              REGRESAR
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Index;