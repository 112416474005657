import React, { useEffect, useState } from 'react'

import { Button, Grid, TextField } from '@mui/material'
// import { useLocalStorage } from '../../../hooks/useLocalStorage';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import BlockIcon from '@mui/icons-material/Block';
import InputRegex from '../../../components/InputRegex';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

const Step2 = ({ nextStep, setSolicitud, ...props }) => {
    const defaultPropiedades = {
        nombre: "",
        celular: "",
        nombreHotel: "",
        sintomas: "",
    }
    const [propiedades, setPropiedades] = useState({ ...defaultPropiedades });
    const [sig, setSig] = useState(false);
    const [solicitudServicio, setSolicitudServicio] = useLocalStorage("solicitudServicio", {});

    useEffect(() => {
        let progress = 0;
        if (propiedades?.nombre !== "") {
            progress += 25;
        }
        if (propiedades?.celular !== "") {
            progress += 25;
        }
        if (propiedades?.nombreHotel !== "") {
            progress += 25;
        }
        if (propiedades?.sintomas !== "") {
            progress += 25;
        }
        if (progress === 100) {
            setSig(true);
        } else {
            setSig(false);
        }
        setSolicitud(progress, 0);
    }, [propiedades]);

    useEffect(() => {
        if (Object.keys(solicitudServicio).length > 0) {
            setPropiedades({ ...propiedades, ...solicitudServicio });
        }
    }, [solicitudServicio]);

    return (
        <Grid container style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
            <Grid item md={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                    alt="L-Hospitalito-Logo.png"
                    src="https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2Flogo.png?alt=media&token=08d29a51-3658-451a-9975-4edd5c989540"
                    title="L-Hospitalito"
                    height="90"
                    width='100%'
                    style={{ objectFit: 'contain' }}
                />
            </Grid>
            <Grid item xs={12} sm={10} md={8} lg={6} xl={4} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', margin: '30px 20px 0 20px' }}>
                <InputRegex
                    style={{ marginBottom: '20px', background: '#FFF' }}
                    label="Nombre completo"
                    typeInput="nombre"
                    variant='outlined'
                    fullWidth
                    value={propiedades.nombre.toUpperCase()}
                    onChange={e => {
                        setPropiedades(prevState => ({
                            ...prevState,
                            ...prevState.propiedades,
                            nombre: e.target.value.toUpperCase()
                        }))
                    }}
                />
                <InputRegex
                    style={{ marginBottom: '20px', background: '#FFF' }}
                    label="Nombre del hotel"
                    typeInput="nombre"
                    variant='outlined'
                    fullWidth
                    value={propiedades.nombreHotel.toUpperCase()}
                    onChange={e => {
                        setPropiedades(prevState => ({
                            ...prevState,
                            ...prevState.propiedades,
                            nombreHotel: e.target.value.toUpperCase()
                        }))
                    }}
                />
                <InputRegex
                    style={{ marginBottom: '20px', background: '#FFF' }}
                    label="Celular"
                    typeInput="phone"
                    variant='outlined'
                    fullWidth
                    value={propiedades.celular}
                    onChange={e => {
                        setPropiedades(prevState => ({
                            ...prevState,
                            ...prevState.propiedades,
                            celular: e.target.value
                        }))
                    }}
                />
                <TextField
                    style={{ marginBottom: '20px', background: '#FFF' }}
                    label="Síntomas"
                    typeInput="text"
                    variant='outlined'
                    multiline
                    rows={4}
                    fullWidth
                    value={propiedades.sintomas}
                    onChange={e => {
                        setPropiedades(prevState => ({
                            ...prevState,
                            ...prevState.propiedades,
                            sintomas: e.target.value
                        }))
                    }}
                />
                <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button
                        onClick={e => {
                            nextStep(0);
                        }}
                        style={{ background: '#155480', color: '#FFF', width: '40%', transition: 'background 1s', border: 'none' }}>
                        <>Anterior<NavigateBeforeIcon style={{ margin: '0 0 1px 10px' }} /></>
                    </Button>
                    <Button
                        onClick={e => {
                            nextStep(1);
                        }}
                        disabled={!sig} style={{ background: '#155480', color: '#FFF', width: '40%', transition: 'background 1s', border: 'none' }}>
                        {sig ? <>Guardar<NavigateNextIcon style={{ margin: '0 0 1px 10px' }} /></> : <>Guardar<BlockIcon style={{ margin: '0 0 1px 10px' }} /></>}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Step2