/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { TextField, Box, Container, Grid } from '@mui/material';
import img from "../assets/img/fac.png";
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { baseStyles, facturaStyles } from "../utils";
import {
	Divider,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	IconButton,
	Link,
} from '@mui/material';
import DatePicker from "../components/DatePickerBeta";
import moment from "moment-timezone";
import { usePost } from "../hooks/usePost";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaymentIcon from '@mui/icons-material/Payment';
import AsyncAutocompleteSearchBeta from "../components/AsyncAutocompleteSearchBeta";
import LoadingButton from '@mui/lab/LoadingButton';
import AlertTypes from "../components/AlertTypes";
import FullScreenLoader from "../components/FullScreenLoader";
import CircularProgress from '@mui/material/CircularProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import InputRegex from "../components/InputRegex"

const useStyles = baseStyles();
const facturaStyle = facturaStyles();
export default function Factura() {
	const classes = useStyles();
	const facturaJSS = facturaStyle();
	const [loadingButton1, setLoadingButton1] = useState(false);
	const [loadingPage, setLoadingPage] = useState(true);
	const [loadingDowload, setLoadingDowload] = useState(false);
	const [errorServicio, setErrorServicio] = useState(false);
	const [errores, cargandoBoton, peticion, setCargando, detalles] = usePost();
	const history = useHistory();
	const [link, setLink] = useState("");
	const [openAlert1, setOpenAlert1] = useState(false);
	const [severity, setSeverity] = useState("");
	const [message, setMessage] = useState("");
	let [servicios, setServicios] = useState([]);
	let [servicio, setServicio] = useState(null);
	const [clearInputServicio, setClearInputServicio] = useState(false);
	const [objServicios, setObjServicios] = React.useState({});
	const metodoPago = [{ 'PUE': 'Pago en una sola exhibición' }, { 'PPD': 'Pago en parcialidades o diferido' }];
	const opcionesPago = [
		{ value: '01', descripcion: 'Efectivo', icon: <LocalAtmIcon style={{ fontSize: "17", color: "green", top: "4px", position: "relative" }} /> },
		{ value: '04', descripcion: 'Tarjeta de crédito', icon: <PaymentIcon style={{ fontSize: "17", color: "blue", top: "4px", position: "relative" }} /> },
		{ value: '28', descripcion: 'Tarjeta de débito', icon: <CreditCardIcon style={{ fontSize: "17", color: "#FFC340", top: "4px", position: "relative" }} /> },
	];
	
	const usoCfdi = [
		{ 'value': "G01", 'descripcion': 'Adquisición de mercancías', 'fisica': true, 'moral': true },
		{ 'value': "G02", 'descripcion': 'Devoluciones, descuentos o bonificaciones', 'fisica': true, 'moral': true },
		{ 'value': "G03", 'descripcion': 'Gastos en general', 'fisica': true, 'moral': true },
		{ 'value': "I01", 'descripcion': 'Construcciones', 'fisica': true, 'moral': true },
		{ 'value': "I02", 'descripcion': 'Mobiliario y equipo de oficina por inversiones', 'fisica': true, 'moral': true },
		{ 'value': "I03", 'descripcion': 'Equipo de transporte', 'fisica': true, 'moral': true },
		{ 'value': "I04", 'descripcion': 'Equipo de cómputo y accesorios', 'fisica': true, 'moral': true },
		{ 'value': "I05", 'descripcion': 'Dados, troqueles, moldes, matrices y herramental', 'fisica': true, 'moral': true },
		{ 'value': "I06", 'descripcion': 'Comunicaciones telefónicas', 'fisica': true, 'moral': true },
		{ 'value': "I07", 'descripcion': 'Comunicaciones satelitales', 'fisica': true, 'moral': true },
		{ 'value': "I08", 'descripcion': 'Otra maquinaria y equipo', 'fisica': true, 'moral': true },
		{ 'value': "D01", 'descripcion': 'Honorarios médicos, dentales y gastos hospitalarios', 'fisica': true, 'moral': false },
		{ 'value': "D02", 'descripcion': 'Gastos médicos por incapacidad o discapacidad', 'fisica': true, 'moral': false },
		{ 'value': "D03", 'descripcion': 'Gastos funerales', 'fisica': true, 'moral': false },
		{ 'value': "D04", 'descripcion': 'Donativos', 'fisica': true, 'moral': false },
		{ 'value': "D05", 'descripcion': 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)', 'fisica': true, 'moral': false },
		{ 'value': "D06", 'descripcion': 'Aportaciones voluntarias al SAR', 'fisica': true, 'moral': false },
		{ 'value': "D07", 'descripcion': 'Primas por seguros de gastos médicos', 'fisica': true, 'moral': false },
		{ 'value': "D08", 'descripcion': 'Gastos de transportación escolar obligatoria', 'fisica': true, 'moral': false },
		{ 'value': "D09", 'descripcion': 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones', 'fisica': true, 'moral': false },
		{ 'value': "D10", 'descripcion': 'Pagos por servicios educativos (colegiaturas)', 'fisica': true, 'moral': false },
		{ 'value': "P01", 'descripcion': 'Por definir', 'fisica': true, 'moral': true },
	];

	let [propiedades, setPropiedades] = useState({
		fecha: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
		folio: "",
		nombreCompleto: "",
		codigoPostal: "",
		rfc: "",
		telefono: "",
		email: "",
		razonSocial: "",
		metodoPago: "PUE",
		usoCfdi: "G03",
		formaPago: "01",
		total: "",
		servicios: [],
		cfdiEmisor: {
			rfc: "CACX7605101P8",
			nombre: "MEDICINA OCUPACIONAL NUEVA VIDA, SA DE CV",
			regimenFiscal: "612"
		}
	});

	const servicioParamsMemo = useMemo(
		() => ({
			pagina: 1,
			limite: 20,
			ordenar: "concepto.asc"
		}),
		[servicio]
	);


	const handleChangeFormaPago = (event) => {
		propiedades = { ...propiedades, formaPago: event.target.value };
		setPropiedades(propiedades);
	};

	const handleChangeMetodoPago = (event) => {
		propiedades = { ...propiedades, metodoPago: event.target.value };
		setPropiedades(propiedades);
	};

	const setServicioCallback = useCallback(
		(e, v) => {
			if (v !== "" && v) {
				v.cantidad = 1;
				setServicio(v);
			} else {
				setServicio(null);
			}
		},
		[setServicio]
	);

	const onAddService = () => {
		if (servicio != null) {
			const found = servicios.some(e => e.concepto === servicio.concepto);
			if (!found) {
				const newService = {
					idFactura: "",
					idServicio: servicio.idServicio,
					unidadM: servicio.unidadM,
					claveP: servicio.claveP,
					concepto: servicio.concepto,
					grupo: servicio.grupo,
					valorU: servicio.valorU,
					valorUC: servicio.valorUC,
					valorUIva: servicio.valorUIva,
					valorUCIva: servicio.valorUCIva,
					cantidad: servicio.cantidad,
				};

				setLoadingButton1(true);
				servicios = [...servicios, newService];
				setServicios(servicios);
				setClearInputServicio((newService) => !newService)
			} else {
				setOpenAlert1(true);
				setSeverity("warning");
				setMessage("El Servicio se agrego a la tabla, cambiar la cantidad en caso de que sean varios servicios del mismo concepto.");
			}
		} else {
			document.getElementById("servicios").focus();
			setOpenAlert1(true);
			setSeverity("error");
			setMessage("Favor de agregar un servicio para poder facturar los datos del formulario.");

		}
	}

	const onDropService = async (id, nombre) => {
		if (id) {
			const text = ("¿Estás seguro de que deseas borrar el servicio " + nombre + "?").toUpperCase();
			const res = await Swal.fire({
				title: text,
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#25607A",
				cancelButtonColor: "#d33",
				confirmButtonText: "Si, borrarlo!",
				cancelButtonText: "Cancelar",
			});

			if (res.isConfirmed) {
				Swal.fire({
					title: "Espera un momento.....",
					showConfirmButton: false,
				});

				let posi;
				const copyServicios = [...servicios];
				servicios.map((e, index) => {
					if (e.idServicio === id) {
						posi = index;
					}
				});
				copyServicios.splice(posi, 1);
				setServicios(copyServicios);
				const texto2 = ("Servicio " + nombre + " borrado con éxito.").toUpperCase();
				Swal.fire(
					"Borrado!",
					texto2,
					"success"
				);
			}
		}
	};

	const handleCloseAlert1 = (event) => {
		setOpenAlert1(false);
	};

	//////////////////USEEfeccts////////////////////
	useEffect(() => {
		setTimeout(() => setLoadingPage(false), 600);

	}, []);

	useEffect(() => {
		if (loadingDowload === true) {
			setTimeout(() => { setLoadingDowload(false); window.location.reload(); }, 1500);
		} else {
			setLink("");
			setOpenAlert1(true);
			setSeverity("success");
			setMessage("Factura Descargada Correctamente.");
		}
	}, [loadingDowload, setLoadingDowload]);

	useEffect(() => {
		if (detalles?.estatus == "200") {
			if (detalles?.type == "1") {
				Swal.fire({
					icon: 'success',
					title: "Factura ya existente",
					text: detalles?.mesagge,
					confirmButtonColor: '#3085d6',
					footer: '<a style={{fontSize:"15px"}}>Gracias por facturar online.</a>'
				});
			} else {
				Swal.fire({
					icon: 'success',
					title: "Factura Timbrada",
					text: detalles?.mesagge,
					confirmButtonColor: '#3085d6',
					footer: '<a style={{fontSize:"15px"}}>Gracias por facturar online.</a>'
				});
			}

			if (detalles?.link !== "") {
				setLink(detalles?.link);
			}
		}

		if (detalles?.estatus == "400") {
			Swal.fire({
				icon: 'error',
				title: detalles?.mesagge,
				text: detalles?.link,
				confirmButtonColor: '#ff0000',
				footer: '<a>Favor de copiar los datos tal como están en su ticked de compra.</a>'
			})
		}

	}, [detalles, detalles?.message, detalles?.estatus, errores]);

	useEffect(() => {
		if (errores) {
			setErrorServicio(true);
			setOpenAlert1(true);
			setSeverity("error");
			setMessage("Favor de llenar los Datos faltantes del formulario.");
			if (errores?.folio || errores?.fecha || errores?.celular || errores?.nombreCompleto || errores?.correo) {
				document.getElementById("generales2").click();
			} else {
				document.getElementById("factura2").click();
			}
		} else {
			setOpenAlert1(false);
		}
	}, [errores]);

	useEffect(() => {
		if (errores && servicios.length === 0) {
			setErrorServicio(true);
			setOpenAlert1(true);
			setSeverity("error");
			setMessage("Favor de llenar los Datos faltantes del formulario.");

		} else {
			setErrorServicio(false);
		}
	}, [errores, servicios]);


	useEffect(() => {
		if (loadingButton1) {
			setTimeout(() => setLoadingButton1(false), 600);
		}
	}, [setLoadingButton1, loadingButton1]);

	const onSubmitClicked = async (event) => {
		Swal.fire({
			title: '¿Está seguro que los datos proporcionados están correctamente escritos? ¿Desea Continuar?',
			showDenyButton: true,
			icon:'info',
			confirmButtonText: 'Si',
			denyButtonText: `Cancelar`,
		}).then((result) => {
			if (result.isConfirmed) {
				try {
					setLink("");
					if (servicios.length === 0) {
						setErrorServicio(true);
						setOpenAlert1(true);
						setSeverity("error");
						setMessage("Favor de agregar uno o más servicios al formulario.");
						document.getElementById("servicios2").click();
						return;
					} else {
						setCargando(true);
						setErrorServicio(false);
						setOpenAlert1(false);
						propiedades.servicios = servicios;
						setPropiedades(propiedades);
						peticion("publico/factura", propiedades, "/factura", false);
					}

				} catch (error) {
					//
				}
			} else if (result.isDenied) {
			}
		})
	};
	//////////////////Funtions////////////////////

	const handleChangeCantidad = (event, row) => {
		servicios.map((data) => {
			if (data.idServicio === row.idServicio) {
				if (event.target.value < 0) {
					setObjServicios({
						...objServicios,
						[row.idServicio]: 1,
					});
				} else if (
					event.target.value.substring(0, 1) === 0
				) {
					setObjServicios({
						...objServicios,
						[row.idServicio]: 1,
					});
				} else if (event.target.value === "e") {
					setObjServicios({
						...objServicios,
						[row.idServicio]: 1,
					});
				} else if (event.target.value > 100000) {
					setObjServicios({
						...objServicios,
						[row.idServicio]: 1,
					});
				} else if (event.target.value === 0) {
					setObjServicios({
						...objServicios,
						[row.idServicio]: 1,
					});
				} else {
					data.cantidad = event.target.value;
					setObjServicios({
						...objServicios,
						[row.idServicio]: event.target.value,
					});
				}
			}

		})

	};

	const onDowload = () => {
		setLoadingDowload(true);
	}
	//////////////////Funtions////////////////////

	// if (loadingPage) return <FullScreenLoader />;

	return (
		<>
			<Container className="factura" component="main"  >

				<Paper className={facturaJSS.papper}>
					<Box className={facturaJSS.solicitarFactura}>
						<img src={img} alt="logo" />
						<div id="generales"></div>
						<Box className={facturaJSS.solicitarFacturaContainer} component="div">
							<Grid container>
								<Grid item xs={12} md={5}>
									<Typography variant="h4" component="h4">
										Solicitar Factura.
									</Typography>
								</Grid>
								<Grid item xs={12} md={8}>
									<Typography variant="h5" component="h5">
										Llene los campos para completar el proceso de facturación.
									</Typography>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Paper>

				<Paper className={facturaJSS.papper}>
					<Typography variant="h4" gutterBottom>
						Datos Generales
					</Typography>
					<a id="generales2" href='#generales' className='page-scroll'> </a>
					<Grid className={facturaJSS.datosGenerales} container spacing={2}>
						<Grid className="campo" item xs={6} sm={6} md={4} xl={4}>
							<h6>Folio</h6>
							<Divider style={{ backgroundColor: "blue", marginBottom: "5%" }} />
							<TextField
								label="Folio"
								size="large"
								color="secondary"
								style={{ height: "1%" }}
								fullWidth
								onChange={(e) =>
									setPropiedades((prevState) => ({
										...prevState,
										...prevState.propiedades,
										folio: e.target.value,
									}))
								}
								inputProps={{
									value: propiedades.folio,
								}}
								helperText={errores?.folio}
								error={Boolean(errores?.folio)}
							/>
						</Grid>
						{/* <Grid className="campo" item xs={6} sm={6} md={4} xl={4}>
							<h6>Fecha Factura</h6>
							<Divider style={{ backgroundColor: "blue", marginBottom: "5%" }} />
							<DatePicker
								labelText="Fecha"
								disableFuture={true}
								color="secondary"
								value={propiedades.fecha}
								onChange={(e) =>
									setPropiedades((prevState) => ({
										...prevState,
										...prevState.propiedades,
										fecha: moment(e).format("YYYY-MM-DD H:m:s"),
									}))
								}
								helperText={errores?.fecha}
								error={Boolean(errores?.fecha)}
								size="large"
								minDate={new Date('2021-01-01T21:11:54')}
							/>
						</Grid> */}

						<Grid className="campo" item xs={12} sm={12} md={4} xl={4}>
							<h6 style={{ fontWeight: "bold" }}>Celular</h6>
							<Divider style={{ backgroundColor: "blue", marginBottom: "5%" }} />
							<InputRegex
								id="standard-multiline-static"
								label="Celular"
								fullWidth
								typeInput="phone"
								color="secondary"
								value={propiedades.telefono}
								maxLength={10}
								onChange={(e) =>
									setPropiedades((prevState) => ({
										...prevState,
										...prevState.propiedades,
										telefono: e.target.value.replaceAll(/[-() ]/g, ''),
									}))
								}
								helperText={errores?.telefono}
								error={Boolean(errores?.telefono)}
							/>
						</Grid>

						{/* nextSeccion */}

						<Grid className="campo" item xs={12} sm={12} md={7} xl={7}>
							<h6 style={{ fontWeight: "bold" }}>Nombre Completo</h6>
							<Divider style={{ backgroundColor: "blue", marginBottom: "3.5%" }} />
							<TextField
								label="Nombre Completo"
								size="large"
								style={{ height: "1%" }}
								color="secondary"
								fullWidth
								onChange={(e) =>
									setPropiedades((prevState) => ({
										...prevState,
										...prevState.propiedades,
										nombreCompleto: e.target.value.toUpperCase(),
									}))
								}
								inputProps={{
									value: propiedades.nombreCompleto,
								}}
								helperText={errores?.nombreCompleto}
								error={Boolean(errores?.nombreCompleto)}
							/>
						</Grid>

						<Grid className="campo" item xs={6} sm={6} md={5} xl={5}>
							<h6 style={{ fontWeight: "bold" }}>Correo</h6>
							<Divider style={{ backgroundColor: "blue", marginBottom: "5%" }} />
							<TextField
								label="Correo"
								variant="outlined"
								color="secondary"
								fullWidth
								helperText={errores?.email}
								error={Boolean(errores?.email)}
								size="large"
								onChange={(e) =>
									setPropiedades((prevState) => ({
										...prevState,
										...prevState.propiedades,
										email: e.target.value,
									}))
								}
								inputProps={{
									value: propiedades.email,
								}}
							/>
						</Grid>
					</Grid>
				</Paper>

				<Paper className={facturaJSS.papper}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Box
								component="div"
								className={facturaJSS.solicitarFacturaContainer}
							>
								<Grid container>
									<Grid item xs={12} md={5}>
										<Typography variant="h4" component="h4">
											Seleccione  <TouchAppIcon className={facturaJSS.touchApp} />
										</Typography>
									</Grid>
									<Grid item xs={12} md={8}>
										<Typography variant="h5" component="h5" style={{ marginTop: "5" }}>
											Los servicios brindados en su ticked.
										</Typography>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<div id="servicios"></div>
						<Grid item xs={12}>
							<a id="servicios2" href='#servicios' className='page-scroll'> </a>
							<Typography
								variant="h4"
								className={classes.typographyModified}
								gutterBottom
							>
								Datos Servicios
							</Typography>
							<Grid container alignItems="center" justify="center" spacing={2}>
								<Grid item xs={12} md={6} >
									<AsyncAutocompleteSearchBeta
										size="medium"
										label="Servicios"
										color="secondary"
										labelProp="concepto"
										ruta="publico/servicios"
										onChange={setServicioCallback}
										extraParams={servicioParamsMemo}
										clearInput={clearInputServicio}
										campoError="idServicio"
										helperText={errorServicio}
										error={Boolean(errorServicio)}
										publica={true}
									//onKeyUp={nextFocus}

									/>
								</Grid>
								<Grid item xs={8} md={3}>
									<LoadingButton
										loading={loadingButton1}
										loadingPosition="start"
										startIcon={<AddCircleOutlineIcon />}
										variant="contained"
										onClick={onAddService}
										size="large"
										fullWidth
										color="primary"
										style={{ fontSize: "13px", backgroundColor: "#25607A" }}
									>
										Agregar Servicio
									</LoadingButton>
								</Grid>

							</Grid>
						</Grid>
						<Grid item xs={12}>
							<TableContainer className={facturaJSS.tablaFactura} component={Paper} >
								<Table sx={{ minWidth: 650 }} aria-label="simple table" style={{ cursor: "pointer", boxShadow: "12px 12px 2px 1px rgba(0, 0, 255, .2)" }}>
									<TableHead className={facturaJSS.tablaFacturaHead} >
										<TableRow >
											<TableCell className="tablaFacturaHeadRowCell" align="center" > <div className="tablaFacturaHeadRow" >ID</div> </TableCell>
											<TableCell className="tablaFacturaHeadRowCell" > <div className="tablaFacturaHeadRow">CONCEPTO</div> </TableCell>
											<TableCell className="tablaFacturaHeadRowCell" align="center" > <div className="tablaFacturaHeadRow">CANTIDAD</div> </TableCell>
											<TableCell className="tablaFacturaHeadRowCell" align="center" > <div className="tablaFacturaHeadRow">BORRAR</div> </TableCell>
										</TableRow>
									</TableHead>
									<TableBody className="tablaFacturaBody">
										{
											loadingButton1 ?
												(
													<TableRow key={"loading"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
														<TableCell align="center" component="th" scope="row" colSpan={4}>
															<CircularProgress />
														</TableCell>
													</TableRow>
												)
												:
												(
													servicios.length === 0 ? (
														<TableRow key={"bienvenido"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
															<TableCell component="th" scope="row" colSpan={4}>
																<h4>Favor de agregar un servicio para continuar.</h4>
															</TableCell>
														</TableRow>
													) : (
														servicios.map((row, index) => (
															<TableRow key={index}>
																<TableCell align="center" component="th" scope="row">
																	<h4 >{index + 1}</h4>
																</TableCell>
																<TableCell component="th" scope="row">
																	<h4>{row.concepto.toUpperCase()}</h4>
																</TableCell>
																<TableCell align="center" className="borderTableCell" >
																	<TextField
																		value={objServicios[row.idServicio] ? objServicios[row.idServicio] : 1}
																		onChange={(event) => handleChangeCantidad(event, row)}
																		size="large"
																		color="secondary"
																		variant="outlined"
																		type="number"
																		inputProps={{
																			min: 0,
																		}}
																	/>
																</TableCell>
																<TableCell align="center" component="th" scope="row">
																	<IconButton color="error" onClick={() => { onDropService(row.idServicio, row.concepto) }} aria-label="delete" size="large">
																		<DeleteIcon />
																	</IconButton>
																</TableCell>
															</TableRow>

														))
													)
												)
										}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
				</Paper>

				<Paper className={facturaJSS.papper}>
					<div id="factura"></div>
					<a id="factura2" href='#factura' className='page-scroll'> </a>
					<Typography
						variant="h4"
						className={classes.typographyModified}
						gutterBottom
					>
						Datos Factura
					</Typography>

					<Grid className={facturaJSS.datosFactura} container spacing={2}>

						<Grid className="campo" item xs={12} sm={12} md={6} xl={6}>
							<h6 style={{ fontWeight: "bold" }}>RFC</h6>
							<Divider style={{ backgroundColor: "blue", marginBottom: "5%" }} />
							<TextField
								label="Rfc"
								size="large"
								color="secondary"
								style={{ height: "1%" }}
								fullWidth
								onChange={(e) =>
									setPropiedades((prevState) => ({
										...prevState,
										...prevState.propiedades,
										rfc: e.target.value,
									}))
								}
								inputProps={{
									value: propiedades.rfc,
								}}
								helperText={errores?.rfc}
								error={Boolean(errores?.rfc)}
							/>
						</Grid>

						<Grid className="campo" item xs={12} sm={12} md={6} xl={6}>
							<h6 style={{ fontWeight: "bold" }}>Razon Social</h6>
							<Divider style={{ backgroundColor: "blue", marginBottom: "5%" }} />
							<TextField
								label="Razon Social"
								size="large"
								color="secondary"
								style={{ height: "1%" }}
								fullWidth
								onChange={(e) =>
									setPropiedades((prevState) => ({
										...prevState,
										...prevState.propiedades,
										razonSocial: e.target.value.toUpperCase(),
									}))
								}
								inputProps={{
									value: propiedades.razonSocial,
								}}
								helperText={errores?.razonSocial}
								error={Boolean(errores?.razonSocial)}
							/>
						</Grid>

						<Grid className="campo" item xs={12} sm={12} md={3} xl={3}>
							<h6 style={{ fontWeight: "bold" }}>Código Postal</h6>
							<Divider style={{ backgroundColor: "blue", marginBottom: "5%" }} />

							<InputRegex
								label="Código Postal"
								fullWidth
								color="secondary"
								value={propiedades.codigoPostal}
								maxLength={6}
								onChange={(e) =>
									setPropiedades((prevState) => ({
										...prevState,
										...prevState.propiedades,
										codigoPostal: e.target.value,
									}))

								}
								helperText={errores?.codigoPostal}
								error={Boolean(errores?.codigoPostal)}
							/>

						</Grid>

						<Grid className="campo" item xs={12} sm={12} md={3} xl={3}>
							<h6 style={{ fontWeight: "bold" }}>Forma de Pago</h6>
							<Divider style={{ backgroundColor: "blue", marginBottom: "5%" }} />
							<FormControl fullWidth color="secondary">
								<InputLabel id="demo-simple-select-standard-label">Forma Pago</InputLabel>
								<Select variant="outlined" label="Forma Pago" value={propiedades.formaPago} onChange={handleChangeFormaPago}>
									{
										opcionesPago?.map((e, index) => (
											<MenuItem key={index} value={e.value}><div><span style={{ fontSize: "17" }}>{e.descripcion}</span>{e.icon}</div></MenuItem>
										))
									}
								</Select>
							</FormControl>
						</Grid>

						<Grid className="campo" item xs={12} sm={12} md={3} xl={3}>
							<h6 style={{ fontWeight: "bold" }}>Uso CFDI</h6>
							<Divider style={{ backgroundColor: "blue", marginBottom: "5%" }} />
							<FormControl fullWidth color="secondary">
								<InputLabel id="demo-simple-select-standard-label">CFDI</InputLabel>
								<Select variant="outlined" label="Uso Cfdi" value={propiedades.usoCfdi} onChange={handleChangeMetodoPago}>
									{
										usoCfdi?.map((e, index) => (
											<MenuItem key={index} value={e.value}><span style={{ fontSize: "17" }}>{e.descripcion}</span></MenuItem>
										))
									}
								</Select>
							</FormControl>
						</Grid>

						<Grid className="campo" item xs={12} sm={12} md={3} xl={3}>

							<FormControl fullWidth sx={{ m: 1 }}>
								<h6 style={{ fontWeight: "bold" }}>Monto Total</h6>
								<Divider style={{ backgroundColor: "blue", marginBottom: "2.5%" }} />
								<OutlinedInput
									type="number"
									color="secondary"
									inputProps={{
										min: 0,
										max: 1000000,
									}}
									id="outlined-adornment-amount"
									value={propiedades.total}
									onChange={(e) =>
										setPropiedades((prevState) => ({
											...prevState,
											...prevState.propiedades,
											total: e.target.value,
										}))
									}
									startAdornment={<InputAdornment position="start">$</InputAdornment>}
									label="Total"
									error={Boolean(errores?.total)}
								/>
							</FormControl>
						</Grid>


					</Grid>
				</Paper>

				<Paper className={classes.paper} >
					<Grid container spacing={2} >

						{
							link !== "" &&
							<Grid item xs={2} xl={2}>
								<Grid container justifyContent="flex-end">
									<LoadingButton
										loading={loadingDowload}
										style={{ backgroundColor: "red", color: "white" }}
										component={Link}
										href={link}
										color="secondary"
										loadingPosition="start"
										startIcon={<DownloadIcon />}
										variant="contained"
										onClick={onDowload}
									>
										Descargar Factura
									</LoadingButton>
								</Grid>
							</Grid>
						}

						<Grid item xs={12} xl={12}>
							<Grid container justifyContent="flex-end">
								<LoadingButton
									loading={cargandoBoton}
									loadingPosition="start"
									startIcon={<SendIcon />}
									variant="contained"
									size="large"
									onClick={onSubmitClicked}
									style={{ fontSize: "13px" }}
									color="primary"

								>
									Generar Factura
								</LoadingButton>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
				<br></br>

				<AlertTypes open={openAlert1} onClose={handleCloseAlert1} severity={severity} message={message} />
			</Container>

		</>
	);
}