import React, { useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Paper,
  Switch,
  TextField,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  CircularProgress,
  FormControlLabel,
  Button,
} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { tableCellStyles, tableRowStyles, entradasStyle } from '../../../../utils';
import { useModel } from "../../../../hooks/useModel";
import { useAuth } from "../../../../hooks/useAuth";
import { useAlert } from "../../../../hooks/useAlert";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import InputRegex from "../../../../components/InputRegex";
import { Save } from "@mui/icons-material";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const useEntradasStyle = entradasStyle();

const AntecedentesLaborablesComponent = ({
  idEvaluacion,
  idOrdenServicio,
  setDisabledByName,
  antecedentesLaboral,
  setGuardado,
  accidenteLaboral,
  ...props
}) => {
  const classes = useStyles();
  const [admin] = useLocalStorage("admin", null);
  const { permiso } = useAuth();
  const entradas = useEntradasStyle();
  const { showAlert } = useAlert();
  const [accidenteSw, setAccidenteSw] = useState(true);
  const [loading, setLoading] = useState(false);
  const [laboralDefault, setLaboralDefault] = useState({
    lugarTrabajo: "",
    tipoTrabajo: "",
    condicionesEspeciales: "",
    tiempoTrabajo: "",
  });
  const [accidentesDefault, setAccidentesDefault] = useState({
    empresa: "",
    tipoLesion: "",
    anio: "",
    descripcion: "",
  });
  const [laborales, setLaborales] = useState(antecedentesLaboral ? antecedentesLaboral.length > 0 ? [...antecedentesLaboral] : [] : []);
  const [accidentesLaborales, setAccidentesLaborales] = useState(accidenteLaboral ? accidenteLaboral.length > 0 ? [...accidenteLaboral] : [] : []);
  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "evaluaciones",
      idEvaluacion,
      redirectOnPost: false,
    });

    useEffect(() => {
    if (Object.keys(modelError)?.length > 0 && !updateModelLoading) {
      console.log(modelError)
      Object.keys(modelError).forEach(
        error => {
          console.log(modelError[error])
          showAlert({ message: modelError[error], severity: "warning" })
        }
      )
      setGuardado(false)
    }
  }, [modelError])

  const onGuardar = async () => {
    setDisabledByName("antecedentesLaborables", true);
    updateModel({
      idOrdenServicio,
      idEvaluacion,
      idSucursal: 1,
      laboral: laborales,
      AccidenteLaboral: accidentesLaborales,
    }, true, "clinicaNuevaVida/nuevaConsulta");
    setGuardado(true);
  };
  
  const onDrop = (i, tipo) => {
    setLoading(true);
    if (tipo === "laborales") {
      let copyLaborales = [...laborales];
      copyLaborales.splice(i, 1);
      setLaborales(copyLaborales);
    } else if (tipo === "accidentes") {
      let copyAccidentes = [...accidentesLaborales];
      copyAccidentes.splice(i, 1);
      setAccidentesLaborales(copyAccidentes);
    }
    setLoading(false);
  }
  const onAdd = tipo => {
    setLoading(true);
    if (tipo === "laborales") {
      let copyLaborales = [...laborales];
      copyLaborales.push({ ...laboralDefault });
      setLaborales(copyLaborales);
      setLaboralDefault({
        lugarTrabajo: "",
        tipoTrabajo: "",
        condicionesEspeciales: "",
        tiempoTrabajo: "",
      });
    } else if (tipo === "accidentes") {
      let copyAccidentes = [...accidentesLaborales];
      copyAccidentes.push({ ...accidentesDefault });
      setAccidentesLaborales(copyAccidentes);
      setAccidentesDefault({
        empresa: "",
        tipoLesion: "",
        anio: "",
        descripcion: "",
      });
    }
    setLoading(false);
  }
  return (
    <Grid
      component={Paper}
      container
      className={classes.paper}
      spacing={1}
      style={{ marginTop: 10 }}
    >
      <Grid item xs={12}>
        <div>
          <strong>{"Antecedentes Laborales"}</strong>
        </div>
        <Divider />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <InputRegex
          label="Lugar de Trabajo"
          variant="standard"
          fullWidth
          value={laboralDefault?.lugarTrabajo}
          onChange={e => {
            setLaboralDefault({ ...laboralDefault, lugarTrabajo: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <InputRegex
          label="Tipo de Trabajo"
          variant="standard"
          fullWidth
          value={laboralDefault?.tipoTrabajo}
          onChange={e => {
            setLaboralDefault({ ...laboralDefault, tipoTrabajo: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <InputRegex
          label="Tiempo de Trabajo"
          variant="standard"
          fullWidth
          value={laboralDefault?.tiempoTrabajo}
          onChange={e => {
            setLaboralDefault({ ...laboralDefault, tiempoTrabajo: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <InputRegex
          label="Condiciones Especiales"
          variant="standard"
          fullWidth
          value={laboralDefault?.condicionesEspeciales}
          onChange={e => {
            setLaboralDefault({ ...laboralDefault, condicionesEspeciales: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={12} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
        <Button
          style={{ color: "white" }}
          variant="contained"
          onClick={e => {
            onAdd("laborales");
          }}
        >Agregar Antecedente Laboral</Button>
      </Grid>

      {
        laborales && laborales.length > 0 && (
          <Grid item xs={12}>
            <Table className={entradas.tableEntradas}>
              <TableHead>
                <TableRowModify>
                  <TableCellModify> # </TableCellModify>
                  <TableCellModify>Lugar de Trabajo</TableCellModify>
                  <TableCellModify>Tipo de Trabajo</TableCellModify>
                  <TableCellModify>Tiempo de Trabajo</TableCellModify>
                  <TableCellModify>Condiciones Especiales</TableCellModify>
                  <TableCellModify></TableCellModify>
                </TableRowModify>
              </TableHead>
              <TableBody>
                {!loading ? laborales?.map((lab, i) => {
                  return (
                    <TableRowModify key={i}>
                      <TableCellModify> {i + 1} </TableCellModify>
                      <TableCellModify> {lab.lugarTrabajo} </TableCellModify>
                      <TableCellModify> {lab.tipoTrabajo} </TableCellModify>
                      <TableCellModify> {lab.tiempoTrabajo} </TableCellModify>
                      <TableCellModify> {lab.condicionesEspeciales} </TableCellModify>
                      <TableCellModify>
                        <IconButton
                          onClick={() => {
                            onDrop(i, "laborales");
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </TableCellModify>
                    </TableRowModify>
                  )
                }) : (
                  <TableRowModify>
                    <TableCellModify></TableCellModify>
                    <TableCellModify></TableCellModify>
                    <TableCellModify> <CircularProgress /> </TableCellModify>
                    <TableCellModify></TableCellModify>
                  </TableRowModify>
                )}
              </TableBody>
            </Table>
          </Grid>
        )
      }

      <Grid item xs={12}>
        <div>
          <strong>{"Accidentes Laborales"}</strong>
        </div>
        <Divider />
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <InputRegex
          label="Empresa"
          variant="standard"
          fullWidth
          value={accidentesDefault?.empresa}
          onChange={e => {
            setAccidentesDefault({ ...accidentesDefault, empresa: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <InputRegex
          label="Tipo de Lesión"
          variant="standard"
          fullWidth
          value={accidentesDefault?.tipoLesion}
          onChange={e => {
            setAccidentesDefault({ ...accidentesDefault, tipoLesion: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <InputRegex
          label="Año"
          variant="standard"
          fullWidth
          value={accidentesDefault?.anio}
          onChange={e => {
            setAccidentesDefault({ ...accidentesDefault, anio: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <InputRegex
          label="Descripción"
          variant="standard"
          fullWidth
          value={accidentesDefault?.descripcion}
          onChange={e => {
            setAccidentesDefault({ ...accidentesDefault, descripcion: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={12} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
        <Button
          style={{ color: "white" }}
          variant="contained"
          onClick={e => {
            onAdd("accidentes");
          }}
        >Agregar Accidente Laboral</Button>
      </Grid>


      {
        accidentesLaborales && accidentesLaborales.length > 0 && (
          <Grid item xs={12}>
            <Table className={entradas.tableEntradas}>
              <TableHead>
                <TableRowModify>
                  <TableCellModify> # </TableCellModify>
                  <TableCellModify>Empresa</TableCellModify>
                  <TableCellModify>Tipo de Lesión</TableCellModify>
                  <TableCellModify>Año</TableCellModify>
                  <TableCellModify>Descripción</TableCellModify>
                  <TableCellModify></TableCellModify>
                </TableRowModify>
              </TableHead>
              <TableBody>
                {!loading ? accidentesLaborales?.map((acc, i) => {
                  return (
                    <TableRowModify key={i}>
                      <TableCellModify> {i + 1} </TableCellModify>
                      <TableCellModify> {acc.empresa} </TableCellModify>
                      <TableCellModify> {acc.tipoLesion} </TableCellModify>
                      <TableCellModify> {acc.anio} </TableCellModify>
                      <TableCellModify> {acc.descripcion} </TableCellModify>
                      <TableCellModify>
                        <IconButton
                          onClick={() => {
                            onDrop(i, "accidentes");
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </TableCellModify>
                    </TableRowModify>
                  )
                }) : (
                  <TableRowModify>
                    <TableCellModify></TableCellModify>
                    <TableCellModify></TableCellModify>
                    <TableCellModify> <CircularProgress /> </TableCellModify>
                    <TableCellModify></TableCellModify>
                  </TableRowModify>
                )}
              </TableBody>
            </Table>
          </Grid>
        )
      }

      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <LoadingButton
            disabled={
              modelLoading ||
                updateModelLoading ||
                admin ||
                (permiso && permiso[0]["evaluaciones"] & 2) === 2
                ? false
                : true
            }
            loading={modelLoading || updateModelLoading}
            variant="contained"
            onClick={() => onGuardar()}
            size="medium"
            startIcon={<Save />}
            style={{ color: "#fff" }}
          >
            GUARDAR
            {/* {(!editing ? "GUARDAR" : "MODIFICAR")} */}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { AntecedentesLaborablesComponent };