import React from 'react'
import { Page, Document, Text, StyleSheet, Image, pdf, View } from '@react-pdf/renderer'
import { useEffect, useState, useRef } from "react";
import { borderLeft } from '@mui/system'
import EvaluacionesClinica from '../views/evaluacionMedica/hospitalito'

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: 'column',
    backgroundColor: '#FCFEFF',
    padding: "20px",
  },
  view: {
    margin: "5px",
    width: "100%",
  },
  header: {
    width: "100%",
    padding: "5px",
    textAlign: "center",
  },
  rows: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  }
})

const Hospitalito = ({ data, ...props }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.view}>
          <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}>
            <div style={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
              <Image style={{ width: "200px" }} src={"https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2FlogoHospitalito2.png?alt=media&token=674b6581-6971-4f27-a6ca-a6c9fa84125a"} />
            </div>
            <div style={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
              <Text style={{ fontSize: "14px", justifyContent: "center" }}>LHOSPITALITO</Text>
              <Text style={{ fontSize: "14px" }}>Blvd. Juan Navarrete 192-L, Valle Grande, 83200 Hermosillo, Son.</Text>
            </div>
          </div>
        </View>

        <View style={styles.view}>
          <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}>
            <div style={styles.header}>
              <Text style={{ fontSize: "14px" }}>NOTAS DE URGENCIAS</Text>
            </div>
            <div style={{ width: "100%", height: "2%", backgroundColor: "#838383" }}></div>
            <div style={styles.rows}>
              <div style={{
                width: "16.66%",
                padding: "5px",
                textAlign: "center",
              }}>
                <Text style={{ fontSize: "12px" }}>Expediente:</Text>
              </div>
              <div style={{
                width: "16.66%",
                padding: "5px",
                textAlign: "center",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.idOrdenServicio}</Text>
              </div>
              <div style={{
                width: "16.66%",
                padding: "5px",
                textAlign: "center",
              }}>
                <Text style={{ fontSize: "12px" }}>Nombre:</Text>
              </div>
              <div style={{
                width: "30%",
                padding: "5px",
                textAlign: "center",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.nombreCompleto}</Text>
              </div>
              <div style={{
                width: "10%",
                padding: "5px",
                textAlign: "center",
              }}>
                <Text style={{ fontSize: "12px" }}>Sexo:</Text>
              </div>
              <div style={{
                width: "10%",
                padding: "5px",
                textAlign: "center",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.genero}</Text>
              </div>
              <div style={{
                width: "10%",
                padding: "5px",
                textAlign: "center",
              }}>
                <Text style={{ fontSize: "12px" }}>Edad:</Text>
              </div>
              <div style={{
                width: "10%",
                padding: "5px",
                textAlign: "center",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.edad}</Text>
              </div>
            </div>
            <div style={styles.rows}>
              <div style={{
                width: "25%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "12px" }}>Tipo Convenio:</Text>
              </div>
              <div style={{
                width: "25%",
                padding: "5px",
                textAlign: "center",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detalleConvenio?.nombreConvenio}</Text>
              </div>
              <div style={{
                width: "25%",
                padding: "5px",
                textAlign: "center",
              }}>
                <Text style={{ fontSize: "12px" }}>Fecha Nacimiento:</Text>
              </div>
              <div style={{
                width: "25%",
                padding: "5px",
                textAlign: "center",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.fechaNacimiento}</Text>
              </div>
            </div>
            <div style={styles.rows}>
              <div style={{
                width: "20%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "12px" }}>Empresa:</Text>
              </div>
              <div style={{
                width: "20%",
                padding: "5px",
                textAlign: "center",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.entradaInventario?.empresa}</Text>
              </div>
            </div>
            <div style={styles.rows}>
              <div style={{
                width: "30%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "12px" }}>Fecha de ingreso:</Text>
              </div>
              <div style={{
                width: "70%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.fechaSolicitud}</Text>
              </div>
            </div>
            <div style={styles.rows}>
              <div style={{
                width: "20%",
                padding: "5px",
                textAlign: "start"
              }}>
                <Text style={{ fontSize: "12px" }}>Diagnóstico:</Text>
              </div>
              <div style={{
                width: "80%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.diagnostico?.diagnostico}</Text>
              </div>
            </div>
            <div style={{ width: "100%", height: "2%", backgroundColor: "#838383" }}></div>
          </div>
        </View>

        <View style={styles.view}>
          <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}>
            <div style={styles.rows}>
              <div style={{
                width: "30%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "12px" }}>Fecha y hora del registro:</Text>
              </div>
              <div style={{
                width: "70%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.creado}</Text>
              </div>
            </div>
            <div style={styles.rows}>
              <div style={styles.header}>
                <Text style={{ fontSize: "14px" }}>MÉDICOS</Text>
              </div>
            </div>
            <div style={styles.rows}>
              <div style={{
                width: "30%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "12px" }}>Médico que recibe:</Text>
              </div>
              <div style={{
                width: "70%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.ordenServicioDoctores[0]?.doctor[0]?.nombreCompleto}</Text>
              </div>
            </div>
            <div style={styles.rows}>
              <div style={{
                width: "30%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "12px" }}>Médico que atendió:</Text>
              </div>
              <div style={{
                width: "70%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.ordenServicioDoctores[0]?.doctor[0]?.nombreCompleto}</Text>
              </div>
            </div>

            {data?.signos &&
              <div style={styles.rows}>
                <div style={styles.header}>
                  <Text style={{ fontSize: "14px" }}>SIGNOS VITALES</Text>
                </div>
              </div>
            }
            {data?.signos &&
              <div style={styles.rows}>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "12px" }}>Temperatura</Text>
                </div>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "10px" }}>{data?.signos?.temperatura}</Text>
                </div>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "12px" }}>Frecuencia respiratoria</Text>
                </div>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "10px" }}>{data?.signos?.frecuenciaRespiratoria}</Text>
                </div>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "12px" }}>Frecuencia cardiaca</Text>
                </div>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "10px" }}>{data?.signos?.frecuenciaCardiaca}</Text>
                </div>
              </div>
            }
            {data?.signos &&
              <div style={styles.rows}>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "12px" }}>Saturación oxígeno</Text>
                </div>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "10px" }}>{data?.signos?.saturacionOxigeno}</Text>
                </div>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "12px" }}>Dextrosis</Text>
                </div>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "10px" }}>{data?.signos?.dextrosis}</Text>
                </div>
              </div>
            }
            {data?.signos &&
              <div style={styles.rows}>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "12px" }}>Sistolica</Text>
                </div>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "10px" }}>{data?.signos?.sistolica}</Text>
                </div>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "12px" }}>Diastolica</Text>
                </div>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "10px" }}>{data?.signos?.diastolica}</Text>
                </div>
              </div>

            }

            {data?.signos &&
              <div style={styles.rows}>
                <div style={styles.header}>
                  <Text style={{ fontSize: "14px" }}>SOMATOMETRÍA</Text>
                </div>
              </div>
            }
            {data?.signos &&
              <div style={styles.rows}>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "12px" }}>Peso</Text>
                </div>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "10px" }}>{data?.signos?.peso}</Text>
                </div>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "12px" }}>Índice de masa corporal</Text>
                </div>
                <div style={{
                  width: "16.66%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "10px" }}>{data?.signos?.imc}</Text>
                </div>
              </div>
            }

            {data?.ordenServicio.solicitudRH &&
              <div style={styles.rows}>
                <div style={styles.header}>
                  <Text style={{ fontSize: "14px" }}>TRAÍDO POR</Text>
                </div>
              </div>
            }
            {data?.ordenServicio.solicitudRH &&
              <div style={styles.rows}>
                <div style={{
                  width: "100%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.solicitudRH}</Text>
                </div>
              </div>
            }
            <div style={styles.rows}>
              <div style={styles.header}>
                <Text style={{ fontSize: "14px" }}>ALERGIA(S) DEL PACIENTE</Text>
              </div>
            </div>
            <div style={styles.rows}>
              <div style={{
                width: "100%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.ordenServicio?.detallePaciente?.alergias}</Text>
              </div>
            </div>
          </div>
        </View>

        {data?.notasEnfermeria &&
          <View style={styles.view}>
            <div style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}>
              <div style={styles.rows}>
                <div style={styles.header}>
                  <Text style={{ fontSize: "14px" }}>TRIAGE</Text>
                </div>
              </div>
              <div style={styles.rows}>
                <div style={{
                  width: "20%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "10px" }}>{data?.notasEnfermeria?.tipoTriage}</Text>
                </div>
                {data?.notasEnfermeria?.tipoTriage == "Triage I" &&
                  <div style={{
                    width: "80%",
                    padding: "5px",
                    textAlign: "start",
                    backgroundColor: "#EA0000",
                  }}>
                    <Text style={{ fontSize: "10px" }}>{data?.notasEnfermeria?.triange}</Text>
                  </div>
                }
                {
                  data?.notasEnfermeria?.tipoTriage == "Triage II" &&
                  <div style={{
                    width: "80%",
                    padding: "5px",
                    textAlign: "start",
                    backgroundColor: "#F65810"
                  }}>
                    <Text style={{ fontSize: "10px" }}>{data?.notasEnfermeria?.triange}</Text>
                  </div>
                }
                {
                  data?.notasEnfermeria?.tipoTriage == "Triage III" &&
                  <div style={{
                    width: "80%",
                    padding: "5px",
                    textAlign: "start",
                    backgroundColor: "#FFFF00"
                  }}>
                    <Text style={{ fontSize: "10px" }}>{data?.notasEnfermeria?.triange}</Text>
                  </div>
                }
                {
                  data?.notasEnfermeria?.tipoTriage == "Triage IV" &&
                  <div style={{
                    width: "80%",
                    padding: "5px",
                    textAlign: "start",
                    backgroundColor: "#638B2C"
                  }}>
                    <Text style={{ fontSize: "10px" }}>{data?.notasEnfermeria?.triange}</Text>
                  </div>
                }
                {
                  data?.notasEnfermeria?.tipoTriage == "Triage V" &&
                  <div style={{
                    width: "80%",
                    padding: "5px",
                    textAlign: "start",
                    backgroundColor: "#3860B2"
                  }}>
                    <Text style={{ fontSize: "10px" }}>{data?.notasEnfermeria?.triange}</Text>
                  </div>
                }
              </div>
            </div>
          </View>
        }

        {data?.antecedente &&
          <View style={styles.view}>
            <div style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}>
              <div style={styles.rows}>
                <div style={styles.header}>
                  <Text style={{ fontSize: "14px" }}>ANTECEDENTES</Text>
                </div>
              </div>
              <div style={styles.rows}>
                <div style={{
                  width: "20%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "12px" }}>AHF</Text>
                </div>
                <div style={{
                  width: "80%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "10px" }}>{data?.antecedente?.ahf}</Text>
                </div>
              </div>
              <div style={styles.rows}>
                <div style={{
                  width: "20%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "12px" }}>APNP</Text>
                </div>
                <div style={{
                  width: "80%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "10px" }}>{data?.antecedente?.apnp}</Text>
                </div>
              </div>
              <div style={styles.rows}>
                <div style={{
                  width: "20%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "12px" }}>APP</Text>
                </div>
                <div style={{
                  width: "80%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "10px" }}>{data?.antecedente?.app}</Text>
                </div>
              </div>
              <div style={styles.rows}>
                <div style={{
                  width: "20%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "12px" }}>AGO</Text>
                </div>
                <div style={{
                  width: "80%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "10px" }}>{data?.antecedente?.ago}</Text>
                </div>
              </div>
            </div>
          </View>
        }

        {data?.padecimiento &&
          <View style={styles.view}>
            <div style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}>
              <div style={styles.rows}>
                <div style={styles.header}>
                  <Text style={{ fontSize: "14px" }}>MOTIVO DE LA CONSULTA O PADECIMIENTO ACTUAL</Text>
                </div>
              </div>
              <div style={styles.rows}>
                <div style={{
                  width: "100%",
                  padding: "5px",
                  textAlign: "start",
                }}>
                  <Text style={{ fontSize: "10px" }}>{data?.padecimiento?.padecimiento}</Text>
                </div>
              </div>
            </div>
          </View>
        }

        {data?.exploracion &&
          <View style={styles.view}>
            <div style={styles.rows}>
              <div style={styles.header}>
                <Text style={{ fontSize: "14px" }}>EXPLORACIÓN FÍSICA Y ESTADO MENTAL</Text>
              </div>
            </div>
            <div style={styles.rows}>
              <div style={{
                width: "100%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.exploracion?.descripcion}</Text>
              </div>
            </div>
          </View>
        }

        {data?.notasEvolucion &&
        <View style={styles.view}>
          <div style={styles.rows}>
            <div style={styles.header}>
              <Text style={{ fontSize: "14px" }}>NOTAS DE EVOLUCION</Text>
            </div>
          </div>
          <div style={styles.rows}>
            <div style={{
              width: "16.66%",
              padding: "5px",
              textAlign: "start",
            }}>
              <Text style={{ fontSize: "12px" }}>Frecuencia respiratoria</Text>
            </div>
            <div style={{
              width: "16.66%",
              padding: "5px",
              textAlign: "start",
            }}>
              <Text style={{ fontSize: "10px" }}>{data?.notasEvolucion?.frecuenciaRespiratoria}</Text>
            </div>
            <div style={{
              width: "16.66%",
              padding: "5px",
              textAlign: "start",
            }}>
              <Text style={{ fontSize: "12px" }}>Frecuencia cardiaca</Text>
            </div>
            <div style={{
              width: "16.66%",
              padding: "5px",
              textAlign: "start",
            }}>
              <Text style={{ fontSize: "10px" }}>{data?.notasEvolucion?.frecuenciaCardiaca}</Text>
            </div>
          </div>
          <div style={styles.rows}>
            <div style={{
              width: "16.66%",
              padding: "5px",
              textAlign: "start",
            }}>
              <Text style={{ fontSize: "12px" }}>Sistolica</Text>
            </div>
            <div style={{
              width: "16.66%",
              padding: "5px",
              textAlign: "start",
            }}>
              <Text style={{ fontSize: "10px" }}>{data?.notasEvolucion?.presionSistolica}</Text>
            </div>
            <div style={{
              width: "16.66%",
              padding: "5px",
              textAlign: "start",
            }}>
              <Text style={{ fontSize: "12px" }}>Diastolica</Text>
            </div>
            <div style={{
              width: "16.66%",
              padding: "5px",
              textAlign: "start",
            }}>
              <Text style={{ fontSize: "10px" }}>{data?.notasEvolucion?.presionDiastolica}</Text>
            </div>
            <div style={{
              width: "16.66%",
              padding: "5px",
              textAlign: "start",
            }}>
              <Text style={{ fontSize: "12px" }}>Saturacion de oxigeno</Text>
            </div>
            <div style={{
              width: "16.66%",
              padding: "5px",
              textAlign: "start",
            }}>
              <Text style={{ fontSize: "10px" }}>{data?.notasEvolucion?.saturacionOxigeno}</Text>
            </div>
          </div>
          <div style={styles.rows}>
            <div style={{
              width: "20%",
              padding: "5px",
              textAlign: "start",
            }}>
              <Text style={{ fontSize: "12px" }}>Seguimiento</Text>
            </div>
            <div style={{
              width: "80%",
              padding: "5px",
              textAlign: "start",
            }}>
              <Text style={{ fontSize: "10px" }}>{data?.notasEvolucion?.seguimiento}</Text>
            </div>
          </div>
        </View>
        }
        {data?.diagnostico &&
          <View style={styles.view}>
            <div style={styles.rows}>
              <div style={styles.header}>
                <Text style={{ fontSize: "14px" }}>DIAGNÓSTICO(S) PRINCIPAL(ES)</Text>
              </div>
            </div>
            <div style={styles.rows}>
              <div style={{
                width: "50%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.diagnostico?.diagnostico}</Text>
              </div>
              <div style={{
                width: "10%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "10px" }}>CIE-10:</Text>
              </div>
              <div style={{
                width: "40%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.diagnostico?.diagnosticoEnfermedad[0]?.enfermedad?.NOMBRE}</Text>
              </div>
            </div>
          </View>
        }

        {data?.plan &&
          <View style={styles.view}>
            <div style={styles.rows}>
              <div style={styles.header}>
                <Text style={{ fontSize: "14px" }}>TRÁTAMIENTO</Text>
              </div>
            </div>
            <div style={styles.rows}>
              <div style={{
                width: "100%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.plan?.tratamiento}</Text>
              </div>
            </div>
          </View>
        }

        {data?.plan &&
          <View style={styles.view}>
            <div style={styles.rows}>
              <div style={styles.header}>
                <Text style={{ fontSize: "14px" }}>PRONÓSTICO</Text>
              </div>
            </div>
            <div style={styles.rows}>
              <div style={{
                width: "100%",
                padding: "5px",
                textAlign: "start",
              }}>
                <Text style={{ fontSize: "10px" }}>{data?.plan?.pronostico}</Text>
              </div>
            </div>
          </View>
        }

        <View style={styles.view}>
          <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <Image style={{ width: "150px" }} src={
                data?.ordenServicio?.usuario
                  ?
                  data?.ordenServicio?.usuario?.detalleDoctor?.urlFile
                  :
                  data?.ordenServicio?.ordenServicioDoctores[0]?.detalleDoctores?.urlFile
              } />
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <div style={{ width: "40%", height: "1px", backgroundColor: "#838383" }}></div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <Text style={{ fontSize: "10px" }}>{
                data?.ordenServicio?.usuario
                  ?
                  data?.ordenServicio?.usuario?.detalleDoctor?.nombreCompleto
                  :
                  data?.ordenServicio?.ordenServicioDoctores[0]?.detalleDoctores?.nombreCompleto
              }</Text>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <Text style={{ fontSize: "10px" }}>{
                data?.ordenServicio?.usuario
                  ?
                  data?.ordenServicio?.usuario?.detalleDoctor?.rol
                  :
                  data?.ordenServicio?.ordenServicioDoctores[0]?.detalleDoctores?.rol
              }</Text>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <Text style={{ fontSize: "10px" }}>Cédula: {
                data?.ordenServicio?.usuario
                  ?
                  data?.ordenServicio?.usuario?.detalleDoctor?.cp
                  :
                  data?.ordenServicio?.ordenServicioDoctores[0]?.detalleDoctores?.cp
              }</Text>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <Text style={{ fontSize: "10px", textAlign: "center" }}>Médico tratante</Text>
            </div>
          </div>
        </View>
      </Page>

    </Document>
  )
}

const blobToExport = async (data) => {
  return await pdf(<Hospitalito data={data} />).toBlob()
}

export default blobToExport