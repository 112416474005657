////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import Chart from "react-apexcharts";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////

const Graphs = ({
    typeGraph,
    cantidad,
    title,
    subTitle,
    colors,
    datos,
    ...props
}) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const [opciones, setOpciones] = useState({});

    const [series, setSeries] = useState([]);
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        switch (typeGraph) {
            case "bar":
                setOpciones({
                    chart: { type: "bar" },
                    xaxis: {
                        categories: datos,
                    },
                    colors: colors,
                    tooltip: {
                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                            return `<div class="arrow_box" style="padding:10px;">
                                <span style="font-size:1rem;font-weight:600;color:#1D5E7B;">${w.config.series[0].name[dataPointIndex]} -  ${series[seriesIndex][dataPointIndex]}</span>
                                </div>`
                        }
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            horizontal: true,
                        }
                    },
                    title: {
                        text: title,
                        align: 'center',
                        floating: true
                    },
                    subtitle: {
                        text: subTitle,
                        align: 'center',
                    },
                });
                setSeries([
                    { name: datos, data: cantidad },
                ]);
                break;
            case "pie":
                setOpciones({
                    chart: {
                        width: 380,
                        type: 'pie',
                    },
                    labels: datos,
                    colors: colors,
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                });
                setSeries(cantidad);
                break;
            case "radialBar":
                setOpciones({
                    chart: {
                        height: 350,
                        type: 'radialBar',
                    },
                    colors: colors,
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                size: '70%',
                            }
                        },
                    },
                    labels: datos,
                });
                setSeries(cantidad);
                break;
            default:
                break;
        }
    }, []);

    return (
        <Chart
            options={opciones}
            series={series}
            type={typeGraph}
        />
    );
}
export default Graphs;