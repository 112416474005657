// <========================= COMPONENTES =============================>
import Tabs from "../../components/Tabs";
import Listado from "./listado";
import { FileManagerProvider } from "../../hooks/useFileManager";
import Agregar from "./agregar";
// <========================= STYLES =============================>
// <========================= ICONOS =============================>
import ViewListIcon from '@mui/icons-material/ViewList';
import { Edit } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';



const Index = ({ match }) => {
    const editing = match?.params?.idCampana !== undefined;
    return (
        <FileManagerProvider>
            <Tabs 
                tabs={
                    [
                        {
                            title: "Listado", 
                            content: <Listado/>,
                            icon:<ViewListIcon/>,
                        },
                        {
                            title: !editing ? "Agregar" : "Editar", 
                            content: !editing ? <Agregar/> : <Agregar idCampana={match?.params?.idCampana}/>,
                            icon:!editing ? <AddCircleIcon/> : <Edit/>, 
                        }
                    ]
                }
            />
        </FileManagerProvider>
    );
}

export default Index;