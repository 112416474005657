/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
// <========================= COMPONENTES =============================>
import {
    Paper,
    Typography,
    Box,
    BottomNavigation,
    BottomNavigationAction,
    FormGroup,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    Accordion,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    AccordionSummary,
    AccordionDetails,
    Switch,
    Grid,
} from "@mui/material";
import AsyncAutocompleteSearchBeta from '../../components/AsyncAutocompleteSearchBeta';
import { usePost } from "../../hooks/usePost";
import { useAlert } from "./../../hooks/useAlert";
import Service from "../../services/api";
import Swal from 'sweetalert2';
import { useAuth } from "../../hooks/useAuth";
import { useGet } from "../../hooks/useGet";
// <========================= STYLES =============================>
import { permisosStyle, tableCellStyles, tableRowStyles, baseStyles, capitalizeFirst } from '../../utils';
// <========================= ICONOS =============================>
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ViewListIcon from '@mui/icons-material/ViewList';
import LoadingButton from '@mui/lab/LoadingButton';
import { Delete } from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const TableCellModify = tableCellStyles()(TableCell);
const useStyles = baseStyles();
const TableRowModify = tableRowStyles()(TableRow);
const pStyles = permisosStyle();
const Index = ({ match }) => {
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
    // <=======================================STATES DEF ETC====================================================> //
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
    const ps = pStyles();
    const classes = useStyles();
    const { showAlert } = useAlert();
    const idUsuario = match.params.idUsuario;
    let [sucursal, setSucursal] = useState(null);
    const [tipoCargando, setTipoCargando] = useState(1);
    const [errores, cargandoBoton, peticion, setCargando, detalles] = usePost();
    const btnNav = useRef(null);
    const { permiso } = useAuth();
    const [btnActivo, setBtnActivo] = useState(true);
    const [principalSelected, setPrincipalSelected] = useState(false);
    const [idSucursal, setIdSucursal] = useState(null);
    const [permisoEditar, setPermisoEditar] = useState(false);
    const [rawPermisos, setRawPermisos] = useState({});
    const [editar, setEditar] = useState(false);
    const url = "usuario/index";
    const [nombreSucursal, setNombreSucursal] = useState("");
    let parametrosIniciales = { pagina: 1, idUsuario: idUsuario, expand: "permisos.sucursal" };

    const [permisos, cargando, actualizar] = useGet({
        initUrl: url,
        params: parametrosIniciales,
        action: true,
    });

    const crearDefaultPermiso = permiso => {
        let permisosKeys = Object.keys(permiso);
        let permisoCreado = {};
        permisosKeys.map(key => {
            if (key != "idUsuario" && key != "idSucursal") {
                if (key.charAt(key.length - 1) == "s") {
                    permisoCreado = { ...permisoCreado, [key]: { ver: true, agregar: false, editar: false, eliminar: false, key: key } }
                } else {
                    permisoCreado = { ...permisoCreado, [key]: false }
                }
            }
        });
        return permisoCreado;
    }

    const [propiedades] = useState({
        uid: "",
        editar: false,
        permisos: crearDefaultPermiso(permiso[0]),
    });
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
    // <=======================================FUNCIONES MEMO====================================================> //
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
    const eliminarPermiso = async (idUsuario, idSucursal) => {
        if (idUsuario && idSucursal) {
            const res = await Swal.fire({
                title: '¿Deseas eliminar este permiso?',
                text: "Favor de corroborar sus datos antes de continuar.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Eliminar!',
                cancelButtonText: 'Cancelar'
            });

            if (res.isConfirmed) {
                showAlert({
                    message: 'Espera un momento.....',
                    severity: 'warning'
                });
                const response = await Service.delete("usuario/eliminar-permisos", { idUsuario, idSucursal });
                if (response && response.detalle.estatus === 200) {
                    showAlert({
                        message: 'El permiso ha sido eliminado con éxito',
                        severity: 'success'
                    });
                    setPermisoEditar(false);
                    setTipoCargando(2);
                    actualizar({
                        urlUpdate: url,
                        properties: parametrosIniciales,
                        loading: true,
                    });
                }
            }
        }
    }
    const sucursalParamsMemo = useMemo(
        () => ({
            pagina: 1,
            limite: 20,
            ordenar: "nombreSucursal.desc",
            notSucursal: true,
            idUsuario: idUsuario
        }),
        [sucursal]
    );

    const formatearPermisos = () => {
        let permisosFormated = [];
        let permisoFormated = {};
        Object.keys(rawPermisos).map(
            key => {
                let p = rawPermisos[key];
                if (key.charAt(key.length - 1) == "s") {
                    let v = 0;
                    if (p.ver) {
                        v += 1;
                    }
                    if (p.agregar) {
                        v += 2;
                    }
                    if (p.editar) {
                        v += 4;
                    }
                    if (p.deshabilitar) {
                        v += 8;
                    }
                    permisoFormated = { ...permisoFormated, [key]: v }
                } else {
                    let v = 0;
                    if (p.val) {
                        v += 1;
                    }
                    if (p.principal) {
                        v += 2;
                    }
                    permisoFormated = { ...permisoFormated, [key]: v }
                }
            }
        );
        permisoFormated = { ...permisoFormated, idSucursal: idSucursal };
        permisosFormated.push(permisoFormated);
        return permisosFormated;
    }

    const guardarPermiso = async (reload) => {
        let obj = propiedades;
        const permisos = formatearPermisos();
        obj = { ...obj, permisos: permisos, uid: idUsuario, editar: editar, idSucursal: idSucursal };
        setTipoCargando(2);
        peticion("usuario/agregar-permisos", obj, "/usuario/permisos/" + idUsuario, true, reload);
    }
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
    // <=======================================EFFECTS CALLBACKS=================================================> //
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
    useEffect(() => {
        if (permisoEditar) {
            let permisosFormated = {};
            let permisoFormated = {};
            Object.keys(permisoEditar).map(
                key => {
                    if (key != "idUsuario" && key != "sucursal" && key != "idSucursal") {
                        if (key.charAt(key.length - 1) == "s") {
                            if ((permisoEditar[key] & 1) === 1) {
                                permisoFormated = { ...permisoFormated, ver: true };
                            } else {
                                permisoFormated = { ...permisoFormated, ver: false };
                            }
                            if ((permisoEditar[key] & 2) === 2) {
                                permisoFormated = { ...permisoFormated, agregar: true };
                            } else {
                                permisoFormated = { ...permisoFormated, agregar: false };
                            }
                            if ((permisoEditar[key] & 4) === 4) {
                                permisoFormated = { ...permisoFormated, editar: true };
                            } else {
                                permisoFormated = { ...permisoFormated, editar: false };
                            }
                            if ((permisoEditar[key] & 8) === 8) {
                                permisoFormated = { ...permisoFormated, deshabilitar: true };
                            } else {
                                permisoFormated = { ...permisoFormated, deshabilitar: false };
                            }
                        } else {
                            if ((permisoEditar[key] & 1) === 1) {
                                permisoFormated = { ...permisoFormated, val: true };
                            }
                            else {
                                permisoFormated = { ...permisoFormated, val: false };
                            }
                            if ((permisoEditar[key] & 2) === 2) {
                                permisoFormated = { ...permisoFormated, principal: true };
                            }
                            else {
                                permisoFormated = { ...permisoFormated, principal: false };
                            }
                        }
                        permisosFormated = { ...permisosFormated, [key]: permisoFormated };
                        setRawPermisos({ ...permisosFormated });
                    }
                }
            );
        }
    }, [permisoEditar]);

    useEffect(() => {
        if (editar) {
            guardarPermiso(false);
            setEditar(false);
        }
    }, [editar]);

    const setSucursalCallback = useCallback(
        (e, v) => {
            if (v !== "" && v) {
                setSucursal(v);
                setIdSucursal(v.idSucursal);
                setRawPermisos({ ...crearDefaultPermiso(permiso[0]) });
            } else {
                setSucursal(null);
            }
        },
        [setSucursal]
    );
    return (
        <>
            <Box sx={{ pb: 7 }}>

                <Paper className={ps.permisosPaperNav} elevation={3}>
                    <BottomNavigation
                        className={ps.btnGroup}
                        showLabels
                        onChange={e => {

                        }}
                    >
                        <BottomNavigationAction className={btnActivo ? ps.btnNavActivo : ps.btnNav} label="Ver Permisos"
                            ref={btnNav}
                            onClick={() => {
                                setBtnActivo(true);
                                setSucursal(null);
                                setPermisoEditar(false);
                                setTipoCargando(1);
                                actualizar({
                                    urlUpdate: url,
                                    properties: parametrosIniciales,
                                    loading: true,
                                });
                            }} icon={<ViewListIcon />} />
                        <BottomNavigationAction className={!btnActivo ? ps.btnNavActivo : ps.btnNav} label="Agregar Permisos"
                            onClick={() => {
                                setBtnActivo(false);
                                setPrincipalSelected(false);
                                setSucursal(null);
                                setPermisoEditar(false);
                            }} icon={<AddCircleIcon />} />
                    </BottomNavigation>
                </Paper>
                <Paper className={ps.permisosPaper}>
                    {
                        btnActivo ?
                            <>
                                {cargando && tipoCargando === 1 ? (
                                    <div className={classes.loadingTable}>
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <Table stickyHeader aria-label="sticky table" style={{ borderRadius: '5px', boxShadow: '0 0 10px #00000069', marginBottom: '30px' }}>
                                        <TableHead sx={{zIndex:"1",position:"relative"}}>
                                            <TableRowModify>
                                                <TableCellModify className={ps.tableCellHead} style={{ borderTopLeftRadius: '5px' }}>#</TableCellModify>
                                                <TableCellModify className={ps.tableCellHead}>NOMBRE SUCURSAL</TableCellModify>
                                                <TableCellModify className={ps.tableCellHead} style={{ borderTopRightRadius: '5px' }}>ACCIONES</TableCellModify>
                                            </TableRowModify>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                permisos != [] && (
                                                    permisos.resultado != undefined && (
                                                        permisos?.resultado[0].permisos && permisos?.resultado[0].permisos.map((permiso, index) => {
                                                            return (
                                                                <TableRowModify className={ps.tableRowBody} key={permiso.idSucursal}
                                                                    style={{ borderBottomRightRadius: '5px', borderBottomLeftRadius: '5px' }}>
                                                                    <TableCellModify className='cellBody'>{index + 1}</TableCellModify>
                                                                    <TableCellModify className='cellBody cellBodyShow'
                                                                        onClick={e => {
                                                                            setPermisoEditar(permiso);
                                                                            setIdSucursal(permiso.idSucursal);
                                                                            setNombreSucursal(permiso.sucursal.nombreSucursal);
                                                                        }}
                                                                    >{permiso.sucursal.nombreSucursal}</TableCellModify>
                                                                    <TableCellModify > <Delete className={ps.tableEliminar}
                                                                        onClick={e => {
                                                                            eliminarPermiso(permiso.idUsuario, permiso.idSucursal);
                                                                        }}
                                                                    /> </TableCellModify>
                                                                </TableRowModify>
                                                            )
                                                        })

                                                    )
                                                )
                                            }
                                        </TableBody>
                                    </Table>
                                )
                                }

                                {
                                    (permisoEditar) && (
                                        (cargandoBoton && tipoCargando == 2) ?
                                            <div className={classes.loadingTable}>
                                                <CircularProgress />
                                            </div>
                                            :
                                            <>
                                                <Typography style={{ textAlign: 'start' }} variant="h6">Roles - Sucursal : {nombreSucursal}</Typography>
                                                <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                                    <Grid container spacing={2}>
                                                        {
                                                            rawPermisos && Object.keys(rawPermisos).map((key, i) => {
                                                                if (rawPermisos[key].principal && !principalSelected) {
                                                                    setPrincipalSelected(true);
                                                                }
                                                                if (key.charAt(key.length - 1) != "s") {
                                                                    return (
                                                                        <Grid item xs={12} md={4}>
                                                                            <FormControlLabel key={key} control={<Checkbox
                                                                                checked={rawPermisos[key].val}
                                                                                onChange={e => {
                                                                                    let p = rawPermisos;
                                                                                    p = { ...p, [key]: { ...p[key], val: e.target.checked } };
                                                                                    setRawPermisos({ ...p });
                                                                                    setEditar(true);
                                                                                }}
                                                                            />} label={key} />
                                                                            <FormControlLabel key={key} control={<Switch
                                                                                checked={rawPermisos[key].principal}
                                                                                disabled={!rawPermisos[key].val ? true : (rawPermisos[key].principal ? false : principalSelected)}
                                                                                onChange={e => {
                                                                                    if (e.target.checked) {
                                                                                        setPrincipalSelected(true);
                                                                                    } else {
                                                                                        if (rawPermisos[key].principal) {
                                                                                            setPrincipalSelected(false);
                                                                                        }
                                                                                    }

                                                                                    let p = rawPermisos;
                                                                                    p = { ...p, [key]: { ...p[key], principal: e.target.checked } };
                                                                                    setRawPermisos({ ...p });
                                                                                    setEditar(true);
                                                                                }}
                                                                            />} label={"Principal"} />
                                                                        </Grid>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    </Grid>
                                                </FormGroup>

                                                <Table stickyHeader aria-label="sticky table" style={{ borderRadius: '5px', boxShadow: '0 0 10px #00000069', marginTop: '30px' }}>
                                                    <TableHead sx={{zIndex:"1",position:"relative"}}>
                                                        <TableRowModify>
                                                            <TableCellModify className={ps.tableCellHead} style={{ borderTopLeftRadius: '5px' }}>PERMISO</TableCellModify>
                                                            <TableCellModify className={ps.tableCellHead}>VER</TableCellModify>
                                                            <TableCellModify className={ps.tableCellHead}>AGREGAR</TableCellModify>
                                                            <TableCellModify className={ps.tableCellHead}>EDITAR</TableCellModify>
                                                            <TableCellModify className={ps.tableCellHead} style={{ borderTopRightRadius: '5px' }}>DESHABILITAR</TableCellModify>
                                                        </TableRowModify>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            rawPermisos && Object.keys(rawPermisos).map((key, i) => {
                                                                if (key.charAt(key.length - 1) == "s") {
                                                                    return (
                                                                        <TableRowModify>
                                                                            <TableCellModify>{key.toUpperCase()}</TableCellModify>
                                                                            <TableCellModify>
                                                                                <FormControlLabel control={<Switch
                                                                                    checked={(rawPermisos[key].ver)}
                                                                                    onChange={e => {
                                                                                        let p = rawPermisos[key];
                                                                                        p.ver = e.target.checked;
                                                                                        if (!e.target.checked) {
                                                                                            p.agregar = false;
                                                                                            p.editar = false;
                                                                                            p.deshabilitar = false;
                                                                                        }
                                                                                        setRawPermisos({ ...rawPermisos, [key]: p });
                                                                                        setEditar(true);
                                                                                    }}
                                                                                />} label="" />
                                                                            </TableCellModify>
                                                                            <TableCellModify>
                                                                                <FormControlLabel control={<Switch
                                                                                    checked={(rawPermisos[key].agregar)}
                                                                                    onChange={e => {
                                                                                        let p = rawPermisos[key];
                                                                                        p.agregar = e.target.checked;
                                                                                        setRawPermisos({ ...rawPermisos, [key]: p });
                                                                                        setEditar(true);
                                                                                    }}
                                                                                    disabled={(!rawPermisos[key].ver)} />} label="" />
                                                                            </TableCellModify>
                                                                            <TableCellModify>
                                                                                <FormControlLabel control={<Switch
                                                                                    checked={(rawPermisos[key].editar)}
                                                                                    onChange={e => {
                                                                                        let p = rawPermisos[key];
                                                                                        p.editar = e.target.checked;
                                                                                        setRawPermisos({ ...rawPermisos, [key]: p });
                                                                                        setEditar(true);
                                                                                    }}
                                                                                    disabled={(!rawPermisos[key].ver)} />} label="" />
                                                                            </TableCellModify>
                                                                            <TableCellModify>
                                                                                <FormControlLabel control={<Switch
                                                                                    checked={(rawPermisos[key].deshabilitar)}
                                                                                    onChange={e => {
                                                                                        let p = rawPermisos[key];
                                                                                        p.deshabilitar = e.target.checked;
                                                                                        setRawPermisos({ ...rawPermisos, [key]: p });
                                                                                        setEditar(true);
                                                                                    }}
                                                                                    disabled={(!rawPermisos[key].ver)} />} label="" />
                                                                            </TableCellModify>
                                                                        </TableRowModify>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </>

                                    )
                                }
                            </>
                            :
                            <>
                                <Typography style={{ textAlign: 'center' }} variant="h4">PERMISOS</Typography>
                                <AsyncAutocompleteSearchBeta
                                    size="medium"
                                    label="Sucursales"
                                    ruta="sucursal"
                                    labelProp="nombreSucursal"
                                    extraParams={sucursalParamsMemo}
                                    publica={false}
                                    onChange={setSucursalCallback}
                                    campoError="idSucursal"
                                />
                                {
                                    (sucursal && sucursal != null) && (
                                        <>
                                            <Typography style={{ textAlign: 'center' }} variant="h6">Roles</Typography>
                                            <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                                <Grid container spacing={2}>
                                                    {
                                                        rawPermisos && Object.keys(rawPermisos).map((key, i) => {
                                                            if (key.charAt(key.length - 1) != "s") {
                                                                return (
                                                                    <Grid item xs={12} md={4}>
                                                                        <FormControlLabel key={key + "check"} control={<Checkbox
                                                                            checked={rawPermisos[key].val}
                                                                            onChange={e => {
                                                                                let p = rawPermisos;
                                                                                p = { ...p, [key]: { ...p[key], val: e.target.checked } };
                                                                                setRawPermisos({ ...p });
                                                                            }}
                                                                        />} label={key} />
                                                                        <FormControlLabel key={key + "switch"} control={<Switch
                                                                            checked={rawPermisos[key].principal}
                                                                            disabled={!rawPermisos[key].val ? true : (rawPermisos[key].principal ? false : principalSelected)}
                                                                            onChange={e => {
                                                                                if (e.target.checked) {
                                                                                    setPrincipalSelected(true);
                                                                                } else {
                                                                                    if (rawPermisos[key].principal) {
                                                                                        setPrincipalSelected(false);
                                                                                    }
                                                                                }

                                                                                let p = rawPermisos;
                                                                                p = { ...p, [key]: { ...p[key], principal: e.target.checked } };
                                                                                setRawPermisos({ ...p });
                                                                            }}
                                                                        />} label={"Principal"} />
                                                                    </Grid>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </Grid>
                                            </FormGroup>


                                            {
                                                rawPermisos && Object.keys(rawPermisos).map((key, i) => {
                                                    if (key.charAt(key.length - 1) == "s") {
                                                        return (
                                                            <Accordion key={i} className={ps.accordionPermiso}>
                                                                <AccordionSummary
                                                                    className={ps.accordionSummaryPermiso}
                                                                    expandIcon={<ExpandMoreIcon style={{ color: '#FFF' }} />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography component={'span'}>{capitalizeFirst(key.toLocaleLowerCase())}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Typography component={'span'}>
                                                                        <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                                                            <FormControlLabel control={<Switch
                                                                                checked={(rawPermisos[key].ver)}
                                                                                onChange={e => {
                                                                                    let p = rawPermisos[key];
                                                                                    p.ver = e.target.checked;
                                                                                    if (!e.target.checked) {
                                                                                        p.agregar = false;
                                                                                        p.editar = false;
                                                                                        p.deshabilitar = false;
                                                                                    }
                                                                                    setRawPermisos({ ...rawPermisos, [key]: p });
                                                                                }}
                                                                            />} label="Ver" />
                                                                            <FormControlLabel control={<Switch
                                                                                checked={(rawPermisos[key].agregar)}
                                                                                onChange={e => {
                                                                                    let p = rawPermisos[key];
                                                                                    p.agregar = e.target.checked;
                                                                                    setRawPermisos({ ...rawPermisos, [key]: p });
                                                                                }}
                                                                                disabled={(!rawPermisos[key].ver)} />} label="Agregar" />
                                                                            <FormControlLabel control={<Switch
                                                                                checked={(rawPermisos[key].editar)}
                                                                                onChange={e => {
                                                                                    let p = rawPermisos[key];
                                                                                    p.editar = e.target.checked;
                                                                                    setRawPermisos({ ...rawPermisos, [key]: p });
                                                                                }}
                                                                                disabled={(!rawPermisos[key].ver)} />} label="Editar" />
                                                                            <FormControlLabel control={<Switch
                                                                                checked={(rawPermisos[key].deshabilitar)}
                                                                                onChange={e => {
                                                                                    let p = rawPermisos[key];
                                                                                    p.deshabilitar = e.target.checked;
                                                                                    setRawPermisos({ ...rawPermisos, [key]: p });
                                                                                }}
                                                                                disabled={(!rawPermisos[key].ver)} />} label="Deshabilitar" />
                                                                        </FormGroup>
                                                                    </Typography>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        );
                                                    }
                                                })
                                            }
                                        </>
                                    )
                                }
                            </>
                    }
                </Paper>
                {
                    (sucursal && sucursal != null && !btnActivo) && (
                        <Paper className={ps.permisosPaperBtn} style={{}}>
                            <Grid item xs={12} xl={12}>
                                <Grid container justifyContent="flex-end">
                                    <LoadingButton
                                        loading={cargandoBoton}
                                        variant="contained"
                                        onClick={e => {
                                            guardarPermiso(true);
                                        }}
                                        size="large"
                                        style={{ color: "#fff" }}
                                    >
                                        GUARDAR PERMISOS
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Paper>
                    )
                }

            </Box>
        </>
    );
}

export default Index;