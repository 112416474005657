/* eslint-disable default-case */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAlert } from "../../hooks/useAlert";
import Service from "../../services/api";
import SendIcon from '@mui/icons-material/Send';
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import { Grid, Paper, Table, TableHead, TableBody, TableRow, TableCell, CircularProgress, IconButton, MenuItem, Select, InputLabel, FormControl, TextField } from '@mui/material';
import AsyncAutocompleteSearchRework from '../../components/AsyncAutocompleteSearchRework';
import AutocompleteDeluxe from '../../components/AutocompleteDeluxe';
import { makeStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from '../../components/InputRegex'
import { formatCurrency } from '../../utils/index';
import Swal from "sweetalert2";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { tableCellStyles, tableRowStyles, entradasStyle, internationalCurrency, capitalizeFirst } from '../../utils';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import RemoveIcon from '@mui/icons-material/Remove';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        flexWrap: "wrap",
        marginBottom: "30px",
    },
}));
const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const useEntradasStyle = entradasStyle();

const Evaluacion = ({ evaluaciones, setEvaluaciones, convenio, totalEditado, cita, editing, finalizado, idOrdenServicio, ...props }) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const classes = useStyles();
    const entradas = useEntradasStyle();
    const { showAlert } = useAlert();
    const [admin] = useLocalStorage("admin", null);
    const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
    const [sucursal, setSucursal] = useState(null);
    const [loading, setLoading] = useState(false);
    const [toDelete, setToDelete] = useState(null);
    const [toAdd, setToAdd] = useState(null);
    const [clearEvaluacion, setClearEvaluacion] = useState(false);
    const [clearServicio, setClearServicio] = useState(false);
    const [clearProducto, setClearProducto] = useState(false);
    const [clearLaboratorio, setClearLaboratorio] = useState(false);
    const [clearSucursal, setClearSucursal] = useState(false);
    const [evaluacion, setEvaluacion] = useState(null);
    const [producto, setProducto] = useState(null);
    const [codigo, setCodigo] = useState("")
    const [laboratorio, setLaboratorio] = useState(null);
    const [reloadProductos, setReloadProductos] = useState(false);
    const [servicio, setServicio] = useState(null);
    const [verificando, setVerificando] = useState(false);
    const [loadingValidacion, setLoadingValidacion] = useState(false);

    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const addEvaluacion = async (eva = null) => {
        if (eva) {
            if (eva) {
                let fallo = false;
                let newEvaluaciones = [];
                eva?.tipoEvaluacionServicio.map(servicio => {
                    newEvaluaciones.push(
                        {
                            ...servicio.servicio,
                            ...servicio.servicio.servicioDoctores[0].doctor[0],
                            tipoEvaluacion: "Servicio",
                            cantidad: 1,
                            loading: false,
                            doctorSeleccionado: servicio.servicio.servicioDoctores[0].doctor[0].idUsuario,
                            descuento: servicio.descuento,
                            precioDescuento: servicio.precioDescuento,
                            por: 100 - (Math.round(((servicio.precioDescuento / servicio.servicio.valorUIva) * 100) * 100) / 100)
                        });
                });
                if (fallo) {
                    setEvaluaciones([]);
                    setEvaluacion(null);
                    setClearEvaluacion(true);
                    return;
                }
                eva?.paquete?.paqueteProductoInventario.map(producto => {
                    if (producto.productoInventario.inventario < 1) {
                        showAlert({
                            message: "Producto en el paquete no cuenta con inventairo disponible:" + producto.productoInventario.nombreProducto,
                            severity: "error",
                        });
                        fallo = true;
                        return;
                    }
                    newEvaluaciones.push(
                        {
                            ...producto.productoInventario,
                            tipoEvaluacion: "Producto",
                            cantidad: 1,
                            idSucursal: producto.idSucursal,
                            loading: false,
                            descuento: 0,
                            precioDescuento: producto.precioPublico,
                            por: 100 - (Math.round(((producto.precioPublico / producto.precioPublico) * 100) * 100) / 100)
                        });
                });
                if (fallo) {
                    setEvaluaciones([]);
                    setEvaluacion(null);
                    setClearEvaluacion(true);
                    return;
                }
                eva?.paquete?.paqueteInventarioLaboratorio.map(producto => {
                    if (producto.inventarioLaboratorio.inventario < 1) {
                        showAlert({
                            message: "Producto Laboratorio en el paquete no cuenta con inventario disponible:" + producto.inventarioLaboratorio.nombreProducto,
                            severity: "error",
                        });
                        fallo = true;
                        return;
                    }
                    newEvaluaciones.push({
                        ...producto.inventarioLaboratorio,
                        tipoEvaluacion: "Producto Laboratorio",
                        cantidad: 1,
                        idSucursal: producto.idSucursal,
                        loading: false,
                        totalDescuento: producto.precioPublico,
                        descuento: 0,
                        por: 100 - (Math.round(((producto.precioPublico / producto.precioPublico) * 100) * 100) / 100)
                    });
                });
                if (fallo) {
                    setEvaluaciones([]);
                    setEvaluacion(null);
                    setClearEvaluacion(true);
                    return;
                }
                setEvaluacion(null);
                setClearEvaluacion(true);
                return newEvaluaciones;
            }
        } else {
            const text =
                "¿Estás seguro de que deseas agregar la evaluacion " + evaluacion?.evaluacion + " con el convenio " + convenio?.nombreConvenio + "?".toUpperCase();
            const res = await Swal.fire({
                title: text,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#25607A",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, Guardar!",
                cancelButtonText: "Cancelar",
            });
            if (res.isConfirmed) {
                if (evaluacion) {
                    let fallo = false;
                    let newEvaluaciones = [];
                    evaluacion?.tipoEvaluacionServicio.map(servicio => {
                        newEvaluaciones.push(
                            {
                                ...servicio.servicio,
                                ...servicio.servicio.servicioDoctores[0].doctor[0],
                                tipoEvaluacion: "Servicio",
                                cantidad: 1,
                                loading: false,
                                doctorSeleccionado: servicio.servicio.servicioDoctores[0].doctor[0].idUsuario,
                                descuento: servicio.descuento,
                                precioDescuento: servicio.precioDescuento,
                                por: 100 - (Math.round(((servicio.precioDescuento / servicio.servicio.valorUIva) * 100) * 100) / 100)
                            });
                    });
                    if (fallo) {
                        setEvaluaciones([]);
                        setEvaluacion(null);
                        setClearEvaluacion(true);
                        return;
                    }
                    evaluacion?.paquete?.paqueteProductoInventario.map(producto => {
                        if (producto.productoInventario.inventario < 1) {
                            showAlert({
                                message: "Producto en el paquete no cuenta con inventairo disponible:" + producto.productoInventario.nombreProducto,
                                severity: "error",
                            });
                            fallo = true;
                            return;
                        }
                        newEvaluaciones.push(
                            {
                                ...producto.productoInventario,
                                tipoEvaluacion: "Producto",
                                cantidad: 1,
                                idSucursal: producto.idSucursal,
                                loading: false,
                                descuento: 0,
                                precioDescuento: producto.precioPublico,
                                por: 100 - (Math.round(((producto.precioPublico / producto.precioPublico) * 100) * 100) / 100)
                            });
                    });
                    if (fallo) {
                        setEvaluaciones([]);
                        setEvaluacion(null);
                        setClearEvaluacion(true);
                        return;
                    }
                    evaluacion?.paquete?.paqueteInventarioLaboratorio.map(producto => {
                        if (producto.inventarioLaboratorio.inventario < 1) {
                            showAlert({
                                message: "Producto Laboratorio en el paquete no cuenta con inventario disponible:" + producto.inventarioLaboratorio.nombreProducto,
                                severity: "error",
                            });
                            fallo = true;
                            return;
                        }
                        newEvaluaciones.push({
                            ...producto.inventarioLaboratorio,
                            tipoEvaluacion: "Producto Laboratorio",
                            cantidad: 1,
                            idSucursal: producto.idSucursal,
                            loading: false,
                            totalDescuento: producto.precioPublico,
                            descuento: 0,
                            por: 100 - (Math.round(((producto.precioPublico / producto.precioPublico) * 100) * 100) / 100)
                        });
                    });
                    if (fallo) {
                        setEvaluaciones([]);
                        setEvaluacion(null);
                        setClearEvaluacion(true);
                        return;
                    }
                    setEvaluaciones(newEvaluaciones);
                    setEvaluacion(null);
                    setClearEvaluacion(true);
                }
            }
        }
    }

    const addProducto = () => {
        if (producto) {
            let repetido = false;
            let noDisponible = false;
            evaluaciones.map((e) => {
                if (e.tipoEvaluacion == "Producto") {
                    if (e.idProductoInventario == producto.idProductoInventario) {
                        repetido = true;
                    }
                }
            });
            if (producto?.inventario < 1) {
                noDisponible = true;
            }
            if (noDisponible) {
                showAlert({
                    message: "Producto no tiene inventario disponible",
                    severity: "error",
                });
                return;
            }
            if (repetido) {
                showAlert({
                    message: "Producto ya esta en la lista",
                    severity: "error",
                });
                return;
            }
            let newEvaluaciones = [...evaluaciones];
            newEvaluaciones.push({
                ...producto,
                tipoEvaluacion: "Producto",
                cantidad: 1,
                idSucursal: sucursal.idSucursal,
                loading: false,
                precioDescuento: producto.precioPublico,
                descuento: 0,
                por: 100 - (Math.round(((producto.precioPublico / producto.precioPublico) * 100) * 100) / 100)
            });
            setEvaluaciones(newEvaluaciones);
            setProducto(null);
            setClearProducto(true);
        }
    }

    const addLaboratorio = () => {
        if (laboratorio) {
            let repetido = false;
            evaluaciones.map((e) => {
                if (e.tipoEvaluacion == "Producto Laboratorio") {
                    if (e.idInventarioLaboratorio == laboratorio.idInventarioLaboratorio) {
                        repetido = true;
                    }
                }
            });
            if (repetido) {
                showAlert({
                    message: "Producto Laboratorio ya esta en la lista",
                    severity: "error",
                });
                return;
            }
            let newEvaluaciones = [...evaluaciones];
            newEvaluaciones.push({
                ...laboratorio,
                tipoEvaluacion: "Producto Laboratorio",
                cantidad: 1,
                idSucursal: sucursal.idSucursal,
                loading: false,
                precioDescuento: laboratorio.precioPublico,
                descuento: 0,
                por: 100 - (Math.round(((laboratorio.precioPublico / laboratorio.precioPublico) * 100) * 100) / 100)
            });
            setEvaluaciones(newEvaluaciones);
            setLaboratorio(null);
            setClearLaboratorio(true);
        }
    }

    const addServicio = (s = null) => {
        if (s) {
            let repetido = false;
            evaluaciones.map((e) => {
                if (e.tipoEvaluacion == "Servicio") {
                    if (e.idServicio == s.servicio.idServicio) {
                        repetido = true;
                    }
                }
            });
            if (repetido) {
                showAlert({
                    message: "Servicio ya esta en la lista",
                    severity: "error",
                });
                return;
            }
            let newEvaluaciones = [];
            newEvaluaciones.push(
                {
                    ...s.servicio,
                    ...s.servicio.servicioDoctores[0].doctor[0],
                    tipoEvaluacion: "Servicio",
                    cantidad: 1,
                    loading: false,
                    doctorSeleccionado: s.servicio.servicioDoctores[0].doctor[0].idUsuario,
                    descuento: s.descuento,
                    precioDescuento: s.precioDescuento,
                    por: Number.parseFloat(100 - ((s.precioDescuento / s.servicio.valorUIva) * 100)).toFixed(2)
                });
            setServicio(null);
            setClearServicio(true);
            return newEvaluaciones;
        } else {
            if (servicio) {
                let repetido = false;
                evaluaciones.map((e) => {
                    if (e.tipoEvaluacion == "Servicio") {
                        if (e.idServicio == servicio.idServicio) {
                            repetido = true;
                        }
                    }
                });
                if (repetido) {
                    showAlert({
                        message: "Servicio ya esta en la lista",
                        severity: "error",
                    });
                    return;
                }
                let newEvaluaciones = [...evaluaciones];
                newEvaluaciones.push(
                    {
                        ...servicio,
                        ...servicio.servicioDoctores[0].doctor[0],
                        tipoEvaluacion: "Servicio",
                        cantidad: 1,
                        loading: false,
                        doctorSeleccionado: servicio.servicioDoctores[0].doctor[0].idUsuario,
                        descuento: 0,
                        precioDescuento: servicio.valorUIva,
                        por: 100 - (Math.round(((servicio.valorUIva / servicio.valorUIva) * 100) * 100) / 100)
                    });
                setEvaluaciones(newEvaluaciones);
                setServicio(null);
                setClearServicio(true);
            }
        }
    }

    const dropEva = async (id, nombre, tipo) => {
        if (id) {
            const res = await Swal.fire({
                title: "Borrar",
                icon: "warning",
                text: `¿Estás seguro de que deseas borrar el "${capitalizeFirst(tipo.toLowerCase())}" seleccionado "${capitalizeFirst(nombre.toLowerCase())}"?`,
                showCancelButton: true,
                confirmButtonColor: "#25607A",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, deseo Continuar!",
                cancelButtonText: "Cancelar",
            });
            if (res.isConfirmed) {
                showAlert({
                    message: "Espera un momento.....",
                    severity: "warning",
                });
                let posi;
                const copyEvaluaciones = [...evaluaciones];
                evaluaciones.map((e, index) => {
                    if (e.tipoEvaluacion == tipo) {
                        if (tipo == "Servicio") {
                            if (e.idServicio == id) {
                                posi = index;
                            }
                        }
                        if (tipo == "Producto") {
                            if (e.idProductoInventario == id) {
                                posi = index;
                            }
                        }
                        if (tipo == "Producto Laboratorio") {
                            if (e.idInventarioLaboratorio == id) {
                                posi = index;
                            }
                        }

                    }
                });
                copyEvaluaciones.splice(posi, 1);
                setEvaluaciones(copyEvaluaciones);
                setLoading(false);
                showAlert({
                    message: tipo + " borrado con éxito.",
                    severity: "success",
                });
            } else {
                setLoading(false);
            }
        }
    }

    const onDropEvaluacion = async (id, nombre, tipo) => {
        if (editing) {
            setToDelete({ id, nombre, tipo })
            setLoading(false);
        } else {
            dropEva(id, nombre, tipo)
        }
    }

    const removeCantidad = async eva => {
        setLoadingValidacion(true);
        switch (eva.tipoEvaluacion) {
            case "Producto":

                let copyEvaluacionesF = [...evaluaciones];
                evaluaciones.map((e, index) => {
                    if (e.tipoEvaluacion == "Producto") {
                        if (e.idProductoInventario == eva.idProductoInventario) {
                            copyEvaluacionesF[index].loading = true;
                        }
                    }
                });
                setEvaluaciones([...copyEvaluacionesF]);

                const Pinventario = await Service.get("producto-inventario?idProductoInventario=" + eva.idProductoInventario + "&idSucursal=" + eva.idSucursal + "&expand=inventario");

                if (Pinventario.resultado.length > 0) {
                    if ((eva.cantidad - 1) <= Pinventario.resultado[0].inventario) {
                        let copyEvaluaciones = [...evaluaciones];
                        evaluaciones.map((e, index) => {
                            if (e.tipoEvaluacion == "Producto") {
                                if (e.idProductoInventario == eva.idProductoInventario) {
                                    copyEvaluaciones[index].cantidad--
                                    copyEvaluaciones[index].loading = false;
                                }
                            }
                        });
                        setEvaluaciones([...copyEvaluaciones]);
                    } else {
                        let copyEvaluaciones = [...evaluaciones];
                        evaluaciones.map((e, index) => {
                            if (e.tipoEvaluacion == "Producto") {
                                if (e.idProductoInventario == eva.idProductoInventario) {
                                    copyEvaluaciones[index].cantidad = Pinventario.resultado[0].inventario;
                                    copyEvaluaciones[index].loading = false;
                                }
                            }
                        });
                        setEvaluaciones([...copyEvaluaciones]);
                        showAlert({
                            message: "el inventario cambio mientras se editaba el valor minimo es:" + eva.cantidad,
                            severity: "error",
                        });
                    }
                } else {
                    showAlert({
                        message: "No se pudo realizar la acción intentelo de nuevo",
                        severity: "error",
                    });
                }
                setLoadingValidacion(false);
                break;
            case "Producto Laboratorio":

                let copyEvaluacionesS = [...evaluaciones];
                evaluaciones.map((e, index) => {
                    if (e.tipoEvaluacion == "Producto Laboratorio") {
                        if (e.idInventarioLaboratorio == eva.idInventarioLaboratorio) {
                            copyEvaluacionesS[index].loading = true;
                        }
                    }
                });
                setEvaluaciones([...copyEvaluacionesS]);

                const PLinventario = await Service.get("inventario-laboratorio?idInventarioLaboratorio=" + eva.idInventarioLaboratorio + "&idSucursal=" + eva.idSucursal + "&expand=inventario");

                if (PLinventario.resultado.length > 0) {
                    if ((eva.cantidad - 1) <= PLinventario.resultado[0].inventario) {
                        let copyEvaluaciones = [...evaluaciones];
                        evaluaciones.map((e, index) => {
                            if (e.tipoEvaluacion == "Producto Laboratorio") {
                                if (e.idInventarioLaboratorio == eva.idInventarioLaboratorio) {
                                    copyEvaluaciones[index].cantidad--
                                    copyEvaluaciones[index].loading = false;
                                }
                            }
                        });
                        setEvaluaciones([...copyEvaluaciones]);
                    } else {
                        let copyEvaluaciones = [...evaluaciones];
                        evaluaciones.map((e, index) => {
                            if (e.tipoEvaluacion == "Producto Laboratorio") {
                                if (e.idInventarioLaboratorio == eva.idInventarioLaboratorio) {
                                    copyEvaluaciones[index].cantidad = PLinventario.resultado[0].inventario;
                                    copyEvaluaciones[index].loading = false;
                                }
                            }
                        });
                        setEvaluaciones([...copyEvaluaciones]);
                        showAlert({
                            message: "el inventario cambio mientras se editaba el valor minimo es:" + eva.cantidad,
                            severity: "error",
                        });
                    }
                } else {
                    showAlert({
                        message: "No se pudo realizar la acción intentelo de nuevo",
                        severity: "error",
                    });
                }
                setLoadingValidacion(false);
                break;
            case "Servicio":
                let copyEvaluaciones = [...evaluaciones];
                evaluaciones.map((e, index) => {
                    if (e.tipoEvaluacion == "Servicio") {
                        if (e.idServicio == eva.idServicio) {
                            copyEvaluaciones[index].cantidad--;
                        }
                    }
                });
                setEvaluaciones([...copyEvaluaciones]);
                setLoadingValidacion(false);
                break;
            default:
                setLoadingValidacion(false);
                break;
        }
    }

    const addEva = async eva => {
        if (eva?.toCantidad < 1) {
            showAlert({
                message: "la canidad no puede ser menor a 1",
                severity: "warning"
            });
            return;
        }
        setLoadingValidacion(true);
        switch (eva.tipoEvaluacion) {
            case "Producto":
                //setLoading>>>
                let copyEvaluacionesF = [...evaluaciones];
                evaluaciones.map((e, index) => {
                    if (e.tipoEvaluacion == "Producto") {
                        if (e.idProductoInventario == eva.idProductoInventario) {
                            copyEvaluacionesF[index].loading = true;
                        }
                    }
                });
                setEvaluaciones([...copyEvaluacionesF]);
                //setLoading<<<

                const Pinventario = await Service.get("producto-inventario?idProductoInventario=" + eva.idProductoInventario + "&idSucursal=" + eva.idSucursal + "&expand=inventario");

                if (Pinventario.resultado.length > 0) {
                    if (eva.toCantidad <= Pinventario.resultado[0].inventario) {
                        let copyEvaluaciones = [...evaluaciones];
                        evaluaciones.map((e, index) => {
                            if (e.tipoEvaluacion == "Producto") {
                                if (e.idProductoInventario == eva.idProductoInventario) {
                                    copyEvaluaciones[index].cantidad = eva?.toCantidad;
                                    copyEvaluaciones[index].loading = false;
                                }
                            }
                        });
                        setEvaluaciones([...copyEvaluaciones]);
                    } else {
                        let copyEvaluaciones = [...evaluaciones];
                        evaluaciones.map((e, index) => {
                            if (e.tipoEvaluacion == "Producto") {
                                if (e.idProductoInventario == eva.idProductoInventario) {
                                    copyEvaluaciones[index].cantidad = Pinventario.resultado[0].inventario;
                                    copyEvaluaciones[index].loading = false;
                                }
                            }
                        });
                        setEvaluaciones([...copyEvaluaciones]);
                        showAlert({
                            message: "alcanzo el maximo de inventario disponible que es:" + Pinventario.resultado[0].inventario,
                            severity: "error",
                        });
                    }
                } else {
                    showAlert({
                        message: "No se pudo realizar la acción intentelo de nuevo",
                        severity: "error",
                    });
                }
                setLoadingValidacion(false);
                break;

            case "Producto Laboratorio":

                let copyEvaluacionesS = [...evaluaciones];
                evaluaciones.map((e, index) => {
                    if (e.tipoEvaluacion == "Producto Laboratorio") {
                        if (e.idInventarioLaboratorio == eva.idInventarioLaboratorio) {
                            copyEvaluacionesS[index].loading = true;
                        }
                    }
                });
                setEvaluaciones([...copyEvaluacionesS]);

                const PLinventario = await Service.get("inventario-laboratorio?idInventarioLaboratorio=" + eva.idInventarioLaboratorio + "&idSucursal=" + eva.idSucursal + "&expand=inventario");

                if (PLinventario.resultado.length > 0) {
                    if (eva.cantidad < PLinventario.resultado[0].inventario) {
                        let copyEvaluaciones = [...evaluaciones];
                        evaluaciones.map((e, index) => {
                            if (e.tipoEvaluacion == "Producto Laboratorio") {
                                if (e.idInventarioLaboratorio == eva.idInventarioLaboratorio) {
                                    copyEvaluaciones[index].cantidad++;
                                    copyEvaluaciones[index].loading = false;
                                }
                            }
                        });
                        setEvaluaciones([...copyEvaluaciones]);
                    } else {
                        let copyEvaluaciones = [...evaluaciones];
                        evaluaciones.map((e, index) => {
                            if (e.tipoEvaluacion == "Producto Laboratorio") {
                                if (e.idInventarioLaboratorio == eva.idInventarioLaboratorio) {
                                    copyEvaluaciones[index].loading = false;
                                }
                            }
                        });
                        setEvaluaciones([...copyEvaluaciones]);
                        showAlert({
                            message: "alcanzo el maximo de inventario disponible que es:" + eva.cantidad,
                            severity: "error",
                        });
                    }
                } else {
                    showAlert({
                        message: "No se pudo realizar la acción intentelo de nuevo",
                        severity: "error",
                    });
                }
                setLoadingValidacion(false);
                break;
            case "Servicio":
                let copyEvaluaciones = [...evaluaciones];
                evaluaciones.map((e, index) => {
                    if (e.tipoEvaluacion == "Servicio") {
                        if (e.idServicio == eva.idServicio) {
                            copyEvaluaciones[index].cantidad = eva?.toCantidad;
                        }
                    }
                });
                setEvaluaciones([...copyEvaluaciones]);
                setLoadingValidacion(false);
                break;
            default:
                setLoadingValidacion(false);
                break;
        }
        setLoadingValidacion(false);
    }

    const addCantidad = async (eva, e) => {
        addEva(eva)
    }

    const getCellsTotales = () => {
        let totalProductos = totalEditado?.producto;
        let totalServicio = 0;
        let totalProductosLaboratorio = totalEditado?.laboratorio;

        evaluaciones.forEach(eva => {
            switch (eva.tipoEvaluacion) {
                case "Servicio":
                    totalServicio = Math.round((totalServicio + (parseFloat(eva.precioDescuento) * eva.cantidad)) * Math.pow(10, 2)) / Math.pow(10, 2);
                    break;
                case "Producto":
                    totalProductos = Math.round((totalProductos + (parseFloat(eva.precioDescuento) * eva.cantidad)) * Math.pow(10, 2)) / Math.pow(10, 2);
                    break;
                case "Producto Laboratorio":
                    totalProductosLaboratorio = Math.round((totalProductosLaboratorio + (parseFloat(eva.precioDescuento) * eva.cantidad)) * Math.pow(10, 2)) / Math.pow(10, 2);
                    break;
            }
        });

        let totalSD = Math.round((totalProductos + totalServicio + totalProductosLaboratorio) * Math.pow(10, 2)) / Math.pow(10, 2);
        return (
            <>
                <TableCellModify>
                    {totalProductos ? "$ " + formatCurrency(totalProductos, 2) : "$ 0"}
                </TableCellModify>
                <TableCellModify>
                    {totalProductosLaboratorio ? "$ " + formatCurrency(totalProductosLaboratorio, 2) : "$ 0"}
                </TableCellModify>
                <TableCellModify>
                    {totalServicio ? "$ " + formatCurrency(totalServicio, 2) : "$ 0"}
                </TableCellModify>
                <TableCellModify>
                    {totalSD ? "$ " + formatCurrency(totalSD, 2) : "$ 0"}
                </TableCellModify>
            </>
        );
    }
    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (clearEvaluacion) { setClearEvaluacion(false) }
        if (clearProducto) { setClearProducto(false) }
        if (clearLaboratorio) { setClearLaboratorio(false) }
        if (clearServicio) { setClearServicio(false) }
    }, [clearEvaluacion, clearProducto, clearLaboratorio, clearServicio]);

    useEffect(async () => {
        if (cita != null || cita != undefined) {
            let evas = [];
            let servs = [];
            if (cita?.citaDatos?.tipoEvaluacion) {
                evas = await addEvaluacion(cita?.citaDatos?.tipoEvaluacion)
            }
            if (cita?.citaServicios?.length > 0) {
                console.log("servicios")
                cita?.citaServicios?.forEach(serv => {
                    console.log("add servicio")
                    console.log(addServicio(serv))
                    servs = [...servs, ...addServicio(serv)]
                    console.log(servs)
                })
            }
            setEvaluaciones([...evas, ...servs])
        }
    }, [cita]);

    return (
        <>
            {
                toDelete !== null &&
                <div style={{
                    position: "fixed",
                    overflowY: "none",
                    inset: 0,
                    zIndex: 10,
                    background: "#00000063",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <div style={{
                        background: "white",
                        borderRadius: "15px",
                        height: "max-content",
                        padding: "50px 50px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <p style={{
                            paddingBottom: "25px",
                            margin: "0",
                            fontSize: "20px",
                            color: "#666666",
                        }}>Codigo de seguridad</p>
                        {
                            !verificando
                                ?
                                <div style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    <TextField value={codigo} onChange={e => { setCodigo(e.target.value) }} type={"password"} />
                                    <IconButton onClick={
                                        async () => {
                                            setVerificando(true)
                                            let res = await Service.post("usuario/codigo", { codigo, idOrdenServicio })
                                            if (res?.detalle?.validado) {
                                                showAlert({
                                                    message: res?.mensaje,
                                                    severity: "success",
                                                });
                                                setCodigo("")
                                                dropEva(toDelete?.id, toDelete?.nombre, toDelete?.tipo)
                                                setToDelete(null)
                                            } else {
                                                showAlert({
                                                    message: "codigo no valido",
                                                    severity: "error",
                                                });
                                                setCodigo("")
                                                setLoading(false)
                                                setToDelete(null)
                                            }
                                            setVerificando(false)
                                        }
                                    }><SendIcon /></IconButton>
                                </div>
                                :
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <CircularProgress />
                                </div>
                        }
                    </div>
                </div>
            }
            {
                toAdd !== null &&
                <div style={{
                    position: "fixed",
                    overflowY: "none",
                    inset: 0,
                    zIndex: 10,
                    background: "#00000063",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <div style={{
                        background: "white",
                        borderRadius: "15px",
                        height: "max-content",
                        padding: "50px 50px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <p style={{
                            paddingBottom: "25px",
                            margin: "0",
                            fontSize: "20px",
                            color: "#666666",
                        }}>Codigo de seguridad</p>
                        {
                            !verificando
                                ?
                                <div style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    <TextField value={codigo} onChange={e => { setCodigo(e.target.value) }} type={"password"} />
                                    <IconButton onClick={
                                        async () => {
                                            setVerificando(true)
                                            let res = await Service.post("usuario/codigo", { codigo })
                                            if (res?.detalle?.validado) {
                                                showAlert({
                                                    message: "codigo valido",
                                                    severity: "success",
                                                });
                                                setCodigo("")
                                                addEva(toAdd)
                                                setToAdd(null)
                                            } else {
                                                showAlert({
                                                    message: "codigo no valido",
                                                    severity: "error",
                                                });
                                                setCodigo("")
                                                setLoading(false)
                                                setToAdd(null)
                                            }
                                            setVerificando(false)
                                        }
                                    }><SendIcon /></IconButton>
                                </div>
                                :
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <CircularProgress />
                                </div>
                        }
                    </div>
                </div>
            }
            <Grid component={Paper} container className={classes.paper} spacing={1}>
                <Grid item xs={12} sm={8} md={8} lg={10}>
                    <AutocompleteDeluxe
                        labelToShow="Tipo de Evaluación"
                        labelProp="evaluacion"
                        nombre="tipo-evaluacion"
                        filtro={sucursal ? { idSucursal: sucursal?.idSucursal, expand: "paquete.paqueteProductoInventario.productoInventario.inventario,paquete.paqueteInventarioLaboratorio.inventarioLaboratorio.inventario,tipoEvaluacionServicio.servicio.servicioDoctores.doctor" } : { expand: "paquete.paqueteProductoInventario.productoInventario.inventario,paquete.paqueteInventarioLaboratorio.inventarioLaboratorio.inventario,tipoEvaluacionServicio.servicio.servicioDoctores.doctor" }}
                        setDato={setEvaluacion}
                        dato={evaluacion}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <LoadingButton
                        disabled={convenio ? evaluacion ? false : true : true}
                        loading={loading}
                        fullWidth
                        variant="contained"
                        onClick={() => addEvaluacion()}
                        size="medium"
                        style={{ color: "#fff" }}
                    >
                        Agregar Evaluación
                    </LoadingButton>
                </Grid>
                <Grid item xs={12}>
                    <Table className={entradas.tableEntradas}>
                        <TableHead>
                            <TableRowModify sx={{ textAlign: "center" }}>
                                <TableCellModify sx={{ textAlign: "center" }}> Asignar Cantidad </TableCellModify>
                                <TableCellModify sx={{ textAlign: "center" }}> Cantidad </TableCellModify>
                                <TableCellModify sx={{ textAlign: "center" }}> Descuento % </TableCellModify>
                                <TableCellModify sx={{ textAlign: "center" }}> Nombre Producto o Servicio </TableCellModify>
                                <TableCellModify sx={{ textAlign: "center" }}> Doctor </TableCellModify>
                                <TableCellModify sx={{ textAlign: "center" }}> Precio Publico </TableCellModify>
                                <TableCellModify sx={{ textAlign: "center" }}> Descuento </TableCellModify>
                                <TableCellModify sx={{ textAlign: "center" }}> Precio con descuento </TableCellModify>
                                <TableCellModify sx={{ textAlign: "center" }}> Total </TableCellModify>
                                <TableCellModify sx={{ textAlign: "center" }}> </TableCellModify>
                            </TableRowModify>
                        </TableHead>
                        <TableBody sx={{ textAlign: "center" }}>
                            {!loading ? evaluaciones?.map((eva, index) => {
                                return (
                                    <TableRowModify sx={{ textAlign: "center" }} key={index}>
                                        <TableCellModify sx={{ display: "flex", flexDirection: "row", padding: "21px", justifyContent: "center", alignItems: "center" }}>
                                            {eva.loading
                                                ?
                                                <CircularProgress />
                                                :
                                                <TextField
                                                    style={{
                                                        width: "fit-content",
                                                        padding: "10px",
                                                        color: "#FFF",
                                                        borderRadius: "5px",
                                                        fontSize: "1rem",
                                                        fontWeight: "600"
                                                    }}
                                                    defaultValue={eva.cantidad}
                                                    onChange={(e) => {
                                                        let copyEvaluaciones = [...evaluaciones];
                                                        copyEvaluaciones[index].toCantidad = e.target.value;
                                                        setEvaluaciones([...copyEvaluaciones]);
                                                    }}
                                                />
                                            }
                                            <IconButton onClick={e => addCantidad(eva, e)} disabled={eva.loading}>
                                                <SendIcon sx={{ color: eva.loading ? "rgba(0, 0, 0, 0.12)" : "#1d5e7b" }} />
                                            </IconButton>
                                        </TableCellModify>
                                        <TableCellModify sx={{ textAlign: "center" }}>
                                            {eva.cantidad}
                                        </TableCellModify>
                                        <TableCellModify sx={{ textAlign: "center" }}>
                                            <InputRegex
                                                value={eva?.por}
                                                onChange={e => {
                                                    let copyEvaluaciones = [...evaluaciones];
                                                    copyEvaluaciones[index].por = e.target.value;
                                                    copyEvaluaciones[index].precioDescuento =
                                                        eva.tipoEvaluacion == "Servicio"
                                                            ?
                                                            Math.round((copyEvaluaciones[index].valorUIva - ((copyEvaluaciones[index].valorUIva / 100) * (e.target.value >= 0 && e.target.value ? e.target.value : 0))) * 100) / 100
                                                            :
                                                            Math.round((copyEvaluaciones[index].precioPublico - ((copyEvaluaciones[index].precioPublico / 100) * (e.target.value >= 0 && e.target.value ? e.target.value : 0))) * 100) / 100

                                                    copyEvaluaciones[index].descuento =
                                                        eva.tipoEvaluacion == "Servicio"
                                                            ?
                                                            Math.round((copyEvaluaciones[index].valorUIva - (Math.round((copyEvaluaciones[index].valorUIva - ((copyEvaluaciones[index].valorUIva / 100) * (e.target.value >= 0 && e.target.value ? e.target.value : 0))) * 100) / 100)) * 100) / 100
                                                            :
                                                            Math.round((copyEvaluaciones[index].precioPublico - (Math.round((copyEvaluaciones[index].precioPublico - ((copyEvaluaciones[index].precioPublico / 100) * (e.target.value >= 0 && e.target.value ? e.target.value : 0))) * 100) / 100)) * 100) / 100

                                                    setEvaluaciones([...copyEvaluaciones]);

                                                }}
                                                typeInput={"%"}
                                            />
                                        </TableCellModify>
                                        <TableCellModify sx={{ textAlign: "center" }}>
                                            {
                                                eva.tipoEvaluacion == "Servicio"
                                                    ?
                                                    eva.concepto
                                                    :
                                                    eva.nombreProducto
                                            }
                                        </TableCellModify>
                                        <TableCellModify sx={{ textAlign: "center" }}>
                                            {
                                                eva.tipoEvaluacion == "Servicio"
                                                    ?
                                                    <FormControl sx={{ width: "200px" }} size="small">
                                                        <Select
                                                            variant="standard"
                                                            labelId="docores-label"
                                                            id="doctores"
                                                            label="Doctores"
                                                            value={eva.doctorSeleccionado}
                                                            onChange={e => {
                                                                let copyEvaluaciones = [...evaluaciones];
                                                                evaluaciones.map((evaluacion, index) => {
                                                                    if (evaluacion.tipoEvaluacion == "Servicio") {
                                                                        if (evaluacion.idServicio == eva.idServicio) {
                                                                            const doctor = copyEvaluaciones[index].servicioDoctores.filter(doctor => doctor.doctor[0].idUsuario == e.target.value);
                                                                            copyEvaluaciones[index] = { ...copyEvaluaciones[index], ...doctor[0].doctor[0], doctorSeleccionado: e.target.value };
                                                                        }
                                                                    }
                                                                });
                                                                setEvaluaciones([...copyEvaluaciones]);
                                                            }}
                                                        >
                                                            {
                                                                eva.servicioDoctores.map(doctor => {
                                                                    return (<MenuItem key={doctor.doctor[0].idUsuario} sx={{ padding: "10px !important" }} value={doctor.doctor[0].idUsuario}>{doctor.doctor[0].nombreCompleto}</MenuItem>);
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    :
                                                    "No Aplica"
                                            }
                                        </TableCellModify>
                                        <TableCellModify sx={{ textAlign: "center" }}>
                                            {
                                                eva.tipoEvaluacion == "Servicio"
                                                    ?
                                                    "$ " + formatCurrency(eva.valorUIva, 2)
                                                    :
                                                    eva.tipoEvaluacion == "Producto"
                                                        ?
                                                        "$ " + formatCurrency(eva.precioPublico, 2)
                                                        :
                                                        "$0.00"

                                            }
                                        </TableCellModify>
                                        <TableCellModify sx={{ textAlign: "center" }}>
                                            {
                                                eva.tipoEvaluacion == "Servicio"
                                                    ?
                                                    eva.descuento == 0
                                                        ?
                                                        "$ 0.00"
                                                        :
                                                        "$ " + formatCurrency(eva.descuento, 2)
                                                    :
                                                    eva.tipoEvaluacion == "Producto"
                                                        ?
                                                        eva.descuento == 0
                                                            ?
                                                            "$ 0.00"
                                                            :
                                                            "$ " + formatCurrency(eva.descuento, 2)
                                                        :
                                                        "$0.00"
                                            }
                                        </TableCellModify>
                                        <TableCellModify sx={{ textAlign: "center" }}>
                                            <InputRegex
                                                value={eva?.precioDescuento}
                                                onChange={e => {
                                                    let copyEvaluaciones = [...evaluaciones];
                                                    copyEvaluaciones[index].precioDescuento = e.target.value;

                                                    copyEvaluaciones[index].por =
                                                        eva.tipoEvaluacion == "Servicio"
                                                            ?
                                                            Number.parseFloat(100 - ((e.target.value / copyEvaluaciones[index].valorUIva) * 100)).toFixed(2)
                                                            :
                                                            Number.parseFloat(100 - ((e.target.value / copyEvaluaciones[index].precioPublico) * 100)).toFixed(2)

                                                    copyEvaluaciones[index].descuento =
                                                        eva.tipoEvaluacion == "Servicio"
                                                            ?
                                                            Math.round(((copyEvaluaciones[index].valorUIva - Math.round(e.target.value * 100) / 100)) * 100) / 100
                                                            :
                                                            Math.round(((copyEvaluaciones[index].precioPublico - Math.round(e.target.value * 100) / 100)) * 100) / 100

                                                    setEvaluaciones([...copyEvaluaciones]);
                                                }}
                                                typeInput={"currency"}
                                            />
                                        </TableCellModify>
                                        <TableCellModify sx={{ textAlign: "center" }}>
                                            {
                                                eva.tipoEvaluacion == "Servicio"
                                                    ?
                                                    eva.precioDescuento === 0
                                                        ?
                                                        "$0.00"
                                                        :
                                                        "$ " + formatCurrency(Math.round((eva.precioDescuento * eva.cantidad) * Math.pow(10, 2)) / Math.pow(10, 2), 2)
                                                    :
                                                    eva.tipoEvaluacion == "Producto"
                                                        ?
                                                        eva.precioDescuento === 0
                                                            ?
                                                            "$0.00"
                                                            :
                                                            "$ " + formatCurrency(Math.round((eva.precioDescuento * eva.cantidad) * Math.pow(10, 2)) / Math.pow(10, 2), 2)
                                                        :
                                                        "$0.00"
                                            }
                                        </TableCellModify>
                                        <TableCellModify sx={{ textAlign: "center" }}>
                                            <IconButton
                                                onClick={e => {
                                                    onDropEvaluacion(
                                                        eva.tipoEvaluacion == "Servicio"
                                                            ?
                                                            eva.idServicio
                                                            :
                                                            eva.tipoEvaluacion == "Producto"
                                                                ?
                                                                eva.idProductoInventario
                                                                :
                                                                eva.idInventarioLaboratorio
                                                        ,
                                                        eva.tipoEvaluacion == "Servicio"
                                                            ?
                                                            eva.concepto
                                                            :
                                                            eva.nombreProducto,
                                                        eva.tipoEvaluacion
                                                    );
                                                    setLoading(true);
                                                }}
                                            >
                                                <RemoveIcon />
                                            </IconButton>
                                        </TableCellModify>
                                    </TableRowModify>
                                )
                            }) : (
                                <TableRowModify>
                                    <TableCellModify></TableCellModify>
                                    <TableCellModify></TableCellModify>
                                    <TableCellModify> <CircularProgress /> </TableCellModify>
                                    <TableCellModify></TableCellModify>
                                    <TableCellModify></TableCellModify>
                                </TableRowModify>
                            )
                            }
                        </TableBody>
                    </Table>

                    {
                        evaluaciones.length > 0 && convenio && (
                            <Table className={entradas.tableEntradas} sx={{ marginTop: "30px" }}>
                                <TableHead>
                                    <TableRowModify>
                                        <TableCellModify>
                                            Total de Productos
                                        </TableCellModify>
                                        <TableCellModify>
                                            Total de Productos Laboatorio
                                        </TableCellModify>
                                        <TableCellModify>
                                            Total de Servicios
                                        </TableCellModify>
                                        <TableCellModify>
                                            Total
                                        </TableCellModify>
                                    </TableRowModify>
                                </TableHead>
                                <TableBody>
                                    <TableRowModify>
                                        {
                                            getCellsTotales()
                                        }
                                    </TableRowModify>
                                </TableBody>
                            </Table>
                        )
                    }
                </Grid>
            </Grid>
            <Grid component={Paper} container className={classes.paper} spacing={1}>
                <Grid item xs={12} sm={8} md={8} lg={10}>
                    <AutocompleteDeluxe
                        labelToShow="Sucursal"
                        labelProp="nombreSucursal"
                        disabled={sucursal}
                        nombre="sucursal"
                        dato={sucursal}
                        setDato={setSucursal}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <LoadingButton
                        disabled={!sucursal}
                        loading={loading}
                        fullWidth
                        variant="contained"
                        onClick={() => {
                            setSucursal(null)
                            setProducto(null)
                            setServicio(null)
                        }}
                        size="medium"
                        style={{ color: "#fff" }}
                    >
                        Cambiar
                    </LoadingButton>
                </Grid>
                {
                    sucursal && (
                        <>
                            <Grid item xs={12} sm={8} md={8} lg={10}>
                                <AutocompleteDeluxe
                                    disabled={!sucursal}
                                    labelToShow="Producto"
                                    labelProp="nombreProducto"
                                    nombre="producto-inventario"
                                    filtro={sucursal ? { idSucursal: sucursal?.idSucursal, expand: "inventario" } : { expand: "inventario" }}
                                    dato={producto}
                                    setDato={setProducto}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={2}>
                                <LoadingButton
                                    disabled={!producto}
                                    loading={loading}
                                    fullWidth
                                    variant="contained"
                                    onClick={() => addProducto()}
                                    size="medium"
                                    style={{ color: "#fff" }}
                                >
                                    Agregar Producto
                                </LoadingButton>
                            </Grid>
                        </>
                    )
                }
                {/* <Grid item xs={12} sm={8} md={8} lg={10}>
                    <AsyncAutocompleteSearchRework
                        label="Producto Laboratorio"
                        disabled={!sucursal}
                        labelProp="nombreProducto"
                        name="inventario-laboratorio"
                        onChange={setidLaboratorioCallback}
                        clearInput={clearLaboratorio}
                        extraParams={paramsMemoLaboratorio}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <LoadingButton
                        disabled={!laboratorio}
                        loading={loading}
                        fullWidth
                        variant="contained"
                        onClick={() => addLaboratorio()}
                        size="medium"
                        style={{ color: "#fff" }}
                    >
                        Agregar Producto Laboratorio
                    </LoadingButton>
                </Grid> */}
                {
                    sucursal && (
                        <>
                            <Grid item xs={12} sm={8} md={8} lg={10}>
                                <AutocompleteDeluxe
                                    labelToShow="Servicio"
                                    labelProp="concepto"
                                    disabled={!sucursal}
                                    nombre="servicio"
                                    filtro={sucursal ? { idSucursal: sucursal?.idSucursal, expand: "servicioDoctores.doctor" } : { expand: "servicioDoctores.doctor" }}
                                    setDato={setServicio}
                                    dato={servicio}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2}>
                                <LoadingButton
                                    disabled={!servicio}
                                    loading={loading}
                                    fullWidth
                                    variant="contained"
                                    onClick={() => addServicio()}
                                    size="medium"
                                    style={{ color: "#fff" }}
                                >
                                    Agregar Servicio
                                </LoadingButton>
                            </Grid>
                        </>
                    )
                }

            </Grid>
        </>
    );
}
export default Evaluacion;