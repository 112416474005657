import React, { useState, useEffect } from "react";
import { Grid, Button, Paper, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { useAlert } from "../../../../hooks/useAlert";
import { Save } from "@mui/icons-material";
import { useModel } from "../../../../hooks/useModel";
import Swal from "sweetalert2";
import { useAuth } from "../../../../hooks/useAuth";
import FirebaseService from "../../../../services/firebase";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
export const LaboratoriosComponent = ({
  idEvaluacion,
  idOrdenServicio,
  laboratorios,
  setGuardado,
  setDisabledByName,
  ...props
}) => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const id = idEvaluacion;
  const [admin] = useLocalStorage("admin", null);
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const { permiso } = useAuth();
  const [archivo, setArchivo] = useState(null);

  const [propiedades, setPropiedades] = useState(
    laboratorios
      ? laboratorios
      : {
        archivo: "",
        urlArchivo: "",
        comentario: "",
      }
  );

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({ name: "evaluaciones", id, redirectOnPost: false });

  useEffect(() => {
    if (Object.keys(modelError)?.length > 0 && !updateModelLoading) {
      console.log(modelError)
      Object.keys(modelError).forEach(
        error => {
          console.log(modelError[error])
          showAlert({ message: modelError[error], severity: "warning" })
        }
      )
      setGuardado(false)
    }
  }, [modelError])

  const onGuardar = async () => {

    let prePropiedades = { ...propiedades };
    let falla = false;
    let valoresPropiedades = Object.values(prePropiedades);
    Object.keys(prePropiedades).forEach((prop) => {
      if (prop != "personalEnfermeria" || prop != "medico" || prop != "cp") {
        if (!falla) {
          if (valoresPropiedades[prop] == "") {
            falla = true;
          }
        }
      }
    });

    setDisabledByName("laboratorios", !falla);

    if (falla) {
      showAlert({
        message: "Todos los campos son requeridos",
        severity: "warning",
      });
      return;
    }

    let body = { ...propiedades };

    if (archivo != null) {
      let url = "";
      let Narchivo = "";
      const ranm = Math.floor(Math.random() * 10000);
      const ranm2 = Math.floor(Math.random() * 10000);
      const ranm3 = Math.floor(Math.random() * 10000);
      const ranm4 = Math.floor(Math.random() * 10000);
      let firebaseName = "Laboratorio/" + ranm + "_" + ranm3 + "_" + archivo.name + "_" + ranm4 + "_" + ranm2;
      url = await FirebaseService.uploadFile(firebaseName, archivo);
      Narchivo = archivo.name;
      body = { ...body, urlArchivo: url, archivo: Narchivo };
    }


    let data = {
      idOrdenServicio,
      idEvaluacion,
      idSucursal: 1,
      Laboratorios: body,
    };
    await updateModel(data, true, "clinicaNuevaVida/nuevaConsulta");
    setGuardado(true);
  };
  return (
    <>
      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
          <TextField
            label={"Laboratorios".toUpperCase()}
            variant="standard"
            maxRows={5}
            multiline
            fullWidth
            size="medium"
            value={propiedades.comentario}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                comentario: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ padding: "5px" }}>
          <Button variant="contained" component="label" sx={{ color: "white" }}>
            Subir PDF
            <input
              onChange={(e) => {
                setArchivo(e.target.files[0]);
              }}
              hidden
              accept="application/pdf"
              type="file"
            />
          </Button>
          {archivo != null ? archivo.name : propiedades.archivo != "" && propiedades.archivo}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading ||
                  updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["evaluaciones"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<Save />}
              style={{ color: "#fff" }}
            >
              GUARDAR
              {/* {(!editing ? "GUARDAR" : "MODIFICAR")} */}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
