/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
//React
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/variables";
import { baseStyles, capitalizeFirst } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { useNotifications } from "../../hooks/useNotifications";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import {
  Grid,
  Menu,
  MenuItem,
  Switch,
  Avatar,
  Modal,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useModels } from "../../hooks/useModels";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import { firestore } from "../../firebase/firebaseConfig";
import moment from "moment-timezone";
import DatePicker from "../../components/DatePickerBeta";
import { DateRangePicker } from "rsuite";
import AsyncAutocompleteSearchBeta from "../../components/AsyncAutocompleteSearchBeta";
import AsyncAutocompleteSearchRework from "../../components/AsyncAutocompleteSearchRework";

//----------------------------------------------------------------------------------------------------------------------

//Librerias
import Swal from "sweetalert2";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import { Delete, Edit, Search, Add } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { BsFileEarmarkRichtextFill, BsJournalMedical } from "react-icons/bs";
//----------------------------------------------------------------------------------------------------------------------

const useStyles = baseStyles();

const Index = () => {
  //Estados Iniciales
  const classes = useStyles();

  const { setNotifications, conteo } = useNotifications();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [perPage, setPerPage] = React.useState(10);
  const [pagina, setPagina] = React.useState(1);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const [admin] = useLocalStorage("admin", null);
  const { user, permiso } = useAuth();
  const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
  const [clearInputDoctor, setClearInputDoctor] = useState(false);
  const [idPaciente, setIdPaciente] = useState("");
  const [sucursal, setSucursal] = useState(null);
  const [clearDoctor, setClearDoctor] = useState(false);
  const [clearPaciente, setClearPaciente] = useState(false);
  const [sucursales, setSucursales] = useState([]);
  const [doctor, setDoctor] = useState({});
  const [paciente, setPaciente] = useState({});

  const [isCliente, setIsCliente] = useState(permiso[0]["cliente"] & 1 === 1);

  const [propiedades, setPropiedades] = useState({
    idPaciente: "",
    idAgenda: "",
    folio: "",
    dia: "",
    hora: "",
    fecha: "",
    eliminado: 0,
    status: "",
    fechaInicio: moment().startOf("month").format("yyyy/MM/DD"),
    fechaFinal: moment(new Date()).format("yyyy/MM/DD"),
  });
  //----------------------------------------------------------------------------------------------------------------------

  //Estados Iniciales Controller
  const paramsMemoU = useMemo(
    () => ({
      ordenar: "nombreCompleto.asc",
      expand: "paciente",
    }),
    []
  );

  const paramsMemoDoctor = useMemo(
    () => ({
      ordenar: "nombreCompleto.asc",
      idSucursal: idSucursal ? idSucursal : null,
      expand: "agendas",
    }),
    [idSucursal]
  );

  const setDoctorCallback = useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setDoctor(v);
        setPropiedades({ ...propiedades, idAgenda: v?.agendas[0]?.idAgenda });
      } else {
        setDoctor(null);
        setPropiedades({ ...propiedades, idAgenda: "" });
      }
    },
    [setDoctor]
  );

  const setPacienteCallback = useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setPaciente(v);
        setPropiedades({ ...propiedades, idPaciente: v.idPaciente });
      } else {
        setPaciente(null);
        setPropiedades({ ...propiedades, idPaciente: "" });
      }
    },
    [setPaciente]
  );

  const parametrosInicialesMemo = useMemo(
    () => ({
      name: "cita",
      ordenar: "idCita.desc",
      extraParams: {
        expand: "paciente,agenda.doctor,ordenServicio,citaDatos",
        idSucursal,
        idUsuario: isCliente ? user?.idUsuario : "",
      },
    }),
    [idSucursal]
  );

  const reset = useCallback(async () => {
    await firestore
      .collection("alertaCita")
      .doc("JBySo0vNelJRFIgYgy3r")
      .set({ correoPromocion: 0, show: false }, { merge: true });
    setNotifications(0);
  }, [setNotifications]);

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({ ...parametrosInicialesMemo });

  const [
    modelsSucursales,
    modelsLoadingSucursales,
    modelsErrorSucursales,
    modelsPageSucursales,
    refreshModelsSucursales,
    deleteModelSucursales,
  ] = useModels({ name: "sucursal", ordenar: "nombreSucursal.asc" });

  //----------------------------------------------------------------------------------------------------------------------

  //Funciones

  const onSearchClicked = async (eliminado) => {
    let params = {};
    if (eliminado != null) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
      };
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage },
      };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async (page) => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async (per) => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  const onDeleteClicked = async (row, type) => {
    try {
      setOpenMenuRow(false);
      await Swal.fire({
        title: type,
        text: `¿Desea ${type} el dato seleccionado  "${capitalizeFirst(
          row.folio.toLowerCase()
        )}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34AA44",
        cancelButtonColor: "#E72C33",
        confirmButtonText: "Si, deseo Continuar.",
      }).then((result) => {
        if (result.isConfirmed) {
          let params = {};
          params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: pagina, limite: perPage },
          };
          deleteModel(row.idCita, type, params);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onVigenteClicked = async (row, type) => {
    try {
      setOpenMenuRow(false);
      await Swal.fire({
        title: "Cancelar",
        text: `¿Desea cancelar la cita con el folio "${capitalizeFirst(
          row.folio.toLowerCase()
        )}" seleccionada  ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34AA44",
        cancelButtonColor: "#E72C33",
        confirmButtonText: "Si, deseo Continuar.",
      }).then((result) => {
        if (result.isConfirmed) {
          let params = {};
          params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: pagina, limite: perPage },
          };
          deleteModel(row.idCita, type, params);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //----------------------------------------------------------------------------------------------------------------------

  //Efectos
  useEffect(() => {
    reset();
  }, []);

  //----------------------------------------------------------------------------------------------------------------------

  return (
    <TablePageLayout
      title="Cita"
      model="cita"
      history={history}
      actionButton={{
        to: "/cita/nuevo",
        icon: <Add />,
        label: "Agregar",
        permiso: "citas",
      }}
      links={[
        {
          label: "Citas",
          icon: <BsJournalMedical fontSize="small" />,
        },
      ]}
      SearchComponents={
        <>
          {
            isCliente ? <>
              <Grid item xs={12} md={4}>
                <InputRegex
                  label="Folio"
                  fullWidth
                  typeInput="text"
                  maxLength={150}
                  value={propiedades.folio}
                  onChange={(e) =>
                    setPropiedades((prevState) => ({
                      ...prevState,
                      ...prevState.propiedades,
                      folio: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid
                  item
                  xs={12}
                  md={4}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <DateRangePicker
                    value={[
                      new Date(propiedades.fechaInicio),
                      new Date(propiedades.fechaFinal),
                    ]}
                    style={{ width: "100%" }}
                    format="dd/MM/yyyy"
                    placeholder="Seleccione el rango de fechas"
                    onChange={(e) => {
                      setPropiedades({
                        ...propiedades,
                        fechaInicio: moment(e[0]).format("yyyy/MM/DD"),
                        fechaFinal: moment(e[1]).format("yyyy/MM/DD"),
                      });
                    }}
                  />
                </Grid>
              <Grid item xs={12} sm={4}>
                <LoadingButton
                  variant="contained"
                  onClick={() => onSearchClicked(null)}
                  loading={modelsLoading && true}
                  style={{
                    float: "right",
                    color: "whitesmoke",
                  }}
                  startIcon={<Search />}
                >
                  Buscar
                </LoadingButton>
              </Grid>
            </>
              :
              <>
                <Grid item xs={12} md={4}>
                  <InputRegex
                    label="Folio"
                    fullWidth
                    typeInput="text"
                    maxLength={150}
                    value={propiedades.folio}
                    onChange={(e) =>
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        folio: e.target.value,
                      }))
                    }
                  />
                </Grid>

                {admin && (
                  <>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="demo-simple-select-autowidth-label">
                          Sucursal
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select-autowidth"
                          label="Sucursales"
                          value={idSucursal ? idSucursal : " "}
                          onChange={(e) => {
                            setIdSucursal(e.target.value);
                          }}
                        >
                          <MenuItem value=" ">Todas</MenuItem>
                          {modelsSucursales &&
                            modelsSucursales.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.idSucursal}>
                                  {item.nombreSucursal}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}

                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <AsyncAutocompleteSearchRework
                    label="Pacientes"
                    labelProp="nombreCompleto"
                    name="paciente"
                    onChange={setPacienteCallback}
                    clearInput={clearPaciente}
                    extraParams={paramsMemoU}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <AsyncAutocompleteSearchRework
                    style={{ margin: "10px 0" }}
                    label="Nombre del profesional"
                    labelProp="nombreCompleto"
                    name="publico/doctores"
                    onChange={setDoctorCallback}
                    clearInput={clearDoctor}
                    extraParams={paramsMemoDoctor}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <DateRangePicker
                    value={[
                      new Date(propiedades.fechaInicio),
                      new Date(propiedades.fechaFinal),
                    ]}
                    style={{ width: "100%" }}
                    format="dd/MM/yyyy"
                    placeholder="Seleccione el rango de fechas"
                    onChange={(e) => {
                      setPropiedades({
                        ...propiedades,
                        fechaInicio: moment(e[0]).format("yyyy/MM/DD"),
                        fechaFinal: moment(e[1]).format("yyyy/MM/DD"),
                      });
                    }}
                  />
                </Grid>

                <Grid xs={12} md={4}>
                  {" "}
                </Grid>

                <Grid item xs={12} sm={4}>
                  <LoadingButton
                    variant="contained"
                    onClick={() => onSearchClicked(null)}
                    loading={modelsLoading && true}
                    style={{
                      float: "right",
                      color: "whitesmoke",
                    }}
                    startIcon={<Search />}
                  >
                    Buscar
                  </LoadingButton>
                </Grid>
              </>
          }
        </>
      }
      DisableComponents={
        <>
          <Grid item xs={8}>
            <FormControlLabel
              control={
                <Switch
                  disabled={modelsLoading && true}
                  onChange={(e) => {
                    setPropiedades((prevState) => ({
                      ...prevState,
                      ...prevState.propiedades,
                      eliminado: e.target.checked ? 1 : 0,
                    }));
                    onSearchClicked(e.target.checked ? 1 : 0);
                  }}
                />
              }
              label="Ver Citas Canceladas"
            />
          </Grid>

          {
            !isCliente &&
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="error"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnPdf}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}pdf/cita?${"admin=" + (admin ? 1 : 0)}
                      ${"&uid=" + user?.idUsuario}
                      ${"&fecha=" + propiedades.fecha}
                      ${"&folio=" + propiedades.folio}
                      ${"&idPaciente=" + propiedades.idPaciente}
                      ${"&idAgenda=" + propiedades.idAgenda}
                      ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`}
                  >
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="warning"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnExcel}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}excel/cita?${"admin=" + (admin ? 1 : 0)}
                      ${"&uid=" + user?.idUsuario}
                      ${"&fecha=" + propiedades.fecha}
                      ${"&folio=" + propiedades.folio}
                      ${"&idPaciente=" + propiedades.idPaciente}
                      ${"&idAgenda=" + propiedades.idAgenda}
                      ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`}
                  >
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          }
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          nombrePermiso="citas"
          permiso={permiso}
          header={[]}
          data={models}
          paginatedApi
          pagina={pagina}
          paginationPageSize={perPage}
          onRowsPerPageChangeApi={changePageRow}
          changePageApi={changePage}
          count={modelsPage !== null ? modelsPage.total : 0}
          labelRowsPerPage={"Renglones por página"}
          extraRows={[
            {
              prop: "idPaciente",
              name: "Paciente",
              sortable: false,
              cell: (row, index) => (
                <p key={index}>{row.paciente.nombreCompleto}</p>
              ),
            },
            {
              prop: "folio",
              name: "Folio",
              sortable: false,
              cell: (row, index) => <p key={index}>{row.folio}</p>,
            },
            {
              prop: "fecha",
              name: "Fecha",
              sortable: false,
              cell: (row, index) => <p key={index}>{moment(row.fecha).format('dddd DD [de] MMMM YYYY')}</p>,
            },
            {
              prop: "estatus",
              name: "Estatus",
              sortable: false,
              cell: (row, index) => <p key={index}>{row.status}</p>,
            },
            {
              prop: "rh",
              name: "Solicitante RH",
              sortable: false,
              cell: (row, index) => <p key={index}>{row.citaDatos.solicitudRH}</p>,
            },
            {
              prop: "status",
              name: "Cancelar",
              sortable: false,
              cell: (row, index) => (
                <Switch
                  disabled={
                    row &&
                      (row?.eliminado !== null)
                      ? true
                      : false
                  }
                  checked={row && row?.status === "CANCELADO" ? true : false}
                  onChange={(e) => {
                    onVigenteClicked(row, "Vigente");
                  }}
                />
              ),
            },
            {
              prop: "acciones",
              name: "Acciones",
              sortable: false,
              cell: (row, index) =>
                ((permiso && (permiso[0]["citas"] & 4) === 4) ||
                  (permiso[0]["citas"] & 8) === 8) && (
                  <div>
                    <IconButton
                      onClick={(event) => {
                        setOpenMenuRow(index);
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={index === openMenuRow}
                      onClose={() => setOpenMenuRow(null)}
                    >
                      {(admin === true ||
                        (permiso && permiso[0]["citas"] & 4) === 4) &&
                        row.eliminado == null && (
                          <NavLink
                            exact
                            to={row.status == "PROCESO" ? `ordenServicio/editar/${row?.ordenServicio?.idOrdenServicio}` : `ordenServicio/nuevo/${row.idCita}`}
                            style={{ color: "#000", textDecoration: "none" }}
                          >
                            <MenuItem
                              aria-label="ordenServicio"
                              onClick={() => {
                                history.push(
                                  row.status == "PROCESO" ?
                                    (`ordenServicio/editar/${row?.ordenServicio?.idOrdenServicio}`,
                                      { match: row?.ordenServicio?.idOrdenServicio })
                                    :
                                    (`ordenServicio/nuevo/${row.idPaciente}`,
                                      { match: row.idPaciente })
                                );
                              }}
                            >
                              <BsFileEarmarkRichtextFill style={{ paddingRight: 5 }} />
                              Orden Servicio
                            </MenuItem>
                          </NavLink>
                        )}

                      {(admin === true ||
                        (permiso && permiso[0]["citas"] & 8) === 8) &&
                        (row?.eliminado === null ? (
                          <MenuItem
                            onClick={() => onDeleteClicked(row, "Deshabilitar")}
                          >
                            <Delete style={{ paddingRight: 5 }} />
                            Deshabilitar
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={() => onDeleteClicked(row, "Habilitar")}
                          >
                            <RestoreFromTrashIcon style={{ paddingRight: 5 }} />
                            Habilitar
                          </MenuItem>
                        ))}
                    </Menu>
                  </div >
                ),
            },
          ]}
        />
      }
    />
  );
};
export default Index;
