/* eslint-disable array-callback-return */
/////////////////////////////////////////////////////////////////////////
//                                Hooks                                //
/////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useModel } from "../../hooks/useModel";
import { useHistory } from "react-router-dom";
import Service from "../../services/api";
import { useAlert } from "../../hooks/useAlert";
import { useLocalStorage } from "../../hooks/useLocalStorage";

////////////////////////////////////////////////////////////////////////
//                                Components                          //
////////////////////////////////////////////////////////////////////////
import {
  Grid, MenuItem, FormControl, InputLabel, Select, TextField, Typography, Button,
  Paper, Table, TableBody, TableHead, TableRow, TableCell,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import PageHeader from "../../components/PageHeader";
import Swal from "sweetalert2";
import Loading from "../../components/FullScreenLoader";
import Countdown from 'react-countdown';
import AsyncAutocompleteSearchRework from '../../components/AsyncAutocompleteSearchRework';
import moment from "moment-timezone";
import DatePickerBeta from "../../components/DatePickerBeta"

////////////////////////////////////////////////////////////////////////
//                                Styles                              //
////////////////////////////////////////////////////////////////////////
import { makeStyles } from "@mui/styles";
import { tableCellStyles, tableRowStyles, entradasStyle, capitalizeFirst } from '../../utils';

////////////////////////////////////////////////////////////////////////
//                                Icons                               //
////////////////////////////////////////////////////////////////////////
import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AutocompleteDeluxe from '../../components/AutocompleteDeluxe';

////////////////////////////////////////////////////////////////////////
//                                Extra                               //
////////////////////////////////////////////////////////////////////////
const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const useEntradasStyle = entradasStyle();
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

const Entradas = () => {
  //////////////////////////////////////////////////////////////////////
  //                              Vars                                //
  //////////////////////////////////////////////////////////////////////
  const classes = useStyles();
  const history = useHistory();
  const { showAlert } = useAlert();
  const entradas = useEntradasStyle();
  const [idSucursal] = useLocalStorage("idSucursal", "");
  const [reload, setReload] = useState(true);
  const [producto, setProducto] = useState(null);
  const [validated, setValidated] = useState(false);
  const [empresaTextField, setEmpresaTextField] = useState(false);
  const [clearInputProductos, setClearInputProductos] = useState(false);
  const [verificando, setVerificando] = useState(false);
  const empresaRef = useRef();
  const [actualizar, setActualizar] = useState(false);
  const operacionRef = useRef();

  const [movimientos, setMovimientos] = useState([]);

  const [propiedades, setPropiedades] = useState({
    comentario: "",
    operacion: "Ingreso",
    paciente: "",
    empresa: "BYLSA DRILLING SA DE CV",
    os: false,
    idSucursal: idSucursal
  });

  const parametrosInicialesMemo = useMemo(() => ({
    name: "entrada-inventario-laboratorio",
    expand: "movimientos.lote.sucursal",
    redirectOnPost: true,
  }), []);

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({ ...parametrosInicialesMemo });

  //////////////////////////////////////////////////////////////////////
  //                              Parametros Iniciales                //
  //////////////////////////////////////////////////////////////////////

  const productoParamsMemo = useMemo(() => ({ pagina: 1, limite: 10, idSucursal, expand: "inventario", ordenar: "nombreProducto.desc", actualizar }), [idSucursal, actualizar]);

  //////////////////////////////////////////////////////////////////////
  //                              Funcions Def                        //
  //////////////////////////////////////////////////////////////////////
  const addProducto = () => {
    setClearInputProductos(true);
    if (!producto) {
      showAlert({
        message: "Debe de seleccionar un producto para poder agregarlo.",
        severity: "error",
      });
      setReload(true);
      setValidated(false);
    } else {
      if (producto.idInventarioLaboratorio != null) {
        const repetido = movimientos.some(e => e.idInventarioLaboratorio === producto.idInventarioLaboratorio);
        if (repetido) {
          showAlert({
            message: "Este producto ya existe en la tabla, si desea agregar mas de este producto cambiar la cantidad en la tabla",
            severity: "error",
          });
          setValidated(false);
          setProducto(null);
        } else {
          if (propiedades.operacion === "Salida") {
            if (producto?.inventario) {
              if (producto?.inventario < 1) {
                showAlert({
                  message: "Este producto no tiene inventario disponible.",
                  severity: "warning",
                });
              } else {
                setMovimientos([...movimientos, { ...producto, fechaCaducidad: moment(new Date()).add("7", "d").format("DD/MM/YYYY"), inventario: 1 }]);
              }
            } else {
              showAlert({
                message: "Este producto no tiene inventario disponible.",
                severity: "warning",
              });
            }
          } else {
            setMovimientos([...movimientos, { ...producto, fechaCaducidad: moment(new Date()).add("7", "d").format("DD/MM/YYYY"), inventario: 1 }]);
          }
        }
      }
    }
    setReload(true);
    setValidated(false);
    setProducto(null);
  }

  const onDropDesglose = async (id, nombre) => {
    if (id) {
      const res = await Swal.fire({
        title: "Borrar",
        icon: "warning",
        text: `¿Estás seguro de que deseas borrar el dato seleccionado "${capitalizeFirst(nombre.toLowerCase())}"?`,
        showCancelButton: true,
        confirmButtonColor: "#25607A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, deseo Continuar!",
        cancelButtonText: "Cancelar",
      });
      if (res.isConfirmed) {
        showAlert({
          message: "Espera un momento.....",
          severity: "warning",
        });
        let posi;

        const copyDesglose = [...movimientos];
        movimientos.map((e, index) => {
          if (e.idInventarioLaboratorio === id) {
            posi = index;
          }
        });
        copyDesglose.splice(posi, 1);
        setMovimientos(copyDesglose);
        setReload(true);
        showAlert({
          message: "Producto borrado con éxito.",
          severity: "success",
        });
      } else {
        setReload(true);
      }
    }
  };

  const onGuardar = async () => {
    setValidated(false);
    if (movimientos.length > 0) {
      let obj = { ...propiedades, movimientos };
      if (propiedades.operacion !== "Salida") {
        obj = { ...obj, paciente: "null" }
      }
      const text = ("¿Estás seguro de que deseas continuar?").toUpperCase();
      const res = await Swal.fire({
        title: text,
        text: 'favor de corroborar la informacion antes de continuar',
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#25607A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Continuar!",
        cancelButtonText: "Cancelar",
      });
      if (res.isConfirmed) {
        showAlert({
          message: "Espera un momento.....",
          severity: "warning",
        });
        updateModel(obj, true, "entradasLaboratorio");
        setMovimientos([]);
      }
    }
    setActualizar(!actualizar);
  }

  const validar = async () => {
    setVerificando(true);
    setReload(false);
    let fallo = false;
    let res = await Service.get("inventario-laboratorio?expand=inventario");
    if (res?.resultado.length > 0) {
      movimientos.forEach((mov, i) => {
        if (mov.inventario < 1) {
          showAlert({
            message: "la salida del producto " + mov.nombreProducto + " no puede ser menor que 0",
            severity: "error",
          });
          if (!fallo) {
            fallo = true;
          }
        } else if (mov.inventario > 1500) {
          showAlert({
            message: "la salida del producto " + mov.nombreProducto + " no puede ser mayor que 1500",
            severity: "error",
          });
          if (!fallo) {
            fallo = true;
          }
        } else {
          let getProductoById = res.resultado.filter((p) => {
            return p.idInventarioLaboratorio === mov.idInventarioLaboratorio;
          });
          if (parseInt(mov.inventario) > getProductoById[0].inventario) {
            if (getProductoById[0].inventario === 0) {
              showAlert({
                message: "el producto " + mov.nombreProducto + " no tiene inventario disponible",
                severity: "error",
              });
              showAlert({
                message: "el producto " + mov.nombreProducto + " se elimino de la lista",
                severity: "info",
              });
              let posi;
              const copyDesglose = [...movimientos];
              movimientos.map((e, index) => {
                if (e.idInventarioLaboratorio === mov.idInventarioLaboratorio) {
                  posi = index;
                }
              });
              copyDesglose.splice(posi, 1);
              setMovimientos(copyDesglose);
            } else {
              showAlert({
                message: "el producto " + mov.nombreProducto + " no tiene suficiente inventario disponible favor de verificar de nuevo",
                severity: "error",
              });
              showAlert({
                message: "el producto " + mov.nombreProducto + " tiene un inventario de " + getProductoById[0].inventario,
                severity: "info",
              });
              let copyMovimientos = [...movimientos];
              copyMovimientos[i].inventario = getProductoById[0].inventario;
              setMovimientos([...copyMovimientos]);
            }
            if (!fallo) {
              fallo = true;
            }
          }
        }
      });
    } else {
      showAlert({
        message: "No se pudo verificar!",
        severity: "error",
      });
    }
    if (!fallo) {
      setValidated(true);
    }
    setReload(true);
    setVerificando(false);
  }
  //////////////////////////////////////////////////////////////////////
  //                              Hooks Def                           //
  //////////////////////////////////////////////////////////////////////

  const setProductoCallback = useCallback((e, v) => {
    if (v !== "" && v) {
      setProducto(v);
    } else {
      setProducto(null);
    }
  }, [setProducto]);

  useEffect(() => {
    if (clearInputProductos) {
      setClearInputProductos(false);
    }
  }, [clearInputProductos]);

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader history={history} title="Entradas" />

      <Grid component={Paper} container className={classes.paper} spacing={1} style={{ marginTop: 10, boxShadow: 'none', border: '3px solid #ebebeb', }}>
        <Grid container spacing={2} style={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={3}>
            <FormControl ref={operacionRef} fullWidth variant="standard">
              <InputLabel id="operacionSelect">Operación</InputLabel>
              <Select
                fullWidth
                labelId="operacionSelect"
                id="operacionSelectId"
                label="Operación"
                value={propiedades.operacion}
                onChange={(e) => {
                  setPropiedades(prevState => ({
                    ...prevState,
                    ...prevState.propiedades,
                    operacion: e.target.value
                  }));
                  setMovimientos([]);
                  setClearInputProductos(true);
                  setProducto(null);
                }}>
                <MenuItem value="Ingreso"> Ingreso </MenuItem>
                <MenuItem value="Salida"> Salida </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Comentario"
              multiline
              rows={3}
              fullWidth
              size="small"
              onChange={(e) => {
                setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  comentario: e.target.value
                }))
                setValidated(false);
              }}
              inputProps={{ value: propiedades.comentario }}
            />
          </Grid>

          {propiedades.operacion === "Salida" && (
            <>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Paciente"
                  fullWidth
                  variant="standard"
                  size="small"
                  onChange={(e) => {
                    setPropiedades(prevState => ({
                      ...prevState,
                      ...prevState.propiedades,
                      paciente: e.target.value.toUpperCase(),
                    }))
                    setValidated(false);
                  }}
                  helperText={modelError?.paciente}
                  error={Boolean(modelError?.paciente)}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="empresaSelect">Empresa</InputLabel>
                  <Select
                    fullWidth
                    labelId="empresaSelect"
                    id="empresaSelectId"
                    label="Empresa"
                    value={propiedades.empresa}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setEmpresaTextField(true);
                      } else {
                        setEmpresaTextField(false);
                      }
                      setPropiedades(prevState => ({
                        ...prevState,
                        ...prevState.propiedades,
                        empresa: e.target.value.toUpperCase()
                      }))
                      setValidated(false);
                    }}
                  >
                    <MenuItem value="AMASFAC">
                      AMASFAC
                    </MenuItem>
                    <MenuItem value="AMMJE">
                      AMMJE
                    </MenuItem>
                    <MenuItem value="ASOCIACIÓN GANADERA LOCAL GENERAL DE HERMOSILLO">
                      ASOCIACIÓN GANADERA LOCAL GENERAL DE HERMOSILLO
                    </MenuItem>
                    <MenuItem value="BYLSA DRILLING SA DE CV">
                      BYLSA DRILLING SA DE CV
                    </MenuItem>
                    <MenuItem value="COORPORATIVO ENERVISIÓN, SAPI DE CV (GASOLINERIAS ARCO)">
                      COORPORATIVO ENERVISIÓN, SAPI DE CV (GASOLINERIAS ARCO)
                    </MenuItem>
                    <MenuItem value="GNP SEGUROS">
                      GNP SEGUROS
                    </MenuItem>
                    <MenuItem value="GREEN GEOLOGY SA DE CV">
                      GREEN GEOLOGY SA DE CV
                    </MenuItem>
                    <MenuItem value="HITO EXPLORATION, CAMPING & CATERING SERVICES">
                      HITO EXPLORATION, CAMPING & CATERING SERVICES
                    </MenuItem>
                    <MenuItem value="HOTEL KINO SA DE CV">
                      HOTEL KINO SA DE CV
                    </MenuItem>
                    <MenuItem value="MATCO">
                      MATCO
                    </MenuItem>
                    <MenuItem value="MINERALES DE TARACHI">
                      MINERALES DE TARACHI
                    </MenuItem>
                    <MenuItem value="PARTICULARES">
                      PARTICULARES
                    </MenuItem>
                    <MenuItem value="SINDICATO UNICO DE TRABAJADORES ELECTRICISTAS DE LA REPUBLICA MEXICANA SECCION 49">
                      SINDICATO UNICO DE TRABAJADORES ELECTRICISTAS DE LA REPUBLICA MEXICANA SECCION 49
                    </MenuItem>
                    <MenuItem value="UNIVERSIDAD DEL TERCER MILENIO S.C (UNID)">
                      UNIVERSIDAD DEL TERCER MILENIO S.C (UNID)
                    </MenuItem>
                    <MenuItem value="">
                      Otra
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {empresaTextField && (
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Empresa Externa"
                    fullWidth
                    ref={empresaRef}
                    variant="standard"
                    size="small"
                    onChange={(e) => {
                      setPropiedades(prevState => ({
                        ...prevState,
                        ...prevState.propiedades,
                        empresa: e.target.value.toUpperCase()
                      }))
                      setValidated(false);
                    }}
                    inputProps={{ value: propiedades.empresa.toUpperCase() }}
                  />
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12} sm={10} sx={{ marginBottom: '10px' }}>
            <AutocompleteDeluxe
              labelToShow="PRODUCTOS LABORATORIO"
              dato={producto}
              filtro={{ expand: "inventario" }}
              setDato={setProducto}
              labelProp="nombreProducto"
              nombre="inventario-laboratorio"
            />
          </Grid>

          <Grid item xs={12} sm={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
            <LoadingButton
              disabled={!producto}
              onClick={(e) => { addProducto() }}
              style={{ color: '#FFF' }} variant="contained"><AddIcon /> Agregar Producto</LoadingButton>
          </Grid>

          {movimientos.length > 0 && (
            <Grid container spacing={2} style={{ alignItems: 'center' }}>
              <Grid item xs={12}>
                <Table className={entradas.tableEntradas}>
                  <TableHead>
                    <TableRowModify>
                      <TableCellModify> # </TableCellModify>
                      <TableCellModify> NOMBRE </TableCellModify>
                      <TableCellModify> CANTIDAD </TableCellModify>
                      {propiedades.operacion !== 'Salida' && (
                        <TableCellModify> FECHA DE CADUCIDAD </TableCellModify>
                      )}
                      <TableCellModify> ELIMINAR </TableCellModify>
                    </TableRowModify>
                  </TableHead>
                  <TableBody>
                    {reload && movimientos.map((prod, i) => (
                      <TableRowModify key={i}>
                        <TableCellModify> {i + 1} </TableCellModify>
                        <TableCellModify> {prod?.nombreProducto?.toUpperCase()} </TableCellModify>
                        <TableCellModify>
                          <TextField
                            label="Cantidad"
                            type="number"
                            defaultValue={prod?.inventario}
                            variant="outlined"
                            onChange={e => {
                              setValidated(false);
                              let copyMovimientos = [...movimientos];
                              copyMovimientos[i].inventario = e.target.value;
                              setMovimientos([...copyMovimientos]);
                            }}
                            inputProps={{ min: 1, }}
                          />
                        </TableCellModify>
                        {propiedades.operacion !== 'Salida' && (
                          <TableCellModify>
                            <TextField
                              labelText="Fecha de Caducidad"
                              variant="standard"
                              value={prod?.fechaCaducidad}
                              onChange={e => {
                                setValidated(false);
                                let copyMovimientos = [...movimientos];
                                copyMovimientos[i].fechaCaducidad = e.target.value;
                                setMovimientos([...copyMovimientos]);
                              }}
                            />
                          </TableCellModify>
                        )}
                        <TableCellModify>
                          <IconButton
                            onClick={e => {
                              onDropDesglose(prod.idInventarioLaboratorio, prod.nombreProducto);
                              setValidated(false);
                              setReload(false)
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </TableCellModify>
                      </TableRowModify>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          )}
        </Grid>


        {
          propiedades.operacion === "Salida"
            ?
            (movimientos.length > 0) && (
              <Grid item xs={12} xl={12}>
                <Grid container justifyContent="flex-end" alignItems="center">
                  {validated && (<Typography style={{ marginRight: '10px', color: '#F6AB2F', fontWeight: 600 }} type="p">La validación expira en:  <Countdown onComplete={e => { setValidated(false) }} style={{ marginRight: '10px', color: '#F6AB2F', fontWeight: 600 }} date={Date.now() + 60000} /></Typography>)}
                  <Button
                    disabled={validated ? true : verificando}
                    variant="contained"
                    onClick={e => { validar() }}
                    size="large"
                    style={
                      validated
                        ?
                        { background: "#2E7D32", color: "#FFF", marginRight: '10px' }
                        :
                        verificando
                          ?
                          { background: "rgb(224 224 224)", color: "#FFF", marginRight: '10px' }
                          :
                          { background: "#D32F2F", color: "#FFF", marginRight: '10px' }
                    }
                  >
                    {validated ? "VALIDADO" : verificando ? "VALIDANDO..." : "VALIDAR INFORMACIÓN"}
                  </Button>
                  {validated && (
                    <LoadingButton
                      loading={modelLoading || updateModelLoading}
                      variant="contained"
                      disabled={!validated}
                      onClick={e => { onGuardar() }}
                      size="large"
                      style={{ color: "#fff" }}
                    >
                      GUARDAR
                    </LoadingButton>
                  )}
                </Grid>
              </Grid>
            )
            :
            <Grid item xs={12} xl={12}>
              <Grid container justifyContent="flex-end" alignItems="center">
                <LoadingButton
                  loading={modelLoading || updateModelLoading}
                  variant="contained"
                  disabled={(movimientos.length < 1)}
                  onClick={e => { onGuardar() }}
                  size="large"
                  style={{ color: "#fff" }}
                >
                  GUARDAR
                </LoadingButton>
              </Grid>
            </Grid>
        }
      </Grid>
    </>
  );
}
export default Entradas;