/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react'

import StepWizardNoDesing from '../../../components/StepWizardNoDesing';
import Step1 from './step1';
import Step2 from './step2';

const Index = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const nextStep = (step) => {
        setCurrentStep(step);
    }
    const setSolicitud = (progress, pos) => {
        let stepsCopy = steps;
        stepsCopy.map((step, i) => {
            if (i == pos) {
                step.value = progress
            }
        });
        setSteps([...stepsCopy]);
    }
    const [steps, setSteps] = useState([
        {
            active: false,
            container: <Step1 nextStep={nextStep} setSolicitud={setSolicitud} />,
            value: 0
        },
        {
            active: false,
            container: <Step2 nextStep={nextStep} setSolicitud={setSolicitud} />,
            value: 0
        }
    ]);

    const fondo = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2FFondo.jpg?alt=media&token=8c6b4630-df68-469d-8eaa-7648cb6c1374";

    useEffect(() => {
        if (currentStep > 0) {
            let stepsCopy = steps;
            stepsCopy.map((step, i) => {
                if (i < currentStep) {
                    step.active = true;
                    step.value = 100
                } else {
                    step.active = false;
                    step.value = 0
                }
            });
            setSteps([...stepsCopy]);
        }
    }, [currentStep]);

    return (
        <>
            <div style={{ position: "absolute", left: "0", right: "0", bottom: "0", top: "0", zIndex: "1" }}>
                <img style={{ height: "100%", width: "100%", objectFit: "cover", objectPosition: "bottom" }} src={fondo} alt={"fondo"} />
            </div>
            <div style={{ position: "absolute", left: "0", right: "0", bottom: "0", top: "0", zIndex: "3",overflowY: "scroll",overflowX: "scroll" }}>
                <div style={{ paddingTop: '20px'}}>
                    <StepWizardNoDesing steps={steps} currentStep={currentStep} />
                </div>
            </div>
        </>
    );
}
export default Index;