/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
//React
import React, { useEffect, useState, useMemo, useRef } from "react";
import { capitalizeFirst } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import { useModel } from "../../hooks/useModel";
import { useModels } from "../../hooks/useModels";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import { useAlert } from "../../hooks/useAlert";
import moment from "moment-timezone";
import FirebaseService from "../../services/firebase";
import Loading from "../../components/FullScreenLoader";
import DatePickerBeta from '../../components/DatePickerBeta';

//----------------------------------------------------------------------------------------------------------------------

//Librerias
import {
  Grid, MenuItem, Switch, FormControl, InputLabel, Select,
  TextField, Typography, Divider, CardMedia, Button, Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Barcode from "react-barcode";
import Modal from 'react-modal';
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import { Add, Edit, PhotoCamera } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import InventoryIcon from '@mui/icons-material/Inventory';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
//----------------------------------------------------------------------------------------------------------------------

//Imagenes
import scanerGif from "../../assets/img/barcode-scan.gif";
import PageHeader from "../../components/PageHeader";
import Tabs from "../../components/TabsRework";
import { BsCameraFill } from "react-icons/bs";
import ReactFileManager from "../../components/ReactFileManager";
import { FiUpload } from "react-icons/fi";
import Swal from "sweetalert2";
//----------------------------------------------------------------------------------------------------------------------

//Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
//----------------------------------------------------------------------------------------------------------------------
Modal.setAppElement(document.getElementById("#modal"));
const Index = ({ match, history, dialog }) => {
  //Estados Iniciales
  const classes = useStyles();
  const { showAlert } = useAlert();
  const id = match.params.id;
  const editing = !!id;
  const [admin] = useLocalStorage("admin", null);
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const { permiso } = useAuth()
  const textError = useRef();
  const [archivo, setArchivo] = useState();
  const [urlImg, setUrlImg] = useState("");
  const [qr, setQr] = useState("");
  const [modalType, setModalType] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [pagina, setPagina] = useState(1);
  const [newObjSucursales, setNewObjSucursales] = useState([]);
  const [tipoImagen, setTipoImagen] = useState("file");
  const [pos, setPos] = useState(0);
  const [foto, setFoto] = useState(null);
  const [video, setVideo] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  //----------------------------------------------------------------------------------------------------------------------

  //Estados Iniciales Controller
  const header = [
    {
      name: "Nombre Sucursal",
      prop: "nombreSucursal",
    }
  ];

  const [propiedades, setPropiedades] = useState({
    nombreProducto: "",
    serie: "",
    departamento: 'pruebas-rapidas',
    almacen: 'Laboratorio',
    marca: "",
    modelo: "",
    descripcion: '',
    comentario: '',
    urlFoto: 'https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Productos%2Fdefault.png.png?alt=media&token=bea632da-49a3-44b0-ae46-d8fcf9070fd7',
    tipo: "Toma de Muestra",
    tipoM: "Mg",
    via: 'Vía oral',
    talla: "mediano",
    dosis: "",
    orden: "",
    cantidadUnidad: "0",
    porcentaje: 0,
    precioProveedor: '',
    precioPublico: '0',
    precioUnidad: '0',
    medida: "Mg",
    inventarioLaboratorioSucursal: []
  });

  //Tabla GET
  const parametrosInicialesMemo = useMemo(
    () => ({
      name: "sucursal",
      ordenar: "idSucursal.asc",
      extraParams: { idSucursal: idSucursal },
    }),
    []
  );
  const [models, modelsPage, refreshModels, deleteModel,] =
    useModels({ ...parametrosInicialesMemo });

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "inventario-laboratorio",
      expand: "inventarioLaboratorioSucursal.sucursal",
      id,
      redirectOnPost: true,
    });

  //----------------------------------------------------------------------------------------------------------------------

  //Funciones

  const changePage = async (page) => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async (per) => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  //----------------------------------------------------------------------------------------------------------------------

  //Funciones Basicas

  const handleFileRead = async ({ target }) => {
    let file = target.files[0];
    const typePhoto = file.name.split('.').pop();
    switch (typePhoto) {
      case 'jpg':
      case 'JPG':
      case 'JPEG':
      case 'PNG':
      case 'GIF':
      case 'jpeg':
      case 'png':
      case 'gif': break;
      default:
        showAlert({ message: 'El archivo no tiene la extensión adecuada', severity: "warning", });
        file = '';
        return;
    }
    if (file !== undefined) {
      setArchivo(file);
      setPropiedades((prevState) => ({
        ...prevState,
        ...prevState.propiedades,
        urlFoto: URL.createObjectURL(target.files[0]),
      }));
    }
  };

  const onGuardar = async () => {
    let respaldo = [];
    let sucursalesFiltered = [];
    if (editing) {
      model.inventarioLaboratorioSucursal.map(sucursal => {
        if (!models.idSucursal == sucursal.idSucursal) {
          respaldo.push({ ...sucursal.sucursal, active: true })
        }
      });
      let sucursalesToFilter = newObjSucursales.concat(respaldo);
      sucursalesToFilter.map(sucursal => {
        if (sucursal.active) {
          sucursalesFiltered.push(sucursal);
        }
      });
    } else {
      let sucursalesToFilter = newObjSucursales;
      sucursalesToFilter.map(sucursal => {
        if (sucursal.active) {
          sucursalesFiltered.push(sucursal);
        }
      });
    }
    if (sucursalesFiltered.length === 0) {
      showAlert({ message: 'Seleccione al menos una sucursal', severity: "warning", });
      return;
    }
    let body = propiedades;

    if (propiedades.tipoM === "talla") {
      body = { ...body, medida: 0, talla: propiedades.talla };
    } else {
      body = { ...body, medida: propiedades.medida, talla: 'null' };
    }
    body = { ...body, dosis: 'null' };
    let urlFileProducto = "";
    const producto = propiedades.nombreProducto.split(" ").join("").toLowerCase();
    const ranm = Math.floor(Math.random() * 1000);
    const pathFirebase = "Producto/" + "Imagen" + "_" + producto + "_" + ranm;
    if (tipoImagen == "file") {
      if (archivo) {
        urlFileProducto = await FirebaseService.uploadFile(pathFirebase, archivo);
        body = { ...body, urlFoto: urlFileProducto };
      }
    } else {
      if (foto) {
        urlFileProducto = await FirebaseService.uploadFile(pathFirebase, foto);
        body = { ...body, urlFoto: urlFileProducto };
      }
    }
    let title = "";
    editing
      ? (title = "¿Deseas Editar este Producto?")
      : (title = "¿Deseas Guardar este Producto?");

    const res = await Swal.fire({
      title: title,
      text: "Favor de corroborar sus datos antes de continuar.",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Continuar!",
      cancelButtonText: "Cancelar",
    });

    if (body.urlFoto === "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Productos%2Fdefault.png.png?alt=media&token=bea632da-49a3-44b0-ae46-d8fcf9070fd7" && res.isConfirmed === true) {
      const resp = await Swal.fire({
        title: "¡No ha seleccionado una imagen.!",
        text: "Se agregarar un imagen por defecto, ¿Desea continuar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Continuar!",
        cancelButtonText: "Cancelar",
      });
      if (resp.isConfirmed) {
        showAlert({
          message: "Guardando imagen...",
          severity: "warning",
        });
        if (editing) {
          body.idInventarioLaboratorio = id;
          body = { ...body, idInventarioLaboratorio: id, inventarioLaboratorioSucursal: sucursalesFiltered }
          updateModel(body, true, "productosLaboratorio");
        }
        body = { ...body, inventarioLaboratorioSucursal: sucursalesFiltered }
        updateModel(body, true, "productosLaboratorio");
      } else {
        modelLoading(false);
      }
    }
    if (res.isConfirmed && body.urlFoto !== "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Productos%2Fdefault.png.png?alt=media&token=bea632da-49a3-44b0-ae46-d8fcf9070fd7") {
      showAlert({
        message: "Guardando imagen...",
        severity: "warning",
      });
      showAlert({
        message: "Guardando producto...",
        severity: "warning",
      });
      if (editing) {
        body.idInventarioLaboratorio = id;
        body = { ...body, idInventarioLaboratorio: id, inventarioLaboratorioSucursal: sucursalesFiltered }
        updateModel(body, true, "productosLaboratorio");
      }
      body = { ...body, inventarioLaboratorioSucursal: sucursalesFiltered }
      updateModel(body, true, "productosLaboratorio");
    }
  };

  const changePos = async _pos => {
    if (_pos == 0) {
      setTipoImagen("file");
    } else {
      setTipoImagen("foto");
    }
    setPos(_pos);
  };

  const deleteImg = async (url) => {
    await FirebaseService.deleteFile("ProductosLab/", url);
    setUrlImg("");
  };

  const getFilterArraySucursales = (array) => {
    return array.map((e) => {
      if (editing) {
        if (model) {
          if (model.inventarioLaboratorioSucursal.some(sucursal => sucursal.idSucursal == e.idSucursal)) {
            return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: true }
          } else {
            return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
          }
        } else {
          return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
        }
      } else {
        return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
      }
    })
  }

  const getVideo = _video => {
    setVideo(_video);
  }
  function openModal() {
    setIsOpen(true);
  };
  function closeModal() {
    setIsOpen(false);
  };
  const onCloseBarcode = () => {
    closeModal();
  }
  //----------------------------------------------------------------------------------------------------------------------
  //Efectos Callbacks

  useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setPropiedades(model);
      setQr(model?.serie);
      const newObj = getFilterArraySucursales(models);
      setNewObjSucursales(newObj);
    }
    return () => {
      mounted = false;
      setPropiedades({
        nombreProducto: "",
        serie: "",
        departamento: 'pruebas-rapidas',
        almacen: 'Laboratorio',
        marca: "",
        modelo: "",
        descripcion: '',
        comentario: '',
        urlFoto: 'https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Productos%2Fdefault.png.png?alt=media&token=bea632da-49a3-44b0-ae46-d8fcf9070fd7',
        tipo: "Toma de Muestra",
        tipoM: "Mg",
        via: 'Vía oral',
        talla: "mediano",
        dosis: "",
        orden: "",
        cantidadUnidad: "0",
        porcentaje: 30,
        precioProveedor: '',
        precioPublico: '',
        precioUnidad: '',
        medida: "Mg",
        inventarioLaboratorioSucursal: []
      });
    };
  }, [model]);

  useEffect(() => {
    if (modelError) {
      if (urlImg !== "" && urlImg !== undefined) {
        deleteImg(urlImg);
      }
    }
  }, [modelError, urlImg]);

  useEffect(() => {
    if (models) {
      const newObj = getFilterArraySucursales(models);
      setNewObjSucursales(newObj);
    }
  }, [models]);

  useEffect(() => {
    const newPropiedades = propiedades;
    newPropiedades.serie = qr;
    setPropiedades({ ...propiedades, ...newPropiedades });
  }, [qr]);

  if (modelLoading) return <Loading />;

  return (
    <>
      <div id="modal" style={{ zIndex: "99" }}>
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal" ariaHideApp={false}>
          {(modalType == "barcode") && (
            <ReactFileManager typeFile={"barcode"} setBarcode={setQr} barcode={qr} onCloseBarcode={onCloseBarcode} />
          )}
          {modalType == "qr" && (
            <>
              <input
                style={{ opacity: "0", position: "absolute", cursor: "default", }}
                autoFocus={true}
                onBlur={({ target }) => { target.focus() }}
                onChange={(e) => {
                  if (e.target.value.match(/ñ/g)) {
                    showAlert({
                      message: "El codigo de barras no acepta ñ",
                      severity: "warning",
                    });
                    closeModal();
                  } else {
                    setQr(e.target.value.replaceAll("'", "-"));
                  }
                }}
                onKeyPress={(event) => {
                  if (event.which == 13 || event.keyCode == 13) {
                    event.target.value = "";
                    closeModal();
                    return false;
                  }
                  return true;
                }}
              />
              <img src={scanerGif} alt="Escanea codigo de barras" style={{ width: '300px', height: '300px' }} />
              <p style={{ zIndex: 999, textAlign: 'center', color: 'rgba(100,100,100,1)', fontSize: '20px' }}>{propiedades.serie.replaceAll("'", "-")}</p>
            </>
          )}
        </Modal>
      </div>
      <PageHeader
        history={history}
        title={(!editing ? "NUEVO" : "EDITAR") + " PRODUCTO"}
        links={[
          {
            to: "/productosLaboratorio",
            icon: <InventoryIcon fontSize="small" />,
            label: "Producto Laboratorio",
          },
          {
            to: !editing ? "/productoLaboratorio/nuevo" : "/productoLaboratorio/editar/" + id,
            icon: !editing ? <Add /> : <Edit />,
            label: !editing
              ? "Nuevo"
              : "Editar - " +
              capitalizeFirst(
                propiedades?.nombreProducto.toLocaleLowerCase()
              ),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid component={Paper} container className={classes.paper} spacing={1} style={{ marginTop: 10 }} >
        <Grid item xs={12}>
          <div><strong>{"INFORMACIÓN DEL PRODUCTO"}</strong></div>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Nombre del producto"
            variant="standard"
            color="primary"
            fullWidth
            typeInput="text"
            maxLength={50}
            value={propiedades.nombreProducto.toUpperCase()}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                nombreProducto: e.target.value,
              }))
            }
            helperText={modelError.nombreProducto}
            error={Boolean(modelError.nombreProducto)}
          />
        </Grid>

        {qr != "" ? (
          <Grid item xs={12} md={4} style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
            <IconButton
              style={{ height: "fit-content", margin: "5px" }}
              onClick={() => {
                setModalType("qr");
                openModal();
              }}
            >
              <DocumentScannerIcon />
            </IconButton>
            <IconButton style={{ height: 'fit-content', margin: '5px' }} onClick={() => {
              setModalType("barcode")
              openModal();
            }}>
              <CameraAltIcon />
            </IconButton>
            <Barcode height={30} width={1.2} fontSize={13} margin={0} marginTop={20} value={qr}
              onClick={() => {
                setModalType("qr");
                openModal();
              }}
            />
            <IconButton
              style={{ height: "fit-content", margin: "5px" }}
              onClick={() => {
                setQr("");
                const newPropiedades = propiedades;
                newPropiedades.serie = "";
                setPropiedades({ ...propiedades, ...newPropiedades });
              }}
            >
              <CloseIcon />
            </IconButton>
            {modelError?.serie && (
              <IconButton
                onClick={() => {
                  setModalType("qr");
                  openModal();
                }}
              >
                <ErrorOutlineIcon style={{ color: "#AA0A00", margin: "0 10px" }} />
                <Typography style={{ color: "#AA0A00" }} ref={textError} />
              </IconButton>
            )}
          </Grid>
        ) : (
          <Grid item xs={12} md={4} style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
            <IconButton
              style={{ height: "fit-content", margin: "5px" }}
              onClick={() => {
                setModalType("qr");
                openModal();
              }}
            >
              <DocumentScannerIcon />
            </IconButton>
            <IconButton style={{ height: 'fit-content', margin: '5px' }} onClick={() => {
              setModalType("barcode")
              openModal();
            }}>
              <CameraAltIcon />
            </IconButton>
            {Boolean(modelError?.serie) && (
              <IconButton
                onClick={() => {
                  setModalType("qr");
                  openModal();
                }}
              >
                <ErrorOutlineIcon style={{ color: "#AA0A00", margin: "0 10px" }} />
                <Typography style={{ color: "#AA0A00" }} ref={textError} />
              </IconButton>
            )}
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="tipoEoM">Seleccionar Tipo</InputLabel>
            <Select
              disabled={editing}
              labelId="tipoEoM"
              fullWidth
              label="Tipo"
              onChange={(e) => {
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  tipo: e.target.value,
                }));
              }}
              inputProps={{
                value: propiedades.tipo,
              }}
            >
              <MenuItem value={"Toma de Muestra"}>Toma de Muestra</MenuItem>
              <MenuItem value={"Reactivo"}>Reactivo</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="departamento">Seleccionar Departamento</InputLabel>
            <Select
              labelId="departamento"
              fullWidth
              label="Departamento"
              onChange={(e) => {
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  departamento: e.target.value,
                }));
              }}
              inputProps={{
                value: propiedades.departamento,
              }}
            >
              <MenuItem value={"biologia-molecular"}>Biología Molecular</MenuItem>
              <MenuItem value={"coproanalisis"}>Coproanalisis</MenuItem>
              <MenuItem value={"cristaleria"}>Cristaleria</MenuItem>
              <MenuItem value={"Microbiología"}>Microbiología</MenuItem>
              <MenuItem value={"Uroanalisis"}>Uroanalisis</MenuItem>
              <MenuItem value={"ginecologia"}>Ginecología</MenuItem>
              <MenuItem value={"hematologia-quimica-sanguinea "}>Hematología y Química sanguínea </MenuItem>
              <MenuItem value={"proteccion-personal"}>Protección Personal</MenuItem>
              <MenuItem value={"pruebas-rapidas"}>Pruebas rápidas</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            label="Marca"
            variant="standard"
            fullWidth
            size="medium"
            onChange={(e) => setPropiedades(prevState => ({
              ...prevState,
              ...prevState.propiedades,
              marca: e.target.value
            }))
            }
            inputProps={{
              value: propiedades.marca.toUpperCase(),
            }}
            helperText={modelError.marca}
            error={Boolean(modelError.marca)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Modelo"
            variant="standard"
            fullWidth
            size="medium"
            onChange={(e) => setPropiedades(prevState => ({
              ...prevState,
              ...prevState.propiedades,
              modelo: e.target.value
            }))}
            inputProps={{
              value: propiedades.modelo.toUpperCase(),
            }}
            helperText={modelError.modelo}
            error={Boolean(modelError.modelo)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="tipoSelect">Presentación del Fármaco</InputLabel>
            <Select
              labelId="tipoSelect"
              
              fullWidth
              label="Tipo"
              onChange={(e) => setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                tipoM: e.target.value,
              }))}
              inputProps={{
                value: propiedades.tipoM,
              }}
            >
              <MenuItem value={"Cassette"}>Cassette</MenuItem>
              <MenuItem value={"Tira"}>Tira</MenuItem>
              <MenuItem value={"Tubo"}>Tubo</MenuItem>
              <MenuItem value={"Pieza"}>Pieza</MenuItem>
              <MenuItem value={"Caja"}>Caja</MenuItem>
              <MenuItem value={"Porron"}>Porrón</MenuItem>
              <MenuItem value={"Reactivo"}>Reactivó</MenuItem>
              <MenuItem value={"Mililitros"}>Mililitros</MenuItem>
              <MenuItem value={"Gotero"}>Gotero</MenuItem>
              <MenuItem value={"Microlitros"}>Microlitros</MenuItem>
              <MenuItem value={"Solucion"}>Solución</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <InputRegex
            style={{ height: "50px" }}
            label="Cantidad Presentación"
            variant="standard"
            fullWidth
            typeInput="number"
            value={propiedades?.medida}
            error={Boolean(modelError?.medida)}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                medida: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={12} md={4} >
          <InputRegex
            style={{ height: '50px' }}
            label="Ordenar Pedido"
            variant="standard"
            fullWidth
            typeInput="number"
            value={propiedades.orden.toString()}
            onChange={(e) => setPropiedades(prevState => ({
              ...prevState,
              ...prevState.propiedades,
              orden: e.target.value
            }))}
            helperText={modelError.orden}
            error={Boolean(modelError.orden)}
          />
        </Grid>

        <Grid item xs={12} sm={9} md={4} xl={4}>
          <InputRegex
            style={{ height: '50px' }}
            label="Porcentaje"
            variant="standard"
            disabled={true}
            fullWidth
            typeInput="number"
            value={propiedades?.porcentaje}
          // onChange={(e) => {
          //   setPropiedades(prevState => ({
          //     ...prevState,
          //     ...prevState.propiedades,
          //     porcentaje: e.target.value,
          //     precioPublico: Math.round((propiedades.precioProveedor * (1 + (e.target.value / 100))) * Math.pow(10, 2)) / Math.pow(10, 2),
          //     precioUnidad: Math.round(((Math.round((propiedades.precioProveedor * (1 + (e.target.value / 100))) * Math.pow(10, 2)) / Math.pow(10, 2)) / propiedades.cantidadUnidad) * Math.pow(10, 2)) / Math.pow(10, 2),
          //   }))
          // }}
          />
        </Grid>

        <Grid item xs={12} md={4} >
          <InputRegex
            style={{ height: '50px' }}
            label="Precio Proveedor"
            variant="standard"
            fullWidth
            typeInput="currency"
            value={propiedades?.precioProveedor?.toString() === "0.00" ? "" : propiedades?.precioProveedor?.toString()}
            onChange={(e) => {
              setPropiedades(prevState => ({
                ...prevState,
                ...prevState.propiedades,
                precioProveedor: e.target.value,
                // precioPublico: Math.round((e.target.value * (1 + (propiedades.porcentaje / 100))) * Math.pow(10, 2)) / Math.pow(10, 2),
                // precioUnidad: Math.round(((Math.round((e.target.value * (1 + (propiedades.porcentaje / 100))) * Math.pow(10, 2)) / Math.pow(10, 2)) / propiedades.cantidadUnidad) * Math.pow(10, 2)) / Math.pow(10, 2),
              }))
            }}
          />
        </Grid>

        {/* {propiedades.precioProveedor !== "" && ( */}
        {false == true && (
          <>
            <Grid item xs={12} md={4} >
              <InputRegex
                style={{ height: '50px' }}
                label="Precio Publico"
                variant="standard"
                disabled={true}
                fullWidth
                typeInput="currency"
                value={propiedades?.precioPublico?.toString()}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputRegex
                style={{ height: "50px" }}
                label="Cantidad Por Unidad"
                variant="standard"
                fullWidth
                typeInput="number"
                value={propiedades?.unidad || ""}
              // onChange={(e) => {
              //   if (e.target.value === "") {
              //     setPropiedades((prevState) => ({
              //       ...prevState,
              //       ...prevState.propiedades,
              //       precioUnidad: ""
              //     }))
              //   }
              //   setPropiedades((prevState) => ({
              //     ...prevState,
              //     ...prevState.propiedades,
              //     cantidadUnidad: e.target.value,
              //     precioUnidad: Math.round((propiedades.precioPublico / e.target.value) * Math.pow(10, 2)) / Math.pow(10, 2),
              //   }))
              // }}
              />
            </Grid>

            <Grid item xs={12} md={4} >
              <InputRegex
                style={{ height: '50px' }}
                label="Precio Unidad"
                variant="standard"
                disabled={true}
                fullWidth
                typeInput="currency"
                // value={propiedades?.precioUnidad?.toString()}
                value={0}

              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={12} >
          <TextField
            label="Descripción"
            variant="standard"
            fullWidth
            size="medium"
            onChange={(e) => setPropiedades(prevState => ({
              ...prevState,
              ...prevState.propiedades,
              descripcion: e.target.value
            }))
            }
            inputProps={{
              value: propiedades.descripcion,
            }}
            helperText={modelError.descripcion}
            error={Boolean(modelError.descripcion)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            multiline
            rows={5}
            label="Comentario"
            fullWidth
            helperText={modelError?.comentario}
            error={Boolean(modelError?.comentario)}
            size="medium"
            onChange={(e) => setPropiedades(prevState => ({
              ...prevState,
              ...prevState.propiedades,
              comentario: e.target.value
            }))}
            inputProps={{
              value: propiedades.comentario,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Tabs changePos={changePos} pos={pos} tabs={[
            {
              title: "Subir Imagen",
              icon: <FiUpload style={{ fontSize: 'x-large', marginLeft: '5px', marginBottom: '5px' }} />,
              content: <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={12} sm={12} md={7} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '30px' }}>
                    <CardMedia
                      component="img"
                      image={propiedades.urlFoto}
                      title=""
                      style={{ width: "305px", height: "305px", objectFit: 'cover', border: '7px solid whiteSmoke', borderRadius: '5px' }}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" >
                  <Grid item xs={12} sm={12} md={2}>
                    <div>
                      <input
                        hidden
                        accept="image/gif,image/jpeg,image/jpg,image/png"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        onChange={handleFileRead}
                      />
                      <label htmlFor="contained-button-file">
                        <Button style={{ color: 'white' }} variant="contained" color="primary" component="span" >
                          <PhotoCamera />
                        </Button>
                      </label>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            },
            {
              title: "Tomar Foto",
              icon: <BsCameraFill style={{ fontSize: 'x-large', marginLeft: '5px', marginBottom: '4px' }} />,
              content: <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
                  <ReactFileManager foto={foto} setFoto={setFoto} getVideo={getVideo} />
                </Grid>
              </div>
            }
          ]} />
        </Grid>
        <Grid item xs={12}>
          <strong>{"SUCURSALES"}</strong>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <TablePagination
            header={header}
            data={newObjSucursales}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "accion",
                name: "Acción",
                sortable: false,
                cell: (row, index) =>
                  <Switch
                    disabled={row && (row?.eliminado === null && row?.active === null) ? true : false}
                    checked={row?.active}
                    onChange={({ target }) => {
                      newObjSucursales[index].active = target.checked;
                      setNewObjSucursales([...newObjSucursales]);
                    }}
                  />,
              },
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading || updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["inventarioLaboratorios"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<SaveIcon />}
              style={{ color: "#fff" }}
            >
              {(!editing ? "GUARDAR" : "MODIFICAR")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Index;