/* eslint-disable eqeqeq */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect } from 'react'
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import {
    Button,
    Grid,
} from '@mui/material';
import { useAlert } from '../../../hooks/useAlert';
import Swal from 'sweetalert2';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BlockIcon from '@mui/icons-material/Block';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import InputRegex from "../../../components/InputRegex";
import Services from '../../../services/api';
import moment from 'moment';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////

const Step3 = ({
    nextStep,
    setProgress,
    ...props
}) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const { showAlert } = useAlert();
    const [agendas, setAgendas] = useLocalStorage("agendas", {});
    const [sig, setSig] = useState(false);
    const [nombre, setNombre] = useState("");
    const [apellidoP, setApellidoP] = useState("");
    const [apellidoM, setApellidoM] = useState("");
    const [correo, setCorreo] = useState("");
    const [telefono, setTelefono] = useState("");
    const [ine, setIne] = useState("");
    const [comentario, setComentario] = useState("");
    const [loading, setLoading] = useState(false);
    const [invalidCorreo, setInvalidCorreo] = useState(false);
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const getTimeFromMins = mins => {
        if (mins >= 24 * 60 || mins < 0) {
            throw new RangeError("Valid input should be greater than or equal to 0 and less than 1440.");
        }
        var h = mins / 60 | 0,
            m = mins % 60 | 0;
        return moment.utc().hours(h).minutes(m).format("hh:mm A");
    }
    const guardarCita = async () => {
        setLoading(true);
        const res = await Services.postWithOutToken("publico/guardar-cita",
            {
                idAgenda: agendas.idAgenda,
                ine: ine,
                genero: "Null",
                nombres: nombre,
                apellidoPaterno: apellidoP,
                apellidoMaterno: apellidoM,
                idSucursal: agendas.sucursal,
                fechaNacimiento: null,
                direccion: "faltante",
                estadoCivil: "faltante",
                tipoSangre: "faltante",
                nombreCompleto: nombre + " " + apellidoP + " " + apellidoM,
                correo: correo,
                telefono: telefono,
                urlFoto: "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Paciente%2Fpaciente_default.jpg?alt=media&token=4dfe89d7-0766-462e-a924-a4a67156277b",
                dia: agendas.horas.fecha,
                hora: agendas.horas.minInicio.toString(),
                fecha: agendas.horas.fechaFormat,
                status: "INICIADO",
                diaHora: getTimeFromMins(agendas.horas.minInicio) + " del dia " + agendas.horas.fecha
            }
        );
        if (res.cuerpo.detalle.estatus == 400) {
            Object.keys(res.cuerpo.errores).forEach(err => {
                showAlert({
                    message: res.cuerpo.errores[err],
                    severity: "error"
                });
            });
        } else if (res.cuerpo.detalle.estatus == 200) {
            const resp = await Swal.fire({
                title: "Cita agregada correctamente",
                text: "Desea agregar otra cita?",
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si",
                cancelButtonText: "No",
            });
            if (resp.isConfirmed) {
                setAgendas({ ...agendas, horas: null });
                nextStep(0);
            } else {
                window.localStorage.clear();
                nextStep("gracias");
            }
        }
        setLoading(false);
    }
    ////////////////////////////////////////// //////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        let progress = 0;
        if (correo != "" && correo != undefined) {
            if (correo.match(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/g)) {
                progress += 17;
                setInvalidCorreo(false);
            } else {
                setInvalidCorreo(true);
            }
        } else {
            setInvalidCorreo(false);
        }

        if (ine) {
            progress += 16;
        }
        if (nombre) {
            progress += 17;
        }
        if (apellidoP) {
            progress += 17;
        }
        if (apellidoM) {
            progress += 17;
        }
        if (telefono) {
            progress += 16;
        }

        if (progress == 100) {
            setSig(true);
        } else {
            setSig(false);
        }
        setProgress(progress, 2);
    }, [correo, nombre, apellidoM, apellidoP, telefono, comentario, ine, setProgress]);

    useEffect(() => {
        if (Object.keys(agendas).length > 0) {
            setCorreo(agendas.correo);
            setNombre(agendas.nombre);
            setApellidoM(agendas.apellidoM);
            setApellidoP(agendas.apellidoP);
            setTelefono(agendas.telefono);
            setComentario(agendas.comentario);
            setIne(agendas.ine);
        }
    }, [agendas]);

    return (
        <>
            <Grid
                container
                spacing={3}
            >
                <Grid xs={12} md={3}>

                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputRegex
                                sx={{ margin: '5px 0' }}
                                label={"INE"}
                                fullWidth
                                typeInput={"number"}
                                value={ine}
                                maxLength={13}
                                onChange={e => {
                                    setIne(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputRegex
                                sx={{ margin: '5px 0' }}
                                label={"Nombre"}
                                fullWidth
                                typeInput={"nombre"}
                                value={nombre}
                                onChange={e => {
                                    setNombre(e.target.value.toUpperCase());
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputRegex
                                sx={{ margin: '5px 0' }}
                                label={"Apellido Paterno"}
                                fullWidth
                                typeInput={"nombre"}
                                value={apellidoP}
                                onChange={e => {
                                    setApellidoP(e.target.value.toUpperCase());
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputRegex
                                sx={{ margin: '5px 0' }}
                                label={"Apellido Materno"}
                                fullWidth
                                typeInput={"nombre"}
                                value={apellidoM}
                                onChange={e => {
                                    setApellidoM(e.target.value.toUpperCase());
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputRegex
                                sx={{ margin: '5px 0' }}
                                label={"Telefono"}
                                maxLength={10}
                                fullWidth
                                typeInput={"phone"}
                                value={telefono}
                                onChange={e => {
                                    setTelefono(e.target.value.replaceAll(/[-() ]/g, ""));
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputRegex
                                sx={{ margin: '5px 0' }}
                                label={"Correo"}
                                fullWidth
                                helperText={invalidCorreo ? "correo no valido" : ""}
                                error={invalidCorreo}
                                typeInput={"text"}
                                value={correo}
                                onChange={e => {
                                    setCorreo(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputRegex
                                sx={{ margin: '5px 0' }}
                                label={"Comentario"}
                                fullWidth
                                typeInput={"text"}
                                value={comentario}
                                onChange={e => {
                                    setComentario(e.target.value);
                                }}
                            />
                        </Grid>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', margin: '10px 0' }}>
                            <Button
                                sx={{ margin: '0 10px' }}
                                onClick={e => {
                                    nextStep(1);
                                    setAgendas({ ...agendas });
                                }}
                                style={{ background: '#155480', color: '#FFF', width: '48%' }}>
                                <NavigateBeforeIcon style={{ margin: '0 10px 1px 0' }} /> Atras
                            </Button>
                            <Button
                                onClick={e => {
                                    setAgendas({ ...agendas, nombre, apellidoM, apellidoP, telefono, correo, comentario, ine });
                                    guardarCita();
                                }}
                                disabled={loading ? true : !sig} style={{ background: !sig ? '#FFF' : '#155480', color: sig ? '#FFF' : '#155480', width: '48%', transition: 'background 1s', border: sig ? 'none' : '2px solid #155480' }}>
                                {sig ? <>Agendar Cita<NavigateNextIcon style={{ margin: '0 0 1px 10px' }} /></> : <>Agendar Cita<BlockIcon style={{ margin: '0 0 1px 10px' }} /></>}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                <Grid xs={12} md={3}>

                </Grid>
            </Grid>
        </>
    );
}
export default Step3;