import React, { useState } from "react"
import { Button, Checkbox, TextField } from "@mui/material"
import Service from "../../../services/api"
import { useAlert } from "../../../hooks/useAlert"

const EncuestaZobele = () => {

    const logoClinica = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Encuesta%2Fclinica.png?alt=media&token=6723f483-eb2c-428a-99a1-131ab8391d77&_gl=1*um98cr*_ga*MTU4OTAxOTUxMC4xNjc3NjEyNjk3*_ga_CW55HF8NVT*MTY4NTgyNDgyNi4xOS4xLjE2ODU4MjQ5MzEuMC4wLjA."

    const cruzes = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Encuesta%2FCRUCES.png?alt=media&token=a07ad386-fa2e-4cd7-a3e0-3976f3bb5465&_gl=1*1v6wzk*_ga*MTU4OTAxOTUxMC4xNjc3NjEyNjk3*_ga_CW55HF8NVT*MTY4NTgyNDgyNi4xOS4xLjE2ODU4MjYyMzcuMC4wLjA."

    const icono1 = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Encuesta%2FVector.png?alt=media&token=a3b3be1f-0c1b-400a-882a-19c27aced621&_gl=1*12kymee*_ga*MTU4OTAxOTUxMC4xNjc3NjEyNjk3*_ga_CW55HF8NVT*MTY4NTgyNDgyNi4xOS4xLjE2ODU4MjU0NDcuMC4wLjA."

    const icono2 = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Encuesta%2FVectordone.png?alt=media&token=20d1cf51-0bfb-490e-984e-8dcecddab665&_gl=1*11tn6rm*_ga*MTU4OTAxOTUxMC4xNjc3NjEyNjk3*_ga_CW55HF8NVT*MTY4NTgzMjgxNy4yMS4xLjE2ODU4MzI4NDYuMC4wLjA."

    const [preguntas, setPreguntas] = useState([
        {
            pregunta: "Turno en que labora",
            respuesta: "",
            contestada: false,
            tipoRes: "check",
            respuestas: [
                {
                    nombre: "Matutino",
                    valor: false
                },
                {
                    nombre: "Vespertino",
                    valor: false
                },
                {
                    nombre: "Nocturno",
                    valor: false
                },
            ]
        },
        {
            pregunta: "Atención recibida en Estación 1 \"Registro\"",
            respuesta: "",
            contestada: false,
            tipoRes: "check",
            respuestas: [
                {
                    nombre: "Mala",
                    valor: false
                },
                {
                    nombre: "Regular",
                    valor: false
                },
                {
                    nombre: "Buena",
                    valor: false
                },
            ],
            comentario: "",
        },
        {
            pregunta: "Atención recibida en Estación 2 \"Espirometría\"",
            respuesta: "",
            contestada: false,
            tipoRes: "check",
            respuestas: [
                {
                    nombre: "Mala",
                    valor: false
                },
                {
                    nombre: "Regular",
                    valor: false
                },
                {
                    nombre: "Buena",
                    valor: false
                },
            ],
            comentario: "",
        },
        {
            pregunta: "Atención recibida en Estación 3 \"RX\"",
            respuesta: "",
            contestada: false,
            tipoRes: "check",
            respuestas: [
                {
                    nombre: "Mala",
                    valor: false
                },
                {
                    nombre: "Regular",
                    valor: false
                },
                {
                    nombre: "Buena",
                    valor: false
                },
            ],
            comentario: "",
        },
        {
            pregunta: "Atención recibida en Estación 4 \"Consulta Médica\"",
            respuesta: "",
            contestada: false,
            tipoRes: "check",
            respuestas: [
                {
                    nombre: "Mala",
                    valor: false
                },
                {
                    nombre: "Regular",
                    valor: false
                },
                {
                    nombre: "Buena",
                    valor: false
                },
            ],
            comentario: "",
        },
        {
            pregunta: "Atención recibida en Estación 5 \"Audiometría\"",
            respuesta: "",
            contestada: false,
            tipoRes: "check",
            respuestas: [
                {
                    nombre: "Mala",
                    valor: false
                },
                {
                    nombre: "Regular",
                    valor: false
                },
                {
                    nombre: "Buena",
                    valor: false
                },
            ],
            comentario: "",
        },
        {
            pregunta: "Cual es tu opinión referente a la presentación de personal médico y staff de Clínica Nueva Vida",
            respuesta: "",
            contestada: false,
            tipoRes: "check",
            respuestas: [
                {
                    nombre: "Mal (Sin uniforme, falta de aseo personal)",
                    valor: false
                },
                {
                    nombre: "Bien (Con uniforme)",
                    valor: false
                },
                {
                    nombre: "Excelente (Uniforme, gafete, bien presentados)",
                    valor: false
                },
                {
                    nombre: "Indiferente",
                    valor: false
                },
            ],
            comentario: "",
        },
        {
            pregunta: "Apoyo de equipo de Staff de Clínica Nueva Vida durante la realización de exámenes períodicos",
            respuesta: "",
            contestada: false,
            tipoRes: "check",
            respuestas: [
                {
                    nombre: "Indiferente en el trato recibido",
                    valor: false
                },
                {
                    nombre: "Atención cálida y humana",
                    valor: false
                },
                {
                    nombre: "Orientación durante el proceso de los exámenes periódicos",
                    valor: false
                },
                {
                    nombre: "Mala atención",
                    valor: false
                },
            ],
            comentario: "",
        },
        {
            pregunta: "Nos compartes algún comentario que te gustaría sea tomado en cuenta para mejorar nuestro servicio o bien para reforzar lo que estamos haciendo bien",
            respuesta: "",
            contestada: false,
            tipoRes: "input",
            comentario:"",
        },
    ]);

    const [iniciado, setIniciado] = useState(false);

    const { showAlert } = useAlert();

    const [finalizado, setFinalizado] = useState(false);

    const [preguntaActual, setPreguntaActual] = useState(0);

    const [loading, setLoading] = useState(false);

    const finalizar = async () => {
        setLoading(true);
        let res = await Service.postWithOutToken("publico/guardar-encuesta-zobele", { preguntas: JSON.stringify(preguntas) })
        if (res.cuerpo.detalle.estatus = 200) {
            showAlert({
                message: "Encuesta Finalizada",
                severity: "success"
            });
            setFinalizado(true);
        }

        setLoading(false);
    }


    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", height: "calc(100% - 103px)", alignItems: "center" }}>
            <div style={{ position: "relative", maxWidth: "400", minHeight: "850px", width: "100%", height: "100%", boxShadow: "black 0px 0px 10px -7px", padding: "70px 0" }}>
                {
                    iniciado
                        ?
                        <>
                            {
                                finalizado
                                    ?
                                    <>
                                        <div style={{ width: "100%", padding: "15px", marginTop: "200px" }}>
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <img src={icono2} style={{ marginBottom: "20px" }} />
                                                <p style={{
                                                    fontFamily: "Montserrat",
                                                    fontStyle: "normal",
                                                    fontWeight: "800",
                                                    fontSize: "30px",
                                                    lineHeight: "31px",
                                                    textAlign: "center",
                                                    color: "#01347C",
                                                    marginBottom: "20px"
                                                }}>¡Gracias por ayudarnos a mejorar!</p>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "20px" }}>
                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginBottom: "20px" }}>
                                            {
                                                preguntas.map((res, i) => {
                                                    return (
                                                        <div style={{ background: preguntas[i].contestada ? "#0078CF" : "#B8B8B8", height: "6px", width: "100%", margin: "5px", cursor: "pointer", borderRadius: "14px" }}
                                                            onClick={e => {
                                                                if ((preguntas[i].contestada && preguntas[preguntaActual].contestada) || (preguntas[i].contestada && i < preguntaActual)) {
                                                                    setPreguntaActual(i)
                                                                }
                                                            }}
                                                        ></div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <p style={{
                                            fontFamily: "Montserrat",
                                            fontStyle: "normal",
                                            fontWeight: "700",
                                            fontSize: "30px",
                                            lineHeight: "31px",
                                            textAlign: "center",
                                            color: "#01347C",
                                            marginBottom: "20px"
                                        }}>{preguntas[preguntaActual].pregunta}</p>

                                        <div style={{ width: "100%", display: "flex", flexDirection: "column", padding: "15px" }}>
                                            {
                                                preguntas[preguntaActual].tipoRes == "check"
                                                &&
                                                <>
                                                    {
                                                        preguntas[preguntaActual].respuestas.map((res, i) => {
                                                            return (
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                    <p style={{
                                                                        fontFamily: "Montserrat",
                                                                        fontStyle: "normal",
                                                                        fontWeight: "600",
                                                                        fontSize: "18px",
                                                                        lineHeight: "19px",
                                                                        color: "#000000",
                                                                    }}>{res.nombre}</p>
                                                                    <Checkbox checked={res.valor} onChange={e => {
                                                                        let copyPreguntas = [...preguntas]
                                                                        copyPreguntas[preguntaActual].respuestas[i].valor = e.target.checked;
                                                                        if (preguntas[preguntaActual].respuestas.some(res => res.valor == true)) {
                                                                            copyPreguntas[preguntaActual].contestada = true;
                                                                        } else {
                                                                            copyPreguntas[preguntaActual].contestada = false;
                                                                        }
                                                                        setPreguntas([...copyPreguntas])
                                                                    }} />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>
                                            }
                                            {
                                                preguntaActual > 0 &&
                                                <TextField
                                                    label={preguntaActual == 8 ? "Escribe tu comentario" : "Escribe tu comentario (opcional)"}
                                                    value={preguntas[preguntaActual].comentario}
                                                    onChange={e => {
                                                        let copyPreguntas = [...preguntas]
                                                        copyPreguntas[preguntaActual].comentario = e.target.value;
                                                        setPreguntas([...copyPreguntas])
                                                    }}
                                                />
                                            }
                                        </div>

                                        {
                                            preguntaActual < 8
                                                ?
                                                <Button style={{
                                                    fontFamily: "Montserrat",
                                                    background: preguntas[preguntaActual].contestada ? "#12A072" : "#B8B8B8",
                                                    color: "white",
                                                    padding: "15px 50px",
                                                    marginTop: "15px"
                                                }}
                                                    disabled={preguntas[preguntaActual].contestada ? loading ? true : false : true}
                                                    onClick={e => {
                                                        setPreguntaActual(preguntaActual + 1)
                                                    }}
                                                >Siguiente</Button>
                                                :
                                                <Button style={{
                                                    fontFamily: "Montserrat",
                                                    background: "#12A072",
                                                    color: "white",
                                                    padding: "15px 50px",
                                                    marginTop: "15px"
                                                }}
                                                    onClick={e => {
                                                        finalizar()
                                                    }}
                                                >Finalizar</Button>
                                        }
                                    </div>
                            }
                        </>
                        :
                        <>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "20px" }}>
                                <p style={{
                                    fontFamily: "Montserrat",
                                    fontStyle: "normal",
                                    fontWeight: "800",
                                    fontSize: "30px",
                                    lineHeight: "31px",
                                    textAlign: "center",
                                    color: "#01347C",
                                    marginBottom: "20px"
                                }}>Compártenos tu experiencia</p>
                                <p style={{
                                    fontFamily: "Montserrat",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "17px",
                                    textAlign: "center",
                                    color: "#3A3A3A",
                                    marginBottom: "20px"
                                }}>La presente encuesta es con el fin de conocer su opinión por la atención recibida durante los exámenes períodicos realizados por Clínica Nueva Vida.</p>
                                <img src={icono1} />
                                <Button style={{
                                    fontFamily: "Montserrat",
                                    background: "#12A072",
                                    color: "white",
                                    padding: "15px 50px",
                                    marginTop: "15px"
                                }}
                                    onClick={e => {
                                        setIniciado(true);
                                    }}
                                >Comenzar</Button>
                            </div>
                        </>
                }
                <div style={{ position: "absolute", left: "0", right: "0", bottom: "0", height: "50%", zIndex: "-1" }}>
                    <img style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                    }} src={cruzes} />
                </div>
            </div>
        </div >
    )

}

export default EncuestaZobele