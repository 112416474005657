/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef } from "react";
import { useHistory, NavLink } from "react-router-dom";
// <======================= COMPONENTES ==========================>
import {
  Grid, Paper, Typography, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, IconButton,
  Chip, Breadcrumbs, Switch, Fab, InputLabel, Select, MenuItem, FormControl, TextField, Stack, Avatar, Popover
} from '@mui/material';
import { baseUrl } from "../../utils/variables";
import moment from "moment-timezone";
import Modal from 'react-modal';
import { useAlert } from "./../../hooks/useAlert";
import Pagination from '@mui/material/Pagination';
import LoadingButton from '@mui/lab/LoadingButton';
import FullScreenLoader from "../../components/FullScreenLoader";
import { useGet } from "../../hooks/useGet";
import { useLocalStorage } from "./../../hooks/useLocalStorage";
import Swal from 'sweetalert2';
import { useAuth } from "../../hooks/useAuth";
import ReactFileManager from "../../components/ReactFileManager";
import { usePost } from "../../hooks/usePost";
// <========================= STYLES =============================>
import { baseStyles, breadCrumbsStyles, tableCellStyles, tableRowStyles, tablaPrimaryStyle } from "../../utils";
// <========================= ICONOS =============================>
import { Delete, Edit, Home } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import { MdPictureAsPdf } from 'react-icons/md';
import { RiFileExcel2Fill } from 'react-icons/ri';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import scanerGif from "../../assets/img/barcode-scan.gif";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { formatCurrency } from '../../utils/index';
import InventoryIcon from '@mui/icons-material/Inventory';
import { Search } from "@mui/icons-material";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";

const useStyles = baseStyles();
const StylesBreadCrumb = breadCrumbsStyles()(Chip);
const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const useTable = tablaPrimaryStyle();
let urlGet = "producto-inventario";
let Barcode = require('react-barcode');
Modal.setAppElement(document.getElementById("#modal"));
const Index = () => {
  // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
  // <=======================================STATES DEF ETC====================================================> //
  // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
  const classes = useStyles();
  const tablas = useTable();
  const [admin] = useLocalStorage("admin", null);
  const history = useHistory();
  const { permiso } = useAuth();
  const { showAlert } = useAlert();
  const [qr, setQr] = useState("");
  const [errores, cargandoEliminado, peticion, setCargando, detalles] = usePost();
  const [modalType, setModalType] = useState(null);
  const switchEliminado = useRef();
  const [tipoCargando, setTipoCargando] = useState(1);
  const [imgProductoModal, setImgProductoModal] = useState("");
  const [servSuc, setServSuc] = useState([]);
  const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
  const [sucursalesPu] = useLocalStorage("sucursales", "");
  const [campos, setCampos] = useState({ nombreProducto: "", serie: "", via: "", tipo: "", idSucursal: "", marca: "", modelo: "", eliminado: false });

  const [modalIsOpen, setIsOpen] = React.useState(false);

  let paramsGet = { limite: 10, pagina: 1, idSucursal: idSucursal, expand: 'lote,productoSucursal.sucursal,inventario' };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const [productos, cargando, actualizar] = useGet({
    initUrl: urlGet,
    params: paramsGet,
    action: true,
  });

  const [sucursales] = useGet({
    initUrl: 'sucursal',
    params: { pagina: 1, ordenar: "nombreSucursal.asc" },
    action: true,
  })

  // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
  // <=======================================FUNCIONES MEMO====================================================> //
  // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
  const isOdd = num => {
    return (num & 1) ? true : false
  }

  const buscarProductos = (eliminado = null, tipo = null, via = null, idSucursal = null) => {
    let obj = { ...paramsGet }
    if (campos.nombreProducto) obj = { ...obj, nombreProducto: campos.nombreProducto }
    if (campos.serie) obj = { ...obj, serie: campos.serie }
    if (campos.tipo) obj = { ...obj, tipo: campos.tipo }
    if (campos.marca) obj = { ...obj, marca: campos.marca }
    if (campos.modelo) obj = { ...obj, modelo: campos.modelo }
    if (campos.via) obj = { ...obj, via: campos.via }

    if (tipo == "" && campos.via !== "") {
      obj = { ...obj, tipo: tipo, via: "" }
    } else if (tipo != null) {
      if (tipo !== "Medicamento" && campos.via !== "") {
        obj = { ...obj, tipo: tipo, via: "" }
      } else {
        obj = { ...obj, tipo: tipo }
      }
    } else {
      if (campos.tipo) {
        obj = { ...obj, tipo: campos.tipo }
      } else if (campos.tipo !== "Medicamento" && campos.via !== "") {
        obj = { ...obj, tipo: tipo, via: "" }
      }
    }

    if (tipo === "Medicamento" && via == "") {
      obj = { ...obj, tipo: "Medicamento", via: via }
    } else if (tipo === "Medicamento" && via != null) {
      obj = { ...obj, tipo: "Medicamento", via: via }
    } else {
      if (campos.tipo === "Medicamento" && campos.via) {
        obj = { ...obj, tipo: "Medicamento", via: campos.via }
      }
    }

    if (eliminado != null) {
      if (eliminado) obj = { ...obj, eliminado: eliminado }
    } else {
      if (campos.eliminado) obj = { ...obj, eliminado: campos.eliminado }
    }

    if (idSucursal != null) {
      if (idSucursal) obj = { ...obj, idSucursal: idSucursal }
    } else {
      if (campos.idSucursal) obj = { ...obj, idSucursal: campos.idSucursal }
    }

    setTipoCargando(2);
    actualizar({
      urlUpdate: urlGet,
      properties: obj,
      loading: true,
    });
  }

  const cambiarPagina = (event, newPage) => {
    let obj = { ...paramsGet }
    if (campos.nombreProducto) obj = { ...obj, nombreProducto: campos.nombreProducto }
    if (campos.serie) obj = { ...obj, serie: campos.serie }
    if (campos.tipo) obj = { ...obj, tipo: campos.tipo }
    if (campos.marca) obj = { ...obj, marca: campos.marca }
    if (campos.modelo) obj = { ...obj, modelo: campos.modelo }
    if (campos.via) obj = { ...obj, via: campos.via }
    if (campos.eliminado) obj = { ...obj, eliminado: campos.eliminado }
    if (campos.idSucursal) obj = { ...obj, idSucursal: campos.idSucursal }
    if (newPage) obj.pagina = newPage
    setTipoCargando(2);
    actualizar({
      urlUpdate: urlGet,
      properties: obj,
      loading: true,
    });
  };

  const deshabilitarProducto = async idProductoInventario => {
    if (idProductoInventario) {
      const res = await Swal.fire({
        title: '¿Deseas deshabilitar este Producto?',
        text: "Favor de corroborar sus datos antes de continuar.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Deshabilitar!',
        cancelButtonText: 'Cancelar'
      });

      if (res.isConfirmed) {
        showAlert({
          message: "Espera un momento.....",
          severity: "warning",
        });
        peticion("producto-inventario/eliminar", { idProductoInventario: idProductoInventario }, "productos", true, false, "DELETE");

      }
    }
  }

  const habilitarProducto = async idProductoInventario => {
    if (idProductoInventario) {
      const res = await Swal.fire({
        title: '¿Deseas habilitar este Producto?',
        text: "Favor de corroborar sus datos antes de continuar.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Habilitar!',
        cancelButtonText: 'Cancelar'
      });

      if (res.isConfirmed) {
        showAlert({
          message: "Espera un momento.....",
          severity: "warning",
        });
        peticion("producto-inventario/habilitar", { idProductoInventario: idProductoInventario }, "productos", true, false, "DELETE");

      }
    }
  }

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }
  const getFilterArraySucursales = array => {
    return array.map((e) => {
      return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, active: false }
    })
  }

  const onCloseBarcode = () => {
    closeModal();
    buscarProductos();
  }

  // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
  // <=======================================EFFECTS CALLBACKS=================================================> //
  // /////////////////////////////////////////////////////////////////////////////////////////////////////////// //

  useEffect(() => {
    if (detalles?.estatus === 200) {
      setTipoCargando(2);
      buscarProductos();
    }
  }, [detalles]);

  useEffect(() => {
    const newCampos = campos;
    newCampos.serie = qr;
    setCampos({ ...campos, ...newCampos });
  }, [qr]);

  useEffect(() => {
    if (sucursales.resultado) {
      const newObj = getFilterArraySucursales(sucursales.resultado);
      setServSuc(newObj);
    }
  }, [sucursales.resultado])

  if (cargando && tipoCargando === 1) return <FullScreenLoader />;
  return (
    <>
      <div className={classes.root}>
        <div id="modal" style={{ zIndex: '99' }}>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            {(modalType == "img") && (
              <img style={{ maxHeight: '600px', minHeight: '600px', width: '100%', height: '100%' }} src={imgProductoModal} alt="imgProductoModal"></img>
            )}
            {(modalType == "barcode") && (
              <ReactFileManager typeFile={"barcode"} setBarcode={setQr} barcode={qr} onCloseBarcode={onCloseBarcode} />
            )}
            {(modalType == "qr") && (
              <>
                <input style={{ opacity: "0", position: "absolute", cursor: "default" }} autoFocus={true} onBlur={({ target }) => { target.focus() }}
                  onChange={
                    (e) => {
                      setQr(e.target.value.replaceAll("'", "-"));
                    }
                  }
                  onKeyPress={(event) => {
                    if (event.which == 13 || event.keyCode == 13) {
                      closeModal();
                      event.target.value = ""
                      buscarProductos();
                      return false;
                    }
                    return true;
                  }}
                />
                <img src={scanerGif} alt="Escanea codigo de barras" style={{ width: '300px', height: '300px' }} />
                <p style={{ zIndex: 999, textAlign: 'center', color: 'rgba(100,100,100,1)', fontSize: '20px' }}>{campos.serie.replaceAll("'", "-")}</p>
              </>
            )}
          </Modal>
        </div>
        <Typography variant="h4" className={classes.typographyModified} gutterBottom >
          PRODUCTOS
        </Typography>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Breadcrumbs aria-label="breadcrumb">
                <StylesBreadCrumb
                  component="a"
                  label="Inicio"
                  icon={<Home fontSize="small" />}
                  onClick={() => history.push("/")}
                />
                <StylesBreadCrumb
                  component="a"
                  label="Productos"
                  icon={<InventoryIcon fontSize="small" />}
                  onClick={() => history.push("/productos")}
                />
              </Breadcrumbs>
            </Grid>

            {(admin === true || (permiso && permiso[0]["inventarios"] & 2) === 2) ?
              <Grid item xs={4}>
                <Grid container justifyContent="flex-end">
                  <NavLink to="/producto/nuevo"  >
                    <Tooltip title="Agregar Producto" aria-label="Agregar Producto" className={classes.successButton}>
                      <Fab color="primary" style={{ zIndex: '0' }} className={classes.fab} >
                        <AddIcon style={{ color: "#fff" }} />
                      </Fab>
                    </Tooltip>
                  </NavLink>
                </Grid>
              </Grid>
              :
              <Grid item xs={4}></Grid>
            }

            <Grid item xs={12} sm={12} md={4} xl={4}>
              <TextField
                fullWidth
                label="Nombre Producto"
                variant="standard"
                value={campos.nombreProducto}
                onChange={(e) => setCampos(prevState => ({
                  ...prevState,
                  ...prevState.campos,
                  nombreProducto: e.target.value.toUpperCase()
                }))}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} xl={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="tipoSelect">Seleccionar Tipo</InputLabel>
                <Select
                  labelId="tipoSelect"
                  fullWidth
                  label="Tipo"
                  value={campos.tipo.toUpperCase()}
                  onChange={(e) => {
                    setCampos(prevState => ({
                      ...prevState,
                      ...prevState.campos,
                      tipo: e.target.value.toUpperCase(),
                    }));
                    buscarProductos(null, e.target.value);
                  }}
                  defaultValue={"MEDICAMENTO"}
                >
                  <MenuItem value="">TODOS</MenuItem>
                  <MenuItem value={"MEDICAMENTO"}>MEDICAMENTO</MenuItem>
                  <MenuItem value={"EQUIPO"}>EQUIPO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {qr != "" ?
              <Grid item xs={12} sm={12} md={4} xl={4} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <IconButton style={{ height: 'fit-content', margin: '5px' }} onClick={() => {
                  setModalType("qr")
                  openModal();
                }}>
                  <DocumentScannerIcon />
                </IconButton>
                <IconButton style={{ height: 'fit-content', margin: '5px' }} onClick={() => {
                  setModalType("barcode")
                  openModal();
                }}>
                  <CameraAltIcon />
                </IconButton>
                <Barcode height={30} width={1.2} fontSize={13} margin={0} marginTop={20} value={qr}
                  onClick={() => {
                    setModalType("qr")
                    openModal();
                  }}
                />
                <IconButton style={{ height: 'fit-content', margin: '5px' }}
                  onClick={
                    () => {
                      setQr("");
                      const newCampos = campos;
                      newCampos.serie = "";
                      setCampos({ ...campos, ...newCampos });
                      buscarProductos();
                    }
                  }
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              :
              <Grid item xs={12} sm={12} md={3} xl={3} style={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton style={{ height: 'fit-content', margin: '5px' }} onClick={() => {
                  setModalType("qr")
                  openModal();
                }}>
                  <DocumentScannerIcon />
                </IconButton>
                <IconButton style={{ height: 'fit-content', margin: '5px' }} onClick={() => {
                  setModalType("barcode")
                  openModal();
                }}>
                  <CameraAltIcon />
                </IconButton>
              </Grid>
            }
            {campos.tipo === "EQUIPO" && (
              <>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <TextField
                    fullWidth
                    label="Marca"
                    variant="standard"
                    value={campos.marca.toUpperCase()}
                    onChange={(e) => setCampos(prevState => ({
                      ...prevState,
                      ...prevState.campos,
                      marca: e.target.value.toUpperCase()
                    }))}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <TextField
                    fullWidth
                    label="Modelo"
                    variant="standard"
                    value={campos.modelo.toUpperCase()}
                    onChange={(e) => setCampos(prevState => ({
                      ...prevState,
                      ...prevState.campos,
                      modelo: e.target.value.toUpperCase()
                    }))}
                  />
                </Grid>
              </>
            )}
            {campos.tipo === 'MEDICAMENTO' && (
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="demo-simple-select-autowidth-label">Via de administración</InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    label="Via Administracion"
                    value={campos.via}
                    onChange={(e) => {
                      setCampos(prevState => ({
                        ...prevState,
                        ...prevState.campos,
                        via: e.target.value
                      }));
                      buscarProductos(null, "Medicamento", e.target.value);
                    }}
                  >
                    <MenuItem value="">TODAS</MenuItem>
                    <MenuItem value="Intravenoso">INTRAVENOSO</MenuItem>
                    <MenuItem value="Intramuscular">INTRAMUSCULAR</MenuItem>
                    <MenuItem value="Nasal">NASAL</MenuItem>
                    <MenuItem value="Oftálmico">OFTÁLMICO</MenuItem>
                    <MenuItem value="Optica">OPTICA</MenuItem>
                    <MenuItem value="Rectal">RECTAL</MenuItem>
                    <MenuItem value="Subcutáneo">SUBCUTÁNEO</MenuItem>
                    <MenuItem value="Tópica">TÓPICA</MenuItem>
                    <MenuItem value="Vía oral">VÍA ORAL</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            {(admin === true) && (
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="demo-simple-select-autowidth-label">Sucursal</InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    label="Sucursales"
                    value={idSucursal}
                    onChange={(e) => {
                      setIdSucursal(e.target.value)
                    }}
                  >
                    <MenuItem value="">TODAS</MenuItem>
                    {servSuc && servSuc.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.idSucursal} >{item.nombreSucursal.toUpperCase()}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'end' }}>
              <LoadingButton
                loading={(cargando && tipoCargando === 3) && true}
                variant="contained"
                onClick={() => buscarProductos()}
                size="large"
                style={{
                  color: "#fff"
                }}
                startIcon={<Search />}
              >

                BUSCAR
              </LoadingButton>
            </Grid>

          </Grid>
        </Paper>
        <Paper className={classes.paper} style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'start', padding: '20px 20px' }}>
          <Grid item xs={8} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Switch
              ref={switchEliminado}
              disabled={(cargando && tipoCargando === 2) && true}
              onChange={(e) => {
                setCampos(prevState => ({
                  ...prevState,
                  ...prevState.campos,
                  eliminado: e.target.checked
                }));
                buscarProductos(e.target.checked);
              }
              }
            />
            Ver Deshabilitados
          </Grid>
          <Grid item xs={4} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Grid item xs={2}>
              <IconButton color="error" aria-label="" component="span">
                <a className={classes.btnPdf} target="_blank" rel="noreferrer" href={
                  `${baseUrl}pdf/inventario-producto?
                  ${"admin=" + (admin ? 1 : 0)}
                  ${"&idSucursal=" + idSucursal}
                  ${"&nombreProducto=" + campos.nombreProducto}
                  ${"&serie=" + campos.serie}
                  ${"&tipo=" + (campos.tipo === "TODOS" ? "" : campos.tipo)}
                  ${"&marca=" + campos.marca}
                  ${"&modelo=" + campos.modelo}
                  ${"&eliminado=" + (campos.eliminado ? 1 : 0)}`
                }>
                  <MdPictureAsPdf />
                </a>
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <IconButton color="warning" aria-label="" component="span">
                <a className={classes.btnExcel} target="_blank" rel="noreferrer" href={
                  `${baseUrl}excel/inventario-producto?
                  ${"admin=" + (admin ? 1 : 0)}
                  ${"&idSucursal=" + idSucursal}
                  ${"&nombreProducto=" + campos.nombreProducto}
                  ${"&serie=" + campos.serie}
                  ${"&tipo=" + (campos.tipo === "TODOS" ? "" : campos.tipo)}
                  ${"&marca=" + campos.marca}
                  ${"&modelo=" + campos.modelo}
                  ${"&eliminado=" + (campos.eliminado ? 1 : 0)}`
                }>
                  <RiFileExcel2Fill />
                </a>
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
        {cargando && tipoCargando === 2 ? (
          <div className={classes.loadingTable}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Paper className={classes.paperTable} style={{ borderRadius: '1px' }}>
              <TableContainer className={classes.container} style={{ borderRadius: '1px' }}>
                <Table stickyHeader aria-label="sticky table" className={tablas.TablePrimary}>
                  <TableHead>
                    <TableRowModify>
                      <TableCellModify style={{ fontWeight: '600', textAlign: 'center' }}>#</TableCellModify>
                      <TableCellModify style={{ fontWeight: '600', textAlign: 'center' }}>TIPO</TableCellModify>
                      <TableCellModify style={{ fontWeight: '600', textAlign: 'center' }}>SERIE</TableCellModify>
                      <TableCellModify style={{ fontWeight: '600', textAlign: 'center' }}>NOMBRE PRODUCTO</TableCellModify>
                      <TableCellModify style={{ fontWeight: '600', textAlign: 'center' }}>PRECIO</TableCellModify>
                      <TableCellModify style={{ fontWeight: '600', textAlign: 'center' }}>TOTAL</TableCellModify>
                      {/* MEDICAMENTO */}
                      {(campos.tipo == "MEDICAMENTO") && (
                        <>
                          <TableCellModify style={{ fontWeight: '600', textAlign: 'center' }}>MEDIDA</TableCellModify>
                          <TableCellModify style={{ fontWeight: '600', textAlign: 'center' }}>DOSIS</TableCellModify>
                          <TableCellModify style={{ fontWeight: '600', textAlign: 'center' }}>VÍA DE ADMINISTRACIÓN</TableCellModify>
                        </>
                      )}
                      {/* Equipo */}
                      {(campos.tipo == "EQUIPO") && (
                        <>
                          <TableCellModify style={{ fontWeight: '600', textAlign: 'center' }}>MARCA</TableCellModify>
                          <TableCellModify style={{ fontWeight: '600', textAlign: 'center' }}>MODELO</TableCellModify>
                        </>
                      )}

                      <TableCellModify style={{ fontWeight: '600' }}>IMAGEN</TableCellModify>
                      <TableCellModify style={{ fontWeight: '600', textAlign: 'center' }}>ACCIONES</TableCellModify>
                    </TableRowModify>
                  </TableHead>
                  <TableBody>
                    {productos &&
                      productos.resultado.map((producto, index) => {
                        let sucursalesFiltradas = [];
                        if (!admin) {
                          producto.productoSucursal.map(sucursal => {
                            if (sucursalesPu.some(sucursalPu => sucursalPu.idSucursal == sucursal.idSucursal)) {
                              sucursalesFiltradas.push(sucursal);
                            }
                          });
                        } else {
                          producto.productoSucursal.map(sucursal => {
                            sucursalesFiltradas.push(sucursal);
                          });
                        }
                        let colorRestock = {
                          fontWeight: '600',
                          textAlign: 'center'
                        };
                        return (
                          <TableRowModify key={producto.idProductoInventario}>
                            <TableCellModify style={{ textAlign: 'center' }}>{index + 1}</TableCellModify>

                            <TableCellModify style={{ textAlign: 'center' }}>{producto.tipo.toUpperCase()}</TableCellModify>
                            <TableCellModify style={{ textAlign: 'center' }}>
                              <Barcode background={isOdd((index + 1)) ? "#F5F5F5" : "#FFF"} height={30} width={1.2} fontSize={13} margin={0} marginTop={20} value={producto.serie} />
                            </TableCellModify>
                            <TableCellModify style={{ textAlign: 'center' }}>{producto.nombreProducto.toUpperCase()}</TableCellModify>
                            <TableCellModify style={{ textAlign: 'center' }}>
                              {producto.precio}
                            </TableCellModify>
                            <TableCellModify style={colorRestock}>
                              {producto.inventario}
                            </TableCellModify>
                            {/* MEDICAMENTO */}
                            {(campos.tipo == "MEDICAMENTO") && (
                              <>
                                <TableCellModify style={{ textAlign: 'center' }}>{producto.medida} {producto.tipoM}</TableCellModify>
                                <TableCellModify style={{ textAlign: 'center' }}>{producto.dosis} {producto.tipoDosis.toUpperCase()}</TableCellModify>
                                <TableCellModify style={{ textAlign: 'center' }}>{producto.via.toUpperCase()}</TableCellModify>
                              </>
                            )}
                            {/* Equipo */}
                            {(campos.tipo == "EQUIPO") && (
                              <>
                                <TableCellModify style={{ textAlign: 'center' }}>{producto.marca.toUpperCase()}</TableCellModify>
                                <TableCellModify style={{ textAlign: 'center' }}>{producto.modelo.toUpperCase()}</TableCellModify>
                              </>
                            )}

                            <TableCellModify style={{ width: '100px', height: '100px' }}>
                              <Avatar style={{ cursor: 'pointer', width: '60%', height: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                                setImgProductoModal(producto.urlFoto);
                                setModalType("img")
                                openModal();
                              }} alt={producto.nombreProducto} src={producto.urlFoto} />
                            </TableCellModify>
                            <TableCellModify style={{ textAlign: 'center' }}>
                              {(admin === true || (permiso && permiso[0]["inventarios"] & 4) === 4) && ((producto.eliminado == null) && (
                                <NavLink exact to={(`producto/editar/${producto.idProductoInventario}`)}>
                                  <Tooltip title="Editar">
                                    <IconButton
                                      aria-label="editar"
                                      onClick={() => history.push(`producto/editar/${producto.idProductoInventario}`)}
                                    >
                                      <Edit />
                                    </IconButton>
                                  </Tooltip>
                                </NavLink>
                              ))}
                              {(admin === true || (permiso && permiso[0]["inventarios"] & 8) === 8) && (
                                <>
                                  {(producto.eliminado == null) ?
                                    <Tooltip title="Deshabilitar">
                                      <IconButton aria-label="eliminar" onClick={() => deshabilitarProducto(producto.idProductoInventario)} >
                                        <Delete />
                                      </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Habilitar">
                                      <IconButton aria-label="eliminar" onClick={() => habilitarProducto(producto.idProductoInventario)} >
                                        <RestoreFromTrashIcon />
                                      </IconButton>
                                    </Tooltip>
                                  }
                                </>
                              )}
                            </TableCellModify>
                          </TableRowModify>
                        );
                      })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <br></br>
              <div className={classes.paginationModified}>
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(productos.paginacion.total / productos.paginacion.limite)}
                    page={productos.paginacion.pagina}
                    onChange={cambiarPagina}
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
              </div>
              <br></br>
            </Paper>
          </>
        )}
      </div >
    </>
  );
}

export default Index;