/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useModels } from '../../../hooks/useModels';
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import Calendar from '../../../components/Calendar';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import {
    Button,
} from '@mui/material';
import moment from "moment";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BlockIcon from '@mui/icons-material/Block';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////

const Step2 = ({
    nextStep,
    setProgress,
    ...props
}) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const [agendas, setAgendas] = useLocalStorage("agendas", {});
    const [horas, setHoras] = useState(false);
    const [sig, setSig] = useState(false);
    const parametrosInicialesMemo = useMemo(
        () => ({
            name: 'publico/agenda',
            expand: "cita, agendaDiaInhabiles",
            extraParams: {
                idSucursal: agendas.sucursal,
                consultorio: agendas.consultorio.consultorio
            }
        }),
        [agendas]
    );
    const [
        models,
    ] = useModels({ ...parametrosInicialesMemo });
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const setCita = (_horas) => {
        setHoras(_horas);
    }
    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        let progress = 0;
        if (horas) {
            progress += 100;
        }
        if (progress == 100) {
            setSig(true);
        } else {
            setSig(false);
        }
        setProgress(progress, 1);
    }, [horas]);

    useEffect(() => {
        if (Object.keys(agendas).length > 0) {
            setHoras(agendas.horas);
        }
    }, [agendas]);


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>{
        }
            {
                models.length > 0 && (
                    <Calendar
                        fechas={[
                            moment(new Date()).format("YYYY/MM/DD"),
                            moment(new Date()).add(3, "M").format("YYYY/MM/DD"),
                        ]}
                        horas={{
                            semana1: { horaInicio: models[0]?.inicioHoraSemana1, horaFinal: models[0]?.finHoraSemana1 },
                            semana2: { horaInicio: models[0]?.inicioHoraSemana2, horaFinal: models[0]?.finHoraSemana2 },
                            sabado1: { horaInicio: models[0]?.inicioHoraSabado1, horaFinal: models[0]?.finHoraSabado1 },
                            sabado2: { horaInicio: models[0]?.inicioHoraSabado2, horaFinal: models[0]?.finHoraSabado2 },
                            domingo1: { horaInicio: models[0]?.inicioHoraDomingo1, horaFinal: models[0]?.finHoraDomingo1 },
                            domingo2: { horaInicio: models[0]?.inicioHoraDomingo2, horaFinal: models[0]?.finHoraDomingo2 },
                        }}
                        cita={models[0]?.cita.filter(c => c.status == "INICIADO")}
                        diasSemana={models[0]?.semana}
                        agendaDiaInhabiles={models[0]?.agendaDiaInhabiles}
                        setCita={setCita}
                        actual={horas}
                        division={models[0]?.division}
                    />
                )
            }
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', margin: '10px 0' }}>
                <Button
                    sx={{ margin: '0 10px' }}
                    onClick={e => {
                        nextStep(0);
                        setAgendas({ ...agendas, horas });
                    }}
                    style={{ background: '#155480', color: '#FFF', width: '48%' }}>
                    <NavigateBeforeIcon style={{ margin: '0 10px 1px 0' }} /> Atras
                </Button>
                <Button
                    sx={{ margin: '0 10px' }}
                    onClick={e => {
                        nextStep(2);
                        setAgendas({ ...agendas, horas, idAgenda: models[0].idAgenda });
                    }}
                    disabled={!sig} style={{ background: !sig ? '#FFF' : '#155480', color: sig ? '#FFF' : '#155480', width: '48%', transition: 'background 1s', border: sig ? 'none' : '2px solid #155480' }}>
                    {sig ? <>Siguiente<NavigateNextIcon style={{ margin: '0 0 1px 10px' }} /></> : <>Siguiente<BlockIcon style={{ margin: '0 0 1px 10px' }} /></>}
                </Button>
            </div>
        </div>
    );
}
export default Step2;