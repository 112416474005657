//React
import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/variables";
import { baseStyles, capitalizeFirst } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import { Grid, Menu, MenuItem, Switch, FormControlLabel, TextField, Avatar, Modal } from "@mui/material";
import { useModels } from "../../hooks/useModels";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
//----------------------------------------------------------------------------------------------------------------------

//Librerias
import Swal from "sweetalert2";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { AccessTimeFilled, Add, Delete, Edit, Search, StoreMallDirectory } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { NavLink } from "react-router-dom";
//----------------------------------------------------------------------------------------------------------------------

const useStyles = baseStyles();

const modalStyle = {
  position: 'absolute',
  top: '50%',
  right: 'auto',
  left: '50%',
  bottom: 'auto',
  transform: 'translate(-50%, -50%)',
  marginRight: '-50%',
  background: '#FFF',
  outline: 'none',
  padding: '15px 0 0 0',
  borderRadius: '5px',
  boxShadow: '0 0 15px black'
};

const Index = () => {
  //Estados Iniciales
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [pagina, setPagina] = useState(1);
  const [openMenuRow, setOpenMenuRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [imgProductoModal, setImgProductoModal] = useState("");
  const [admin] = useLocalStorage("admin", null);
  const { user, permiso } = useAuth();
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  //----------------------------------------------------------------------------------------------------------------------

  //Estados Iniciales Controller
  const header = [
    { prop: "etiqueta", name: "Etiqueta", sortable: true},
    { prop: "nombreSucursal", name: "Nombre", sortable: true, },
    { prop: "direccion", name: "Dirección", sortable: true },
    { prop: "telefono", name: "Teléfono", sortable: true },
    { prop: "correo", name: "Correo", sortable: true },
    { prop: "comentario", name: "Comentario", sortable: false },
  ];

  const [propiedades, setPropiedades] = useState({
    nombreSucursal: "",
    direccion: "",
    telefono: "",
    correo: "",
    comentario: "",
    urlFoto: "",
    eliminado: 0,
  });

  const parametrosInicialesMemo = useMemo(() => ({ name: "sucursal", ordenar: "idSucursal.desc" }),[]);
  const [ models, modelsLoading, modelsError, modelsPage, refreshModels, deleteModel, ] = useModels({ ...parametrosInicialesMemo });
  //----------------------------------------------------------------------------------------------------------------------

  //Funciones

  const onSearchClicked = async (eliminado) => {
    let params = {};
    if (eliminado != null) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
      };
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage },
      };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async (page) => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async (per) => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  const onDeleteClicked = async (row, type) => {
    try {
      setOpenMenuRow(false);
      await Swal.fire({
        title: type,
        text: `¿Desea ${type} el dato seleccionado  "${capitalizeFirst(
          row.nombreSucursal.toLowerCase()
        )}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34AA44",
        cancelButtonColor: "#E72C33",
        confirmButtonText: "Si, deseo Continuar.",
      }).then((result) => {
        if (result.isConfirmed) {
          let params = {};
          params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: pagina, limite: perPage },
          };
          deleteModel(row.idSucursal, type, params);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  //----------------------------------------------------------------------------------------------------------------------
  //Efectos
  //----------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" style={modalStyle} >
        <div style={{ backgroundColor: "#FFF" }}>
          <CloseIcon
            onClick={handleClose}
            style={{ marginBottom: "15px", marginRight: "15px", display: "flex", marginLeft: "auto", cursor: "pointer", }}
          />
          <img style={{ maxHeight: '600px', minHeight: '400px', width: '100%' }} src={imgProductoModal} alt="imgProductoModal" />
        </div>
      </Modal>
      <TablePageLayout title="Sucursales" model="sucursal" history={history}
        actionButton={{ to: "/sucursal/nuevo", icon: <Add />, label: "Agregar Sucursal", permiso: "sucursales" }}
        links={[{ label: "Sucursales", icon: <StoreMallDirectory fontSize="small" /> }]}
        SearchComponents={
          <>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                size="small"
                label="Nombre de la sucursal"
                variant="standard"
                onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  nombreSucursal: e.target.value
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputRegex
                label="Correo"
                fullWidth
                typeInput="text"
                maxLength={50}
                value={propiedades.correo}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    correo: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputRegex
                label="Telefono"
                fullWidth
                typeInput="phone"
                maxLength={10}
                value={propiedades.telefono}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    telefono: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                size="small"
                label="direccion"
                variant="standard"
                onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  direccion: e.target.value
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
              <LoadingButton
                variant="contained"
                onClick={() => onSearchClicked(null)}
                loading={modelsLoading && true}
                style={{
                  float: "right",
                  color: "whitesmoke",
                  justifyContent: "flex-end",
                }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>
          </>
        }
        DisableComponents={
          <>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={modelsLoading && true}
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        eliminado: e.target.checked ? 1 : 0,
                      }));
                      onSearchClicked(e.target.checked ? 1 : 0);
                    }}
                  />
                }
                label="Ver Deshabilitados"
              />
            </Grid>

            <Grid item xs={4} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }} >
              <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                <IconButton color="error" aria-label="upload picture" component="span" >
                  <a
                    className={classes.btnPdf}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}pdf/sucursales?${"admin=" + (admin ? 1 : 0)}${"&uid=" + user?.idUsuario}${"&nombreSucursal=" + propiedades.nombreSucursal}${"&direccion=" + propiedades.direccion}${"&telefono=" + propiedades.telefono}${"&correo=" + propiedades.correo}${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`} >
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                <IconButton color="warning" aria-label="upload picture" component="span">
                  <a className={classes.btnExcel} target="_blank" rel="noreferrer"
                    href={`${baseUrl}excel/sucursales?${"admin=" + (admin ? 1 : 0)}${"&uid=" + user?.idUsuario}${"&nombreSucursal=" + propiedades.nombreSucursal}${"&correo=" + propiedades.correo}${"&telefono=" + propiedades.telefono}${"&direccion=" + propiedades.direccion}${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`} >
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <TablePagination
            nombrePermiso="sucursales"
            permiso={permiso}
            header={header}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "urlFoto",
                name: "Foto",
                sortable: false,
                cell: (row, index) => (
                  <div>
                    <Avatar
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setImgProductoModal(row.urlFoto);
                        handleOpen();
                      }}
                      alt={row.nombre}
                      src={row.urlFoto}
                    />
                  </div>
                ),
              },
              {
                prop: "acciones",
                name: "Acciones",
                sortable: false,
                cell: (row, index) => (permiso && (permiso[0]["sucursales"] & 4) === 4 || (permiso[0]["sucursales"] & 8) === 8) && (
                  <div>
                      <IconButton onClick={(event) => { setOpenMenuRow(index); setAnchorEl(event.currentTarget) }}>
                      <MoreVertIcon />
                      </IconButton>
                      <Menu anchorEl={anchorEl} keepMounted open={index === openMenuRow} onClose={() => setOpenMenuRow(null)}>
                      {(admin === true || (permiso && permiso[0]["sucursales"] & 4) === 4) && (row.eliminado == null) && (
                        <NavLink exact to={(`sucursal/editar/${row.idSucursal}`)} style={{ color: '#000', textDecoration: 'none' }}>
                          <MenuItem aria-label="editar"
                            onClick={() => {
                              history.push(`sucursal/editar/${row.idSucursal}`, { match: row.idSucursal });
                            }}>
                            <Edit style={{ paddingRight: 5 }} />
                            Editar
                          </MenuItem>
                        </NavLink>
                      )}
                      {(admin === true || (permiso && permiso[0]["sucursales"] & 8) === 8) && (
                        row?.eliminado === null ? (
                          <MenuItem
                            onClick={() => onDeleteClicked(row, "Deshabilitar")}
                          >
                            <Delete style={{ paddingRight: 5 }} />
                            Deshabilitar
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={() => onDeleteClicked(row, "Habilitar")}
                          >
                            <RestoreFromTrashIcon style={{ paddingRight: 5 }} />
                            Habilitar
                          </MenuItem>
                        )
                      )}
                      {(admin === true || (permiso && permiso[0]["sucursales"] & 4) === 4) && (row.eliminado == null) && (
                        <NavLink exact to={(`sucursal/horario/${row.idSucursal}`)} style={{ color: '#000', textDecoration: 'none' }}>
                          <MenuItem aria-label="Horario" onClick={() => history.push(`sucursal/horario/${row.idSucursal}`)}>
                            <AccessTimeFilled style={{ paddingRight: 5 }} />
                            Horarios
                          </MenuItem>
                        </NavLink>
                      )}
                      </Menu> 
                  </div>                   
                 ),
              },
            ]}
          />
        }
      />
    </>
  );
};
export default Index;
