import firebase from "firebase/app";
import { baseUrl, baseUrlPublica } from "../utils/variables";

const getCurrentToken = async () => {
  if (firebase.auth().currentUser) {
    let token = await firebase.auth().currentUser.getIdToken();
    return token
  }
};

const getHeaders = (token) => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
});

const getHeadersWhitOutToken = () => ({
  "Content-Type": "application/json",
  Accept: "application/json",
});

class Services {
  async get(url) {
    const token = await getCurrentToken();
    const response = await fetch(baseUrl + url, {
      method: "GET",
      headers: getHeaders(token),
    });

    return response.json();
  }

  async getFirebase(url) {
    const token = await getCurrentToken();
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(token),
    });

    return response.json();
  }

  async getWithOutToken(url) {
    const response = await fetch(baseUrl + url, {
      method: "GET",
      headers: getHeadersWhitOutToken(),
    });

    return response.json();
  }

  async getPDF(url) {
    window.open(baseUrlPublica + url, "_blank");
  }

  async post(url, data) {
    const token = await getCurrentToken();
    const response = await fetch(baseUrl + url, {
      method: "POST",
      headers: getHeaders(token),
      body: JSON.stringify(data),
    });

    return response.json();
  }

  async postWithOutToken(url, data) {
    const response = await fetch(baseUrl + url, {
      method: "POST",
      headers: getHeadersWhitOutToken(),
      body: JSON.stringify(data),
    });

    try {
      let finalData = await response.json();
      return finalData;
    } catch (error) {
      console.error(error);
    }
  }

  async delete(url, data) {
    const token = await getCurrentToken();
    const response = await fetch(baseUrl + url, {
      method: "DELETE",
      headers: getHeaders(token),
      body: JSON.stringify(data),
    });

    return response.json();
  }

  async deleteWithOutToken(url, data) {
    const token = await getCurrentToken();
    const response = await fetch(baseUrl + url, {
      method: "DELETE",
      headers: getHeadersWhitOutToken(token),
      body: JSON.stringify(data),
    });

    return response.json();
  }

  async habilitar(url, data) {
    const token = await getCurrentToken();
    const response = await fetch(baseUrl + url, {
      method: "DELETE",
      headers: getHeaders(token),
      body: JSON.stringify(data),
    });

    return response.json();
  }

  async getBlob(url, data) {
    const token = await getCurrentToken();
    const response = await fetch(baseUrl + url, {
      method: "POST",
      headers: getHeaders(token),
      body: JSON.stringify(data),
    });
    return await response.blob();
  }

  getBlobPDF = async (url) => {
    const token = await getCurrentToken();
    const response = await fetch(baseUrlPublica + "pdf/" + url, {
      method: "GET",
      headers: getHeaders(token),
    });
    return await response.blob();
  };

  async downloadBlob(url, data, fileName) {
    const blob = await this.getBlob(url, data);
    const urlObj = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = urlObj;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  }
}

export default new Services();
