import React from "react";
import { IconButton } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Firebase from "../services/firebase";

const PdfMerge = ({ files, disabled = false, ...props }) => {

  const createFile = async () => {
    await Firebase.getPdfFromFiles(files);
  };

  return (
    <IconButton
      disabled={disabled}
      onClick={() => {
        createFile();
      }}
    >
      <PictureAsPdfIcon sx={disabled ? { color: "#e2e2e2" } : { color: "green" }} />
    </IconButton>
  );
};

export default PdfMerge;


// [
//   {
//     url: `${baseUrlPublica}pdf/clinica?${"idEvaluacion=" + row.idEvaluacion}${"&idSucursal=" + idSucursal}&descarga=0`,
//     tipo: "Evaluacion",
//   },
//   {
//     url: "EstudiosGabinete/544_2221grupo-habilitados-2022-10-28 _ 04_31_32pm.pdf",
//     tipo: "Laboratorio",
//   },
//   {
//     url: "EstudiosGabinete/7635_9833CURP_CARJ181205HSRSBSA1.pdf",
//     tipo: "Radio",
//   },
// ]