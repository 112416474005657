import { Button, Checkbox, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, TextField } from "@mui/material"
import React from "react"
import { makeStyles } from "@mui/styles"
import { useState } from "react"
import FichaIdentificacion from "./datos/fichaIdentificacion"
import AntecedentesHF from "./datos/antecedentesHF"
import AntecedentesPNP from "./datos/antecedentesPNP"
import AntecedentesGineco from "./datos/antecedentesGineco"
import AntecedentesLaborales from "./datos/antecedentesLaborales"
import AntecedentesPP from "./datos/antecedentesPP"
import Interrogatorio from "./datos/interrogatorio"
import Exploracion from "./datos/exploracion"
import Conclusiones from "./datos/conclusiones"
import { LoadingButton } from "@mui/lab"
import Service from '../../../../services/api'
import { useAlert } from "../../../../hooks/useAlert"
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}))

export const DatosPdfComponent = ({ idOrdenServicio, idEvaluacion, datospdf, fichaIdentificacion, ...props }) => {
    console.log(fichaIdentificacion)

    const { showAlert } = useAlert();
    const classes = useStyles()

    const [screen, setScreen] = useState("")

    const screenToRender = _screen => {
        switch (_screen) {
            case "fichaIdentificacion":
                return arrayNombres[_screen]?.Componente
            case "antecedentesHF":
                return arrayNombres[_screen]?.Componente
            case "antecedentesPNP":
                return arrayNombres[_screen]?.Componente
            case "antecedentesGineco":
                return arrayNombres[_screen]?.Componente
            case "antecedentesLaborales":
                return arrayNombres[_screen]?.Componente
            case "antecedentesPP":
                return arrayNombres[_screen]?.Componente
            case "interrogatorio":
                return arrayNombres[_screen]?.Componente
            case "exploracion":
                return arrayNombres[_screen]?.Componente
            case "conclusiones":
                return arrayNombres[_screen]?.Componente

            default:
                return <></>
        }
    }

    const [propiedades, setPropiedades] = useState(
        (datospdf?.data != undefined && datospdf?.data != "" && datospdf?.data != null)
            ?
            JSON.parse(datospdf?.data)
            :
            {
                fichaIdentificacion: {
                    nombre: fichaIdentificacion?.nombres + " " + fichaIdentificacion?.apellidoPaterno + " " + fichaIdentificacion?.apellidoMaterno,
                    fechaExamen: moment(new Date()).format("DD/MM/YYYY"),
                    fechaNacimiento: moment(fichaIdentificacion?.fechaNacimiento).format("DD/MM/YYYY"),
                    edad: fichaIdentificacion?.edad,
                    estadoCivil: fichaIdentificacion?.estadoCivil,
                    sexo: fichaIdentificacion?.genero,
                    telefono: fichaIdentificacion?.telefono,
                    lugarNacimiento: fichaIdentificacion?.nacionalidad,
                    direccion: fichaIdentificacion?.direccion,
                    ine: fichaIdentificacion?.ine,
                    aspirante: fichaIdentificacion?.puesto,
                    lugarResidencia: "Hermosillo",
                },
                antecedentesHF: {
                    alergicos
                        :
                        "Negado",
                    asma
                        :
                        "Negado",
                    degenerativas
                        :
                        "Negado",
                    diabetesMellitus
                        :
                        "Negado",
                    enfisemaPulmonar
                        :
                        "Negado",
                    epilepsia
                        :
                        "Negado",
                    hipertensionArterial
                        :
                        "Negado",
                    hipertirodismo
                        :
                        "Negado",
                    hipotiroidismo
                        :
                        "Negado",
                    infartoAM
                        :
                        "Negado",
                    inquemiaMiocardia
                        :
                        "Negado",
                    insuficienciaCardiaca
                        :
                        "Negado",
                    migraña
                        :
                        "Negado",
                    neumonias
                        :
                        "Negado",
                    obesidad
                        :
                        "Negado",
                    oncologicas
                        :
                        "Negado",
                    osteoartrosis
                        :
                        "Negado",
                    otrasEC
                        :
                        "Negado",
                    otrasEE
                        :
                        "Negado",
                    otrasEN
                        :
                        "Negado",
                    piel
                        :
                        "Negado",
                    psiquiatricas
                        :
                        "Negado",
                    rinitisAlergica
                        :
                        "Negado",
                    sordera
                        :
                        "Negado",
                    tuberculosis
                        :
                        "Negado",
                },
                antecedentesPNP: {},
                antecedentesGineco: {},
                antecedentesLaborales: {},
                antecedentesPP: {
                    alergicos
                        :
                        "Negado",
                    cardiopatia
                        :
                        "Negado",
                    congenitos
                        :
                        "Negado",
                    dermatologicos
                        :
                        "Negado",
                    diabetesMelitus
                        :
                        "Negado",
                    digestivos
                        :
                        "Negado",
                    endocrinos
                        :
                        "Negado",
                    genitourinario
                        :
                        "Negado",
                    hematologicos
                        :
                        "Negado",
                    hipertension
                        :
                        "Negado",
                    infecciosos
                        :
                        "Negado",
                    mentales
                        :
                        "Negado",
                    neurologicos
                        :
                        "Negado",
                    quirurgicos
                        :
                        "Negado",
                    toxicomanias
                        :
                        "Negado",
                    traumaticos
                        :
                        "Negado",
                },
                interrogatorio: {
                    sintomasGenerales: {
                        cambiosEnElPeso: "Negado",
                        cambiosEnElApetito: "Negado",
                        sudoracionAnormal: "Negado",
                        escalofrio: "Negado",
                        fiebre: "Negado",
                        debilidadOAstenia: "Negado",
                    },
                    organosDeLosSentidos: {
                        alteracionesDeVision: "Negado",
                        hipocausia: "Negado",
                        acufenos: "Negado",
                        otalgia: "Negado",
                        otorrea: "Negado",
                        otorragia: "Negado",
                        vertigo: "Negado",
                        epistaxis: "Negado",
                        rinorrea: "Negado",
                        epistaxis: "Negado",
                        alteracionesDelGusto: "Negado",
                        medicamentosOtotoxicos: "Negado",
                    },
                    aparatoDigestivo: {
                        nauseas: "Negado",
                        vomito: "Negado",
                        odinofagia: "Negado",
                        dolorAbdominal: "Negado",
                        pirosis: "Negado",
                        distensionAbdominal: "Negado",
                    },
                    aparatoRespiratorio: {
                        tosDeMasDeUnaSemana: "Negado",
                        tosSeca: "Negado",
                        tosHumeda: "Negado",
                        tosConHemoptisis: "Negado",
                        hipertermia: "Negado",
                        rinorrea: "Negado",
                        olfato: "Negado",
                        narizTapada: "Negado",
                        secresionRetronasal: "Negado",
                        disfonia: "Negado",
                        otrosSintomas: "Negado",
                        recibioTratamiento: "Negado",
                    },
                    aparatoUrogenital: {
                        disuaria: "Negado",
                        poliaquiuria: "Negado",
                        hematuria: "Negado",
                        calibreChorroUrinario: "Negado",
                        disfuncionErectil: "Negado",
                        retencionUrinaria: "Negado",
                    },
                    sistemaEndrocrino: {
                        polidipsia: "Negado",
                        poliuria: "Negado",
                        polifagia: "Negado",
                        intoleranciaAlFrio: "Negado",
                        intoleranciaAlCalor: "Negado",
                        hirsutismo: "Negado",
                    },
                    pielYAnexos: {
                        manchas: "Negado",
                        urticaria: "Negado",
                        angioedema: "Negado",
                        cambiosEnLunares: "Negado",
                        alteracionPeloUñas: "Negado",
                        otros: "Negado",
                    },
                    sistemaMusculoesqueletico: {
                        dolorMuscular: "Negado",
                        debilidadMuscular: "Negado",
                        inflamacionArticular: "Negado",
                        dolorOseo: "Negado",
                        dolorLumbar: "Negado",
                        dolorCervical: "Negado",
                        disestesias: "Negado",
                        paresias: "Negado",
                        paraestesias: "Negado",
                        hiperestesias: "Negado",
                        hipoestesias: "Negado",
                        anestesias: "Negado",
                        torticolis: "Negado",
                        espasmosDeTorsion: "Negado",
                        algias: "Negado",
                        tendinitis: "Negado",
                        ciatica: "Negado",
                        otros: "Negado",
                    },
                    esferaNeuropsiquiatrica: {
                        cefalea: "Negado",
                        convulsiones: "Negado",
                        agresividad: "Negado",
                        nerviosismo: "Negado",
                        alucinaciones: "Negado",
                        alteracionDeLaConciencia: "Negado",
                        temblor: "Negado",
                        ansiedad: "Negado",
                        manias: "Negado",
                        desorientacion: "Negado",
                        ausencias: "Negado",
                        alteracionesSueño: "Negado",
                        depresion: "Negado",
                        fobias: "Negado",
                        alteracionDeMemoria: "Negado",
                    }
                },
                exploracion: {
                    acortamiento: "Normal",
                    varices: "Normal",
                    flexionExtension
                        :
                        "Normal",
                    flexionExtension2
                        :
                        "Normal",
                    flexionExtension3
                        :
                        "Normal",
                    fuerzaMuscular
                        :
                        "Normal",
                    fuerzaMuscular2
                        :
                        "Normal",
                    fuerzaMuscular3
                        :
                        "Normal",
                    inclinacionLateralDerecha
                        :
                        "Normal",
                    inclinacionLateralDerecha2
                        :
                        "Normal",
                    inclinacionLateralDerecha3
                        :
                        "Normal",
                    inclinacionLateralIzquierda
                        :
                        "Normal",
                    inclinacionLateralIzquierda2
                        :
                        "Normal",
                    inclinacionLateralIzquierda3
                        :
                        "Normal",
                    integridad
                        :
                        "Normal",
                    movilidad
                        :
                        "Normal",
                    movilidad2
                        :
                        "Normal",
                    movilidad3
                        :
                        "Normal",
                    reflejos
                        :
                        "Normal",
                    rotacionLateralDerecha
                        :
                        "Normal",
                    rotacionLateralDerecha2
                        :
                        "Normal",
                    rotacionLateralDerecha3
                        :
                        "Normal",
                    rotacionLateralIzquierda
                        :
                        "Normal",
                    rotacionLateralIzquierda2
                        :
                        "Normal",
                    rotacionLateralIzquierda3
                        :
                        "Normal",
                    sensibilidad
                        :
                        "Normal",
                },
                conclusiones: {},
            }
    )

    const guardar = async () => {
        let res = await Service.post("evaluaciones/guardar", {
            idOrdenServicio,
            idEvaluacion,
            idSucursal: 1,
            DatosPdf: { data: JSON.stringify(propiedades) }
        })
        console.log(res)
        if (res?.detalle?.estatus == 200) {
            showAlert({
                message: "Datos guardados correctamente",
                severity: "success"
            });
        } else {
            showAlert({
                message: "favor de revisar la informacion enviada",
                severity: "error"
            });
        }

    }

    const arrayNombres = []
    arrayNombres["fichaIdentificacion"] = { nombre: "FICHA IDENTIFICACION", Componente: <FichaIdentificacion datos={propiedades} setDatos={setPropiedades} /> }
    arrayNombres["antecedentesHF"] = { nombre: "ANTECEDENTES HEREDOFAMILIARES", Componente: <AntecedentesHF datos={propiedades} setDatos={setPropiedades} /> }
    arrayNombres["antecedentesPNP"] = { nombre: "ANTECEDENTES PERSONALES NO PATOLOGICOS", Componente: <AntecedentesPNP datos={propiedades} setDatos={setPropiedades} /> }
    arrayNombres["antecedentesGineco"] = { nombre: "ANTECEDENTES GINECOOBSTETRICOS", Componente: <AntecedentesGineco datos={propiedades} setDatos={setPropiedades} /> }
    arrayNombres["antecedentesLaborales"] = { nombre: "ANTECEDENTES LABORALES", Componente: <AntecedentesLaborales datos={propiedades} setDatos={setPropiedades} /> }
    arrayNombres["antecedentesPP"] = { nombre: "ANTECEDENTES PERSONALES PATOLOGICOS", Componente: <AntecedentesPP datos={propiedades} setDatos={setPropiedades} /> }
    arrayNombres["interrogatorio"] = { nombre: "INTERROGATORIO POR APARATOS Y SISTEMAS", Componente: <Interrogatorio datos={propiedades} setDatos={setPropiedades} /> }
    arrayNombres["exploracion"] = { nombre: "EXPLORACION FISICA", Componente: <Exploracion datos={propiedades} setDatos={setPropiedades} /> }
    arrayNombres["conclusiones"] = { nombre: "COMENTARIOS Y CONCLUSIONES", Componente: <Conclusiones datos={propiedades} setDatos={setPropiedades} /> }

    return (
        <>
            <Grid
                container
                spacing={2}
                component={Paper}
                className={classes.paper}
                style={{ marginTop: 10 }}
            >
                {
                    Object.keys(propiedades).map(key => {
                        return (
                            <Grid xs={2} sx={{ padding: "10px" }}>
                                <Button onClick={e => { setScreen(key) }} sx={{ background: "black", color: "white", width: "100%", height: "-webkit-fill-available", "&:hover": { background: "#666666" } }}>{arrayNombres[key]?.nombre}</Button>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid
                container
                spacing={2}
                component={Paper}
                className={classes.paper}
                style={{ marginTop: 10 }}
            >
                {
                    screenToRender(screen)
                }
            </Grid>
            <Grid
                container
                spacing={2}
                component={Paper}
                className={classes.paper}
                style={{ marginTop: 10 }}
            >
                <LoadingButton onClick={
                    () => { guardar() }
                }
                >
                    GUARDAR
                </LoadingButton>
            </Grid >
        </>
    )
}
