/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useModel } from "../../hooks/useModel";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import DatePicker from "../../components/DatePickerBeta"
import Service from "../../services/api";
import AsyncAutocompleteSearchRework from '../../components/AsyncAutocompleteSearchRework';
import InputRegex from "../../components/InputRegex";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import PageHeader from "../../components/PageHeader";
import {
    Grid,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Table,
    IconButton,
    CircularProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAlert } from "../../hooks/useAlert";
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";
import PacienteTarget from "./PacienteTarget";
import moment from "moment";
import RemoveIcon from '@mui/icons-material/Remove';
import { useModels } from '../../hooks/useModels';
import Calendar from '../../components/Calendar';
import AutocompleteDeluxe from "../../components/AutocompleteDeluxe";

////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { tableCellStyles, tableRowStyles, entradasStyle, capitalizeFirst } from '../../utils';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Edit } from "@mui/icons-material";
import { BsFileEarmarkRichtextFill } from "react-icons/bs";
import SaveIcon from "@mui/icons-material/Save";
import { is } from "date-fns/locale";
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        flexWrap: "wrap",
    },
}));
const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const useEntradasStyle = entradasStyle();
const OrdenServicioDetail = ({ match, history, dialog }) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const id = match.params.id;
    const idOrdenServicio = match.params.idOrdenServicio;
    const noCita = !!id;
    const classes = useStyles();
    const { showAlert } = useAlert();
    const editing = !!idOrdenServicio;
    const [admin] = useLocalStorage("admin", null);
    const { permiso } = useAuth();
    const [isCliente, setIsCliente] = useState(permiso[0]["cliente"] & 1 === 1);

    const [usuariosConvenios, setUsuariosConvenios] = useState(null);

    const [convenioTipoEvaluacion, setConvenioTipoEvaluacion] = useState(null);

    const [cargando, setCargando] = useState(false);
    const [idSucursalStorage] = useLocalStorage("idSucursal", "");
    const [citaDetalles, setCitaDetalles] = useState(null);
    const [guardado, setGuardado] = useState(true);
    const [servicio, setServicio] = useState(null);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [solicitudRH, setSolicitudRH] = useState("N/A");
    const [idSucursal, setIdSucursal] = useState(1);
    const entradas = useEntradasStyle();
    const [reload, setReload] = useState(true);
    const [servicios, setServicios] = useState([]);
    const [fechaCita, setFechaCita] = useState("");
    const [evaluacion, setEvaluacion] = useState(null);
    const [convenio, setConvenio] = useState(null);
    const [clearConsultorio, setClearConsultorio] = useState(false);
    const [consultorio, setConsultorio] = useState(null);
    const [horas, setHoras] = useState(false);
    const defaultPaciente = {
        ine: "",
        genero: "M",
        nombres: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        nombreCompleto: "",
        correo: "N/A",
        direccion: "N/A",
        telefono: "9999999999",
        contactoEmergencia: "9999999999",
        urlFoto:
            "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Paciente%2Fpaciente_default.jpg?alt=media&token=4dfe89d7-0766-462e-a924-a4a67156277b",
        fechaNacimiento: moment(new Date()).format("YYYY-MM-DD"),
        estadoCivil: "SOLTERO(A)",
        tipoSangre: "O+",
        pacienteSucursal: [],
        nivelEducativo: "PREPARATORIA",
        nacionalidad: "México",
        fechaSolicitud: moment(new Date()).format("yyyy/MM/DD"),
        alergias: "NINGUNA",
        puesto: "N/A",
        proyecto: "N/A",
        edad: 0,
        nombreEmergencia: "",
    };

    const setResultadosConvenios = async () => {
        let res = await Service.get("usuarios-convenios?expand=convenio&idUsuario=" + permiso[0]["idUsuario"])
        console.log(res, "res")
        setUsuariosConvenios(res.resultado);
    }

    const setResultadoConvenioTipoEvaluacion = async () => {
        let res = await Service.get("convenios-tipo-evaluaciones?expand=tipoevaluacion&idUsuario=" + permiso[0]["idUsuario"])
        console.log(res, "res2")
        setConvenioTipoEvaluacion(res.resultado);
    }

    useEffect(() => {
        if (isCliente) {
            setResultadosConvenios();
            setResultadoConvenioTipoEvaluacion();
        }
    }, [])

    const getTimeFromMins = mins => {
        if (mins >= 24 * 60 || mins < 0) {
            throw new RangeError("Valid input should be greater than or equal to 0 and less than 1440.");
        }
        var h = mins / 60 | 0,
            m = mins % 60 | 0;
        return moment.utc().hours(h).minutes(m).format("hh:mm A");
    }
    const [pacienteDetalles, setPacienteDetalles] = useState(defaultPaciente);
    const [propiedades, setPropiedades] = useState({
        idCita: "",
    });
    const [fecha, setFecha] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const paramsMemoConsultorio = useMemo(
        () => ({
            ordenar: "consultorio.asc",
            idSucursal
        }),
        [idSucursal]
    );
    const { model, modelLoading, modelError, updateModel, updateModelLoading } =
        useModel({
            name: "cita",
            idOrdenServicio,
            redirectOnPost: true,
        });
    const parametrosInicialesMemo = useMemo(
        () => ({
            name: 'publico/agenda',
            expand: "cita, agendaDiaInhabiles",
            extraParams: {
                idSucursal,
                consultorio: consultorio?.consultorio
            }
        }),
        [idSucursal, consultorio]
    );
    const [
        models,
    ] = useModels({ ...parametrosInicialesMemo });

    useEffect(() => {
        if (clearConsultorio) {
            setClearConsultorio(false);
        }
    }, [clearConsultorio]);
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const onGuardar = async () => {
        if (horas || isCliente) {
            const text =
                "¿Estás seguro de que deseas guardar la orden de servicio?".toUpperCase();
            const res = await Swal.fire({
                title: text,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#25607A",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, Guardar!",
                cancelButtonText: "Cancelar",
            });
            if (res.isConfirmed) {
                if (isCliente) {
                    if (fechaCita == "") {
                        showAlert({
                            message: "es necesario seleccionar una fecha para la cita",
                            severity: "error",
                        });
                        return;
                    }
                }
                let body = isCliente
                    ?
                    {
                        pacienteDetalles,
                        idSucursal: idSucursal,
                        datos: {
                            idConvenio: convenio,
                            idTipoEvaluacion: evaluacion,
                            solicitudRH
                        },
                        fechaCita: fechaCita,
                        diaHora: "",
                        servicios,
                        isCliente,
                    }
                    :
                    {
                        pacienteDetalles,
                        horas: { ...horas, minInicio: horas?.minInicio.toString() },
                        idAgenda: models[0]?.idAgenda,
                        idSucursal: idSucursal,
                        fechaCita: moment(horas.fecha, 'dddd DD [de] MMMM YYYY').format("YYYY-MM-DD") + " 00:00:01",
                        diaHora: getTimeFromMins(horas.minInicio) + " del dia " + horas.fecha,
                        datos: {
                            idConvenio: convenio?.idConvenio,
                            idTipoEvaluacion: evaluacion?.idTipoEvaluacion,
                            solicitudRH
                        },
                        servicios
                    };
                console.log(body, "body")
                updateModel(body, true, "citas");
            };
        } else {
            showAlert({
                message: "es necesario seleccionar una hora para la cita",
                severity: "error",
            });
        }
    }
    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////

    const getOrdenServicio = async () => {
        setLoadingEdit(true);
        let res = await Service.get("orden-servicio?expand=detallePaciente,cita,detalleConvenio.convenio,detalleConvenio.vendedor,ordenServicioServicio.servicio.servicioDoctores.doctor,entradas.entrada.movimientos.lote,entradasLaboratorio.entrada.movimientos.lote&idOrdenServicio=" + idOrdenServicio);
        if (res?.resultado.length > 0) {
            let _paciente = { ...res?.resultado[0]?.detallePaciente };
            setPacienteDetalles(_paciente);
            setLoadingEdit(false);
        } else {
            showAlert({
                message: "No se pudo cargar la orden de servicio seleccionada",
                severity: "error"
            });
            setLoadingEdit(false);
        }
    }

    const addServicio = () => {
        if (servicio) {
            let repetido = false;
            servicios.map((e) => {
                if (e.tipoEvaluacion == "Servicio") {
                    if (e.idServicio == servicio.idServicio) {
                        repetido = true;
                    }
                }
            });
            if (repetido) {
                showAlert({
                    message: "Servicio ya esta en la lista",
                    severity: "error",
                });
                return;
            }
            let newServicios = [...servicios];
            newServicios.push(
                {
                    ...servicio,
                    ...servicio.servicioDoctores[0].doctor[0],
                    tipoEvaluacion: "Servicio",
                    cantidad: 1,
                    loading: false,
                    doctorSeleccionado: servicio.servicioDoctores[0].doctor[0].idUsuario,
                    descuento: 0,
                    precioDescuento: servicio.valorUIva,
                    por: 100 - (Math.round(((servicio.valorUIva / servicio.valorUIva) * 100) * 100) / 100)
                });
            setServicios(newServicios);
            setServicio(null);
        }
    }

    const onDropServicio = async (id, nombre) => {
        if (id) {
            showAlert({
                message: "Espera un momento.....",
                severity: "warning",
            });
            let posi;
            const copyServicios = [...servicios];
            servicios.map((e, index) => {
                if (e.idServicio === id) {
                    posi = index;
                }
            });
            copyServicios.splice(posi, 1);
            setServicios(copyServicios);
            showAlert({
                message: "Servicio borrado con éxito.",
                severity: "success",
            });
        }
        setReload(true);
    };

    useEffect(() => {
        if (editing) {
            getOrdenServicio();
        }
    }, []);

    const setidConsultorioCallback = useCallback(
        (e, v) => {
            if (v !== "" && v) {
                setConsultorio(v);
            } else {
                setConsultorio("");
            }
        },
        [setConsultorio]
    );

    useEffect(() => {
        let mounted = true;
        if (mounted && model) {
            setPropiedades(model);
        }
        return () => {
            mounted = false;
            setPropiedades({
                idSucursalStorage,
            });
        };
    }, [model]);

    const setCita = (_horas) => {
        setHoras(_horas);
    }

    return (
        <>
            <PageHeader
                history={history}
                title={(!editing ? "NUEVA" : "EDITAR") + " ORDEN DE SERVICIO"}
                links={[
                    {
                        to: "/ordenServicios",
                        icon: <BsFileEarmarkRichtextFill fontSize="large" />,
                        label: "Orden Servicio",
                    },
                    {
                        to: !editing ? "/ordenServicio/nuevo" : "/ordenServicio/ /" + id,
                        icon: !editing ? <Add /> : <Edit />,
                        label: !editing
                            ? "Nuevo"
                            : "Editar - " +
                            "Orden de Servicio con el id: " + idOrdenServicio,
                    },
                ]}
                editing={editing}
                dialog={dialog}
            />
            <Grid component={Paper} container className={classes.paper} spacing={1} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="Sucursal-Label">Sucursal a la que se asistira</InputLabel>
                        <Select
                            labelId="Sucursal-Label"
                            id="Sucursal-Id"
                            value={idSucursal}
                            label="Sucursal"
                            onChange={e => {
                                setIdSucursal(e.target.value)
                                setClearConsultorio(true)
                                setConsultorio(null);
                            }}
                        >
                            <MenuItem value={1}>Clínica Nueva Vida</MenuItem>
                            <MenuItem value={2}>LHospitalito</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <PacienteTarget
                id={id}
                setGuardado={setGuardado}
                citaDetalles={citaDetalles}
                setCitaDetalles={setCitaDetalles}
                setPacienteDetalles={setPacienteDetalles}
                pacienteDetalles={pacienteDetalles}
                defaultPaciente={defaultPaciente}
                osEditing={editing}
                loadingEdit={loadingEdit}
                noCita={noCita}
                setLoadingEdit={setLoadingEdit}
            />
            <Grid component={Paper} container className={classes.paper} spacing={1} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                <Grid item xs={12} >
                    {
                        !isCliente ?
                            <AutocompleteDeluxe
                                labelToShow="Convenio"
                                labelProp="nombreConvenio"
                                nombre="convenio"
                                disabled={editing}
                                filtro={{ expand: "vendedor" }}
                                value={convenio}
                                setDato={setConvenio}
                            />
                            :
                            <>
                                {/* select de convenios */}
                                <FormControl fullWidth variant="standard">
                                    <InputLabel id="Convenio-Label">Convenio</InputLabel>
                                    <Select
                                        labelId="Convenio-Label"
                                        id="Convenio-Id"
                                        value={convenio}
                                        label="Convenio"
                                        onChange={e => {
                                            setConvenio(e.target.value)
                                        }}
                                    >
                                        {console.log("usuariosConvenios", usuariosConvenios)}
                                        {
                                            usuariosConvenios?.length > 0 && usuariosConvenios?.map((e, index) => {
                                                console.log(e)
                                                return (
                                                    <MenuItem key={index} value={e?.idConvenio}>{e?.convenio?.nombreConvenio}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </>
                    }
                </Grid>
                <Grid item xs={12}>
                    <InputRegex
                        label="Solicitante de RH"
                        fullWidth
                        typeInput="text"
                        onChange={(e) => {
                            setSolicitudRH(e.target.value);
                        }}
                        inputProps={{
                            value: solicitudRH,
                        }}
                    />
                </Grid>
                {
                    isCliente &&
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            labelText="Fecha de Cita"
                            variant="standard"
                            fullWidth
                            typePicker="mobile"
                            inputFormat="dd/MM/yyyy"
                            size="medium"
                            value={fechaCita != "" ? fechaCita : null}
                            onChange={(e) => setFechaCita(moment(e).format("YYYY-MM-DD H:m:s"))}
                        />

                    </Grid>
                }
                <Grid item xs={12}>
                    {
                        !isCliente ?
                            <AutocompleteDeluxe
                                labelToShow="Tipo de Evaluación"
                                labelProp="evaluacion"
                                nombre="tipo-evaluacion"
                                filtro={
                                    idSucursal
                                        ?
                                        {
                                            idSucursal,
                                            expand: "paquete.paqueteProductoInventario.productoInventario.inventario,paquete.paqueteInventarioLaboratorio.inventarioLaboratorio.inventario,tipoEvaluacionServicio.servicio.servicioDoctores.doctor"
                                        }
                                        :
                                        {
                                            expand: "paquete.paqueteProductoInventario.productoInventario.inventario,paquete.paqueteInventarioLaboratorio.inventarioLaboratorio.inventario,tipoEvaluacionServicio.servicio.servicioDoctores.doctor"
                                        }
                                }
                                setDato={setEvaluacion}
                                dato={evaluacion}
                            />
                            :
                            <>
                                {/* select de tipo de evaluacion */}
                                <FormControl fullWidth variant="standard">
                                    <InputLabel id="TipoEvaluacion-Label">Tipo de Evaluación</InputLabel>
                                    <Select
                                        labelId="TipoEvaluacion-Label"
                                        id="TipoEvaluacion-Id"
                                        value={evaluacion}
                                        label="Tipo de Evaluación"
                                        onChange={e => {
                                            setEvaluacion(e.target.value)
                                        }}
                                    >
                                        {
                                            convenioTipoEvaluacion?.length > 0 && convenioTipoEvaluacion?.map((e, index) => {
                                                return (
                                                    <MenuItem key={index} value={e?.idTipoEvaluacion}>{e?.tipoevaluacion?.evaluacion}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </>
                    }

                </Grid>

                <Grid item xs={12}>
                    <Grid item xs={12} sm={8} md={8} lg={10}>
                        <AutocompleteDeluxe
                            labelToShow="Servicio"
                            labelProp="concepto"
                            disabled={!idSucursal}
                            nombre="servicio"
                            filtro={idSucursal ? { idSucursal, expand: "servicioDoctores.doctor" } : { expand: "servicioDoctores.doctor" }}
                            setDato={setServicio}
                            dato={servicio}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={2}>
                        <LoadingButton
                            disabled={!servicio}
                            fullWidth
                            variant="contained"
                            onClick={() => addServicio()}
                            size="medium"
                            style={{ color: "#fff" }}
                        >
                            Agregar Servicio
                        </LoadingButton>
                    </Grid>
                </Grid>
                {(servicios.length > 0) && (
                    <>
                        <Grid item xs={12}>
                            <Table className={entradas.tableEntradas}>
                                <TableHead>
                                    <TableRowModify>
                                        <TableCellModify> # </TableCellModify>
                                        <TableCellModify> Concepto </TableCellModify>
                                        <TableCellModify> Grupo </TableCellModify>
                                        <TableCellModify> descuento % </TableCellModify>
                                        <TableCellModify> descuento </TableCellModify>
                                        <TableCellModify> Valor Unitario </TableCellModify>
                                        <TableCellModify> Valor con descuento </TableCellModify>
                                        <TableCellModify> </TableCellModify>
                                    </TableRowModify>
                                </TableHead>
                                <TableBody>
                                    {reload ? servicios.map((serv, index) => {
                                        return (
                                            <TableRowModify key={index}>
                                                <TableCellModify> {index + 1} </TableCellModify>
                                                <TableCellModify> {serv?.concepto} </TableCellModify>
                                                <TableCellModify> {serv?.grupo} </TableCellModify>
                                                <TableCellModify>
                                                    <InputRegex
                                                        value={serv?.por}
                                                        onChange={e => {
                                                            let copyServicios = [...servicios];
                                                            copyServicios[index].por = e.target.value;
                                                            copyServicios[index].precioDescuento = Math.round((copyServicios[index].valorUIva - ((copyServicios[index].valorUIva / 100) * (e.target.value >= 0 && e.target.value ? e.target.value : 0))) * 100) / 100;
                                                            copyServicios[index].descuento = Math.round((copyServicios[index].valorUIva - (Math.round((copyServicios[index].valorUIva - ((copyServicios[index].valorUIva / 100) * (e.target.value >= 0 && e.target.value ? e.target.value : 0))) * 100) / 100)) * 100) / 100;
                                                            setServicios([...copyServicios]);

                                                        }}
                                                        typeInput={"%"}
                                                    />
                                                </TableCellModify>
                                                <TableCellModify>
                                                    $ {serv.descuento}
                                                </TableCellModify>
                                                <TableCellModify> {serv.valorUIva} </TableCellModify>
                                                <TableCellModify>
                                                    <InputRegex
                                                        value={serv?.precioDescuento}
                                                        onChange={e => {
                                                            let copyServicios = [...servicios];
                                                            copyServicios[index].precioDescuento = e.target.value;
                                                            copyServicios[index].por = 100 - (Math.round(((e.target.value / copyServicios[index].valorUIva) * 100) * 100) / 100);
                                                            copyServicios[index].descuento =
                                                                Math.round(
                                                                    (
                                                                        (
                                                                            copyServicios[index].valorUIva
                                                                            -
                                                                            Math.round(e.target.value * 100) / 100
                                                                        )
                                                                    ) * 100
                                                                ) / 100;
                                                            setServicios([...copyServicios]);
                                                        }}
                                                        typeInput={"currency"}
                                                    />
                                                </TableCellModify>
                                                <TableCellModify>
                                                    <IconButton
                                                        onClick={e => {
                                                            setReload(false);
                                                            onDropServicio(serv.idServicio, serv.concepto);
                                                        }}
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </TableCellModify>
                                            </TableRowModify>
                                        )
                                    }) : (
                                        <TableRowModify>
                                            <TableCellModify></TableCellModify>
                                            <TableCellModify></TableCellModify>
                                            <TableCellModify> <CircularProgress /> </TableCellModify>
                                            <TableCellModify></TableCellModify>
                                        </TableRowModify>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </>
                )}
            </Grid>

            {
                !isCliente &&
                <>
                    <Grid component={Paper} container className={classes.paper} spacing={1} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                        <Grid item xs={12}>
                            <AsyncAutocompleteSearchRework
                                style={{ margin: '10px 0' }}
                                defaultValue={consultorio ?? null}
                                disabled={!(idSucursal > 0)}
                                label="Consultorio"
                                labelProp="consultorio"
                                name="publico/agenda"
                                onChange={setidConsultorioCallback}
                                clearInput={clearConsultorio}
                                extraParams={paramsMemoConsultorio}
                            />
                        </Grid>
                    </Grid>
                    <Grid component={Paper} container className={classes.paper} spacing={1} sx={{ marginTop: "20px", marginBottom: "20px", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        {
                            idSucursal > 0 && consultorio != "" &&
                            (
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>{
                                }
                                    {
                                        models.length > 0 ? (
                                            <Calendar
                                                fechas={[
                                                    moment(new Date()).format("YYYY/MM/DD"),
                                                    moment(new Date()).add(3, "M").format("YYYY/MM/DD"),
                                                ]}
                                                horas={{
                                                    semana1: { horaInicio: models[0]?.inicioHoraSemana1, horaFinal: models[0]?.finHoraSemana1 },
                                                    semana2: { horaInicio: models[0]?.inicioHoraSemana2, horaFinal: models[0]?.finHoraSemana2 },
                                                    sabado1: { horaInicio: models[0]?.inicioHoraSabado1, horaFinal: models[0]?.finHoraSabado1 },
                                                    sabado2: { horaInicio: models[0]?.inicioHoraSabado2, horaFinal: models[0]?.finHoraSabado2 },
                                                    domingo1: { horaInicio: models[0]?.inicioHoraDomingo1, horaFinal: models[0]?.finHoraDomingo1 },
                                                    domingo2: { horaInicio: models[0]?.inicioHoraDomingo2, horaFinal: models[0]?.finHoraDomingo2 },
                                                }}
                                                cita={models[0]?.cita.filter(c => c.status == "INICIADO")}
                                                diasSemana={models[0]?.semana}
                                                agendaDiaInhabiles={models[0]?.agendaDiaInhabiles}
                                                setCita={setCita}
                                                actual={horas}
                                                division={models[0]?.division}
                                            />
                                        )
                                            :
                                            <CircularProgress />
                                    }
                                </div>
                            )
                        }
                    </Grid>
                </>
            }


            <Grid component={Paper} container className={classes.paper} spacing={1} style={{ marginBottom: "250px" }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                        <LoadingButton
                            disabled={
                                !guardado
                                    ? true
                                    : modelLoading ||
                                        updateModelLoading ||
                                        admin ||
                                        (permiso && permiso[0]["citas"] & 2) === 2
                                        ? false
                                        : true
                            }
                            loading={modelLoading || updateModelLoading || cargando}
                            variant="contained"
                            onClick={() => onGuardar()}
                            size="medium"
                            startIcon={<SaveIcon />}
                            style={{ color: "#fff" }}
                        >
                            {!editing ? "GUARDAR" : "MODIFICAR"}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
export default OrdenServicioDetail;
