/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { baseUrlPublica } from '../../../../utils/variables';
import {
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import { useModel } from "../../../../hooks/useModel";
import { useAuth } from "../../../../hooks/useAuth";
import { useAlert } from "../../../../hooks/useAlert";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import Service from "../../../../services/api";
import LaunchIcon from '@mui/icons-material/Launch';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
export const ComentariosConclusionesComponent = ({
  idEvaluacion,
  idOrdenServicio,
  audio,
  eva,
  comentarios,
  estudiosGabinete,
  setGuardado,
  laboratorios,
  setDisabledByName,
  ...props
}) => {
  const classes = useStyles();
  const id = idEvaluacion;
  const [admin] = useLocalStorage("admin", null);
  const { showAlert } = useAlert();
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const [loadingMedico, setLoadingMedico] = useState(false);
  const { permiso, user } = useAuth();
  const [files] = useState(null);
  const _archivos = estudiosGabinete ? [...estudiosGabinete?.archivosRadios] : []
  if (laboratorios) {
    _archivos.push({
      ...laboratorios,
      type: "application/pdf"
    })
  }
  const [archivos] = useState(_archivos);


  const [propiedades, setPropiedades] = useState(
    comentarios
      ? comentarios
      : {
        evaluacion: "",
        restricciones: "",
        recomendaciones: "",
        nombreMedico: "",
        cp: "",
        ce: "",
        firma: "null",
      }
  );

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "evaluaciones",
      id,
      redirectOnPost: false,
    });

  const getDetallesDoctor = async () => {
    setLoadingMedico(true);
    let res = await Service.get("detalle-doctor?idDoctor=" + user.idUsuario + "&expand=creador");
    if (res?.resultado.length > 0) {
      setPropiedades({ ...propiedades, cp: res.resultado[0]?.cp, ce: res.resultado[0]?.cpe, nombreMedico: res.resultado[0]?.creador.nombreCompleto });
    }
    setLoadingMedico(false);
  }

  useEffect(() => {
    getDetallesDoctor();
  }, []);

  useEffect(() => {
    if (Object.keys(modelError)?.length > 0 && !updateModelLoading) {
      console.log(modelError)
      Object.keys(modelError).forEach(
        error => {
          console.log(modelError[error])
          showAlert({ message: modelError[error], severity: "warning" })
        }
      )
      setGuardado(false)
    }
  }, [modelError])

  const onGuardar = async () => {
    let body = propiedades;

    let prePropiedades = { ...propiedades };
    let falla = false;
    let valoresPropiedades = Object.values(prePropiedades);
    Object.keys(prePropiedades).forEach((prop) => {
      if (prop != "personalEnfermeria" || prop != "medico" || prop != "cp") {
        if (!falla) {
          if (valoresPropiedades[prop] == "") {
            falla = true;
          }
        }
      }
    });

    setDisabledByName("comentariosConclusiones", !falla);

    if (falla) {
      showAlert({
        message: "Todos los campos son requeridos",
        severity: "warning",
      });
      return;
    }

    let data = {
      idOrdenServicio,
      idEvaluacion,
      idSucursal: 1,
      ComentarioConclusiones: { ...body },
      estudiosGabinete: files,
      laboratorios: body,
    };
    await updateModel(data, true, "clinicaNuevaVida/nuevaConsulta");
    setGuardado(true);
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <strong>{"COMENTARIOS Y CONCLUSIONES"}</strong>
          </div>
          <Divider />
        </Grid>

        <Grid
          item
          md={12}
          style={{ display: "flex", flexWrap: "wrap", marginTop: "-15px" }}
        >
          <Grid item xs={12} md={3}>
            <TextField
              label="Evaluacion Ocupacional"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.evaluacion}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  evaluacion: e.target.value,
                }))
              }
            />
          </Grid>

          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Restricciones"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.restricciones}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  restricciones: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Recomendaciones / Comentarios"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.recomendaciones}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  recomendaciones: e.target.value,
                }))
              }
            />
          </Grid>
          {
            archivos.length > 0 || audio != null
              ?
              <div style={{ width: "100%", display: "flex", flexDirection: "column", }}>
                {
                  audio != null && (
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", borderBottom: "2px solid rgb(218, 208, 131)", padding: "5px", marginTop: "10px" }}>
                      <img
                        style={{
                          height: "100px",
                        }}
                        src={
                          "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Campanas%2Fdefaultpdf.png?alt=media&token=7e79fc7c-aec2-41ce-aa0c-e1f9c6e11745"
                        }
                        alt="imagen"
                      />
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        <p>Audiometria</p>
                        <IconButton onClick={e => {
                          window.open(`${baseUrlPublica}pdf/audiometriapdf?${"idEvaluacion=" + eva}`, '_blank');
                        }}>
                          <LaunchIcon />
                        </IconButton>
                      </div>
                    </div>
                  )
                }
                {
                  archivos.map(archivo => {
                    return (
                      <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", borderBottom: "2px solid rgb(218, 208, 131)", padding: "5px", marginTop: "10px" }}>
                        <img
                          style={{
                            height: "100px",
                          }}
                          src={
                            archivo.type === "application/pdf"
                              ?
                              "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Campanas%2Fdefaultpdf.png?alt=media&token=7e79fc7c-aec2-41ce-aa0c-e1f9c6e11745"
                              : archivo.urlArchivo
                          }
                          alt="imagen"
                        />
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                          <p>{archivo.archivo}</p>
                          <IconButton onClick={e => {
                            window.open(archivo.urlArchivo, '_blank');
                          }}>
                            <LaunchIcon />
                          </IconButton>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
              :
              <></>
          }

        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading ||
                  updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["evaluaciones"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<Save />}
              style={{ color: "#fff" }}
            >
              GUARDAR
              {/* {(!editing ? "GUARDAR" : "MODIFICAR")} */}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
