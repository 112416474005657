import React from "react";

const NotificationsContext = React.createContext();

// const defaultNotifications = () => [1, 2];

export function NotificationsProvider(props) {
  const [notifications, setNotifications] = React.useState(0);
  const [lading, setLading] = React.useState(false);
  const [conteo, setConteo] = React.useState(0);
  const [conteoCita, setConteoCita] = React.useState(0);
  const [notificationCita, setNotificationsCita] = React.useState(0);
  const [notificacionOrdenServicio, setNotificacionOrdenServicio] = React.useState(0);
  const [conteoOrdenServicio, setConteoOrdenServicio] = React.useState(0);
  // COMPONENTE SE MONTÓ
  React.useEffect(() => {}, []);

  const memData = React.useMemo(() => {
    // AQUI SE PONE TODO LO Q EL HOOK EXPORTA
    return { notifications, setNotifications,conteo ,setConteo, conteoCita, setConteoCita, notificationCita, setNotificationsCita, notificacionOrdenServicio, setNotificacionOrdenServicio, conteoOrdenServicio, setConteoOrdenServicio};
  }, [notifications, conteo, conteoCita, notificationCita, conteoOrdenServicio, notificacionOrdenServicio]);
  

  return <NotificationsContext.Provider value={memData} {...props} />;
}

export function useNotifications() {
  const context = React.useContext(NotificationsContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: notifications context not defined.";
  }
  return context;
}
