
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useMemo } from 'react'
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useModels } from "../../hooks/useModels";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import { FormControl, Grid, InputLabel, MenuItem, Select, Popover } from "@mui/material";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import moment from "moment-timezone";
import TextField from '@mui/material/TextField';
import DatePicker from "../../components/DatePickerBeta"
import { GetTotales, GetVerDetalles, GetVerMas } from './popover';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { baseStyles, formatCurrency, getWeeksOfMonth } from "../../utils";
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Search } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
import { baseUrl } from "../../utils/variables";

const useStyles = baseStyles();

const Listado = ({ changePos, ...props }) => {
  ////////////////////////////////////////////////////////////////////////
  //                                Vars                                //
  ////////////////////////////////////////////////////////////////////////
  const classes = useStyles();
  const history = useHistory();
  const [perPage, setPerPage] = useState(10);
  const [pagina, setPagina] = useState(1);
  const [admin] = useLocalStorage("admin", null);
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const { user, permiso } = useAuth();
  const [pos, setPos] = useState(0);

  const [propiedades, setPropiedades] = useState({
    empresa: "",
    fechaInicio: "",
    fechaFinal: "",
    operacion: "",
    paciente: "",
    comentario: "",
    mes: "",
    os: false,
    idSucursal: idSucursal,
  });

  ////////////////////////////////////////////////////////////////////////
  //                               models                               //
  ////////////////////////////////////////////////////////////////////////
  const parametrosInicialesMemo = useMemo(() => ({
    name: "entrada-inventario",
    expand: "movimientos.lote.sucursal,creador,sucursal",
    ordenar: "idEntradaInventario.desc",
    extraParams: { ...propiedades }
  }), []);

  const [models, modelsLoading, modelsError, modelsPage, refreshModels] = useModels({ ...parametrosInicialesMemo });

  ////////////////////////////////////////////////////////////////////////
  //                            Funcions Def                            //
  ////////////////////////////////////////////////////////////////////////
  const onSearchClicked = async eliminado => {
    let params = {};
    if (eliminado != null) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
      };
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage },
      };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async page => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };


  const changePageRow = async per => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  const getTotal = (_entrada) => {
    let total = _entrada?.movimientos?.reduce((a, s) => { return a + parseFloat(s.totalProveedor) }, 0);
    return "$ " + formatCurrency(total, 2);
  }

  const getCantidadTotal = (_entrada) => {
    let total = _entrada?.movimientos?.reduce((a, s) => { return a + s.cantidad; }, 0);
    return total;
  }

  const getVerMas = (_entrada) => {
    return <PopupState variant="popover" popupId="demo-popup-popover" >
      {(popupState) => (
        <div>
          <IconButton variant="contained" {...bindTrigger(popupState)}>
            <VisibilityIcon />
          </IconButton>
          <Popover {...bindPopover(popupState)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} transformOrigin={{ vertical: 'top', horizontal: 'center', }} >
            {
              _entrada?.movimientos?.map(lote => {
                return (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ width: '100%' }}>
                      <div style={{ background: '#1D5E7B ', padding: '5px 20px', color: '#FFF', textAlign: 'center' }}>{lote.lote.nombreProducto.toUpperCase()} </div>
                      <div style={{ textAlign: 'center' }}><strong> Cantidad : </strong>{lote.cantidad} <strong> Precio : </strong>${formatCurrency(lote.totalProveedor, 2)} </div>
                    </div>
                  </div>
                )
              })
            }
          </Popover>
        </div>
      )}
    </PopupState>
  }

  return (
    <>
      <TablePageLayout
        customCss={{ border: '3px solid #ebebeb', boxShadow: 'none', }}
        title="Listado"
        model="entrada-inventario"
        history={history}
        links={[]}
        SearchComponents={
          <>
            <Grid item xs={12} md={6}>
              <DatePicker
                labelText="Fecha Inicio"
                variant="standard"
                fullWidth
                typePicker="mobile"
                inputFormat="dd/MM/yyyy"
                size="medium"
                value={propiedades.fechaInicio != "" ? propiedades.fechaInicio : null}
                onChange={(e) => setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  fechaInicio: moment(e).format("YYYY-MM-DD H:m:s"),
                }))}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                labelText="Fecha Final"
                variant="standard"
                fullWidth
                typePicker="mobile"
                inputFormat="dd/MM/yyyy"
                size="medium"
                value={propiedades.fechaFinal != "" ? propiedades.fechaFinal : null}
                onChange={(e) => setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  fechaFinal: moment(e).format("YYYY-MM-DD H:m:s"),
                }))}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="operacionSelect">Operación</InputLabel>
                <Select
                  fullWidth
                  labelId="operacionSelect"
                  id="operacionSelectId"
                  label="Operación"
                  value={propiedades.operacion}
                  onChange={(e) => setPropiedades(prevState => ({
                    ...prevState,
                    ...prevState.propiedades,
                    operacion: e.target.value
                  }))}
                >
                  <MenuItem value=""> Todas </MenuItem>
                  <MenuItem value="Ingreso"> Ingreso </MenuItem>
                  <MenuItem value="Salida"> Salida </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Paciente"
                sx={{ width: "100%" }}
                variant="standard"
                value={propiedades.paciente}
                onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  paciente: e.target.value
                }))}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="standard"
                sx={{ width: "100%" }}
                label="Empresa"
                value={propiedades.empresa}
                onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  empresa: e.target.value
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                fullWidth
                onClick={() => onSearchClicked(null)}
                loading={modelsLoading && true}
                style={{ float: "right", color: "whitesmoke", width: "auto" }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>
          </>
        }


        //CAMBIAR LOS PDF
        DisableComponents={
          <>
            <Grid item xs={8} sx={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0px 20px" }}>
              <Grid item xs={4}>
                <DatePicker
                  labelText="Mes"
                  variant="standard"
                  fullWidth
                  typePicker="mobile"
                  inputFormat="dd/MM/yyyy"
                  size="medium"
                  value={propiedades.mes != "" ? propiedades.mes : null}
                  onChange={(e) => setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    mes: moment(e).format("MM-DD-YYYY H:m:s"),
                  }))}
                />
              </Grid>
              <IconButton disabled={(propiedades?.mes == "")} color="warning" aria-label="upload picture" component="span"
                onClick={e => {
                  window.open(`${baseUrl}excel/entradas-por-mes?
                  ${"&idSucursal=" + idSucursal}
                  ${"&semanas=" + JSON.stringify(getWeeksOfMonth(propiedades?.mes))}
              `, "_blank")
                }}
              >
                <RiFileExcel2Fill />
              </IconButton>
              EXCEL POR MES
            </Grid>
            <Grid item xs={4} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", }} >
              <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                <IconButton color="error" aria-label="upload picture" component="span">
                  <a
                    className={classes.btnPdf}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}pdf/entradas-inventarios?${"admin=" + (admin ? 1 : 0)}
                      ${"&uid=" + user?.idUsuario}
                      ${"idHistorialCaptura=" + propiedades.idHistorialCaptura}
                      ${"&operacion=" + propiedades.operacion}
                    `}
                  >
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center", }} >
                <IconButton color="warning" aria-label="upload picture" component="span">
                  <a
                    className={classes.btnExcel}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}excel/entradas-inventarios?${"admin=" + (admin ? 1 : 0)}
                      ${"&idSucursal=" + idSucursal}
                      ${"&uid=" + user?.idUsuario}
                      ${"&fecha=" + propiedades.fecha}
                      ${"&operacion=" + propiedades.operacion}
                    `}
                  >
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }

        customCssDisableButtons={{ justifyContent: "flex-end" }}
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <TablePagination
            nombrePermiso="inventarios"
            permiso={permiso}
            header={[]}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "operacion",
                name: "OPERACIÓN",
                cell: (row, index) => (

                  <p key={index}>
                    {row.operacion.toUpperCase()}
                  </p>
                )
              },

              {
                prop: "usuario",
                name: "USUARIO QUE REALIZO LA OPERACION",
                cell: (row, index) => (

                  <p key={index}>
                    {row?.creador?.nombreCompleto.toUpperCase()}
                  </p>
                )
              },
              {
                prop: "comentario",
                name: "COMENTARIO",
                cell: (row, index) => (
                  <p key={index}>{row.comentario?.toUpperCase()}</p>
                )
              },
              {
                prop: "getCantidadTotal",
                name: "CANTIDAD",
                cell: (row, index) => (
                  getCantidadTotal(row)
                )
              },
              {
                prop: "getTotal",
                name: "TOTAL",
                cell: (row, index) => (
                  getTotal(row)
                )
              },
              {
                prop: "getVerMas",
                name: "VER MAS",
                cell: (row, index) => (
                  getVerMas(row)
                )
              },
              {
                prop: "exportar",
                name: "EXPORTAR",
                cell: (row, index) => (
                  <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Grid item xs={1} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <IconButton color="error" aria-label="upload picture" component="span">
                        <a className={classes.btnPdf} target="_blank" rel="noreferrer" href={
                          `${baseUrl}pdf/entradas-inventarios-row?
                          ${"idEntradaInventario=" + row.idEntradaInventario}
                          ${"&idSucursal=" + idSucursal}`}>
                          <MdPictureAsPdf />
                        </a>
                      </IconButton>
                      <IconButton color="warning" aria-label="upload picture" component="span">
                        <a className={classes.btnExcel} target="_blank" rel="noreferrer" href={
                          `${baseUrl}excel/entradas-inventarios-row?
                          ${"idEntradaInventario=" + row.idEntradaInventario}
                          ${"&idSucursal=" + idSucursal}`}>
                          <RiFileExcel2Fill />
                        </a>
                      </IconButton>
                    </Grid>
                  </Grid>
                )
              }
            ]}
          />
        }
      />
    </>
  );
}

export default Listado;