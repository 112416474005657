/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useMemo, useCallback, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useModels } from "../../hooks/useModels";
import { NavLink } from "react-router-dom";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import {
    Grid,
    MenuItem,
    IconButton,
    FormControlLabel,
    Switch,
    FormControl,
    Select,
    InputLabel,
    Menu,
} from "@mui/material";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import Swal from "sweetalert2";
import moment from 'moment';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { baseStyles } from "../../utils";
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import PercentIcon from '@mui/icons-material/Percent';
import { Add, Delete, Edit, Search } from "@mui/icons-material";
import { MdPictureAsPdf } from "react-icons/md";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { RiFileExcel2Fill } from "react-icons/ri";

////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
import { baseUrl } from "../../utils/variables";
const useStyles = baseStyles();

const Comision = () => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const [perPage, setPerPage] = useState(10);
    const [openMenuRow, setOpenMenuRow] = useState(null);
    const [pagina, setPagina] = useState(1);
    const [admin] = useLocalStorage("admin", null);
    const { user, permiso } = useAuth();
    const parametrosInicialesMemo = useMemo(
        () => ({
            name: "comision",
            expand: "sucursal",
        }),
        []
    );
    const [
        models,
        modelsLoading,
        modelsError,
        modelsPage,
        refreshModels,
        deleteModel,
    ] = useModels({ ...parametrosInicialesMemo });

    const [propiedades, setPropiedades] = useState({
        comision: "",
        comentario: "",
        

    });
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const onDeleteClicked = async (mensaje, type, id) => {
        try {
            setOpenMenuRow(false);
            await Swal.fire({
                title: type,
                text: mensaje,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34AA44",
                cancelButtonColor: "#E72C33",
                confirmButtonText: "Si, deseo Continuar.",
            }).then((result) => {
                if (result.isConfirmed) {
                    let params = {};
                    params = {
                        ...parametrosInicialesMemo,
                        ...propiedades,
                        ...{ pagina: pagina, limite: perPage },
                    };
                    deleteModel(id, type, params);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const onSearchClicked = async eliminado => {
        let params = {};
        if (eliminado != null) {
            params = {
                ...parametrosInicialesMemo,
                ...propiedades,
                ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
            };
        } else {
            params = {
                ...parametrosInicialesMemo,
                ...propiedades,
                ...{ pagina: pagina, limite: perPage },
            };
        }
        await refreshModels({ isCargando: true, params });
    };

    const changePage = async page => {
        setPagina(page + 1);
        let params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: page + 1, limite: perPage },
        };
        await refreshModels({ isCargando: true, params });
    };

    const changePageRow = async per => {
        setPerPage(per);
        const params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ limite: per, pagina: pagina },
        };
        await refreshModels({ isCargando: true, params });
    };

    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////

    return (
        <>
            <TablePageLayout
                title="Comisiones"
                model="comision"
                history={history}
                actionButton={{
                    to: "/comision/nuevo",
                    icon: <Add />,
                    label: "Agregar",
                    permiso: "comisiones"
                }}
                links={[
                    {
                        label: "Comisiones",
                        to: "/comisiones",
                        icon: <PercentIcon fontSize="small" />
                    },
                ]}
                SearchComponents={
                    <>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="comisionSelect">Comision</InputLabel>
                                <Select
                                    labelId="comisionSelect"
                                    id="comisionSelectId"
                                    value={propiedades.comision}
                                    label="Comision"
                                    onChange={(e) =>
                                        setPropiedades((prevState) => ({
                                            ...prevState,
                                            ...prevState.propiedades,
                                            comision: e.target.value,
                                        }))
                                    }
                                >
                                    <MenuItem value={""}>Todos</MenuItem>
                                    <MenuItem value={"5"}>5%</MenuItem>
                                    <MenuItem value={"10"}>10%</MenuItem>
                                    <MenuItem value={"15"}>15%</MenuItem>
                                    <MenuItem value={"20"}>20%</MenuItem>
                                    <MenuItem value={"25"}>25%</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        
                        <Grid  xs={12} sm={3}> </Grid>
                        <Grid  xs={12} sm={3}> </Grid>
                        <Grid item xs={12} md={2} style={{ display: 'flex' }}>
                            <LoadingButton
                                variant="contained"
                                fullWidth
                                onClick={() => onSearchClicked(null)}
                                loading={modelsLoading && true}
                                style={{
                                    float: "right",
                                    color: "whitesmoke",
                                }}
                                startIcon={<Search />}
                            >
                                Buscar
                            </LoadingButton>
                        </Grid>
                       
                    </>
                }
                DisableComponents={
                    <>
                        <Grid item xs={8}>
                            <FormControlLabel
                                className={classes.switchColor}
                                control={
                                    <Switch
                                        disabled={modelsLoading && true}
                                        onChange={(e) => {
                                            setPropiedades((prevState) => ({
                                                ...prevState,
                                                ...prevState.propiedades,
                                                eliminado: e.target.checked ? 1 : 0,
                                            }));
                                            onSearchClicked(e.target.checked ? 1 : 0);
                                        }}
                                    />
                                }
                                label="Ver Deshabilitados"
                            />
                        </Grid>
                        <Grid item xs={4} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", }}>
                            <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <IconButton color="error" aria-label="upload picture" component="span">
                                    <a className={classes.btnPdf} target="_blank" rel="noreferrer"
                                        href={`${baseUrl}pdf/comisiones?${"admin=" + (admin ? 1 : 0)}
                            ${"&uid=" + user?.idUsuario}
                            ${"&creado=" + propiedades.creado}
                            ${"&comision=" + propiedades.comision}
                            ${"&comentario=" + propiedades.comentario}
                            ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`}>
                                        <MdPictureAsPdf />
                                    </a>
                                </IconButton>
                            </Grid>
                            <Grid item xs={2} style={{ isplay: "flex", flexDirection: "row", alignItems: "center", }} >
                                <IconButton color="warning" aria-label="upload picture" component="span">
                                    <a className={classes.btnExcel} target="_blank" rel="noreferrer" href={`${baseUrl}excel/comisiones?
                            ${"admin=" + (admin ? 1 : 0)}
                            ${"&uid=" + user?.idUsuario}
                            ${"&creado=" + propiedades.creado}
                            ${"&comision=" + propiedades.comision}
                            ${"&comentario=" + propiedades.comentario}
                            ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`}>
                                        <RiFileExcel2Fill />
                                    </a>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </>
                }
                tableLoading={modelsLoading}
                tableErrors={modelsError}
                TableComponent={
                    <TablePagination
                        nombrePermiso="comisiones"
                        permiso={permiso}
                        header={[]}
                        data={models}
                        paginatedApi
                        pagina={pagina}
                        paginationPageSize={perPage}
                        onRowsPerPageChangeApi={changePageRow}
                        changePageApi={changePage}
                        count={modelsPage !== null ? modelsPage.total : 0}
                        labelRowsPerPage={"Renglones por página"}
                        extraRows={[
                            {
                                prop: "creado",
                                name: "Fecha",
                                sortable: false,
                                cell: (row, index) => (
                                    <p key={index}>{moment(row.creado).format("DD/MM/yyy")}</p>
                                )
                            },
                            {
                                prop: "comision",
                                name: "Comision",
                                cell: (row, index) => (
                                    <p key={index}>{row.comision} %</p>
                                )
                            },
                            {
                                prop: "comentario",
                                name: "Comentario",
                                cell: (row, index) => (
                                    <p key={index}>{row.comentario}</p>
                                )
                            },
                            {
                                prop: "acciones",
                                name: "Acciones",
                                sortable: false,
                                cell: (row, index) => (permiso && (permiso[0]["comisiones"] & 4) === 4 || (permiso[0]["comisiones"] & 8) === 8) && (
                                    <div>
                                        <IconButton onClick={(event) => { setOpenMenuRow(index); setAnchorEl(event.currentTarget) }}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu anchorEl={anchorEl} keepMounted open={index === openMenuRow} onClose={() => setOpenMenuRow(null)}>
                                            {(admin === true || (permiso && permiso[0]["comisiones"] & 4) === 4) && (row.eliminado == null) && (
                                                <NavLink exact to={(`comision/editar/${row.idComision}`)} style={{ color: '#000', textDecoration: 'none' }}>
                                                    <MenuItem aria-label="editar"
                                                        onClick={() => {
                                                            history.push(`comision/editar/${row.idComision}`, { match: row.idComision });
                                                        }}>
                                                        <Edit style={{ paddingRight: 5 }} />
                                                        Editar
                                                    </MenuItem>
                                                </NavLink>
                                            )}
                                            {(admin === true || (permiso && permiso[0]["comisiones"] & 8) === 8) && (
                                                row?.eliminado === null ? (
                                                    <MenuItem
                                                        onClick={() => onDeleteClicked(`Desea Deshabilitar la Comision ${row.comision}`, "Deshabilitar", row.idComision)}
                                                    >
                                                        <Delete style={{ paddingRight: 5 }} />
                                                        Deshabilitar
                                                    </MenuItem>
                                                ) : (
                                                    <MenuItem
                                                        onClick={() => onDeleteClicked(`Desea Habilitar la Comision ${row.comision}`, "Habilitar", row.idComision)}
                                                    >
                                                        <RestoreFromTrashIcon style={{ paddingRight: 5 }} />
                                                        Habilitar
                                                    </MenuItem>
                                                )
                                            )}
                                        </Menu>
                                    </div>
                                ),
                            },
                        ]}
                    />
                }
            />
        </>
    );
}
export default Comision;