/* eslint-disable react-hooks/exhaustive-deps */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useModels } from '../../hooks/useModels';
import { NavLink } from 'react-router-dom';
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import {
    Grid,
    MenuItem,
    IconButton,
    FormControlLabel,
    Switch,
    FormControl,
    Select,
    InputLabel,
    Menu,
    Popover
} from '@mui/material';
import TablePageLayout from '../../components/TablePageLayout';
import TablePagination from '../../components/TablePagination';
import LoadingButton from '@mui/lab/LoadingButton';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AsyncAutocompleteSearchBeta from '../../components/AsyncAutocompleteSearchBeta';
import Swal from 'sweetalert2';
import moment from 'moment';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { baseStyles } from '../../utils';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Delete, Edit, Search } from '@mui/icons-material';
import { MdPictureAsPdf } from 'react-icons/md';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { RiFileExcel2Fill } from 'react-icons/ri';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
import { baseUrl } from '../../utils/variables';
const useStyles = baseStyles();


const Agenda = () => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const history = useHistory();
    const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const [doctor, setDoctor] = useState({});
    const [clearInputDoctor, setClearInputDoctor] = useState(false);
    const [perPage, setPerPage] = useState(10);

    const setDoctorCallback = useCallback((e, v) => {
        if (v !== "" && v) {
            setDoctor(v);
            setPropiedades({ ...propiedades, idDoctor: v.idUsuario });
        } else {
            setDoctor(null);
            setPropiedades({ ...propiedades, idDoctor: "" });
        }
    }, [setDoctor]);
    
    const doctorParamsMemo = useMemo(() => ({
        pagina: 1,
        limite: 20,
        idSucursal: idSucursal,
        ordenar: "nombreCompleto.desc"
    }), [idSucursal]);
    const [openMenuRow, setOpenMenuRow] = useState(null);
    const [pagina, setPagina] = useState(1);
    const [admin] = useLocalStorage('admin', null);
    const { user, permiso } = useAuth();
    const parametrosInicialesMemo = useMemo(
        () => ({
            name: 'agenda',
            expand: "doctor",
            idSucursal
        }),
        [idSucursal]
    );
    const [
        models,
        modelsLoading,
        modelsError,
        modelsPage,
        refreshModels,
        deleteModel,
    ] = useModels({ ...parametrosInicialesMemo });

    const sucursalesParametros = useMemo(() => ({
        name: "sucursal",
        ordenar: "nombreSucursal.asc",
      }), []);

    const [sucursales] = useModels({ ...sucursalesParametros });

    const [propiedades, setPropiedades] = useState({
        idDoctor: '',
        inicioAgenda: moment(new Date()).startOf('month').format('YYYY/MM/DD'),
        finAgenda: moment(new Date()).format('YYYY/MM/DD'),
    });

    const getTotales = row => {
        return (
          <PopupState variant="popover" popupId="demo-popup-popover" >
            {(popupState) => (
              <div>
                <IconButton variant="contained" {...bindTrigger(popupState)}>
                  <VisibilityIcon />
                </IconButton>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ width: '100%' }}>
                      <div style={{ background: '#1D5E7B ', padding: '5px 20px', color: '#FFF', textAlign: 'center' }}>Horario de Semana inicial</div>
                      <div style={{ textAlign: 'center' }}>{moment(row.inicioHoraSemana1).format('HH:mm')} a {moment(row.finHoraSemana1).format('HH:mm')}</div>
                    </div>
                    <div style={{ width: '100%' }}>
                      <div style={{ background: '#1D5E7B ', padding: '5px 20px', color: '#FFF', textAlign: 'center' }}>Horario de Semana final</div>
                      <div style={{ textAlign: 'center' }}>{moment(row.inicioHoraSemana2).format('HH:mm')} a {moment(row.finHoraSemana2).format('HH:mm')}</div>
                    </div>
                    <div style={{ width: '100%' }}>
                      <div style={{ background: '#1D5E7B ', padding: '5px 20px', color: '#FFF', textAlign: 'center' }}>Horario de Sabado inicial</div>
                      <div style={{ textAlign: 'center' }}>{moment(row.inicioHoraSabado1).format('HH:mm')} a {moment(row.finHoraSabado1).format('HH:mm')}</div>
                    </div>
                    <div style={{ width: '100%' }}>
                      <div style={{ background: '#1D5E7B ', padding: '5px 20px', color: '#FFF', textAlign: 'center' }}>Horario de Sabado final</div>
                      <div style={{ textAlign: 'center' }}>{moment(row.inicioHoraSabado2).format('HH:mm')} a {moment(row.finHoraSabado2).format('HH:mm')}</div>
                    </div>
                    <div style={{ width: '100%' }}>
                      <div style={{ background: '#1D5E7B ', padding: '5px 20px', color: '#FFF', textAlign: 'center' }}>Horario de Domingo inicial</div>
                      <div style={{ textAlign: 'center' }}>{moment(row.inicioHoraDomingo1).format('HH:mm')} a {moment(row.finHoraDomingo1).format('HH:mm')}</div>
                    </div>
                    <div style={{ width: '100%' }}>
                      <div style={{ background: '#1D5E7B ', padding: '5px 20px', color: '#FFF', textAlign: 'center' }}>Horario de Domingo final</div>
                      <div style={{ textAlign: 'center' }}>{moment(row.inicioHoraDomingo2).format('HH:mm')} a {moment(row.finHoraDomingo2).format('HH:mm')}</div>
                    </div>
                  </div>
    
                </Popover>
              </div>
            )}
          </PopupState>
        );
      }

    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const onDeleteClicked = async (mensaje, type, id) => {
        try {
            setOpenMenuRow(false);
            await Swal.fire({
                title: type,
                text: mensaje,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34AA44',
                cancelButtonColor: '#E72C33',
                confirmButtonText: 'Si, deseo Continuar.',
            }).then((result) => {
                if (result.isConfirmed) {
                    let params = {};
                    params = {
                        ...parametrosInicialesMemo,
                        ...propiedades,
                        ...{ pagina: pagina, limite: perPage },
                    };
                    deleteModel(id, type, params);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const onSearchClicked = async eliminado => {
        let params = {};
        if (eliminado != null) {
            params = {
                ...parametrosInicialesMemo,
                ...propiedades,
                ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
            };
        } else {
            params = {
                ...parametrosInicialesMemo,
                ...propiedades,
                ...{ pagina: pagina, limite: perPage },
            };
        }
        await refreshModels({ isCargando: true, params });
    };

    const changePage = async page => {
        setPagina(page + 1);
        let params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: page + 1, limite: perPage },
        };
        await refreshModels({ isCargando: true, params });
    };

    const changePageRow = async per => {
        setPerPage(per);
        const params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ limite: per, pagina: pagina },
        };
        await refreshModels({ isCargando: true, params });
    };
    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////

    return (
        <>
            <TablePageLayout
                title='Agenda'
                model='agenda'
                history={history}
                actionButton={{
                    to: '/agenda/nuevo',
                    icon: <Add />,
                    label: 'Agregar',
                    permiso: 'agendas'
                }}
                links={[
                    {
                        label: 'Agenda',
                        to: '/agendas',
                        icon: <DateRangeIcon fontSize='small' />
                    },
                ]}
                SearchComponents={
                    <>
                        <Grid item xs={12} sm={10}>
                            <AsyncAutocompleteSearchBeta  
                                variant="standard"
                                label="Doctores"
                                ruta="usuario/doctores"
                                labelProp="nombreCompleto"
                                extraParams={doctorParamsMemo}
                                clearInput={clearInputDoctor}
                                onChange={setDoctorCallback}
                                campoError="idUsuario"
                            />
                        </Grid>

                        {(admin === true) && (
                <>
                  <Grid item xs={12} sm={10}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="demo-simple-select-autowidth-label">Sucursal</InputLabel>
                      <Select
                        fullWidth
                        variant="standard"
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        label="Sucursales"
                        value={propiedades.idSucursal}
                        onChange={(e) => setPropiedades(prevState => ({
                          ...prevState,
                          ...prevState.propiedades,
                          idSucursal: e.target.value
                        }))}
                      >
                        <MenuItem value="">TODAS</MenuItem>
                        {sucursales && sucursales.map((item, index) => (
                          <MenuItem key={index} value={item.idSucursal} >{item.nombreSucursal.toUpperCase()}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
                        <Grid item xs={12} md={2} style={{ display: 'flex' }}>
                            <LoadingButton
                                variant='contained'
                                fullWidth
                                onClick={() => onSearchClicked(null)}
                                loading={modelsLoading && true}
                                style={{
                                    float: 'right',
                                    color: 'whitesmoke',
                                }}
                                startIcon={<Search />}
                            >
                                Buscar
                            </LoadingButton>
                        </Grid>
                    </>
                }
                DisableComponents={
                    <>
                        <Grid item xs={8}>
                            <FormControlLabel
                                className={classes.switchColor}
                                control={
                                    <Switch
                                        disabled={modelsLoading && true}
                                        onChange={(e) => {
                                            setPropiedades((prevState) => ({
                                                ...prevState,
                                                ...prevState.propiedades,
                                                eliminado: e.target.checked ? 1 : 0,
                                            }));
                                            onSearchClicked(e.target.checked ? 1 : 0);
                                        }}
                                    />
                                }
                                label='Ver Deshabilitados'
                            />
                        </Grid>
                        <Grid item xs={4} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', }}>
                            <Grid item xs={2} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                                <IconButton color='error' aria-label='upload picture' component='span'>
                                    <a className={classes.btnPdf} target='_blank' rel='noreferrer' href={`${baseUrl}pdf/agenda?
                                        ${'admin=' + (admin ? 1 : 0)}
                                        ${'&uid=' + user?.idUsuario}
                                        ${'&idDoctor=' + propiedades.idDoctor}
                                        ${"&fechaInicio=" + propiedades.fechaInicio}${"&fechaFinal=" + propiedades.fechaFinal}
                                        ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`}>
                                        <MdPictureAsPdf />
                                    </a>
                                </IconButton>
                            </Grid>
                            <Grid item xs={2} style={{ isplay: 'flex', flexDirection: 'row', alignItems: 'center', }} >
                                <IconButton color='warning' aria-label='upload picture' component='span'>
                                    <a className={classes.btnExcel} target='_blank' rel='noreferrer' href={`${baseUrl}excel/agenda?
                                        ${'admin=' + (admin ? 1 : 0)}
                                        ${'&uid=' + user?.idUsuario}
                                        ${'&idDoctor=' + propiedades.idDoctor}
                                        ${"&fechaInicio=" + propiedades.fechaInicio}${"&fechaFinal=" + propiedades.fechaFinal}
                                        ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`}>
                                        <RiFileExcel2Fill />
                                    </a>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </>
                }
                tableLoading={modelsLoading}
                tableErrors={modelsError}
                TableComponent={
                    <TablePagination
                        nombrePermiso='agendas'
                        permiso={permiso}
                        header={[]}
                        data={models}
                        paginatedApi
                        pagina={pagina}
                        paginationPageSize={perPage}
                        onRowsPerPageChangeApi={changePageRow}
                        changePageApi={changePage}
                        count={modelsPage !== null ? modelsPage.total : 0}
                        labelRowsPerPage={'Renglones por página'}
                        extraRows={[
                            {
                                prop: 'doctor',
                                name: 'Doctor',
                                sortable: false,
                                cell: (row, index) => (
                                    <p key={index}>{row.doctor.nombreCompleto}</p>
                                )
                            },
                            {
                                prop: 'horarios',
                                name: 'Horarios',
                                sortable: false,
                                cell: (row, index) => (
                                    getTotales(row)
                                )
                            },
                            {
                                prop: 'division',
                                name: 'Consultas De:',
                                sortable: false,
                                cell: (row, index) => (
                                    <p key={index}>{row.division} Min.</p>
                                )
                            },
                            {
                                prop: 'semana',
                                name: 'Dias Habiles',
                                sortable: false,
                                cell: (row, index) => (
                                    <p key={index}>{row.semana}</p>
                                )
                            },
                            {
                                prop: 'acciones',
                                name: 'Acciones',
                                sortable: false,
                                cell: (row, index) => (permiso && (permiso[0]['agendas'] & 4) === 4 || (permiso[0]['agendas'] & 8) === 8) && (
                                    <div>
                                        <IconButton onClick={(event) => { setOpenMenuRow(index); setAnchorEl(event.currentTarget) }}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu anchorEl={anchorEl} keepMounted open={index === openMenuRow} onClose={() => setOpenMenuRow(null)}>
                                            {(admin === true || (permiso && permiso[0]['agendas'] & 4) === 4) && (row.eliminado == null) && (
                                                <NavLink exact to={(`agenda/editar/${row.idAgenda}`)} style={{ color: '#000', textDecoration: 'none' }}>
                                                    <MenuItem aria-label='editar'
                                                        onClick={() => {
                                                            history.push(`agenda/editar/${row.idAgenda}`, { match: row.idAgenda });
                                                        }}>
                                                        <Edit style={{ paddingRight: 5 }} />
                                                        Editar
                                                    </MenuItem>
                                                </NavLink>
                                            )}
                                            {(admin === true || (permiso && permiso[0]['agendas'] & 8) === 8) && (
                                                row?.eliminado === null ? (
                                                    <MenuItem
                                                        onClick={() => onDeleteClicked(`Desea Deshabilitar el Agenda ${row.nombreConvenio}`, 'Deshabilitar', row.idAgenda)}
                                                    >
                                                        <Delete style={{ paddingRight: 5 }} />
                                                        Deshabilitar
                                                    </MenuItem>
                                                ) : (
                                                    <MenuItem
                                                        onClick={() => onDeleteClicked(`Desea Habilitar el Agenda ${row.nombreConvenio}`, 'Habilitar', row.idAgenda)}
                                                    >
                                                        <RestoreFromTrashIcon style={{ paddingRight: 5 }} />
                                                        Habilitar
                                                    </MenuItem>
                                                )
                                            )}
                                        </Menu>
                                    </div>
                                ),
                            },
                        ]}
                    />
                }
            />
        </>
    );
}
export default Agenda;