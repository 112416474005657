import React, { useState } from "react"
import { CircularProgress, Divider, Grid, IconButton, Paper, TextField } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { LoadingButton } from "@mui/lab"
import { Save } from "@mui/icons-material"
import { useModel } from "../../../../hooks/useModel"
import { useAuth } from "../../../../hooks/useAuth"
import { useLocalStorage } from "../../../../hooks/useLocalStorage"
import { useEffect } from "react"
import { useAlert } from "../../../../hooks/useAlert"
import { FaNotesMedical } from "react-icons/fa"
import { baseUrlPublica } from "../../../../utils/variables"
import { baseStyles } from "../../../../utils"
import Service from "../../../../services/api";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}))

const useBaseStyles = baseStyles()

export const RecetasComponent = ({ idEvaluacion, idOrdenServicio, receta, guardado, setGuardado, setDisabledByName }) => {
    const classes = useBaseStyles()
    const id = idEvaluacion
    const [admin] = useLocalStorage("admin", null)
    const [idSucursal] = useLocalStorage("idSucursal", null)
    const { showAlert } = useAlert()
    const { permiso } = useAuth()
    const [loading, setLoading] = useState(false)

    console.log(receta)

    const [propiedades, setPropiedades] = useState(
        receta
            ? receta
            : {
                contenido: "",
            }
    )

    const setReceta = async () => {
        setLoading(true)

        let res = await Service.get(
            "orden-servicio?idOrdenServicio=" +
            idOrdenServicio +
            "&expand=evaluacion.receta"
        );
        if (res?.resultado?.length > 0) {
            setPropiedades({ ...propiedades, ...res?.resultado[0]?.evaluacion?.receta });
        }
        setLoading(false);
    }

    const { model, modelLoading, modelError, updateModel, updateModelLoading } =
        useModel({
            name: "evaluaciones",
            id,
            redirectOnPost: false,
        })

    useEffect(() => {
        if (Object.keys(modelError)?.length > 0 && !updateModelLoading) {
            console.log(modelError)
            Object.keys(modelError).forEach(
                error => {
                    console.log(modelError[error])
                    showAlert({ message: modelError[error], severity: "warning" })
                }
            )
            setGuardado(false)
        } else if (Object.keys(modelError)?.length < 1 && !updateModelLoading && guardado) {
            setReceta()
        }
    }, [modelError])

    const onGuardar = async () => {
        const body = propiedades

        if (propiedades?.contenido == "" || propiedades?.contenido == undefined || propiedades?.contenido == null) {
            showAlert({
                message: "el contenido de la receta es requerido",
                severity: "warning"
            })
            return
        }

        let data = { idOrdenServicio, idEvaluacion, idSucursal: 2, Receta: body?.contenido }
        setDisabledByName("RecetasComponent", true)
        await updateModel(data, true, "hospitalito/nuevaConsulta")
        setGuardado(true)
    }

    return (
        <>
            <Grid
                container
                spacing={1}
                component={Paper}
                className={classes.paper}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12}>
                    <div style={{ textAlign: "center" }}>
                        <strong>{"RECETA"}</strong>
                    </div>
                    <Divider />
                </Grid>

                <Grid item xs={10} md={10} style={{ padding: "0px 5px" }}>
                    <TextField
                        label="Contenido"
                        variant="standard"
                        maxRows={5}
                        multiline
                        fullWidth
                        size="medium"
                        value={propiedades?.contenido}
                        onChange={(e) =>
                            setPropiedades((prevState) => ({
                                ...prevState,
                                ...prevState.propiedades,
                                contenido: e.target.value,
                            }))
                        }
                    />
                </Grid>
                <Grid item xs={2} md={2} style={{ padding: "0px 5px" }} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    {
                        !loading ?
                            <IconButton disabled={propiedades?.idReceta ? false : true} aria-label="upload picture" component="span">
                                <a target="_blank" rel="noreferrer" href={`${baseUrlPublica}pdf/receta?${"&id=" + propiedades?.idReceta}${"&folio=" + propiedades?.folio}`}>
                                    <FaNotesMedical style={{ color: propiedades?.idReceta ? '#1c5e7b' : '#e3e3e3' }} />
                                </a>
                            </IconButton>
                            :
                            <CircularProgress />
                    }
                </Grid>
            </Grid>

            <Grid
                container
                spacing={1}
                component={Paper}
                className={classes.paper}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                        <LoadingButton
                            disabled={
                                modelLoading ||
                                    updateModelLoading ||
                                    admin ||
                                    (permiso && permiso[0]["evaluaciones"] & 2) === 2
                                    ? false
                                    : true
                            }
                            loading={modelLoading || updateModelLoading}
                            variant="contained"
                            onClick={() => onGuardar()}
                            size="medium"
                            startIcon={<Save />}
                            style={{ color: "#fff" }}
                        >
                            GUARDAR
                            {/* {(!editing ? "GUARDAR" : "MODIFICAR")} */}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
