/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
//----------------------------------------------------------------------------------------------------------------------
//React hooks
import React, { useEffect, useState, useCallback } from "react";
import { useCacheBuster } from "../hooks/CacheBuster";
import { useAuth } from "../hooks/useAuth";
import { useLocalStorage } from "./../hooks/useLocalStorage";
import { useNotifications } from "../hooks/useNotifications";

//----------------------------------------------------------------------------------------------------------------------
//Components
import {
  Select, MenuItem, FormControl, InputLabel, Drawer, ListItem,
  ListItemIcon, ListItemText, ListItemButton, Divider, Collapse, List,
} from "@mui/material";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import InputRegex from "./../components/InputRegex";
import FullScreenLoader from "./../components/FullScreenLoader";
import { points } from "../utils/points";
import { navigatorStyle } from "../utils";
import { frontUrl } from "../utils/variables";
import Services from "./../services";
import { firestore } from "../firebase/firebaseConfig";

//----------------------------------------------------------------------------------------------------------------------
//Icons
import { Sync, LocationOn, Logout, ExpandMore, ExpandLess, MarkunreadMailbox, Home } from "@mui/icons-material";
import { BsJournalMedical, BsBellFill } from "react-icons/bs";
import { GiHospital } from "react-icons/gi";
import { FaClinicMedical } from "react-icons/fa";
import { MdAssessment } from "react-icons/md";

//----------------------------------------------------------------------------------------------------------------------
//Image
import logo from "../assets/img/c.png";

const urlObtenerSucursal = "sucursal";
const urlObtenerSucursales = "perfil/obtener-sucursales";
const urlObtenerPermisoSucursal = "perfil/obtener-permiso-sucursal";

const item = { py: "2px", px: 3, color: "rgba(255, 255, 255, 0.7)", "&:hover, &:focus": { bgcolor: "rgba(255, 255, 255, 0.08)", }, };
const itemCategory = { boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset", py: 1.5, px: 3 };

const navigatorStyleJSS = navigatorStyle();
export default function Navigator(props) {
  const navigatorJSS = navigatorStyleJSS();
  const { ...other } = props;
  const { signOut, user, permiso, setPermiso, setSucursal } = useAuth();
  const { refreshCacheAndReload } = useCacheBuster();
  const [loading, setLoading] = useState(false);
  const [permisoStorage, setPermisoStorage] = useLocalStorage("permiso", null);
  const [sucursalStorage, setSucursalStorage] = useLocalStorage("sucursal", null);
  const [sucursales, setSucursales] = useLocalStorage("sucursales", null);
  const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
  const [admin, setAdmin] = useLocalStorage("admin", null);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [openInventario, setOpenInventario] = useState([]);
  const { notifications, setNotifications, conteo, setConteo, conteoCita, setConteoCita, notificationCita, setNotificationsCita, notificationOrdenServicio, setNotificationOrdenServicio, conteoOrdenServicio, setConteoOrdenServicio } = useNotifications();
  const [pointsLinks, setPointsLinks] = useState(points.links);

  const notify = useCallback(async (total) => {
    const text = `${total === 1 ? "Nuevo Correo" : "Nuevos Correos"} de Promoción`;
    let notification = new Notification("Clinica Nueva Vida", { body: text, icon: logo });
    notification.onclick = async (e) => {
      await firestore.collection("alertas").doc("JBySo0vNelJRFIgYgy3r").set({ correoPromocion: 0, show: false }, { merge: true });
      setNotifications(0);
      window.open(frontUrl + "correoPromociones");
    };
  }, [conteo]);

  const notifyCita = useCallback(async (total) => {
    const text = `${total === 1 ? "Nueva Cita" : "Nuevas Citas"} en LHospitalito`;
    let notification = new Notification("Clinica Nueva Vida", { body: text, icon: logo, });
    notification.onclick = async (e) => {
      await firestore.collection("alertaCita").doc("g57jjxEM9vtcILpg3s5h").set({ cita: 0, show: false }, { merge: true });
      setNotificationsCita(0);
      window.open(frontUrl + "citas");
    };
  }, [conteoCita]);

  const notifyOrdenServicio = useCallback(async (total) => {
    const text = `${total === 1 ? "Nueva Orden Servicio" : "Nuevas Ordenes de Servicio"} en LHospitalito`;
    let notification = new Notification("Clinica Nueva Vida", { body: text, icon: logo, });
    notification.onclick = async (e) => {
      await firestore.collection("alertaOrdenServicio").doc("g57jjxEM9vtcILpg3s5h").set({ ordenServicio: 0, show: false }, { merge: true });
      setNotificationOrdenServicio(0);
      window.open(frontUrl + "ordenServicio");
    };
  }, [conteoOrdenServicio]);

  const d = useCallback(async () => {
    await firestore.collection("alertas").doc("JBySo0vNelJRFIgYgy3r")
      .onSnapshot(async (query) => {
        if (query.data().correoPromocion > 0) {
          setConteo(query.data().total);
          setNotifications(query.data().correoPromocion);
          if (!query.data().show) {
            notify(query.data().total);
            await firestore.collection("alertas").doc("JBySo0vNelJRFIgYgy3r")
              .set({ correoPromocion: query.data().correoPromocion, show: true }, { merge: true });
          }
        } else {
          setNotifications(0);
        }
      });
  }, [setNotifications, notify, setConteo]);

  const d2 = useCallback(async () => {
    await firestore.collection("alertaCita").doc("g57jjxEM9vtcILpg3s5h")
      .onSnapshot(async (query) => {
        if (query.data().cita > 0) {
          setConteoCita(query.data().total);
          setNotificationsCita(query.data().cita);
          if (!query.data().show) {
            notifyCita(query.data().total);
            await firestore.collection("alertaCita").doc("g57jjxEM9vtcILpg3s5h")
              .set({ cita: query.data().cita, show: true }, { merge: true });
          }
        } else {
          setNotificationsCita(0);
        }
      });
  }, [setNotificationsCita, notifyCita, setConteoCita]);

  const d3 = useCallback(async () => {
    await firestore.collection("alertaOrdenServicio").doc("agsdhjfghajsdg")
      .onSnapshot(async (query) => {
        if (!query.data().ordenServicio > 0) {
          setConteoOrdenServicio(query.data().total);
          setNotificationOrdenServicio(query.data().ordenServicio);
          if (!query.data().show) {
            notifyOrdenServicio(query.data().total);
            await firestore.collection("alertaOrdenServicio").doc("ahfdghja")
              .set({ ordenServicio: query.data().ordenServicio, show: true }, { merge: true });
          }
        } else {
          setNotificationOrdenServicio(0);
        }
      });
  }, [setNotificationOrdenServicio, notifyOrdenServicio, setConteoOrdenServicio]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickInventario = (id) => {
    let preOrdenInventario = [...openInventario];
    if (preOrdenInventario.includes(id)) {
      let posi;
      openInventario.map((e, index) => {
        if (e === id) {
          posi = index;
        }
      });
      preOrdenInventario.splice(posi, 1);
    } else {
      preOrdenInventario.push(id);
    }
    setOpenInventario(preOrdenInventario);
  };

  const onEditCountFirebase = async () => {
    await firestore.collection("alertas").doc("JBySo0vNelJRFIgYgy3r").set({ correoPromocion: 0, show: false }, { merge: true });
    setNotifications(0);
  };

  const onEditCountCitaFirebase = async () => {
    await firestore.collection("alertaCita").doc("g57jjxEM9vtcILpg3s5h").set({ cita: 0, show: false }, { merge: true });
    setNotificationsCita(0);
  };

  const guardarPermisos = useCallback(async (idSucursal, tipo) => {
    try {
      const response = await Services.get(urlObtenerPermisoSucursal + "?idSucursal=" + idSucursal);
      setPermiso(response.resultado);
      setPermisoStorage(response.resultado);
      if (tipo == "2") {
        history.push("/inicio");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [setPermiso, history]);

  const obtenerSucursal = useCallback(async (idSucursal, tipo) => {
    try {
      const response = await Services.get(urlObtenerSucursal + "?idSucursal=" + idSucursal + "&eliminado=0");
      setSucursal(response.resultado[0]);
      setSucursalStorage(response.resultado[0]);
      guardarPermisos(idSucursal, tipo);
    } catch (error) {
      console.error(error);
    }
  }, [setSucursal, setIdSucursal, guardarPermisos]);

  const obtenerPrimeraSucursal = useCallback(async (ad) => {
    try {
      const response = await Services.get(urlObtenerSucursales + "?eliminado=0");
      setSucursales(response.resultado);
      if (!ad) setIdSucursal(response.resultado[0].idSucursal);
      obtenerSucursal(response.resultado[0].idSucursal, "1");
    } catch (error) {
      console.error(error);
    }
  }, [sucursales]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (permiso) {
        if (admin || permiso[0]["correosPromociones"] > 0) {
          Notification.requestPermission();
          d();
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [permiso]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (permiso) {
        if (admin || permiso[0]["citas"] > 0) {
          Notification.requestPermission();
          d2();
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [permiso]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (permiso) {
        if (admin || permiso[0]["ordenServicio"] > 0) {
          Notification.requestPermission();
          d3();
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [permiso]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoading(true);
      if (admin == null) {
        if (user && !user?.admin) {
          obtenerPrimeraSucursal(false);
          setAdmin(false);
        }
        if (user && user?.admin) {
          obtenerPrimeraSucursal(true);
          setAdmin(true);
        }
      } else {
        setSucursal(sucursalStorage);
        setPermiso(permisoStorage);
        setLoading(false);
      }
    }
    return () => (mounted = false);
  }, [user]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (filter != "") {
        setPointsLinks(points.links.filter(p => p.title.toUpperCase().includes(filter.toUpperCase())));
      } else {
        setPointsLinks(points.links);
      }
    }
    return () => (mounted = false);
  }, [filter]);

  if (loading) return <FullScreenLoader color2="#1D5E7B" />;
  return (
    <Drawer className={navigatorJSS.whitoutScroll} variant="permanent" {...other}>
      <List disablePadding>
        <NavLink to="/inicio" style={{ textDecoration: "none" }}>
          <ListItem style={{ boxShadow: "none" }} sx={{ ...item, ...itemCategory }}>
            <ListItemText>Panel de Control</ListItemText>
          </ListItem>
        </NavLink>
        <Divider textAlign="left" sx={{ width: "100%", height: "3px", }} />
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText style={{ color: "white" }}>
              Módulos
              <InputRegex
                variant="standard"
                fullWidth
                size="medium"
                typeInput="text"
                value={filter}
                style={{ color: "white" }}
                onChange={(e) => setFilter(e.target.value)}
              />
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider textAlign="left" sx={{ width: "40%", height: "1px", bgcolor: "#DAD083", }} />
        {(permiso && permiso[0]["cliente"] & 1) <= 0 && (
          <NavLink exact className={(isActive) => !isActive ? navigatorJSS.actiNo : navigatorJSS.acti} to="/inicio" style={{ textDecoration: "none" }} >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Home style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText style={{ color: "white" }}>Inicio</ListItemText>
              </ListItemButton>
            </ListItem>
          </NavLink>
        )}

        {!admin && (permiso && permiso[0]["cliente"] & 1) <= 0 && (
          <ListItemButton onClick={handleClick} style={{ color: "white" }}>
            <ListItemIcon>
              <LocationOn />
            </ListItemIcon>
            <ListItemText primary="Sucursal" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        )}

        <Collapse in={open} timeout="auto" unmountOnExit>
          {!admin && (
            <ListItem style={{ backgroundColor: "white" }}>
              <FormControl variant="outlined" size="small" fullWidth >
                <InputLabel>Sucursales</InputLabel>
                <Select
                  value={idSucursal}
                  label="Sucursales"
                  style={{ textAlign: "left", fontSize: "14px" }}
                  onChange={(e) => {
                    setLoading(true);
                    setIdSucursal(e.target.value);
                    obtenerSucursal(e.target.value, "2");
                  }}
                >
                  {sucursales != null ? sucursales.map((opt, index) => (
                    <MenuItem key={index} value={opt.idSucursal}>
                      {opt.nombreSucursal}
                    </MenuItem>
                  )) : []}
                </Select>
              </FormControl>
            </ListItem>
          )}
        </Collapse>
        {pointsLinks.sort(({ title: a }, { title: b }) => a.localeCompare(b)).map((point, index) => {
          return point.type === "NavLink" ? (admin === true || (permiso && permiso[0][point.permission] & 1) === 1) && (
            <NavLink key={index} exact className={(isActive) => !isActive ? navigatorJSS.actiNo : navigatorJSS.acti} to={point.to} style={{ textDecoration: "none" }} >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>{point.icon}</ListItemIcon>
                  <ListItemText style={{ color: "white" }}>
                    {point.title}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>
          ) : point.type === "Collapse" ? (admin === true || (permiso && permiso[0][point.permission] & 1) === 1) && (
            <div key={index}>
              <ListItemButton key={"A" + index} onClick={e=>{handleClickInventario(point.id)}} style={{ color: "white" }} >
                <ListItemIcon>{point.icon}</ListItemIcon>
                <ListItemText primary={point.title} />
                {openInventario.includes(point.id) ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse key={"B" + index} in={openInventario.includes(point.id)} timeout="auto" unmountOnExit>
                {point.items.map((item, index) => {
                  return ((admin === true || (permiso && permiso[0][point.permission] & 1) === 1) && (
                    <ListItem key={"C" + index + index} style={{ backgroundColor: "white" }} disablePadding >
                      <NavLink exact className={(isActive) => !isActive ? navigatorJSS.actiNo : navigatorJSS.acti} to={item.to} style={{ textDecoration: "none", width: "100%", }} >
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText style={{ color: "#1D5E7B" }} >
                              {item.title}
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </NavLink>
                    </ListItem>
                  ));
                })}
              </Collapse>
            </div>
          ) : point.type === "Correo" ? ((admin === true || (permiso && permiso[0][point.permission] & 1) === 1) && (
            <div key={"R" + index}>
              <NavLink exact className={(isActive) => !isActive ? navigatorJSS.actiNo : navigatorJSS.acti} onClick={() => onEditCountFirebase()} to={point.to} style={{ textDecoration: "none" }} >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <MarkunreadMailbox style={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText style={{ color: "white" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {point.title}
                        <div style={{ position: "relative", display: "flex", marginLeft: "13px", flexDirection: "column", alignContent: "center", alignItems: "center" }}>
                          <BsBellFill style={{ fontSize: "1.5rem" }} />
                          <strong style={{ color: "red", position: "absolute" }}>
                            {notifications > 9 ? "9+" : notifications != 0 && notifications}
                          </strong>
                        </div>
                      </div>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </div>
          )) : point.type === "Cita" ? ((admin === true || (permiso && permiso[0][point.permission] & 1) === 1) && (
            <div key={"T" + index}>
              <NavLink exact className={(isActive) => !isActive ? navigatorJSS.actiNo : navigatorJSS.acti} onClick={() => onEditCountCitaFirebase()} to={point.to} style={{ textDecoration: "none" }} >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <BsJournalMedical style={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText style={{ color: "white" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {point.title}
                        <div style={{ position: "relative", display: "flex", marginLeft: "13px", flexDirection: "column", alignContent: "center", alignItems: "center", }} >
                          <BsBellFill style={{ fontSize: "1.5rem" }} />
                          <strong style={{ color: "red", position: "absolute" }}>
                            {notificationCita > 9 ? "9+" : notificationCita != 0 && notificationCita}
                          </strong>
                        </div>
                      </div>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </div>
          )) : point.type === "OrdenServicio" ? ((admin === true || (permiso && permiso[0][point.permission] & 1) === 1) && (
            <div key={"Y" + index}>
              <NavLink exact className={(isActive) => !isActive ? navigatorJSS.actiNo : navigatorJSS.acti} onClick={() => onEditCountCitaFirebase()} to={point.to} style={{ textDecoration: "none" }} >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <BsJournalMedical style={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText style={{ color: "white" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {point.title}
                        <div style={{ position: "relative", display: "flex", marginLeft: "13px", flexDirection: "column", alignContent: "center", alignItems: "center", }} >
                          <BsBellFill style={{ fontSize: "1.5rem" }} />
                          <strong style={{ color: "red", position: "absolute" }}>
                            {notificationOrdenServicio > 9 ? "9+" : notificationOrdenServicio != 0 && notificationOrdenServicio}
                          </strong>
                        </div>
                      </div>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </div>
          )) : null
        })}
        <ListItem disablePadding>
          <ListItemButton onClick={refreshCacheAndReload}>
            <ListItemIcon>
              <Sync style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText style={{ color: "white" }}>Actualizar</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={signOut}>
            <ListItemIcon>
              <Logout style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText style={{ color: "white" }}>
              Cerrar Sesión
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
