import React from "react";

const AlertContext = React.createContext();

export function AlertProvider(props) {

  const [alertas, setAlertas] = React.useState([]);

  React.useEffect(() => {
    if(alertas.length > 0){
      if(!alertas.some(e => e.open === true)){
        setAlertas([]);
      }
    }
  }, [alertas]);

  const eliminarAlerta = async (rand,time) => {
    setTimeout(() => {
      let alertasCopy = alertas;
      alertasCopy.map(alerta => {
        if (alerta.pos == rand) {
          alerta.open = false;
        }
      });
      setAlertas([...alertasCopy]);
    }, time);
  }

  const showAlert = React.useCallback(
    ({ message, severity = "info", position = null, time = 4000 }) => {
      let rand = Math.random(1, 23444);
      let alertasCopy = alertas;
      alertasCopy.push({
        message: message,
        severity: severity,
        pos: rand,
        open: true,
      });
      eliminarAlerta(rand, time);
      setAlertas([...alertasCopy]);
    },
    []
  );

  const memData = React.useMemo(() => {
    // const closeAlert = () => {
    //   setOpen(false);
    //   setTimeout(() => {
    //     setPosition(defaultPlace);
    //     setSeverity(defaultColor);
    //     setIcon(defaultIcon);
    //     setMessage(defaultMessage);
    //   }, 2000);
    // };
    return {
      alertas,
      showAlert,
      // closeAlert,
    };
  }, [alertas, showAlert]);

  return <AlertContext.Provider value={memData} {...props} />;
}

export function useAlert() {
  const context = React.useContext(AlertContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: alert context not defined.";
  }
  return context;
}
