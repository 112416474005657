/* eslint-disable no-unused-vars */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocalStorage } from "../../../hooks/useLocalStorage";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import {
    Grid,
    Button,
    Hidden,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import AsyncAutocompleteSearchRework from '../../../components/AsyncAutocompleteSearchRework';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BlockIcon from '@mui/icons-material/Block';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////

const Step1 = ({
    nextStep,
    setProgress,
    ...props
}) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const [clearServicio, setClearServicio] = useState(false);
    const [clearConsultorio, setClearConsultorio] = useState(false);
    const [sucursal, setSucursal] = useState("");
    const [sig, setSig] = useState(false);
    const [servicio, setServicio] = useState(null);
    const [agendas, setAgendas] = useLocalStorage("agendas", {});
    const [consultorio, setConsultorio] = useState(null);
    const paramsMemoServicio = useMemo(
        () => ({
            ordenar: "concepto.asc",
            idSucursal: sucursal ? sucursal : null,
            expand: "servicioDoctores.doctor.agendas"
        }),
        [sucursal]
    );

    const paramsMemoConsultorio = useMemo(
        () => ({
            ordenar: "consultorio.asc",
            idSucursal: sucursal ? sucursal : null,
        }),
        [sucursal]
    );
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        let progress = 0;
        if (servicio) {
            progress += 33;
        }
        if (sucursal) {
            progress += 33;
        }
        if (consultorio) {
            progress += 34;
        }
        if (progress == 100) {
            setSig(true);
        } else {
            setSig(false);
        }
        setProgress(progress, 0);
    }, [servicio, sucursal, setProgress, consultorio]);

    useEffect(() => {
        if (Object.keys(agendas).length > 0) {
            setSucursal(agendas.sucursal);
            setServicio(agendas.servicio);
        }
    }, [agendas]);

    const setidServicioCallback = useCallback(
        (e, v) => {
            if (v !== "" && v) {
                setServicio(v);
            } else {
                setServicio("");
            }
        },
        [setServicio]
    );

    const setidConsultorioCallback = useCallback(
        (e, v) => {
            if (v !== "" && v) {
                setConsultorio(v);
            } else {
                setConsultorio("");
            }
        },
        [setConsultorio]
    );
    return (
        <>
            <Grid container spacing={3}>
                <Hidden mdDown>
                    <Grid item xs={12} md={3}></Grid>
                </Hidden>
                <Grid item xs={12} md={6} style={{ padding: '30px 0 0 20px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="Sucursal-Label">Sucursal a la que se asistira</InputLabel>
                                <Select
                                    labelId="Sucursal-Label"
                                    id="Sucursal-Id"
                                    value={sucursal}
                                    label="Sucursal"
                                    onChange={e => { setSucursal(e.target.value) }}
                                >
                                    <MenuItem value={""}>Seleccione Sucursal</MenuItem>
                                    <MenuItem value={1}>Clínica Nueva Vida</MenuItem>
                                    <MenuItem value={2}>LHospitalito</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <AsyncAutocompleteSearchRework
                                style={{ margin: '10px 0' }}
                                defaultValue={consultorio ?? null}
                                disabled={!sucursal}
                                label="Consultorio"
                                labelProp="consultorio"
                                name="publico/agenda"
                                onChange={setidConsultorioCallback}
                                clearInput={clearConsultorio}
                                extraParams={paramsMemoConsultorio}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AsyncAutocompleteSearchRework
                                style={{ margin: '10px 0' }}
                                defaultValue={servicio ?? null}
                                disabled={!sucursal}
                                label="Servicios a citar"
                                labelProp="concepto"
                                name="publico/servicios"
                                onChange={setidServicioCallback}
                                clearInput={clearServicio}
                                extraParams={paramsMemoServicio}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', margin: '10px 0' }}>
                                <Button
                                    onClick={e => {
                                        nextStep(1);
                                        setAgendas({ ...agendas, sucursal, servicio, consultorio });
                                    }}
                                    disabled={!sig} style={{ background: !sig ? '#FFF' : '#155480', color: sig ? '#FFF' : '#155480', width: '48%', transition: 'background 1s', border: sig ? 'none' : '2px solid #155480' }}>
                                    {sig ? <>Siguiente<NavigateNextIcon style={{ margin: '0 0 1px 10px' }} /></> : <>Siguiente<BlockIcon style={{ margin: '0 0 1px 10px' }} /></>}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden mdDown>
                    <Grid item xs={12} md={3}></Grid>
                </Hidden>
            </Grid>
        </>
    );
}
export default Step1;