import { useState } from 'react';
import { Grid, Accordion, AccordionSummary, Typography, AccordionDetails, CircularProgress } from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Service from "../services/api";
import { useMemo } from 'react';
import { useLocalStorage } from "./../hooks/useLocalStorage";
import { useAuth } from "../hooks/useAuth";
import { Button } from 'rsuite';
import StartIcon from '@mui/icons-material/Start';

function DoctoresComponent() {

    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const { user, signOut } = useAuth();
    const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
    const [loading, setLoading] = useState(true);

    const filtrarOrdenServicio = (datos) => {
        if (datos) {
            let datosFiltrados = [];
            datos.forEach(dato => {
                if (!datosFiltrados.some(d => {
                    return d.idOrdenServicio == dato.idOrdenServicio;
                })) {
                    datosFiltrados.push(dato.ordenServicio);
                }
            });
            return datosFiltrados;
        }
    }

    useMemo(async () => {
        if (open) {
            let res = await Service.get("orden-servicio-doctores?idSucursal=" + idSucursal + "&idUsuario=" + user.idUsuario + "&expand=ordenServicio.detallePaciente");
            setLoading(false);
            setData(filtrarOrdenServicio(res.resultado));
        }
    }, [open]);

    return (
        <>
            <div style={{
                position: "fixed",
                float: "right",
                bottom: "0",
                width: "50%",
                right: "0",
                height: open ? "80%" : "0%",
                background: "white",
                minHeight: '30px',
                borderTopLeftRadius: "5px",
                boxShadow: "0 0 15px #00000040",
                transition: "all .5s ease-out",
                overflowY: "scroll"
            }}>
                {/* <div style={{
                    position: "absolute",
                    right: "9px",
                    top: "-20px",
                    background: "red",
                    width: "30px",
                    height: "30px",
                    borderTopLeftRadius: "100%",
                    borderTopRightRadius: "100%",
                    borderBottomRightRadius: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                }}>!</div> */}
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer" }} onClick={e => { setOpen(open ? false : true); if (!loading) { setLoading(true) } }}>
                        {
                            open
                                ?
                                <ExpandMoreIcon sx={{ fontSize: "30px", color: "#1d5e7b" }} />
                                :
                                <ExpandLessIcon sx={{ fontSize: "30px", color: "#1d5e7b" }} />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {
                            open && (
                                loading
                                    ?
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "70px",
                                        alignItems: "center",
                                        
                                    }}><CircularProgress /></div>
                                    :
                                    (data && data.length > 0)
                                        ?
                                        data.map(os => {
                                            return (
                                                (os.status == "PROCESO") && (
                                                    <Accordion>
                                                        <AccordionSummary
                                                            sx={{ borderBottom: "2px solid #dad083" }}
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={os.idOrdenServicio + "panel-content"}
                                                            id={os.idOrdenServicio}
                                                        >
                                                            <Typography sx={{ color: "#666666" }}><strong>ORDEN DE SERVICIO:</strong> {os.idOrdenServicio} <strong>PACIENTE:</strong> {os.detallePaciente.nombreCompleto}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails sx={{ boxShadow: "none" }}>
                                                            <Typography sx={{ color: "#666666" }}><strong>FECHA DE SOLICITUD:</strong> {os.fechaSolicitud}</Typography>
                                                            <Typography sx={{ color: "#666666" }}><strong>MOTIVO CONSULTA:</strong> {os.observaciones}</Typography>
                                                            <Typography sx={{ color: "#666666" }}><strong>ESTATUS:</strong> <p style={{ color: os.status == "PROCESO" ? "green" : "#666666", display: "contents", fontWeight: "600" }}>{os.status}</p></Typography>
                                                            <Button style={{
                                                                display: "flex",
                                                                color: "white",
                                                                alignItems: "center",
                                                                background: "#1d5e7b",
                                                            }}
                                                                onClick={e => {
                                                                    if (os.idSucursal == 1) {
                                                                        window.location = "/clinicaNuevaVida/nuevaConsulta/" + os.idOrdenServicio
                                                                    }
                                                                    if (os.idSucursal == 2) {
                                                                        window.location = "/hospitalito/nuevaConsulta/" + os.idOrdenServicio
                                                                    }
                                                                }}
                                                            >EVALUAR <StartIcon /></Button>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            );
                                        })
                                        :
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            margin: "70px",
                                            alignItems: "center",
                                        }}>NO HAY ORDENES DE SERVICIO EN PROCESO
                                        </div>

                            )
                        }

                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default DoctoresComponent;