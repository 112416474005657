import React, { useState } from "react";
import { Divider, Grid, Paper, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { useAuth } from "../../../../hooks/useAuth";
import { useAlert } from "../../../../hooks/useAlert";
import { useModel } from "../../../../hooks/useModel";
import Swal from "sweetalert2";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
export const AntecedentesPersonalesComponent = ({
  idEvaluacion,
  idOrdenServicio,
  antecedentesPersonales,
  setDisabledByName,
  setGuardado,
  ...props
}) => {
  const classes = useStyles();
  const id = idEvaluacion;
  const [admin] = useLocalStorage("admin", null);
  const { showAlert } = useAlert();

  const [idSucursal] = useLocalStorage("idSucursal", null);
  const { permiso } = useAuth();
  const [propiedades, setPropiedades] = useState(
    antecedentesPersonales
      ? antecedentesPersonales
      : {
        familiares:
          "Diabetes (-), Hipertensión (-), Oncológicos (-), Asma (-), Reumáticos (-) ",
        patologicos:
          "Enfermedades cronicodegenerativas (-), Rinitis (-), Asma (-), Alergias a medicamentos (-);  Hemotransfusiones (-); Quirúrgicos (-), Fracturas (-); Medicamentos (-); Vacunas de covid (+)",
        noPatologicos:
          "Tabaquismo: (-); Alcoholismo (-); Drogas (-); Deportes/Actividades extra laborales (-)",
        ginecoObestetricos: "",
      }
  );

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "evaluaciones",
      id,
      redirectOnPost: false,
    });

  useEffect(() => {
    if (Object.keys(modelError)?.length > 0 && !updateModelLoading) {
      console.log(modelError)
      Object.keys(modelError).forEach(
        error => {
          console.log(modelError[error])
          showAlert({ message: modelError[error], severity: "warning" })
        }
      )
      setGuardado(false)
    }
  }, [modelError])

  const onGuardar = async () => {
    const body = propiedades;

    let prePropiedades = { ...propiedades };
    let falla = false;
    let valoresPropiedades = Object.values(prePropiedades);
    Object.keys(prePropiedades).forEach((prop) => {
      if (prop != "personalEnfermeria" || prop != "medico" || prop != "cp") {
        if (!falla) {
          if (valoresPropiedades[prop] == "") {
            falla = true;
          }
        }
      }
    });

    setDisabledByName("antecedentesPersonales", !falla);

    if (falla) {
      showAlert({
        message: "Todos los campos son requeridos",
        severity: "warning",
      });
      return;
    }

    let data = {
      idOrdenServicio,
      idEvaluacion,
      idSucursal: 1,
      AntecedentesPersonales: body,
    };
    updateModel(data, true, "clinicaNuevaVida/nuevaConsulta");
    setGuardado(true);
  };
  return (
    <>
      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <strong>{"ANTECEDENTES PERSONALES"}</strong>
          </div>
          <Divider />
        </Grid>
        <Grid
          item
          md={12}
          style={{ display: "flex", flexWrap: "wrap", marginTop: "-15px" }}
        >
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Antecedentes Heredo Familiares"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.familiares}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  familiares: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Antecedentes Personales No Patológicos"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.noPatologicos}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  noPatologicos: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Antecedentes Personales Patológicos"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.patologicos}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  patologicos: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Antecedentes Gineco Obestétricos"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.ginecoObestetricos}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  ginecoObestetricos: e.target.value,
                }))
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading ||
                  updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["evaluaciones"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<Save />}
              style={{ color: "#fff" }}
            >
              GUARDAR
              {/* {(!editing ? "GUARDAR" : "MODIFICAR")} */}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
