import firebase from "firebase/app";
import { PDFDocument } from "pdf-lib";
import ImagePDF from "../pdf/ImagePDF.js";
import Clinica from "../pdf/Clinica";
import Clinica1 from "../pdf/Clinica1";
import Clinica2 from "../pdf/Clinica2";
import DatosPdf from "../pdf/DatosPdf";
import moment from "moment";
class FirebaseService {
  async getCollection(collection = "") {
    return await firebase.firestore().collection(collection).get();
  }
  async getCollectionWhere(
    collection = "",
    where = "",
    codintion = "",
    value = ""
  ) {
    return await firebase
      .firestore()
      .collection(collection)
      .where(where, codintion, value)
      .get();
  }
  async getDocById(collection = "", id = "") {
    return await firebase.firestore().collection(collection).doc(id).get();
  }
  async createDoc(collection = "", data = {}) {
    return await firebase.firestore().collection(collection).doc().set(data);
  }
  async deleteFile(path, fileName) {
    const ref = firebase.storage().ref(path);
    const childRef = ref.child(fileName);
    childRef.delete();
  }
  filterFiles(files) {
    let filesProps = [];
    let newFiles = [];
    files.forEach((file) => {
      if (file.tipo == "Evaluacion" || file.tipo == "Laboratorio") {
        filesProps.push({
          ...file,
          file: file.url,
        });
        newFiles.push(file.url);
      } else {
        let storage = firebase.storage();
        let pathReference = storage.ref();
        filesProps.push({
          ...file,
          file: pathReference.child(file.url).getDownloadURL(),
        });
        newFiles.push(pathReference.child(file.url).getDownloadURL());
      }
    });
    return { filesProps, newFiles };
  }

  async getPdfFromFiles(files) {
    let { newFiles, filesProps } = this.filterFiles(files);

    //START PROMISE
    let values = await Promise.all(newFiles);
    let responses = await Promise.all(
      values.map(async (val, i) => {
        return fetch(val);
      })
    );
    let blobs = await Promise.all(
      responses.map((response) => {
        return response.blob();
      })
    );
    let arrayBuffers = await Promise.all(
      blobs.map(async (blob) => {
        return blob.arrayBuffer();
      })
    );
    filesProps.map((file, i) => {
      file.arrayBuffer = arrayBuffers[i];
    });
    //END PROMISE

    // FILTER TYPES
    let buffersRadios = filesProps.filter((file) => {
      return file.tipo == "Radio";
    });
    let buffersLaboratorio = filesProps.filter((file) => {
      return file.tipo == "Laboratorio";
    });
    let buffersEvaluacion = filesProps.filter((file) => {
      return file.tipo == "Evaluacion";
    });
    // END FILTER TYPES

    // CREATE PDF
    const doc = await PDFDocument.create();
    // END CREATE PDF
    // ADD PAGES EVALUACION
    if (buffersEvaluacion.length > 2) {
      let pdf = await Clinica1(buffersEvaluacion[0].row);
      pdf = await pdf.arrayBuffer();
      const documentoCargar = await PDFDocument.load(pdf);
      const contentPages = await doc.copyPages(
        documentoCargar,
        documentoCargar.getPageIndices()
      );
      for (const page of contentPages) {
        doc.addPage(page);
      }

      let pdf2 = await DatosPdf(buffersEvaluacion[1].row);
      pdf2 = await pdf2.arrayBuffer();
      const documentoCargar2 = await PDFDocument.load(pdf2);
      const contentPages2 = await doc.copyPages(
        documentoCargar2,
        documentoCargar2.getPageIndices()
      );
      for (const page2 of contentPages2) {
        doc.addPage(page2);
      }

      let pdf3 = await Clinica2(buffersEvaluacion[2].row);
      pdf3 = await pdf3.arrayBuffer();
      const documentoCargar3 = await PDFDocument.load(pdf3);
      const contentPages3 = await doc.copyPages(
        documentoCargar3,
        documentoCargar3.getPageIndices()
      );
      for (const page3 of contentPages3) {
        doc.addPage(page3);
      }
    } else {
      let pdf = await Clinica1(buffersEvaluacion[0].row);
      pdf = await pdf.arrayBuffer();
      const documentoCargar = await PDFDocument.load(pdf);
      const contentPages = await doc.copyPages(
        documentoCargar,
        documentoCargar.getPageIndices()
      );
      for (const page of contentPages) {
        doc.addPage(page);
      }
      let pdf2 = await Clinica2(buffersEvaluacion[1].row);
      pdf2 = await pdf2.arrayBuffer();
      const documentoCargar3 = await PDFDocument.load(pdf2);
      const contentPages3 = await doc.copyPages(
        documentoCargar3,
        documentoCargar3.getPageIndices()
      );
      for (const page3 of contentPages3) {
        doc.addPage(page3);
      }
    }
    // END ADD PAGES EVALUACION

    // ADD PAGES LABORATORIO
    let laboDocuments = await Promise.all(
      buffersLaboratorio.map(async (labo) => {
        return await PDFDocument.load(labo.arrayBuffer);
      })
    );

    let laboPages = await Promise.all(
      laboDocuments.map(async (laboDocument) => {
        return await doc.copyPages(laboDocument, laboDocument.getPageIndices());
      })
    );

    for (const pages of laboPages) {
      for (const page of pages) {
        doc.addPage(page);
      }
    }
    //END ADD PAGES LABORATORIO

    // ADD PAGES RADIOS
    //BUFFERS FILTERED BY IMAGES
    let buffersRadiosImages = buffersRadios.filter((radio) => {
      return radio.tipoArchivo !== "application/pdf";
    });
    //BUFFERS FILTERED BY PDF
    let buffersRadiosPdfs = buffersRadios.filter((radio) => {
      return radio.tipoArchivo === "application/pdf";
    });
    //PROMISE URL
    let buffersRadiosImagesUrl = await Promise.all(
      buffersRadiosImages.map(async (radio) => {
        return radio.file;
      })
    );
    //CONVERT IMAGES TO PDF
    let buffersRadiosImagesToPdf = await Promise.all(
      buffersRadiosImagesUrl.map(async (image) => {
        return await ImagePDF(image);
      })
    );
    //CONVERT IMAGESPDF TO ARRAYBUFFER
    let buffersRadiosImagesPdfsToArrayBuffers = await Promise.all(
      buffersRadiosImagesToPdf.map(async (pdf) => {
        return await pdf.arrayBuffer();
      })
    );
    //CONCAT PDFS AND IMAGES TO PDF
    let buffersRadiosComplete = buffersRadiosPdfs
      .map((pdf) => pdf.arrayBuffer)
      .concat(buffersRadiosImagesPdfsToArrayBuffers);

    let radioDocuments = await Promise.all(
      buffersRadiosComplete.map(async (radio) => {
        return await PDFDocument.load(radio);
      })
    );
    let radioPages = await Promise.all(
      radioDocuments.map(async (radioDocument) => {
        return await doc.copyPages(
          radioDocument,
          radioDocument.getPageIndices()
        );
      })
    );
    for (const pages of radioPages) {
      for (const page of pages) {
        doc.addPage(page);
      }
    }
    //END ADD PAGES RADIOS

    //DOWNLOAD PDF
    let docFinal = await doc.save();
    let blobDocFinal = new Blob([docFinal], {
      type: "application/pdf",
    });
    window.open(window.URL.createObjectURL(blobDocFinal), "_blank");
    //END DOWNLOAD PDF
  }

  async getFilesFiltrados(_files) {
    let tempFiles = [];
    for await (let _file of _files) {
      if (_file.tipo == "Evaluacion" || _file.tipo == "Laboratorio") {
        tempFiles.push({ ..._file, url: _file.url });
      } else {
        let storage = firebase.storage();
        let pathReference = storage.ref();
        let url = await pathReference.child(_file.url).getDownloadURL();
        tempFiles.push({ ..._file, url });
      }
    }
    return tempFiles;
  }

  getFilePromise(_file) {
    return new Promise((res, rej) => {
      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = async () => {
        let buffer = await xhr.response.arrayBuffer();
        res({ ..._file, buffer });
      };
      xhr.open("GET", _file?.url);
      xhr.send();
    });
  }

  async getBlobFromFiles(files) {
    let res = new Promise(async (res, rej) => {
      let filesFiltrados = await this.getFilesFiltrados(files);
      Promise.all(
        filesFiltrados?.map((file) => {
          return this.getFilePromise(file);
        })
      ).then(async (filesBuffers) => {
        const doc = await PDFDocument.create();

        let filesBuffersRadios = filesBuffers.filter((file) => {
          return file?.tipo == "Radio";
        });

        let filesBuffersLaboratorio = filesBuffers.filter((file) => {
          return file?.tipo == "Laboratorio";
        });

        let filesBuffersEvaluacion = filesBuffers.filter((file) => {
          return file?.tipo == "Evaluacion";
        });

        if (filesBuffersEvaluacion.length > 2) {
          let pdf = await Clinica1(filesBuffersEvaluacion[0].row);
          pdf = await pdf.arrayBuffer();
          const documentoCargar = await PDFDocument.load(pdf);
          const contentPages = await doc.copyPages(
            documentoCargar,
            documentoCargar.getPageIndices()
          );
          for (const page of contentPages) {
            doc.addPage(page);
          }

          let pdf2 = await DatosPdf(filesBuffersEvaluacion[1].row);
          pdf2 = await pdf2.arrayBuffer();
          const documentoCargar2 = await PDFDocument.load(pdf2);
          const contentPages2 = await doc.copyPages(
            documentoCargar2,
            documentoCargar2.getPageIndices()
          );
          for (const page2 of contentPages2) {
            doc.addPage(page2);
          }

          let pdf3 = await Clinica2(filesBuffersEvaluacion[2].row);
          pdf3 = await pdf3.arrayBuffer();
          const documentoCargar3 = await PDFDocument.load(pdf3);
          const contentPages3 = await doc.copyPages(
            documentoCargar3,
            documentoCargar3.getPageIndices()
          );
          for (const page3 of contentPages3) {
            doc.addPage(page3);
          }
        } else {
          let pdf = await Clinica1(filesBuffersEvaluacion[0].row);
          pdf = await pdf.arrayBuffer();
          const documentoCargar = await PDFDocument.load(pdf);
          const contentPages = await doc.copyPages(
            documentoCargar,
            documentoCargar.getPageIndices()
          );
          for (const page of contentPages) {
            doc.addPage(page);
          }
          let pdf2 = await Clinica2(filesBuffersEvaluacion[1].row);
          pdf2 = await pdf2.arrayBuffer();
          const documentoCargar3 = await PDFDocument.load(pdf2);
          const contentPages3 = await doc.copyPages(
            documentoCargar3,
            documentoCargar3.getPageIndices()
          );
          for (const page3 of contentPages3) {
            doc.addPage(page3);
          }
        }

        if (filesBuffersLaboratorio.length > 0) {
          for await (let fileBufferLabo of filesBuffersLaboratorio) {
            const documentoCargar = await PDFDocument.load(
              fileBufferLabo.buffer
            );
            const contentPages = await doc.copyPages(
              documentoCargar,
              documentoCargar.getPageIndices()
            );
            for (const page of contentPages) {
              doc.addPage(page);
            }
          }
        }

        if (filesBuffersRadios.length > 0) {
          for await (let fileBufferRadio of filesBuffersRadios) {
            let pdf = fileBufferRadio.buffer;
            if (fileBufferRadio.tipoArchivo !== "application/pdf") {
              pdf = await ImagePDF(fileBufferRadio.url);
              pdf = await pdf.arrayBuffer();
            }
            const documentoCargar = await PDFDocument.load(pdf);
            const contentPages = await doc.copyPages(
              documentoCargar,
              documentoCargar.getPageIndices()
            );
            for (const page of contentPages) {
              doc.addPage(page);
            }
          }
        }

        let docFinal = await doc.save();
        let blobFinal = new Blob([docFinal], {
          type: "application/pdf",
        });
        res({
          file: blobFinal,
          name:
            "EVALUACION_MEDICA_" +
            filesBuffersEvaluacion[0]?.row?.ordenServicio?.detallePaciente?.nombreCompleto
              .replaceAll(" ", "_")
              .toUpperCase() +
            "_" +
            moment(
              filesBuffersEvaluacion[0]?.row?.ordenServicio?.creado
            ).format("DD_MM_YYYY") +
            ".pdf",
        });
      });
    });
    return res;
  }

  async uploadFile(path = "", _file) {
    try {
      const file = await firebase.storage().ref().child(path).put(_file);
      const fileUrl = await file.ref.getDownloadURL();

      return fileUrl.toString();
    } catch (error) {
      console.log(error);
      return "error";
    }
  }
}

export default new FirebaseService();
