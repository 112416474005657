////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useMemo, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useModels } from "../../hooks/useModels";
import { NavLink } from "react-router-dom";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import { Grid, MenuItem, IconButton, FormControlLabel, Switch, Menu, Popover, } from "@mui/material";
import TablePageLayout from "../../components/TablePageLayout";
import RangePicker from "../../components/RangePicker";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import AsyncAutocompleteSearchRework from "../../components/AsyncAutocompleteSearchRework";
import Swal from "sweetalert2";
import moment from 'moment';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { baseStyles } from "../../utils";
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Delete, Edit, MedicationLiquid, Search } from "@mui/icons-material";
import { FaNotesMedical } from "react-icons/fa";
import VisibilityIcon from '@mui/icons-material/Visibility'
import { MdPictureAsPdf } from "react-icons/md";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { RiFileExcel2Fill } from "react-icons/ri";
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
import { baseUrl, baseUrlPublica } from "../../utils/variables";
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
const useStyles = baseStyles();

const Recetas = () => {
   ////////////////////////////////////////////////////////////////////////
   //                              Vars                                  //
   ////////////////////////////////////////////////////////////////////////
   const history = useHistory();
   const [anchorEl, setAnchorEl] = useState(null);
   const classes = useStyles();
   const [perPage, setPerPage] = useState(10);
   const [openMenuRow, setOpenMenuRow] = useState(null);
   const [pagina, setPagina] = useState(1);
   const [admin] = useLocalStorage("admin", null);
   const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", null);
   const [idUsuario, setIdUsuario] = useState("");
   const { user, permiso } = useAuth();

   const parametrosInicialesMemo = useMemo(() => ({
      pagina: 1,
      limite: 10,
      ordenar: 'idReceta.desc',
      name: "receta",
      expand: 'sucursal',
      extraParams: {
         idSucursal,
         fechaInicio: moment(new Date()).startOf("month").format("yyyy/MM/DD"),
         fechaFinal: moment(new Date()).format("yyyy/MM/DD"),
      }
   }), []);

   const paramsMemo = useMemo(() => ({ ordenar: "nombreSucursal.asc" }), []);
   const paramsMemoU = useMemo(() => ({ ordenar: "nombreCompleto.asc" }), []);

   const [models, modelsLoading, modelsError, modelsPage, refreshModels, deleteModel] = useModels({ ...parametrosInicialesMemo });

   const [propiedades, setPropiedades] = useState({
      paciente: "",
      edad: "",
      fechaInicio: moment(new Date()).startOf("month").format("yyyy/MM/DD"),
      fechaFinal: moment(new Date()).format("yyyy/MM/DD"),
      idSucursal,
      idUsuario,

   });

   ////////////////////////////////////////////////////////////////////////
   //                           Funcions Def                             //
   ////////////////////////////////////////////////////////////////////////
   const onDeleteClicked = async (mensaje, type, id) => {
      try {
         setOpenMenuRow(false);
         await Swal.fire({
            title: type,
            text: mensaje,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34AA44",
            cancelButtonColor: "#E72C33",
            confirmButtonText: "Si, deseo Continuar.",
         }).then((result) => {
            if (result.isConfirmed) {
               let params = {};
               params = { ...parametrosInicialesMemo, ...propiedades, ...{ pagina: pagina, limite: perPage }, };
               deleteModel(id, type, params);
            }
         });
      } catch (error) {
         console.log(error);
      }
   };

   const onSearchClicked = async eliminado => {
      let params = {};
      if (eliminado != null) {
         params = { ...parametrosInicialesMemo, ...propiedades, ...{ pagina: pagina, limite: perPage, eliminado: eliminado }, };
      } else {
         params = { ...parametrosInicialesMemo, ...propiedades, ...{ pagina: pagina, limite: perPage }, };
      }
      await refreshModels({ isCargando: true, params });
   };

   const changePage = async page => {
      setPagina(page + 1);
      let params = { ...parametrosInicialesMemo, ...propiedades, ...{ pagina: page + 1, limite: perPage }, };
      await refreshModels({ isCargando: true, params });
   };

   const changePageRow = async per => {
      setPerPage(per);
      const params = { ...parametrosInicialesMemo, ...propiedades, ...{ limite: per, pagina: pagina }, };
      await refreshModels({ isCargando: true, params });
   };
   ////////////////////////////////////////////////////////////////////////
   //                            Hooks Def                               //
   ////////////////////////////////////////////////////////////////////////

   const setIdSucursalCallback = useCallback((e, v) => {
      if (v !== "" && v) {
         setIdSucursal(v.idSucursal);
         setPropiedades({ ...propiedades, idSucursal: v.idSucursal });
      } else {
         setIdSucursal("");
      }
   }, [setIdSucursal]);

   const setIdUsuarioCallback = useCallback((e, v) => {
      if (v !== "" && v) {
         setIdUsuario(v.idUsuario);
         setPropiedades({ ...propiedades, idUsuario: v.idUsuario });
      } else {
         setIdUsuario("");
      }
   }, [setIdUsuario]);

   return (
      <>
         <TablePageLayout
            title="Recetas"
            model="receta"
            history={history}
            actionButton={{ to: "/receta/nuevo", icon: <Add />, label: "Agregar", permiso: "doctor" }}
            links={[{ label: "Recetas", to: "/recetas", icon: <MedicationLiquid fontSize="small" /> }]}
            SearchComponents={
               <>
                  <Grid item xs={12} sm={4}>
                     <InputRegex
                        label="Paciente"
                        variant="standard"
                        fullWidth
                        size="medium"
                        typeInput='text'
                        value={propiedades.paciente}
                        onChange={(e) => setPropiedades((prevState) => ({
                           ...prevState,
                           ...prevState.propiedades,
                           paciente: e.target.value,
                        }))}
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <InputRegex
                        label="Edad"
                        variant="standard"
                        fullWidth
                        size="medium"
                        typeInput='number'
                        value={propiedades.edad}
                        onChange={(e) => setPropiedades((prevState) => ({
                           ...prevState,
                           ...prevState.propiedades,
                           edad: e.target.value,
                        }))}
                     />
                  </Grid>
                  <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                     <RangePicker
                        style={{ width: '100%' }}
                        value={[new Date(propiedades.fechaInicio), new Date(propiedades.fechaFinal)]}
                        format="dd/MM/yyyy"
                        placeholder="Seleccione el rango de fechas"
                        onChange={e => setPropiedades({
                           ...propiedades,
                           fechaInicio: moment(e[0]).format("yyyy/MM/DD"),
                           fechaFinal: moment(e[1]).format("yyyy/MM/DD")
                        })}
                     />
                  </Grid>
                  {(admin === true || (permiso && (permiso[0]["recetas"] & 15) === 15 )) ? (
                     <>
                        <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                           <AsyncAutocompleteSearchRework
                              label="Doctores"
                              labelProp="nombreCompleto"
                              name="usuario"
                              onChange={setIdUsuarioCallback}
                              extraParams={paramsMemoU}
                           />
                        </Grid>
                     </>
                  ):(<Grid item md={4} />)}

                  {(admin === true && (permiso && (permiso[0]["recetas"] & 15) === 15 )) ? (
                     <>
                        <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                           <AsyncAutocompleteSearchRework
                              label="Sucursales"
                              labelProp="nombreSucursal"
                              name="sucursal"
                              onChange={setIdSucursalCallback}
                              extraParams={paramsMemo}
                           />
                        </Grid>
                        <Grid item xs={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}></Grid>
                     </>
                  ):(<Grid item md={6} />)}
                  
                  <Grid item xs={12} md={2} style={{ display: 'flex' }}>
                     <LoadingButton
                        variant="contained"
                        fullWidth
                        onClick={() => onSearchClicked(null)}
                        loading={modelsLoading && true}
                        style={{ float: "right", color: "whitesmoke", }}
                        startIcon={<Search />}
                     >
                        Buscar
                     </LoadingButton>
                  </Grid>
               </>
            }
            DisableComponents={
               <>
                  <Grid item xs={12} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", padding: '0 20px' }}>
                     <IconButton color="error" aria-label="upload picture" component="span">
                        <a className={classes.btnPdf} target="_blank" rel="noreferrer" 
                          href={`${baseUrl}pdf/receta?${"admin="+(admin ? 1 : 0)}${"&uid="+user?.idUsuario}${"&idSucursal="+idSucursal}${"&paciente="+propiedades.paciente}${"&edad="+propiedades.edad}${"&fechaInicio="+propiedades.fechaInicio}${"&fechaFinal="+propiedades.fechaFinal}${"&eliminado="+(propiedades.eliminado ? 1 : 0)}`}>
                           <MdPictureAsPdf />
                        </a>
                     </IconButton>
                     <IconButton color="warning" aria-label="upload picture" component="span">
                        <a className={classes.btnExcel} target="_blank" rel="noreferrer" 
                        href={`${baseUrl}excel/receta?${"admin="+(admin ? 1 : 0)}${"&uid="+user?.idUsuario}${"&idSucursal="+idSucursal}${"&paciente="+propiedades.paciente}${"&edad="+propiedades.edad}${"&fechaInicio="+propiedades.fechaInicio}${"&fechaFinal="+propiedades.fechaFinal}${"&eliminado="+(propiedades.eliminado ? 1 : 0)}`}>
                           <RiFileExcel2Fill />
                        </a>
                     </IconButton>
                  </Grid>
               </>
            }
            tableLoading={modelsLoading}
            tableErrors={modelsError}
            TableComponent={
               <TablePagination
                  nombrePermiso="recetas"
                  permiso={permiso}
                  header={[]}
                  data={models}
                  paginatedApi
                  pagina={pagina}
                  paginationPageSize={perPage}
                  onRowsPerPageChangeApi={changePageRow}
                  changePageApi={changePage}
                  count={modelsPage !== null ? modelsPage.total : 0}
                  labelRowsPerPage={"Renglones por página"}
                  extraRows={[
                     {
                        prop: "folio",
                        name: "Folio",
                        sortable: true,
                        cell: (row, index) => <p key={index}>{row.folio}</p>
                     },
                     {
                        prop: "creado",
                        name: "Fecha",
                        sortable: false,
                        cell: (row, index) => <p key={index}>{moment(row.creado).format("DD/MM/yyy")}</p>
                     },
                     {
                        prop: "receta",
                        name: "Receta",
                        sortable: false,
                        cell: (row, index) => (
                           <IconButton aria-label="upload picture" component="span" key={index}>
                              <a className={classes.btnPdf} target="_blank" rel="noreferrer" href={`${baseUrlPublica}pdf/receta?${"&id=" + row.idReceta}${"&folio=" + row.folio}`}>
                                 <FaNotesMedical style={{ color: '#1c5e7b' }} />
                              </a>
                           </IconButton>
                        )
                     },
                     {
                        prop: "paciente",
                        name: "Paciente",
                        cell: (row, index) => <p key={index}>{row.paciente}</p>
                     },
                     {
                        prop: "edad",
                        name: "Edad",
                        cell: (row, index) => <p key={index}>{row.edad}</p>
                     },
                     {
                        prop: "conteinido",
                        name: "Contenido",
                        sortable: false,
                        cell: (row, index) => {
                           const newArr = row.contenido.split("\n");
                           const contenido = newArr.map((item, index) => {
                              return (
                                 <p style={{ textAlign: 'justify' }} key={index}>{item}</p>
                              )
                           });
                           return (
                              <PopupState variant="popover" popupId="demo-popup-popover" >
                                 {(popupState) => (
                                    <div>
                                       <p key={index} style={{ cursor: 'pointer', textAlign: 'center' }} {...bindTrigger(popupState)}><VisibilityIcon style={{ color: 'rgb(80, 80, 80)' }} /></p>
                                       <Popover {...bindPopover(popupState)} anchorOrigin={{ vertical: 'top', horizontal: 'center', }} transformOrigin={{ vertical: 'bottom', horizontal: 'center', }} >
                                          <div style={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
                                             <h5 style={{ marginBottom: '5px', textAlign: 'center' }}>Receta Médica</h5>
                                             <div style={{ padding: '15px', border: '2px solid rgba(127, 127, 127, .5)', borderRadius: '5px' }}>
                                                {contenido}
                                             </div>
                                          </div>
                                       </Popover>
                                    </div>
                                 )}
                              </PopupState >
                           )
                        }
                     },
                     {
                        prop: "acciones",
                        name: "Acciones",
                        sortable: false,
                        cell: (row, index) => (permiso && (permiso[0]["recetas"] & 4) === 4 || (permiso[0]["recetas"] & 8) === 8) && (
                           <div>
                              <IconButton onClick={(event) => { setOpenMenuRow(index); setAnchorEl(event.currentTarget) }}>
                                 <MoreVertIcon />
                              </IconButton>
                              <Menu anchorEl={anchorEl} keepMounted open={index === openMenuRow} onClose={() => setOpenMenuRow(null)}>
                                 {(admin === true || (permiso && permiso[0]["recetas"] & 4) === 4) && (row.eliminado == null) && (
                                    <NavLink exact to={(`receta/editar/${row.idReceta}`)} style={{ color: '#000', textDecoration: 'none' }}>
                                       <MenuItem aria-label="editar" onClick={() => { history.push(`receta/editar/${row.idReceta}`, { match: row.idPaquete }); }}>
                                          <Edit style={{ paddingRight: 5 }} /> Editar
                                       </MenuItem>
                                    </NavLink>
                                 )}
                                 {(admin === true || (permiso && permiso[0]["recetas"] & 8) === 8) && (
                                    row?.eliminado === null ? (
                                       <MenuItem onClick={() => onDeleteClicked(`Desea Deshabilitar el Paciente ${row.paciente}`, "Deshabilitar", row.idReceta)} >
                                          <Delete style={{ paddingRight: 5 }} /> Deshabilitar
                                       </MenuItem>
                                    ) : (
                                       <MenuItem onClick={() => onDeleteClicked(`Desea Habilitar el Paciente ${row.paciente}`, "Habilitar", row.idReceta)} >
                                          <RestoreFromTrashIcon style={{ paddingRight: 5 }} /> Habilitar
                                       </MenuItem>
                                    )
                                 )}
                              </Menu>
                           </div>
                        ),
                     },
                  ]}
               />
            }
         />
      </>
   );
}
export default Recetas;