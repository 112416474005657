/* eslint-disable default-case */
import React, { useState } from "react";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import { useModel } from "../../../../hooks/useModel";
import Swal from "sweetalert2";
import { useAuth } from "../../../../hooks/useAuth";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { getSelectionRange } from "@testing-library/user-event/dist/utils";
import { useEffect } from "react";
import { useAlert } from "../../../../hooks/useAlert";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
export const NotasEnfermeriaComponent = ({
  idEvaluacion,
  idOrdenServicio,
  setDisabledByName,
  setGuardado,
  notasEnfermeria,
}) => {
  const classes = useStyles();
  const id = idEvaluacion;
  const { showAlert } = useAlert();
  const [admin] = useLocalStorage("admin", null);
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const { permiso } = useAuth();

  const [propiedades, setPropiedades] = useState(
    notasEnfermeria
      ? notasEnfermeria
      : {
        notas: "",
        insumos: "",
        personalEnfermeria: "",
        medico: "",
        cp: "",
      }
  );

  const getTriage = () => {
    let triage = {
      name: "Triage I",
      checked: false,
      value:
        "Requiere atención inmediata. La condición clínica del paciente representa un riesgo vital y necesita maniobras de reanimación por su compromiso ventilatorio, respiratorio, hemodinámico o neurológico, perdida de miembro u órgano u otras condiciones que por norma exijan atención inmediata.",
      colorTriage: "#FF0000",
    };
    if (notasEnfermeria) {
      switch (notasEnfermeria.tipoTriage) {
        case "Triage I":
          triage = {
            name: "Triage I",
            checked: false,
            value:
              "Requiere atención inmediata. La condición clínica del paciente representa un riesgo vital y necesita maniobras de reanimación por su compromiso ventilatorio, respiratorio, hemodinámico o neurológico, perdida de miembro u órgano u otras condiciones que por norma exijan atención inmediata.",
            colorTriage: "#FF0000",
          };
          break;
        case "Triage II":
          triage = {
            name: "Triage II",
            checked: false,
            value:
              "La condición clínica del paciente puede evolucionar hacia un rá-pido deterioro o a su muerte, o incrementar el riesgo para la pérdida de un miembro u órgano, por lo tanto, requiere una atención que no debe superar los treinta (30) minutos. La presencia de un dolor extremo de acuerdo con el sistema de clasificación usado debe ser considerada como un criterio dentro de esta categoría.",
            colorTriage: "#F65810",
          };
          break;
        case "Triage III":
          triage = {
            name: "Triage III",
            checked: false,
            value:
              "La condición clínica del paciente requiere de medidas diagnósticas y terapéuticas en urgencias. Son aquellos pacientes que necesitan un examen complementario o un tratamiento rápido, dado que se encuentran estables desde el punto de vista fisiológico aunque su situación puede empeorar si no se actúa.",
            colorTriage: "#FFFF00",
          };
          break;
        case "Triage IV":
          triage = {
            name: "Triage IV",
            checked: false,
            value:
              "El paciente presenta condiciones médicas que no comprometen su estado general, ni representan un riesgo evidente para la vida o pérdida de miembro u órgano. No obstante, existen riesgos de complicación o secuelas de la enfermedad o lesión si no recibe la atención correspondiente.",
            colorTriage: "#638B2C",
          };
          break;
        case "Triage V":
          triage = {
            name: "Triage V",
            checked: false,
            value:
              "El paciente presenta una condición clínica relacionada con problemas agudos o crónicos sin evidencia de deterioro que comprometa el estado general de paciente.",
            colorTriage: "#3860B2",
          };
          break;
      }
    }
    return triage;
  }

  const [triageObj, setTriageObj] = useState(getTriage());

  const [triage, setTriage] = useState([
    {
      name: "Triage I",
      checked: false,
      value:
        "Requiere atención inmediata. La condición clínica del paciente representa un riesgo vital y necesita maniobras de reanimación por su compromiso ventilatorio, respiratorio, hemodinámico o neurológico, perdida de miembro u órgano u otras condiciones que por norma exijan atención inmediata.",
      colorTriage: "#FF0000",
    },
    {
      name: "Triage II",
      checked: false,
      value:
        "La condición clínica del paciente puede evolucionar hacia un rá-pido deterioro o a su muerte, o incrementar el riesgo para la pérdida de un miembro u órgano, por lo tanto, requiere una atención que no debe superar los treinta (30) minutos. La presencia de un dolor extremo de acuerdo con el sistema de clasificación usado debe ser considerada como un criterio dentro de esta categoría.",
      colorTriage: "#F65810",
    },
    {
      name: "Triage III",
      checked: false,
      value:
        "La condición clínica del paciente requiere de medidas diagnósticas y terapéuticas en urgencias. Son aquellos pacientes que necesitan un examen complementario o un tratamiento rápido, dado que se encuentran estables desde el punto de vista fisiológico aunque su situación puede empeorar si no se actúa.",
      colorTriage: "#FFFF00",
    },
    {
      name: "Triage IV",
      checked: false,
      value:
        "El paciente presenta condiciones médicas que no comprometen su estado general, ni representan un riesgo evidente para la vida o pérdida de miembro u órgano. No obstante, existen riesgos de complicación o secuelas de la enfermedad o lesión si no recibe la atención correspondiente.",
      colorTriage: "#638B2C",
    },
    {
      name: "Triage V",
      checked: false,
      value:
        "El paciente presenta una condición clínica relacionada con problemas agudos o crónicos sin evidencia de deterioro que comprometa el estado general de paciente.",
      colorTriage: "#3860B2",
    },
  ]);

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "evaluaciones",
      id,
      redirectOnPost: false,
    });

  useEffect(() => {
    if (notasEnfermeria) {
      let triageCopy = [...triage];
      triage.forEach((tri, i) => {
        if (tri.name === notasEnfermeria.tipoTriage) {
          triageCopy[i].checked = true;
        }
      });
      setTriage(triageCopy);
    }
  }, []);

  const setAllFalse = () => {
    let copyTriage = [...triage];
    triage.forEach((tri, i) => {
      copyTriage[i].checked = false;
    });
    setTriage(copyTriage);
  }

  useEffect(() => {
    if (Object.keys(modelError)?.length > 0 && !updateModelLoading) {
      console.log(modelError)
      Object.keys(modelError).forEach(
        error => {
          console.log(modelError[error])
          showAlert({ message: modelError[error], severity: "warning" })
        }
      )
      setGuardado(false)
    }
  }, [modelError])

  const onGuardar = async () => {
    let body = propiedades;
    body = { ...body, triange: triageObj.value, tipoTriage: triageObj.name };

    let data = {
      idOrdenServicio,
      idEvaluacion,
      idSucursal: 2,
      NotasEnfermeria: body,
    };
    setDisabledByName("NotasEnfermeriaComponent", true);
    await updateModel(data, true, "hospitalito/nuevaConsulta");
    setGuardado(true);
  };
  return (
    <>
      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <strong>{"NOTAS DE ENFERMERÍA"}</strong>
          </div>
          <Divider />
        </Grid>

        <Grid item xs={12} md={12} style={{ padding: "0px 15px" }}>
          <TextField
            label="Notas de enfermería"
            variant="standard"
            maxRows={5}
            multiline
            fullWidth
            size="medium"
            value={propiedades.notas}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                notas: e.target.value,
              }))
            }
          />
        </Grid>

        <Grid item md={12} style={{ display: "flex", flexWrap: "wrap" }}>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <strong>{"TRIAGE"}</strong>
            </div>
          </Grid>

          <Grid item xs={12} md={12} style={{ padding: "0px 15px" }}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Triage</FormLabel>
              <RadioGroup
                defaultValue={triage[0].name}
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                {triage.map((triages, index) => (
                  <Grid key={index} item md={12} style={{ display: "flex" }}>
                    <Grid item md={2}>
                      <FormControlLabel
                        label={triages.name}
                        value={triage[index]?.name}
                        control={
                          <Radio
                            checked={triages.checked}
                            onChange={(e) => {
                              setAllFalse();
                              let newTriageObj = triage[index];
                              setTriageObj(newTriageObj);
                              triage[index].checked = e.target.checked;
                            }}
                          />
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      md={10}
                      style={{ backgroundColor: triages.colorTriage }}
                    >
                      <p>{triages.value}</p>
                    </Grid>
                  </Grid>
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading ||
                  updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["evaluaciones"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<Save />}
              style={{ color: "#fff" }}
            >
              GUARDAR
              {/* {(!editing ? "GUARDAR" : "MODIFICAR")} */}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
