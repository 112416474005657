import React, { useState, useEffect } from "react";
import { Divider, Grid, Paper, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

import { Save } from "@mui/icons-material";
import MultiFile from "../../../../components/MultiFile";
import { useModel } from "../../../../hooks/useModel";
import { useAuth } from "../../../../hooks/useAuth";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import Swal from "sweetalert2";
import { useAlert } from "../../../../hooks/useAlert";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export const ParaclinicosComponent = ({
  idEvaluacion,
  idOrdenServicio,
  paraclinicos,
  setGuardado,
  setDisabledByName,
}) => {
  const classes = useStyles();
  const id = idEvaluacion;
  const [admin] = useLocalStorage("admin", null);
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const { permiso } = useAuth();
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();
  const [files, setFiles] = useState(paraclinicos ? paraclinicos?.estudios ? paraclinicos?.estudios.length > 0 ? paraclinicos?.estudios : [] : [] : []);
  const [propiedades, setPropiedades] = useState(
    paraclinicos
      ? paraclinicos
      : {
        usaLentes: "",
        daltonismo: "",
        campimetriaDerecha: "",
        campimetriaIzquierda: "",
        vlao: "",
        vlod: "",
        vloi: "",
        vcao: "",
        vcod: "",
        vcoi: "",
        personalEnfermeria: "",
        medico: "",
        cp: "",
      }
  );

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({ name: "evaluaciones", id, redirectOnPost: false });

  useEffect(() => {
    if (Object.keys(modelError)?.length > 0 && !updateModelLoading) {
      console.log(modelError)
      Object.keys(modelError).forEach(
        error => {
          console.log(modelError[error])
          showAlert({ message: modelError[error], severity: "warning" })
        }
      )
      setGuardado(false)
    }
  }, [modelError])

  const onGuardar = async () => {

    let data = {
      idOrdenServicio,
      idEvaluacion,
      idSucursal: 2,
      Paraclinicos: propiedades,
      Estudio: files,
    };
    setDisabledByName("ParaclinicosComponent", true);
    await updateModel(data, true, "hospitalito/nuevaConsulta");
    setGuardado(true);
  };
  return (
    <>
      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <strong>{"PARACLINICOS"}</strong>
          </div>
          <Divider />
        </Grid>
        <Grid item xs={12} md={12} style={{ margin: "5px" }}>
          <MultiFile files={files} loading={loading} setLoading={setLoading} setFiles={setFiles} firebaseFolder={"EstudiosGabinete"} />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading ||
                  updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["evaluaciones"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<Save />}
              style={{ color: "#fff" }}
            >
              GUARDAR
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
