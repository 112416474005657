import { Button, Fab, Grid, Tooltip, Typography } from '@mui/material';
import React from "react";
import UrlBreadcrumb from "./UrlBreadcrumb";
import { makeStyles } from "@mui/styles";
import { useLocalStorage } from "./../hooks/useLocalStorage";
import { useAuth } from "./../hooks/useAuth";
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    marginBottom: "3px",
    color:"#666666",
  },
}));

const PageHeader = ({ history, ...props }) => {
  const [admin] = useLocalStorage("admin", null);
  const { permiso } = useAuth();
  const classes = useStyles();
  const {
    title = "Página sin título",
    links,
    preActionButton,
    actionButton,
  } = props;

  return (
    <>
      {props.dialog ? (
        <></>
      ) : (
        <>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UrlBreadcrumb links={links} history={history} />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="flex-end">
                <Grid item xs={12} style={{margin: "12px 0", zIndex: "0"}}>
                  {preActionButton && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={(e) => {
                        if (preActionButton.to) {
                          history.push(preActionButton.to);
                        } else if (preActionButton.onClick) {
                          preActionButton.onClick(e);
                        }
                      }}
                      style={{
                        float: "left",
                        backgroundColor: "#2F2F2F",
                        color: "whitesmoke",
                      }}
                      size="small"
                      endIcon={preActionButton.icon}
                      disabled={preActionButton.disabled}
                    >
                      {preActionButton.label}
                    </Button>
                  )}
                  {actionButton && (
                    (admin === true || (permiso && permiso[0][actionButton.permiso] & 2) === 2) && (actionButton.permiso!="doctor") ? (
                      <NavLink to={actionButton.to} >
                        <Tooltip title={actionButton.label} aria-label={actionButton.label} className={classes.successButton}>
                          <Fab color="primary" className={classes.fab} style={{ float: "right", color: "whitesmoke" }}>
                            {actionButton.icon}
                          </Fab>
                        </Tooltip>
                      </NavLink>
                    ) : ((admin === false && (permiso && permiso[0][actionButton.permiso] & 1) > 0) && (actionButton.permiso="doctor") ) &&  (
                      <>
                        <NavLink to={actionButton.to} >
                          <Tooltip title={actionButton.label} aria-label={actionButton.label} className={classes.successButton}>
                            <Fab color="primary" className={classes.fab} style={{ float: "right", color: "whitesmoke" }}>
                              {actionButton.icon}
                            </Fab>
                          </Tooltip>
                        </NavLink>
                      </>
                    )
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default PageHeader;
