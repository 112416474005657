////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useEffect, useState } from 'react'
import { useRef } from 'react'
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import Chart from "react-apexcharts"
import { CircularProgress } from '@mui/material'
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////

const AudiometriaChart = ({
    monoI,
    monoD,
    foto,
    setFoto,
    ...props
}) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const audiometriaChart = useRef(null)
    const [loading, setLoading] = useState(false);
    const sleep = m => new Promise(r => setTimeout(r, m))
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const getUri = async () => {
        setLoading(true);
        if (audiometriaChart !== null) {
            await sleep(1000)
            let res = await audiometriaChart?.current?.chart?.dataURI()
            fetch(res?.imgURI)
                .then(res => res.blob())
                .then(b => setFoto(b))
        }
        setLoading(false);
    }

    useEffect(() => {
        getUri()
    }, [monoI, monoD])
    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
            {
                loading && (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        <CircularProgress />
                        CARGANDO IMAGEN POR FAVOR ESPERA...
                    </div>
                )
            }
            <Chart
                style={{ width: "100%" }}
                ref={audiometriaChart}
                options={{
                    colors: ['#4472C4', '#FF0000'],
                    grid: {
                        show: true,
                        borderColor: '#90A4AE',
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },
                    },
                    markers: {
                        size: 10,
                    },
                    chart: {
                        id: "basic-bar"
                    },
                    xaxis: {
                        position: "top",
                        categories: [500, 1000, 2000, 3000, 4000, 6000, 8000]
                    },
                    yaxis: {
                        reversed: true,
                        min: -10,
                        max: 95,
                        tickAmount: 21,
                        forceNiceScale: true,
                    }
                }}
                series={[
                    {
                        name: "Monoaural Izquierdo",
                        data: monoI,
                    },
                    {
                        name: "Monoaural Derecho",
                        data: monoD,
                    },
                ]}
                type={'line'}
            />
        </div>
    )
}
export default AudiometriaChart