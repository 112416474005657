import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Box, useMediaQuery, ThemeProvider, CssBaseline } from "@mui/material";
import PrivateRoute from "../components/PrivateRoute";
import Navigator from "../components/Navigator";
import Header from "../components/Header";
import { useApp } from "../hooks/useApp";

import Factura from "../views/factura/index";
import FacturaDetail from "../views/factura/detail";

import DoctoresComponent from "../components/DoctoresComponent";

import Sucursal from "../views/sucursal/";
import SucursalDetail from "../views/sucursal/detail";
import SucursalHorario from "../views/sucursal/horarios";
import Usuario from "../views/usuario/";
import UsuarioDetail from "../views/usuario/detail";
import UsuarioPermisos from "../views/usuario/permisos";
import Servicio from "../views/servicio/";
import { useAuth } from "../hooks/useAuth";
import ServicioDetail from "../views/servicio/detail";

import Seguimiento from "../views/seguimiento";

import Inventario from "../views/inventario/index";
import InventarioDetail from "../views/inventario/detail";

import InventarioLaboratorio from "../views/inventarioLaboratorio/index";
import InventarioLaboratorioDetail from "../views/inventarioLaboratorio/detail";

import Campanas from "../views/campanas/index";

import Entrada from "../views/entrada/index";

import EntradaLaboratorio from "../views/entradaLaboratorio/index";

import CorreoPromocion from "../views/correoPromocion/index";

import RecepcionPaciente from "../views/recepcionPaciente/index";
import RecepcionPacienteDetail from "../views/recepcionPaciente/detail";

import Paciente from "../views/pacientes/index";
import PacienteDetails from "../views/pacientes/details";

import TipoEvaluacion from "../views/tipoEvaluacion/index";
import TipoEvaluacionDetails from "../views/tipoEvaluacion/detail";

import Paquetes from "../views/paquetes/index";
import PaquetesDetail from "../views/paquetes/detail";

import Valoraciones from "../views/valoracion/index";
import ValoracionesDetail from "../views/valoracion/detail";

import Recetas from "../views/receta/index";
import RecetasDetail from "../views/receta/detail";

import Convenio from "../views/convenio/index";
import ConvenioDetail from "../views/convenio/detail";

import Comision from "../views/comision/index";
import ComisionDetail from "../views/comision/detail";

import Agenda from "../views/agenda";
import AgendaDetail from "../views/agenda/detail";

import Grupos from "../views/grupo"
import GruposDetails from "../views/grupo/detail"

import OrdenServicio from "../views/ordenServicio";
import OrdenServicioDetail from "../views/ordenServicio/detail";

import Cita from "../views/cita";
import CitaDetail from "../views/cita/detail";

import Reportes from "../views/reporte/index";

import Finanzas from "../views/finanzas/index";
import FinanzasDetail from "../views/finanzas/detail";

import FinanzasGenerales from "../views/finanzasGenerales/index";
import FinanzasGeneralesDetail from "../views/finanzasGenerales/detail";

import Encuestas from "../views/encuestas";

import Home from "../views/home";

import Hospitalito from "../views/evaluacionMedica/hospitalito/index";
import HospitalitoDetail from "../views/evaluacionMedica/hospitalito/detail";

import ClinicaNuevaVida from "../views/evaluacionMedica/clinicaNuevaVida/index";
import ClinicaNuevaVidaDetail from "../views/evaluacionMedica/clinicaNuevaVida/detail";

import ReciboPDF from "../components/ReciboPDF";

import { useLocalStorage } from "./../hooks/useLocalStorage";

const drawerWidth = 256;

const PrivateRoutes = () => {

  const { signOut, user, permiso } = useAuth();
  const { MuiTheme } = useApp();
  const [admin, setAdmin] = useLocalStorage("admin", null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(MuiTheme.breakpoints.up("md"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Router>
      <>
        <ThemeProvider theme={MuiTheme}>
          <Box sx={{ display: "flex", minHeight: "100vh" }}>
            <CssBaseline />
            <Box
              component="nav"
              sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
            >
              {isSmUp ? null : (
                <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                />
              )}
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                sx={{ display: { md: "block", xs: "none" } }}
              />
            </Box>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Header onDrawerToggle={handleDrawerToggle} />
              <Box
                component="main"
                sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}
              >
                <Switch>

                  {/*RecepcionPaciente*/}
                  <PrivateRoute
                    exact
                    path="/historialPacientes"
                    permisoCheck="recepcionPacientes"
                    component={RecepcionPaciente}
                  />
                  <PrivateRoute
                    exact
                    path="/historialPaciente/ver/:id"
                    permisoCheck="recepcionPacientes"
                    component={RecepcionPacienteDetail}
                  />
                  {/*CorreoPromociones*/}
                  <PrivateRoute
                    exact
                    path="/correoPromociones"
                    permisoCheck="correosPromociones"
                    component={CorreoPromocion}
                  />

                  {/*SUCURSALES*/}
                  <PrivateRoute
                    exact
                    path="/sucursales"
                    permisoCheck="sucursales"
                    component={Sucursal}
                  />
                  <PrivateRoute
                    exact
                    path="/sucursal/nuevo"
                    permisoCheck="sucursales"
                    component={SucursalDetail}
                  />

                  <PrivateRoute
                    exact
                    path="/sucursal/editar/:idSucursal"
                    permisoCheck="sucursales"
                    component={SucursalDetail}
                  />

                  <PrivateRoute
                    exact
                    path="/recibo/:id"
                    permisoCheck="ordenServicios"
                    component={ReciboPDF}
                  />

                  <PrivateRoute
                    exact
                    path="/sucursal/horario/:idSucursal"
                    permisoCheck="sucursales"
                    component={SucursalHorario}
                  />
                  {/*FACTURAS*/}
                  <PrivateRoute
                    exact
                    path="/facturas"
                    permisoCheck="facturas"
                    component={Factura}
                  />

                  <PrivateRoute
                    exact
                    path="/factura/nuevo"
                    permisoCheck="facturas"
                    component={FacturaDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/factura/editar/:idFactura"
                    permisoCheck="facturas"
                    component={FacturaDetail}
                  />

                  {/*USUARIOS*/}
                  <PrivateRoute
                    exact
                    path="/usuarios"
                    permisoCheck="usuarios"
                    component={Usuario}
                  />

                  <PrivateRoute
                    exact
                    path="/usuario/nuevo"
                    permisoCheck="usuarios"
                    component={UsuarioDetail}
                  />

                  <PrivateRoute
                    exact
                    path="/usuario/permisos/:idUsuario"
                    permisoCheck="usuarios"
                    component={UsuarioPermisos}
                  />

                  <PrivateRoute
                    exact
                    path="/usuario/editar/:idUsuario"
                    permisoCheck="usuarios"
                    component={UsuarioDetail}
                  />

                  {/*SERVICIOS*/}
                  <PrivateRoute
                    exact
                    path="/servicios"
                    permisoCheck="servicios"
                    component={Servicio}
                  />

                  <PrivateRoute
                    exact
                    path="/servicio/nuevo"
                    permisoCheck="servicios"
                    component={ServicioDetail}
                  />

                  <PrivateRoute
                    exact
                    path="/servicio/editar/:idServicio"
                    permisoCheck="servicios"
                    component={ServicioDetail}
                  />

                  {/*REPORTES*/}
                  <PrivateRoute
                    exact
                    path="/reportes"
                    permisoCheck="reportes"
                    component={Reportes}
                  />
                  {/*INVENTARIO*/}
                  <PrivateRoute
                    exact
                    path="/productos"
                    permisoCheck="inventarios"
                    component={Inventario}
                  />

                  <PrivateRoute
                    exact
                    path="/producto/nuevo"
                    permisoCheck="inventarios"
                    component={InventarioDetail}
                  />

                  <PrivateRoute
                    exact
                    path="/producto/editar/:idProductoInventario"
                    permisoCheck="inventarios"
                    component={InventarioDetail}
                  />

                  {/*INVENTARIO LABORATORIO*/}
                  <PrivateRoute
                    exact
                    path="/productosLaboratorio"
                    permisoCheck="inventarioLaboratorios"
                    component={InventarioLaboratorio}
                  />

                  <PrivateRoute
                    exact
                    path="/productoLaboratorio/nuevo"
                    permisoCheck="inventarioLaboratorios"
                    component={InventarioLaboratorioDetail}
                  />

                  <PrivateRoute
                    exact
                    path="/productoLaboratorio/editar/:id"
                    permisoCheck="inventarioLaboratorios"
                    component={InventarioLaboratorioDetail}
                  />

                  {/*CAMPAÑAS*/}
                  <PrivateRoute
                    exact
                    path="/campanas"
                    permisoCheck="campanas"
                    component={Campanas}
                  />

                  <PrivateRoute
                    exact
                    path="/campanas/editar/:idCampana"
                    permisoCheck="campanas"
                    component={Campanas}
                  />

                  {/*CAMPAÑAS*/}
                  <PrivateRoute
                    exact
                    path="/grupos"
                    permisoCheck="campanas"
                    component={Grupos}
                  />

                  <PrivateRoute
                    exact
                    path="/grupo/editar/:idGrupoCampana"
                    permisoCheck="campanas"
                    component={GruposDetails}
                  />
                  <PrivateRoute
                    exact
                    path="/grupo/nuevo"
                    permisoCheck="campanas"
                    component={GruposDetails}
                  />

                  {/*PACIENTES*/}
                  <PrivateRoute
                    exact
                    path="/pacientes"
                    permisoCheck="pacientes"
                    component={Paciente}
                  />

                  <PrivateRoute
                    exact
                    path="/paciente/editar/:id"
                    permisoCheck="pacientes"
                    component={PacienteDetails}
                  />

                  <PrivateRoute
                    exact
                    path="/paciente/nuevo"
                    permisoCheck="pacientes"
                    component={PacienteDetails}
                  />

                  {/*TipoEvaluacion*/}
                  <PrivateRoute
                    exact
                    path="/evaluaciones"
                    permisoCheck="tipoEvaluaciones"
                    component={TipoEvaluacion}
                  />

                  <PrivateRoute
                    exact
                    path="/evaluacion/editar/:id"
                    permisoCheck="tipoEvaluaciones"
                    component={TipoEvaluacionDetails}
                  />

                  <PrivateRoute
                    exact
                    path="/evaluacion/nuevo"
                    permisoCheck="tipoEvaluaciones"
                    component={TipoEvaluacionDetails}
                  />

                  {/*PAQUETES*/}
                  <PrivateRoute
                    exact
                    path="/paquetes"
                    permisoCheck="paquetes"
                    component={Paquetes}
                  />

                  <PrivateRoute
                    exact
                    path="/paquete/nuevo"
                    permisoCheck="paquetes"
                    component={PaquetesDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/paquete/editar/:id"
                    permisoCheck="paquetes"
                    component={PaquetesDetail}
                  />

                  {/*PAQUETES*/}
                  <PrivateRoute
                    exact
                    path="/valoraciones"
                    permisoCheck="valoraciones"
                    component={Valoraciones}
                  />

                  <PrivateRoute
                    exact
                    path="/valoracion/nuevo"
                    permisoCheck="valoraciones"
                    component={ValoracionesDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/valoracion/editar/:id"
                    permisoCheck="valoraciones"
                    component={ValoracionesDetail}
                  />

                  {/*RECETAS*/}
                  <PrivateRoute
                    exact
                    path="/recetas"
                    permisoCheck="recetas"
                    component={Recetas}
                  />

                  <PrivateRoute
                    exact
                    path="/receta/nuevo"
                    permisoCheck="recetas"
                    component={RecetasDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/receta/editar/:id"
                    permisoCheck="recetas"
                    component={RecetasDetail}
                  />

                  {/*CONVENIO*/}
                  <PrivateRoute
                    exact
                    path="/convenios"
                    permisoCheck="convenios"
                    component={Convenio}
                  />

                  <PrivateRoute
                    exact
                    path="/convenio/nuevo"
                    permisoCheck="convenios"
                    component={ConvenioDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/convenio/editar/:id"
                    permisoCheck="convenios"
                    component={ConvenioDetail}
                  />

                  {/*COMISION*/}
                  <PrivateRoute
                    exact
                    path="/comisiones"
                    permisoCheck="comisiones"
                    component={Comision}
                  />

                  <PrivateRoute
                    exact
                    path="/comision/nuevo"
                    permisoCheck="comisiones"
                    component={ComisionDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/comision/editar/:id"
                    permisoCheck="comisiones"
                    component={ComisionDetail}
                  />

                  {/*AGENDA*/}
                  <PrivateRoute
                    exact
                    path="/agendas"
                    permisoCheck="agendas"
                    component={Agenda}
                  />

                  <PrivateRoute
                    exact
                    path="/agenda/nuevo"
                    permisoCheck="agendas"
                    component={AgendaDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/agenda/editar/:id"
                    permisoCheck="agendas"
                    component={AgendaDetail}
                  />

                  {/*ORDEN DE SERVICIO*/}
                  <PrivateRoute
                    exact
                    path="/ordenServicios"
                    permisoCheck="ordenServicios"
                    component={OrdenServicio}
                  />
                  <PrivateRoute
                    exact
                    path="/ordenServicio/nuevo"
                    permisoCheck="ordenServicios"
                    component={OrdenServicioDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/ordenServicio/editar/:idOrdenServicio"
                    permisoCheck="ordenServicios"
                    component={OrdenServicioDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/ordenServicio/nuevo/:id"
                    permisoCheck="ordenServicios"
                    component={OrdenServicioDetail}
                  />

                  {/*INVENTARIO*/}
                  <PrivateRoute
                    exact
                    path="/entradas"
                    permisoCheck="inventarios"
                    component={Entrada}
                  />

                  {/*INVENTARIO LABORATORIO*/}
                  <PrivateRoute
                    exact
                    path="/entradasLaboratorio"
                    permisoCheck="inventarioLaboratorios"
                    component={EntradaLaboratorio}
                  />

                  {/*CITA*/}
                  <PrivateRoute
                    exact
                    path="/citas"
                    permisoCheck="citas"
                    component={Cita}
                  />

                  <PrivateRoute
                    exact
                    path="/cita/nuevo"
                    permisoCheck="citas"
                    component={CitaDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/cita/editar/:id"
                    permisoCheck="citas"
                    component={CitaDetail}
                  />

                  {/*EVALUACION MEDICA*/}
                  <PrivateRoute
                    exact
                    path="/hospitalito"
                    permisoCheck="evaluaciones"
                    component={Hospitalito}
                  />
                  <PrivateRoute
                    exact
                    path="/hospitalito/nuevaConsulta/:id"
                    permisoCheck="evaluaciones"
                    component={HospitalitoDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/hospitalito/:id"
                    permisoCheck="evaluaciones"
                    component={HospitalitoDetail}
                  />

                  <PrivateRoute
                    exact
                    path="/finanzas"
                    permisoCheck="finanzas"
                    component={Finanzas}
                  />

                  <PrivateRoute
                    exact
                    path="/finanzas/nuevo"
                    permisoCheck="finanzas"
                    component={FinanzasDetail}
                  />

                  <PrivateRoute
                    exact
                    path="/finanzas/generales"
                    permisoCheck="finanzasGenerales"
                    component={FinanzasGenerales}
                  />

                  <PrivateRoute
                    exact
                    path="/finanzas/generales/nuevo"
                    permisoCheck="finanzasGenerales"
                    component={FinanzasGeneralesDetail}
                  />

                  <PrivateRoute
                    exact
                    path="/encuestas"
                    permisoCheck="encuestas"
                    component={Encuestas}
                  />

                  <PrivateRoute
                    exact
                    path="/finanzas/editar/:id"
                    permisoCheck="finanzas"
                    component={FinanzasDetail}
                  />

                  <PrivateRoute
                    exact
                    path="/seguimientos"
                    permisoCheck="seguimientos"
                    component={Seguimiento}
                  />
                  <PrivateRoute
                    exact
                    path="/clinicaNuevaVida"
                    permisoCheck="evaluaciones"
                    component={ClinicaNuevaVida}
                  />
                  <PrivateRoute
                    exact
                    path="/clinicaNuevaVida/nuevaConsulta/:id"
                    permisoCheck="evaluaciones"
                    component={ClinicaNuevaVidaDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/clinicaNuevaVida/:id"
                    permisoCheck="evaluaciones"
                    component={ClinicaNuevaVidaDetail}
                  />
                  <Route exact path="/inicio" component={Home} />
                  <Route path="*" component={() => <Redirect to="/inicio" />} />
                </Switch>

                {
                  ((admin || ((permiso && permiso[0].doctor & 1) === 1)) || (admin || ((permiso && permiso[0].laboratorio & 1) === 1)) || (admin || ((permiso && permiso[0].radiologo & 1) === 1)) || (admin || ((permiso && permiso[0].enfermero & 1) === 1))) && (
                    <DoctoresComponent />
                  )
                }
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </>
    </Router>
  );
};

export default PrivateRoutes;

