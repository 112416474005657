////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useMemo } from 'react';
import { useModels } from "../../hooks/useModels";
import { useAuth } from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////0
import { CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import DatePicker from "../../components/DatePickerBeta"
import { RiFileExcel2Fill } from "react-icons/ri";
import moment from 'moment';
import AutocompleteDeluxe from "../../components/AutocompleteDeluxe";
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import Service from '../../services/api'
import { useAlert } from '../../hooks/useAlert'
import Swal from 'sweetalert2'
import DeleteIcon from '@mui/icons-material/Delete';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { baseStyles } from "../../utils";
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Add, Search } from "@mui/icons-material";
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
import { LoadingButton } from '@mui/lab';
import { baseUrl } from '../../utils/variables';

const Finanzas = ({ ...props }) => {
    const { permiso } = useAuth();
    const history = useHistory();
    const [propiedades, setPropiedades] = useState({
        fechaInicio: "",
        fechaFinal: "",
    });
    const { showAlert } = useAlert();
    const [perPage, setPerPage] = useState(10);
    const [pagina, setPagina] = useState(1);
    const [convenio, setConvenio] = useState(null);
    const [loadingRow, setLoadingRow] = useState({ id: 0, loading: true });

    const parametrosInicialesMemo = useMemo(() => ({
        pagina: 1,
        limite: 10,
        ordenar: 'creado.desc',
        name: "finanzas-general",
        expand: "orden.orden,convenio",
        extraParams: {
            ...propiedades
        }
    }), []);

    const [models, modelsLoading, modelsError, modelsPage, refreshModels, deleteModel] = useModels({ ...parametrosInicialesMemo });

    const onSearchClicked = async eliminado => {
        let params = {};
        if (eliminado != null) {
            params = {
                ...parametrosInicialesMemo,
                ...propiedades,
                ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
            };
        } else {
            params = {
                ...parametrosInicialesMemo,
                ...propiedades,
                ...{ pagina: pagina, limite: perPage },
            };
        }
        await refreshModels({ isCargando: true, params });
    };

    const changePage = async page => {
        setPagina(page + 1);
        let params = { ...parametrosInicialesMemo, ...{ pagina: page + 1, limite: perPage }, };
        await refreshModels({ isCargando: true, params });
    };

    const changePageRow = async per => {
        setPerPage(per);
        const params = { ...parametrosInicialesMemo, ...{ limite: per, pagina: pagina }, };
        await refreshModels({ isCargando: true, params });
    };

    const borrar = async row => {
        const text = ("¿Estás seguro de que deseas borrar?").toUpperCase();
        const res = await Swal.fire({
            title: text,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#25607A",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, Continuar!",
            cancelButtonText: "Cancelar",
        });

        if (res.isConfirmed) {
            setLoadingRow({ id: row?.idFinanza, loading: true })
            let res = await Service.post("finanzas-general/borrar", { idFinanzaGeneral: row?.idFinanzaGeneral });
            if (res?.detalle?.estatus == 200) {
                showAlert({ message: "SE BORRO CORRECTAMENTE", severity: "success" })
                refreshModels()
            } else {
                showAlert({ message: "NO SE PUDO BORRAR", severity: "error" })
            }
            setLoadingRow({ id: 0, loading: false })
        }
    }

    return (
        <TablePageLayout
            title="Finanzas Generales"
            model="finanzas-general"
            history={history}
            links={[{ label: "Finanzas Generales", to: "/finanzas/generales", icon: <QueryStatsIcon fontSize="small" /> }]}
            tableLoading={modelsLoading}
            actionButton={{ to: "/finanzas/generales/nuevo", icon: <Add />, label: "Agregar Finanzas General", permiso: "finanzasGenerales" }}
            tableErrors={modelsError}
            SearchComponents={
                <>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            labelText="Fecha Inicio"
                            variant="standard"
                            fullWidth
                            typePicker="mobile"
                            inputFormat="dd/MM/yyyy"
                            size="medium"
                            value={propiedades.fechaInicio != "" ? propiedades.fechaInicio : null}
                            onChange={(e) => setPropiedades((prevState) => ({
                                ...prevState,
                                ...prevState.propiedades,
                                fechaInicio: moment(e).format("YYYY-MM-DD H:m:s"),
                            }))}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            labelText="Fecha Final"
                            variant="standard"
                            fullWidth
                            typePicker="mobile"
                            inputFormat="dd/MM/yyyy"
                            size="medium"
                            value={propiedades.fechaFinal != "" ? propiedades.fechaFinal : null}
                            onChange={(e) => setPropiedades((prevState) => ({
                                ...prevState,
                                ...prevState.propiedades,
                                fechaFinal: moment(e).format("YYYY-MM-DD H:m:s"),
                            }))}
                        />

                    </Grid>
                    <Grid item xs={12} >
                        <AutocompleteDeluxe
                            labelToShow="Convenio"
                            labelProp="nombreConvenio"
                            nombre="convenio"
                            value={convenio}
                            setDato={setConvenio}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <LoadingButton
                            variant="contained"
                            onClick={() => onSearchClicked(null)}
                            loading={modelsLoading && true}
                            style={{
                                float: "right",
                                color: "whitesmoke",
                            }}
                            startIcon={<Search />}
                        >
                            Buscar
                        </LoadingButton>
                    </Grid>
                </>
            }
            TableComponent={
                <TablePagination
                    nombrePermiso="finanzas-generales"
                    permiso={permiso}
                    header={[]}
                    data={models}
                    paginatedApi
                    pagina={pagina}
                    paginationPageSize={perPage}
                    onRowsPerPageChangeApi={changePageRow}
                    changePageApi={changePage}
                    count={modelsPage !== null ? modelsPage.total : 0}
                    labelRowsPerPage={"Renglones por página"}
                    extraRows={[
                        {
                            prop: "creado",
                            name: "FECHA DE CREACION",
                            sortable: true,
                            cell: (row, index) => <p key={index}>{moment(row?.creado).format("dddd DD MMMM YYYY")}</p>
                        },
                        {
                            prop: "fechaInicio",
                            name: "FECHA DESDE",
                            sortable: true,
                            cell: (row, index) => <p key={index}>{moment(row?.fechaInicio).format("dddd DD MMMM YYYY")}</p>
                        },
                        {
                            prop: "fechaFinal",
                            name: "FECHA HASTA",
                            sortable: true,
                            cell: (row, index) => <p key={index}>{moment(row?.fechaFinal).format("dddd DD MMMM YYYY")}</p>
                        },
                        {
                            prop: "convenio",
                            name: "CONVENIO",
                            sortable: true,
                            cell: (row, index) => <p key={index}>{row?.idConvenio > 0 ? row?.convenio?.nombreConvenio : "N/A"}</p>
                        },
                        {
                            prop: "status",
                            name: "ESTATUS",
                            sortable: true,
                            cell: (row, index) => <p key={index}>{row?.status}</p>
                        },
                        {
                            prop: "excel",
                            name: "EXCEL",
                            sortable: true,
                            cell: (row, index) =>
                                <IconButton color="warning" aria-label="upload picture" component="span"
                                    onClick={e => {
                                        window.open(`
                                            ${baseUrl}excel/finanzas3?idFinanzaGeneral=${row?.idFinanzaGeneral}`, "_blank")
                                    }}>
                                    <RiFileExcel2Fill />
                                </IconButton>
                        },
                        {
                            prop: "accion3",
                            name: "ELIMINAR",
                            sortable: true,
                            cell: (row, index) => <p key={index}>{
                                loadingRow?.id == row?.idFinanza && loadingRow?.loading == true ?
                                    <CircularProgress />
                                    :
                                    row?.status != "PAGADO" ?
                                        <IconButton onClick={e => {
                                            borrar(row);
                                        }}>
                                            <DeleteIcon />
                                        </IconButton>
                                        :
                                        <></>
                            }</p>
                        },

                    ]}
                />
            }
        />
    )
}
export default Finanzas