import { useEffect, useRef, useState } from 'react'
import Loading from "../../../components/FullScreenLoader";

import {
    Grid,
    Button,
    TextField,
    Rating,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
    Divider
} from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BlockIcon from '@mui/icons-material/Block';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import { styled } from '@mui/material/styles';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import Services from '../../../services/api';
import Swal from 'sweetalert2';
import { usePost } from '../../../hooks/usePost';

const defaultPropiedades = {
    comentario: "",
    satisfaccion: "",
    recomendado: "",
};

const Questionario = () => {
    const [sig, setSig] = useState(false)
    const [errores, cargandoBoton, peticion, setCargando, detalles] = usePost();
    const [propiedades, setPropiedades] = useState({ ...defaultPropiedades });
    const cruz = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Publico%2Fcruz.png?alt=media&token=ff2f4cee-73f5-4426-be08-abed0d8b97e5";
    const activo = useRef(false)
    const [isActive, setIsActive] = useState(false);
    const [iconActive, setIconActive] = useState("");
    const customIcons = {
        1: {
            icon: <SentimentVeryDissatisfiedIcon className='icon1' style={{ opacity: .7 }} />,
            label: "Very Dissatisfied",
            value: "Muy Insatisfecho",
        },
        2: {
            icon: <SentimentDissatisfiedIcon className='icon2' style={{ opacity: .7 }} />,
            label: "Dissatisfied",
            value: "Insatisfecho"
        },
        3: {
            icon: <SentimentSatisfiedIcon className='icon3' style={{ opacity: .7 }} />,
            label: "Neutral",
            value: "Indiferente"
        },
        4: {
            icon: <SentimentSatisfiedAltIcon className='icon4' style={{ opacity: .7 }} />,
            label: "Satisfied",
            value: "Satisfecho"
        },
        5: {
            icon: <SentimentVerySatisfiedIcon className='icon5' style={{ opacity: .7 }} />,
            label: "Very Satisfied",
            value: "Muy Satisfecho",
        },
    };
    const StyledRating = styled(Rating)({
        '& .MuiRating-iconHover': {
            '& .icon1': {
                color: 'red ',
                fontSize: '50px'
            },
            '& .icon2': {
                color: 'orangered',
                fontSize: '50px'
            },
            '& .icon3': {
                color: 'yellow',
                fontSize: '50px'
            },
            '& .icon4': {
                color: 'yellowgreen',
                fontSize: '50px'
            },
            '& .icon5': {
                color: 'green',
                fontSize: '50px'
            }
        },
        '& .MuiRating-iconEmpty': isActive ? {
            '& .icon1': {
                color: iconActive=="icon1"?"red":"#d3d3d3",
                fontSize: "40px"
            },
            '& .icon2': {
                color: iconActive=="icon2"?"orangered":"#d3d3d3",
                fontSize: "40px"
            },
            '& .icon3': {
                color: iconActive=="icon3"?"yellow":"#d3d3d3",
                fontSize: "40px"
            },
            '& .icon4': {
                color: iconActive=="icon4"?"yellowgreen":"#d3d3d3",
                fontSize: "40px"
            },
            '& .icon5': {
                color: iconActive=="icon5"?"green":"#d3d3d3",
                fontSize: "40px"
            }
        } : {
            '& .icon1': {
                color: 'red ',
                fontSize: '40px'
            },
            '& .icon2': {
                color: 'orangered',
                fontSize: '40px'
            },
            '& .icon3': {
                color: 'yellow',
                fontSize: '40px'
            },
            '& .icon4': {
                color: 'yellowgreen',
                fontSize: '40px'
            },
            '& .icon5': {
                color: 'green',
                fontSize: '40px'
            }
        },
        '& .MuiRating-iconFilled': isActive && {
            '& .icon1': {
                color: iconActive!="icon1"?"red":"#d3d3d3",
                fontSize: "40px"
            },
            '& .icon2': {
                color: iconActive!="icon2"?"orangered":"#d3d3d3",
                fontSize: "40px"
            },
            '& .icon3': {
                color: iconActive!="icon3"?"yellow":"#d3d3d3",
                fontSize: "40px"
            },
            '& .icon4': {
                color: iconActive!="icon4"?"yellowgreen":"#d3d3d3",
                fontSize: "40px"
            },
            '& .icon5': {
                color: iconActive!="icon5"?"green":"#d3d3d3",
                fontSize: "40px"
            }
        }
    });

    function IconContainer(props) {
        const { value, ...other } = props;
        return <span {...other}>{customIcons[value].icon}</span>;
    }

    const onGuardar = async () => {
        let body = propiedades;
        const res = await Services.postWithOutToken("encuesta/guardar", body);
        if (res.cuerpo.detalle.estatus == 200) {
            await Swal.fire(
                '¡Muchas gracias por tus comentarios!',
                '¡Tus respuestas han sido registradas con éxito!',
                'success'
            );
            window.location.href = "https://clinicanuevavida.org/";
        }
    }

    useEffect(() => {
        let progress = 0;
        if (propiedades?.comentario != "") {
            progress += 1;
        }
        if (propiedades?.satisfaccion != "") {
            progress += 1;
        }
        if (propiedades?.recomendado != "") {
            progress += 1;
        }
        if (progress == 3) {
            setSig(true);
        } else {
            setSig(false);
        }
    }, [propiedades]);


    return (
        <>
            <div style={{ position: 'absolute', top: '0', right: '0', bottom: '0', left: '0' }}>
                <img src={cruz} alt="cruz"
                    style={{
                        position: 'absolute',
                        height: '100px',
                        width: '100px',
                        zIndex: '-9',
                        top: '75%',
                        left: '10%',
                        filter: 'opacity(0.2)'
                    }}
                />
                <img src={cruz} alt="cruz"
                    style={{
                        position: 'absolute',
                        height: '30px',
                        width: '30px',
                        zIndex: '-9',
                        top: '70%',
                        left: '82%',
                    }}
                />
                <img src={cruz} alt="cruz"
                    style={{
                        position: 'absolute',
                        height: '150px',
                        width: '150px',
                        zIndex: '-9',
                        top: '39%',
                        left: '75%',
                        filter: 'opacity(0.3)'
                    }}
                />
                <img src={cruz} alt="cruz"
                    style={{
                        position: 'absolute',
                        height: '100px',
                        width: '100px',
                        zIndex: '-9',
                        top: '15%',
                        left: '10%',
                        filter: 'opacity(0.2)'
                    }}
                />
                <img src={cruz} alt="cruz"
                    style={{
                        position: 'absolute',
                        height: '80px',
                        width: '80px',
                        zIndex: '-9',
                        top: '36%',
                        left: '19%',
                        filter: 'opacity(1)'
                    }}
                />
            </div>
            <div style={{ paddingTop: '20px' }}>
                <Grid container spacin={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <Typography variant="h4" style={{ textAlign: 'center', color: '#666', fontSize: "2.5rem" }}>
                        ¡Tu opinión es valiosa para nosotros!
                    </Typography>
                    <Divider /> <hr style={{ color: '#666' }} />
                    <Grid item xs={12} sm={8} md={6} lg={4} style={{ marginTop: '20px', margin: '20px' }}>
                        <p style={{ fontSize: '1.5rem', color: '#666', margin: '0', marginBottom: '20px' }}>1.	¿Cómo calificarías tu experiencia general con Clínica Nueva Vida?</p>
                        <TextField
                            style={{ marginBottom: '20px', background: '#FFF' }}
                            label="Comentario"
                            type={'text'}
                            variant='standard'
                            helperText={errores?.comentario}
                            error={Boolean(errores?.comentario)}
                            multiline
                            maxRows={3}
                            fullWidth
                            value={propiedades.comentario}
                            onChange={
                                e => {
                                    setPropiedades(prevState => ({
                                        ...prevState,
                                        ...prevState.propiedades,
                                        comentario: e.target.value
                                    }))
                                }
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} lg={4} style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', margin: '20px' }}>
                        <p style={{ fontSize: '1.5rem', color: '#666', margin: '0', marginBottom: '20px' }}>2.	¿Qué tan satisfecho/a te encuentras con la atención brindada por el personal de Clínica Nueva Vida?</p>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <StyledRating
                                style={{ justifyContent: 'center', alignItems: 'center' }}
                                name="highlight-selected-only"
                                IconContainerComponent={IconContainer}
                                highlightSelectedOnly
                                ref={activo}
                                onChange={
                                    e => {
                                        activo.current.childNodes.forEach(item => {
                                            if (item.localName == "label") {
                                                if (item.className.split(" ")[1] != "MuiRating-pristine") {
                                                    var res = item.childNodes[0].className.split(" ").some(e => e == "MuiRating-iconActive");
                                                    if(res){
                                                        setIsActive(true);
                                                        setIconActive(item.childNodes[0].childNodes[0].classList[2])
                                                    }
                                                }
                                            }
                                        });
                                        const calificacion = customIcons[e.target.value].value
                                        setPropiedades(prevState => ({
                                            ...prevState,
                                            ...prevState.propiedades,
                                            satisfaccion: calificacion
                                        }))
                                    }
                                }
                            />
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={8} md={6} lg={4} style={{ marginTop: '20px', margin: '20px' }}>
                        <p style={{ fontSize: '1.5rem', color: '#666', margin: '0', marginBottom: '20px' }}>3.	Considerando tu experiencia con nosotros, ¿qué probabilidades hay de que nos recomiendes a un familiar o amigo?</p>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel
                                    style={{ color: '#666' }}
                                    value="si"
                                    control={<Radio />}
                                    label="Sí"
                                    onChange={e => {
                                        setPropiedades(prevState => ({
                                            ...prevState,
                                            ...prevState.propiedades,
                                            recomendado: e.target.value
                                        }))
                                    }}
                                />
                                <FormControlLabel
                                    style={{ color: '#666' }}
                                    value="no"
                                    control={<Radio />}
                                    label="No"
                                    onChange={e => {
                                        setPropiedades(prevState => ({
                                            ...prevState,
                                            ...prevState.propiedades,
                                            recomendado: e.target.value
                                        }))
                                    }}
                                />
                                <FormControlLabel
                                    style={{ color: '#666' }}
                                    value="talvez"
                                    control={<Radio />}
                                    label="Tal vez"
                                    onChange={e => {
                                        setPropiedades(prevState => ({
                                            ...prevState,
                                            ...prevState.propiedades,
                                            recomendado: e.target.value
                                        }))
                                    }}
                                />
                            </RadioGroup>
                        </FormControl>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                            <Button
                                onClick={() => onGuardar()}
                                disabled={!sig} style={{ background: !sig ? '#FFF' : '#155480', color: sig ? '#FFF' : '#155480', width: '48%', transition: 'background 1s', border: sig ? 'none' : '2px solid #155480' }}>
                                {sig ? <>Guardar<NavigateNextIcon style={{ margin: '0 0 1px 10px' }} /></> : <>Guardar<BlockIcon style={{ margin: '0 0 1px 10px' }} /></>}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
export default Questionario;