import React, { useState } from "react";
import { Divider, Grid, Paper, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import { useModel } from "../../../../hooks/useModel";
import { useAlert } from "../../../../hooks/useAlert";
import { useAuth } from "../../../../hooks/useAuth";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
export const ExploracionFisicaComponent = ({
  idEvaluacion,
  idOrdenServicio,
  exploracion,
  setGuardado,
  setDisabledByName,
  ...props
}) => {
  const classes = useStyles();
  const id = idEvaluacion;
  const { showAlert } = useAlert();
  const [admin] = useLocalStorage("admin", null);
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const { permiso } = useAuth();
  const [propiedades, setPropiedades] = useState(
    exploracion
      ? exploracion
      : {
          cabezaCuello:
            "Movimientos oculares conservados, no hay pterigion; Mucosa oral con buena hidratación, amígdalas sin hiperemia ni megalias; Conductos auditivos externos sin perforaciones, sin taponamientos; No hay adenopatías palpables, cuello cilíndrico y simétrico, tiroides no palpable, tráquea móvil indolora, movimientos de flexo extensión conservada, rotación lateral derecha e izquierda bien, inclinación derecha e izquierda normal, fuerza muscular sin compromiso.",
          cardiorespiratorio:
            "Rítmicos, sin soplos o ruidos agregados auscultables; Murmullo vesicular conservado. Campos pulmonares bien ventilados.",
          abdomen:
            "Blando, depresible no doloroso a la palpación superficial ni profunda, peristalsis audible, sin masas palpables ni megalias, sin defectos herniarios",
          extremidadesSuperiores:
            "Eutróficas, íntegras y simétricas de buen tono y fuerza muscular con arcos de movilidad conservados, Apley posterior y anterior, Yergason, Jobe, Hawking Kennedy, Phallen, Finkelstein Negativos; Reflejos osteotendinosos presentes",
          extremidadesInferiores:
            "Sin dolor a la movilización activa y pasiva, maniobras de Lassegue, Patrick, Ficat, cajon anterior y posterior, bostezo, Mc Murray en ambas piernas negativos; Reflejos osteotendinosos presentes",
          columna:
            "Movimientos de flexión, extensión, rotación lateral derecha e izquierda, inclinación normales, fuerza muscular conservada, Maniobras de Adams, Thomas, Kernick normales, signos de Lasegue y Patrick ausentes.",
          descripcion: null,
          personalMedico: null,
          medico: null,
          cp: null,
        }
  );

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "evaluaciones",
      id,
      redirectOnPost: false,
    });

  useEffect(() => {
    if (Object.keys(modelError)?.length > 0 && !updateModelLoading) {
      console.log(modelError);
      Object.keys(modelError).forEach((error) => {
        console.log(modelError[error]);
        showAlert({ message: modelError[error], severity: "warning" });
      });
      setGuardado(false);
    }
  }, [modelError]);

  const onGuardar = async () => {
    const body = propiedades;

    let prePropiedades = { ...propiedades };
    let falla = false;
    let valoresPropiedades = Object.values(prePropiedades);
    Object.keys(prePropiedades).forEach((prop) => {
      if (prop != "personalEnfermeria" || prop != "medico" || prop != "cp") {
        if (!falla) {
          if (valoresPropiedades[prop] == "") {
            falla = true;
          }
        }
      }
    });

    setDisabledByName("exploracionFisica", !falla);

    if (falla) {
      showAlert({
        message: "Todos los campos son requeridos",
        severity: "warning",
      });
      return;
    }

    let data = {
      idOrdenServicio,
      idEvaluacion,
      idSucursal: 1,
      ExploracionFisica: body,
    };
    await updateModel(data, true, "clinicaNuevaVida/nuevaConsulta");
    setGuardado(true);
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <strong>{"EXPLORACIÓN FÍSICA"}</strong>
          </div>
          <Divider />
        </Grid>
        <Grid
          item
          md={12}
          style={{ display: "flex", flexWrap: "wrap", marginTop: "-15px" }}
        >
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Cabeza y Cuello"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.cabezaCuello}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  cabezaCuello: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Cardiorrespiratorio"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.cardiorespiratorio}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  cardiorespiratorio: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Abdomen"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.abdomen}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  abdomen: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Extremidades Superiores"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.extremidadesSuperiores}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  extremidadesSuperiores: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Extremidades Inferiores"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.extremidadesInferiores}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  extremidadesInferiores: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Columna"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              size="medium"
              value={propiedades.columna}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  columna: e.target.value,
                }))
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading ||
                updateModelLoading ||
                admin ||
                (permiso && permiso[0]["evaluaciones"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<Save />}
              style={{ color: "#fff" }}
            >
              GUARDAR
              {/* {(!editing ? "GUARDAR" : "MODIFICAR")} */}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
