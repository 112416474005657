import { Grid, TextField } from "@mui/material"
import React from "react"

const antecedentesGineco = ({ datos, setDatos, ...props }) => {
    console.log(datos)
    return (
        <>
            <Grid item xs={12} sx={{ margin: "20px 0", color: "#FFFFFF", background: "black", padding: "10px" }}>
                ANTECEDENTES GINECOOBSTETRICOS
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"menarca".toLocaleUpperCase()}
                    value={datos?.antecedentesGineco?.menarca}
                    placeholder={"menarca".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesGineco: { ...datos?.antecedentesGineco, menarca: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"ivsa".toLocaleUpperCase()}
                    value={datos?.antecedentesGineco?.ivsa}
                    placeholder={"ivsa".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesGineco: { ...datos?.antecedentesGineco, ivsa: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"g".toLocaleUpperCase()}
                    value={datos?.antecedentesGineco?.g}
                    placeholder={"g".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesGineco: { ...datos?.antecedentesGineco, g: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"p".toLocaleUpperCase()}
                    value={datos?.antecedentesGineco?.p}
                    placeholder={"p".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesGineco: { ...datos?.antecedentesGineco, p: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"c".toLocaleUpperCase()}
                    value={datos?.antecedentesGineco?.c}
                    placeholder={"c".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesGineco: { ...datos?.antecedentesGineco, c: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"a".toLocaleUpperCase()}
                    value={datos?.antecedentesGineco?.a}
                    placeholder={"a".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesGineco: { ...datos?.antecedentesGineco, a: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"fum".toLocaleUpperCase()}
                    value={datos?.antecedentesGineco?.fum}
                    placeholder={"fum".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesGineco: { ...datos?.antecedentesGineco, fum: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"doc".toLocaleUpperCase()}
                    value={datos?.antecedentesGineco?.doc}
                    placeholder={"doc".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesGineco: { ...datos?.antecedentesGineco, doc: e.target.value } }) }}
                />
            </Grid>

            <Grid xs={12} md={4} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"mpf".toLocaleUpperCase()}
                    value={datos?.antecedentesGineco?.mpf}
                    placeholder={"mpf".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, antecedentesGineco: { ...datos?.antecedentesGineco, mpf: e.target.value } }) }}
                />
            </Grid>

        </>
    )
}

export default antecedentesGineco