import GroupIcon from "@mui/icons-material/Group";
import InventoryIcon from "@mui/icons-material/Inventory";
import { FaBoxes } from "react-icons/fa";
import { ImBoxAdd } from "react-icons/im";
import { ContactsRounded } from "@mui/icons-material";
import { FaUserInjured } from "react-icons/fa";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MedicationLiquidIcon from "@mui/icons-material/MedicationLiquid";
import DateRangeIcon from "@mui/icons-material/DateRange";
import HandshakeIcon from "@mui/icons-material/Handshake";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { FaPenAlt, FaChartPie } from "react-icons/fa";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import CampaignIcon from "@mui/icons-material/Campaign";
import { RiServiceFill } from "react-icons/ri";
import { BsFileEarmarkRichtextFill } from "react-icons/bs";
import PercentIcon from '@mui/icons-material/Percent';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import { BsJournalMedical } from "react-icons/bs"
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CalculateIcon from '@mui/icons-material/Calculate';

const points = {
  title: "Navigation Bar",
  icon: "",
  links: [
    {
      type: "NavLink",
      to: "/usuarios",
      icon: <GroupIcon style={{ color: "white" }} />,
      title: "Usuarios",
      permission: "usuarios",

    },
    {
      type: "NavLink",
      to: "/finanzas",
      icon: <CalculateIcon style={{ color: "white" }} />,
      title: "Finanzas",
      permission: "finanzas",

    },
    {
      type: "NavLink",
      to: "/finanzas/generales",
      icon: <CalculateIcon style={{ color: "white" }} />,
      title: "Finanzas Generales",
      permission: "finanzasGenerales",

    },
    {
      type: "NavLink",
      to: "/encuestas",
      icon: <FaChartPie style={{ color: "white" }} />,
      title: "Encuestas",
      permission: "encuestas",

    },
    {
      id: "Inventario",
      type: "Collapse",
      items: [
        { to: "/productos", title: "Productos", id: "Inventario", icon: <FaBoxes style={{ color: "#1D5E7B" }} /> },
        { to: "/entradas", title: "Entradas/Productos", id: "Inventario", icon: <ImBoxAdd style={{ color: "#1D5E7B" }} /> }
      ],
      icon: <InventoryIcon />,
      title: "Inventario",
      permission: "inventarios",

    },
    {
      id: "Inventario Laboratorio",
      type: "Collapse",
      items: [
        { to: "/productosLaboratorio", id: "Inventario Laboratorio", title: "Productos", icon: <FaBoxes style={{ color: "#1D5E7B" }} /> },
        { to: "/entradasLaboratorio", id: "Inventario Laboratorio", title: "Entradas/Productos", icon: <ImBoxAdd style={{ color: "#1D5E7B" }} /> }
      ],
      icon: <InventoryIcon />,
      title: "Inventario Laboratorio",
      permission: "inventarioLaboratorios",

    },
    {
      id: "Evaluaciones",
      type: "Collapse",
      items: [
        { to: "/clinicaNuevaVida", title: "Clinica Nueva Vida", id: "Evaluaciones", icon: <MedicalInformationIcon style={{ color: "#1D5E7B" }} /> },
        { to: "/hospitalito", title: "LHospitalito", id: "Evaluaciones", icon: <MedicalInformationIcon style={{ color: "#1D5E7B" }} /> }
      ],
      icon: <MedicalInformationIcon />,
      title: "Evaluaciones",
      permission: "evaluaciones",

    },
    {
      type: "Correo",
      to: "/correoPromociones",
      icon: <InventoryIcon />,
      title: "Correos Promoción",
      permission: "correosPromociones"
    },
    {
      type: "NavLink",
      to: "/grupos",
      icon: <AllInboxIcon style={{ color: "white" }} />,
      title: "Grupo Contactos",
      permission: "campanas"
    },
    {
      type: "NavLink",
      to: "/recepcionPacientes",
      icon: <ContactsRounded style={{ color: "white" }} />,
      title: "Historial Pacientes",
      permission: "recepcionPacientes",

    },
    {
      type: "NavLink",
      to: "/pacientes",
      icon: <FaUserInjured style={{ color: "white" }} />,
      title: "Recepcion Pacientes",
      permission: "pacientes",

    },
    {
      type: "NavLink",
      to: "/evaluaciones",
      icon: <AssignmentIcon style={{ color: "white" }} />,
      title: "Tipo Evaluaciones",
      permission: "tipoEvaluaciones",

    },
    {
      type: "NavLink",
      to: "/paquetes",
      icon: <MedicationLiquidIcon style={{ color: "white" }} />,
      title: "Paquetes",
      permission: "paquetes",

    },
    {
      type: "NavLink",
      to: "/agendas",
      icon: <DateRangeIcon style={{ color: "white" }} />,
      title: "Agenda",
      permission: "agendas",

    },
    {
      type: "NavLink",
      to: "/convenios",
      icon: <HandshakeIcon style={{ color: "white" }} />,
      title: "Convenios",
      permission: "convenios",

    },
    {
      type: "NavLink",
      to: "/valoraciones",
      icon: <FactCheckIcon style={{ color: "white" }} />,
      title: "Valoraciones",
      permission: "valoraciones",
    },
    {
      type: "NavLink",
      to: "/recetas",
      icon: <FaPenAlt style={{ color: "white" }} />,
      title: "Recetas",
      permission: "recetas",
    },
    {
      type: "NavLink",
      to: "/reportes",
      icon: <FaChartPie style={{ color: "white" }} />,
      title: "Reportes",
      permission: "reportes",
    },
    {
      type: "NavLink",
      to: "/sucursales",
      icon: <StoreMallDirectoryIcon style={{ color: "white" }} />,
      title: "Sucursales",
      permission: "sucursales",
    },
    {
      type: "NavLink",
      to: "/campanas",
      icon: <CampaignIcon style={{ color: "white" }} />,
      title: "Campañas",
      permission: "campanas",
    },
    {
      type: "NavLink",
      to: "/servicios",
      icon: <RiServiceFill style={{ color: "white" }} />,
      title: "Servicios",
      permission: "servicios",
    },
    {
      type: "NavLink",
      to: "/seguimientos",
      icon: <QueryStatsIcon style={{ color: "white" }} />,
      title: "Seguimientos",
      permission: "seguimientos",
    },
    {
      type: "NavLink",
      to: "/comisiones",
      icon: <PercentIcon style={{ color: "white" }} />,
      title: "Comisiones",
      permission: "comisiones",
    },
    {
      type: "NavLink",
      to: "/ordenServicios",
      icon: <BsFileEarmarkRichtextFill style={{ color: "white", fontSize: "large" }} />,
      title: "Orden de servicios",
      permission: "ordenServicios",
    },
    {
      type: "Cita",
      to: "/citas",
      icon: <BsJournalMedical style={{ color: "white", fontSize: "large" }} />,
      title: "Citas",
      permission: "citas",
    },
  ],
};

export { points };
