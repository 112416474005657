import { CircularProgress } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Service from "../services/api"
import {
    Autocomplete,
    TextField
} from '@mui/material'
import { propertyAccesor } from '../utils'

const AutocompleteDeluxe = ({ nombre, dato, setDato, labelToShow, labelProp, grouped, filtro = {}, ...props }) => {
    const [datos, setDatos] = useState(null)
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(true)
    const getDatos = async () => {
        setLoading(true);
        let filtroString = ""
        if (Object.keys(filtro).length > 0) {
            Object.keys(filtro).forEach(f => {
                filtroString += ("&" + f + "=" + filtro[f])
            })
        }
        let res = await Service.get(nombre + "?limite=9999999" + filtroString)
        setDatos(res?.resultado)
        setLoading(false);
    }
    useEffect(() => {
        getDatos()
    }, [])
    return (
        <div style={{ width: "100%" }}>
            {
                !loading
                    ?
                    <Autocomplete
                        sx={{ width: "100%" }}
                        disablePortal
                        id="autocompleteDeluxeVersion-1.0"
                        value={dato}
                        groupBy={grouped ? (option) => option.CAPITULO : null}
                        onChange={(e, v) => setDato(v)}
                        options={datos?.filter(dato => (dato[labelProp].toLowerCase().trim()).includes((search.toLowerCase().trim()))).slice(0, 10)}
                        getOptionLabel={(option) => propertyAccesor(option, labelProp)}
                        renderInput={(params) => <TextField size='Small' variant="standard" sx={{ width: "100%" }} onChange={e => { setSearch(e.target.value) }} {...params} label={labelToShow} />}
                        {...props}
                    />
                    :
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <CircularProgress />
                    </div>
            }
        </div >
    )
}

export default AutocompleteDeluxe