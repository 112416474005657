import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Breadcrumbs,
  Paper,
  TextField,
  Typography,
  Chip,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Alert,
  TableCell,
  TableRow,
} from "@mui/material";
import Divider from '@mui/material/Divider';
import { Edit, Home, Search } from "@mui/icons-material";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { styled } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import { PhotoCamera } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton';
import Geocode from "react-geocode";
import { useGet } from "../../hooks/useGet";
import { usePost } from "../../hooks/usePost";
import FullScreenLoader from "../../components/FullScreenLoader";
import Map from "../../components/GoogleMap";
import InputRegex from "../../components/InputRegex";
import { baseStyles, breadCrumbsStyles, tableCellStyles, tableRowStyles } from "../../utils";
import FirebaseService from "../../services/firebase";
import { useAuth } from "../../hooks/useAuth";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import {useAlert} from "./../../hooks/useAlert";
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';

const useStyles = baseStyles();
const StylesBreadCrumb = breadCrumbsStyles()(Chip);

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  color: "black",
  padding: theme.spacing(1),
  BorderStyle: "outset"
}));

const Index = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const { showAlert } = useAlert();
  const editing = match.params.idSucursal !== undefined;
  const [errores, cargandoBoton, peticion, detalles] = usePost();
  const [zoom, setZoom] = useState(12);
  const [archivo, setArchivo] = useState();
  const { permiso } = useAuth();
  const [admin] = useLocalStorage("admin", null);
  const [urlImg, setUrlImg] = useState("");
  const [arrayHorarioInitial, setArrayHorarioInitial] = useState([
    { dia: 'LUNES', fechaAbierto: "07:00", show: false, fechaCerrado: "20:00", fechaDescansoInicio: "15:00", fechaDescansoFin: "16:00", orden: 1 },
    { dia: 'MARTES', fechaAbierto: "07:00", show: false, fechaCerrado: "20:00", fechaDescansoInicio: "15:00", fechaDescansoFin: "16:00", orden: 2 },
    { dia: 'MIERCOLES', fechaAbierto: "07:00", show: false, fechaCerrado: "20:00", fechaDescansoInicio: "15:00", fechaDescansoFin: "16:00", orden: 3 },
    { dia: 'JUEVES', fechaAbierto: "07:00", show: false, fechaCerrado: "20:00", fechaDescansoInicio: "15:00", fechaDescansoFin: "16:00", orden: 4 },
    { dia: 'VIERNES', fechaAbierto: "07:00", show: false, fechaCerrado: "20:00", fechaDescansoInicio: "15:00", fechaDescansoFin: "16:00", orden: 5 },
    { dia: 'SABADO', fechaAbierto: "07:00", show: false, fechaCerrado: "20:00", fechaDescansoInicio: "15:00", fechaDescansoFin: "16:00", orden: 6 },
    { dia: 'DOMINGO', fechaAbierto: "07:00", show: false, fechaCerrado: "20:00", fechaDescansoInicio: "15:00", fechaDescansoFin: "16:00", orden: 7 }
  ]);
  const [propiedades, setPropiedades] = useState({
    nombreSucursal: "",
    telefono: "",
    correo: "",
    etiqueta: "",
    comentario: "",
    ubicacion: null,
    direccion: "",
    urlFoto: "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sucursales%2FImagen_asdasd_894?alt=media&token=78da34f7-90e0-4785-923e-d357ff71755c",
    horarios: [],
  });
  const inputDireccion = useRef();

  const [sucursal, cargando] = useGet({
    initUrl: "sucursal/index",
    params: {
      idSucursal: match.params.idSucursal,
    },
    action: editing ? true : false,
  });
  const deleteImg = async (url) => {
    await FirebaseService.deleteFile("Sucursal/", url);
    setUrlImg("");
  }

  const handleFileRead = async ({ target }) => {
    const file = target.files[0];
    if (file !== undefined) {
      setArchivo(file);
      setPropiedades((prevState) => ({
        ...prevState,
        ...prevState.propiedades,
        urlFoto: URL.createObjectURL(target.files[0]),
      }));
    }
  };

  const onMapsSearch = (address) => {
    Geocode.setApiKey("AIzaSyCi1iCZoUFisXN9MgPS1N0tUyRBn8bC4u4");
    Geocode.setLanguage("es");
    Geocode.setRegion("es");
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        const currentPosition = {
          lat: lat,
          lng: lng,
        };
        setPropiedades(prevState => ({
          ...prevState,
          ...prevState.propiedades,
          ubicacion: currentPosition
        }));
        setZoom(19);
      }, (error) => {
        if (error && address) {
          showAlert({
            message: "Dirección no encontrada favor de especificar la direccion un poco más, verificar tener el orden correcto Ciudad, Calle, Número y Colonia.",
            severity: "warning",
          })
        }
      },
    );
  };

  const guardarSucursal = async () => {
    let urlFileSucursal = "";
    let obj = propiedades;
    if (!editing) obj.horarios = arrayHorarioInitial;
    const sucursal = propiedades.nombreSucursal.split(" ").join("").toLowerCase();
    if (archivo) {
      const ranm = Math.floor(Math.random() * 1000);
      const pathFirebase = "Sucursales/" + "Imagen" + "_" + sucursal + "_" + ranm;
      urlFileSucursal = await FirebaseService.uploadFile(pathFirebase, archivo);
      obj = { ...obj, urlFoto: urlFileSucursal };
    }
    peticion("sucursal/guardar", obj, "/sucursales");
  };

  useEffect(() => {
    if (editing) {
      let data = sucursal && sucursal.resultado && sucursal.resultado[0];
      setPropiedades(data);
      onMapsSearch(data?.direccion);
    }
  }, [sucursal, editing]);

  useEffect(() => {
    if (detalles && detalles?.estatus == 400) {
      if (urlImg != "" && urlImg != undefined) {
        deleteImg(urlImg);
      }
    }
  }, [detalles]);

  if (editing && cargando) return <FullScreenLoader />

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.typographyModified} gutterBottom style={{ textAlign: "center" }}>
        {editing ? `EDITANDO SUCURSAL` : `NUEVA SUCURSAL`}
      </Typography>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <StylesBreadCrumb
                component="a"
                label="Inicio"
                icon={<Home fontSize="small" />}
                onClick={() => history.push("/")}
              />
              <StylesBreadCrumb
                component="a"
                label="Sucursales"
                icon={<StoreMallDirectoryIcon fontSize="small" />}
                onClick={() => history.push("/sucursales")}
              />
              <StylesBreadCrumb
                component="a"
                icon={editing ? <Edit fontSize="small" /> : <AddBusinessIcon fontSize="small" />}
                label={editing ? `Editar Sucursal: ${propiedades.nombreSucursal}` : `Crear Sucursal`}
              />
            </Breadcrumbs>
            <br></br>
          </Grid>

          <Grid item xs={12}>
            <Div><strong>{"INFORMACIÓN DE LA SUCURSAL"}</strong></Div>
            <Divider />
          </Grid>

          <Grid item xs={12} md={4} >
            <TextField
              label="Nombre de la sucursal"
              variant="standard"
              fullWidth
              helperText={errores?.nombreSucursal}
              error={Boolean(errores?.nombreSucursal)}
              size="medium"
              onChange={(e) => setPropiedades(prevState => ({
                ...prevState,
                ...prevState.propiedades,
                nombreSucursal: e.target.value
              }))
              }
              inputProps={{
                value: propiedades.nombreSucursal,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <InputRegex
              id="standard-multiline-static"
              label="Celular"
              fullWidth
              typeInput="phone"
              value={propiedades.telefono}
              maxLength={10}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  telefono: e.target.value.replaceAll(/[-() ]/g, ''),
                }))
              }
              helperText={errores?.telefono}
              error={Boolean(errores?.telefono)}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              label="Correo Electronico"
              variant="standard"
              fullWidth
              helperText={errores?.correo}
              error={Boolean(errores?.correo)}
              size="medium"
              onChange={(e) => setPropiedades(prevState => ({
                ...prevState,
                ...prevState.propiedades,
                correo: e.target.value
              }))
              }
              inputProps={{
                value: propiedades.correo,
              }}

            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Etiqueta"
              variant="standard"
              fullWidth
              helperText={errores?.etiqueta}
              error={Boolean(errores?.etiqueta)}
              size="medium"
              maxLength={50}
              onChange={(e) => setPropiedades(prevState => ({
                ...prevState,
                ...prevState.propiedades,
                etiqueta: e.target.value
              }))
              }
              inputProps={{
                value: propiedades.etiqueta,
              }}

            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label="Comentarios"
              fullWidth
              variant="standard"
              maxRows={5}
              multiline
              helperText={errores?.comentario}
              error={Boolean(errores?.comentario)}
              size="medium"
              onChange={(e) => setPropiedades(prevState => ({
                ...prevState,
                ...prevState.propiedades,
                comentario: e.target.value
              }))
              }
              style={{ width: '100%' }}
              inputProps={{
                value: propiedades.comentario,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={3}>
                <Typography variant="h5" gutterBottom>
                  IMAGEN
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={7} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '30px' }}>
                <Divider style={{ marginBottom: "2%" }} />
                {
                  <CardMedia
                    component="img"
                    image={propiedades.urlFoto}
                    title=""
                    style={{ width: "50%", height: "auto", objectFit: 'cover' }}
                  />

                }

              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={2}>
                <div>
                  <input
                    hidden
                    accept="image/x-png,image/jpeg,image/gif"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    onChange={handleFileRead}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      style={{ color: 'white' }}
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      <PhotoCamera />
                    </Button>
                  </label>
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/** */}
          <Grid item xs={12}>
            <Div><strong>{"UBICACIÓN DE LA SUCURSAL"}</strong></Div>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={6}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel ref={inputDireccion} style={{ color: errores?.direccion && "red" }}>
                Ciudad, Calle, Número, Colonia
              </InputLabel>
              <OutlinedInput
                label="Ciudad, Calle, Número, Colonia"
                style={{ width: "100%" }}
                error={Boolean(errores?.direccion)}
                onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  direccion: e.target.value
                }))
                }
                endAdornment={
                  <InputAdornment position="end">
                    <Search
                      style={{ cursor: "pointer" }}
                      onClick={() => onMapsSearch(propiedades?.direccion)}
                    ></Search>
                  </InputAdornment>
                }
                inputProps={{
                  ref: inputDireccion,
                  value: propiedades.direccion,
                }}
              />
              <FormHelperText style={{ color: "red" }}>
                {errores?.direccion}
              </FormHelperText>
            </FormControl>
          </Grid>
          {/** */}
          <Grid item xs={12}>
            {errores?.ubicacion && (
              <>
                <Alert variant="filled" severity="error">
                  {errores?.ubicacion}
                </Alert>
                <br></br>
              </>
            )}
            <Map position={propiedades.ubicacion} zoom={zoom} />
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.paper}>
        <Grid item xs={12} xl={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={(admin === true 
                || (permiso && permiso[0]["sucursales"] & 2) === 2) ? false : true}
              loading={cargandoBoton}
              variant="contained"
              onClick={guardarSucursal}
              size="large"
              className={classes.successButton}
              style={{ color: "#fff" }}
            >
              GUARDAR SUCURSAL
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>
    </div >
  );
}

export default Index;