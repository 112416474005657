import React from "react";
import packageJson from "../../package.json";
import { useLocalStorage } from "./useLocalStorage";
import "firebase/firestore";

global.appVersion = packageJson.version;

const online = window.navigator.onLine;

const CacheBusterContext = React.createContext();

export function CacheBusterProvider(props) {
  const [isView, setIsView] = useLocalStorage("isView", false);
  const refreshCacheAndReload = React.useCallback(() => {
    if (caches && online) {
      const newIsView = isView;
      window.localStorage.clear();
      setIsView(newIsView);
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    online
      ? window.location.reload()
      : console.log("No se puede actualizar en modo offline");
  }, [isView,setIsView]);


  const memData = React.useMemo(() => {
    return { refreshCacheAndReload };
  }, [refreshCacheAndReload]);

  return <CacheBusterContext.Provider value={memData} {...props} />;
}

export function useCacheBuster() {
  const context = React.useContext(CacheBusterContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: cache buster context not defined.";
  }
  return context;
}