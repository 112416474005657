/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Button, CircularProgress, Divider, FormControlLabel, Grid, IconButton, Paper, Switch, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import MultiFile from "../../../../components/MultiFile";
import { PhotoCamera, Save } from "@mui/icons-material";
import { useAlert } from "../../../../hooks/useAlert";
import { useModel } from "../../../../hooks/useModel";
import FirebaseService from "../../../../services/firebase";
import { useAuth } from "../../../../hooks/useAuth";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useEffect } from "react";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
export const EstudiosGabineteComponent = ({
  idEvaluacion,
  idOrdenServicio,
  estudiosGabinete,
  setGuardado,
  setDisabledByName,
  ...props
}) => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const id = idEvaluacion;
  const [admin] = useLocalStorage("admin", null);
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const { permiso } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [files, setFiles] = useState(estudiosGabinete ? estudiosGabinete.archivosRadios ? estudiosGabinete.archivosRadios.length > 0 ? estudiosGabinete.archivosRadios : [] : [] : []);
  const [propiedades, setPropiedades] = useState(
    estudiosGabinete
      ? estudiosGabinete
      : {
        electrocardiograma: "",
        interpretacionRadiologia: "",
        espirometria: "",
        espiro: false,
        psico:false,
        psicologia: "",
        customDoc: "",
        urlCustomDoc: "",
        customDocFirebaseName: "",
        electro: false,
        radio: false,
      }
  );

  const uploadFile = async file => {
    const ranm = Math.floor(Math.random() * 10000);
    let firebaseName = ranm + "_" + moment(new Date()).format("DD_MM_YYYY_HH_ss_mm") + "_" + file.name;
    const pathFirebase = "CustomDocuments" + "/" + firebaseName;
    let url = await FirebaseService.uploadFile(pathFirebase, file);
    return { firebaseName, url };
  };

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "evaluaciones",
      id,
      redirectOnPost: false,
    });

  useEffect(() => {
    if (Object.keys(modelError)?.length > 0 && !updateModelLoading) {
      console.log(modelError)
      Object.keys(modelError).forEach(
        error => {
          console.log(modelError[error])
          showAlert({ message: modelError[error], severity: "warning" })
        }
      )
      setGuardado(false)
    }
  }, [modelError])

  const onGuardar = async () => {
    const body = propiedades;

    let prePropiedades = { ...propiedades };
    let falla = false;
    let valoresPropiedades = Object.values(prePropiedades);
    Object.keys(prePropiedades).forEach((prop) => {
      if (prop != "personalEnfermeria" || prop != "medico" || prop != "cp") {
        if (!falla) {
          if (valoresPropiedades[prop] == "") {
            falla = true;
          }
        }
      }
    });

    setDisabledByName("estudiosGabinete", !falla);

    if (falla) {
      showAlert({
        message: "Todos los campos son requeridos",
        severity: "warning",
      });
      return;
    }

    let data = {
      idOrdenServicio,
      idEvaluacion,
      idSucursal: 1,
      EstudiosGabinete: body,
      ArchivosRadios: files,
    };
    await updateModel(data, true, "clinicaNuevaVida/nuevaConsulta");
    setGuardado(true);
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <strong>{"ESTUDIOS DE GABINETE"}</strong>
          </div>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <p>
              <strong>{"NOTA: "}</strong>Ingresar int0erpretación de estudios
              realizados en cada uno de los campos.
            </p>
          </div>
          <Divider />
        </Grid>

        <Grid
          item
          md={12}
          style={{ display: "flex", flexWrap: "wrap", marginTop: "-15px" }}
        >
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Electrocardiograma"
              variant="standard"
              maxRows={5}
              multiline
              fullWidth
              disabled={!propiedades?.electro}
              size="medium"
              value={propiedades.electrocardiograma}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  electrocardiograma: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Espirometría"
              variant="standard"
              maxRows={5}
              multiline
              disabled={!propiedades?.espiro}
              fullWidth
              size="medium"
              value={propiedades.espirometria}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  espirometria: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Psicología"
              variant="standard"
              maxRows={5}
              multiline
              disabled={!propiedades?.psico}
              fullWidth
              size="medium"
              value={propiedades.psicologia}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  psicologia: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
            <TextField
              label="Interpretacion Radiológica"
              variant="standard"
              maxRows={5}
              disabled={!propiedades?.radio}
              multiline
              fullWidth
              size="medium"
              value={propiedades.interpretacionRadiologia}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  interpretacionRadiologia: e.target.value,
                }))
              }
            />
          </Grid>
          {
            !loadingFile
              ?
              <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
                Documento Personalizado
                <IconButton color="primary" aria-label="Documento Personalizado" component="label">
                  <input onChange={async (e) => {
                    if (e?.target?.files[0]?.type == "application/pdf") {
                      setLoadingFile(true)
                      let res = await uploadFile(e?.target?.files[0]);
                      setPropiedades({
                        ...propiedades,
                        customDoc: e?.target?.files[0]?.name,
                        urlCustomDoc: res?.url,
                        customDocFirebaseName: res?.firebaseName
                      });
                      setLoadingFile(false)
                    } else {
                      showAlert({
                        message: "el documento solo puede ser PDF",
                        severity: "warning"
                      });
                    }
                  }} hidden accept="application/pdf" type="file" />
                  <UploadFileIcon />
                </IconButton>
                <br />
                DOCUMENTO: {
                  propiedades?.customDoc != "" && <a href={propiedades?.urlCustomDoc} target="_blank">{propiedades?.customDoc}</a>
                }
              </Grid>
              :
              <Grid item xs={12} md={12} style={{ padding: "0px 5px" }}>
                Subiendo Archivo
                <CircularProgress />
              </Grid>
          }

          <FormControlLabel control={<Switch
            checked={propiedades?.espiro}
            onChange={e => {
              setPropiedades({
                ...propiedades,
                espiro: e.target.checked
              });
            }}
          />} label="ARCHIVOS ESPIROMETRIA" />
          <FormControlLabel control={<Switch
            checked={propiedades?.psico}
            onChange={e => {
              setPropiedades({
                ...propiedades,
                psico: e.target.checked
              });
            }}
          />} label="ARCHIVOS PSICOLOGÍA" />
          <FormControlLabel control={<Switch
            checked={propiedades?.electro}
            onChange={e => {
              setPropiedades({
                ...propiedades,
                electro: e.target.checked
              });
            }}
          />} label="ARCHIVOS ELECTROCARDIOGRAMA" />
          <FormControlLabel control={<Switch
            checked={propiedades?.radio}
            onChange={e => {
              setPropiedades({
                ...propiedades,
                radio: e.target.checked
              });
            }}
          />} label="ARCHIVOS RADIOGRAFIA" />

          <Grid item xs={12} md={12} style={{ margin: "5px" }}>
            <MultiFile loading={loading} setLoading={setLoading} files={files} setFiles={setFiles} firebaseFolder={"EstudiosGabinete"} />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                loading ? true :
                  modelLoading ||
                    updateModelLoading ||
                    admin ||
                    (permiso && permiso[0]["evaluaciones"] & 2) === 2
                    ? false
                    : true
              }
              loading={loading ? true : modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<Save />}
              style={{ color: "#fff" }}
            >
              GUARDAR
              {/* {(!editing ? "GUARDAR" : "MODIFICAR")} */}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
