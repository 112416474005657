import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment-timezone";
import { LoadingButton } from "@mui/lab";
import CachedIcon from '@mui/icons-material/Cached';
import { Divider } from "rsuite";
import { Save } from "@mui/icons-material";
import {
  tableCellStyles,
  tableRowStyles,
  tablaPrimaryStyle,
} from "../../../../utils";
import { useAlert } from "../../../../hooks/useAlert";

import imagen from "../../../../assets/no-photo-available.jpg";
import { useModel } from "../../../../hooks/useModel";
import { useAuth } from "../../../../hooks/useAuth";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import FirebaseService from "../../../../services/firebase";
import AudiometriaChart from "../../../../components/AudiometriaChart";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const useTable = tablaPrimaryStyle();

export const ParaclinicosComponent = ({
  idEvaluacion,
  idOrdenServicio,
  paraclinicos,
  audioValores,
  setGuardado,
  detallesPaciente,
  setDisabledByName,
  ...props
}) => {
  const classes = useStyles();
  const tablas = useTable();
  const { showAlert } = useAlert();
  const id = idEvaluacion;
  const [admin] = useLocalStorage("admin", null);
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const { permiso } = useAuth();
  const [grafica, setGrafica] = useState(false);
  const [imageFromDB, setImageFromDB] = useState(audioValores ? true : false);
  const [audiometriaCheck, setAudiometriaCheck] = useState(audioValores ? true : false);
  const [paraclinicosCheck, setParaclinicosCheck] = useState(paraclinicos ? true : false);
  const [archivo, setArchivo] = useState();
  const [foto, setFoto] = useState("");
  const [monoI, setMonoI] = useState([]);
  const [monoD, setMonoD] = useState([]);
  const [propiedades, setPropiedades] = useState(
    paraclinicos
      ? paraclinicos
      : {
        usaLentes: "",
        daltonismo: "",
        campimetriaDerecha: "",
        campimetriaIzquierda: "",
        vlao: "",
        vlod: "",
        vloi: "",
        vcao: "",
        vcod: "",
        vcoi: "",
        cp: "",
        personalEnfermeria: "",
        medico: "",
      }
  );
  const [audiometria, setAudiometria] = useState(audioValores ? audioValores : {
    monoauralIzquierdo: "",
    monoauralDerecho: "",
    hbc: "",
    interpretacion: "",
    urlFoto: imagen,
  });
  const audiometriaInputsDefault = [
    { number: 500, izquierda: "", derecha: "" },
    { number: 1000, izquierda: "", derecha: "" },
    { number: 2000, izquierda: "", derecha: "" },
    { number: 3000, izquierda: "", derecha: "" },
    { number: 4000, izquierda: "", derecha: "" },
    { number: 6000, izquierda: "", derecha: "" },
    { number: 8000, izquierda: "", derecha: "" },
  ];
  const filtradoAudiometira = () => {
    let copyAudio = [];
    audiometriaInputsDefault.forEach((audio) => {
      if (audio.number == 500) {
        copyAudio.push({
          number: 500,
          izquierda: audioValores.i500,
          derecha: audioValores.d500,
        });
      }
      if (audio.number == 1000) {
        copyAudio.push({
          number: 1000,
          izquierda: audioValores.i1000,
          derecha: audioValores.d1000,
        });
      }
      if (audio.number == 2000) {
        copyAudio.push({
          number: 2000,
          izquierda: audioValores.i2000,
          derecha: audioValores.d2000,
        });
      }
      if (audio.number == 3000) {
        copyAudio.push({
          number: 3000,
          izquierda: audioValores.i3000,
          derecha: audioValores.d3000,
        });
      }
      if (audio.number == 4000) {
        copyAudio.push({
          number: 4000,
          izquierda: audioValores.i4000,
          derecha: audioValores.d4000,
        });
      }
      if (audio.number == 6000) {
        copyAudio.push({
          number: 6000,
          izquierda: audioValores.i6000,
          derecha: audioValores.d6000,
        });
      }
      if (audio.number == 8000) {
        copyAudio.push({
          number: 8000,
          izquierda: audioValores.i8000,
          derecha: audioValores.d8000,
        });
      }
    });
    return copyAudio;
  };

  const [audiometriaInputs, setAudiometriaInputs] = useState(
    audioValores ? filtradoAudiometira() : [...audiometriaInputsDefault]
  );

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({ name: "evaluaciones", id, redirectOnPost: false });

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    if (file !== undefined) {
      const ranm = Math.floor(Math.random() * 10000);
      const ranm2 = Math.floor(Math.random() * 10000);
      const pathFirebase = "Audiometria/" + ranm + ranm2 + "_" + file.name;
      let urlFoto = await FirebaseService.uploadFile(pathFirebase, file);

      setArchivo(file);
      setAudiometria((prevState) => ({
        ...prevState,
        ...prevState.audiometria,
        urlFoto: urlFoto,
      }));
    }
  };

  const formatearAudiometria = () => {
    let d500;
    let d1000;
    let d2000;
    let d3000;
    let d4000;
    let d6000;
    let d8000;
    let i500;
    let i1000;
    let i2000;
    let i3000;
    let i4000;
    let i6000;
    let i8000;

    audiometriaInputs.forEach((audio) => {
      if (audio.number == 500) {
        d500 = audio.derecha;
        i500 = audio.izquierda;
      }
      if (audio.number == 1000) {
        d1000 = audio.derecha;
        i1000 = audio.izquierda;
      }
      if (audio.number == 2000) {
        d2000 = audio.derecha;
        i2000 = audio.izquierda;
      }
      if (audio.number == 3000) {
        d3000 = audio.derecha;
        i3000 = audio.izquierda;
      }
      if (audio.number == 4000) {
        d4000 = audio.derecha;
        i4000 = audio.izquierda;
      }
      if (audio.number == 6000) {
        d6000 = audio.derecha;
        i6000 = audio.izquierda;
      }
      if (audio.number == 8000) {
        d8000 = audio.derecha;
        i8000 = audio.izquierda;
      }
    });

    return {
      ...audiometria,
      d500,
      d1000,
      d2000,
      d3000,
      d4000,
      d6000,
      d8000,
      i500,
      i1000,
      i2000,
      i3000,
      i4000,
      i6000,
      i8000,
    };
  };

  useEffect(() => {
    let _audio = formatearAudiometria()
    let monoI = ((parseFloat(_audio.i500) + parseFloat(_audio.i1000) + parseFloat(_audio.i2000) + parseFloat(_audio.i3000)) / 4) * 0.8;
    let monoD = ((parseFloat(_audio.d500) + parseFloat(_audio.d1000) + parseFloat(_audio.d2000) + parseFloat(_audio.d3000)) / 4) * 0.8;

    let mayor =
      monoI === monoD
        ?
        monoI
        :
        monoI > monoD
          ?
          monoI
          :
          monoD

    let menor =
      monoI === monoD
        ?
        monoD
        :
        monoI > monoD
          ?
          monoD
          :
          monoI

    let _hbc = (((menor * 7) + mayor) / 8)

    setAudiometria({ ...audiometria, monoauralIzquierdo: monoI.toString(), monoauralDerecho: monoD.toString(), hbc: _hbc.toString() })
  }, [audiometriaInputs]);

  const getMonoI = () => {
    let _audio = formatearAudiometria()
    let arr = [];
    arr.push(parseFloat(_audio?.i500));
    arr.push(parseFloat(_audio?.i1000));
    arr.push(parseFloat(_audio?.i2000));
    arr.push(parseFloat(_audio?.i3000));
    arr.push(parseFloat(_audio?.i4000));
    arr.push(parseFloat(_audio?.i6000));
    arr.push(parseFloat(_audio?.i8000));
    return arr
  };
  const getMonoD = () => {
    let _audio = formatearAudiometria()
    let arr = [];
    arr.push(parseFloat(_audio?.d500));
    arr.push(parseFloat(_audio?.d1000));
    arr.push(parseFloat(_audio?.d2000));
    arr.push(parseFloat(_audio?.d3000));
    arr.push(parseFloat(_audio?.d4000));
    arr.push(parseFloat(_audio?.d6000));
    arr.push(parseFloat(_audio?.d8000));
    return arr
  };

  useEffect(() => {
    if (Object.keys(modelError)?.length > 0 && !updateModelLoading) {
      console.log(modelError)
      Object.keys(modelError).forEach(
        error => {
          console.log(modelError[error])
          showAlert({ message: modelError[error], severity: "warning" })
        }
      )
      setGuardado(false)
    }
  }, [modelError])

  const onGuardar = async () => {
    let body = propiedades;
    let body2 = formatearAudiometria();
    body = { ...body };

    setDisabledByName("paraclinicos", true);

    let data = {};
    let url = "";

    if (audiometriaCheck && paraclinicosCheck) {
      console.log(detallesPaciente)
      if (foto === "") {
        showAlert({
          message: "Favor de actualizar la tabla de audiometria",
          severity: "warning"
        });
        return
      } else {
        if (detallesPaciente) {
          const ranm = Math.floor(Math.random() * 1000);
          const ranm1 = Math.floor(Math.random() * 1000);
          const ranm2 = Math.floor(Math.random() * 1000);
          const ranm3 = Math.floor(Math.random() * 1000);
          const nombre = detallesPaciente?.nombreCompleto?.replaceAll(' ', '_');
          const pathFirebase = "Audiometria/" + ranm + "_" + ranm1 + "_" + detallesPaciente?.ine + "_" + nombre + "_" + moment(new Date()).format("DD_MM_YYYY_HH_mm_ss") + "_" + ranm2 + "_" + ranm3;
          console.log(pathFirebase)
          url = await FirebaseService.uploadFile(pathFirebase, foto);
        } else {
          showAlert({
            message: "no se pudo cargar el nombre del paciente",
            severity: "warning"
          });
          return;
        }
      }
      data = {
        idOrdenServicio,
        idEvaluacion,
        idSucursal: 1,
        Paraclinicos: body,
        Audiometria: { ...body2, urlFoto: url },
      };
    } else if (audiometriaCheck) {
      if (foto === "") {
        showAlert({
          message: "Favor de actualizar la tabla de audiometria",
          severity: "warning"
        });
      } else {
        const ranm = Math.floor(Math.random() * 1000);
        const ranm1 = Math.floor(Math.random() * 1000);
        const ranm2 = Math.floor(Math.random() * 1000);
        const ranm3 = Math.floor(Math.random() * 1000);
        const pathFirebase = "Audiometria/" + ranm + "_" + ranm1 + moment(new Date()).format("DD_MM_YYYY_HH_mm_ss") + ranm2 + "_" + ranm3;
        url = await FirebaseService.uploadFile(pathFirebase, foto);
      }
      data = {
        idOrdenServicio,
        idEvaluacion,
        idSucursal: 1,
        Audiometria: { ...body2, urlFoto: url },
      };
    } else if (paraclinicosCheck) {
      data = {
        idOrdenServicio,
        idEvaluacion,
        idSucursal: 1,
        Paraclinicos: body,
      };
    }

    await updateModel(data, true, "clinicaNuevaVida/nuevaConsulta");
    setGuardado(true);
  };
  return (
    <>
      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={8}>
          <FormControlLabel
            className={classes.switchColor}
            label="Estudio Visual"
            control={
              <Switch
                checked={paraclinicosCheck}
                onChange={(e) => setParaclinicosCheck(e.target.checked)}
              />
            }
          />
        </Grid>
        {
          paraclinicosCheck && (<>

            <Grid item xs={12}>
              <div style={{ textAlign: "center" }}>
                <strong>{"PARACLINICOS"}</strong>
              </div>
              <Divider />
            </Grid>

            <Grid
              item
              md={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Grid item xs={12} md={2} style={{ paddingRight: "10px" }}>
                <FormControl variant="standard" fullWidth size="large">
                  <InputLabel variant="standard" id="demo-select-small">
                    Usa Lentes
                  </InputLabel>
                  <Select
                    variant="standard"
                    size="large"
                    id="demo-select-small"
                    label="Usa Lentes"
                    value={propiedades.usaLentes}
                    onChange={(e) =>
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        usaLentes: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="Si">Si</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={2} style={{ padding: "0px 10px 0px 0px" }}>
                <FormControl variant="standard" fullWidth size="large">
                  <InputLabel variant="standard" id="demo-select-small">
                    Daltonismo
                  </InputLabel>
                  <Select
                    variant="standard"
                    size="large"
                    id="demo-select-small"
                    label="Daltonismo"
                    value={propiedades.daltonismo}
                    onChange={(e) =>
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        daltonismo: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="Positivo">Positivo</MenuItem>
                    <MenuItem value="Negativo">Negativo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4} style={{ padding: "0px 10px 0px 0px" }}>
                <TextField
                  label="Campimetría Derecha"
                  variant="standard"
                  maxRows={5}
                  multiline
                  fullWidth
                  size="medium"
                  value={propiedades.campimetriaDerecha}
                  onChange={(e) =>
                    setPropiedades((prevState) => ({
                      ...prevState,
                      ...prevState.propiedades,
                      campimetriaDerecha: e.target.value,
                    }))
                  }
                />
              </Grid>

              <Grid item xs={12} md={4} style={{ padding: "0px 10px 0px 0px" }}>
                <TextField
                  label="Campimetría Izquierda"
                  variant="standard"
                  maxRows={5}
                  multiline
                  fullWidth
                  size="medium"
                  value={propiedades.campimetriaIzquierda}
                  onChange={(e) =>
                    setPropiedades((prevState) => ({
                      ...prevState,
                      ...prevState.propiedades,
                      campimetriaIzquierda: e.target.value,
                    }))
                  }
                />
              </Grid>
            </Grid>

            <Grid
              item
              md={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginTop: "15px",
              }}
            >
              <TableContainer
                className={classes.container}
                style={{ borderRadius: "1px" }}
              >
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className={tablas.TablePrimary}
                >
                  <TableHead>
                    <TableRowModify
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <Grid item md={6}>
                        <TableCellModify
                          style={{
                            fontWeight: "600",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Grid item md={12}>
                            Vista Lejana
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <TableCellModify>VL AO</TableCellModify>
                            <TableCellModify>VL OD</TableCellModify>
                            <TableCellModify>VL OI</TableCellModify>
                          </Grid>
                        </TableCellModify>
                      </Grid>
                      <Grid item md={6}>
                        <TableCellModify
                          style={{
                            fontWeight: "600",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Grid item md={12}>
                            Vista Cercana
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <TableCellModify>VC AO</TableCellModify>
                            <TableCellModify>VC OD</TableCellModify>
                            <TableCellModify>VC OI</TableCellModify>
                          </Grid>
                        </TableCellModify>
                      </Grid>
                    </TableRowModify>
                  </TableHead>
                  <TableBody>
                    <TableRowModify
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <Grid item md={6}>
                        <TableCellModify
                          style={{
                            fontWeight: "600",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Grid
                            item
                            md={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <TableCellModify>
                              <FormControl>
                                <InputLabel style={{ textAlign: "center" }}>
                                  VL AO
                                </InputLabel>
                                <OutlinedInput
                                  label="VL AO"
                                  size="small"
                                  aria-describedby="outlined-weight-helper-text"
                                  value={propiedades.vlao}
                                  onChange={(e) =>
                                    setPropiedades((prevState) => ({
                                      ...prevState,
                                      ...prevState.propiedades,
                                      vlao: e.target.value,
                                    }))
                                  }
                                />
                              </FormControl>
                            </TableCellModify>
                            <TableCellModify>
                              <FormControl>
                                <InputLabel style={{ textAlign: "center" }}>
                                  VL OD
                                </InputLabel>
                                <OutlinedInput
                                  label="VL OD"
                                  size="small"
                                  aria-describedby="outlined-weight-helper-text"
                                  value={propiedades.vlod}
                                  onChange={(e) =>
                                    setPropiedades((prevState) => ({
                                      ...prevState,
                                      ...prevState.propiedades,
                                      vlod: e.target.value,
                                    }))
                                  }
                                />
                              </FormControl>
                            </TableCellModify>
                            <TableCellModify>
                              <FormControl>
                                <InputLabel style={{ textAlign: "center" }}>
                                  VL OI
                                </InputLabel>
                                <OutlinedInput
                                  label="VL OI"
                                  size="small"
                                  aria-describedby="outlined-weight-helper-text"
                                  value={propiedades.vloi}
                                  onChange={(e) =>
                                    setPropiedades((prevState) => ({
                                      ...prevState,
                                      ...prevState.propiedades,
                                      vloi: e.target.value,
                                    }))
                                  }
                                />
                              </FormControl>
                            </TableCellModify>
                          </Grid>
                        </TableCellModify>
                      </Grid>
                      <Grid item md={6}>
                        <TableCellModify
                          style={{
                            fontWeight: "600",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Grid
                            item
                            md={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <TableCellModify>
                              <FormControl>
                                <InputLabel style={{ textAlign: "center" }}>
                                  VC AO
                                </InputLabel>
                                <OutlinedInput
                                  label="VC AO"
                                  size="small"
                                  aria-describedby="outlined-weight-helper-text"
                                  value={propiedades.vcao}
                                  onChange={(e) =>
                                    setPropiedades((prevState) => ({
                                      ...prevState,
                                      ...prevState.propiedades,
                                      vcao: e.target.value,
                                    }))
                                  }
                                />
                              </FormControl>
                            </TableCellModify>
                            <TableCellModify>
                              <FormControl>
                                <InputLabel style={{ textAlign: "center" }}>
                                  VC OI
                                </InputLabel>
                                <OutlinedInput
                                  label="VC OI"
                                  size="small"
                                  aria-describedby="outlined-weight-helper-text"
                                  value={propiedades.vcod}
                                  onChange={(e) =>
                                    setPropiedades((prevState) => ({
                                      ...prevState,
                                      ...prevState.propiedades,
                                      vcod: e.target.value,
                                    }))
                                  }
                                />
                              </FormControl>
                            </TableCellModify>
                            <TableCellModify>
                              <FormControl>
                                <InputLabel style={{ textAlign: "center" }}>
                                  VC OI
                                </InputLabel>
                                <OutlinedInput
                                  label="VC OI"
                                  size="small"
                                  aria-describedby="outlined-weight-helper-text"
                                  value={propiedades.vcoi}
                                  onChange={(e) =>
                                    setPropiedades((prevState) => ({
                                      ...prevState,
                                      ...prevState.propiedades,
                                      vcoi: e.target.value,
                                    }))
                                  }
                                />
                              </FormControl>
                            </TableCellModify>
                          </Grid>
                        </TableCellModify>
                      </Grid>
                    </TableRowModify>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>)
        }
      </Grid>

      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={8}>
          <FormControlLabel
            className={classes.switchColor}
            label="Audiometría"
            control={
              <Switch
                checked={audiometriaCheck}
                onChange={(e) => setAudiometriaCheck(e.target.checked)}
              />
            }
          />
        </Grid>

        <Grid
          item
          md={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginTop: "15px",
          }}
        >


          {audiometriaCheck && (
            <>
              <Grid item xs={12}>
                <div style={{ textAlign: "center" }}>
                  <strong>{"AUDIOMETRÍA"}</strong>
                </div>
                <Divider />
              </Grid>
              <Grid
                item
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  marginTop: "15px",
                }}
              >
                <Grid
                  item
                  md={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "0px 20px",
                  }}
                >
                  <TableContainer
                    className={classes.container}
                    style={{ borderRadius: "1px" }}
                  >
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      className={tablas.TablePrimary}
                    >
                      <TableHead>
                        <TableRowModify>
                          <TableCellModify
                            style={{ fontWeight: "600", textAlign: "center" }}
                          >
                            #
                          </TableCellModify>
                          <TableCellModify
                            style={{ fontWeight: "600", textAlign: "center" }}
                          >
                            Izquierdo
                          </TableCellModify>
                          <TableCellModify
                            style={{ fontWeight: "600", textAlign: "center" }}
                          >
                            Derecho
                          </TableCellModify>
                        </TableRowModify>
                      </TableHead>
                      <TableBody>
                        {audiometriaInputs.map((data, index) => (
                          <TableRowModify>
                            <TableCellModify
                              style={{ fontWeight: "600", textAlign: "center" }}
                            >
                              {data.number}
                            </TableCellModify>
                            <TableCellModify
                              style={{ fontWeight: "600", textAlign: "center" }}
                            >
                              <Grid
                                item
                                xs={12}
                                style={{ padding: "0px 10px 0px 0px" }}
                              >
                                <TextField
                                  label="Izquierda"
                                  variant="outlined"
                                  fullWidth
                                  size="medium"
                                  value={data.izquierda}
                                  onChange={(e) => {
                                    let valoresAudio = [...audiometriaInputs];
                                    valoresAudio[index].izquierda =
                                      e.target.value;
                                    setAudiometriaInputs(valoresAudio);
                                  }}
                                />
                              </Grid>
                            </TableCellModify>
                            <TableCellModify
                              style={{ fontWeight: "600", textAlign: "center" }}
                            >
                              <Grid
                                item
                                xs={12}
                                style={{ padding: "0px 10px 0px 0px" }}
                              >
                                <TextField
                                  label="Derecha"
                                  value={data.derecha}
                                  variant="outlined"
                                  fullWidth
                                  size="medium"
                                  onChange={(e) => {
                                    let valoresAudio = [...audiometriaInputs];
                                    valoresAudio[index].derecha = e.target.value;
                                    setAudiometriaInputs(valoresAudio);
                                  }}
                                />
                              </Grid>
                            </TableCellModify>
                          </TableRowModify>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item md={6} style={{ alignContent: "center" }}>
                  <Grid item xs={12}>
                    <TextField
                      label="Monoaural izquierdo"
                      variant="standard"
                      disabled
                      fullWidth
                      size="medium"
                      value={audiometria.monoauralIzquierdo}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Monoaural derecho"
                      variant="standard"
                      disabled
                      fullWidth
                      size="medium"
                      value={audiometria.monoauralDerecho}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="HBC"
                      variant="standard"
                      disabled
                      fullWidth
                      size="medium"
                      value={audiometria.hbc}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Interpretación"
                      variant="standard"
                      fullWidth
                      maxRows={7}
                      multiline
                      size="medium"
                      value={audiometria.interpretacion}
                      onChange={(e) =>
                        setAudiometria((prevState) => ({
                          ...prevState,
                          ...prevState.audiometria,
                          interpretacion: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                </Grid>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
                  <IconButton onClick={
                    e => {
                      let _audio = formatearAudiometria()
                      setMonoI([
                        _audio?.i500,
                        _audio?.i1000,
                        _audio?.i2000,
                        _audio?.i3000,
                        _audio?.i4000,
                        _audio?.i6000,
                        _audio?.i8000,
                      ])
                      setMonoD([
                        _audio?.d500,
                        _audio?.d1000,
                        _audio?.d2000,
                        _audio?.d3000,
                        _audio?.d4000,
                        _audio?.d6000,
                        _audio?.d8000,
                      ])
                    }
                  }>
                    <CachedIcon />
                  </IconButton>
                  ACTUALIZAR TABLA
                </div>
                <Grid item xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                  <AudiometriaChart
                    monoI={monoI}
                    monoD={monoD}
                    foto={foto}
                    setFoto={setFoto}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        component={Paper}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading ||
                  updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["evaluaciones"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<Save />}
              style={{ color: "#fff" }}
            >
              GUARDAR
              {/* {(!editing ? "GUARDAR" : "MODIFICAR")} */}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
