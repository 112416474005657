/* eslint-disable eqeqeq */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect } from 'react';
import DatePicker from "../../components/DatePickerBeta"
import { useModel } from "../../hooks/useModel";
import { useAuth } from "../../hooks/useAuth";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import PageHeader from "../../components/PageHeader";
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from 'moment';
import { makeStyles } from "@mui/styles";
import Loading from "../../components/FullScreenLoader";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { tableCellStyles, tableRowStyles, entradasStyle, capitalizeFirst } from '../../utils';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Edit } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import HandshakeIcon from '@mui/icons-material/Handshake';
import AutocompleteDeluxe from '../../components/AutocompleteDeluxe';
import { useAlert } from '../../hooks/useAlert';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));

const FinanzasDetail = ({ match, history, dialog }) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const id = match.params.id;
    const classes = useStyles();
    const editing = !!id;
    const { showAlert } = useAlert();
    const { permiso } = useAuth();
    const [propiedades, setPropiedades] = useState({
        fechaInicio: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        fechaFinal: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        tipoPago: "CREDITO",
    });

    const [convenio, setConvenio] = useState(null);

    const [idSucursal, setIdSucursal] = useState(1);

    const { model, modelLoading, modelError, updateModel, updateModelLoading } =
        useModel({
            name: "finanzas-general",
            expand: "orden.orden",
            id,
            redirectOnPost: true,
        });
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const onGuardar = async () => {
        if (propiedades?.fechaInicio == "") {
            showAlert({ message: 'Fecha de inicio es requerida', severity: "warning", });
            return;
        }
        if (propiedades?.fechaFinal == "") {
            showAlert({ message: 'Fecha de final es requerida', severity: "warning", });
            return;
        }
        updateModel({ ...propiedades, idConvenio: convenio != null ? convenio?.idConvenio: 0, idSucursal }, true, "finanzas/generales");
    };
    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        let mounted = true;
        if (mounted && model) {
            setPropiedades(model);
        }
        return () => {
            mounted = false;
            setPropiedades({
                fechaInicio: moment(new Date()).format("yyyy/MM/DD"),
                fechaFinal: moment(new Date()).format("yyyy/MM/DD"),
            });
        };
    }, [model]);

    if (modelLoading) return <Loading />;

    return (
        <>
            <PageHeader
                history={history}
                title={(!editing ? "NUEVO" : "EDITAR") + " CONJUNTO DE ORDENES DE SERVICIO"}
                links={[
                    {
                        to: "/finanzas/generales",
                        icon: <HandshakeIcon fontSize="small" />,
                        label: "Finanzas Generales",
                    },
                    {
                        to: !editing ? "/finanazas/generales/nuevo" : "/finanazas/generales/editar/" + id,
                        icon: !editing ? <Add /> : <Edit />,
                        label: !editing
                            ? "Nuevo"
                            : "Editar"
                    },
                ]}
                editing={editing}
                dialog={dialog}
            />
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid component={Paper} container className={classes.paper} spacing={1} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel id="Sucursal-Label">Sucursal</InputLabel>
                            <Select
                                labelId="Sucursal-Label"
                                id="Sucursal-Id"
                                value={idSucursal}
                                label="Sucursal"
                                onChange={e => {
                                    setIdSucursal(e.target.value)
                                }}
                            >
                                <MenuItem value={1}>Clínica Nueva Vida</MenuItem>
                                <MenuItem value={2}>LHospitalito</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <DatePicker
                        labelText="Fecha Inicio"
                        variant="standard"
                        fullWidth
                        typePicker="mobile"
                        inputFormat="dd/MM/yyyy"
                        size="medium"
                        value={propiedades.fechaInicio != "" ? propiedades.fechaInicio : null}
                        onChange={(e) => setPropiedades((prevState) => ({
                            ...prevState,
                            ...prevState.propiedades,
                            fechaInicio: moment(e).format("YYYY-MM-DD H:m:s"),
                        }))}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DatePicker
                        labelText="Fecha Final"
                        variant="standard"
                        fullWidth
                        typePicker="mobile"
                        inputFormat="dd/MM/yyyy"
                        size="medium"
                        value={propiedades.fechaFinal != "" ? propiedades.fechaFinal : null}
                        onChange={(e) => setPropiedades((prevState) => ({
                            ...prevState,
                            ...prevState.propiedades,
                            fechaFinal: moment(e).format("YYYY-MM-DD H:m:s"),
                        }))}
                    />

                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth variant='standard'>
                        <InputLabel id="id-status-pago">Tipo de pago</InputLabel>
                        <Select
                            labelId="id-status-pago"
                            id="id-status-pago-select"
                            value={propiedades?.tipoPago}
                            label="Tipo de pago"
                            onChange={e => {
                                setPropiedades({ ...propiedades, tipoPago: e.target.value })
                            }}
                        >
                            <MenuItem value={"CONTADO"}>CONTADO</MenuItem>
                            <MenuItem value={"TODOS"}>TODOS</MenuItem>
                            <MenuItem value={"CREDITO"}>CREDITO</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} >
                    <AutocompleteDeluxe
                        labelToShow="Convenio"
                        labelProp="nombreConvenio"
                        nombre="convenio"
                        value={convenio}
                        setDato={setConvenio}
                    />
                </Grid>
            </Grid>
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                        <LoadingButton
                            disabled={
                                modelLoading || updateModelLoading ||
                                    (permiso && permiso[0]["finanzasGenerales"] & 2) === 2
                                    ? false
                                    : true
                            }
                            loading={modelLoading || updateModelLoading}
                            variant="contained"
                            size="medium"
                            onClick={e => { onGuardar() }}
                            startIcon={<SaveIcon />}
                            style={{ color: "#fff" }}
                        >
                            {(!editing ? "GUARDAR" : "MODIFICAR")}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default FinanzasDetail;