/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { AppProvider } from "./hooks/useApp";
import { FormProvider } from "./hooks/useFormulario";
import { AuthProvider, useAuth } from "./hooks/useAuth";
import { useMediaQuery } from "@mui/material";
import { CacheBusterProvider } from "./hooks/CacheBuster";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import { createTheme } from '@mui/material/styles';
import './App.css';
import { useApp } from "./hooks/useApp";
import FullScreenLoader from "./components/FullScreenLoader";
import { AlertProvider, useAlert } from "./hooks/useAlert";
import { NotificationsProvider } from "./hooks/useNotifications";
import Collapse from '@mui/material/Collapse';
import MuiAlert from '@mui/material/Alert';
import esAR from 'rsuite/locales/es_AR';
import { CustomProvider } from 'rsuite';
import InternetDetector from "./components/offline";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "whiteSmoke"
        }
      }
    }
  }
});

const RootComponent = () => {

  const { MuiTheme } = useApp();
  const { session, sessionLoading } = useAuth();
  const { alertas } = useAlert();
  const isSmUp = useMediaQuery(MuiTheme.breakpoints.up("md"));

  const AlertMemo = useMemo(
    () => (
      <>
        {
          alertas.length > 0 && (
            <div style={{ position: 'fixed', bottom: '0', right: '0', top: 'auto', left: isSmUp ? '70%' : '0', justifyContent: 'flex-end' }}>
              {
                alertas && alertas.map(
                  (alerta, i) => {
                    return (
                      <Collapse key={i} in={alerta.open}>
                        <Alert style={{ margin: '10px' }} severity={alerta.severity} elevation={6} variant="filled">
                          {alerta.message}
                        </Alert>
                      </Collapse>
                    );
                  }
                )
              }
            </div>
          )
        }
      </>
    ),
    [alertas]
  );

  if (sessionLoading) return <FullScreenLoader />;

  return (
    <>
      {!session ? (
        <FormProvider>
          <PublicRoutes />
        </FormProvider>
      ) :
        (
          <PrivateRoutes />
        )
      }
      {AlertMemo}
    </>
  );
};

const App = () => {
  return (

    <CacheBusterProvider>

      <AppProvider>
        <AlertProvider>
          <CustomProvider locale={esAR}>
            <NotificationsProvider>
              <AuthProvider>
                <RootComponent />
              </AuthProvider>
              <InternetDetector />
            </NotificationsProvider>
          </CustomProvider>
        </AlertProvider>
      </AppProvider>
    </CacheBusterProvider>
  );
};

export default App;