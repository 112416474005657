/* eslint-disable no-mixed-operators */
/* eslint-disable no-useless-concat */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useCallback, useRef, useMemo } from 'react'
import { NavLink, useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useModels } from "../../hooks/useModels";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import { Grid, Popover, CircularProgress, Checkbox, TextField, FormControlLabel, Switch, Menu, MenuItem, FormControl, InputLabel, Select } from "@mui/material";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import { useAlert } from "./../../hooks/useAlert";
import moment from 'moment';
import { DateRangePicker } from 'rsuite';
import { styled } from '@mui/material/styles';
import Services from '../../services/api';
import 'rsuite/dist/rsuite.css';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Tabs from '../../components/TabsRework';
import Swal from "sweetalert2";
import FirebaseService from "../../services/firebase";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { baseStyles, capitalizeFirst } from "../../utils";
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import IconButton from "@mui/material/IconButton";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { Add, Delete, Edit, Search } from "@mui/icons-material";
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import DoneIcon from '@mui/icons-material/Done';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import SaveIcon from '@mui/icons-material/Save';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CommentIcon from '@mui/icons-material/Comment';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import FactCheckIcon from '@mui/icons-material/FactCheck';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
import { baseUrl } from "../../utils/variables";
import AsyncAutocompleteSearchRework from '../../components/AsyncAutocompleteSearchRework';
import MultiCheck from "../../components/multiCheck";

const useStyles = baseStyles();
const Input = styled('input')({
  display: 'none',
});
const Valoraciones = () => {
  ////////////////////////////////////////////////////////////////////////
  //                              Vars                                  //
  ////////////////////////////////////////////////////////////////////////
  const classes = useStyles();
  const filename = useRef();
  const history = useHistory()
  const { showAlert } = useAlert();
  const [archivo, setArchivo] = useState(null);
  const [clear] = useState(false);
  const [openMenuRow, setOpenMenuRow] = useState(null);
  const [comentario, setComentario] = useState('NO APTO PROBLEMAS VISUALES');
  const [anchorEl, setAnchorEl] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [pagina, setPagina] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [admin] = useLocalStorage("admin", null);
  const [buttonActive, setButtonActive] = useState("");
  const [idConvenio, setIdConvenio] = useState("");
  const [seguimiento, setSeguimiento] = useState({
    l: false,
    m: false,
    a: false,
    rx: false,
  });
  const [nombreC, setNombreC] = useState({
    nombres: 'null',
    apellidoPaterno: 'null',
    apellidoMaterno: 'null',
  });
  const [pos, setPos] = useState(0);
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const lref = useRef();
  const mref = useRef();
  const aref = useRef();
  const rxref = useRef();
  const nombresref = useRef();
  const apellidoPaternoRef = useRef();
  const apellidoMaternoRef = useRef();
  const { user, permiso } = useAuth();

  const paramsMemo = useMemo(() => ({
    ordenar: "nombreConvenio.asc",
  }), []);

  const [propiedades, setPropiedades] = useState({
    idConvenio,
    nombreCompleto: "",
    fechaInicio: moment().startOf("month").format("yyyy/MM/DD"),
    fechaFinal: moment(new Date()).format("yyyy/MM/DD"),
    status: 'PROCESO',
    estado: "",
    idSucursal
  });

  const parametrosInicialesMemo = useMemo(() => ({
    pagina: 1, limite: 10, name: "valoracion", ordenar: 'idValoracion.desc', expand: "sucursal, convenio", extraParams: { ...propiedades, idSucursal }
  }), []);

  const [models, modelsLoading, modelsError, modelsPage, refreshModels, deleteModel] = useModels({ ...parametrosInicialesMemo });
  ////////////////////////////////////////////////////////////////////////
  //                           Funcions Def                             //
  ////////////////////////////////////////////////////////////////////////
  const getStatus = row => {
    return (
      row.i == null ?
        <SentimentNeutralIcon style={{ fontSize: '40px', color: '#DAD083' }} />
        :
        row.i
          ?
          <SentimentSatisfiedAltIcon style={{ fontSize: '40px', color: 'green' }} />
          :
          <SentimentVeryDissatisfiedIcon style={{ fontSize: '40px', color: 'red' }} />
    )
  }

  const changePos = async _pos => {
    if (_pos == 0) {
      setPropiedades({ ...propiedades, status: 'PROCESO' });
      let params = { ...parametrosInicialesMemo, ...propiedades, status: 'PROCESO', };
      await refreshModels({ isCargando: true, params });
    } else if (_pos == 1) {
      setPropiedades({ ...propiedades, status: 'FINALIZADO' });
      let params = { ...parametrosInicialesMemo, ...propiedades, status: 'FINALIZADO', };
      await refreshModels({ isCargando: true, params });
    }
    setPos(_pos);
  }

  const onSearchClicked = async (valor) => {
    let params = {};
    if (valor != null && valor <= 1) {
      params = { ...parametrosInicialesMemo, ...propiedades, ...{ pagina: pagina, limite: perPage, eliminado: valor } };
    } else if (valor === 'no-apto') {
      params = {
        ...parametrosInicialesMemo, ...propiedades,
        ...{ pagina: pagina, limite: perPage, estado: valor },
      }
    } else if (valor === 'aprobado') {
      params = { ...parametrosInicialesMemo, ...propiedades, ...{ pagina: pagina, limite: perPage, estado: valor } }
    } else {
      params = { ...parametrosInicialesMemo, ...propiedades, ...{ pagina: pagina, limite: perPage } };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async page => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async per => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  const onDeleteClicked = async (row, type) => {
    try {
      setOpenMenuRow(false);
      await Swal.fire({
        title: type,
        text: `¿Desea ${type} el dato seleccionado "${capitalizeFirst(
          row.nombreCompleto.toLowerCase()
        )}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34AA44",
        cancelButtonColor: "#E72C33",
        confirmButtonText: "Si, deseo Continuar.",
      }).then((result) => {
        if (result.isConfirmed) {
          let params = {};
          params = { ...parametrosInicialesMemo, ...propiedades, ...{ pagina: pagina, limite: perPage } };
          deleteModel(row.idValoracion, type, params);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileRead = async (event) => {
    let file = event.target.files[0];
    const typePhoto = file.name.split(".").pop();
    switch (typePhoto) {
      case "pdf":
        break;
      default:
        showAlert({ message: "El archivo no tiene la extensión adecuada debe ser un PDF", severity: "warning" });
        file = "";
    }
    if (file !== undefined && file !== "") {
      let name = event.target.files[0].name;
      if (name.length <= 16) {
        filename.current.firstChild.textContent = name;
      } else {
        filename.current.firstChild.textContent = name.slice(0, 16) + "...";
      }
      setArchivo(file);
    }
  };

  const guardar = async (row, _seguimiento, _proceso, _comentario, _nombreCompleto) => {
    if (_seguimiento) {
      const resSwal = await Swal.fire({
        title: "¿Deseas Editar este Seguimiento?",
        text: "Favor de corroborar sus datos antes de continuar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Continuar!",
        cancelButtonText: "Cancelar",
      });
      if (resSwal.isConfirmed) {
        showAlert({ message: "Espera un momento.....", severity: "warning" });
        setIsLoading(true);
        let body = { idValoracion: row.idValoracion, l: lref.current.firstChild.checked, m: mref.current.firstChild.checked, a: aref.current.firstChild.checked, rx: rxref.current.firstChild.checked };
        const res = await Services.post("valoracion/guardar", body);
        setIsLoading(false);
        onSearchClicked(null);
        if (res) {
          showAlert({ message: "Seguimiento editado correctamente", severity: "success" });
        } else {
          showAlert({ message: res.message, severity: "error" });
        }
      }
    } else if (_proceso) {
      if (buttonActive != "") {
        const resSwal = await Swal.fire({
          title: "¿Deseas Editar esta Valoracion?",
          text: "Favor de corroborar sus datos antes de continuar.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Continuar!",
          cancelButtonText: "Cancelar",
        });
        if (resSwal.isConfirmed) {
          showAlert({ message: "Espera un momento.....", severity: "warning" });
          setIsLoading(true);
          let body = { idValoracion: row.idValoracion, i: buttonActive == "dissatis" ? false : true, status: "FINALIZADO" };
          const res = await Services.post("valoracion/guardar", body);
          setIsLoading(false);
          onSearchClicked(null);
          if (res) {
            showAlert({ message: "Valoracion editada correctamente", severity: "success" });
            setButtonActive("");
          } else {
            showAlert({ message: res.message, severity: "error" });
          }
        }
      } else {
        showAlert({ message: "Seleccione un resultado", severity: "warning" });
      }
    } else if (_nombreCompleto) {
      if (_nombreCompleto != "") {
        const resSwal = await Swal.fire({
          title: "¿Deseas Editar este paciente?",
          text: "Favor de corroborar sus datos antes de continuar.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Continuar!",
          cancelButtonText: "Cancelar",
        });
        if (resSwal.isConfirmed) {
          showAlert({ message: "Espera un momento.....", severity: "warning" });
          setIsLoading(true);
          let body = {
            idValoracion: row.idValoracion,
            nombres: nombresref?.current?.firstChild?.childNodes[1]?.firstChild?.value,
            apellidoPaterno: apellidoPaternoRef?.current?.firstChild?.childNodes[1]?.firstChild?.value,
            apellidoMaterno: apellidoMaternoRef?.current?.firstChild?.childNodes[1]?.firstChild?.value
          };
          const res = await Services.post("valoracion/guardar", body);
          setIsLoading(false);
          onSearchClicked(null);
          if (res) {
            showAlert({ message: "Validacion editada correctamente", severity: "success" });
            setArchivo(null);
            setComentario("");
            setButtonActive("");
          } else {
            showAlert({ message: res.message, severity: "error" });
          }
        }
      } else {
        showAlert({ message: "Debe ingresar un nombre y/o apellido", severity: "warning" });
      }
    } else if (_comentario) {
      if (comentario != "") {
        const resSwal = await Swal.fire({
          title: "¿Deseas Editar esta Valoración?",
          text: "Favor de corroborar sus datos antes de continuar.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Continuar!",
          cancelButtonText: "Cancelar",
        });
        if (resSwal.isConfirmed) {
          showAlert({ message: "Espera un momento.....", severity: "warning" });
          setIsLoading(true);
          let body = { idValoracion: row.idValoracion, comentario, status: "FINALIZADO" };
          const res = await Services.post("valoracion/guardar", body);
          setIsLoading(false);
          onSearchClicked(null);
          if (res) {
            showAlert({ message: "Validacion editada correctamente", severity: "success" });
            setArchivo(null);
            setComentario("");
            setButtonActive("");
            filename.current.firstChild.textContent = "seleccione un archivo";
          } else {
            showAlert({ message: res.message, severity: "error", });
          }
        }
      } else {
        showAlert({ message: "Debe ingresar un comentario", severity: "warning", });
      }

    } else {
      if (archivo !== null && archivo !== "") {
        const resSwal = await Swal.fire({
          title: "¿Deseas Editar esta Valoración?",
          text: "Favor de corroborar sus datos antes de continuar.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Continuar!",
          cancelButtonText: "Cancelar",
        });
        if (resSwal.isConfirmed) {
          showAlert({ message: "Espera un momento.....", severity: "warning", });
          setIsLoading(true);
          const ranm = Math.floor(Math.random() * 1000);
          const pathFirebase = "Valoracion/" + "File" + "_" + row.nombreCompleto + ranm;
          let urlFile = await FirebaseService.uploadFile(pathFirebase, archivo);
          let body = { idValoracion: row.idValoracion, urlFile, status: "FINALIZADO" };
          const res = await Services.post("valoracion/guardar", body);
          setIsLoading(false);
          onSearchClicked(null);
          if (res) {
            showAlert({ message: "Validacion editada correctamente", severity: "success", });
            setArchivo(null);
            setComentario("");
            setButtonActive("");
            filename.current.firstChild.textContent = "seleccione un archivo";
          } else {
            showAlert({ message: res.message, severity: "error", });
          }
        }
      } else {
        showAlert({ message: "Debe seleccionar un archivo", severity: "warning", });
      }
    }
  }

  const setIdConvenioCallback = useCallback((e, v) => {
    if (v !== "" && v) {
      setIdConvenio(v.idConvenio);
      setPropiedades({ ...propiedades, idConvenio: v.idConvenio });
    } else {
      setIdConvenio("");
    }
  }, [setIdConvenio]);

  const hasSeguimiento = row => {
    if (row.i === null && row.l === null && row.m === null && row.a === null && row.rx === null) {
      return false;
    }
    return true;
  }

  ////////////////////////////////////////////////////////////////////////
  //                            Hooks Def                               //
  ////////////////////////////////////////////////////////////////////////

  return (
    <>
      <TablePageLayout
        title="Valoraciones"
        model="valoracion"
        history={history}
        actionButton={{ to: "/valoracion/nuevo", icon: <Add />, label: "Agregar", permiso: "valoraciones" }}
        links={[{ label: "valoraciones", to: "/valoraciones", icon: <FactCheckIcon fontSize="small" /> },]}
        SearchComponents={
          <>
            <Grid item xs={12} md={4}>
              <InputRegex
                label="Nombre Completo"
                variant="standard"
                fullWidth
                size="medium"
                typeInput='text'
                value={propiedades.nombreCompleto}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    nombreCompleto: e.target.value,
                  }))
                }
              />
            </Grid>
            {(admin === true || (permiso[0]["valoraciones"] & 15) === 15) && (
              <Grid item xs={12} md={4}>
                <AsyncAutocompleteSearchRework
                  label="Convenios"
                  labelProp="nombreConvenio"
                  name="convenio"
                  onChange={setIdConvenioCallback}
                  clearInput={clear}
                  extraParams={paramsMemo}
                />
              </Grid>
            )}
            {propiedades.status === 'FINALIZADO' && (
              <Grid item xs={12} md={4} >
                <FormControl variant="standard" fullWidth size="large">
                  <InputLabel variant="standard" id="demo-select-small">Estado</InputLabel>
                  <Select
                    variant="standard"
                    size="large"
                    id="demo-select-small"
                    label="Estado"
                    value={propiedades.estado}
                    onChange={(e) =>
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        estado: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="todos">Todos</MenuItem>
                    <MenuItem value="no-apto">No apto</MenuItem>
                    <MenuItem value="aprobado">Aprobado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
              <DateRangePicker value={[new Date(propiedades.fechaInicio), new Date(propiedades.fechaFinal)]} style={{ width: '100%' }} format="dd/MM/yyyy" placeholder="Seleccione el rango de fechas"
                onChange={e => {
                  setPropiedades({
                    ...propiedades,
                    fechaInicio: moment(e[0]).format("yyyy/MM/DD"),
                    fechaFinal: moment(e[1]).format("yyyy/MM/DD")
                  });
                }}
              />
            </Grid>
            {propiedades.status === 'FINALIZADO' ? <Grid item xs={12} md={10}></Grid> : <Grid item xs={12} md={10}></Grid>}
            <Grid item xs={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
              <LoadingButton
                variant="contained"
                fullWidth
                onClick={() => onSearchClicked(propiedades.estado ? propiedades.estado : null)}
                loading={modelsLoading && true}
                style={{ float: "right", color: "whitesmoke" }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>
          </>
        }
        DisableComponents={
          <>
            <Grid item xs={8}>
              <FormControlLabel
                className={classes.switchColor}
                control={
                  <Switch
                    disabled={modelsLoading && true}
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        eliminado: e.target.checked ? 1 : 0,
                      }));
                      onSearchClicked(e.target.checked ? 1 : 0);
                    }}
                  />
                }
                label="Ver Desabilitados"
              />
            </Grid>
            <Grid item xs={4} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", }}>
              <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", }}>
                <IconButton color="error" aria-label="upload picture" component="span">
                  <a className={classes.btnPdf} target="_blank" rel="noreferrer" href={`${baseUrl}pdf/valoracion?
                    ${"admin=" + (admin ? 1 : 0)}
                    ${"&uid=" + user?.idUsuario}
                    ${"&nombreCompleto=" + propiedades.nombreCompleto}
                    ${"&fechaInicio=" + propiedades.fechaInicio}${"&fechaFinal=" + propiedades.fechaFinal}
                    ${"&status=" + propiedades.status}
                    ${"&estado=" + propiedades.estado}
                    ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`
                  }>
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              {/* <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center", }} >
                <IconButton color="warning" aria-label="upload picture" component="span">
                  <a className={classes.btnExcel} target="_blank" rel="noreferrer" href={`${baseUrl}excel/valoracion?
                    ${"admin=" + (admin ? 1 : 0)}
                    ${"&uid=" + user?.idUsuario}
                    ${"&nombreCompleto=" + propiedades.nombreCompleto}
                    ${"&fechaInicio=" + propiedades.fechaInicio}${"&fechaFinal=" + propiedades.fechaFinal}
                    ${"&status=" + propiedades.status}
                    ${"&estado=" + propiedades.estado}
                    ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`
                  }>
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid> */}
              <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center", }} >
                <IconButton color="warning" aria-label="upload picture" component="span">
                  <a className={classes.btnExcel} target="_blank" rel="noreferrer" href={`${baseUrl}excel/valoracion-reporte`
                  }>
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }
        customCssDisableButtons={{ justifyContent: "flex-end", }}
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <Tabs changePos={changePos} pos={pos} tabs={[
            {
              title: "En Proceso",
              icon: <HourglassBottomIcon />,
              content: <TablePagination
                nombrePermiso="valoraciones"
                permiso={permiso}
                header={[]}
                data={models}
                paginatedApi
                pagina={pagina}
                paginationPageSize={perPage}
                onRowsPerPageChangeApi={changePageRow}
                changePageApi={changePage}
                count={modelsPage !== null ? modelsPage.total : 0}
                labelRowsPerPage={"Renglones por página"}
                extraRows={[
                  {
                    prop: "nombreCompleto",
                    name: "Nombre Completo",
                    cell: (row, index) => (
                      <p key={index}>{row.nombreCompleto.toUpperCase()}</p>
                    )
                  },
                  {
                    prop: "changeEstudio",
                    name: "Seguimiento",
                    cell: (row, index) => (
                      <PopupState variant="popover" popupId="demo-popup-popover1" >
                        {(popupState) => (
                          <div>
                            <IconButton variant="contained" {...bindTrigger(popupState)}>
                              <MultiCheck options={[row.l, row.m, row.a, row.rx]} />
                            </IconButton>
                            <Popover {...bindPopover(popupState)} anchorOrigin={{ vertical: 'top', horizontal: 'center', }} transformOrigin={{ vertical: 'bottom', horizontal: 'center', }} >
                              <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <Grid item xs={12} style={{ width: '100%', padding: '5px' }}>
                                  <div style={{ display: 'flex', alignItems: 'center', background: '#1D5E7B', flexDirection: 'row', justifyContent: 'space-between', padding: '0 30px', color: '#FFF', borderRadius: '5px' }}>
                                    <div> <strong>Laboratorio</strong> </div>
                                    <div>
                                      <Checkbox
                                        ref={lref}
                                        checked={row.l}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#fff !important' } }}
                                      />
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} style={{ width: '100%', padding: '5px' }}>
                                  <div style={{ display: 'flex', alignItems: 'center', background: '#1D5E7B', flexDirection: 'row', justifyContent: 'space-between', padding: '0 30px', color: '#FFF', borderRadius: '5px' }}>
                                    <div> <strong>Medicina General</strong> </div>
                                    <div>
                                      <Checkbox
                                        ref={mref}
                                        checked={row.m}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#fff !important' } }}
                                      />
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} style={{ width: '100%', padding: '5px' }}>
                                  <div style={{ display: 'flex', alignItems: 'center', background: '#1D5E7B', flexDirection: 'row', justifyContent: 'space-between', padding: '0 30px', color: '#FFF', borderRadius: '5px' }}>
                                    <div> <strong>Audiometria</strong> </div>
                                    <div>
                                      <Checkbox
                                        ref={aref}
                                        checked={row.a}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#fff !important' } }}
                                      />
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} style={{ width: '100%', padding: '5px' }}>
                                  <div style={{ display: 'flex', alignItems: 'center', background: '#1D5E7B', flexDirection: 'row', justifyContent: 'space-between', padding: '0 30px', color: '#FFF', borderRadius: '5px' }}>
                                    <div> <strong>Rayos X</strong> </div>
                                    <div>
                                      <Checkbox
                                        ref={rxref}
                                        checked={row.rx}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#fff !important' } }}
                                      />
                                    </div>
                                  </div>
                                </Grid>
                              </div>
                            </Popover>
                          </div>
                        )}
                      </PopupState >
                    )
                  },
                  {
                    prop: "status",
                    name: "Estado",
                    cell: (row, index) => (
                      <p key={index}>{getStatus(row)}</p>
                    )
                  },
                  {
                    prop: "acciones",
                    name: "Acciones",
                    cell: (row, index) => (permiso && (permiso[0]["valoraciones"] & 15) === 15 || (permiso[0]["valoraciones"] & 15) === 15) && (
                      <div>
                        <IconButton onClick={(event) => { setOpenMenuRow(index); setAnchorEl(event.currentTarget) }}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu anchorEl={anchorEl} keepMounted open={index === openMenuRow} onClose={() => setOpenMenuRow(null)}>
                          {(admin === true || (permiso && permiso[0]["valoraciones"] & 15) === 15) && (row.eliminado == null) && (
                            <NavLink exact to={(`valoracion/editar/${row.idValoracion}`)} style={{ color: '#000', textDecoration: 'none' }}>
                              <MenuItem aria-label="editar"
                                onClick={() => {
                                  history.push(`valoracion/editar/${row.idValoracion}`, { match: row.idValoracion });
                                }}>
                                <Edit style={{ paddingRight: 5 }} />
                                Editar
                              </MenuItem>
                            </NavLink>
                          )}
                          {(admin === true || (permiso && permiso[0]["valoraciones"] & 15) === 15) && (
                            row?.eliminado === null ? (
                              <MenuItem
                                onClick={() => onDeleteClicked(row, "Deshabilitar")}
                              >
                                <Delete style={{ paddingRight: 5 }} />
                                Deshabilitar
                              </MenuItem>
                            ) : (
                              <MenuItem
                                onClick={() => onDeleteClicked(row, "Habilitar")}
                              >
                                <RestoreFromTrashIcon style={{ paddingRight: 5 }} />
                                Habilitar
                              </MenuItem>
                            )
                          )}
                        </Menu>
                      </div>
                    ),
                  }
                ]}
              />
            },
            {
              title: "FINALIZADO",
              icon: <DoneIcon />,
              content: <TablePagination
                nombrePermiso="valoraciones"
                permiso={permiso}
                header={[]}
                data={models}
                paginatedApi
                pagina={pagina}
                paginationPageSize={perPage}
                onRowsPerPageChangeApi={changePageRow}
                changePageApi={changePage}
                count={modelsPage !== null ? modelsPage.total : 0}
                labelRowsPerPage={"Renglones por página"}
                extraRows={[
                  {
                    prop: "nombreCompleto",
                    name: "Nombre Completo",
                    cell: (row, index) => (
                      <p key={index}>{row.nombreCompleto.toUpperCase()}</p>
                    )
                  },
                  {
                    prop: "status",
                    name: "Estado",
                    cell: (row, index) => (
                      <p key={index}>{getStatus(row)}</p>
                    )
                  },
                  {
                    prop: "comentario",
                    name: "Comentario",
                    cell: (row, index) => (
                      <p style={row.comentario == "null" ? { color: '#bdbdbd', fontWeight: '600' } : {}} key={index}>{row.comentario == "null" ? "Sin Comentario" : row.comentario}</p>
                    )
                  },
                  {
                    prop: "download",
                    name: "Descargar PDF",
                    cell: (row, index) => (
                      (row.urlFile == "null") ?
                        <IconButton disabled={true}>
                          <FileDownloadRoundedIcon />
                        </IconButton>
                        :
                        <a href={row.urlFile} target="_blank" rel='noreferrer' >
                          <IconButton>
                            <FileDownloadRoundedIcon />
                          </IconButton>
                        </a>
                    )
                  },
                  {
                    prop: "acciones",
                    name: "Acciones",
                    cell: (row, index) => (permiso && (permiso[0]["valoraciones"] & 15) === 15 || (permiso[0]["valoraciones"] & 15) === 15) && (
                      <div>
                        <IconButton onClick={(event) => { setOpenMenuRow(index); setAnchorEl(event.currentTarget) }}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu anchorEl={anchorEl} keepMounted open={index === openMenuRow} onClose={() => setOpenMenuRow(null)}>
                          {(admin === true || (permiso && permiso[0]["valoraciones"] & 15) === 15) && (row.eliminado == null) && (
                            <NavLink exact to={(`valoracion/editar/${row.idValoracion}`)} style={{ color: '#000', textDecoration: 'none' }}>
                              <MenuItem aria-label="editar"
                                onClick={() => {
                                  history.push(`valoracion/editar/${row.idValoracion}`, { match: row.idValoracion });
                                }}>
                                <Edit style={{ paddingRight: 5 }} />
                                Editar
                              </MenuItem>
                            </NavLink>
                          )}
                          {(admin === true || (permiso && permiso[0]["valoraciones"] & 15) === 15) && (
                            row?.eliminado === null ? (
                              <MenuItem
                                onClick={() => onDeleteClicked(row, "Deshabilitar")}
                              >
                                <Delete style={{ paddingRight: 5 }} />
                                Deshabilitar
                              </MenuItem>
                            ) : (
                              <MenuItem
                                onClick={() => onDeleteClicked(row, "Habilitar")}
                              >
                                <RestoreFromTrashIcon style={{ paddingRight: 5 }} />
                                Habilitar
                              </MenuItem>
                            )
                          )}
                        </Menu>
                      </div>
                    ),
                  }
                ]}
              />
            }
          ]}
          />
        }
      />
    </>
  );
}
export default Valoraciones;

