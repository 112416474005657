import { Grid, TextField } from "@mui/material"
import React from "react"

const antecedentesHF = ({ datos, setDatos, ...props }) => {
    return (
        <>
            <Grid item xs={12} sx={{ margin: "20px 0", color: "#FFFFFF", background: "black", padding: "10px" }}>
                INTERROGATORIO POR APARATOS Y SISTEMAS
            </Grid>
            {
                Object.keys(datos?.interrogatorio).map(cuadro => {
                    return (
                        <>
                            <Grid xs={12} sx={{ padding: "10px" }}>
                                {(cuadro.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + cuadro.replace(/([A-Z])/g, " $1").slice(1)).toLocaleUpperCase()}
                            </Grid>
                            {
                                Object.keys(datos?.interrogatorio[cuadro]).map(campo => {
                                    return (
                                        <Grid xs={12} md={2} sx={{ padding: "10px" }}>
                                            <TextField
                                                fullWidth
                                                label={(campo.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + campo.replace(/([A-Z])/g, " $1").slice(1)).toLocaleUpperCase()}
                                                value={datos?.interrogatorio[cuadro][campo]}
                                                placeholder={(campo.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + campo.replace(/([A-Z])/g, " $1").slice(1)).toLocaleUpperCase()}
                                                onChange={e => {
                                                    let newExploracion = { ...datos?.interrogatorio }
                                                    newExploracion[cuadro][campo] = e.target.value
                                                    setDatos({ ...datos, interrogatorio: { ...newExploracion } })
                                                }}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </>
                    )
                })
            }
        </>
    )
}

export default antecedentesHF