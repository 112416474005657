//React
import React, { useEffect, useState, useRef, useMemo } from "react";
import { capitalizeFirst } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import { useModel } from "../../hooks/useModel";
import { useModels } from "../../hooks/useModels";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import DatePickerBeta from '../../components/DatePickerBeta';
import { useAlert } from "../../hooks/useAlert";
import moment from "moment-timezone";
import FirebaseService from "../../services/firebase";
import Loading from "../../components/FullScreenLoader";

//----------------------------------------------------------------------------------------------------------------------

//Librerias
import {
  Grid, MenuItem, Switch, Modal, FormControl,
  InputLabel, Select, TextField, Typography, Divider, CardMedia, Button, Paper
} from "@mui/material";
import { makeStyles } from "@mui/styles";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import { Add, Edit, PhotoCamera } from "@mui/icons-material";
import { FaUserInjured } from "react-icons/fa";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { FiUpload } from "react-icons/fi";
import { BsCameraFill } from 'react-icons/bs';
//----------------------------------------------------------------------------------------------------------------------

//Imagenes
import PageHeader from "../../components/PageHeader";
import Tabs from "../../components/TabsRework";
import ReactFileManager from "../../components/ReactFileManager";
//----------------------------------------------------------------------------------------------------------------------

//Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
const modalStyle = {
  position: 'absolute',
  top: '50%',
  right: 'auto',
  left: '50%',
  bottom: 'auto',
  transform: 'translate(-50%, -50%)',
  marginRight: '-50%',
  background: '#FFF',
  outline: 'none',
  padding: '15px 0 0 0',
  borderRadius: '5px',
  boxShadow: '0 0 15px black'

};
//----------------------------------------------------------------------------------------------------------------------

const Index = ({ match, history, dialog }) => {
  //Estados Iniciales
  const classes = useStyles();
  const { showAlert } = useAlert();
  const id = match.params.id;
  const editing = !!id;
  const [admin] = useLocalStorage("admin", null);
  const { permiso } = useAuth()
  const [archivo, setArchivo] = useState("https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Paciente%2Fpaciente_default.jpg?alt=media&token=4dfe89d7-0766-462e-a924-a4a67156277b");
  const [foto, setFoto] = useState(null);
  const [urlImg, setUrlImg] = useState("");
  const [ tipoImagen, setTipoImagen ] = useState("file");
  const formulario = useRef();
  const [perPage, setPerPage] = useState(10);
  const [pagina, setPagina] = useState(1);
  const [newObjSucursales, setNewObjSucursales] = useState([]);
  const [pos, setPos] = useState(0);
  //----------------------------------------------------------------------------------------------------------------------

  //Estados Iniciales Controller
  const header = [
    {
      name: "Nombre Sucursal",
      prop: "nombreSucursal",
    }
  ];

  const [propiedades, setPropiedades] = useState({
    ine: "",
    genero: "M",
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    nombreCompleto: "",
    correo: "",
    direccion: "",
    telefono: "",
    urlFoto: "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Paciente%2Fpaciente_default.jpg?alt=media&token=4dfe89d7-0766-462e-a924-a4a67156277b",
    fechaNacimiento: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
    estadoCivil: "SOLTERO(A)",
    tipoSangre: "A+",
    pacienteSucursal: [],
    nivelEducativo: "",
    nacionalidad: "",
    contactoEmergencia: "",
    nombreEmergencia: "",
  });

  //Tabla GET
  const parametrosInicialesMemo = useMemo(
    () => ({
      name: "sucursal",
      ordenar: "idSucursal.asc",
    }),
    []
  );

  const [models, modelsPage, refreshModels, deleteModel,] =
    useModels({ ...parametrosInicialesMemo });

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "paciente",
      expand: "pacienteSucursal.sucursal",
      id,
      redirectOnPost: true,
    });

  //----------------------------------------------------------------------------------------------------------------------

  //Funciones
  const changePage = async (page) => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async (per) => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  //----------------------------------------------------------------------------------------------------------------------

  //Funciones Basicas

  const handleFileRead = async ({ target }) => {
    const file = target.files[0];
    const typePhoto = file.name.split('.').pop();
    switch (typePhoto) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif': break;
      default:
        showAlert({ message: 'El archivo no tiene la extensión adecuada', severity: "warning", });
        file = '';
    }
    if (file !== undefined) {
      setArchivo(file);
      setPropiedades((prevState) => ({
        ...prevState,
        ...prevState.propiedades,
        urlFoto: URL.createObjectURL(target.files[0]),
      }));
    }
  };

  const onGuardar = async () => {
    let respaldo = [];
    let sucursalesFiltered = [];
    if (editing) {
      model.pacienteSucursal.map(sucursal => {
        if (!models.some(s => s.idSucursal == sucursal.idSucursal)) {
          respaldo.push({ ...sucursal.sucursal, active: true })
        }
      });
      let sucursalesToFilter = newObjSucursales.concat(respaldo);
      sucursalesToFilter.map(sucursal => {
        if (sucursal.active) {
          sucursalesFiltered.push(sucursal);
        }
      });
    } else {
      let sucursalesToFilter = newObjSucursales;
      sucursalesToFilter.map(sucursal => {
        if (sucursal.active) {
          sucursalesFiltered.push(sucursal);
        }
      });
    }
    if (sucursalesFiltered.length === 0) {
      showAlert({ message: 'Seleccione al menos una sucursal', severity: "warning", });
      return;
    }
    let urlFilePaciente = "";
    let body = propiedades;
    const paciente = propiedades.nombreCompleto.split(" ").join("").toLowerCase();
    const ranm = Math.floor(Math.random() * 1000);
    const pathFirebase = "Paciente/" + "Imagen" + "_" + paciente + "_" + ranm;
    if (tipoImagen == "file") {
      if (archivo) {
        urlFilePaciente = await FirebaseService.uploadFile(pathFirebase, archivo);
        body = { ...body, urlFoto: urlFilePaciente };
      }
    } else {
      if (foto) {
        urlFilePaciente = await FirebaseService.uploadFile(pathFirebase, foto);
        body = { ...body, urlFoto: urlFilePaciente };
      }
    }
    if (editing) {
      body.idPaciente = id;
    }
    body = { ...body, pacienteSucursal: sucursalesFiltered }
    updateModel(body, true, "pacientes");
  };

  const deleteImg = async (url) => {
    await FirebaseService.deleteFile("Paciente/", url);
    setUrlImg("");
  };

  const getFilterArraySucursales = (array) => {
    return array.map((e) => {
      if (editing) {
        if (model) {
          if (model.pacienteSucursal.some(sucursal => sucursal.idSucursal == e.idSucursal)) {
            return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: true }
          } else {
            return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
          }
        } else {
          return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
        }
      } else {
        return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
      }
    })
  }

  const changePos = async _pos => {
    if (_pos == 0) {
      setTipoImagen("file");
    } else {
      setTipoImagen("foto");
    }
    setPos(_pos);
  };
  //----------------------------------------------------------------------------------------------------------------------

  //Efectos Callbacks

  useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setPropiedades(model);
      const newObj = getFilterArraySucursales(models);
      setNewObjSucursales(newObj);
    }
    return () => {
      mounted = false;
      setPropiedades({
        ine: "",
        genero: "",
        nombres: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        nombreCompleto: "",
        correo: "",
        direccion: "",
        telefono: "",
        urlFoto: "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Paciente%2Fpaciente_default.jpg?alt=media&token=4dfe89d7-0766-462e-a924-a4a67156277b",
        fechaNacimiento: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        estadoCivil: "SOLTERO(A)",
        tipoSangre: "A+",
        eliminado: 0,
        pacienteSucursal: [],
        nivelEducativo: "",
        nacionalidad: "",
        contactoEmergencia: "",
        nombreEmergencia: "",
      });
    };
  }, [model]);

  useEffect(() => {
    if (modelError) {
      if (urlImg !== "" && urlImg !== undefined) {
        deleteImg(urlImg);
      }
    }
  }, [modelError, urlImg]);

  useEffect(() => {
    if (models) {
      const newObj = getFilterArraySucursales(models);
      setNewObjSucursales(newObj);
    }
  }, [models]);

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title={(!editing ? "NUEVO" : "EDITAR") + " PACIENTE"}
        links={[
          {
            to: "/pacientes",
            icon: <FaUserInjured fontSize="large" />,
            label: "Recepcion Pacientes",
          },
          {
            to: !editing ? "/paciente/nuevo" : "/paciente/editar/" + id,
            icon: !editing ? <Add /> : <Edit />,
            label: !editing
              ? "Nuevo"
              : "Editar - " +
              capitalizeFirst(
                propiedades?.nombreCompleto.toLocaleLowerCase()
              ),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div ref={formulario} ><strong>{"INFORMACIÓN DEL PACIENTE"}</strong></div>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            variant="standard"
            color="primary"
            label="I.N.E."
            fullWidth
            typeInput="text"
            maxLength={50}
            value={propiedades.ine}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                ine: e.target.value,
              }))
            }
            helperText={modelError.ine}
            error={Boolean(modelError.ine)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Nombre(s)"
            variant="standard"
            color="primary"
            fullWidth
            typeInput="text"
            maxLength={50}
            value={propiedades.nombres}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                nombres: e.target.value,
              }))
            }
            helperText={modelError.nombres}
            error={Boolean(modelError.nombres)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Apellido Paterno"
            variant="standard"
            color="primary"
            fullWidth
            typeInput="text"
            maxLength={50}
            value={propiedades.apellidoPaterno}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                apellidoPaterno: e.target.value,
              }))
            }
            helperText={modelError.apellidoPaterno}
            error={Boolean(modelError.apellidoPaterno)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Apellido Materno"
            variant="standard"
            color="primary"
            fullWidth
            typeInput="text"
            maxLength={50}
            value={propiedades.apellidoMaterno}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                apellidoMaterno: e.target.value,
              }))
            }
            helperText={modelError.apellidoMaterno}
            error={Boolean(modelError.apellidoMaterno)}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="tipoSelect">Seleccionar género</InputLabel>
            <Select
              labelId="tipoSelect"
              fullWidth
              error={Boolean(modelError?.genero)}
              label="Genero"
              onChange={
                (e) => {
                  setPropiedades(prevState => ({
                    ...prevState,
                    ...prevState.propiedades,
                    genero: e.target.value
                  }));
                }
              }
              inputProps={{
                value: propiedades.genero,
              }}
            >
              <MenuItem value={"M"}>Masculino</MenuItem>
              <MenuItem value={"F"}>Femenino</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="tipoSelect">Estado Civil</InputLabel>
            <Select
              labelId="tipoSelect"
              fullWidth
              error={Boolean(modelError?.estadoCivil)}
              label="Estado Civil"
              onChange={
                (e) => {
                  setPropiedades(prevState => ({
                    ...prevState,
                    ...prevState.propiedades,
                    estadoCivil: e.target.value
                  }));
                }
              }
              inputProps={{
                value: propiedades.estadoCivil,
              }}
            >
              <MenuItem value={"SOLTERO(A)"}>SOLTERO(A)</MenuItem>
              <MenuItem value={"CASADO(A)"}>CASADO(A)</MenuItem>
              <MenuItem value={"DIVORCIADO(A)"}>DIVORCIADO(A)</MenuItem>
              <MenuItem value={"VIUDO(A)"}>VIUDO(A)</MenuItem>
              <MenuItem value={"CONCUBINATO(A)"}>CONCUBINATO(A)</MenuItem>
              {/* <MenuItem value={"SEPARACIONJUDICIAL"}>SEPARACIÓN EN PROCESO JUDICIAL</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>

        

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Tipo de sangre"
            variant="standard"
            color="primary"
            fullWidth
            typeInput="text"
            maxLength={50}
            value={propiedades.tipoSangre}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                tipoSangre: e.target.value,
              }))
            }
            helperText={modelError.tipoSangre}
            error={Boolean(modelError.tipoSangre)}
          />
        </Grid>

        <Grid item xs={12} md={4} >
          <DatePickerBeta
            labelText="Fecha de Nacimiento"
            value={propiedades.fechaNacimiento}
            variant="standard"
            inputFormat="MM/dd/yyyy"
            onChange={e => {
              setPropiedades(prevState => ({
                ...prevState,
                ...prevState.propiedades,
                fechaNacimiento: moment(e).format("YYYY-MM-DDTHH:mm:ss")
              }))
            }
            }
          />
        </Grid>

        <Grid item xs={12} md={4} >
          <TextField
            label="Correo"
            variant="standard"
            fullWidth
            type="email"
            value={propiedades.correo}
            onChange={(e) => setPropiedades(prevState => ({
              ...prevState,
              ...prevState.propiedades,
              correo: e.target.value
            }))
            }
            helperText={modelError.correo}
            error={Boolean(modelError.correo)}
          />
        </Grid>

        <Grid item xs={12} md={4} >
          <InputRegex
            label="Teléfono"
            variant="standard"
            fullWidth
            maxLength={10}
            typeInput="phone"
            value={propiedades.telefono}
            onChange={(e) => setPropiedades(prevState => ({
              ...prevState,
              ...prevState.propiedades,
              telefono: e.target.value.replaceAll(/[-() ]/g, '')
            }))
            }
            helperText={modelError.telefono}
            error={Boolean(modelError.telefono)}
          />
        </Grid>

        <Grid item xs={12} md={4} >
          <InputRegex
            label="Contacto Emergencia"
            variant="standard"
            fullWidth
            maxLength={10}
            typeInput="phone"
            value={propiedades.contactoEmergencia}
            onChange={(e) => setPropiedades(prevState => ({
              ...prevState,
              ...prevState.propiedades,
              contactoEmergencia: e.target.value.replaceAll(/[-() ]/g, '')
            }))
            }
            helperText={modelError.contactoEmergencia}
            error={Boolean(modelError.contactoEmergencia)}
          />
        </Grid>

        <Grid item xs={12} md={4} >
          <TextField
            label="Nombre Contacto de Emergencias"
            variant="standard"
            fullWidth
            type="text"
            value={propiedades.nombreEmergencia}
            onChange={(e) => setPropiedades(prevState => ({
              ...prevState,
              ...prevState.propiedades,
              nombreEmergencia: e.target.value
            }))
            }
            helperText={modelError.nombreEmergencia}
            error={Boolean(modelError.nombreEmergencia)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Nivel de estudios"
            variant="standard"
            color="primary"
            fullWidth
            typeInput="text"
            maxLength={50}
            value={propiedades.nivelEducativo == null ? "": propiedades?.nivelEducativo.toUpperCase()}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                nivelEducativo: e.target.value,
              }))
            }
            helperText={modelError.nivelEducativo}
            error={Boolean(modelError.nivelEducativo)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Nacionalidad"
            variant="standard"
            color="primary"
            fullWidth
            typeInput="text"
            maxLength={50}
            value={propiedades.nacionalidad == null ? "": propiedades?.nacionalidad.toUpperCase()}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                nacionalidad: e.target.value,
              }))
            }
            helperText={modelError.nacionalidad}
            error={Boolean(modelError.nacionalidad)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Dirección"
            variant="outlined"
            multiline
            size="medium"
            rows={1}
            fullWidth
            value={propiedades.direccion}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                direccion: e.target.value,
              }))
            }
            helperText={modelError.direccion}
            error={Boolean(modelError.direccion)}
          />
        </Grid>
      </Grid>
      <Grid component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Tabs changePos={changePos} pos={pos} tabs={[
          {
            title: "Subir Imagen",
            icon: <FiUpload style={{ fontSize: 'x-large', marginLeft: '5px', marginBottom: '5px' }} />,
            content:
              <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={12} sm={12} md={7} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '30px' }}>
                    <CardMedia
                      component="img"
                      image={propiedades.urlFoto}
                      title=""
                      style={{ width: "305px", height: "305px", objectFit: 'cover', border: '7px solid whiteSmoke', borderRadius: '5px' }}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" >
                  <Grid item xs={12} sm={12} md={2}>
                    <div>
                      <input
                        hidden
                        accept="image/gif,image/jpeg,image/jpg,image/png"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        onChange={handleFileRead}
                      />
                      <label htmlFor="contained-button-file">
                        <Button style={{ color: 'white' }} variant="contained" color="primary" component="span" >
                          <PhotoCamera />
                        </Button>
                      </label>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
          },
          {
            title: "Tomar Foto",
            icon: <BsCameraFill style={{ fontSize: 'x-large', marginLeft: '5px', marginBottom: '4px' }} />,
            content:
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
                  <ReactFileManager foto={foto} setFoto={setFoto} />
                </Grid>
              </div>
          }
        ]} />
      </Grid>
      <Grid component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <strong>{"SUCURSALES"}</strong>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <TablePagination
            header={header}
            data={newObjSucursales}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "accion",
                name: "Acción",
                sortable: false,
                cell: (row, index) =>
                  <Switch
                    disabled={row && (row?.eliminado === null && row?.active === null) ? true : false}
                    checked={row?.active}
                    onChange={({ target }) => {
                      newObjSucursales[index].active = target.checked;
                      setNewObjSucursales([...newObjSucursales]);
                    }}
                  />,
              },
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading || updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["pacientes"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<SaveIcon />}
              style={{ color: "#fff" }}
            >
              {(!editing ? "GUARDAR" : "MODIFICAR")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
}

export default Index;