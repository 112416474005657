
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useModel } from "../../hooks/useModel";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { useAlert } from "../../hooks/useAlert";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import PageHeader from "../../components/PageHeader";
import {
  Grid,
  Divider,
  Paper,
  Switch,
  Table,
  CircularProgress,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import TablePagination from "../../components/TablePagination";
import { useModels } from "../../hooks/useModels";
import InputRegex from "../../components/InputRegex";
import Loading from "../../components/FullScreenLoader";
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";
import AsyncAutocompleteSearchRework from "../../components/AsyncAutocompleteSearchRework";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { tableCellStyles, tableRowStyles, entradasStyle, capitalizeFirst, internationalCurrency } from '../../utils';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Edit, MedicationLiquid } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import RemoveIcon from '@mui/icons-material/Remove';
import { RiServiceFill } from "react-icons/ri";
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const useEntradasStyle = entradasStyle();

const ServiciosDetail = ({ match, history, dialog }) => {
  ////////////////////////////////////////////////////////////////////////
  //                              Vars                                  //
  ////////////////////////////////////////////////////////////////////////
  const header = [
    {
      name: "Nombre Sucursal",
      prop: "nombreSucursal",
    }
  ];
  const id = match.params.idServicio;
  const classes = useStyles();
  const { showAlert } = useAlert();
  const [clear, setClear] = useState(false);
  const [errorDoctor] = useState();
  const [usuario, setUsuario] = useState({});
  const [usuarios, setUsuarios] = useState([]);
  const [loadingUsuario] = useState(false);
  const entradas = useEntradasStyle();
  const [newObjSucursales, setNewObjSucursales] = useState([])
  const [reload, setReload] = useState(true);
  const [clearInputProducto, setClearInputProducto] = useState(false);
  const editing = !!id;
  const [admin] = useLocalStorage("admin", null);
  const { permiso } = useAuth();
  const [idSucursal] = useLocalStorage("idSucursal", "");
  const [propiedades, setPropiedades] = useState({
    grupo: "",
    unidadM: "",
    claveP: "",
    concepto: "",
    valorU: "",
    valorUIva: "",
    iva: "",
    proceso: "",
    idSucursal,
  });
  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "servicio",
      expand: "servicioSucursal, servicioDoctores.doctor",
      id,
      redirectOnPost: true,
    });

  const parametrosInicialesMemo = useMemo(
    () => ({
      name: "sucursal",
      ordenar: "idSucursal.asc",
    }),
    []
  );

  const paramsMemoDoctor = useMemo(
    () => ({
      ordenar: "nombreCompleto.asc",
      idSucursal,
    }),
    [idSucursal]
  );
  const [models, modelsPage, refreshModels, deleteModel,] =
    useModels({ ...parametrosInicialesMemo });
  ////////////////////////////////////////////////////////////////////////
  //                           Funcions Def                             //
  ////////////////////////////////////////////////////////////////////////

  const agregarDoctor = () => {
    setClear(true);
    window.setInterval(() => { setClear(false) }, 10);
    if (usuario.idUsuario != null) {
      const repetido = usuarios.some(e => e.idUsuario === usuario.idUsuario);
      if (repetido) {
        showAlert({
          message: "Este Usuario ya fue agregado",
          severity: "error",
        });
        setUsuario(null);
      } else {
        let copyUsuarios = [...usuarios];
        copyUsuarios.push(usuario);
        setUsuarios(copyUsuarios);
        setUsuario(null);
      }
    }
  }

  const onDropDoctor = async (id, nombre) => {
    if (id) {
      const res = await Swal.fire({
        title: "Borrar",
        icon: "warning",
        text: `¿Estás seguro de que deseas borrar el Doctor seleccionado "${capitalizeFirst(nombre.toLowerCase())}"?`,
        showCancelButton: true,
        confirmButtonColor: "#25607A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, deseo Continuar!",
        cancelButtonText: "Cancelar",
      });
      if (res.isConfirmed) {
        showAlert({
          message: "Espera un momento.....",
          severity: "warning",
        });
        let posi;
        const copyUsuarios = [...usuarios];
        usuarios.map((e, index) => {
          if (e.idUsuario === id) {
            posi = index;
          }
        });
        copyUsuarios.splice(posi, 1);
        setUsuarios(copyUsuarios);
        setReload(true);
        showAlert({
          message: "Doctor borrado con éxito.",
          severity: "success",
        });
      } else {
        setReload(true);
      }
    }
  };
  const onGuardar = async () => {
    let respaldo = [];
    let sucursalesFiltered = [];
    if (editing) {
      model?.servicioSucursal.map(sucursal => {
        if (!models.some(s => s.idSucursal == sucursal.idSucursal)) {
          respaldo.push({ ...sucursal.sucursal, active: true })
        }
      });
      let sucursalesToFilter = newObjSucursales.concat(respaldo);
      sucursalesToFilter.map(sucursal => {
        if (sucursal.active) {
          sucursalesFiltered.push(sucursal);
        }
      });
    } else {
      let sucursalesToFilter = newObjSucursales;
      sucursalesToFilter.map(sucursal => {
        if (sucursal.active) {
          sucursalesFiltered.push(sucursal);
        }
      });
    }
    if (sucursalesFiltered.length === 0) {
      showAlert({ message: 'Seleccione al menos una sucursal', severity: "warning", });
      return;
    }
    let body = { ...propiedades, servicioSucursal: sucursalesFiltered, servicioDoctores: usuarios };
    if (editing) {
      body.idServicio = id;
    }
    updateModel(body, true, "servicios");
  };

  const getFilterArraySucursales = (array) => {
    return array.map((e) => {
      if (editing) {
        if (model) {
          if (model.servicioSucursal.some(sucursal => sucursal.idSucursal == e.idSucursal)) {
            return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: true }
          } else {
            return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
          }
        } else {
          return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
        }
      } else {
        return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
      }
    })
  }

  const calculo = () => {
    if (propiedades.valorU !== "" && propiedades.valorU !== undefined) {
      let iva = (propiedades.valorU * 0.16).toFixed(2);
      setPropiedades({ ...propiedades, iva });
    }
  }

  ////////////////////////////////////////////////////////////////////////
  //                            Hooks Def                               //
  ////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      let preLoadUsuarios = [];
      model.servicioDoctores.map(u => preLoadUsuarios.push({ idUsuario: u?.doctor[0]?.idUsuario, nombreCompleto: u?.doctor[0]?.nombreCompleto, correo: u?.doctor[0]?.correo }));
      setPropiedades(model);
      setUsuarios(preLoadUsuarios);
      const newObj = getFilterArraySucursales(models);
      setNewObjSucursales(newObj);
    }
    return () => {
      mounted = false;
      setPropiedades({
        grupo: "",
        unidadM: "",
        claveP: "",
        concepto: "",
        valorU: "",
        valorUIva: "",
        iva: "",
        proceso: "",
        idSucursal,
      });
    };
  }, [model]);

  useEffect(() => {
    if (clearInputProducto) {
      setClearInputProducto(false);
    }
  }, [clearInputProducto]);

  const setidUsuarioCallback = useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setUsuario({ idUsuario: v?.idUsuario, nombreCompleto: v?.nombreCompleto, correo: v?.correo });
      } else {
        setUsuario("");
      }
    },
    []
  );





  useEffect(() => {
    const newObj = getFilterArraySucursales(models);
    setNewObjSucursales(newObj);
  }, [models]);

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title={(!editing ? "NUEVO" : "EDITAR") + " SERVICIO "}
        links={[
          {
            to: "/servicios",
            icon: <RiServiceFill fontSize="small" />,
            label: "Servicios",
          },
          {
            to: !editing ? "/servicios/nuevo" : "/servicio/editar/" + id,
            icon: !editing ? <Add /> : <Edit />,
            label: !editing
              ? "Nuevo"
              : "Editar - " +
              capitalizeFirst(
                propiedades?.concepto.toLocaleLowerCase()
              ),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div><strong>{"INFORMACIÓN DEL SERVICIO"}</strong></div>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Tipo de estudio"
            variant="standard"
            fullWidth
            size="medium"
            helperText={modelError?.grupo}
            error={Boolean(modelError?.grupo)}
            typeInput='text'
            value={propiedades?.grupo}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                grupo: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Unidad de Medida"
            variant="standard"
            fullWidth
            helperText={modelError?.unidadM}
            error={Boolean(modelError?.unidadM)}
            size="medium"
            typeInput='text'
            value={propiedades?.unidadM}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                unidadM: e.target.value,
              }))
            }
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Clave del Producto"
            variant="standard"
            fullWidth
            helperText={modelError?.claveP}
            error={Boolean(modelError?.claveP)}
            size="medium"
            typeInput='text'
            value={propiedades?.claveP}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                claveP: e.target.value,
              }))
            }
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Nombre del estudio"
            variant="standard"
            fullWidth
            helperText={modelError?.concepto}
            error={Boolean(modelError?.concepto)}
            size="medium"
            typeInput='text'
            value={propiedades?.concepto}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                concepto: e.target.value,
              }))
            }
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Valor Unidad"
            variant="standard"
            fullWidth
            helperText={modelError?.valorU}
            error={Boolean(modelError?.valorU)}
            size="medium"
            typeInput='currency'
            value={propiedades?.valorU}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                valorU: e.target.value,
                iva: (e.target.value * 0.16).toFixed(2),
                valorUIva: (e.target.value * 1.16).toFixed(2)
              }))
            }
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Iva"
            variant="standard"
            fullWidth
            disabled
            helperText={modelError?.iva}
            error={Boolean(modelError?.iva)}
            size="medium"
            typeInput='text'
            value={propiedades?.iva}

          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Valor Unidad con Iva"
            variant="standard"
            fullWidth
            helperText={modelError?.valorUIva}
            error={Boolean(modelError?.valorUIva)}
            size="medium"
            typeInput='text'
            value={propiedades?.valorUIva}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="procesoSelect">Proceso</InputLabel>
            <Select
              labelId="procesoSelect"
              id="procesoSelectId"
              value={propiedades.proceso}
              label="Forma de Pago"
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  proceso: e.target.value,
                }))
              }
            >
              <MenuItem value={"CIRUGIA"}>Cirugia</MenuItem>
              <MenuItem value={"CONSULTA"}>Consulta</MenuItem>
              <MenuItem value={"MEDICAMENTO"}>Medicamento</MenuItem>
              <MenuItem value={"OTROS-SERVICIOS"}>Otro Servicio</MenuItem>
              <MenuItem value={"PROCEDIMIENTO"}>Procedimiento</MenuItem>
            </Select>
          </FormControl>
        </Grid>

      </Grid>

      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <div><strong>{"DOCTORES"}</strong></div>
          <Divider />
        </Grid>
        <Grid item xs={10}>
          <AsyncAutocompleteSearchRework
            label="Doctores"
            labelProp="nombreCompleto"
            name="usuario"
            onChange={setidUsuarioCallback}
            clearInput={clear}
            extraParams={paramsMemoDoctor}
            error={errorDoctor}
          />
        </Grid>
        <Grid item xs={2} style={{ display: 'flex' }}>
          <LoadingButton
            disabled={usuario?.idUsuario == null}
            variant="contained"
            color="primary"
            onClick={e => { agregarDoctor() }}
            loading={loadingUsuario}
            style={{ color: "#fff", width: '100%' }}
          >
            <Add />Agregar Doctor
          </LoadingButton>
        </Grid>
        {(usuarios.length > 0) && (
          <>
            <Grid item xs={12}>
              <Table className={entradas.tableEntradas}>
                <TableHead>
                  <TableRowModify>
                    <TableCellModify> # </TableCellModify>
                    <TableCellModify> Nombre Completo </TableCellModify>
                    <TableCellModify> Correo </TableCellModify>
                    <TableCellModify> </TableCellModify>
                  </TableRowModify>
                </TableHead>
                <TableBody>
                  {reload ? usuarios.map((u, index) => {
                    return (
                      <TableRowModify key={index}>
                        <TableCellModify> {index + 1} </TableCellModify>
                        <TableCellModify> {u?.nombreCompleto} </TableCellModify>
                        <TableCellModify> {u?.correo} </TableCellModify>
                        <TableCellModify>
                          <IconButton
                            onClick={e => {
                              onDropDoctor(u.idUsuario, u.nombreCompleto);
                              setReload(false);
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </TableCellModify>
                      </TableRowModify>
                    )
                  }) : (
                    <TableRowModify>
                      <TableCellModify></TableCellModify>
                      <TableCellModify></TableCellModify>
                      <TableCellModify> <CircularProgress /> </TableCellModify>
                      <TableCellModify></TableCellModify>
                    </TableRowModify>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </>
        )}
      </Grid>

      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >

        <Grid item xs={12}>
          <strong>{"SUCURSALES"}</strong>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <TablePagination
            header={header}
            data={newObjSucursales}
            extraRows={[
              {
                prop: "accion",
                name: "Acción",
                sortable: false,
                cell: (row, index) =>
                  <Switch
                    disabled={row && (row?.eliminado === null && row?.active === null) ? true : false}
                    checked={row?.active}
                    onChange={({ target }) => {
                      newObjSucursales[index].active = target.checked;
                      setNewObjSucursales([...newObjSucursales]);
                    }}
                  />,
              },
            ]}
          />
        </Grid>
        <Grid
          component={Paper}
          container
          className={classes.paper}
          spacing={1}
          style={{ marginTop: 10 }}
        ></Grid>

      </Grid>
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading || updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["servicios"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={onGuardar}
              size="medium"
              startIcon={<SaveIcon />}
              style={{ color: "#fff" }}
            >
              {(!editing ? "GUARDAR" : "MODIFICAR")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default ServiciosDetail;