////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import StepWizard from '../../../components/stepWizard';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Gracias from './gracias';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import {
    MdLooksOne,
    MdLooksTwo,
    MdLooks3,
} from 'react-icons/md';
import BeenhereIcon from '@mui/icons-material/Beenhere';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const Agenda = () => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const [currentStep, setCurrentStep] = useState(0);
    const [gracias, setGracias] = useState(false);
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const nextStep = (step) => {
        if(step === "gracias"){
            setGracias(true);
        }else{
            setCurrentStep(step);
        }
    }
    const setProgress = (progress, pos) => {
        let stepsCopy = steps;
        stepsCopy.map((step, i) => {
            if (i == pos) {
                step.value = progress
            }
        });
        setSteps([...stepsCopy]);
    }

    const [steps, setSteps] = useState([
        {
            icon: <MdLooksOne />,
            iconActive: <BeenhereIcon />,
            active: false,
            container: <Step1 nextStep={nextStep} setProgress={setProgress} />,
            value: 0
        },
        {
            icon: <MdLooksTwo />,
            iconActive: <BeenhereIcon />,
            active: false,
            container: <Step2 nextStep={nextStep} setProgress={setProgress} />,
            value: 0
        },
        {
            icon: <MdLooks3 />,
            iconActive: <BeenhereIcon />,
            active: false,
            container: <Step3 nextStep={nextStep} setProgress={setProgress} />,
            value: 0
        }
    ]);
    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (currentStep > 0) {
            let stepsCopy = steps;
            stepsCopy.map((step, i) => {
                if (i < currentStep) {
                    step.active = true;
                    step.value = 100
                } else {
                    step.active = false;
                    step.value = 0
                }
            });
            setSteps([...stepsCopy]);
        }
    }, [currentStep]);

    return (
        <>
            <div style={gracias?{}:{ padding: '20px' }}>
                {
                    gracias ?
                        <Gracias />
                        :
                        <StepWizard steps={steps} currentStep={currentStep} />
                }
            </div>
        </>
    );
}
export default Agenda;