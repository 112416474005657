/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useCallback} from "react";
import ViewListIcon from '@mui/icons-material/ViewList';
import Listado from "../views/campanas/listado";

const FileManagerContext = React.createContext();

export function FileManagerProvider(props) {

    const [ files, setFiles ] = useState([]);

    const [ cargandoFiles, setCargandoFiles ] = useState(false);

    const [activeTab, setActiveTab] = useState({
        title: "Listado", 
        content: <Listado/>,
        icon:<ViewListIcon/>,
    });

    const agregarFiles = filesArray => {
        let copyFiles = [...files];
        copyFiles.push(filesArray);
        setFiles(copyFiles);
    };

    const setCarga = carga => {
        setCargandoFiles(carga);
    };

    const agregarActiveTab = activeTab => {
        setActiveTab({...activeTab});
    }

    const clearFiles = (filesArray = false) => {
        if(!filesArray){
            setFiles([]);
        }else{
            setFiles([...filesArray]);
        }
    }

    const memData = React.useMemo(() => {
        return {
            files,
            agregarFiles,
            clearFiles,
            activeTab,
            agregarActiveTab,
            cargandoFiles,
            setCarga,
        };
    }, [files,agregarFiles,clearFiles,activeTab,agregarActiveTab,cargandoFiles,setCarga]);

    return <FileManagerContext.Provider value={memData} {...props} />;
}

export function useFileManager() {
    const context = React.useContext(FileManagerContext);
    if (!context) {
        // eslint-disable-next-line no-throw-literal
        throw "error: file manager context not defined.";
    }
    return context;
}
