import React, { useState, createContext, useContext, useCallback, useMemo, useEffect } from "react";
import Service from "../services/api";
import { auth } from "../firebase/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { useApp } from "./useApp";

const AuthContext = createContext();

export function AuthProvider(props) {
  const { token, setToken } = useApp();
  const [session, sessionLoading, sessionError] = useAuthState(auth);
  const [errorFirebase, setErrorFirebase] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const [permiso, setPermiso] = useState(null);
  const [sucursal, setSucursal] = useState(null);

  const onTokenChange = useCallback(
    (userCredential) => {
      if (userCredential) {
        userCredential.getIdToken().then((newToken) => {
          setToken(newToken);
        });
      }
    },
    [setToken]
  );

  const signIn = useCallback(async (email, password) => {
    setLoginLoading(true);
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      setErrorFirebase(e);
    } finally {
      setLoginLoading(false);
    }
  }, []);

  const signOut = useCallback(async () => {
    try {
      await auth.signOut();
      setUserData(null);
      setPermiso(null);
      setSucursal(null);
      window.localStorage.clear();
    } catch (e) {
      console.error(e)
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    let unsub = null;
    if (mounted && session) {
      unsub = auth.onIdTokenChanged(onTokenChange);
    }
    return () => {
      mounted = false;
      if (unsub) unsub();
    };
  }, [session, onTokenChange]);

  useEffect(() => {
    if (token) {
      setErrorFirebase(null);
      const obtenerPerfil = async () => {
        try {
          const response = await Service.get("perfil/cuenta?expand=permiso");
          if (response?.detalle?.estatus === 200) {
            setUserData(response.resultado[0]);
            setUserLoading(false);
          }
        } catch (error) {
          console.error(error);
        }
      };
      obtenerPerfil();
    } else {
      setUserData(null);
    }
  }, [token]);

  const memData = useMemo(() => {
    return {
      session,
      sessionLoading,
      user: userData,
      userLoading,
      userError: sessionError,
      signIn,
      signOut,
      errorFirebase,
      loginLoading,
      permiso,
      setPermiso,
      sucursal,
      setSucursal
    };
  }, [
    session,
    sessionLoading,
    sessionError,
    userData,
    userLoading,
    signIn,
    signOut,
    errorFirebase,
    loginLoading,
    permiso,
    setPermiso,
    sucursal,
    setSucursal
  ]);

  return <AuthContext.Provider value={memData} {...props} />;
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: auth context not defined.";
  }
  return context;
}