////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { useAlert } from '../../../hooks/useAlert';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useModel } from '../../../hooks/useModel';
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import {
    TextField,
    Grid,
    Button,
    CircularProgress
} from '@mui/material';
import InputRegex from '../../../components/InputRegex';
import FirebaseService from "../../../services/firebase";
import '@tensorflow/tfjs-core';
import * as faceapi from 'face-api.js';
import Services from '../../../services/api';
import Swal from 'sweetalert2';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import BlockIcon from '@mui/icons-material/Block';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////

const Step3 = ({
    nextStep,
    setProgress,
    ...props
}) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const defaultPropiedades = {

    };
    const [propiedades, setPropiedades] = useState({ ...defaultPropiedades });
    const [sig, setSig] = useState(false);
    const [recepcion, setRecepcion] = useLocalStorage("recepcion", {});
    const [file, setFile] = useState(false);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const { showAlert } = useAlert();
    const [hasPhoto, setHasPhoto] = useState(false);
    const [loadingVideo, setLoadingVideo] = useState(true);
    const [validando, setValidando] = useState(false);
    const [validado, setValidado] = useState(false);
    const matches = useMediaQuery('(max-width:700px)');
    const [isGuardar, setIsGuardar] = useState(false);
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const guardar = () => {
        setIsGuardar(true);
        canvasRef.current.toBlob(async blob => {
            let body = propiedades;
            let url = "";
            const ranm = Math.floor(Math.random() * 1000);
            const paciente = (body.nombres + body.apellidoPaterno + body.apellidoMaterno).toLowerCase();
            const pathFirebase = "Publico/" + paciente + ranm;
            var image = new Image();
            image.src = blob;
            url = await FirebaseService.uploadFile(pathFirebase, blob);
            body = { ...body, urlFoto: url, nacionalidad: "sin definir", nivelEducativo: "sin definir" };
            const res = await Services.postWithOutToken("recepcion-paciente/guardar", body);
            if (res.cuerpo.detalle.estatus == 200) {
                await Swal.fire(
                    'Gracias!',
                    'tus datos han sido regristrados con éxito!',
                    'success'
                );
                window.localStorage.clear();
                window.location.href = "https://clinicanuevavida.org/";
            } else {
                setIsGuardar(false);
                nextStep(1)
            }
        });
    }

    const getVideo = () => {
        navigator.getMedia = (navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia);

        navigator.getMedia({
            video: true,
            audio: false
        },
            stream => {
                setLoadingVideo(false);
                let video = videoRef.current;
                video.srcObject = stream;
                video.play();
            }, err => {
                console.log(err);
            }
        )
    }

    const takePhoto = () => {
        setHasPhoto(true);
    }

    const detectFace = async afile => {
        const detections = await faceapi.detectAllFaces(afile, new faceapi.SsdMobilenetv1Options());
        setValidando(false);
        if (detections.length > 0) {
            setSig(true);
            showAlert({
                message: 'Verificado correctamente',
                severity: 'success'
            });
        } else {
            setSig(false);
            showAlert({
                message: 'Favor de tomar otra foto',
                severity: 'error'
            });
        }
    }

    const validarPhoto = () => {
        detectFace(canvasRef.current);
    }
    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        setSig(false);
        if (hasPhoto) {
            let video = videoRef.current;
            let photo = canvasRef.current;
            photo.width = video.clientWidth;
            photo.height = video.clientHeight;

            let ctx = photo.getContext('2d');
            ctx.drawImage(video, 0, 0, photo.width, photo.height);
            setSig(true);
        }
    }, [hasPhoto]);

    useEffect(() => {
        getVideo();
    }, [videoRef]);

    const loadModels = async () => {
        const MODEL_URL = process.env.PUBLIC_URL + '/models';
        Promise.all([
            faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
            faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
            faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
        ]).then();
    }

    useEffect(() => {
        loadModels();
    }, []);

    useEffect(() => {
        if (file) {
            detectFace(file);
        }
    }, [file]);

    useEffect(() => {
        if (validando) {
            validarPhoto();
        }
    }, [validando]);

    useEffect(() => {
        if (Object.keys(recepcion).length > 0) {
            setPropiedades({ ...propiedades, ...recepcion });
        }
    }, [recepcion]);

    return (
        <>
            <Grid item xs={12} sm={8} md={6} lg={4} style={{ marginTop: '20px', margin: '20px' }}>
                <p style={{ fontSize: '1.5rem', color: '#666', margin: '0', marginBottom: '20px' }}>Validacion de identidad</p>
                <p style={{ fontSize: '1rem', color: '#666', margin: '0', marginBottom: '20px' }}>Es necesario que se mire bien su rostro en la foto</p>
                {
                    loadingVideo ?
                        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
                            <CircularProgress />
                        </div>
                        :
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                            <video style={{ width: '100%' }} ref={videoRef}></video>
                            <Button style={{ position: 'absolute', bottom: '20px', background: '#155480', color: '#FFF' }} onClick={takePhoto}>Tomar Foto</Button>
                            {
                                hasPhoto && (
                                    <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                                        <canvas ref={canvasRef}></canvas>
                                        {
                                            validando && (
                                                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px', alignItems: 'center', flexDirection: 'column', position: 'absolute', bottom: '0', top: '0', left: '0', right: '0' }}>
                                                    <CircularProgress />
                                                    <p style={{ color: '#FFF', textShadow: '1px 1px 0px #000000, 0px 0px 10px rgb(0 0 0 / 70%)' }}>Validando...</p>
                                                </div>
                                            )
                                        }
                                        <div style={{ position: 'absolute', bottom: '20px' }}>
                                            {/* <Button style={{ background: '#155480', color: '#FFF', marginRight: '20px' }} onClick={e => { setValidando(true); }} >Validar</Button> */}
                                            <Button style={{ background: '#155480', color: '#FFF' }} onClick={e => { setHasPhoto(false); }} >Tomar Otra Foto</Button>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                }

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Button
                        onClick={e => {
                            nextStep(1);
                            setRecepcion({ ...recepcion, ...propiedades });
                        }}
                        style={{ background: '#155480', color: '#FFF', width: '48%' }}>
                        <NavigateBeforeIcon style={{ margin: '0 10px 1px 0' }} /> Atras
                    </Button>
                    {
                        isGuardar
                            ?
                            <div style={{ width: '48%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <CircularProgress />
                            </div>
                            :
                            <Button
                                onClick={e => {
                                    nextStep(3);
                                    guardar();
                                }}
                                disabled={!sig} style={{ background: !sig ? '#FFF' : '#155480', color: sig ? '#FFF' : '#155480', width: '48%', transition: 'background 1s', border: sig ? 'none' : '2px solid #155480', }}>
                                {sig ? <>Guardar<NavigateNextIcon style={{ margin: '0 0 1px 10px' }} /></> : <>Guardar<BlockIcon style={{ margin: '0 0 1px 10px' }} /></>}
                            </Button>
                    }
                </div>
            </Grid>
        </>
    );
}
export default Step3;