/* eslint-disable eqeqeq */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useModel } from "../../hooks/useModel";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { useAlert } from "../../hooks/useAlert";
import { useModels } from "../../hooks/useModels";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import PageHeader from "../../components/PageHeader";
import {
    Grid,
    Paper,
    FormControl,
    Divider,
    TableCell,
    CircularProgress,
    TableRow,
    TableHead,
    TableBody,
    IconButton,
    Table,
    InputLabel,
    Switch,
    Select,
    MenuItem,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import moment from 'moment';
import DatePickerBeta from '../../components/DatePickerBeta';
import TablePagination from "../../components/TablePagination";
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";
import Loading from "../../components/FullScreenLoader";
import AsyncAutocompleteSearchRework from "../../components/AsyncAutocompleteSearchRework";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { tableCellStyles, tableRowStyles, entradasStyle, capitalizeFirst } from '../../utils';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Edit } from "@mui/icons-material";
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from "@mui/icons-material/Save";
import HandshakeIcon from '@mui/icons-material/Handshake';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));
const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const useEntradasStyle = entradasStyle();

const ConvenioDetail = ({ match, history, dialog }) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const id = match.params.id;
    const header = [
        {
            name: "Nombre Sucursal",
            prop: "nombreSucursal",
        }
    ];
    const classes = useStyles();
    const { showAlert } = useAlert();
    const entradas = useEntradasStyle();
    const [cliente, setCliente] = useState({});
    const [contactos, setContactos] = useState([]);
    const [usuario, setUsuario] = useState({});
    const [clear, setClear] = useState(false);
    const [errorUsuario, setErrorUsuario] = useState(false);
    const [errorContacto, setErrorContacto] = useState(false);
    const [errorVendedor, setErrorVendedor] = useState();
    const [clearVendedor, setClearVendedor] = useState(false);
    const [reload, setReload] = useState(true);
    const [vendedor, setVendedor] = useState(null);
    const [loadingUsuario, setLoadingUsuario] = useState(false);
    const [newObjSucursales, setNewObjSucursales] = useState([])
    const editing = !!id;
    const defaultContacto = {
        nombreContacto: "",
        telefono: "",
        correo: "",
    }
    const [contacto, setContacto] = useState({ ...defaultContacto });
    const [admin] = useLocalStorage("admin", null);
    const { permiso } = useAuth();
    const [usuarios, setUsuarios] = useState([]);
    const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
    const [propiedades, setPropiedades] = useState({
        nombreConvenio: "",
        corte: "",
        vigenciaInicio: moment(new Date()).format("yyyy/MM/DD"),
        vigenciaFinal: moment(new Date()).format("yyyy/MM/DD"),
        descuento: "5",
        formaPago: "CONTADO",
        ine: "",
        nombres: "",
        apellidoPaterno: "",
        comentario: "null",
        apellidoMaterno: "",
        telefono: "",
        correo: "",
        nombreContacto: "",
        telefonoContacto: "",
        correoContacto: "",
        fechaNacimiento: moment(new Date()).format("yyyy/MM/DD"),
        idSucursal,
    });

    const paramsMemo = useMemo(
        () => ({
            ordenar: "nombreCompleto.asc",
            limite: 5,

        }),
        []
    );
    const paramsMemoVendedor = useMemo(
        () => ({
            ordenar: "nombreCompleto.asc",
            idSucursal,
        }),
        [idSucursal]
    );

    const parametrosInicialesMemo = useMemo(
        () => ({
            name: "sucursal",
            ordenar: "idSucursal.asc",
        }),
        []
    );
    const [models, modelsPage, refreshModels, deleteModel,] =
        useModels({ ...parametrosInicialesMemo });

    const { model, modelLoading, modelError, updateModel, updateModelLoading } =
        useModel({
            name: "convenio",
            expand: "detalleCliente,vendedor,detalleContacto,convenioSucursal,convenioUsuario.usuario",
            id,
            redirectOnPost: true,
        });
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const onGuardar = async () => {
        let respaldo = [];
        let sucursalesFiltered = [];
        if (editing) {
            model.convenioSucursal.map(sucursal => {
                if (!models.some(s => s.idSucursal == sucursal.idSucursal)) {
                    respaldo.push({ ...sucursal.sucursal, active: true })
                }
            });
            let sucursalesToFilter = newObjSucursales.concat(respaldo);
            sucursalesToFilter.map(sucursal => {
                if (sucursal.active) {
                    sucursalesFiltered.push(sucursal);
                }
            });
        } else {
            let sucursalesToFilter = newObjSucursales;
            sucursalesToFilter.map(sucursal => {
                if (sucursal.active) {
                    sucursalesFiltered.push(sucursal);
                }
            });
        }
        if (sucursalesFiltered.length === 0) {
            showAlert({ message: 'Seleccione al menos una sucursal', severity: "warning", });
            return;
        }
        if (usuarios.length === 0) {
            setErrorUsuario(true);
            showAlert({ message: 'Seleccione al menos un usuario', severity: "warning", });
            return;
        } else {
            setErrorUsuario(false);
        }

        if (vendedor == null) {
            setErrorVendedor(true);
            showAlert({ message: 'Seleccione al menos un vendedor', severity: "warning", });
            return;
        } else {
            setErrorVendedor(false);
        }

        if (contactos.length === 0) {
            setErrorContacto(true);
            showAlert({ message: 'Seleccione al menos un contacto', severity: "warning", });
            return;
        } else {
            setErrorContacto(false);
        }
        let body = { ...propiedades, convenioSucursal: sucursalesFiltered, idVendedor: vendedor.idUsuario, contacto: contactos, usuario: usuarios, cliente };
        updateModel(body, true, "convenios");
    };
    const agregarUsuario = () => {
        setClear(true);
        window.setInterval(() => { setClear(false) }, 10);
        if (usuario.idUsuario != null) {
            const repetido = usuarios.some(e => e.idUsuario === usuario.idUsuario);
            if (repetido) {
                showAlert({
                    message: "Este Usuario ya fue agregado",
                    severity: "error",
                });
                setUsuario(null);
            } else {
                let copyUsuarios = [...usuarios];
                copyUsuarios.push(usuario);
                setUsuarios(copyUsuarios);
                setUsuario(null);
            }
        }
    }
    const agregarContacto = () => {
        const repetido = contactos.some(e => e.nombreContacto === contacto.nombreContacto);
        if (repetido) {
            showAlert({
                message: "Este Contacto ya fue agregado",
                severity: "error",
            });
            setContacto({ ...defaultContacto });
        } else {
            let copyContactos = [...contactos];
            copyContactos.push(contacto);
            setContactos(copyContactos);
            setContacto({ ...defaultContacto });
        }
    }
    const onDropContacto = async (id, nombre) => {
        if (id) {
            const res = await Swal.fire({
                title: "Borrar",
                icon: "warning",
                text: `¿Estás seguro de que deseas borrar el Contacto seleccionado "${capitalizeFirst(nombre.toLowerCase())}"?`,
                showCancelButton: true,
                confirmButtonColor: "#25607A",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, deseo Continuar!",
                cancelButtonText: "Cancelar",
            });
            if (res.isConfirmed) {
                showAlert({
                    message: "Espera un momento.....",
                    severity: "warning",
                });
                let posi;
                const copyContactos = [...contactos];
                contactos.map((e, index) => {
                    if (e.nombreContacto === id) {
                        posi = index;
                    }
                });
                copyContactos.splice(posi, 1);
                setContactos(copyContactos);
                setReload(true);
                showAlert({
                    message: "Contacto borrado con éxito.",
                    severity: "success",
                });
            } else {
                setReload(true);
            }
        }
    };
    const onDropUsuario = async (id, nombre) => {
        if (id) {
            const res = await Swal.fire({
                title: "Borrar",
                icon: "warning",
                text: `¿Estás seguro de que deseas borrar el Usuario seleccionado "${capitalizeFirst(nombre.toLowerCase())}"?`,
                showCancelButton: true,
                confirmButtonColor: "#25607A",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, deseo Continuar!",
                cancelButtonText: "Cancelar",
            });
            if (res.isConfirmed) {
                showAlert({
                    message: "Espera un momento.....",
                    severity: "warning",
                });
                let posi;
                const copyUsuarios = [...usuarios];
                usuarios.map((e, index) => {
                    if (e.idUsuario === id) {
                        posi = index;
                    }
                });
                copyUsuarios.splice(posi, 1);
                setUsuarios(copyUsuarios);
                setReload(true);
                showAlert({
                    message: "Usuario borrado con éxito.",
                    severity: "success",
                });
            } else {
                setReload(true);
            }
        }
    };
    const getFilterArraySucursales = (array) => {
        return array.map((e) => {
            if (editing) {
                if (model) {
                    if (model.convenioSucursal.some(sucursal => sucursal.idSucursal == e.idSucursal)) {
                        return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: true }
                    } else {
                        return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
                    }
                } else {
                    return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
                }
            } else {
                return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, eliminado: e.eliminado, active: false }
            }
        })
    }
    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        let mounted = true;
        if (mounted && model) {
            let PreLoadUsuarios = [];
            model.convenioUsuario.map(u => PreLoadUsuarios.push(u.usuario));
            setPropiedades(model);
            setUsuarios(PreLoadUsuarios);
            setContactos(model.detalleContacto);
            setCliente(model.detalleCliente);
            setVendedor(model.vendedor);
            setClearVendedor(true);
            window.setInterval(() => { setClearVendedor(false) }, 10);
            const newObj = getFilterArraySucursales(models);
            setNewObjSucursales(newObj);
        }
        return () => {
            mounted = false;
            setPropiedades({
                nombreConvenio: "",
                corte: "",
                vigenciaInicio: moment(new Date()).format("yyyy/MM/DD"),
                vigenciaFinal: moment(new Date()).format("yyyy/MM/DD"),
                descuento: "",
                formaPago: "",
                ine: "",
                nombres: "",
                apellidoPaterno: "",
                apellidoMaterno: "",
                telefono: "",
                correo: "",
                idSucursal,
            });
        };
    }, [model]);

    useEffect(() => {
        const newObj = getFilterArraySucursales(models);
        setNewObjSucursales(newObj);
    }, [models]);

    const setidUsuarioCallback = useCallback(
        (e, v) => {
            if (v !== "" && v) {
                setUsuario(v);
            } else {
                setUsuario("");
            }
        },
        []
    );
    const setVendedorCallback = useCallback(
        (e, v) => {
            if (v !== "" && v) {
                setVendedor(v);
            } else {
                setVendedor("");
            }
        },
        []
    );

    if (modelLoading) return <Loading />;

    return (
        <>
            <PageHeader
                history={history}
                title={(!editing ? "NUEVO" : "EDITAR") + " CONVENIO"}
                links={[
                    {
                        to: "/convenios",
                        icon: <HandshakeIcon fontSize="small" />,
                        label: "Convenio",
                    },
                    {
                        to: !editing ? "/convenio/nuevo" : "/convenio/editar/" + id,
                        icon: !editing ? <Add /> : <Edit />,
                        label: !editing
                            ? "Nuevo"
                            : "Editar - " +
                            capitalizeFirst(
                                propiedades?.nombreConvenio.toLowerCase()
                            ),
                    },
                ]}
                editing={editing}
                dialog={dialog}
            />
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <div><strong>{"INFORMACIÓN DEL CONVENIO"}</strong></div>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputRegex
                        label="Nombre Convenio"
                        variant="standard"
                        fullWidth
                        size="medium"
                        typeInput='text'
                        value={propiedades.nombreConvenio}
                        onChange={(e) =>
                            setPropiedades((prevState) => ({
                                ...prevState,
                                ...prevState.propiedades,
                                nombreConvenio: e.target.value,
                            }))
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputRegex
                        label="Dia de Corte"
                        variant="standard"
                        fullWidth
                        size="medium"
                        typeInput='number'
                        value={propiedades.corte.toString()}
                        onChange={(e) => {
                            if (e.target.value > 31) {
                                setPropiedades((prevState) => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    corte: "31",
                                }))
                            } else if (e.target.value < 1) {
                                setPropiedades((prevState) => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    corte: "",
                                }))
                            } else {
                                setPropiedades((prevState) => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    corte: e.target.value,
                                }))
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="descuentoSelect">Descuento</InputLabel>
                        <Select
                            labelId="descuentoSelect"
                            id="descuentoSelectId"
                            value={propiedades.descuento}
                            label="Descuento"
                            onChange={(e) =>
                                setPropiedades((prevState) => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    descuento: e.target.value,
                                }))
                            }
                        >
                            <MenuItem value={"0"}>0%</MenuItem>
                            <MenuItem value={"15"}>15%</MenuItem>
                            <MenuItem value={"30"}>30%</MenuItem>
                            <MenuItem value={"50"}>50%</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="formaPagoSelect">Forma de Pago</InputLabel>
                        <Select
                            labelId="formaPagoSelect"
                            id="formaPagoSelectId"
                            value={propiedades.formaPago}
                            label="Forma de Pago"
                            onChange={(e) =>
                                setPropiedades((prevState) => ({
                                    ...prevState,
                                    ...prevState.propiedades,
                                    formaPago: e.target.value,
                                }))
                            }
                        >
                            <MenuItem value={"CONTADO"}>Contado</MenuItem>
                            <MenuItem value={"CREDITO"}>Credito</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} >
                    <DatePickerBeta
                        labelText="Inicio del Convenio"
                        value={propiedades.vigenciaInicio}
                        variant="standard"
                        inputFormat="dd/MM/yyyy"
                        onChange={e => {
                            setPropiedades(prevState => ({
                                ...prevState,
                                ...prevState.propiedades,
                                vigenciaInicio: moment(e).format("yyyy/MM/DD")
                            }))
                        }
                        }
                    />
                </Grid>
                <Grid item xs={12} md={4} >
                    <DatePickerBeta
                        labelText="Final del Convenio"
                        value={propiedades.vigenciaFinal}
                        variant="standard"
                        inputFormat="dd/MM/yyyy"
                        onChange={e => {
                            setPropiedades(prevState => ({
                                ...prevState,
                                ...prevState.propiedades,
                                vigenciaFinal: moment(e).format("yyyy/MM/DD")
                            }))
                        }
                        }
                    />
                </Grid>
            </Grid>
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <div><strong>{"INFORMACIÓN DEL CLIENTE"}</strong></div>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputRegex
                        label="INE"
                        variant="standard"
                        fullWidth
                        size="medium"
                        typeInput='text'
                        value={cliente?.ine}
                        onChange={(e) =>
                            setCliente((prevState) => ({
                                ...prevState,
                                ...prevState.cliente,
                                ine: e.target.value,
                            }))
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputRegex
                        label="Nombres"
                        variant="standard"
                        fullWidth
                        size="medium"
                        typeInput='text'
                        value={cliente?.nombres}
                        onChange={(e) =>
                            setCliente((prevState) => ({
                                ...prevState,
                                ...prevState.cliente,
                                nombres: e.target.value,
                            }))
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputRegex
                        label="Apellido Paterno"
                        variant="standard"
                        fullWidth
                        size="medium"
                        typeInput='text'
                        value={cliente?.apellidoPaterno}
                        onChange={(e) =>
                            setCliente((prevState) => ({
                                ...prevState,
                                ...prevState.cliente,
                                apellidoPaterno: e.target.value,
                            }))
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputRegex
                        label="Apellido Materno"
                        variant="standard"
                        fullWidth
                        size="medium"
                        typeInput='text'
                        value={cliente?.apellidoMaterno}
                        onChange={(e) =>
                            setCliente((prevState) => ({
                                ...prevState,
                                ...prevState.cliente,
                                apellidoMaterno: e.target.value,
                            }))
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputRegex
                        label="Teléfono"
                        variant="standard"
                        fullWidth
                        size="medium"
                        maxLength='10'
                        typeInput='phone'
                        value={cliente?.telefono?.replaceAll(/[-() ]/g, '')}
                        onChange={(e) =>
                            setCliente((prevState) => ({
                                ...prevState,
                                ...prevState.cliente,
                                telefono: e.target.value,
                            }))
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputRegex
                        label="Correo Electrónico"
                        variant="standard"
                        fullWidth
                        size="medium"
                        typeInput='text'
                        value={cliente?.correo}
                        onChange={(e) =>
                            setCliente((prevState) => ({
                                ...prevState,
                                ...prevState.cliente,
                                correo: e.target.value,
                            }))
                        }
                    />
                </Grid>
            </Grid>
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <div><strong>{"INFORMACIÓN DE CONTACTO"}</strong></div>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputRegex
                        label="Nombre Contacto"
                        error={errorContacto}
                        variant="standard"
                        fullWidth
                        size="medium"
                        typeInput='text'
                        value={contacto?.nombreContacto}
                        onChange={(e) =>
                            setContacto((prevState) => ({
                                ...prevState,
                                ...prevState.contacto,
                                nombreContacto: e.target.value,
                            }))
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputRegex
                        label="Teléfono Contacto"
                        error={errorContacto}
                        variant="standard"
                        fullWidth
                        size="medium"
                        maxLength='10'
                        typeInput='phone'
                        value={contacto?.telefono?.replaceAll(/[-() ]/g, '')}
                        onChange={(e) =>
                            setContacto((prevState) => ({
                                ...prevState,
                                ...prevState.contacto,
                                telefono: e.target.value,
                            }))
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputRegex
                        label="Correo Electrónico"
                        error={errorContacto}
                        variant="standard"
                        fullWidth
                        size="medium"
                        typeInput='text'
                        value={contacto?.correo}
                        onChange={(e) =>
                            setContacto((prevState) => ({
                                ...prevState,
                                ...prevState.contacto,
                                correo: e.target.value,
                            }))
                        }
                    />
                </Grid>
                <Grid xs={12} sm={10}></Grid>
                <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton
                        disabled={contacto?.nombreContacto == null || contacto?.nombreContacto == "" || contacto?.telefono == null || contacto?.telefono == "" || contacto?.correo == null || contacto?.correo == ""}
                        variant="contained"
                        color="primary"
                        onClick={e => { agregarContacto() }}
                        loading={loadingUsuario}
                        style={{ color: "#fff" }}
                    >
                        <Add />Agregar Contacto
                    </LoadingButton>
                </Grid>
                {(contactos.length > 0) && (
                    <>
                        <Grid item xs={12}>
                            <Table className={entradas.tableEntradas}>
                                <TableHead>
                                    <TableRowModify>
                                        <TableCellModify> # </TableCellModify>
                                        <TableCellModify> Nombre Contacto </TableCellModify>
                                        <TableCellModify> Telefono </TableCellModify>
                                        <TableCellModify> Correo </TableCellModify>
                                        <TableCellModify> </TableCellModify>
                                    </TableRowModify>
                                </TableHead>
                                <TableBody>
                                    {reload ? contactos.map((c, index) => {
                                        return (
                                            <TableRowModify key={index}>
                                                <TableCellModify> {index + 1} </TableCellModify>
                                                <TableCellModify> {c.nombreContacto} </TableCellModify>
                                                <TableCellModify> {c.telefono} </TableCellModify>
                                                <TableCellModify> {c.correo} </TableCellModify>
                                                <TableCellModify>
                                                    <IconButton
                                                        onClick={e => {
                                                            onDropContacto(c.nombreContacto, c.nombreContacto);
                                                            setReload(false);
                                                        }}
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </TableCellModify>
                                            </TableRowModify>
                                        )
                                    }) : (
                                        <TableRowModify>
                                            <TableCellModify></TableCellModify>
                                            <TableCellModify></TableCellModify>
                                            <TableCellModify> <CircularProgress /> </TableCellModify>
                                            <TableCellModify></TableCellModify>
                                        </TableRowModify>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <div><strong>{"VENDEDOR"}</strong></div>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <AsyncAutocompleteSearchRework
                        label="Vendedor"
                        error={errorVendedor}
                        defaultValue={vendedor}
                        labelProp="nombreCompleto"
                        name="usuario/vendedores"
                        onChange={setVendedorCallback}
                        clearInput={clearVendedor}
                        extraParams={paramsMemoVendedor}
                    />

                </Grid>
            </Grid>
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <div><strong>{"USUARIOS SISTEMA"}</strong></div>
                    <Divider />
                </Grid>
                <Grid item xs={10}>
                    <AsyncAutocompleteSearchRework
                        label="Usuarios"
                        labelProp="nombreCompleto"
                        name="usuario"
                        onChange={setidUsuarioCallback}
                        clearInput={clear}
                        extraParams={paramsMemo}
                        error={errorUsuario}
                    />
                </Grid>
                <Grid item xs={2} style={{ display: 'flex' }}>
                    <LoadingButton
                        disabled={usuario?.idUsuario == null}
                        variant="contained"
                        color="primary"
                        onClick={e => { agregarUsuario() }}
                        loading={loadingUsuario}
                        style={{ color: "#fff", width: '100%' }}
                    >
                        <Add />Agregar Usuario
                    </LoadingButton>
                </Grid>
                {(usuarios.length > 0) && (
                    <>
                        <Grid item xs={12}>
                            <Table className={entradas.tableEntradas}>
                                <TableHead>
                                    <TableRowModify>
                                        <TableCellModify> # </TableCellModify>
                                        <TableCellModify> Nombre Completo </TableCellModify>
                                        <TableCellModify> Correo </TableCellModify>
                                        <TableCellModify> </TableCellModify>
                                    </TableRowModify>
                                </TableHead>
                                <TableBody>
                                    {reload ? usuarios.map((u, index) => {
                                        return (
                                            <TableRowModify key={index}>
                                                <TableCellModify> {index + 1} </TableCellModify>
                                                <TableCellModify> {u.nombreCompleto} </TableCellModify>
                                                <TableCellModify> {u.correo} </TableCellModify>
                                                <TableCellModify>
                                                    <IconButton
                                                        onClick={e => {
                                                            onDropUsuario(u.idUsuario, u.nombreCompleto);
                                                            setReload(false);
                                                        }}
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </TableCellModify>
                                            </TableRowModify>
                                        )
                                    }) : (
                                        <TableRowModify>
                                            <TableCellModify></TableCellModify>
                                            <TableCellModify></TableCellModify>
                                            <TableCellModify> <CircularProgress /> </TableCellModify>
                                            <TableCellModify></TableCellModify>
                                        </TableRowModify>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12}>
                    <strong>{"SUCURSALES"}</strong>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <TablePagination
                        header={header}
                        data={newObjSucursales}
                        extraRows={[
                            {
                                prop: "accion",
                                name: "Acción",
                                sortable: false,
                                cell: (row, index) =>
                                    <Switch
                                        disabled={row && (row?.eliminado === null && row?.active === null) ? true : false}
                                        checked={row?.active}
                                        onChange={({ target }) => {
                                            newObjSucursales[index].active = target.checked;
                                            setNewObjSucursales([...newObjSucursales]);
                                        }}
                                    />,
                            },
                        ]}
                    />
                </Grid>


            </Grid>
            <Grid
                component={Paper}
                container
                className={classes.paper}
                spacing={1}
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                        <LoadingButton
                            disabled={
                                modelLoading || updateModelLoading ||
                                    admin ||
                                    (permiso && permiso[0]["convenios"] & 2) === 2
                                    ? false
                                    : true
                            }
                            loading={modelLoading || updateModelLoading}
                            variant="contained"
                            size="medium"
                            onClick={e => { onGuardar() }}
                            startIcon={<SaveIcon />}
                            style={{ color: "#fff" }}
                        >
                            {(!editing ? "GUARDAR" : "MODIFICAR")}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
export default ConvenioDetail;