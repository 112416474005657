/* eslint-disable eqeqeq */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState } from 'react';
import Service from "../../../services/api";
import { useAlert } from "./../../../hooks/useAlert";
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import {
    Grid,
    Divider,
    Paper,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from '@mui/material';
import { makeStyles } from "@mui/styles";

////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import PublishIcon from '@mui/icons-material/Publish';
import InputRegex from '../../../components/InputRegex';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));

const ValoracionPublico = () => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const classes = useStyles();
    const { showAlert } = useAlert();
    const logo = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2FLogo.png?alt=media&token=46f97c79-3b08-4c2f-a277-c086784c16b7";
    const [loading, setLoading] = useState(false);
    const [registrado, setRegistrado] = useState(false);
    const defaultProps = {
        nombres: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        idConvenio: 1,
        urlFile: 'null',
        status: 'Proceso',
        comentario: 'null',
        l: false,
        rx: false,
        a: false,
        m: false,
        idSucursal: 1,
    };
    const [propiedades, setPropiedades] = useState({ ...defaultProps });
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const onGuardar = async () => {
        if (propiedades.nombre != "" && propiedades.apellidoMaterno != "" && propiedades.apellidoPaterno != "") {
            setLoading(true);
            let body = { ...propiedades }
            let res = await Service.postWithOutToken("publico/valoracion", body);
            if (res.cuerpo.detalle.estatus === 200) {
                showAlert({
                    message: "VALORACIÓN REGISTRADA CORRECTAMENTE",
                    severity: "success",
                });
                setPropiedades({ ...defaultProps });
                setLoading(false);
                setRegistrado(true);
            } else {
                showAlert({
                    message: "HUBO UN ERROR AL REGISTRARSE FAVOR DE INTENTARLO DE NUEVO",
                    severity: "error",
                });
            }
        } else {
            showAlert({
                message: "TODOS LOS CAMPOS SON REQUERIDOS!",
                severity: "error",
            });
        }
    };
    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////
    return (
        <>
            <Grid container spacing={1} >
                <Grid item xs={1} md={2} lg={3}></Grid>
                {
                    registrado ?
                        <Grid item xs={12} md={8} lg={6}>
                            <Grid item xs={12} sx={{ background: "#1e5f7b", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <img src={logo} alt={"logo"} style={{ height: "160px", width: "400px", objectFit: "cover" }} />
                            </Grid>
                            <Grid item xs={12} sx={{ background: "#FFF", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <p style={{ fontSize: "2.5rem", margin: "50px", fontWeight: "600", color: "#666666" }}>Gracias por elegir a  <br /> Clinica Nueva Vida.</p>
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex !important", justifyItems: "center !important" }}>
                                <LoadingButton
                                    sx={{ position: "relative", margin: "auto" }}
                                    variant="contained"
                                    onClick={() => setRegistrado(false)}
                                    size="medium"
                                    style={{ color: "#fff" }}
                                >
                                    {"VOLVER AL REGISTRO"}
                                </LoadingButton>
                            </Grid>
                        </Grid> :
                        <Grid item xs={10} md={8} lg={6}>
                            <Grid container spacing={1} component={Paper} className={classes.paper} style={{ marginTop: 10 }} >
                                <Grid item xs={12}>
                                    <div><strong>{"INFORMACIÓN DE LA VALORACIÓN"}</strong></div>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} >
                                    <InputRegex
                                        label="Nombre/s"
                                        variant="standard"
                                        fullWidth
                                        size="medium"
                                        typeInput='text'
                                        value={propiedades.nombres.toUpperCase()}
                                        onChange={(e) =>
                                            setPropiedades((prevState) => ({
                                                ...prevState,
                                                ...prevState.propiedades,
                                                nombres: e.target.value.toUpperCase(),
                                            }))
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <InputRegex
                                        label="Apellido Paterno"
                                        variant="standard"
                                        fullWidth
                                        size="medium"
                                        typeInput='text'
                                        value={propiedades.apellidoPaterno.toUpperCase()}
                                        onChange={(e) =>
                                            setPropiedades((prevState) => ({
                                                ...prevState,
                                                ...prevState.propiedades,
                                                apellidoPaterno: e.target.value.toUpperCase(),
                                            }))
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <InputRegex
                                        label="Apellido Materno"
                                        variant="standard"
                                        fullWidth
                                        size="medium"
                                        typeInput='text'
                                        value={propiedades.apellidoMaterno.toUpperCase()}
                                        onChange={(e) =>
                                            setPropiedades((prevState) => ({
                                                ...prevState,
                                                ...prevState.propiedades,
                                                apellidoMaterno: e.target.value.toUpperCase(),
                                            }))
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ margin: '20px 0' }}>
                                    <Grid container justifyContent="flex-end">
                                        <LoadingButton
                                            loading={loading}
                                            variant="contained"
                                            onClick={() => onGuardar()}
                                            size="medium"
                                            startIcon={<PublishIcon />}
                                            style={{ color: "#fff" }}
                                        >
                                            {"REGISTRAR VALORACIÓN"}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                }
                <Grid item xs={1} md={2} lg={3}></Grid>
            </Grid>
        </>
    );
}
export default ValoracionPublico;