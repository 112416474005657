import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const mapStyles = {
    height: "60vh",
    width: "100%",
};

const center = {
  lat: 29.106049,
  lng: -110.946938,
};

function Map({ position = null, zoom = 10, customCss = {}}) {
    return (
      <LoadScript
        googleMapsApiKey="AIzaSyCi1iCZoUFisXN9MgPS1N0tUyRBn8bC4u4"
      >
        <GoogleMap
          mapContainerStyle={{...mapStyles, ...customCss}}
          center={position === null ? center : position}
          zoom={zoom}
        >
          {(position !== null && position.lat) ? (
            <Marker
              region="sonora"
              icon={"https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2FmarketGoogle.png?alt=media&token=3813c46e-208c-412a-855c-75de613cfd5c"}
              position={position}
            />
          ) : null}
        </GoogleMap>
      </LoadScript>
    );
};

export default Map;