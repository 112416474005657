import * as React from 'react';
import {TextField, Box, Container, Grid, Alert} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { obtenerErrorFirebase } from "../utils/erroresFirebase";
import { useAuth } from "../hooks/useAuth";
import acceso from "../assets/img/acceso.png";
import { useLocalStorage } from "../hooks/useLocalStorage";

export default function SignIn() {
  const { signIn, errorFirebase, loginLoading } = useAuth();
  const [correo, setCorreo] = React.useState("");
  const [clave, setClave] = React.useState("");
  const [isView, setIsView] = useLocalStorage("isView", false);

  const onSubmitClicked = async (event) => {
    try {
      event.preventDefault();
      const newIsView = isView;
      window.localStorage.clear();
      setIsView(newIsView);
      await signIn(correo, clave);
    } catch (error) {
      //
    }
  };

  return (
      <Container className="login" component="main" maxWidth="xs" >
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={acceso} style={{ height: 200 }} alt="logo" />
          <Box>
            <TextField
              margin="normal"
              fullWidth
              label="Correo Electronico"
              type="text"
              value={correo.toUpperCase()}
              onChange={(e) => setCorreo(e.target.value.trim())}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Contraseña"
              type="password"
              onChange={(e) => setClave(e.target.value)}
            />
            {errorFirebase !== null && (
            <Grid container>
              <Alert variant="filled" severity="error" style={{width:"100%"}}>
                {obtenerErrorFirebase(errorFirebase?.message)}
              </Alert>
            </Grid>
            )}
            <LoadingButton 
              loading={loginLoading}
              variant="contained" 
              onClick={onSubmitClicked}
              size="large"
              fullWidth
              color="primary"
              style={{margin: "10px 0px"}}
            >
             Entrar
            </LoadingButton>
          </Box>
        </Box>
      </Container>
  );
}