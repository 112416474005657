/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";
import {
    Grid,
    Paper,
    Typography,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip,
    IconButton,
    Chip,
    Breadcrumbs,
    Fab,
    TextField,
    Stack
} from '@mui/material';
import { baseUrl } from "../../utils/variables";
import { Delete, Edit, Home } from "@mui/icons-material";
import { useAuth } from "../../hooks/useAuth";
import { MdPictureAsPdf } from 'react-icons/md'
import { RiFileExcel2Fill } from 'react-icons/ri'
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import SettingsIcon from '@mui/icons-material/Settings';
import Modal from '@mui/material/Modal';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { baseStyles, breadCrumbsStyles, tablaPrimaryStyle, tableCellStyles, tableRowStyles } from "../../utils";
import { useGet } from "../../hooks/useGet";
import Service from "../../services/api";
import FullScreenLoader from "../../components/FullScreenLoader";
import { useLocalStorage } from "./../../hooks/useLocalStorage";
import Switch from '@mui/material/Switch';
import Pagination from '@mui/material/Pagination';
import Swal from 'sweetalert2';
import LoadingButton from '@mui/lab/LoadingButton';
import GroupIcon from '@mui/icons-material/Group';
import { Search} from "@mui/icons-material";

const useStyles = baseStyles();
const StylesBreadCrumb = breadCrumbsStyles()(Chip);
const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const useTable = tablaPrimaryStyle();

let url = "usuario/index";

const Index = () => {
    const classes = useStyles();
    const history = useHistory();
    const tablas = useTable();
    const [admin] = useLocalStorage("admin", null);
    const [tipoCargando, setTipoCargando] = useState(1);
    const [imgUsuarioModal, setImgUsuarioModal] = useState("");
    const { permiso } = useAuth();
    const [campos, setCampos] = useState({ nombreCompleto: "", correo: "", idSucursal: "", eliminado: false, ine: "" });
    const [open, setOpen] = useState(false);
    const [servSuc, setServSuc] = useState([]);
    const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let paramsGet = { pagina: 1, idSucursal: idSucursal };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: '#FFF',
        outline: 'none',
        padding: '15px 0 0 0',
        borderRadius: '5px',
        boxShadow: '0 0 15px black'
    };

    const [usuarios, cargando, actualizar] = useGet({
        initUrl: url,
        params: paramsGet,
        action: true,
    });

    const [sucursales] = useGet({
        initUrl: 'sucursal',
        params: { pagina: 1, ordenar: "nombreSucursal.asc" },
        action: true,
    })

    const buscarUsuario = (eliminado = null) => {
        let obj = { ...paramsGet }
        if (campos.nombreCompleto) obj = { ...obj, nombreCompleto: campos.nombreCompleto }
        if (campos.correo) obj = { ...obj, correo: campos.correo }
        if (campos.ine) obj = { ...obj, ine: campos.ine }
        if (campos.idSucursal) obj = { ...obj, idSucursal: campos.idSucursal }
        if (eliminado != null) {
            if (eliminado) obj = { ...obj, eliminado: eliminado }
        } else {
            if (campos.eliminado) obj = { ...obj, eliminado: campos.eliminado }
        }
        setTipoCargando(2);
        actualizar({
            urlUpdate: url,
            properties: obj,
            loading: true,
        });
    }

    const deshabilitarUsuario = async idUsuario => {
        if (idUsuario) {
            const res = await Swal.fire({
                title: '¿Deseas deshabilitar este Usuario?',
                text: "Favor de corroborar sus datos antes de continuar.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Deshabilitar!',
                cancelButtonText: 'Cancelar'
            });

            if (res.isConfirmed) {
                Swal.fire({
                    title: 'Espera un momento.....',
                    showConfirmButton: false,
                });
                const response = await Service.delete("usuario/eliminar", { idUsuario });
                if (response && response.detalle.estatus === 200) {
                    Swal.fire(
                        'Deshabilitado!',
                        'El usuario ha sido deshabilitado con éxito',
                        'success'
                    )
                    buscarUsuario();
                }
            }
        }
    }

    const habilitarUsuario = async idUsuario => {
        if (idUsuario) {
            const res = await Swal.fire({
                title: '¿Deseas habilitar este Usuario?',
                text: "Favor de corroborar sus datos antes de continuar.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Habilitar!',
                cancelButtonText: 'Cancelar'
            });

            if (res.isConfirmed) {
                Swal.fire({
                    title: 'Espera un momento.....',
                    showConfirmButton: false,
                });
                const response = await Service.delete("usuario/habilitar", { idUsuario });
                if (response && response.detalle.estatus === 200) {
                    Swal.fire(
                        'Habilitado!',
                        'El usuario ha sido habilitado con éxito',
                        'success'
                    )
                    buscarUsuario();
                }
            }
        }
    }

    const cambiarPagina = (event, newPage) => {
        let obj = { ...paramsGet }
        if (campos.nombreCompleto) obj = { ...obj, nombreCompleto: campos.nombreCompleto }
        if (campos.correo) obj = { ...obj, correo: campos.correo }
        if (campos.ine) obj = { ...obj, ine: campos.ine }
        if (campos.idSucursal) obj = { ...obj, idSucursal: campos.idSucursal }
        if (campos.eliminado) obj = { ...obj, eliminado: campos.eliminado }
        if (newPage) obj.pagina = newPage
        setTipoCargando(2);
        actualizar({
            urlUpdate: url,
            properties: obj,
            loading: true,
        });
    };

    const getFilterArraySucursales = array => {
        return array.map((e) => {
            return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, active: false }
        })
    }

    useEffect(() => {
        if (sucursales.resultado) {
            const newObj = getFilterArraySucursales(sucursales.resultado);
            setServSuc(newObj);
        }
    }, [sucursales.resultado])

    if (cargando && tipoCargando === 1) return <FullScreenLoader />;

    return (
        <div className={classes.root}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={modalStyle}>
                    <CloseIcon onClick={handleClose} style={{ marginBottom: '15px', marginRight: '15px', display: 'flex', marginLeft: 'auto', cursor: 'pointer' }} />
                    <img style={{ maxHeight: '600px', minHeight: '400px', width: '100%' }} src={imgUsuarioModal} alt="imgUsuarioModal"></img>
                </div>
            </Modal>
            <Typography
                variant="h4"
                className={classes.typographyModified}
                gutterBottom
            >
                USUARIOS
            </Typography>

            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <StylesBreadCrumb
                                component="a"
                                label="Inicio"
                                icon={<Home fontSize="small" />}
                                onClick={() => history.push("/")}
                            />
                            <StylesBreadCrumb
                                component="a"
                                label="Usuarios"
                                icon={<GroupIcon fontSize="small" />}
                                onClick={() => history.push("/usuarios")}
                            />
                        </Breadcrumbs>
                    </Grid>

                    {(admin === true || (permiso && permiso[0]["usuarios"] & 2) === 2) ?
                        <Grid item xs={4}>
                            <Grid container justifyContent="flex-end">
                                <NavLink to="/usuario/nuevo"  >
                                    <Tooltip title="Agregar Usuario" aria-label="Agregar Usuario" className={classes.successButton}>
                                        <Fab color="primary" className={classes.fab}>
                                            <GroupAddIcon style={{ color: "#fff" }} />
                                        </Fab>
                                    </Tooltip>
                                </NavLink>
                            </Grid>
                        </Grid>
                        :
                        <Grid item xs={4}></Grid>
                    }

                    <Grid item xs={12} sm={9} md={3} xl={3}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Nombre Completo"
                            variant="standard"
                            onChange={(e) => setCampos(prevState => ({
                                ...prevState,
                                ...prevState.campos,
                                nombreCompleto: e.target.value
                            }))
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={9} md={3} xl={3}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Correo"
                            variant="standard"
                            onChange={(e) => setCampos(prevState => ({
                                ...prevState,
                                ...prevState.campos,
                                correo: e.target.value
                            }))
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={9} md={3} xl={3}>
                        <TextField
                            fullWidth
                            size="small"
                            label="INE"
                            variant="standard"
                            onChange={(e) => setCampos(prevState => ({
                                ...prevState,
                                ...prevState.campos,
                                ine: e.target.value
                            }))
                            }
                        />
                    </Grid>

                   

                    <Grid item xs={12} sm={3} md={3} xl={3} style={{
                                display:'flex',
                                justifyContent:'flex-end',
                            }}>
                        <LoadingButton
                            loading={(cargando && tipoCargando === 3) && true}
                            variant="contained"
                            onClick={() => buscarUsuario()}
                            size="large"
                            style={{
                                color: "#fff",
                            }}
                            startIcon={<Search />}
                        >
                            BUSCAR
                        </LoadingButton>
                    </Grid>

                    {
                            (admin === true) &&
                            (
                                <Grid item xs={12} sm={9} md={3} xl={3}>
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel id="demo-simple-select-autowidth-label">Sucursal</InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            label="Sucursales"
                                            value={idSucursal}
                                            onChange={(e) => setIdSucursal(e.target.value)}
                                        >
                                            <MenuItem defaultValue='seleccione'>
                                                <em>Todas</em>
                                            </MenuItem>
                                            {servSuc && servSuc.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.idSucursal} >{item.nombreSucursal}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )
                        }

                </Grid>
            </Paper>
            <Paper className={classes.paper} style={{ textAlign: 'start', padding: '20px 20px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'space-between'}}>
                <Grid item xs={8}>
                    <Switch
                        disabled={(cargando && tipoCargando === 2) && true}
                        onChange={(e) => {
                            setCampos(prevState => ({
                                ...prevState,
                                ...prevState.campos,
                                eliminado: e.target.checked
                            }));
                            buscarUsuario(e.target.checked);
                        }
                        }
                    />
                    Ver Deshabilitados
                </Grid>
                <Grid item xs={4} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Grid item xs={2}>
                        <IconButton color="error" aria-label="upload picture" component="span">
                            <a className={classes.btnPdf} target="_blank" rel="noreferrer" href={
                                `${baseUrl}pdf/usuarios?${"nombreCompleto=" + campos.nombreCompleto}${"&correo=" + campos.correo}${"&idSucursal=" + campos.idSucursal}${"&eliminado=" + (campos.eliminado?1:0)}${"&ine=" + campos.ine}`
                            }>
                                <MdPictureAsPdf />
                            </a>
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton color="warning" aria-label="upload picture" component="span">
                            <a className={classes.btnExcel} target="_blank" rel="noreferrer" href={
                                `${baseUrl}excel/usuarios?${"nombreCompleto=" + campos.nombreCompleto}${"&correo=" + campos.correo}${"&idSucursal=" + campos.idSucursal}${"&eliminado=" + (campos.eliminado?1:0)}${"&ine=" + campos.ine}`}>
                                <RiFileExcel2Fill />
                            </a>
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>
            <Grid container>
                <Grid item xs={12}>

                    {cargando && tipoCargando === 2 ? (
                        <div className={classes.loadingTable}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            <Paper className={classes.paperTable}>
                                <TableContainer className={classes.container}>
                                    <Table stickyHeader aria-label="sticky table" className={tablas.TablePrimary}>
                                        <TableHead sx={{zIndex:"1",position:"relative"}}>
                                            <TableRowModify>
                                                <TableCellModify style={{ fontWeight: '600' }}>#</TableCellModify>
                                                <TableCellModify style={{ fontWeight: '600' }}>INE</TableCellModify>
                                                <TableCellModify style={{ fontWeight: '600' }}>NOMBRE COMPLETO</TableCellModify>
                                                <TableCellModify style={{ fontWeight: '600' }}>CORREO</TableCellModify>
                                                <TableCellModify style={{ fontWeight: '600' }}>ADMIN</TableCellModify>
                                                <TableCellModify style={{ fontWeight: '600' }}>IMAGEN</TableCellModify>
                                                {
                                                    (admin === true || (permiso && permiso[0]["usuarios"] & 4) === 4) && (admin === true || (permiso && permiso[0]["usuarios"] & 8) === 8) && (
                                                        < TableCellModify style={{ textAlign: 'center', fontWeight: '600' }}> ACCIONES</TableCellModify>
                                                    )}
                                            </TableRowModify>
                                        </TableHead>
                                        <TableBody>
                                            {usuarios &&
                                                usuarios.resultado.map((row, index) => {
                                                    return (
                                                        <TableRowModify key={row.idUsuario}>

                                                            <TableCellModify>{index + 1}</TableCellModify>
                                                            <TableCellModify>{row.ine}</TableCellModify>
                                                            <TableCellModify>{row.nombreCompleto}</TableCellModify>
                                                            <TableCellModify>{row.correo}</TableCellModify>
                                                            <TableCellModify>{(row.admin) ? "SI" : "NO"}</TableCellModify>
                                                            <TableCellModify>
                                                                <Avatar style={{ cursor: 'pointer' }} onClick={() => {
                                                                    setImgUsuarioModal(row.urlFoto);
                                                                    handleOpen();
                                                                }} alt={row.nombre} src={row.urlFoto} />
                                                            </TableCellModify>
                                                            <TableCellModify>

                                                                {
                                                                    (admin === true || (permiso && permiso[0]["usuarios"] & 4) === 4) && (
                                                                        (row.eliminado == null) && (
                                                                            <NavLink to={(`usuario/editar/${row.idUsuario}`)} exact>
                                                                                <Tooltip title="Editar">
                                                                                    <IconButton
                                                                                        aria-label="editar"
                                                                                        onClick={() => {
                                                                                            history.push(`usuario/editar/${row.idUsuario}`);
                                                                                        }
                                                                                        }
                                                                                    >
                                                                                        <Edit />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </NavLink>
                                                                        )
                                                                    )
                                                                }
                                                                {
                                                                    (admin === true || (permiso && permiso[0]["usuarios"] & 8) === 8) && (
                                                                        <>
                                                                            {(row.eliminado == null) ?
                                                                                <Tooltip title="Deshabilitar">
                                                                                    <IconButton
                                                                                        aria-label="eliminar"
                                                                                        onClick={() => deshabilitarUsuario(row.idUsuario)}
                                                                                    >
                                                                                        <Delete />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                :
                                                                                <Tooltip title="Habilitar">
                                                                                    <IconButton
                                                                                        aria-label="eliminar"
                                                                                        onClick={() => habilitarUsuario(row.idUsuario)}
                                                                                    >
                                                                                        <RestoreFromTrashIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            }
                                                                        </>
                                                                    )
                                                                }

                                                                <Tooltip title="Opciones">
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            history.push(`usuario/permisos/${row.idUsuario}`);
                                                                        }
                                                                        }
                                                                    >
                                                                        <SettingsIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCellModify>
                                                        </TableRowModify>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <br></br>
                                <div className={classes.paginationModified}>
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={Math.ceil(
                                                usuarios.paginacion.total / usuarios.paginacion.limite
                                            )}
                                            page={usuarios.paginacion.pagina}
                                            onChange={cambiarPagina}
                                            variant="outlined"
                                            shape="rounded"
                                        />
                                    </Stack>
                                </div>
                                <br></br>
                            </Paper>
                        </>
                    )}
                </Grid>
            </Grid>
        </div >
    );
};

export default Index;