import { Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import React from "react"

const conclusiones = ({ datos, setDatos, ...props }) => {
    return (
        <>
            <Grid xs={12} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"diagnostico medico:".toLocaleUpperCase()}
                    value={datos?.conclusiones?.diagnosticoMedico}
                    placeholder={"diagnostico medico:".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, conclusiones: { ...datos?.conclusiones, diagnosticoMedico: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"plan terapeutico:".toLocaleUpperCase()}
                    value={datos?.conclusiones?.terapeutico}
                    placeholder={"plan terapeutico:".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, conclusiones: { ...datos?.conclusiones, terapeutico: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} sx={{ padding: "10px" }}>
                <TextField
                    fullWidth
                    label={"recomendaciones:".toLocaleUpperCase()}
                    value={datos?.conclusiones?.recomendaciones}
                    placeholder={"recomendaciones:".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, conclusiones: { ...datos?.conclusiones, recomendaciones: e.target.value } }) }}
                />
            </Grid>
            <Grid xs={12} sx={{ padding: "0px 10px 10px 10px" }}>
                <InputLabel id="apto-label">{"apto".toLocaleUpperCase()}</InputLabel>
                <Select
                    fullWidth
                    labelId="apto-label"
                    label={"apto".toLocaleUpperCase()}
                    value={datos?.conclusiones?.apto}
                    placeholder={"apto".toLocaleUpperCase()}
                    onChange={e => { setDatos({ ...datos, conclusiones: { ...datos?.conclusiones, apto: e.target.value } }) }}
                >
                    <MenuItem value={"SI"}>SI</MenuItem>
                    <MenuItem value={"NO"}>NO</MenuItem>
                </Select>
            </Grid>
        </>
    )
}

export default conclusiones