/* eslint-disable no-mixed-operators */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useModels } from '../../hooks/useModels';
import { NavLink } from 'react-router-dom';
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import {
    Grid,
    MenuItem,
    IconButton,
    FormControlLabel,
    Switch,
    Popover,
    Menu,
} from '@mui/material';
import TablePageLayout from '../../components/TablePageLayout';
import TablePagination from '../../components/TablePagination';
import LoadingButton from '@mui/lab/LoadingButton';
import InputRegex from '../../components/InputRegex';
import Swal from 'sweetalert2';
import moment from 'moment';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { baseStyles } from '../../utils';
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import { Add, Delete, Edit, Search } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { MdPictureAsPdf } from 'react-icons/md';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { RiFileExcel2Fill } from 'react-icons/ri';
import AllInboxIcon from '@mui/icons-material/AllInbox';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
import { baseUrl } from "../../utils/variables";
const useStyles = baseStyles();


const Grupos = () => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const [perPage, setPerPage] = useState(10);
    const [openMenuRow, setOpenMenuRow] = useState(null);
    const [pagina, setPagina] = useState(1);
    const [admin] = useLocalStorage('admin', null);
    const { user, permiso } = useAuth();
    const parametrosInicialesMemo = useMemo(
        () => ({
            name: 'grupo-contacto',
            expand: 'datosCampana'
        }),
        []
    );
    const [
        models,
        modelsLoading,
        modelsError,
        modelsPage,
        refreshModels,
        deleteModel,
    ] = useModels({ ...parametrosInicialesMemo });

    const [propiedades, setPropiedades] = useState({
        nombreConvenio: '',
        fechaInicio: moment(new Date()).startOf('month').format('YYYY/MM/DD'),
        fechaFinal: moment(new Date()).format('YYYY/MM/DD'),
    });
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    const onDeleteClicked = async (mensaje, type, id) => {
        try {
            setOpenMenuRow(false);
            await Swal.fire({
                title: type,
                text: mensaje,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34AA44',
                cancelButtonColor: '#E72C33',
                confirmButtonText: 'Si, deseo Continuar.',
            }).then((result) => {
                if (result.isConfirmed) {
                    let params = {};
                    params = {
                        ...parametrosInicialesMemo,
                        ...propiedades,
                        ...{ pagina: pagina, limite: perPage },
                    };
                    deleteModel(id, type, params);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const getTotales = row => {
        return (
            <PopupState variant="popover" popupId="demo-popup-popover" >
                {(popupState) => (
                    <div>
                        <IconButton variant="contained" {...bindTrigger(popupState)}>
                            <VisibilityIcon />
                        </IconButton>
                        <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {
                                    row.datosCampana && row.datosCampana.map((contacto, i) => {
                                        return (
                                            <div key={contacto.idDatosCampana} style={{ width: '100%' }}>
                                                <div style={{ background: '#1D5E7B ', padding: '5px 20px', color: '#FFF', textAlign: 'center' }}>{contacto.tipo}</div>
                                                <div style={{ textAlign: 'center' }}>{contacto.contacto}</div>
                                            </div>
                                        );
                                    })
                                }
                            </div>

                        </Popover>
                    </div>
                )}
            </PopupState>
        );
    }

    const onSearchClicked = async eliminado => {
        let params = {};
        if (eliminado != null) {
            params = {
                ...parametrosInicialesMemo,
                ...propiedades,
                ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
            };
        } else {
            params = {
                ...parametrosInicialesMemo,
                ...propiedades,
                ...{ pagina: pagina, limite: perPage },
            };
        }
        await refreshModels({ isCargando: true, params });
    };

    const changePage = async page => {
        setPagina(page + 1);
        let params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: page + 1, limite: perPage },
        };
        await refreshModels({ isCargando: true, params });
    };

    const changePageRow = async per => {
        setPerPage(per);
        const params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ limite: per, pagina: pagina },
        };
        await refreshModels({ isCargando: true, params });
    };
    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////

    return (
        <>
            <TablePageLayout
                title='Grupo Contacto'
                model='grupo-contacto'
                history={history}
                actionButton={{
                    to: '/grupo/nuevo',
                    icon: <Add />,
                    label: 'Agregar',
                    permiso: 'campanas'
                }}
                links={[
                    {
                        label: 'Grupo Contacto',
                        to: '/grupos',
                        icon: <AllInboxIcon fontSize='small' />
                    },
                ]}
                SearchComponents={
                    <>
                        <Grid item xs={12} sm={3}>
                            <InputRegex
                                label='Nombre del Grupo'
                                variant='standard'
                                fullWidth
                                size='medium'
                                typeInput='text'
                                value={propiedades.nombreGrupo}
                                onChange={(e) =>
                                    setPropiedades((prevState) => ({
                                        ...prevState,
                                        ...prevState.propiedades,
                                        nombreGrupo: e.target.value,
                                    }))
                                }
                            />
                        </Grid>
                        <Grid xs={12} sm={3}> </Grid>
                        <Grid xs={12} sm={3}> </Grid>
                        <Grid item xs={12} md={2} style={{ display: 'flex' }}>
                            <LoadingButton
                                variant='contained'
                                fullWidth
                                onClick={() => onSearchClicked(null)}
                                loading={modelsLoading && true}
                                style={{
                                    float: 'right',
                                    color: 'whitesmoke',
                                }}
                                startIcon={<Search />}
                            >
                                Buscar
                            </LoadingButton>
                        </Grid>
                    </>
                }
                DisableComponents={
                    <>
                        <Grid item xs={8}>
                            <FormControlLabel
                                className={classes.switchColor}
                                control={
                                    <Switch
                                        disabled={modelsLoading && true}
                                        onChange={(e) => {
                                            setPropiedades((prevState) => ({
                                                ...prevState,
                                                ...prevState.propiedades,
                                                eliminado: e.target.checked ? 1 : 0,
                                            }));
                                            onSearchClicked(e.target.checked ? 1 : 0);
                                        }}
                                    />
                                }
                                label='Ver Deshabilitados'
                            />
                        </Grid>
                        <Grid item xs={4} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", }}>
                            <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <IconButton color="error" aria-label="upload picture" component="span">
                                    <a className={classes.btnPdf} target="_blank" rel="noreferrer" href={`${baseUrl}pdf/grupo?
                                    ${"admin=" + (admin ? 1 : 0)}
                                    ${"&uid=" + user?.idUsuario}
                                    ${"&nombreGrupo=" + propiedades.nombreGrupo}
                                    ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`}>
                                        <MdPictureAsPdf />
                                    </a>
                                </IconButton>
                            </Grid>
                            <Grid item xs={2} style={{ isplay: "flex", flexDirection: "row", alignItems: "center", }} >
                                <IconButton color="warning" aria-label="upload picture" component="span">
                                    <a className={classes.btnExcel} target="_blank" rel="noreferrer" href={`${baseUrl}excel/grupo?
                                    ${"admin=" + (admin ? 1 : 0)}
                                    ${"&uid=" + user?.idUsuario}
                                    ${"&nombreGrupo=" + propiedades.nombreGrupo}
                                    ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`}>
                                        <RiFileExcel2Fill />
                                    </a>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </>
                }
                tableLoading={modelsLoading}
                tableErrors={modelsError}
                TableComponent={
                    <TablePagination
                        nombrePermiso='campanas'
                        permiso={permiso}
                        header={[]}
                        data={models}
                        paginatedApi
                        pagina={pagina}
                        paginationPageSize={perPage}
                        onRowsPerPageChangeApi={changePageRow}
                        changePageApi={changePage}
                        count={modelsPage !== null ? modelsPage.total : 0}
                        labelRowsPerPage={'Renglones por página'}
                        extraRows={[
                            {
                                prop: 'fechaProp',
                                name: 'Fecha',
                                sortable: false,
                                cell: (row, index) => (
                                    <p key={index}>{moment(row.fechaProp).format('DD/MM/yyy')}</p>
                                )
                            },
                            {
                                prop: 'nombreGrupo',
                                name: 'Nombre Del Grupo',
                                cell: (row, index) => (
                                    <p key={index}>{row.nombreGrupo}</p>
                                )
                            },
                            {
                                prop: 'contactos',
                                name: 'contactos',
                                cell: (row, index) => (
                                    getTotales(row)
                                )
                            },
                            {
                                prop: 'acciones',
                                name: 'Acciones',
                                sortable: false,
                                cell: (row, index) => (permiso && (permiso[0]['campanas'] & 4) === 4 || (permiso[0]['campanas'] & 8) === 8) && (
                                    <div>
                                        <IconButton onClick={(event) => { setOpenMenuRow(index); setAnchorEl(event.currentTarget) }}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu anchorEl={anchorEl} keepMounted open={index === openMenuRow} onClose={() => setOpenMenuRow(null)}>
                                            {(admin === true || (permiso && permiso[0]['campanas'] & 4) === 4) && (row.eliminado == null) && (
                                                <NavLink exact to={(`grupo/editar/${row.idGrupoContacto}`)} style={{ color: '#000', textDecoration: 'none' }}>
                                                    <MenuItem aria-label='editar'
                                                        onClick={() => {
                                                            history.push(`grupo/editar/${row.idGrupoContacto}`, { match: row.idGrupoContacto });
                                                        }}>
                                                        <Edit style={{ paddingRight: 5 }} />
                                                        Editar
                                                    </MenuItem>
                                                </NavLink>
                                            )}
                                            {(admin === true || (permiso && permiso[0]['campanas'] & 8) === 8) && (
                                                row?.eliminado === null ? (
                                                    <MenuItem
                                                        onClick={() => onDeleteClicked(`Desea Deshabilitar el Grupo Contacto ${row.nombreGrupo}`, 'Deshabilitar', row.idGrupoContacto)}
                                                    >
                                                        <Delete style={{ paddingRight: 5 }} />
                                                        Deshabilitar
                                                    </MenuItem>
                                                ) : (
                                                    <MenuItem
                                                        onClick={() => onDeleteClicked(`Desea Habilitar el Grupo Contacto ${row.nombreGrupo}`, 'Habilitar', row.idGrupoContacto)}
                                                    >
                                                        <RestoreFromTrashIcon style={{ paddingRight: 5 }} />
                                                        Habilitar
                                                    </MenuItem>
                                                )
                                            )}
                                        </Menu>
                                    </div>
                                ),
                            },
                        ]}
                    />
                }
            />
        </>
    );
}
export default Grupos;