////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect } from 'react'
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import { Grid } from '@mui/material';

////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////


const StepWizardNoDesing = ({ steps = [], currentStep = 0 }) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const [step, setStep] = useState(steps[currentStep]);
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////
    
    useEffect( () => {
        if(currentStep != steps.length){
            setStep(steps[currentStep])
        }else{
            
        }
    },[currentStep])


    return (
        <>
            <Grid container spacin={2}>
                <Grid item xs={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    {step.container}
                </Grid>
            </Grid>
        </>
    );
}
export default StepWizardNoDesing;