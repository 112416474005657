////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import { DateRangePicker } from 'rsuite';
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////

const RangePicker = ({
    value,
    format,
    placeholder,
    onChange,
    ranges = true,
    ...props
}) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////

    return (
        <>
            {
                !ranges ?
                    <DateRangePicker
                        style={{ width: '100%' }}
                        value={value}
                        onChange={onChange}
                        format={format}
                        placeholder={placeholder}
                        ranges={[]}
                    />
                    :
                    <DateRangePicker
                        style={{ width: '100%' }}
                        value={value}
                        onChange={onChange}
                        format={format}
                        placeholder={placeholder}
                    />
            }
        </>
    );
}
export default RangePicker;