////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { Grid } from '@mui/material';
import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////

const Gracias = () => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////
    const logo = "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2FLogo.png?alt=media&token=46f97c79-3b08-4c2f-a277-c086784c16b7";
    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////

    return (
        <>
            <Grid container spacing={0} sx={{ paddingTop: "40px" }}>
                <Grid item xs={12} sx={{ background: "#1e5f7b", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <a href="http://clinicanuevavida.org">
                        <img src={logo} alt={"logo"} style={{ height: "160px", width: "400px", objectFit: "cover" }} />
                    </a>
                </Grid>
                <Grid item xs={12} sx={{ background: "#FFF", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <p style={{ fontSize: "2.5rem", margin: "50px", fontWeight: "600", color: "#666666" }}>Gracias por elegir a Clinica Nueva Vida.</p>
                </Grid>
            </Grid>
        </>
    );
}
export default Gracias;