////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////

const MultiCheck = ({
    options,
    size,
    ...props
}) => {
    ////////////////////////////////////////////////////////////////////////
    //                              Vars                                  //
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////
    //                           Funcions Def                             //
    ////////////////////////////////////////////////////////////////////////
    
    ////////////////////////////////////////////////////////////////////////
    //                            Hooks Def                               //
    ////////////////////////////////////////////////////////////////////////

    return (
        <>
            <div style={{width:"30px"}}>
                {
                    options.map((option, i) => {
                        return (
                            <>
                                {
                                    option ?
                                        <CheckBoxIcon style={{fontSize:"15px", color:'#666'}} key={i} />
                                        :
                                        <CheckBoxOutlineBlankIcon style={{fontSize:"15px", color:'#666'}} key={i} />
                                }
                            </>
                        )
                    })
                }
            </div>
        </>
    );
}
export default MultiCheck;