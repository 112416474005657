import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertTypes({ open = false, onClose, severity = "success", message = "Alerta"}) {

  const handleClose = (event, reason) => {
    onClose()
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={7500} onClose={handleClose}>
        <Alert color={severity} onClose={handleClose} sx={{ width: '100%' }}>
           <h4 style={{color:"White"}}>{message}</h4>
        </Alert>
      </Snackbar>
    </Stack>
  );
}